import React, { useState, useRef, useEffect, useCallback, Fragment, useImperativeHandle, forwardRef } from 'react';
import { Container, Row, Col, Input, Button, Modal, ModalHeader, ModalBody, Form } from 'reactstrap';
import { useParams, useHistory } from 'react-router-dom';
import SearchInventories from './searchInventories';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { createBundlesAct, getInventoryListAct, getBundleItemAct, updateBundleItemAct, getMaxBundleNoAct } from '../../../store/inventory/inventoryactions';
import { generateApiUrl } from '../../../api/apihelper';
import { toast } from 'react-toastify';
import WhTableContainer from '../../../Components/Common/WhTables';
import { useMemo } from 'react';
import { generateColumns, mapBundleDetails, generatePayload } from './helper';
import ApiLoader from '../../../Components/Common/ApiLoader';
import RouteChangeConfirmation from '../../../Components/Common/RouteChangeConfirmation';

function CreateBundle({ path, resetBundle, setResetBundle}) {
    
    const [isBundleRouteBlocked,setIsBundleRouteBlocked] = useState(false);
    const isBundleRouteChangeApiCalled = useRef(false);
    const [productsModal, setProductsModal] = useState(false);
    const [title, setTitle] = useState('');
    const [modalTableData, setModalTableData] = useState([]);
    const [status,setStatus] = useState('new');
    const [modalPageCount, setModalPageCount] = useState(0);
    const [rowSelection, setRowSelection] = useState({});
    const [tableData, setTableData] = useState([]);
    const [itemNumbers,setItemNumbers] = useState([]);
    const dispatch = useDispatch();
    const { id } = useParams();
    const history = useHistory();
    const [bundleSkuNo,setBundleSkuNo] = useState("");
    const isCreateBundleBtnCliked = useRef(false);
    const {
        getInventoryListResp,
        getInventoryListLoading,
        updateBundleItemResp,
        inventoryErrorResp,
        getBundleItemResp,
        createBundleItemResp,
        getMaxBundleNoResp,
        inventoryApiLoading
    } = useSelector(
        ({ inventory }) => ({
            getInventoryListResp: inventory.getInventoryListResp,
            getBundleItemResp: inventory.getBundleItemResp,
            updateBundleItemResp: inventory.updateBundleItemResp,
            createBundleItemResp: inventory.createBundleItemResp,
            getMaxBundleNoResp: inventory.getMaxBundleNoResp,
            inventoryErrorResp: inventory.inventoryErrorResp,
            inventoryApiLoading: inventory.inventoryApiLoading
        }),
        shallowEqual
    );

    const inventoryResp = useRef({
        prevGetInventoryListResp: getInventoryListResp,
        prevGetBundleItemResp: getBundleItemResp,
        prevUpdateBundleItemResp: updateBundleItemResp,
        prevCreateBundleItemResp: createBundleItemResp,
        prevInventoryErrorResp: inventoryErrorResp,
    });

    useEffect(() => {
        const {
            prevGetInventoryListResp,
            prevInventoryErrorResp,
            prevGetBundleItemResp,
            prevCreateBundleItemResp,
            prevUpdateBundleItemResp
        } = inventoryResp.current || {};

        if (createBundleItemResp && prevCreateBundleItemResp !== createBundleItemResp) {
            if (!isBundleRouteChangeApiCalled.current) {
                isCreateBundleBtnCliked.current=true;
                setIsBundleRouteBlocked(false);
            } else {
                toast.success("Successfully saved the bundle details");
                isBundleRouteChangeApiCalled.current = false;
            }
        }
        if (getBundleItemResp && prevGetBundleItemResp !== getBundleItemResp) {
            const {type,data} = getBundleItemResp || {};
            if(type === 'success'){
                mapBundleDetails(
                    data, 
                    setTableData, 
                    setTitle,
                    getItemNumbers,
                    setBundleSkuNo,
                    setStatus
                );
            }
        }
        if(updateBundleItemResp && prevUpdateBundleItemResp !== updateBundleItemResp){
            if (!isBundleRouteChangeApiCalled.current) {
                toast.success("Successfully updated Bundle Details");
                dispatch(getBundleItemAct(generateApiUrl(`get_bundle_item`, { id: id })));
            } else {
                isBundleRouteChangeApiCalled.current = false;
                toast.success("Successfully updated Bundle Details");
            }
        }
        if (getInventoryListResp && prevGetInventoryListResp !== getInventoryListResp) {
            setModalTableData(getInventoryListResp.data.inventories || []);
            setModalPageCount(getInventoryListResp.data.total || 0);            
        }

        if (inventoryErrorResp && prevInventoryErrorResp !== inventoryErrorResp ) {
            const { errors, error, message } = inventoryErrorResp || {};
            if(!isBundleRouteChangeApiCalled.current){
                toast.error((errors && errors[0] && errors[0].msg) || error || message);
            }
            isBundleRouteChangeApiCalled.current = false;
            isCreateBundleBtnCliked.current=false;
        }

        inventoryResp.current.prevGetInventoryListResp = getInventoryListResp;
        inventoryResp.current.prevGetBundleItemResp = getBundleItemResp;
        inventoryResp.current.prevUpdateBundleItemResp = updateBundleItemResp;
        inventoryResp.current.prevCreateBundleItemResp = createBundleItemResp;
        inventoryResp.current.prevInventoryErrorResp = inventoryErrorResp;
    }, [getInventoryListResp, inventoryErrorResp, getBundleItemResp, updateBundleItemResp, createBundleItemResp, history]);

    const toggleProductsModal = () => {
        setProductsModal(!productsModal);
    };

    const fieldChangeHndlr = (key, value, index) => {
        setTableData((prev) => {
            const updatedTableData = [...prev];
            const updatedRowData = { ...updatedTableData[index], [key]: value };
            updatedTableData.splice(index, 1, updatedRowData);
            return updatedTableData;
        });
    };
    const handleSelectInventoryItem = (newItemNumbers,selectedData) => {
        const filteredItemNumbers = newItemNumbers.filter(itemNumber => !itemNumbers.includes(itemNumber));
        const removedItemNumbers = itemNumbers.filter(itemNumber => !newItemNumbers.includes(itemNumber));
        const newFilteredData = selectedData
                .filter(item => filteredItemNumbers.includes(item.itemNumber))
                .map(item => ({ 
                    ...item, 
                    quantity: 1 // or any default value or calculated value
                }));
        setTableData(prev => {
            const existingData = [...prev].filter(item => !removedItemNumbers.includes(item.itemNumber));
            return [...existingData,...newFilteredData];
        });
        setItemNumbers([...newItemNumbers]);
        toggleProductsModal();
    };
    const removeItem = (itemNumber) => {
        setTableData(prev => {
            const filteredData = [...prev].filter(item => item.itemNumber !== itemNumber);
            return filteredData;
        })
        setItemNumbers(prev => {
            const filteredItemNumbers = [...prev].filter(num => num !== itemNumber)
            return filteredItemNumbers;
        })
   
    };
    
    
    const columns = useMemo(() => generateColumns(removeItem, fieldChangeHndlr,id,status), [status]);

    const createBundleApiCall = (payload) => {
        dispatch(
            createBundlesAct(
            generateApiUrl("create_bundle"), payload)
        );
    }

    const updateBundleApiCall = (payload) => {
        dispatch(
            updateBundleItemAct(
              generateApiUrl("update_bundle_item", { id : id }),
              payload
            )
        );
    }
    const createBundle = () => {
        const payload = generatePayload(tableData, title);
        if(!isBundleRouteChangeApiCalled.current){
            if (title === "")  toast.error("Please add Title");
            else if (tableData.length === 0) toast.error("Select Items for create Bundle");
            else {    
                if (id === "bundles-createbundle") {
                    createBundleApiCall(payload);
                } else {
                    updateBundleApiCall(payload);
                }
            }
        }else{
            if (id === "bundles-createbundle") {
                createBundleApiCall(payload);
            } else {
                updateBundleApiCall(payload);
            }
        }
        
    };
    
    const handleChangeBundleValue = (e) => {
        setTitle(e.target.value);
    };

    const getAllInventoryApiCall = useCallback(() => {
        dispatch(getInventoryListAct(generateApiUrl("get_inventory_list")));
    }, [dispatch]);

    useEffect(() => {
        const debounceFn = setTimeout(() => {
            getAllInventoryApiCall();
        }, 400);

        return () => {
            clearTimeout(debounceFn);
        };
    }, [getAllInventoryApiCall]);
    const getItemNumbers = (itemNumbers) => {
        setItemNumbers([...itemNumbers])
    }
    useEffect(() => {
        if (resetBundle) {
            setTitle('');
            setTableData([]);
            setResetBundle(false);
        }
    }, [resetBundle, setResetBundle]);
 useEffect(()=>{
    if( id && id === 'bundles-createbundle'){
        dispatch(getMaxBundleNoAct(generateApiUrl(`get_max_bundle_no`)));
    }
 },[id])

 useEffect(()=>{
    if(id && id === 'bundles-createbundle'){
        setBundleSkuNo(getMaxBundleNoResp?.data?.skuNumber || "")
    }
 },[getMaxBundleNoResp])
 useEffect(() => {
    if (status === 'new' && title) setIsBundleRouteBlocked(true);
    else if (status === 'edit') setIsBundleRouteBlocked(true);
    else setIsBundleRouteBlocked(false);
  }, [status, title]);

  useEffect(() => {
    if (!isBundleRouteBlocked && isCreateBundleBtnCliked.current) {
      history.push("/warehouseinventory/bundles",{showCreateBundleItemSuccess:true});
    }
  }, [isBundleRouteBlocked]);

    return (
        <>
            <Container fluid>
                <Row className="justify-content-center" style={{ backgroundColor: '#ededed' }}>
                    <Col xs={6} md={6} className="px-5 pt-3 ">
                        <Col xs="12" className="wh-bundle-bg px-4 py-3 pb-4 shadow-lg">
                            <h4>Title</h4>
                            <Input
                                type="text"
                                id="title"
                                fieldkey="title"
                                value={title}
                                onChange={handleChangeBundleValue}
                            />
                        </Col>

                        <Col xs="12" className="wh-bundle-bg px-4 py-1 pb-1 mt-4 shadow-lg">
                            <WhTableContainer
                                columns={(tableData.length > 0) ? columns : []}
                                data={tableData || []}
                                className="custom-header-css"
                                divClass="table-responsive mt-3 whbrand-table table-container-bundle w-100"
                                tableClass="align-middle"
                                theadClass="table-light"
                                showNoDataComponent={true}
                                loading={(getInventoryListLoading || !tableData)}
                            />
                            <div xs={12} className="d-flex justify-content-center align-items-center flex-column">
                                {tableData.length === 0 && (
                                    <>
                                        <div>Select the products you want to bundle.</div>
                                        <div>
                                            <Button
                                                style={{
                                                    marginTop: "1rem",
                                                    backgroundColor: "#000000",
                                                    borderRadius: "0.5rem",
                                                    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                                                }}
                                                onClick={() => toggleProductsModal()}
                                            >
                                                Add Products
                                            </Button>
                                        </div>
                                    </>
                                )}
                            </div>
                            <Row className="mt-1">
                                <Col xs="12" className="d-flex justify-content-between">
                                    {tableData.length > 0 && (
                                        <Fragment>
                                            {status === 'open' && (
                                                <Button
                                                    style={{
                                                        marginTop: "1rem",
                                                        backgroundColor: "#000000",
                                                        borderRadius: "0.5rem",
                                                        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                                                    }}
                                                    onClick={() => setStatus('edit')}
                                                >
                                                    Edit
                                                </Button>
                                            )}
                                            {(status === 'edit' || status === 'new') && (
                                                
                                            <Fragment>
                                                <Button
                                                style={{
                                                    marginTop: "1rem",
                                                    backgroundColor: "#000000",
                                                    borderRadius: "0.5rem",
                                                    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                                                }}
                                                onClick={() => toggleProductsModal()}
                                            >
                                                Add Products
                                            </Button>
                                                <Button
                                                    className={'px-5'}
                                                    style={{
                                                        marginTop: "1rem",
                                                        backgroundColor: "#000000",
                                                        borderRadius: "0.5rem",
                                                        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                                                    }}
                                                    onClick={() => createBundle()}
                                                >
                                                {status !== 'edit' ? "Save" : "Update"}
                                                </Button>
                                            </Fragment>
                                        
                                            )}
                                        </Fragment>
                                    )}
                                </Col>
                            </Row>
                        </Col>
                    </Col>
                    <Col xs={2} className={'pt-3'}>
                        <div className="d-flex flex-column align-items-center wh-bundle-bg" style={{border:'2px solid #000000'}}>
                            <div
                            className="fs-4 fw-bold d-flex align-items-center flex-column justify-content-center py-3"
                            >
                                <span>SKU # </span>
                                <span> {bundleSkuNo}</span>
                            </div>
                            
                        </div>
                </Col>
                </Row>
            </Container>
            <Modal
                id="showSearchModal"
                isOpen={productsModal}
                toggle={toggleProductsModal}
                centered
                size="lg"
            >
                <ModalHeader className="py-2 px-3" toggle={toggleProductsModal} closeAriaLabel={toggleProductsModal}>
                    Add products
                </ModalHeader>
                <Form
                    onSubmit={(e) => {
                        e.preventDefault();
                    }}
                >
                    <ModalBody className="px-0 pt-0">
                        <SearchInventories
                            setShowSearchModal={setProductsModal}
                            modalTableData={modalTableData}
                            modalPageCount={modalPageCount || 0}
                            getInventoryListAct={getInventoryListAct}
                            generateApiUrl={generateApiUrl}
                            dispatch={dispatch}
                            setRowSelection={setRowSelection}
                            rowSelection={rowSelection}
                            handleSelectInventoryItem={handleSelectInventoryItem}
                            getInventoryListLoading={getInventoryListLoading}
                            defaultUniqueIds={itemNumbers}
                        />
                    </ModalBody>
                </Form>
            </Modal>
            <ApiLoader loading={inventoryApiLoading} />
            <RouteChangeConfirmation
                    isBlocked={isBundleRouteBlocked}
                    title="Leave Page?"
                    content="You have unsaved changes. Are you sure you want to leave this page?"
                    saveDetailsHndlr={() => {
                        isBundleRouteChangeApiCalled.current = true;
                        createBundle();
                    }}
                    routeChangeApiResp={id === "bundles-createbundle" ? createBundleItemResp : updateBundleItemResp}
                    routeChangeApiErrResp={inventoryErrorResp}
                    toast={toast}
                />
        </>
    );
}

export default CreateBundle;
