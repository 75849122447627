import { all, fork } from "redux-saga/effects";
//layout
import LayoutSaga from "./layouts/saga";
//Auth
import AccountSaga from "./auth/register/saga";
import AuthSaga from "./auth/login/saga";
import ForgetSaga from "./auth/forgetpwd/saga";
import ProfileSaga from "./auth/profile/saga";
//Customer
import { customerSaga } from "./customer/customersaga";
//Supplier
import { supplierSaga } from "./supplier/suppliersaga";
//Inventory
import { inventorySaga } from "./inventory/inventorysaga";
//Purchase Order
import { purchaseOrderSaga } from "./purchaseorder/purchaseordersaga";
//Sales
import { salesSaga } from "./sales/salessaga";
//MasterData
import { masterDataSaga } from "./masterdata/masterdatasaga";
//UserManagement
import { userManagementSaga } from "./usermanagement/usermanagementsaga";
//Employee
import { employeeSaga } from "./employee/employeesaga";
//Department
import { departmentSaga } from "./department/departmentsaga";
//Designation
import { designationSaga } from "./designation/designationsaga";
//Reports
import { reportsSaga } from "./reports/reportssaga";

export default function* rootSaga() {
  yield all([
    fork(LayoutSaga),
    fork(AccountSaga),
    fork(AuthSaga),
    fork(ForgetSaga),
    fork(ProfileSaga),
    fork(customerSaga),
    fork(masterDataSaga),
    fork(supplierSaga),
    fork(inventorySaga),
    fork(purchaseOrderSaga),
    fork(salesSaga),
    fork(userManagementSaga),
    fork(employeeSaga),
    fork(departmentSaga),
    fork(designationSaga),
    fork(reportsSaga)
  ]);
}
