import React from 'react';
import { Col,  Row } from 'reactstrap';

export default function FooterWH() {
  const textStyle = {
    fontSize: '0.8rem', 
    backgroundColor:"#272729",
    color:"white",
    display:"flex",
    justifyContent:"center",
    alignItems:"center",
    paddingY:"1rem",
    border: '1px solid #8a949b', 
    height: "31px",
  };
  const footerWH ={
    bottom: 0,
    position: "fixed",
    right: "0",
    left: "0",
    zIndex:1000
}

  return (
    <>
      <footer className="text-center  w-100 mt-3 p-0 overflow-hidden" style={footerWH}>
        <Row>
          <Col className=' px-0 border-end'>
            <div style={textStyle}>
              F2 - Count
            </div>
          </Col>
          <Col className=' px-0 border-end'>
            <div style={textStyle}>
              F3 - List Items
            </div>
          </Col>
          <Col className=' px-0 border-end'>
            <div style={textStyle}>
              F4 - Print
            </div>
          </Col>
          <Col className=' px-0 border-end'>
            <div style={textStyle}>
              F5 - Payment
            </div>
          </Col>
          <Col className=' px-0 border-end'>
            <div style={textStyle}>
              F9 - Payments Only
            </div>
          </Col>
          <Col className=' px-0'>
            <div style={textStyle}>
              F12 - Close
            </div>
          </Col>
        </Row>
      </footer>
    </>
  );
}
