import React, { useRef, useState, useEffect } from "react";
import { Input } from "reactstrap";

export default function ClickedNumber({
  itemId,
  value = "",
  itemKey,
  fieldChangeHndlr,
  index,
  spanClassName = "",
  innerDivStyle,
  max,
  min,
  containerClassName,
  addObserver= false,
  allowNegative=true,
  onKeyDown=()=>{}
}) {
  const [inputValue, setInputValue] = useState(value);
  const [showValueState, setShowValueState] = useState(false);
  const ref = useRef(null);
  const inputRef = useRef(null);
  const handleClickOutside = (e) => {
    if (ref.current && !ref.current.contains(e.target)) {
      let v;
      setInputValue((prev) => {
        v = prev;
        return prev;
      });
      setShowValueState((prev) => {
        if (prev === true) {
          fieldChangeHndlr(itemKey, v, index);
          return false;
        }
        return prev;
      });
    }
  };
  useEffect(() => {
    if(showValueState) document.addEventListener("mousedown", handleClickOutside, true);
    else document.removeEventListener("mousedown", handleClickOutside, true);
    return () => document.removeEventListener("mousedown", handleClickOutside, true);
  }, [showValueState]);
  useEffect(() => {
    if (showValueState) inputRef.current.focus();
  }, [showValueState]);
  function onClickHanlder() {
    setInputValue(value);
    setShowValueState(true);
  }

  function onChangeHandler(event) {
    const { name, value } = event.target;
    if ((((min ? value >= min : true) && (max ? value <= max : true)) || value === "") && (Number(value) >= 0 ? true : allowNegative)) setInputValue(value);
  }
  function handleFocus() {
    inputRef.current.select();
  }

  function onKeyDownHanlder(e) {
    if (e.key === "Enter") {
      setShowValueState(false);
      fieldChangeHndlr(itemKey, inputValue, index);
    } else {
      const invalidChars = ["e", "E", "+"];
      if (min >= 0) invalidChars.push("-");
      invalidChars.includes(e.key) && e.preventDefault();
    }
  }

  function decreaseCounter() {
    if ((min ? Number(inputValue) - 1 >= min : true) && (Number(inputValue) - 1 >= 0 ? true : allowNegative)) setInputValue((prevValue) => Number(prevValue) - 1);
  }
  function increaseCounter() {
    if (max ? Number(inputValue) + 1 <= max : true) setInputValue((prevValue) => Number(prevValue) + 1);
  }

  useEffect(() => {
    let observer;
    if (addObserver) {
      const element = ref.current;
      // Create an observer that watches for changes in the className of the element
      observer = new MutationObserver((mutationsList) => {
        mutationsList.forEach((mutation) => {
          if (mutation.type === "attributes" && mutation.attributeName === "class") {
            if (element.className.includes("active")) setShowValueState(true);
          }
        });
      });
        // Start observing the element for changes in its attributes
        observer.observe(element, { attributes: true });
    }
    // Cleanup function to stop observing the element when the component unmounts
    return () => {
      if (addObserver) {
        observer.disconnect();
      }
    };
  }, []);

  useEffect(() => {
    if (!showValueState) ref.current.classList.remove("active");
  }, [showValueState]);

  useEffect(() => {
    if(showValueState)
      setInputValue(value);
  }, [showValueState]);

  return (
    <div ref={ref} style={{ height: "30px", }} className={containerClassName}>
      {showValueState ? (
        <div className="input-step full-width light" style={{...innerDivStyle}}>
          <button type="button" className="minus" onClick={decreaseCounter}>
            –
          </button>
          <Input
            type="number" onWheel={(e) => e.target.blur()}
            className="product-quantity"
            value={inputValue}
            onChange={onChangeHandler}
            onKeyDown={e => {
              onKeyDown(e);
              onKeyDownHanlder(e);
            }}
            onFocus={handleFocus}
            innerRef={inputRef}
          />
          <button type="button" className="plus" onClick={increaseCounter}>
            +
          </button>
        </div>
      ) : (
        <span className={`d-flex align-items-center ${spanClassName} w-100 h-100`} onClick={onClickHanlder}>
          {value}
        </span>
      )}
    </div>
  );
}
