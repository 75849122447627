import React, { useMemo, useState, useRef, useEffect, useCallback } from "react";
import moment from "moment";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { toast } from "react-toastify";
import { Container, Row, Col, Label, Input, Button, Modal, ModalHeader, Form, ModalBody } from "reactstrap";
import { actions, generateColumns } from "./helper";
import BackIcon from "../../../../assets/images/warehouse/menu/back.png";
import WhTableContainer from "../../../../Components/Common/WhTables";
import ApiLoader from "../../../../Components/Common/ApiLoader";
import { getStartDateFilterValue, getEndDateFilterValue } from "../../../../utility/dateutility";
//Api Helper
import { generateApiUrl } from "../../../../api/apihelper";
//Actions
import { getInvPricingReportPrintAct, getSalesHistoryReportAct } from "../../../../store/reports/reportsactions";
import F12CloseModalListener from "../../../../Components/Common/F12close";
import PrintItem from "./PrintItem";

const SalesHistory = ({ history }) => {
  const [showPrintModal, setShowPrintModal] = useState(false);
  const [filters, setFilters] = useState({
    startDate:moment().format("YYYY-MM-DD"),
    endDate:moment().format("YYYY-MM-DD"),
  });
  const [tableData, setTableData] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  function togglePrintModal() {
    setShowPrintModal(!showPrintModal);
  }
  const dispatch = useDispatch();
  const isDownloadBtnClicked = useRef(false);
  const currentPageSize = useRef(30);
  const { getSalesHistoryReportResp, getSalesHistoryReportLoading, reportsErrorResp } = useSelector(
    ({ reports }) => ({
      getSalesHistoryReportResp: reports.getSalesHistoryReportResp,
      getSalesHistoryReportLoading: reports.getSalesHistoryReportLoading,
      reportsErrorResp: reports.reportsErrorResp,
    }),
    shallowEqual
  );
  const customerResp = useRef({
    prevGetSalesHistoryReportResp: getSalesHistoryReportResp,
    prevReportsErrorResp: reportsErrorResp,
  });
  useEffect(() => {
    const { prevGetSalesHistoryReportResp, prevReportsErrorResp } = customerResp.current || {};
    if (getSalesHistoryReportResp && prevGetSalesHistoryReportResp !== getSalesHistoryReportResp) {
      if (isDownloadBtnClicked.current) {
        const url = window.URL.createObjectURL(new Blob([getSalesHistoryReportResp]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute(
              "download",
             "sales_history.csv"
            );
            document.body.appendChild(link);
            link.click();
            link.remove();
        isDownloadBtnClicked.current = false;
      } else {
        setTableData(getSalesHistoryReportResp?.data?.report || []);
        currentPageSize.current = getSalesHistoryReportResp?.data?.pageSize;
        setPageCount(Math.ceil((getSalesHistoryReportResp?.data?.total || 0) / 10))
        setPageCount((getSalesHistoryReportResp?.data?.total))
      }
    } else if (reportsErrorResp && prevReportsErrorResp !== reportsErrorResp) {
      const { errors, error, message } = reportsErrorResp || {};
      toast.error((Array.isArray(errors) && errors[0].msg) || error || message);
    }
    customerResp.current.prevGetSalesHistoryReportResp = getSalesHistoryReportResp;
    customerResp.current.prevReportsErrorResp = reportsErrorResp;
  }, [getSalesHistoryReportResp, reportsErrorResp]);

  const getSalesHistoryReportApiCall = useCallback(
    (page, pageSize, download , downloadType = 'pdf') => {
      const {  startDate, endDate } = filters;
      let params = {};
      if(!download) {
        params.page = page ? page + 1 : 1;
        params.pageSize = pageSize || (currentPageSize.current);
      }
      if(startDate) params.startDate = getStartDateFilterValue(startDate);
      if(endDate) params.endDate = getEndDateFilterValue(endDate);
      if (download && downloadType === 'html') {
        params.download = true;
        params.downloadType = downloadType;
        // isDownloadBtnClicked.current = true;
        dispatch(
          getInvPricingReportPrintAct(
            generateApiUrl(`get_sales_history_report`),
            params
          )
        );
      } 
      else if (download){
        params.download = true;
        isDownloadBtnClicked.current = true; 
        dispatch(
          getSalesHistoryReportAct(
            generateApiUrl(`get_sales_history_report`),
            params,
            {responseType: 'blob'}
          )
        );}
      else {
        dispatch(
          getSalesHistoryReportAct(
            generateApiUrl(`get_sales_history_report`),
            params
          )
        );
      }
    },
    [filters]
  );

  useEffect(() => {
    const debounceFn = setTimeout(() => {
      getSalesHistoryReportApiCall();
    }, 400);
    return () => {
      clearTimeout(debounceFn);
    };
  }, [getSalesHistoryReportApiCall]);

  const handlePageChange = (page, pageSize) => {
    getSalesHistoryReportApiCall(page, pageSize);
  };

  const filtersChngHndlr = (e) => {
    const { value, checked, name, type } = e.target;
    setFilters((prev) => ({ ...prev, [name]: type === "checkbox" ? checked : value }));
  };

  const resetFilters = () => {
    setFilters({
      startDate:moment().format("YYYY-MM-DD"),
      endDate:moment().format("YYYY-MM-DD"),
    })
  }

  const columns = useMemo(() => generateColumns(), []);

  const handleMainFilterChange = (e) => {
    const { id } = e.target;
    switch (id) {
      case "payment-history":
        return history.push("/paymentandsalesreport/paymenthistory");
      case "sales-by-highest-order":
        return history.push("/paymentandsalesreport/salesbyhighestorder");
      default:
        return null;
    }
  };
  return (
    <>
    <Container fluid className=" pt-3 pb-5">
      <Row>
        <Col xs="12" className="mb-1">
        <div className="px-3 mb-3 pt-1 border rounded-1 report-header" style={{display:"inline-block"}}>
          <h3 style={{color:'#fff'}}>Sales History Report</h3>
         </div>
          <div className="border ps-4 my-1" style={{ position: "relative" }}>
            <Label className="text-dark fs-5" style={{ position: "absolute", transform: "translateY(-50%)" }}>
            <span className=" px-1 wh-item-bg"> Filters</span>
            </Label>
            <Row>
              <Col xs="6" xxl="7" className="pe-5">
                <Row className="my-3">
                  <Col xs={4}>
                    <div className="form-check my-1">
                      <Input
                        className="form-check-input"
                        type="checkbox"
                        id="payment-history"
                        onChange={handleMainFilterChange}
                      />
                      <Label className="form-check-label text-nowrap" htmlFor="payment-history">
                        Payment History
                      </Label>
                    </div>

                    <div className="form-check my-1">
                      <Input className="form-check-input" type="checkbox" id="sales-history" checked />
                      <Label className="form-check-label text-nowrap" htmlFor="sales-history">
                        Sales History
                      </Label>
                    </div>

                    {/* <div className="form-check my-1">
                      <Input className="form-check-input" type="checkbox" id="cashback-award" disabled />
                      <Label className="form-check-label text-nowrap" htmlFor="cashback-award">
                        Cashback Award
                      </Label>
                    </div> */}

                    <div className="form-check my-1">
                      <Input
                        className="form-check-input"
                        type="checkbox"
                        id="sales-by-highest-order"
                        onChange={handleMainFilterChange}
                      />
                      <Label className="form-check-label text-nowrap" htmlFor="sales-by-highest-order">
                        Sales by highest Order
                      </Label>
                    </div>
                  </Col>
                  <Col xs={4}>
                    <Row className="my-1">
                      <Col xs="12">
                        <Label className="mb-1">Date From</Label>
                        <Input type="date" className="form-control py-1" name="startDate" value={filters.startDate} onChange={filtersChngHndlr} />
                      </Col>
                    </Row>
                    <Row className="mb-1">
                      <Col xs="12">
                        <Label className="mb-1">Date To</Label>
                        <Input type="date" className="form-control py-1" name="endDate" value={filters.endDate} onChange={filtersChngHndlr} />
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={4}>
                    <Row className="my-1">
                      <Col xs="12">
                        <Label className="mb-1">Customer</Label>
                        <Input type="text" className="form-control py-1" />
                      </Col>
                    </Row>
                    <Row className="my-1">
                      <Col xs="12">
                        <Label className="mb-1">Group</Label>
                        <Input type="text" className="form-control py-1" />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
              <Col xs="2" className="pt-3">
                <div className="form-check my-1">
                  <Input className="form-check-input" type="checkbox" id="formCheck6" />
                  <Label className="form-check-label text-nowrap" htmlFor="formCheck6">
                    Daily
                  </Label>
                </div>
                <div className="form-check mb-1">
                  <Input className="form-check-input" type="checkbox" id="formCheck6" />
                  <Label className="form-check-label text-nowrap" htmlFor="formCheck6">
                    Daily by Station
                  </Label>
                </div>
                <div className="form-check mb-1">
                  <Input className="form-check-input" type="checkbox" id="formCheck6" />
                  <Label className="form-check-label text-nowrap" htmlFor="formCheck6">
                    Details Monthly
                  </Label>
                </div>
                <div className="form-check mb-1">
                  <Input className="form-check-input" type="checkbox" id="formCheck6" />
                  <Label className="form-check-label text-nowrap" htmlFor="formCheck6">
                    Total Monthly
                  </Label>
                </div>
                <div className="form-check mb-1">
                  <Input className="form-check-input" type="checkbox" id="formCheck6" />
                  <Label className="form-check-label text-nowrap" htmlFor="formCheck6">
                    Total Weekly
                  </Label>
                </div>
              </Col>
              <Col xs="4" xxl="3" className="d-flex flex-column justify-content-center pe-xxl-5 pe-4">
                <div className="d-flex align-items-center w-100 mb-1">
                  <div className="sale-wh-wrapper flex-grow-1 mt-0 me-xxl-4 me-2">
                    <div className="d-flex justify-content-around border border-section py-2 h-100">
                      {actions(togglePrintModal, getSalesHistoryReportApiCall,resetFilters).map((item) => {
                        return (
                          <div
                            key={item.id}
                            className="action-icon-container"
                            onClick={item.clickHandler || (() => {})}
                            style={{cursor:"pointer"}}
                          >
                            <img className="action-icon" src={item.icon} />
                            <span>{item.label}</span>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <div
                    className="d-flex flex-column align-items-center"
                    style={{ cursor: "pointer" }}
                    onClick={() => history.push("/reportsmenu")}
                  >
                    <img src={BackIcon} width="32" height="32" alt="back-icon" />
                    <div className="text-decoration-underline mb-0">Go Back</div>
                  </div>
                </div>
                <div className="d-flex justify-content-start">
                  <Button color="light" className="border border-dark me-2 text-nowrap wh-btn">
                    Customer Balance
                  </Button>
                  <Button color="light" className="border border-dark text-nowrap wh-btn">
                    Balance Sheet
                  </Button>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
        <Col xs="12">
          <WhTableContainer
            columns={columns}
            data={tableData || []}
            className="custom-header-css"
            divClass="table-responsive table-container-sales-history"
            tableClass="align-middle table-bordered"
            theadClass="table-light"
            handlePageChange={handlePageChange}
            pageCount={pageCount || 0}
            customPageSize={30}
            showNoDataComponent={true}
          />
        </Col>
      </Row>
      <ApiLoader loading={getSalesHistoryReportLoading} />
    </Container>
     <Modal
     id="showPrintModal"
     isOpen={showPrintModal}
     toggle={togglePrintModal}
     centered
     size="lg"
   >
     <F12CloseModalListener onClose={togglePrintModal} />
     <ModalHeader className="py-2 px-3" toggle={togglePrintModal}>
       Print Options
     </ModalHeader>
     <Form
       onSubmit={(e) => {
         e.preventDefault();
       }}
     >
       <ModalBody className="px-0 pt-0">
         <PrintItem
           getSalesHistoryReportApiCall={getSalesHistoryReportApiCall}
           filters={filters}
           showPrintModal={showPrintModal}
         />
       </ModalBody>
     </Form>
   </Modal>
   </>
  );
};

export default SalesHistory;
