import inventoryconstants from "./inventoryconstants";

export const getInventoryListAct = (apiEndpoint, payload, optionalConfig) => {
  return {
    type: inventoryconstants.GET_INVENTORY_LIST_REQUEST,
    apiEndpoint,
    payload,
    optionalConfig,
  };
};

export const changeGetInventoryListLoadingStatusAct = (status) => {
  return {
    type: inventoryconstants.CHANGE_GET_INVENTORY_LIST_LOADING_STATUS,
    payload: status,
  };
};

export const getInventoryAllMasterDataAct = (apiEndpoint, payload, optionalConfig) => {
  return {
    type: inventoryconstants.GET_INVENTORY_ALL_MASTER_DATA_REQUEST,
    apiEndpoint,
    payload,
    optionalConfig,
  };
};

export const createInventoryItemAct = (apiEndpoint, payload, optionalConfig) => {
  return {
    type: inventoryconstants.CREATE_INVENTORY_ITEM_REQUEST,
    apiEndpoint,
    payload,
    optionalConfig,
  };
};

export const getInventoryBundlesListAct = (apiEndpoint, payload, optionalConfig) => {
  return {
    type: inventoryconstants.GET_INVENTORY_BUNDLES_LIST_REQUEST,
    apiEndpoint,
    payload,
    optionalConfig,
  };
};

export const changeGetInventoryBundlesListLoadingStatusAct = (status) => {
  return {
    type: inventoryconstants.CHANGE_GET_INVENTORY_BUNDLES_LIST_LOADING_STATUS,
    payload: status,
  };
};

export const createBundlesAct = (apiEndpoint, payload, optionalConfig) => {
  return {
    type: inventoryconstants.CREATE_BUNDLES_REQUEST,
    apiEndpoint,
    payload,
    optionalConfig,
  };
};

export const getInventoryItemAct = (apiEndpoint, payload, optionalConfig) => {
  return {
    type: inventoryconstants.GET_INVENTORY_ITEM_REQUEST,
    apiEndpoint,
    payload,
    optionalConfig,
  };
};

export const getBundleItemAct = (apiEndpoint, payload, optionalConfig) => {
  return {
    type: inventoryconstants.GET_BUNDLE_ITEM_REQUEST,
    apiEndpoint,
    payload,
    optionalConfig,
  };
};

export const updateInventoryItemAct = (apiEndpoint, payload, optionalConfig) => {
  return {
    type: inventoryconstants.UPDATE_INVENTORY_ITEM_REQUEST,
    apiEndpoint,
    payload,
    optionalConfig,
  };
};

export const updateBundleItemAct = (apiEndpoint, payload, optionalConfig) => {
  return {
    type: inventoryconstants.UPDATE_BUNDLE_ITEM_REQUEST,
    apiEndpoint,
    payload,
    optionalConfig,
  };
};

export const deleteInventoryItemAct = (apiEndpoint, payload, optionalConfig) => {
  return {
    type: inventoryconstants.DELETE_INVENTORY_ITEM_REQUEST,
    apiEndpoint,
    payload,
    optionalConfig,
  };
};

export const deleteBundleItemAct = (apiEndpoint, payload, optionalConfig) => {
  return {
    type: inventoryconstants.DELETE_BUNDLE_ITEM_REQUEST,
    apiEndpoint,
    payload,
    optionalConfig,
  };
};

export const getPurchaseOrdersAct = (apiEndpoint, payload, optionalConfig) => {
  return {
    type: inventoryconstants.GET_PURCHASE_ORDERS_REQUEST,
    apiEndpoint,
    payload,
    optionalConfig,
  };
};

export const getSuppliersOfGivenItemAct = (apiEndpoint, payload, optionalConfig) => {
  return {
    type: inventoryconstants.GET_SUPPLIERS_OF_GIVEN_ITEM_REQUEST,
    apiEndpoint,
    payload,
    optionalConfig,
  };
};

export const getSalesOfGivenItemAct = (apiEndpoint, payload, optionalConfig) => {
  return {
    type: inventoryconstants.GET_SALES_OF_GIVEN_ITEM_REQUEST,
    apiEndpoint,
    payload,
    optionalConfig,
  };
};

export const getMaxInventoryNumAct = (apiEndpoint, payload, optionalConfig) => {
  return {
    type: inventoryconstants.GET_MAX_INVENTORY_NUM_REQUEST,
    apiEndpoint,
    payload,
    optionalConfig,
  };
};

export const createCustomerInventoryPriceAct = (apiEndpoint, payload, optionalConfig) => {
  return {
    type: inventoryconstants.CREATE_CUSTOMER_INVENTORY_PRICE_REQUEST,
    apiEndpoint,
    payload,
    optionalConfig,
  };
};

export const getAdjustmentHistoryOfItemAct = (apiEndpoint, payload, optionalConfig) => {
  return {
    type: inventoryconstants.GET_ADJUSTMENT_HISTORY_OF_ITEM_REQUEST,
    apiEndpoint,
    payload,
    optionalConfig,
  };
};

export const createQtyAdjustmentAct = (apiEndpoint, payload, optionalConfig) => {
  return {
    type: inventoryconstants.CREATE_QTY_ADJUSTMENT_REQUEST,
    apiEndpoint,
    payload,
    optionalConfig,
  };
};

export const createMultiQtyAdjustmentAct = (apiEndpoint, payload, optionalConfig) => {
  return {
    type: inventoryconstants.CREATE_MULTI_QTY_ADJUSTMENT_REQUEST,
    apiEndpoint,
    payload,
    optionalConfig,
  };
};

export const getSpecialPricesAct = (apiEndpoint, payload, optionalConfig) => {
  return {
    type: inventoryconstants.GET_SPECIAL_PRICES_REQUEST,
    apiEndpoint,
    payload,
    optionalConfig,
  };
};

export const activateInventoryItemAct = (apiEndpoint, payload, optionalConfig) => {
  return {
    type: inventoryconstants.ACTIVATE_INVENTORY_ITEM_REQUEST,
    apiEndpoint,
    payload,
    optionalConfig,
  };
};

export const changeInventoryApiLoadingStatusAct = (status) => {
  return {
    type: inventoryconstants.CHANGE_INVENTORY_API_LOADING_STATUS,
    payload: status,
  };
};

export const getMaxBundleNoAct = (apiEndpoint, payload, optionalConfig) => {
  return {
      type:inventoryconstants.GET_MAX_BUNDLE_NO_REQUEST,
      apiEndpoint,
      payload,
      optionalConfig
  }
};