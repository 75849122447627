import React from 'react';
import { Input, Table } from 'reactstrap';

const InvoiceCalculationsSidebar = ({invDetailsValidation, status, getSalesmanListResp, getMasterDataForSupplierResp, getMasterDataByCategoryResp, getDriversListResp, totalWeight, totalItems,thdStyle}) => {
    return ( 
    <div className="table-responsive">
        <Table className="table-bordered align-middle table-nowrap mb-0">
        <thead className='wh-table-head'>
            <tr>
            <th className="py-1" style={{ width: "16.67%", }}>
                Salesman
            </th>
            <th className="py-1" style={{ width: "16.67%",}}>
                Payment Terms
            </th>
            <th className="py-1" style={{ width: "16.67%" ,}}>
                Shipped Via
            </th>
            <th className="py-1" style={{ width: "16.67%",}}>
                Driver
            </th>
            <th className="py-1" style={{ width: "16.67%",}}>
                Weight
            </th>
            <th className="py-1" style={{ width: "16.67%", }}>
                Count
            </th>
            </tr>
        </thead>
        <tbody >
            <tr  >
            <td className="fw-medium p-0 ">
                <Input
                type="select"
                className="border-0 py-1"
                id="salesman_id"
                name="salesman_id"
                value={invDetailsValidation.values.salesman_id}
                onChange={invDetailsValidation.handleChange}
                disabled={status === "posted"}
                >
                <option value="">Select</option>
                {(getSalesmanListResp?.data.salesman || []).map((item) => (
                    <option value={item.id} key={item.id}>
                    {item.salesman}
                    </option>
                ))}
                </Input>
            </td>
            <td className="fw-medium p-0" >
                <Input
                type="select"
                className="border-0 py-1"
                id="paymentTerms"
                name="paymentTerms"
                value={invDetailsValidation.values.paymentTerms}
                onChange={invDetailsValidation.handleChange}
                disabled={status === "posted"}
                >
                <option value="">Select</option>
                {(getMasterDataForSupplierResp?.data?.paymentTerms || []).map((item) => (
                    <option key={item} value={item}>
                    {item}
                    </option>
                ))}
                </Input>
            </td>
            <td className="fw-medium p-0" >
                <Input
                type="select"
                className="border-0 py-1"
                id="shippedVia"
                name="shippedVia"
                value={invDetailsValidation.values.shippedVia}
                onChange={invDetailsValidation.handleChange}
                disabled={status === "posted"}
                >
                <option value="">Select</option>
                {(getMasterDataByCategoryResp?.data?.shippedVia || []).map((item) => (
                    <option key={item} value={item}>
                    {item}
                    </option>
                ))}
                </Input>
            </td>
            <td className="fw-medium p-0" >
                <Input
                type="select"
                className="border-0 py-1"
                id="driverName"
                name="driverName"
                value={invDetailsValidation.values.driverName}
                onChange={invDetailsValidation.handleChange}
                disabled={status === "posted"}
                >
                <option value="">Select</option>
                {(getDriversListResp?.data.drivers || []).map((item) => (
                    <option value={item.id} key={item.id}>
                    {item.name}
                    </option>
                ))}
                </Input>
            </td>
            <td className={`fw-medium px-2 py-0 ${status === "posted" ? "wh-body-bg" : ""} `} >
                {totalWeight.toFixed(2)}
            </td>
            <td className={`fw-medium px-2 py-0  ${status === "posted" ? "wh-body-bg" : ""} `}>
                {totalItems}
            </td>
            </tr>
        </tbody>
        </Table>
    </div> );
}
 
export default InvoiceCalculationsSidebar;