import React, { useEffect, useRef, useState, useContext } from 'react';
import { Modal, ModalBody, ModalHeader} from "reactstrap";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { Switch, Route, useHistory } from "react-router-dom";

//Layouts
import NonAuthLayout from "../Layouts/NonAuthLayout";
import BlankLayout from "../Layouts/BlankLayout";
import WarehouseLayout from "../Layouts/WarehouseLayout";
import WarehouseAdminLayout from "../Layouts/WarehouseAdminLayout";
//routes
import { warehousePublicRoutes, authWarehouseBlankRoutes, authWareHouseRoutes, adminWareHouseRoutes } from "./allRoutes";
import { AccessRoute, AuthProtected } from './AuthProtected';
import { saveLoginDetails, logoutUser } from '../store/actions';
//Context
import { PermissionsContext } from "../context/PermissionsContext";
import { generateApiUrl } from "../api/apihelper";
import { ModalFooter } from 'reactstrap';

const inactiveTimeLimit = 600;
const warningTimerLimit = 60;

const Index = () => {
    const wareHousePublicRoutesPaths = warehousePublicRoutes.map((r) => r.path);
    const authWarehouseBlankRoutesPath = authWarehouseBlankRoutes.map((r) => r.path);
    const authWarehouseRoutePath = authWareHouseRoutes.map((r) => r.path);
    const adminWarehouseRoutePath = adminWareHouseRoutes.map((r) => r.path);
    const [inactiveTimer, setInactiveTimer] = useState(inactiveTimeLimit);
    const [showTimeoutWarningModal, setShowTimeoutWarningModal] = useState(false);
    const interval = useRef(null);
    const dispatch = useDispatch();
    const { handleLogout } = useContext(PermissionsContext);
    const handleLogoutUser = () => {
        dispatch(logoutUser(generateApiUrl('logout_user')));
        handleLogout();
    }
    const history = useHistory();
    const {Login : { loginDetails }} = useSelector(state => state, shallowEqual);
    useEffect(() => {
      const user = localStorage.getItem("user");
      if(!user && !loginDetails?.user) {
        history.push("/");
      }
    }, [loginDetails]);
    useEffect(() => {
      const token = localStorage.getItem("token");
      const tokenExpiresAt = localStorage.getItem("tokenExpiresAt");
      const refreshToken = localStorage.getItem("refreshToken");
      const user = localStorage.getItem("user");
      if(user) {
        dispatch(saveLoginDetails({
          token,
          tokenExpiresAt,
          refreshToken,
          user:JSON.parse(user)
        }));
      }
    }, []);
    useEffect(() => {
      function handleUserActivity() {
        setInactiveTimer(inactiveTimeLimit);
      }
      function handleEnterWhenWarning(e) {
        if(e.key === "Enter") {
          setInactiveTimer(inactiveTimeLimit);
          setShowTimeoutWarningModal(false);
        }
      }
      if(loginDetails?.user && inactiveTimer !== 0) {
        if(!interval.current) {
          interval.current = setInterval(() => {
            setInactiveTimer(prev => prev > 0 ? prev - 1 : prev);
          }, 1000);
        }
        if(!showTimeoutWarningModal) {
          document.addEventListener("mousemove", handleUserActivity);
          document.addEventListener("keydown", handleUserActivity);
          document.removeEventListener("keydown", handleEnterWhenWarning);
        } else {
          document.removeEventListener("mousemove", handleUserActivity);
          document.removeEventListener("keydown", handleUserActivity);
          document.addEventListener("keydown", handleEnterWhenWarning);
        }
      } else {
        setInactiveTimer(inactiveTimeLimit);
        clearInterval(interval.current);
        setShowTimeoutWarningModal(false);
        interval.current = null;
      }
      return () => {
        document.removeEventListener("mousemove", handleUserActivity);
        document.removeEventListener("keydown", handleUserActivity);
        document.removeEventListener("keydown", handleEnterWhenWarning);
      }
    }, [loginDetails, showTimeoutWarningModal]);

    useEffect(() => {
      const {pathname} = history.location;
      if(inactiveTimer === warningTimerLimit) {
        setShowTimeoutWarningModal(true);
      }
      else if(inactiveTimer === 0 && !pathname.includes("/warehousesales/") && !pathname.includes("/warehousepurchaseorders/")) {
        clearInterval(interval.current);
        interval.current = null;
        setShowTimeoutWarningModal(false);
        handleLogoutUser();
      } else if(inactiveTimer === 0) {
        clearInterval(interval.current);
        interval.current = null;
        dispatch(saveLoginDetails({
          ...loginDetails,
          sessionExpired:true
        }));
      }
    }, [inactiveTimer]);
    return (
      <React.Fragment>
        <Switch>
          <Route path={wareHousePublicRoutesPaths}>
              <NonAuthLayout>
                <BlankLayout>
                  <Switch>
                    {warehousePublicRoutes.map((route, idx) => (
                      <Route path={route.path} component={route.component} key={idx} exact={true} />
                    ))}
                  </Switch>
                </BlankLayout>
              </NonAuthLayout>
          </Route>

          <Route path={authWarehouseBlankRoutesPath}>
            <AuthProtected>
            <NonAuthLayout>
              <BlankLayout>
                <Switch>
                  {authWarehouseBlankRoutes.map((route, idx) => (
                    <AccessRoute path={route.path} component={route.component} key={idx} exact={true} />
                  ))}
                </Switch>
              </BlankLayout>
            </NonAuthLayout>
            </AuthProtected>
          </Route>

          <Route path={authWarehouseRoutePath}>
            <AuthProtected>
            <NonAuthLayout>
              <WarehouseLayout>
                <Switch>
                  {authWareHouseRoutes.map((route, idx) => (
                    <AccessRoute path={route.path} component={route.component} key={idx} exact={true} />
                  ))}
                </Switch>
              </WarehouseLayout>
            </NonAuthLayout>
            </AuthProtected>
          </Route>

          <Route path={adminWarehouseRoutePath} exact>
            <AuthProtected>
            <WarehouseAdminLayout>
              <Switch>
                {adminWareHouseRoutes.map((route, idx) => (
                  <AccessRoute path={route.path} component={route.component} key={idx} exact={true} />
                ))}
              </Switch>
            </WarehouseAdminLayout>
            </AuthProtected>
          </Route>
        </Switch>
        <Modal isOpen={showTimeoutWarningModal} centered={true}>
          <ModalHeader className="border-bottom py-2">Session Timeout Warning</ModalHeader>
          <ModalBody>
            Your session is about to expire in {inactiveTimer} seconds.
          </ModalBody>
          <ModalFooter>
              <button
                type="button"
                className={`btn w-sm btn-primary`}
                data-bs-dismiss="modal"
                onClick={() => {
                  setInactiveTimer(inactiveTimeLimit);
                  setShowTimeoutWarningModal(false);
                }}
              >
                Continue Session
              </button>
          </ModalFooter>
        </Modal>
      </React.Fragment>
    );
};

export default Index;