import { takeLatest, call, put, delay } from "redux-saga/effects";
import { getData, postData, putData, deleteData } from "../../api/apiactions";
import { handleApiResponse } from "../../utility/sagautility";
import customerconstants from "./customerconstants";
import { changeCustomerApiLoadingStateAct, changeGetInvoicesLoadingStatusAct, changeGetAllCustomersLoadingStatusAct } from "./customeractions"

function withLoading(fn) {
    return function* withLoadingGenerator(...args) {
        yield put(changeCustomerApiLoadingStateAct(true));
        yield delay(500);
        yield* fn(...args);
        yield put(changeCustomerApiLoadingStateAct(false));
    }
}


function* getAllCustomersGen(action) {
    yield put(changeGetAllCustomersLoadingStatusAct(true));
    const response = yield call(getData,action.apiEndpoint,action.payload,action.optionalConfig);
    yield call(handleApiResponse, response, {
        successAction : customerconstants.GET_ALL_CUSTOMERS_SUCCESS,
        failAction :  customerconstants.GET_ALL_CUSTOMERS_FAIL,
    });
    yield delay(500);
    yield put(changeGetAllCustomersLoadingStatusAct(false));
}

function* getCustomerGen(action) {
    const response = yield call(getData,action.apiEndpoint,action.payload,action.optionalConfig);
    yield call(handleApiResponse, response, {
        successAction : customerconstants.GET_CUSTOMER_SUCCESS,
        failAction :  customerconstants.GET_CUSTOMER_FAIL,
    });
}

function* createCustomerGen(action) {
    const response = yield call(postData,action.apiEndpoint,action.payload,action.optionalConfig);
    yield call(handleApiResponse, response, {
        successAction : customerconstants.CREATE_CUSTOMER_SUCCESS,
        failAction :  customerconstants.CREATE_CUSTOMER_FAIL,
    });
}

function* getMaxCustomerNoGen(action) {
    const response = yield call(getData,action.apiEndpoint,action.payload,action.optionalConfig);
    yield call(handleApiResponse, response, {
        successAction : customerconstants.GET_MAX_CUSTOMER_NO_SUCCESS,
        failAction :  customerconstants.GET_MAX_CUSTOMER_NO_FAIL,
    });
}

function* getMasterDataAllGen(action) {
    const response = yield call(getData,action.apiEndpoint,action.payload,action.optionalConfig);
    yield call(handleApiResponse, response, {
        successAction : customerconstants.GET_MASTER_DATA_ALL_SUCCESS,
        failAction :  customerconstants.GET_MASTER_DATA_ALL_FAIL,
    });
}

function* getMasterDataByCategoryGen(action) {
    const response = yield call(getData,action.apiEndpoint,action.payload,action.optionalConfig);
    yield call(handleApiResponse, response, {
        successAction : customerconstants.GET_MASTER_DATA_BY_CATEGORY_SUCCESS,
        failAction :  customerconstants.GET_MASTER_DATA_BY_CATEGORY_FAIL,
    });
}

function* updateBasicInfoGen(action) {
    const response = yield call(putData,action.apiEndpoint,action.payload,action.optionalConfig);
    yield call(handleApiResponse, response, {
        successAction : customerconstants.UPDATE_BASIC_INFO_SUCCESS,
        failAction :  customerconstants.UPDATE_BASIC_INFO_FAIL,
    });
}

function* updateClassificationGen(action) {
    const response = yield call(putData,action.apiEndpoint,action.payload,action.optionalConfig);
    yield call(handleApiResponse, response, {
        successAction : customerconstants.UPDATE_CLASSIFICATION_SUCCESS,
        failAction :  customerconstants.UPDATE_CLASSIFICATION_FAIL,
    });
}

function* updateBillingInfoGen(action) {
    const response = yield call(putData,action.apiEndpoint,action.payload,action.optionalConfig);
    yield call(handleApiResponse, response, {
        successAction : customerconstants.UPDATE_BILLING_INFO_SUCCESS,
        failAction :  customerconstants.UPDATE_BILLING_INFO_FAIL,
    });
}

function* getPaymentsOfCustomerGen(action) {
    const response = yield call(getData,action.apiEndpoint,action.payload,action.optionalConfig);
    yield call(handleApiResponse, response, {
        successAction : customerconstants.GET_PAYMENTS_OF_CUSTOMER_SUCCESS,
        failAction :  customerconstants.GET_PAYMENTS_OF_CUSTOMER_FAIL,
    });
}

function* getInvoicesOfCustomerGen(action) {
    yield put(changeGetInvoicesLoadingStatusAct(true));
    const response = yield call(getData,action.apiEndpoint,action.payload,action.optionalConfig);
    yield call(handleApiResponse, response, {
        successAction : customerconstants.GET_INVOICES_OF_CUSTOMER_SUCCESS,
        failAction :  customerconstants.GET_INVOICES_OF_CUSTOMER_FAIL,
    });
    yield delay(500);
    yield put(changeGetInvoicesLoadingStatusAct(false));
}

function* deleteCustomerGen(action) {
    const response = yield call(deleteData,action.apiEndpoint,action.payload,action.optionalConfig);
    yield call(handleApiResponse, response, {
        successAction : customerconstants.DELETE_CUSTOMER_SUCCESS,
        failAction :  customerconstants.DELETE_CUSTOMER_FAIL,
    });
}

function* getCustomerAccountBalanceHistoryGen(action) {
    const response = yield call(getData,action.apiEndpoint,action.payload,action.optionalConfig);
    yield call(handleApiResponse, response, {
        successAction : customerconstants.GET_CUSTOMER_ACCOUNT_BALANCE_HISTORY_SUCCESS,
        failAction :  customerconstants.GET_CUSTOMER_ACCOUNT_BALANCE_HISTORY_FAIL,
    });
}

function* createSpecialPriceForCustomerGen(action) {
    const response = yield call(postData,action.apiEndpoint,action.payload,action.optionalConfig);
    yield call(handleApiResponse, response, {
        successAction : customerconstants.CREATE_SPECIAL_PRICE_FOR_CUSTOMER_SUCCESS,
        failAction :  customerconstants.CREATE_SPECIAL_PRICE_FOR_CUSTOMER_FAIL,
    });
}

function* deleteSpecialPriceForCustomerGen(action) {
    const response = yield call(deleteData,action.apiEndpoint,action.payload,action.optionalConfig);
    yield call(handleApiResponse, response, {
        successAction : customerconstants.DELETE_SPECIAL_PRICE_FOR_CUSTOMER_SUCCESS,
        failAction :  customerconstants.DELETE_SPECIAL_PRICE_FOR_CUSTOMER_FAIL,
    });
}

function* acceptPaymentsAnywhereGen(action) {
    const response = yield call(postData,action.apiEndpoint,action.payload,action.optionalConfig);
    yield call(handleApiResponse, response, {
        successAction : customerconstants.ACCEPT_PAYMENTS_ANYWHERE_SUCCESS,
        failAction :  customerconstants.ACCEPT_PAYMENTS_ANYWHERE_FAIL,
    });
}

function* getCustomersInventoryDiscountsGen(action) {
    const response = yield call(postData,action.apiEndpoint,action.payload,action.optionalConfig);
    yield call(handleApiResponse, response, {
        successAction : customerconstants.GET_CUSTOMER_INVENTORY_DISCOUNTS_SUCCESS,
        failAction :  customerconstants.GET_CUSTOMER_INVENTORY_DISCOUNTS_FAIL,
    });
}

export function* customerSaga() {
    yield takeLatest(customerconstants.GET_ALL_CUSTOMERS_REQUEST, getAllCustomersGen);
    yield takeLatest(customerconstants.GET_CUSTOMER_REQUEST, getCustomerGen);
    yield takeLatest(customerconstants.CREATE_CUSTOMER_REQUEST, withLoading(createCustomerGen));
    yield takeLatest(customerconstants.GET_MAX_CUSTOMER_NO_REQUEST, getMaxCustomerNoGen);
    yield takeLatest(customerconstants.GET_MASTER_DATA_ALL_REQUEST, getMasterDataAllGen);
    yield takeLatest(customerconstants.GET_MASTER_DATA_BY_CATEGORY_REQUEST, getMasterDataByCategoryGen);
    yield takeLatest(customerconstants.UPDATE_BASIC_INFO_REQUEST, withLoading(updateBasicInfoGen));
    yield takeLatest(customerconstants.UPDATE_CLASSIFICATION_REQUEST, withLoading(updateClassificationGen));
    yield takeLatest(customerconstants.UPDATE_BILLING_INFO_REQUEST, withLoading(updateBillingInfoGen));
    yield takeLatest(customerconstants.GET_PAYMENTS_OF_CUSTOMER_REQUEST, getPaymentsOfCustomerGen);
    yield takeLatest(customerconstants.GET_INVOICES_OF_CUSTOMER_REQUEST, getInvoicesOfCustomerGen);
    yield takeLatest(customerconstants.DELETE_CUSTOMER_REQUEST, withLoading(deleteCustomerGen));
    yield takeLatest(customerconstants.GET_CUSTOMER_ACCOUNT_BALANCE_HISTORY_REQUEST, getCustomerAccountBalanceHistoryGen);
    yield takeLatest(customerconstants.CREATE_SPECIAL_PRICE_FOR_CUSTOMER_REQUEST, withLoading(createSpecialPriceForCustomerGen));
    yield takeLatest(customerconstants.DELETE_SPECIAL_PRICE_FOR_CUSTOMER_REQUEST, withLoading(deleteSpecialPriceForCustomerGen));
    yield takeLatest(customerconstants.ACCEPT_PAYMENTS_ANYWHERE_REQUEST, withLoading(acceptPaymentsAnywhereGen));
    yield takeLatest(customerconstants.GET_CUSTOMER_INVENTORY_DISCOUNTS_REQUEST, getCustomersInventoryDiscountsGen);
}
