import React, { useMemo } from "react";
import { Col, Container, Row, Label, Input, FormGroup } from 'reactstrap';
import WhTableContainer from "../../../../Components/Common/WhTables";
import {
    generateCustomerInfoColumns, 
    custerInfoTableData,
} from "./helper";

export const FeaturesTab = () => {
    const costomerInfoColumns = useMemo(() => generateCustomerInfoColumns(), []);
    return (
      <React.Fragment>
        <Container fluid className="whbrand-text-color">
          <Row className="px-4 mb-2">
            <Col xs={5}>
              <h6>Promotion</h6>
              <WhTableContainer
                columns={costomerInfoColumns}
                data={custerInfoTableData || []}
                customPageSize={7}
                className="custom-header-css"
                divClass="table-responsive mt-2 whbrand-table"
                tableClass="align-middle table-bordered"
                theadClass="table-light"
              />
            </Col>
          </Row>
          <Row className="px-4 mb-2">
            <Col xs={5}>
              <h6>Promotion Description ( MSA )</h6>
              <div className="d-flex align-items-center">
                <div className="form-check mb-0">
                  <Input className="form-check-input mb-0" type="checkbox" id="formCheck6" defaultChecked />
                </div>
                <Input type="text" className="form-control mb-0" />
              </div>
            </Col>
          </Row>
          <Row className="px-4 mb-2">
            <Col xs={5}>
              <Row>
                <Col xs="6">
                  <FormGroup>
                    <Label className="form-label mb-1 text-nowrap p-0">Manufacturer Promotion Code</Label>
                    <Input className="form-control" type="text" />
                  </FormGroup>
                </Col>
                <Col xs="6">
                  <FormGroup>
                    <Label htmlFor="companyName" className="form-label mb-1 text-nowrap p-0">
                      Sub-Categories
                    </Label>
                    <select className="form-select mb-1" aria-label="Default select example">
                      <option></option>
                    </select>
                  </FormGroup>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col xs="7">
              <div className="border px-4 mb-4" style={{ position: "relative" }}>
                <Label className="fs-5 text-dark" style={{ position: "absolute", transform: "translateY(-50%)" }}>
                <span className=" px-1 wh-item-bg">History</span>
                </Label>
                <Row className="mt-3">
                  <Col xs="4">
                    <Label className="mb-0">Created Date</Label>
                  </Col>
                </Row>
                <Row className="mb-1">
                  <Col xs="4">
                    <Input type="text" className="form-control" />
                  </Col>
                  <Col xs="4" className="d-flex align-items-center">
                    <Label className="mb-0">Cost Changes</Label>
                  </Col>
                  <Col xs="4" className="d-flex align-items-center">
                    <Label className="mb-0">Retail Changes</Label>
                  </Col>
                </Row>
                <Row className="mb-1">
                  <Col xs="4">
                    <Label className="mb-0">Last Used</Label>
                    <Input type="text" className="form-control" />
                  </Col>
                  <Col xs="4" className="d-flex align-items-end">
                    <Input type="text" className="form-control" />
                  </Col>
                  <Col xs="4" className="d-flex align-items-end">
                    <Input type="text" className="form-control" />
                  </Col>
                </Row>
                <Row className="mb-1">
                  <Col xs="4">
                    <Label className="mb-0">Last Sales</Label>
                    <Input type="text" className="form-control" />
                  </Col>
                  <Col xs="4" className="d-flex align-items-end">
                    <Input type="text" className="form-control" />
                  </Col>
                  <Col xs="4" className="d-flex align-items-end">
                    <Input type="text" className="form-control" />
                  </Col>
                </Row>
              </div>
            </Col>
            <Col xs="5" className="px-5">
              <Row className="mb-1">
                <Col xs="4" className="d-flex align-items-center">NPM Brand Code:</Col>
                <Col xs="8">
                  <Input type="text" className="form-control" />
                </Col>
              </Row>
              <Row className="mb-1">
                <Col xs="4" className="d-flex align-items-center">Commission Rate:</Col>
                <Col xs="8">
                  <div className="input-group">
                    <Input type="text" className="form-control" aria-describedby="basic-addon2" />
                    <span className="input-group-text" id="basic-addon2">
                      %
                    </span>
                  </div>
                </Col>
              </Row>
              <Row className="mb-1">
                <Col xs="4" className="d-flex align-items-center">State Tax Jurisdiction :</Col>
                <Col xs="8">
                  <Input type="text" className="form-control" />
                </Col>
              </Row>
              <Row className="mb-1">
                <Col xs="4" className="d-flex align-items-center">Insurance Value:</Col>
                <Col xs="8">
                  <Input type="text" className="form-control" />
                </Col>
              </Row>
              <Row className="mb-1">
                <Col xs="4" className="d-flex align-items-center">Replacement Cost:</Col>
                <Col xs="8">
                  <Input type="text" className="form-control" />
                </Col>
              </Row>
              <Row className="mb-1">
                <Col xs="4" className="d-flex align-items-center">Inventory Date:</Col>
                <Col xs="8">
                  <Input type="text" className="form-control" />
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    );
}