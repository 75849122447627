import React, { useMemo, useState, useEffect, useRef, useCallback } from "react";
import { Col, Container, Row, Button } from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import WhTableContainer from "../../../../Components/Common/WhTables";
import DeleteModal from "../../../../Components/Common/DeleteModal";
import { generateColumns, mapSpecialPrices } from "./specialpriceshelper";
//Actions
import { getSpecialPricesAct, getInventoryListAct } from "../../../../store/inventory/inventoryactions";
import {
  createSpecialPriceForCustomerAct,
  deleteSpecialPriceForCustomerAct,
} from "../../../../store/customer/customeractions";

export default function AddNewItem({
  dispatch,
  generateApiUrl,
  getSpecialPricesResp,
  id,
  getInventoryListResp,
  toggleSpecialPricesModal,
  deleteSpecialPriceForCustomerResp,
  toast,
}) {
  const [generateColumnsTrigger, setGenerateColumnsTrigger] = useState(false);
  const [showPriceDeleteConfirm, setShowPriceDeleteConfirm] = useState(false);
  const specialPricesValidation = useFormik({
    initialValues: [{
      itemNumber: "",
      description: "",
      customerPrice: "",
    }],
    validationSchema: Yup.array().of(
      Yup.object().shape({
        itemNumber: Yup.string().required("Item Number is Required"),
        description: Yup.string().required("Description is Required"),
        customerPrice:  Yup.string().when("itemNumber", {
          is: (itemNumber) => !!itemNumber,
          then : Yup.string().required("Customer price required")
        })
      })
    ),
    onSubmit: () => {
      saveCustomerSpecialPrice();
    },
  });
  const togglePriceDeleteConfirm = () => setShowPriceDeleteConfirm(!showPriceDeleteConfirm);
  const debounceFn = useRef(null);
  const detailsOfPriceToBeDeleted = useRef({ id: "", index: "" });
  const currentSelectItem = useRef({
    index: "",
    key: "",
  });
  useEffect(() => {
    if (id !== "new") dispatch(getSpecialPricesAct(generateApiUrl("get_special_prices"), { customerNumber: id }));
  }, []);
  const prevGetSpecialPricesResp = useRef(getSpecialPricesResp);
  const prevGetInventoryListResp = useRef(getInventoryListResp);
  const prevDeleteSpecialPriceForCustomerResp = useRef(deleteSpecialPriceForCustomerResp);
  useEffect(() => {
    if (getSpecialPricesResp && getSpecialPricesResp !== prevGetSpecialPricesResp.current) {
      mapSpecialPrices(getSpecialPricesResp, specialPricesValidation.setValues);
    } else if (getInventoryListResp && getInventoryListResp !== prevGetInventoryListResp.current) {
      specialPricesValidation.setValues((prev) => {
        const updatedSpecialPrices = [...prev],
          { index, key } = currentSelectItem.current,
          rowData = { ...updatedSpecialPrices[index] },
          listOptions = [];
        (getInventoryListResp?.data?.inventories || []).forEach((item) => {
          const { itemNumber, description, cost } = item;
          listOptions.push({
            itemNumber,
            description,
            cost: `$${cost}`,
          });
        });

        updatedSpecialPrices.splice(index, 1, {
          ...rowData,
          [key === "itemNumberSearch" ? "itemNumberListOptions" : "descriptionListOptions"]: listOptions,
        });
        return updatedSpecialPrices;
      });
    } else if (
      deleteSpecialPriceForCustomerResp &&
      deleteSpecialPriceForCustomerResp !== prevDeleteSpecialPriceForCustomerResp.current
    ) {
      toast.success("Successfully deleted the Customer Special Price");
      specialPricesValidation.setValues((prev) => {
        const updatedSpecialPrices = [...prev];
        updatedSpecialPrices.splice(detailsOfPriceToBeDeleted.current.index, 1);
        detailsOfPriceToBeDeleted.current = { id: "", index: "" };
        return updatedSpecialPrices;
      });
      togglePriceDeleteConfirm();
    }
    prevGetSpecialPricesResp.current = getSpecialPricesResp;
    prevGetInventoryListResp.current = getInventoryListResp;
    prevDeleteSpecialPriceForCustomerResp.current = deleteSpecialPriceForCustomerResp;
  }, [getSpecialPricesResp, getInventoryListResp, deleteSpecialPriceForCustomerResp]);
  const getInventoryListApiCall = useCallback((key, value) => {
    let params = {
      page: 1,
      pageSize: 5,
      [key]: value,
    };
    dispatch(getInventoryListAct(generateApiUrl(`get_inventory_list`), params));
  }, []);
  useEffect(() => {
    return () => {
      clearTimeout(debounceFn.current);
    };
  }, []);
  const addNewRow = () => {
    // setSpecialPrices((prev) => [
    //   ...prev,
    //   {
    //     itemNumber: "",
    //     description: "",
    //     customerPrice: "",
    //   },
    // ]);
    specialPricesValidation.setValues((prev) => [
      ...prev,
      {
        itemNumber: "",
        description: "",
        customerPrice: "",
      },
    ]);
  };
  const handleRowDelete = (id, index) => {
    if (!id) {
      // setSpecialPrices((prev) => {
      //   const updatedSpecialPrices = [...prev];
      //   updatedSpecialPrices.splice(index, 1);
      //   return updatedSpecialPrices;
      // });
      specialPricesValidation.setValues((prev) => {
        const updatedSpecialPrices = [...prev];
        updatedSpecialPrices.splice(index, 1);
        return updatedSpecialPrices;
      });
    } else {
      togglePriceDeleteConfirm();
      detailsOfPriceToBeDeleted.current = {
        id,
        index,
      };
    }
  };
  const handlePriceDelete = () => {
    dispatch(
      deleteSpecialPriceForCustomerAct(generateApiUrl("delete_special_price_for_customer"), {
        priceIds: [detailsOfPriceToBeDeleted.current.id],
      })
    );
  };
  function selectItemHndlr(itemDetails, index) {
    const { itemNumber, description } = itemDetails;
    specialPricesValidation.setValues((prev) => {
      const updatedSpecialPrices = [...prev],
        rowData = { ...updatedSpecialPrices[index] };
      updatedSpecialPrices.splice(index, 1, {
        ...rowData,
        itemNumber,
        description,
      });
      return updatedSpecialPrices;
    });
  }
  const searchValueChngHndlr = (value, index, key) => {
    specialPricesValidation.setValues((prev) => {
      const updatedSpecialPrices = [...prev],
        rowData = { ...updatedSpecialPrices[index] };
      updatedSpecialPrices.splice(index, 1, {
        ...rowData,
        [key]: value,
      });
      debounceFn.current = setTimeout(() => {
        getInventoryListApiCall(key === "itemNumberSearch" ? "itemNumber" : "description", value);
      }, 400);
      currentSelectItem.current = {
        key,
        index,
      };
      return updatedSpecialPrices;
    });
  };
  const handleCustomerPriceChange = (e, index) => {
    const { value } = e.target;
    specialPricesValidation.setValues((prev) => {
      const updatedSpecialPrices = [...prev],
        rowData = { ...updatedSpecialPrices[index] };
      updatedSpecialPrices.splice(index, 1, {
        ...rowData,
        customerPrice: value,
      });
      return updatedSpecialPrices;
    });
  };
  const handleSpecialPriceBlur = (index) => {
    setGenerateColumnsTrigger(!generateColumnsTrigger);
    specialPricesValidation.setValues((prev) => {
      const updatedSpecialPrices = [...prev],
        rowData = { ...updatedSpecialPrices[index] };
      updatedSpecialPrices.splice(index, 1, {
        ...rowData,
        customerPrice: rowData.customerPrice !== "" ? Number(rowData.customerPrice).toFixed(2) : "",
      });
      return updatedSpecialPrices;
    });
  };
  const saveCustomerSpecialPrice = () => {
    const payload = [];
    specialPricesValidation.values.forEach((item) => {
      const { itemNumber, customerPrice } = item;
      if (!item.id && itemNumber && customerPrice) payload.push({ itemNumber, specialPrice: customerPrice });
    });
    if (payload.length > 0)
      dispatch(
        createSpecialPriceForCustomerAct(generateApiUrl("create_special_price_for_customer", { customerNumber: id }), {
          items: payload,
        })
      );
    else toggleSpecialPricesModal();
  };
  const columns = useMemo(
    () => generateColumns(selectItemHndlr, searchValueChngHndlr, handleCustomerPriceChange, handleRowDelete, handleSpecialPriceBlur, specialPricesValidation),
    [generateColumnsTrigger]
  );
  return (
    <Container fluid className="px-0">
      <Row className="m-0 px-0">
        <Col xs={12} className="bg-primary text-center fs-5 text-white py-2 px-0">
          Customer Special Price
        </Col>
      </Row>
      {/* <div className="d-flex justify-content-end">
        <Button color="primary" outline>
          + Add New
        </Button>
      </div> */}
      <Row>
        <Col xs={12}>
          <WhTableContainer
            columns={columns}
            data={specialPricesValidation.values}
            customPageSize={7}
            className="custom-header-css"
            divClass="table-responsive overflow-visible"
            tableClass="align-middle"
            theadClass="table-light"
          />
        </Col>
        <Col xs="12 px-4">
          <Button color="primary" className="wh-btn" outline onClick={addNewRow}>
            + Add New
          </Button>
        </Col>
      </Row>
      <div className="d-flex justify-content-center">
        <Button color="primary" className="me-2 d-flex align-items-center wh-btn" outline disabled={!specialPricesValidation.values.length} onClick={(e) => {
          setGenerateColumnsTrigger(!generateColumnsTrigger);
          specialPricesValidation.handleSubmit(e);
        }}>
          <i className="ri-save-line fs-5 me-1"></i> Save
        </Button>
        <Button color="danger" className="d-flex align-items-center wh-btn-danger" outline onClick={toggleSpecialPricesModal}>
          <i className="ri-close-circle-line fs-5 me-1"></i> Close
        </Button>
      </div>
      <DeleteModal
        show={showPriceDeleteConfirm}
        onCloseClick={togglePriceDeleteConfirm}
        onDeleteClick={handlePriceDelete}
      />
    </Container>
  );
}
