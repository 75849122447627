export function generateCustomerInfoColumns() {
    return (
        [
            {
                Header: "Item Number",
                accessor: "customerBalance"
            },
            {
                Header: "Description",
                accessor: "accurateBalance"
            }, {
                Header: "Quantity",
                accessor: "dateTime"
            }
        ]
    )
}
export const custerInfoTableData = [
    {
        customerBalance: "",
        accurateBalance: "",
        dateTime: ""
    },
]

