import React, {useRef,useState} from 'react'
import { Container,Row,Col,Input,Modal,ModalHeader,ModalBody,Form, Button } from 'reactstrap';
import { Link, withRouter,useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { shallowEqual } from 'react-redux';
import { generateApiUrl } from '../../../api/apihelper';
import { getInventoryBundlesListAct } from '../../../store/inventory/inventoryactions';
import { useDispatch } from 'react-redux';
import { useCallback } from 'react';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import TableLoader from '../../../Components/Common/TableLoader';
import { generateBundlesColumns } from './helper';
import NoDataComponent from '../../../Components/Common/NoDataComponent';

function Bundles({
  setIsBlocked,
  parentRef,
  setRowSelectedIds,
  search,
  setSearchValue,
  setSearchListOptions,
  setColumnsList,
  dropdownBtnClicked,
  toggleDeleteModal,
  isRouteChangeApiCalled
}) {
    const [bundlesList,setBundlesList] = useState([]);
    const [selectedBundles, setSelectedBundles] = useState([]);
    const [selectAllBundles,setSelectAllBundles] = useState(false);
    const dispatch = useDispatch();
    const history = useHistory();
    const {
    
        getInventoryBundlesListLoading,
        getInventoryBundlesListResp,
        inventoryErrorResp,
        deleteBundleItemResp
      } = useSelector(
        ({ inventory }) => ({
        getInventoryBundlesListResp: inventory.getInventoryBundlesListResp,
        deleteBundleItemResp: inventory.deleteBundleItemResp,
        getInventoryBundlesListLoading : inventory.getInventoryBundlesListLoading,
        inventoryErrorResp: inventory.inventoryErrorResp,
        }),
        shallowEqual
      );
      const inventoryResp = useRef({
        prevGetInventoryBundlesListResp:getInventoryBundlesListResp,
        prevInventoryErrorResp: inventoryErrorResp,
        prevDeleteBundleItemResp: deleteBundleItemResp
        
      });
      useEffect(() => {
        const { prevGetInventoryBundlesListResp, prevInventoryErrorResp, prevDeleteBundleItemResp } =
          inventoryResp.current || {};
        if (getInventoryBundlesListResp && prevGetInventoryBundlesListResp !== getInventoryBundlesListResp) {
          const list = [];
          (getInventoryBundlesListResp?.data?.bundles || []).forEach(({ title , id}) => {
            list.push({
              title,
              id
            });
          });
          setBundlesList(list);
          setSearchListOptions(list);
          if(!dropdownBtnClicked.current){
            setBundlesList(getInventoryBundlesListResp?.data?.bundles || [])
          }
          setIsBlocked(false);
          if(isRouteChangeApiCalled.current){
            isRouteChangeApiCalled.current=false;
          }
  
        }
        if (deleteBundleItemResp && prevDeleteBundleItemResp !== deleteBundleItemResp) {
          toast.success("Successfully deleted bundle items");
          getInventoryBundlesListApiCall(true);
        }
        // if (inventoryErrorResp && prevInventoryErrorResp !== inventoryErrorResp) {
        //   const { errors, error, message } = inventoryErrorResp || {};
        //   toast.error((Array.isArray(errors) && errors[0] && errors[0].msg) || error || message);
        // }
        inventoryResp.current.prevGetInventoryBundlesListResp = getInventoryBundlesListResp;
        inventoryResp.current.prevDeleteBundleItemResp = deleteBundleItemResp;
        inventoryResp.current.prevInventoryErrorResp = inventoryErrorResp;
      }, [getInventoryBundlesListResp, inventoryErrorResp, deleteBundleItemResp]);
      const getInventoryBundlesListApiCall = useCallback(
        (showAllSuppliers, page) => {
          if (showAllSuppliers) {
            let params = {
              page: page || 1,
              pageSize: 50,
            };
            dispatch(getInventoryBundlesListAct(generateApiUrl(`get_inventory_bundles_list`), params));

            dropdownBtnClicked.current = true;
          } 
          else if (search) {
            let params = {
              page: 1,
              pageSize: 50,
            };
            if (isNaN(Number(search))) params.title = search;
            dispatch(getInventoryBundlesListAct(generateApiUrl(`get_inventory_bundles_list`), params));
            dropdownBtnClicked.current = false;
          }
        },
        [search]
      );
      useEffect(()=> {
        getInventoryBundlesListApiCall(true);
        setColumnsList(generateBundlesColumns)
      },[])
      useEffect(()=>{
        if (parentRef) {
          parentRef.current = { getInventoryBundlesListApiCall };
        }
      },[parentRef])
      useEffect(() => {
        const debounceFn = setTimeout(() => {
          getInventoryBundlesListApiCall();
        }, 200);
        return () => {
          clearTimeout(debounceFn);
        };
      }, [getInventoryBundlesListApiCall]);
      const handleCheckboxChange = (bundleId) => {
        setSelectedBundles(prevItems => {
          if (prevItems.includes(bundleId)) {
              setSelectAllBundles(false);
              return prevItems.filter(id => id !== bundleId);
          } else {
              return [...prevItems, bundleId];
          }
          
      });
      

    };
    const handleSelectAllChange = (isChecked)=>{
      if(isChecked){
        const bundlesListId = bundlesList.map(item => item.id);
        setSelectAllBundles(true);
        setSelectedBundles(bundlesListId)
      }else{
        setSelectAllBundles(false);
        setSelectedBundles([]);
      }
    }
    useEffect(()=>{
      if(selectedBundles.length > 0){
        if(selectedBundles.length === bundlesList.length){
          setSelectAllBundles(true);
        }
        setRowSelectedIds(selectedBundles);
      }else{
        setRowSelectedIds(selectedBundles);
      }
    },[selectedBundles])
    useEffect(()=>{
      setSelectAllBundles(false)
    },[deleteBundleItemResp])

    useEffect(() => {
      if (history.location?.state?.showCreateBundleItemSuccess) {
        toast.success("Successfully saved the bundle details");
        history.replace();
      } 
    }, []);

  return (
    <Container fluid className={'px-4'}>
        <Row className={'justify-content-center px-3 py-4'} style={{backgroundColor:'#ededed'}}>
            <Col xs='12' className={'wh-bundles-container'}>
                {!getInventoryBundlesListLoading && (
                    <ul>
                      <li
                        className='wh-bundle-title-bg px-5 py-2 my-1 d-flex flex-row justify-content-start align-items-center' style={{minHeight:'10px'}}>
                          <label className="checkbox checkbox-primary ml-2 mb-0 pe-3">
                              <input
                                  type="checkbox"
                                  onChange={(e) => handleSelectAllChange(e.target.checked)}
                                  checked={selectAllBundles}
                                  style={{height:'17px',width:'17px'}}
                              />
                              
                          </label>
                          <h4 className='align-self-stretch flex-grow-1 text-center'> Title </h4>
                      </li>
                    {bundlesList.length > 0 ? (bundlesList.map((item,index)=>(
                        <li  key={index}
                        className='wh-bundle-bg px-5 py-2 my-1 d-flex flex-row justify-content-start align-items-center' style={{minHeight:'10px'}}>
                          <label className="checkbox checkbox-primary ml-2 mb-0">
                              <input
                                  type="checkbox"
                                  onChange={() => handleCheckboxChange(item.id)}
                                  checked={selectedBundles.includes(item.id)}
                                  style={{height:'17px',width:'17px'}}
                              />
                              
                          </label>
                        <Link
                        className={'align-self-stretch flex-grow-1 text-center'}
                        to={`/warehouseinventory/bundles-createbundle/${item.id}`}
                        >
                            {item.title}
                        </Link>
                    </li>
                    ))):(
                      <div className='py-5'>
                        <NoDataComponent />
                      </div>
                    )} 
                    </ul>
                )}
                {getInventoryBundlesListLoading && (
                    <TableLoader />
                )}
            </Col>
        </Row>
    </Container>
  )
}

export default Bundles;
