import React from 'react';
import {Modal, ModalHeader, ModalBody, Form} from "reactstrap";
import PrintItem from './print';
import F12CloseModalListener from '../../../Common/F12close';

const PrintModal = ({isOpen, toggle }) => {
    return ( 
    <Modal id="showPrintModal" isOpen={isOpen} toggle={toggle} centered size="lg">
         <F12CloseModalListener onClose={toggle} />
        <ModalHeader className="py-2 px-3" toggle={toggle}>
        Print Invoice Dialog
        </ModalHeader>
        <Form
            onSubmit={(e) => {
                e.preventDefault();
            }}
        >
        <ModalBody className="px-0 pt-0">
            <PrintItem />
        </ModalBody>
        </Form>
    </Modal> );
}
 
export default PrintModal;