export const tabs = [
    {
        id:"company_profile",
        name:"Company Profile"
    },
    {
        id:"bank_details",
        name:"Bank Details"
    },
    {
        id:"code_prefixes",
        name:"Code Prefixes"
    },
]