import { Link } from "react-router-dom";
//Utility
import { getMmddyyyyDateFormatted } from "../../utility/dateutility"
//Constants
import { currencySymbol } from "../../constants";
export function generateColumns(page) {
    return [
        {
            Header: "Invoice No",
            accessor: "invoiceNumber",
            Cell:({cell:{value}}) => <Link to={`/warehousesales/${value}`}>{value}</Link>
        }, 
        {
            Header: "Invoice Date",
            accessor: "invoiceDate",
            Cell:({cell:{value}}) => value ? getMmddyyyyDateFormatted(value) : "-"
        }, 
        {
            Header: "Salesman",
            accessor: "salesman.name",
            Cell:({cell:{value}}) => value || "-"
        }, 
    ]
}
