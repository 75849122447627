import React, { useEffect } from 'react';
import { Redirect, withRouter, useLocation } from 'react-router-dom';

//redux
import { useSelector } from "react-redux";
import { useProfile } from "../Components/Hooks/UserHooks"

const NonAuthLayout = ({ children }) => {
    const location = useLocation();
    const {
        layoutModeType,
    } = useSelector(state => ({
        layoutModeType: state.Layout.layoutModeType,
    }));
    const { userProfile, loading } = useProfile();
    useEffect(() => {
        if (layoutModeType === "dark") {
            document.body.setAttribute("data-layout-mode", "dark");
        } else {
            document.body.setAttribute("data-layout-mode", "light");
        }
    }, [layoutModeType]);
    const userInfo = JSON.parse(localStorage.getItem("user"));
    if (userProfile && !loading && userInfo?.isActive && location.pathname === "/warehouselogin") {
        return (
        <Redirect to={{ pathname: "/warehousemenu", state: { from: location.pathname } }} />
        );
    }
    return (
        <div>
            {children}
        </div>
    );
};

export default withRouter(NonAuthLayout);