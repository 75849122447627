import React, { useEffect, useState } from 'react';
import { Label } from 'reactstrap';
import SelectItem from "../../../Components/Common/SelectItem";
//helper
import { customerColumnsList } from "./helper";

const SearchCustomerBox = ({
  search, 
  setSearch, 
  searchListOptions, 
  setSearchListOptions,
  selectItemCallbck, 
  getAllCustomersApiCall, 
  status, 
  currentCustomerListPage, 
  callbackOnCustomerListApiResp,
  outerDivClassName = 'py-3',
  boldText,
}) => {
  const [customerNumberId,setCustomerNumberId] = useState('')
  function onDoubleClick() {
    const newWindow = window.open();
    newWindow.location = `/warehousecustomers/${customerNumberId}`;
  }
  useEffect(() => {
    if (searchListOptions.length === 1) {
      const [{ customerNumber }] = searchListOptions;
      setCustomerNumberId(customerNumber); 
    }
  }, [searchListOptions])
  
    return (
      <div className={`d-flex justify-content-between align-items-end border border-section px-3 ${outerDivClassName}`}>
        <div className="w-100">
          <Label htmlFor="userrole" className="form-label mb-1" style={{...boldText}}>
            Sold To
          </Label>
          <div className="d-flex align-items-center">
            <SelectItem
              searchValue={search}
              setSearchValue={setSearch}
              listOptions={searchListOptions}
              setSearchListOptions={setSearchListOptions}
              columnsList={customerColumnsList}
              placeholder="Select Customer"
              // onDoubleClick ={onDoubleClick}
              onDoubleClick ={search !== '' ? onDoubleClick : () => {}}
              
              selectedItemHandler={selectItemCallbck}
              fieldKey="companyName"
              dataIdKey="customerNumber"
              containerClassName={"flex-grow-1"}
              inputClassname='py-0 whbrand-bg whbrand-text-color'
              disabled={status === "posted"}
              showDropdownBtn={status !== "posted"}
              addF3Shortcut={false}
              dropdownBtnClickHndlr={(setShowOptions) => {
                getAllCustomersApiCall(true);
                setShowOptions(true);
              }}
              callbackAfterBottomReached={(fn) => {
                getAllCustomersApiCall(true, currentCustomerListPage.current);
                callbackOnCustomerListApiResp.current = fn;
              }}
              btnClassname={'py-0 whbrand-text-color border-light'}
              boldText={boldText}
            />
          </div>
        </div>
      </div>
    );
}
 
export default SearchCustomerBox;