import purchaseorderconstants from "./purchaseorderconstants";

const initialState = {
  getAllPurchaseOrdersResp: undefined,
  getAllPurchaseOrdersLoading: false,
  createPurchaseOrderResp: undefined,
  getPurchaseOrderDetailsResp: undefined,
  updatePurchaseOrderDetailsResp: undefined,
  getMaxPONoResp: undefined,
  receivePoResp: undefined,
  deletePoResp: undefined,
  openPoResp: undefined,
  downloadPoPdfResp: undefined,
  poApiLoading: false,
  purchaseOrderErrorResp: undefined,
};

export default function purchaseOrder(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case purchaseorderconstants.GET_ALL_PURCHASE_ORDERS_SUCCESS:
      return { ...state, getAllPurchaseOrdersResp: payload };
    case purchaseorderconstants.CHANGE_GET_ALL_PURCHASE_ORDERS_LOADING_STATUS:
      return { ...state, getAllPurchaseOrdersLoading: payload };
    case purchaseorderconstants.CREATE_PURCHASE_ORDER_SUCCESS:
      return { ...state, createPurchaseOrderResp: payload };
    case purchaseorderconstants.GET_PURCHASE_ORDER_DETAILS_SUCCESS:
      return { ...state, getPurchaseOrderDetailsResp: payload };
    case purchaseorderconstants.UPDATE_PURCHASE_ORDER_DETAILS_SUCCESS:
      return { ...state, updatePurchaseOrderDetailsResp: payload };
    case purchaseorderconstants.GET_MAX_PO_NO_SUCCESS:
      return { ...state, getMaxPONoResp: payload };
    case purchaseorderconstants.RECEIVE_PO_SUCCESS:
      return { ...state, receivePoResp: payload };
    case purchaseorderconstants.DELETE_PO_SUCCESS:
      return { ...state, deletePoResp: payload };
    case purchaseorderconstants.OPEN_PO_SUCCESS:
      return { ...state, openPoResp: payload };
    case purchaseorderconstants.DOWNLOAD_PO_PDF_SUCCESS:
      return { ...state, downloadPoPdfResp: payload };
    case purchaseorderconstants.CHANGE_PO_API_LOADING_STATUS:
      return { ...state, poApiLoading: payload };

    case purchaseorderconstants.GET_ALL_PURCHASE_ORDERS_FAIL:
    case purchaseorderconstants.CREATE_PURCHASE_ORDER_FAIL:
    case purchaseorderconstants.GET_PURCHASE_ORDER_DETAILS_FAIL:
    case purchaseorderconstants.UPDATE_PURCHASE_ORDER_DETAILS_FAIL:
    case purchaseorderconstants.GET_MAX_PO_NO_FAIL:
    case purchaseorderconstants.RECEIVE_PO_FAIL:
    case purchaseorderconstants.DELETE_PO_FAIL:
    case purchaseorderconstants.OPEN_PO_FAIL:
    case purchaseorderconstants.DOWNLOAD_PO_PDF_FAIL:
      return { ...state, purchaseOrderErrorResp: payload };
    default:
      return state;
  }
}
