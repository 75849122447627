import React, { useEffect, useState, useRef } from "react";
import { Button, Row, Col, Container, Label, Input, FormFeedback, Form } from "reactstrap";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import SaveIcon from "../../../../../assets/images/warehouse/salespage/save.png";
import DummyAvatar from "../../../../../assets/images/warehouse/salespage/dummy-avatar.jpg";
import { addEmployeeInitialValues, getAddEmployeeValidationSchema } from "./helper";
//Api Helper
import { generateApiUrl } from "../../../../../api/apihelper";
//Actions
import { getUserRolesAct } from "../../../../../store/usermanagement/usermanagementactions";
import { createEmployeeAct, updateEmployeeAct } from "../../../../../store/employee/employeeactions";
import { getDepartmentsAct } from "../../../../../store/department/departmentactions";
import { getDesignationsAct } from "../../../../../store/designation/designationactions";
import { getZipcodeAct, getStatesListAct } from "../../../../../store/masterdata/masterdataactions";
//Utility
import { blockInvalidCharForPhone } from "../../../../../utility/commonutility";
import { getYyyymmddDateFormatted } from "../../../../../utility/dateutility";

function getInitialValues(editEmployeeData) {
  if (editEmployeeData) {
    const {
      name,
      department,
      designation,
      roleId,
      homePhone,
      email,
      birthDate,
      gender,
      hireDate,
      salary,
      user,
      street,
      city,
      zip,
      suit,
      state,
      county,
      active
    } = editEmployeeData;
    return {
      image: "",
      name: name || "",
      departmentId: department?.id || "",
      designationId: designation?.id || "",
      roleId: user?.role?.id || "",
      homePhone: homePhone || "",
      email: email || "",
      birthDate: birthDate ? getYyyymmddDateFormatted(birthDate) : "",
      gender: gender || "",
      hireDate: hireDate ? getYyyymmddDateFormatted(hireDate) : "",
      salary: salary || "",
      username: user?.username || "",
      password: "",
      street: street || "",
      city: city || "",
      zip: zip || "",
      suit: suit || "",
      state: state || "",
      county: county || "",
      active,
    };
  } else return addEmployeeInitialValues;
}

const AddNewEmployee = ({ setActiveId, editEmployeeData }) => {
  const [previewUrl, setPreviewUrl] = useState(editEmployeeData?.image || null);
  const dispatch = useDispatch();
  const addEmployeeValidation = useFormik({
    initialValues: getInitialValues(editEmployeeData),
    validationSchema: getAddEmployeeValidationSchema(!!editEmployeeData),
    onSubmit: (values) => {
      const formData = new FormData();
      for (let i in values) {
        if(values[i] || typeof values[i] === "boolean") formData.append(i, values[i]);
        console.log(values[i]);
      }
      if(!editEmployeeData) dispatch(createEmployeeAct(generateApiUrl("create_employee"), formData));
      else dispatch(updateEmployeeAct(generateApiUrl("update_employee", {id:editEmployeeData.id}), formData));
    },
  });
  const {
    employeeApiLoading,
    getUserRolesResp,
    getDepartmentsResp,
    getDesignationsResp,
    getZipcodeResp,
    getStatesListResp,
    createEmployeeResp,
    updateEmployeeResp,
    employeeErrorResp,
    masterDataErrorResp,
  } = useSelector(
    ({ employee, userManagement, department, designation, masterData }) => ({
      employeeApiLoading: employee.employeeApiLoading,
      getUserRolesResp: userManagement.getUserRolesResp,
      getDepartmentsResp: department.getDepartmentsResp,
      getDesignationsResp: designation.getDesignationsResp,
      getZipcodeResp: masterData.getZipcodeResp,
      getStatesListResp: masterData.getStatesListResp,
      createEmployeeResp: employee.createEmployeeResp,
      updateEmployeeResp: employee.updateEmployeeResp,
      employeeErrorResp: employee.employeeErrorResp,
      masterDataErrorResp: masterData.masterDataErrorResp,
    }),
    shallowEqual
  );
  useEffect(() => {
    dispatch(getUserRolesAct(generateApiUrl("get_user_roles")));
    dispatch(getDepartmentsAct(generateApiUrl("get_departments")));
    dispatch(getDesignationsAct(generateApiUrl("get_designations")));
    dispatch(getStatesListAct(generateApiUrl(`get_states_list`)));
  }, []);
  const apiResp = useRef({
    prevGetZipcodeResp: getZipcodeResp,
    prevMasterDataErrorResp: masterDataErrorResp,
  });
  useEffect(() => {
    const { prevGetZipcodeResp, prevMasterDataErrorResp } = apiResp.current || {};
    if (getZipcodeResp && prevGetZipcodeResp !== getZipcodeResp) {
      const {
        data: {
          zipcode: { cityName, countryName, stateName },
        },
      } = getZipcodeResp;
      const state = getStatesListResp?.data?.states.find((item) => item.name.toLowerCase() === stateName.toLowerCase());
      addEmployeeValidation.setValues((prev) => ({
        ...prev,
        city: cityName,
        county: countryName,
        state: state?.code || "",
      }));
    }
    if (masterDataErrorResp && prevMasterDataErrorResp !== masterDataErrorResp) {
      const { errors, error, message } = masterDataErrorResp || {};
      toast.error((Array.isArray(errors) && errors[0].msg) || error || message);
    }
    apiResp.current.prevGetZipcodeResp = getZipcodeResp;
    apiResp.current.prevMasterDataErrorResp = masterDataErrorResp;
  }, [getZipcodeResp, masterDataErrorResp]);
  const getZipcodeApiCall = () => {
    dispatch(getZipcodeAct(generateApiUrl(`get_zipcode`, { zipcode: addEmployeeValidation.values.zip })));
  };
  const handleFileInputChange = (e) => {
    const file = e.target.files[0];
    addEmployeeValidation.setValues((prev) => ({ ...prev, image: file }));

    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewUrl(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      setPreviewUrl(null);
    }
  };
  return (
    <Container fluid className="px-5 mb-5">
      <div className="d-flex align-items-center justify-content-between mb-4">
        <h4>{editEmployeeData ? "Edit Employee" : "Add Employee"}</h4>
        <Button
          color="primary"
          className="fs-5 d-flex align-items-center"
          onClick={() => setActiveId("employees_list")}
          outline
        >
          {" "}
          <i className="ri-arrow-left-line"></i> Back
        </Button>
      </div>
      <div className="d-flex justify-content-end align-items-center">
        <div className="d-flex align-items-center">
          <Label htmlFor="active" className="form-label mb-0 text-nowrap fs-5 fw-bold">
            Status:
          </Label>
          <div className="form-check form-switch form-switch-lg form-switch-success px-5 ms-2">
            {addEmployeeValidation.values.active}
            <Input className="form-check-input" type="checkbox" role="switch" id="active" name="active" checked={addEmployeeValidation.values.active} onChange={addEmployeeValidation.handleChange} />
            <span className="fs-5 fw-bold text-success">Active</span>
          </div>
        </div>
        <Button
          color="primary"
          className="fs-5 d-flex align-items-center"
          outline
          onClick={addEmployeeValidation.handleSubmit}
        >
          {" "}
          <img src={SaveIcon} width="32" height="32" alt="save" /> Save
        </Button>
      </div>
      <Row>
        <Col xs="6">
          <Row className="mb-3">
            <Col xl={3} xxl={2} className="d-flex align-items-center">
              <Label className="form-label mb-0 text-nowrap">Employee Name :</Label>
            </Col>
            <Col xl={9} xxl={10}>
              <Input
                className="form-control"
                placeholder="Enter Employee Name"
                type="text"
                name="name"
                value={addEmployeeValidation.values.name}
                onChange={addEmployeeValidation.handleChange}
                invalid={addEmployeeValidation.touched.name && addEmployeeValidation.errors.name}
              />
              <FormFeedback>{addEmployeeValidation.touched.name && addEmployeeValidation.errors.name}</FormFeedback>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col xl={3} xxl={2} className="d-flex align-items-center">
              <Label className="form-label mb-0 text-nowrap">Username :</Label>
            </Col>
            <Col xl={9} xxl={10}>
              <Input
                className="form-control"
                placeholder="Enter Username"
                type="text"
                name="username"
                value={addEmployeeValidation.values.username}
                onChange={addEmployeeValidation.handleChange}
                invalid={addEmployeeValidation.touched.username && addEmployeeValidation.errors.username}
              />
              <FormFeedback>
                {addEmployeeValidation.touched.username && addEmployeeValidation.errors.username}
              </FormFeedback>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col xl={3} xxl={2} className="d-flex align-items-center">
              <Label className="form-label mb-0 text-nowrap">Department :</Label>
            </Col>
            <Col xl={9} xxl={10}>
              <Input
                className="form-control form-select"
                type="select"
                name="departmentId"
                value={addEmployeeValidation.values.departmentId}
                onChange={addEmployeeValidation.handleChange}
                invalid={addEmployeeValidation.touched.departmentId && addEmployeeValidation.errors.departmentId}
              >
                <option value="">Select Department</option>
                {(getDepartmentsResp?.data?.departments || []).map((item) => (
                  <option key={item.id} value={item.id}>
                    {item.name}
                  </option>
                ))}
              </Input>
              <FormFeedback>
                {addEmployeeValidation.touched.departmentId && addEmployeeValidation.errors.departmentId}
              </FormFeedback>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col xl={3} xxl={2} className="d-flex align-items-center">
              <Label className="form-label mb-0 text-nowrap">Designation :</Label>
            </Col>
            <Col xl={9} xxl={10}>
              <Input
                className="form-control form-select"
                type="select"
                name="designationId"
                value={addEmployeeValidation.values.designationId}
                onChange={addEmployeeValidation.handleChange}
                invalid={addEmployeeValidation.touched.designationId && addEmployeeValidation.errors.designationId}
              >
                <option value="">Select Designation</option>
                {(getDesignationsResp?.data?.designations || []).map((item) => (
                  <option key={item.id} value={item.id}>
                    {item.name}
                  </option>
                ))}
              </Input>
              <FormFeedback>
                {addEmployeeValidation.touched.designationId && addEmployeeValidation.errors.designationId}
              </FormFeedback>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col xl={3} xxl={2} className="d-flex align-items-center">
              <Label className="form-label mb-0 text-nowrap">Role :</Label>
            </Col>
            <Col xl={9} xxl={10}>
              <Input
                className="form-control form-select"
                type="select"
                name="roleId"
                value={addEmployeeValidation.values.roleId}
                onChange={addEmployeeValidation.handleChange}
                invalid={addEmployeeValidation.touched.roleId && addEmployeeValidation.errors.roleId}
              >
                <option value="">Select Role</option>
                {(getUserRolesResp?.data?.userRoles || []).map((item) => (
                  <option key={item.id} value={item.id}>
                    {item.name}
                  </option>
                ))}
              </Input>
              <FormFeedback>{addEmployeeValidation.touched.roleId && addEmployeeValidation.errors.roleId}</FormFeedback>
            </Col>
          </Row>
        </Col>
        <Col xs="6" className="ps-5">
          <div className="d-flex align-items-center">
            <div className="d-flex flex-column align-items-start">
              <Label className="fs-5 mb-1">Upload Photo</Label>
              <div className="border p-1 mb-1">
                <img src={previewUrl || DummyAvatar} alt="user" width="90" height="90" />
              </div>
              <Label>Supported Formats: JPEG/PNG/BMP</Label>
            </div>
            <Input className="form-control w-50" accept="image/*" type="file" onChange={handleFileInputChange} />
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs="6">
          <Row className="mb-3">
            <Col xl={3} xxl={2} className="d-flex align-items-center">
              <Label className="form-label mb-0 text-nowrap">Contact :</Label>
            </Col>
            <Col xl={9} xxl={10}>
              <Input
                className="form-control"
                placeholder="Enter Contact"
                type="text"
                name="homePhone"
                value={addEmployeeValidation.values.homePhone}
                onChange={(e) => blockInvalidCharForPhone(e, () => addEmployeeValidation.handleChange(e))}
                minLength="15"
                maxLength="15"
                invalid={addEmployeeValidation.touched.homePhone && addEmployeeValidation.errors.homePhone}
              />
              <FormFeedback>{addEmployeeValidation.touched.homePhone && addEmployeeValidation.errors.homePhone}</FormFeedback>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col xl={3} xxl={2} className="d-flex align-items-center">
              <Label className="form-label mb-0 text-nowrap">Email Id :</Label>
            </Col>
            <Col xl={9} xxl={10}>
              <Input
                className="form-control"
                placeholder="Enter Email Id"
                type="text"
                name="email"
                value={addEmployeeValidation.values.email}
                onChange={addEmployeeValidation.handleChange}
              />
            </Col>
          </Row>

          {!editEmployeeData && (
            <Row className="mb-3">
              <Col xl={3} xxl={2} className="d-flex align-items-center">
                <Label className="form-label mb-0 text-nowrap">Password :</Label>
              </Col>
              <Col xl={9} xxl={10}>
                <Input
                  className="form-control"
                  placeholder="Enter Password"
                  type="password"
                  name="password"
                  value={addEmployeeValidation.values.password}
                  onChange={addEmployeeValidation.handleChange}
                  invalid={addEmployeeValidation.touched.password && addEmployeeValidation.errors.password}
                />
                <FormFeedback>
                  {addEmployeeValidation.touched.password && addEmployeeValidation.errors.password}
                </FormFeedback>
              </Col>
            </Row>
          )}

          <Row className="mb-3">
            <Col xl={3} xxl={2} className="d-flex align-items-center">
              <Label className="form-label mb-0 text-nowrap">Date of Birth :</Label>
            </Col>
            <Col xl={4} xxl={5}>
              <Input
                type="date"
                className="form-control"
                name="birthDate"
                value={addEmployeeValidation.values.birthDate}
                onChange={addEmployeeValidation.handleChange}
              />
            </Col>
           
            <Col xl={5} xxl={5}>
             <div className="d-flex align-items-center">
              <Label className="form-label mb-0 text-nowrap me-2">Gender :</Label>
              <select
                className="form-select"
                name="gender"
                value={addEmployeeValidation.values.gender}
                onChange={addEmployeeValidation.handleChange}
              >
                <option value="">Select Gender</option>
                <option>Male</option>
                <option>Female</option>
              </select>
             </div>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col xl={3} xxl={2} className="d-flex align-items-center">
              <Label className="form-label mb-0 text-nowrap">Joining Date :</Label>
            </Col>
            <Col xl={4} xxl={5}>
              <Input
                type="date"
                className="form-control"
                name="hireDate"
                value={addEmployeeValidation.values.hireDate}
                onChange={addEmployeeValidation.handleChange}
              />
            </Col>
          
            <Col xl={5} xxl={5}>
             <div className="d-flex align-items-center">
              <Label className="form-label mb-0 text-nowrap me-2">Salary :</Label>
              <Input
                className="form-control"
                placeholder="Enter Salary"
                type="text"
                name="salary"
                value={addEmployeeValidation.values.salary}
                onChange={addEmployeeValidation.handleChange}
              />
             </div>
            </Col>
          </Row>
        </Col>
        <Col xs="6 ps-5">
          <Row className="mb-3">
            <Col xl={3} xxl={2} className="d-flex align-items-center">
              <Label className="form-label mb-0 text-nowrap">Street :</Label>
            </Col>
            <Col xl={9} xxl={10}>
              <Input
                className="form-control"
                placeholder="Enter Street"
                type="text"
                name="street"
                value={addEmployeeValidation.values.street}
                onChange={addEmployeeValidation.handleChange}
              />
            </Col>
          </Row>

          <Row className="mb-3">
            <Col xl={3} xxl={2} className="d-flex align-items-center">
              <Label className="form-label mb-0 text-nowrap">Suit :</Label>
            </Col>
            <Col xl={9} xxl={10}>
              <Input
                className="form-control"
                placeholder="Enter Suit"
                type="text"
                name="suit"
                value={addEmployeeValidation.values.suit}
                onChange={addEmployeeValidation.handleChange}
              />
            </Col>
          </Row>

          <Row className="mb-3">
            <Col xl={3} xxl={2} className="d-flex align-items-center">
              <Label className="form-label mb-0 text-nowrap">City :</Label>
            </Col>
            <Col xl={9} xxl={10}>
              <Input
                className="form-control"
                placeholder="Enter City"
                type="text"
                name="city"
                value={addEmployeeValidation.values.city}
                onChange={addEmployeeValidation.handleChange}
              />
            </Col>
          </Row>

          <Row className="mb-3">
            <Col xl={3} xxl={2} className="d-flex align-items-center">
              <Label className="form-label mb-0 text-nowrap">State :</Label>
            </Col>
            <Col xs={4}>
              <select
                className="form-control form-select"
                type="text"
                name="state"
                value={addEmployeeValidation.values.state}
                onChange={addEmployeeValidation.handleChange}
              >
                <option value="">Select State</option>
                {(getStatesListResp?.data?.states || []).map((item) => (
                  <option value={item.code} key={item.id}>
                    {item.name}
                  </option>
                ))}
              </select>
            </Col>
            <Col xl={3} xxl={2} className="d-flex align-items-center ps-5">
              <Label className="form-label mb-0 text-nowrap">Zip :</Label>
            </Col>
            <Col xs={4} className="d-flex align-items-start">
              <div className="flex-grow-1 me-2">
                <Input
                  name="zip"
                  value={addEmployeeValidation.values.zip}
                  onChange={addEmployeeValidation.handleChange}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") getZipcodeApiCall();
                  }}
                  className="form-control"
                  placeholder="Enter zip"
                  type="text"
                  invalid={addEmployeeValidation.touched.zip && addEmployeeValidation.errors.zip ? true : false}
                />
                <FormFeedback>
                  {addEmployeeValidation.touched.zip && addEmployeeValidation.errors.zip
                    ? addEmployeeValidation.errors.zip
                    : ""}
                </FormFeedback>
              </div>
              <Button color="primary" onClick={getZipcodeApiCall}>
                <i className="ri-check-line"></i>
              </Button>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col xl={3} xxl={2} className="d-flex align-items-center">
              <Label className="form-label mb-0 text-nowrap">County :</Label>
            </Col>
            <Col xl={9} xxl={10}>
              <Input
                className="form-control"
                placeholder="Enter County"
                type="text"
                name="county"
                value={addEmployeeValidation.values.county}
                onChange={addEmployeeValidation.handleChange}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default AddNewEmployee;
