import React, { useMemo, useEffect, useState, useRef } from "react";
import { Col, Container, Row } from "reactstrap";
import WhTableContainer from "../../../../Components/Common/WhTables";
import { generateColumns } from "./helper";
//Actions
import { getInvoicesOfCustomerAct } from "../../../../store/customer/customeractions";

export const SalesTab = ({ history, dispatch, generateApiUrl, id, useSelector, shallowEqual, isPaymentAnywhereModelOpen }) => {
  const [tableData, setTableData] = useState([]);
  const { getInvoicesOfCustomerResp } = useSelector(
    ({ customer }) => ({
      getInvoicesOfCustomerResp: customer.getInvoicesOfCustomerResp,
    }),
    shallowEqual
  );
  const apiResp = useRef({
    prevGetInvoicesOfCustomerResp: getInvoicesOfCustomerResp,
  });
  useEffect(() => {
    const { prevGetInvoicesOfCustomerResp } = apiResp.current;
    if (getInvoicesOfCustomerResp && prevGetInvoicesOfCustomerResp !== getInvoicesOfCustomerResp && !isPaymentAnywhereModelOpen.current) {
      setTableData(getInvoicesOfCustomerResp.data.invoices);
    }
    apiResp.current.prevGetInvoicesOfCustomerResp = getInvoicesOfCustomerResp;
  }, [getInvoicesOfCustomerResp]);
  useEffect(() => {
    if (id && id !== "new")
      dispatch(
        getInvoicesOfCustomerAct(generateApiUrl("get_invoices_of_customer"), {
          customerNumber: id,
          posted:true,
          page: 1,
          pageSize: 10,
          orderBy:"invoiceDate",
          sort:"desc"
        })
      );
  }, []);
  const handlePageChange = (page, pageSize) => {
    dispatch(
      getInvoicesOfCustomerAct(generateApiUrl("get_invoices_of_customer"), {
        customerNumber: id,
        page: page + 1,
        pageSize,
        posted:true,
        orderBy:"invoiceDate",
        sort:"desc"
      })
    );
  };
  const columns = useMemo(() => generateColumns(history), []);
  return (
    <React.Fragment>
      <Container fluid className="px-4 whbrand-text-color">
        <Row className="m-0">
          {/* <Col xs="12" className="d-flex justify-content-end">
              <div>
                <select className="form-select">
                  <option>10</option>
                  <option>25</option>
                  <option>50</option>
                  <option>75</option>
                </select>
              </div>
            </Col> */}
          <Col className="px-0" xs={12}>
            <WhTableContainer
              columns={columns}
              data={tableData || []}
              className="custom-header-css"
              divClass="table-responsive mt-3 whbrand-table"
              tableClass="align-middle table-bordered text-center"
              theadClass="table-light"
              handlePageChange={handlePageChange}
              pageCount={Math.ceil((getInvoicesOfCustomerResp?.data?.total || 0) / 10)}
              customPageSize={30}
              showNoDataComponent={true}
            />
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};
