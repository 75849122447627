import React, { useRef, useEffect, useState } from "react";
import { useSelector, shallowEqual } from "react-redux";
import { Col, Container, Input, Label, Row, Form, Button, FormFeedback } from "reactstrap";
import ResetIcon from "../../../assets/images/warehouse/salespage/reset-blue.png";
//Actions
import { getZipcodeAct } from "../../../store/masterdata/masterdataactions";
import { getMasterDataForSupplierAct } from "../../../store/supplier/supplieractions";
import { getStatesListAct } from "../../../store/masterdata/masterdataactions";
//Utility
import { blockInvalidCharForPhone, toCamelCase, toTitleCase } from "../../../utility/commonutility"
import { generateStatesData } from "./detailshelper";
import { useMemo } from "react";

export const DetailsTab = ({ validation, id, dispatch, generateApiUrl }) => {
  // const [dropdownState, setDropdownState] = useState({
  //   vendorType: [],
  //   paymentTerms: [],
  // });
  const {
    getZipcodeResp,
    getStatesListResp,
    getMasterDataForSupplierResp,
  } = useSelector(
    ({ masterData, supplier }) => ({
      getZipcodeResp: masterData.getZipcodeResp,
      createSupplierResp: supplier.createSupplierResp,
      updateSupplierDetailsResp: supplier.updateSupplierDetailsResp,
      getStatesListResp: masterData.getStatesListResp,
      getMasterDataForSupplierResp: supplier.getMasterDataForSupplierResp,
    }),
    shallowEqual
  );
  const apiResp = useRef({
    prevGetZipcodeResp: getZipcodeResp,
    prevGetMasterDataForSupplierResp: getMasterDataForSupplierResp,
  });
  const {
    data: { states = [] },
  } = getStatesListResp || { data: {} };
  const category = useRef("vendorType");
  useEffect(() => {
    const {
      prevGetZipcodeResp,
      prevGetMasterDataForSupplierResp,
    } = apiResp.current || {};
    if (getZipcodeResp && prevGetZipcodeResp !== getZipcodeResp) {
      const {
        data: {
          zipcode: { cityName, countryName, stateName },
        },
      } = getZipcodeResp;
      const st = states.find((item) => item.name.toLowerCase() === stateName.toLowerCase());
      validation.setValues((prev) => ({ ...prev, city: cityName, st: st?.code || "" }));
    }
    // if (getMasterDataForSupplierResp && prevGetMasterDataForSupplierResp !== getMasterDataForSupplierResp) {
    //   if (category.current === "vendorType") {
    //     setDropdownState((prev) => ({ ...prev, vendorType: getMasterDataForSupplierResp.data.vendorType }));
    //     category.current = "paymentTerms";
    //     dispatch(
    //       getMasterDataForSupplierAct(generateApiUrl(`get_master_data_for_supplier`), { category: "paymentTerms" })
    //     );
    //   } else if (category.current === "paymentTerms") {
    //     setDropdownState((prev) => ({ ...prev, paymentTerms: getMasterDataForSupplierResp.data.paymentTerms }));
    //     category.current = "vendorType";
    //   }
    // }
    apiResp.current.prevGetZipcodeResp = getZipcodeResp;
    apiResp.current.prevGetMasterDataForSupplierResp = getMasterDataForSupplierResp;
  }, [getZipcodeResp, getMasterDataForSupplierResp]);
  useEffect(() => {
    // dispatch(getStatesListAct(generateApiUrl(`get_states_list`)));
    dispatch(
      getMasterDataForSupplierAct(generateApiUrl(`get_master_data_for_supplier`), { category: category.current })
    );
  }, []);
  const getZipcodeApiCall = () => {
    dispatch(getZipcodeAct(generateApiUrl(`get_zipcode`, { zipcode: validation.values.zip })));
  };
  
  const statesData = useMemo(() => generateStatesData(), []);
  return (
    <Container fluid className="py-3 px-5">
      <Form>
        <Row>
          <Col xs={7}>
            <Row className="mb-3">
              <Col md={6}>
                <div className="mb-3">
                  <Label for="supplierName" className="form-label mb-1 required">
                    Company Name
                  </Label>
                  <Input
                    type="text"
                    className="form-control"
                    id="supplierName"
                    name="supplierName"
                    value={validation.values.supplierName}
                    onChange={validation.handleChange}
                    invalid={validation.touched.supplierName && validation.errors.supplierName ? true : false}
                  />
                  <FormFeedback>
                    {validation.touched.supplierName && validation.errors.supplierName
                      ? validation.errors.supplierName
                      : ""}
                  </FormFeedback>
                </div>
              </Col>
              <Col md={6}>
                <div className="mb-3">
                  <Label for="supplierAccountNumber" className="form-label mb-1">
                    Account Number
                  </Label>
                  <Input
                    type="text"
                    className="form-control"
                    id="supplierAccountNumber"
                    name="supplierAccountNumber"
                    value={validation.values.supplierAccountNumber}
                    onChange={validation.handleChange}
                  />
                </div>
              </Col>
              <Col xs={2}>
                <div className="mb-3">
                  <Label for="salutation" className="form-label mb-1">
                    Salutation
                  </Label>
                  <Input
                    type="select"
                    id="salutation"
                    name="salutation"
                    value={validation.values.salutation}
                    onChange={validation.handleChange}
                    className="form-select"
                  >
                    <option value="">Select</option>
                    <option>Mr</option>
                    <option>Mrs</option>
                    <option>Dr</option>
                    <option>Sir</option>
                  </Input>
                </div>
              </Col>
              <Col md={5}>
                <div className="mb-3">
                  <Label for="firstName" className="form-label mb-1">
                    First Name
                  </Label>
                  <Input
                    type="text"
                    className="form-control"
                    id="firstName"
                    name="firstName"
                    value={validation.values.firstName}
                    onChange={validation.handleChange}
                    invalid={validation.touched.firstName && validation.errors.firstName ? true : false}
                  />
                  <FormFeedback>
                    {validation.touched.firstName && validation.errors.firstName ? validation.errors.firstName : ""}
                  </FormFeedback>
                </div>
              </Col>
              <Col md={5}>

                <div className="mb-3">
                  <Label for="lastName" className="form-label mb-1">
                    Last Name
                  </Label>
                  <Input
                    type="text"
                    className="form-control"
                    id="lastName"
                    name="lastName"
                    value={validation.values.lastName}
                    onChange={validation.handleChange}
                    invalid={validation.touched.lastName && validation.errors.lastName ? true : false}
                  />
                  <FormFeedback>
                    {validation.touched.lastName && validation.errors.lastName ? validation.errors.lastName : ""}
                  </FormFeedback>
                </div>
              </Col>

              <Col md={4}>
              <div className="mb-3">
                  <Label for="gstNumber" className="form-label mb-1 required">
                    {validation.values.isOutOfState?'IGST':'GSTIN'} Number
                  </Label>
                  <Input
                    type="text"
                    className="form-control"
                    placeholder="Enter GST number"
                    id="gstNumber"
                    name="gstNumber"
                    value={validation.values.gstNumber}
                    onChange={validation.handleChange}
                    invalid={validation.touched.gstNumber && validation.errors.gstNumber ? true : false}
                  />
                  <FormFeedback>
                    {validation.touched.gstNumber && validation.errors.gstNumber ? validation.errors.gstNumber : ""}
                  </FormFeedback>
                </div>
              </Col>
            </Row>
            <Row className="g-0 mb-4">
              <Col xs={12} className="border position-relative px-3 py-3 wh-border-thick">
                <Row>
                  <Label
                    className="fs-5 ml-2 text-dark position-absolute"
                    style={{ transform: "translateY(-50%)", top: 0, left: "10px" }}
                  >
                    <span className=" px-1 wh-item-bg">Contact Details</span>
                  </Label>
                  <Col xs={6}>
                    <div className="mb-3">
                      <Label for="phone" className="form-label mb-1">
                        Phone Number
                      </Label>
                      <Input
                        type="text"
                        onWheel={(e) => e.target.blur()}
                        className="form-control"
                        id="phone"
                        name="phone"
                        value={validation.values.phone}
                        onChange={(e) => blockInvalidCharForPhone(e, () => validation.handleChange(e))}
                        maxLength={15}
                        invalid={validation.touched.phone && validation.errors.phone ? true : false}
                      />
                      <FormFeedback>
                        {validation.touched.phone && validation.errors.phone ? validation.errors.phone : ""}
                      </FormFeedback>
                    </div>
                  </Col>
                  <Col xs={6}>
                    <div className="mb-3">
                      <Label for="fax" className="form-label mb-1">
                        Fax
                      </Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="fax"
                        name="fax"
                        value={validation.values.fax}
                        onChange={validation.handleChange}
                      />
                    </div>
                  </Col>
                  <Col xs={12}>
                    <div className="mb-3">
                      <Label for="email" className="form-label mb-1">
                        Email Address
                      </Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="email"
                        name="email"
                        value={validation.values.email}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        invalid={validation.touched.email && validation.errors.email ? true : false}
                      />
                      <FormFeedback>
                        {validation.touched.email && validation.errors.email ? validation.errors.email : ""}
                      </FormFeedback>
                    </div>
                  </Col>
                  
                </Row>
              </Col>
            </Row>
            <Row className="g-0 mb-0">
              <Col xs={12} className="border position-relative px-3 py-0 pb-0 mb-0 wh-border-thick" >
                <Row>
                  <Label
                    className="fs-5 ml-2 text-dark position-absolute"
                    style={{ transform: "translateY(-50%)", top: 0, left: "10px" }}
                  >
                    <span className=" px-1  wh-item-bg">Address</span>
                  </Label>
                  <Col xs={12}>
                    <div className="mb-3 mt-3">
                      <Label for="flatNumber" className="form-label mb-1 required">
                      Flat, House no., Building, Company, Apartment
                      </Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="flatNumber"
                        name="flatNumber"
                        value={validation.values.flatNumber}
                        onChange={validation.handleChange}
                        invalid={validation.touched.flatNumber && validation.errors.flatNumber ? true : false}
                      />
                      <FormFeedback>
                        {validation.touched.flatNumber && validation.errors.flatNumber ? validation.errors.flatNumber : ""}
                      </FormFeedback>
                    </div>
                  </Col>
                  <Col xs={6}>
                    <div className="mb-3 mt-0">
                      <Label for="street" className="form-label mb-1 required">
                      Area, Street, Sector, Village
                      </Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="street"
                        name="street"
                        value={validation.values.street}
                        onChange={validation.handleChange}
                        invalid={validation.touched.street && validation.errors.street ? true : false}
                      />
                      <FormFeedback>
                        {validation.touched.street && validation.errors.street ? validation.errors.street : ""}
                      </FormFeedback>
                    </div>
                  </Col>
                  <Col xs={6}>
                    <div className="mb-3 mt-0">
                      <Label for="landmark" className="form-label mb-1 required">
                      Landmark
                      </Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="landmark"
                        name="landmark"
                        value={validation.values.landmark}
                        onChange={validation.handleChange}
                        invalid={validation.touched.landmark && validation.errors.landmark ? true : false}
                      />
                      <FormFeedback>
                        {validation.touched.landmark && validation.errors.landmark ? validation.errors.landmark : ""}
                      </FormFeedback>
                    </div>
                  </Col>
                  <Col xs={4}>
                    <div className="mb-3">
                      <Label for="zip" className="form-label mb-1 required">
                        Pin Code
                      </Label>
                      <div className="d-flex align-items-start align-items-center">
                        <div className="flex-grow-1 me-2">
                          <Input
                            type="number"
                            onWheel={(e) => e.target.blur()}
                            id="zip"
                            name="zip"
                            value={validation.values.zip}
                            onChange={(e) => {
                              const { value } = e.target,
                                { length } = value;
                              if (length <= 11) validation.setValues((prev) => ({ ...prev, zip: e.target.value }));
                            }}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                getZipcodeApiCall();
                              } else if( e.key === "ArrowUp" || e.key === "ArrowDown"){
                                e.preventDefault();
                              }
                            }}
                            className="form-control flex-grow-1 me-1"
                            invalid={validation.touched.zip && validation.errors.zip ? true : false}
                          />
                          <FormFeedback>
                            {validation.touched.zip && validation.errors.zip ? validation.errors.zip : ""}
                          </FormFeedback>
                        </div>
                        {/* <Button color="primary" onClick={getZipcodeApiCall}>
                          <i className="ri-check-line"></i>
                        </Button> */}
                      </div>
                    </div>
                  </Col>
                  <Col xs={4}>
                    <div className="mb-3">
                      <Label for="city" className="form-label mb-1 required">
                        Town/City
                      </Label>
                      <Input
                        id="city"
                        name="city"
                        value={validation.values.city}
                        onChange={(e) => {
                          if (/^[a-zA-Z]+$/.test(e.target.value) || e.target.value == "") validation.handleChange(e);
                        }}
                        type="text"
                        className="form-control"
                        invalid={validation.touched.city && validation.errors.city ? true : false}
                      />
                      <FormFeedback>
                        {validation.touched.city && validation.errors.city ? validation.errors.city : ""}
                      </FormFeedback>
                    </div>
                  </Col>
                  <Col xs={4}>
                    <div className="mb-3">
                      <Label for="st" className="form-label mb-1 required">
                        State
                      </Label>
                      <Input
                        id="st"
                        name="st"
                        value={validation.values.st}
                        onChange={validation.handleChange}
                        type="select"
                        className="form-control"
                        invalid={validation.touched.st && validation.errors.st ? true : false}
                      >
                        <option value="">Select</option>
                        {statesData.map((item) => (
                          <option key={item.id} value={item.code}>
                            {item.name}
                          </option>
                        ))}
                      </Input>
                      <FormFeedback>
                        {validation.touched.st && validation.errors.st ? validation.errors.st : ""}
                      </FormFeedback>
                    </div>
                  </Col>

                  {/* <Col xs={6}>
                    <div className="mb-3">
                      <Label for="suite" className="form-label mb-1">
                        Suite
                      </Label>
                      <Input
                        id="suite"
                        name="suite"
                        value={validation.values.suite}
                        onChange={validation.handleChange}
                        type="text"
                        className="form-control"
                        invalid={validation.touched.suite && validation.errors.suite ? true : false}
                      />
                      <FormFeedback>
                        {validation.touched.suite && validation.errors.suite ? validation.errors.suite : ""}
                      </FormFeedback>
                    </div>
                  </Col> */}
                  {/* <Col xs={6}>
                    <div className="mb-3">
                      <Label for="country" className="form-label mb-1 required">
                      country
                      </Label>
                      <Input
                        id="country"
                        name="country"
                        value={validation.values.country}
                        onChange={(e) => {
                          if (/^[a-zA-Z]+$/.test(e.target.value) || e.target.value == "") validation.handleChange(e);
                        }}
                        type="text"
                        className="form-control"
                        invalid={validation.touched.country && validation.errors.country ? true : false}
                      />
                      <FormFeedback>
                        {validation.touched.country && validation.errors.country ? validation.errors.country : ""}
                      </FormFeedback>
                    </div>
                  </Col> */}
                </Row>
              </Col>
            </Row>
          </Col>
          <Col xs={5}>
            <Row>
            <Col xs={2} className="d-flex flex-column align-items-center mt-4 ps-5">
                  <div className="form-check ms-2">
                    <Input
                      className="form-check-input"
                      type="checkbox"
                      name="isOutOfState"
                      id="isOutOfState"
                      checked={validation.values.isOutOfState}
                      onChange={validation.handleChange}
                    />
                    <Label className="form-check-label text-nowrap" htmlFor="isOutOfState">
                      Out of State
                    </Label>
                  </div>
                </Col>
              <Col xs={10} className="d-flex flex-column align-items-center mb-0">
                <div className="d-flex align-items-center mb-0">
                  <Label htmlFor="active" className="form-label mb-1 mb-0 text-nowrap fs-5 fw-bold">
                    Supplier Status:
                  </Label>
                  <div className="form-check form-switch form-switch-lg form-switch-success px-5 ms-2">
                    <Input
                      className="form-check-input"
                      type="checkbox"
                      role="switch"
                      id="active"
                      name="active"
                      checked={validation.values.active}
                      onChange={validation.handleChange}
                    />
                    <span className={`fs-5 fw-bold ${validation.values.active ? "text-success" : "text-danger"}`}>
                      {validation.values.active ? "Active" : "Non Active"}
                    </span>
                  </div>
                </div>
                {id === "new" ? (
                  <Button color="primary" className="w-50 wh-btn" outline onClick={validation.handleSubmit}>
                    <span className="d-flex justify-content-center align-items-center">
                      {/* <Spinner size="sm" type="grow" className="flex-shrink-0" role="status"> Loading... </Spinner> */}
                      <i className="ri-save-line" style={{ fontSize: "28px" }}></i>
                      <span className="ms-2">Save</span>
                    </span>
                  </Button>
                ) : (
                  <Button color="primary" className="w-50 wh-btn" outline onClick={validation.handleSubmit}>
                    <span className="d-flex justify-content-center align-items-center">
                      {/* <Spinner size="sm" type="grow" className="flex-shrink-0" role="status"> Loading... </Spinner> */}
                      <img src={ResetIcon} style={{ height: "32px", width: "32px" }} />
                      <span className="ms-2">Update Details</span>
                    </span>
                  </Button>
                )}
              </Col>
              {/* <Col xs={12} className="mb-1 mt-2">
                <div className="d-flex mb-0">
                  <div className="form-check ms-2 me-2">
                    <Input
                      className="form-check-input"
                      type="checkbox"
                      id="supplier"
                      name="supplier"
                      checked={validation.values.supplier}
                      onChange={validation.handleChange}
                    />
                    <Label className="form-check-label text-nowrap" htmlFor="supplier">
                      Supplier
                    </Label>
                  </div>
                  <div className="form-check ms-2">
                    <Input className="form-check-input" type="checkbox" id="formCheck6" defaultChecked />
                    <Label className="form-check-label text-nowrap" htmlFor="formCheck6">
                      Vendor
                    </Label>
                  </div>
                </div>
                <div className="mb-3">
                  <Label for="typeOfVendor" className="form-label mb-1">
                    Vendor Type
                  </Label>
                  <select
                    id="typeOfVendor"
                    name="typeOfVendor"
                    value={validation.values.typeOfVendor}
                    onChange={validation.handleChange}
                    className="form-select w-50"
                  >
                    <option value="">Select</option>
                    {dropdownState.vendorType.map((item) => (
                      <option key={item}>{item}</option>
                    ))}
                  </select>
                </div>
                <div className="mb-3">
                  <div className="form-check ms-2 mb-2">
                    <Input
                      className="form-check-input"
                      type="checkbox"
                      id="isOutOfState"
                      name="isOutOfState"
                      checked={validation.values.isOutOfState}
                      onChange={validation.handleChange}
                    />
                    <Label className="form-check-label text-nowrap" htmlFor="isOutOfState">
                      Out Of State Supplier
                    </Label>
                  </div>
                  <div className="form-check ms-2 mb-2">
                    <Input
                      className="form-check-input"
                      type="checkbox"
                      id="isLocalTaxesPaid"
                      name="isLocalTaxesPaid"
                      checked={validation.values.isLocalTaxesPaid}
                      onChange={validation.handleChange}
                    />
                    <Label className="form-check-label text-nowrap" htmlFor="isLocalTaxesPaid">
                      Local Paid Supplier
                    </Label>
                  </div>
                  <div className="form-check ms-2 mb-2">
                    <Input
                      className="form-check-input"
                      type="checkbox"
                      id="isReport"
                      name="isReport"
                      checked={validation.values.isReport}
                      onChange={validation.handleChange}
                    />
                    <Label className="form-check-label text-nowrap" htmlFor="isReport">
                      Report Taxes to State of : NC
                    </Label>
                  </div>
                </div>

                <div className="mb-3 d-flex align-items-center">
                  <Label for="discountPer" className="form-label mb-1 text-nowrap mb-0 me-2">
                    Discount% :
                  </Label>
                  <Input
                    type="text"
                    className="form-control w-50"
                    id="discountPer"
                    name="discountPer"
                    value={validation.values.discountPer}
                    onChange={validation.handleChange}
                  />
                </div>
              </Col> */}
            </Row>
            {/* <Col xs={12} className="mb-1 mt-2">
            <Row className="g-0 mb-1 mt-5">
              <Col xs={12} className="border position-relative px-3 py-3 mb-1 wh-border-thick">
                <Row>
                  <Label
                    className="fs-5 ml-2 text-dark position-absolute"
                    style={{ transform: "translateY(-50%)", top: 0, left: "10px" }}
                  >
                    <span className=" px-1 wh-item-bg"> Sales Representative 1 Contact Details</span>
                  </Label>
                  <Col xs={6}>
                    <div className="mb-2">
                      <Label for="salesFirstName" className="form-label mb-1">
                        First Name
                      </Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="salesFirstName"
                        name="salesFirstName"
                        value={validation.values.salesFirstName}
                        onChange={validation.handleChange}
                      />
                    </div>
                  </Col>
                  <Col xs={6}>
                    <div className="mb-2">
                      <Label for="salesLastName" className="form-label mb-1">
                        Last Name
                      </Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="salesLastName"
                        name="salesLastName"
                        value={validation.values.salesLastName}
                        onChange={validation.handleChange}
                      />
                    </div>
                  </Col>
                  <Col xs={6}>
                    <div className="mb-2">
                      <Label for="salesPhone" className="form-label mb-1">
                        Phone Number
                      </Label>
                      <Input
                        type="text"
                        onWheel={(e) => e.target.blur()}
                        className="form-control"
                        id="salesPhone"
                        name="salesPhone"
                        value={validation.values.salesPhone}
                        onChange={(e) => blockInvalidCharForPhone(e, () => validation.handleChange(e))}
                        maxLength={15}
                      />
                    </div>
                  </Col>
                  <Col xs={6}>
                    <div className="mb-2">
                      <Label for="salesEmail" className="form-label mb-1">
                        Email Address
                      </Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="salesEmail"
                        name="salesEmail"
                        value={validation.values.salesEmail}
                        onChange={validation.handleChange}
                      />
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col xs={12} className="border position-relative px-3 py-3 mb-4 wh-border-thick mt-3">
                <Row>
                  <Label
                    className="fs-5 ml-2 text-dark position-absolute"
                    style={{ transform: "translateY(-50%)", top: 0, left: "10px" }}
                  >
                    <span className=" px-1 wh-item-bg"> Sales Representative 2 Contact Details</span>
                  </Label>
                  <Col xs={6}>
                    <div className="mb-2">
                      <Label for="salesFirstName2" className="form-label mb-1">
                        First Name
                      </Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="salesFirstName2"
                        name="salesFirstName2"
                        value={validation.values.salesFirstName2}
                        onChange={validation.handleChange}
                      />
                    </div>
                  </Col>
                  <Col xs={6}>
                    <div className="mb-2">
                      <Label for="salesLastName2" className="form-label mb-1">
                        Last Name
                      </Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="salesLastName2"
                        name="salesLastName2"
                        value={validation.values.salesLastName2}
                        onChange={validation.handleChange}
                      />
                    </div>
                  </Col>
                  <Col xs={6}>
                    <div className="mb-2">
                      <Label for="salesPhone2" className="form-label mb-1">
                        Phone Number
                      </Label>
                      <Input
                        type="text"
                        onWheel={(e) => e.target.blur()}
                        className="form-control"
                        id="salesPhone2"
                        name="salesPhone2"
                        value={validation.values.salesPhone2}
                        onChange={(e) => blockInvalidCharForPhone(e, () => validation.handleChange(e))}
                        maxLength={15}
                      />
                    </div>
                  </Col>
                  <Col xs={6}>
                    <div className="mb-2">
                      <Label for="salesEmail2" className="form-label mb-1">
                        Email Address
                      </Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="salesEmail2"
                        name="salesEmail2"
                        value={validation.values.salesEmail2}
                        onChange={validation.handleChange}
                      />
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
            </Col> */}
          </Col>

          {/* <Col xs={12} className="mb-3">
            <Row>
              <Col xs={7}>
                <Row className="g-0">
                  <Col xs={12} className="border position-relative px-3 py-3 wh-border-thick">
                    <Row>
                      <Label
                        className="fs-5 ml-2 text-dark position-absolute"
                        style={{ transform: "translateY(-50%)", top: 0, left: "10px" }}
                      >
                        <span className=" px-1 wh-item-bg">Additional Details</span>
                      </Label>
                      <Col xs={6}>
                        <div className="mb-3">
                          <Label for="creditLimit" className="form-label mb-1">
                            Credit Limit
                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="creditLimit"
                            name="creditLimit"
                            value={validation.values.creditLimit}
                            onChange={validation.handleChange}
                          />
                        </div>
                      </Col>
                      <Col xs={6}>
                        <div className="mb-3">
                          <Label for="fedTaxID" className="form-label mb-1">
                            Fed Tax ID
                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="fedTaxID"
                            name="fedTaxID"
                            value={validation.values.fedTaxID}
                            onChange={validation.handleChange}
                          />
                        </div>
                      </Col>
                      <Col xs={6}>
                        <div className="mb-3">
                          <Label for="stateTaxID" className="form-label mb-1">
                            State Tax ID
                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="stateTaxID"
                            name="stateTaxID"
                            value={validation.values.stateTaxID}
                            onChange={validation.handleChange}
                          />
                        </div>
                      </Col>
                      <Col xs={6}>
                        <div className="mb-3">
                          <Label for="companyName" className="form-label mb-1">
                            Ledger
                          </Label>
                          <Input type="text" className="form-control" id="companyName" />
                        </div>
                      </Col>
                      <Col xs={6}>
                        <div className="mb-3">
                          <Label for="paymentTerms" className="form-label mb-1">
                            Pay Term
                          </Label>
                          <Input
                            type="select"
                            className="form-select"
                            id="paymentTerms"
                            name="paymentTerms"
                            value={validation.values.paymentTerms}
                            onChange={validation.handleChange}
                          >
                            <option value="">Select</option>
                            {dropdownState.paymentTerms.map((item) => (
                              <option key={item}>{item}</option>
                            ))}
                          </Input>
                        </div>
                      </Col>
                      <Col xs={6}>
                        <div className="mb-3">
                          <Label for="discount" className="form-label mb-1">
                            Discount
                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="discount"
                            name="discount"
                            value={validation.values.discount}
                            onChange={validation.handleChange}
                          />
                        </div>
                      </Col>
                      <Col xs={12}>
                        <div>
                          <Label for="memo" className="form-label mb-1 text-nowrap">
                            Check Memo
                          </Label>
                          <div className="d-flex align-items-center">
                            <Input
                              id="memo"
                              name="memo"
                              value={validation.values.memo}
                              onChange={validation.handleChange}
                              className="form-control"
                              type="text"
                            />
                            <div className="form-check ms-2">
                              <Input
                                className="form-check-input"
                                type="checkbox"
                                id="printYtd"
                                name="printYtd"
                                checked={validation.values.printYtd}
                                onChange={validation.handleChange}
                              />
                              <Label className="form-check-label text-nowrap" htmlFor="printYtd">
                                Print YTD on check
                              </Label>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
              <Col xs={5}></Col>
            </Row>
          </Col> */}

          <Col xs={7}>
              <Label for="comments" className="form-label mb-1">
                Comments
              </Label>
              <textarea
                cols="4"
                className="form-control"
                id="comments"
                name="comments"
                value={validation.values.comments}
                onChange={validation.handleChange}
              ></textarea>
          </Col>
        </Row>
      </Form>
    </Container>
  );
};
