import React from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import F12CloseModalListener from './F12close';
const PdfPreviewModal = ({isOpen, handleClose, pdfSrc}) => {
  return (
    <Modal className='custom-modal' isOpen={isOpen} toggle={handleClose} size="xl"  centered >
      <F12CloseModalListener onClose={handleClose} />
      <ModalHeader toggle={handleClose} />
      <ModalBody style={{ maxHeight: '80vh', overflowY: 'auto' }}>
        <div style={{maxWidth: '57rem', margin: 'auto', border: 'none', minHeight: '85vh' }} dangerouslySetInnerHTML={{ __html: pdfSrc }} />
      </ModalBody>
    </Modal>
  );
};

export default PdfPreviewModal;

