import React, { useMemo, useEffect, useState, useRef } from "react";
import { useSelector, shallowEqual } from "react-redux";
import { Col, Container, Row } from "reactstrap";
import WhTableContainer from "../../../../Components/Common/WhTables";
import { generateColumns, tableData } from "./helper";
//Actions
import { getSupplierInvoicesAct } from "../../../../store/supplier/supplieractions";

export const UnpaidTab = ({ id, dispatch, generateApiUrl }) => {
  const { getSupplierInvoicesResp } = useSelector(
    ({ supplier }) => ({
      getSupplierInvoicesResp: supplier.getSupplierInvoicesResp,
    }),
    shallowEqual
  );
  const [tableData,setTableData] = useState([]);
      const prevGetSupplierInvoicesResp = useRef(getSupplierInvoicesResp);
      useEffect(() => {
        if(getSupplierInvoicesResp && prevGetSupplierInvoicesResp.current !== getSupplierInvoicesResp) {
          setTableData(getSupplierInvoicesResp.data.invoices)
        }
        prevGetSupplierInvoicesResp.current = getSupplierInvoicesResp;
      },[getSupplierInvoicesResp])
  useEffect(() => {
    if (id && id !== "new")
      dispatch(
        getSupplierInvoicesAct(generateApiUrl(`get_supplier_invoices`), {
          supplierNumber: id,
          paymentStatus: "unpaid",
        })
      );
  }, []);
  const handlePageChange = (page, pageSize) => {
    dispatch(
      getSupplierInvoicesAct(generateApiUrl(`get_supplier_invoices`), {
        supplierNumber: id,
        paymentStatus: "unpaid",
        page: page + 1,
        pageSize: pageSize,
      })
    );
  };
  const {
    data: { page },
  } = getSupplierInvoicesResp || { data: { page: 1 } };
  const columns = useMemo(() => generateColumns(page), [page]);
  return (
    <Container fluid className="px-4">
      <Row className="m-0">
        <Col xs={12}>
          <WhTableContainer
            columns={columns}
            data={tableData || []}
            customPageSize={100}
            className="custom-header-css"
            divClass="table-responsive mt-3 whbrand-table"
            tableClass="align-middle table-bordered text-center"
            theadClass="table-light"
            handlePageChange={handlePageChange}
            pageCount={Math.ceil((getSupplierInvoicesResp?.data?.total || 0) / 10)}
          
            showNoDataComponent={true}
            showPagination={tableData.length > 0}
          />
        </Col>
      </Row>
    </Container>
  );
};
