import React, { useMemo, useState, useRef, useEffect, useCallback } from "react";
import moment from "moment";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { Container, Row, Col, Label, Input, Modal, ModalHeader, Form, ModalBody } from "reactstrap";
import { toast } from "react-toastify";
import { actions, generateColumns, tableData } from "./helper";
import BackIcon from "../../../assets/images/warehouse/menu/back.png";
import WhTableContainer from "../../../Components/Common/WhTables";
import { getStartDateFilterValue, getEndDateFilterValue } from "../../../utility/dateutility";
import ApiLoader from "../../../Components/Common/ApiLoader";
//Api Helper
import { generateApiUrl } from "../../../api/apihelper";
//Actions
import { getDriversReportAct, getInvPricingReportPrintAct } from "../../../store/reports/reportsactions";
import { getDriversListAct } from "../../../store/masterdata/masterdataactions";
import F12CloseModalListener from "../../../Components/Common/F12close";
import PrintItem from "./PrintItem";

const DriversReport = ({ history }) => {
  const [filters, setFilters] = useState({
    driverType: "all",
    driver_id:"",
    startDate: moment().format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
    invoiceType:"all"
  });
  const [showPrintModal, setShowPrintModal] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [pageCount, setPageCount] = useState(0);

  const dispatch = useDispatch();
  const currentPageSize = useRef(30);
  const isDownloadBtnClicked = useRef(false);
  const { getDriversReportResp, getDriversReportLoading, getDriversListResp,getInvPricingReportLoading, reportsErrorResp, masterDataErrorResp } = useSelector(
    ({ reports, masterData }) => ({
      getDriversReportResp: reports.getDriversReportResp,
      getDriversReportLoading: reports.getDriversReportLoading,
      getDriversListResp: masterData.getDriversListResp,
      getInvPricingReportLoading: reports.getInvPricingReportLoading,
      reportsErrorResp: reports.reportsErrorResp,
      masterDataErrorResp: masterData.masterDataErrorResp,
    }),
    shallowEqual
  );
  function togglePrintModal() {
    setShowPrintModal(!showPrintModal);
  }
  const customerResp = useRef({
    prevGetDriversReportResp: getDriversReportResp,
    prevReportsErrorResp: reportsErrorResp,
    prevMasterDataErrorResp: masterDataErrorResp,
  });
  useEffect(() => {
    const { prevGetDriversReportResp, prevReportsErrorResp, prevMasterDataErrorResp } = customerResp.current || {};
    if (getDriversReportResp && prevGetDriversReportResp !== getDriversReportResp) {
      if (isDownloadBtnClicked.current) {
        const url = window.URL.createObjectURL(new Blob([getDriversReportResp]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
         "drivers_report.csv"
        );
        document.body.appendChild(link);
        link.click();
        link.remove();
        isDownloadBtnClicked.current = false;
      } else {
        setTableData(getDriversReportResp?.data?.report || []);
        currentPageSize.current = getDriversReportResp?.data?.pageSize;
        setPageCount((getDriversReportResp?.data?.total))
      }
    } else if (reportsErrorResp && prevReportsErrorResp !== reportsErrorResp) {
      const { errors, error, message } = reportsErrorResp || {};
      toast.error((Array.isArray(errors) && errors[0].msg) || error || message);
    } else if (masterDataErrorResp && prevMasterDataErrorResp !== masterDataErrorResp) {
      const { errors, error, message } = masterDataErrorResp || {};
      toast.error((Array.isArray(errors) && errors[0].msg) || error || message);
    }
    customerResp.current.prevGetDriversReportResp = getDriversReportResp;
    customerResp.current.prevReportsErrorResp = reportsErrorResp;
    customerResp.current.prevMasterDataErrorResp = masterDataErrorResp;
  }, [getDriversReportResp, reportsErrorResp, masterDataErrorResp]);

  const getSuppliersReportApiCall = useCallback(
    (page, pageSize, download, downloadType='pdf') => {
      const {  startDate, endDate, invoiceType, driverType, driver_id } = filters;
      let params = {};
      if (!download) {
        params.page = page ? page + 1 : 1;
        params.pageSize = pageSize || currentPageSize.current;
      }
      if (startDate) params.startDate = getStartDateFilterValue(startDate);
      if (endDate) params.endDate = getEndDateFilterValue(endDate);
      if(invoiceType === "paid") params.paid = true;
      if(invoiceType === "unpaid") params.paid = false;
      if(driverType === "individual" && driver_id) params.driver_id = driver_id;
      if (download && downloadType === "html") {
        params.download = true;
        params.downloadType = downloadType;
        dispatch(
          getInvPricingReportPrintAct(
            generateApiUrl(`get_drivers_report`),
            params
          )
        );
      } else if (download) {
        params.download = true;
        isDownloadBtnClicked.current = true;
        dispatch(
          getDriversReportAct(
            generateApiUrl(`get_drivers_report`),
            params,
            { responseType: "blob" }
          )
        );
      } else {
        dispatch(
          getDriversReportAct(
            generateApiUrl(`get_drivers_report`),
            params
          )
        );
      }
    },
    [filters]
  );

  useEffect(() => {
    const debounceFn = setTimeout(() => {
      getSuppliersReportApiCall();
    }, 400);
    return () => {
      clearTimeout(debounceFn);
    };
  }, [getSuppliersReportApiCall]);

  const handlePageChange = (page, pageSize) => {
    getSuppliersReportApiCall(page, pageSize);
  };

  useEffect(() => {
    dispatch(getDriversListAct(generateApiUrl(`get_drivers_list`)));
  }, []);

  const filtersChngHndlr = (e) => {
    const { value, checked, name, type } = e.target;
    setFilters((prev) => ({ ...prev, [name]: type === "checkbox" ? checked : value }));
  };

  const resetFilters = () => {
    setFilters({
      status: "",
      isOutOfState: false,
      isLocalTaxesPaid: false,
      isPOActivity: false,
      poStartDate: "",
      poEndDate: "",
    })
  }

  const columns = useMemo(() => generateColumns(), []);
  return (
   <>
    <Container fluid className=" pt-3 pb-0">
      <Row>
        <Col xs="12" className="mb-1">
        <div className="px-3 mb-3 pt-1 border rounded-1 report-header" style={{display:"inline-block"}}>
          <h3 style={{color:'#fff'}}>Drivers Report</h3>
         </div>
          <div className="border ps-4 my-3 mb-1" style={{ position: "relative" }}>
            <Label className="text-dark fs-5" style={{ position: "absolute", transform: "translateY(-50%)" }}>
            <span className=" px-1 wh-item-bg"> Filters</span>
            </Label>
            <Row>
              <Col xs="6" className="pe-5">
                <Row className="my-3 mb-1">
                  <Col xs={6}>
                    <Label htmlFor="companyName" className="form-label mb-0 text-nowrap">
                      User Type :
                    </Label>
                    <select className="form-select py-1">
                      <option>Driver</option>
                      <option>Salesman</option>
                    </select>
                  </Col>
                  <Col xs={6}>
                    <Label htmlFor="companyName" className="form-label mb-0 text-nowrap">
                      Date From :
                    </Label>
                    <Input
                      type="date"
                      className="form-control py-1"
                      name="startDate"
                      value={filters.startDate}
                      onChange={filtersChngHndlr}
                    />
                  </Col>
                </Row>

                <Row className="mb-1">
                  <Col xs={6}>
                    <Label htmlFor="companyName" className="form-label mb-0 text-nowrap">
                      Driver Type :
                    </Label>
                    <select
                      className="form-select py-1"
                      name="driverType"
                      value={filters.driverType}
                      onChange={filtersChngHndlr}
                    >
                      <option value="all">All</option>
                      <option value="individual">Individual</option>
                    </select>
                  </Col>
                  <Col xs={6}>
                    <Label htmlFor="companyName" className="form-label mb-0 text-nowrap">
                      Date To :
                    </Label>
                    <Input
                      type="date"
                      className="form-control py-1"
                      name="endDate"
                      value={filters.endDate}
                      onChange={filtersChngHndlr}
                    />
                  </Col>
                </Row>
                <Row className="mb-1">
                  <Col xs={6}>
                    {filters.driverType === "individual" && (
                      <>
                        <Label className="form-label mb-0 text-nowrap">
                          Driver :
                        </Label>
                        <select
                          className="form-select py-1"
                          name="driver_id"
                          value={filters.driver_id}
                          onChange={filtersChngHndlr}
                        >
                          <option value="">Select</option>
                          {(getDriversListResp?.data.drivers || []).map((item) => (
                            <option value={item.id} key={item.id}>
                              {item.name}
                            </option>
                          ))}
                        </select>
                      </>
                    )}
                  </Col>
                  <Col xs={6}>
                    <Label htmlFor="companyName" className="form-label mb-0 text-nowrap">
                      Invoice Type :
                    </Label>
                    <select
                      className="form-select py-1"
                      name="invoiceType"
                      value={filters.invoiceType}
                      onChange={filtersChngHndlr}
                    >
                      <option value="all">All</option>
                      <option value="paid">Paid</option>
                      <option value="unpaid">Unpaid</option>
                    </select>
                  </Col>
                </Row>
              </Col>
              <Col xs="2"></Col>
              <Col xs="4" className="ps-5">
                <Row className="h-100">
                  <Col xs="12" className="d-flex align-items-center pe-5">
                    <div className="sale-wh-wrapper flex-grow-1 mt-0 me-4">
                      <div className="d-flex justify-content-center border border-section py-2 px-1 h-100">
                        {actions(togglePrintModal, getSuppliersReportApiCall).map((item) => {
                          return (
                            <div
                              key={item.id}
                              className="action-icon-container"
                              onClick={item.clickHandler || (() => {})}
                              style={{cursor:"pointer"}}
                            >
                              <img className="action-icon" src={item.icon} />
                              <span>{item.label}</span>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                    <div
                      className="d-flex flex-column align-items-center"
                      style={{ cursor: "pointer" }}
                      onClick={() => history.push("/reportsmenu")}
                    >
                      <img src={BackIcon} width="32" height="32" alt="back-icon" />
                      <div className="text-decoration-underline mb-0">Go Back</div>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </Col>
        <Col xs="12">
          <WhTableContainer
            columns={columns}
            data={tableData || []}
            className="custom-header-css"
            divClass="table-responsive table-container-drivers-report"
            tableClass="align-middle table-bordered"
            theadClass="table-light"
            handlePageChange={handlePageChange}
            pageCount={pageCount || 0}
            customPageSize={30}
            showNoDataComponent={true}
          />
        </Col>
      </Row>
      <ApiLoader loading={getDriversReportLoading} />
    </Container>
    <Modal
     id="showPrintModal"
     isOpen={showPrintModal}
     toggle={togglePrintModal}
     centered
     size="lg"
   >
     <F12CloseModalListener onClose={togglePrintModal} />
     <ModalHeader className="py-2 px-3" toggle={togglePrintModal}>
       Print Options
     </ModalHeader>
     <Form
       onSubmit={(e) => {
         e.preventDefault();
       }}
     >
       <ModalBody className="px-0 pt-0">
         <PrintItem
           getSuppliersReportApiCall={getSuppliersReportApiCall}
           filters={filters}
           showPrintModal={showPrintModal}
         />
       </ModalBody>
     </Form>
   </Modal>
   <ApiLoader loading={getInvPricingReportLoading} />
   </>
  );
};

export default DriversReport;
