import supplierconstants from "./supplierconstants";

export const getSuppliersListAct = (apiEndpoint, payload, optionalConfig) => {
  return {
    type: supplierconstants.GET_SUPPLIERS_LIST_REQUEST,
    apiEndpoint,
    payload,
    optionalConfig,
  };
};

export const changeGetSuppliersListLoadingStatusAct = (status) => {
  return {
    type: supplierconstants.CHANGE_GET_SUPPLIERS_LIST_LOADING_STATUS,
    payload: status,
  };
};

export const createSupplierAct = (apiEndpoint, payload, optionalConfig) => {
  return {
    type: supplierconstants.CREATE_SUPPLIER_REQUEST,
    apiEndpoint,
    payload,
    optionalConfig,
  };
};

export const getSupplierAct = (apiEndpoint, payload, optionalConfig) => {
  return {
    type: supplierconstants.GET_SUPPLIER_REQUEST,
    apiEndpoint,
    payload,
    optionalConfig,
  };
};

export const updateSupplierDetailsAct = (apiEndpoint, payload, optionalConfig) => {
  return {
    type: supplierconstants.UPDATE_SUPPLIER_DETAILS_REQUEST,
    apiEndpoint,
    payload,
    optionalConfig,
  };
};

export const getSupplierInvoicesAct = (apiEndpoint, payload, optionalConfig) => {
  return {
    type: supplierconstants.GET_SUPPLIER_INVOICES_REQUEST,
    apiEndpoint,
    payload,
    optionalConfig,
  };
};

export const getMasterDataForSupplierAct = (apiEndpoint, payload, optionalConfig) => {
  return {
    type: supplierconstants.GET_MASTER_DATA_FOR_SUPPLIER_REQUEST,
    apiEndpoint,
    payload,
    optionalConfig,
  };
};

export const deleteSupplierAct = (apiEndpoint, payload, optionalConfig) => {
  return {
    type: supplierconstants.DELETE_SUPPLIER_REQUEST,
    apiEndpoint,
    payload,
    optionalConfig,
  };
};

export const getItemsOfSupplierAct = (apiEndpoint, payload, optionalConfig) => {
  return {
    type: supplierconstants.GET_ITEMS_OF_SUPPLIER_REQUEST,
    apiEndpoint,
    payload,
    optionalConfig,
  };
};

export const getMaxSupplierNumAct = (apiEndpoint, payload, optionalConfig) => {
  return {
    type: supplierconstants.GET_MAX_SUPPLIER_NUM_REQUEST,
    apiEndpoint,
    payload,
    optionalConfig,
  };
};

export const changeSupplierApiLoadingStatusAct = (status) => {
  return {
    type: supplierconstants.CHANGE_SUPPLIER_API_LOADING_STATUS,
    payload: status,
  };
};