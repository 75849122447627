import moment from "moment";

const removeZfromISOString = (isoString) => {
  const userTimezone = moment.tz.guess();
  if(isoString.length === 24) return moment(moment.tz(isoString.slice(0,23),'America/New_York')).tz(userTimezone);
  return isoString;
}

export function getMmddyyyyDateFormatted(dateValue) {
  const updatedDateValue = typeof(dateValue) === "string" ? removeZfromISOString(dateValue) : dateValue;
  return moment(updatedDateValue).format("MM-DD-YYYY");
}

export function getYyyymmddDateFormatted(dateValue) {
  const updatedDateValue = typeof(dateValue) === "string" ? removeZfromISOString(dateValue) : dateValue;
  return moment(updatedDateValue).format("YYYY-MM-DD");
}

export function getCustomDateFormatted(dateValue, format) {
  const updatedDateValue = typeof(dateValue) === "string" ? removeZfromISOString(dateValue) : dateValue;
  return moment(updatedDateValue).format(format);
}

// export function getStartDateFilterValue(dateValue) {
//   return moment(`${dateValue} 00:00:00`).clone().tz('America/New_York').format("YYYY-MM-DD HH:mm:ss");
// }

export function getStartDateFilterValue(dateValue) {
  const localTimezone = moment.tz.guess();
  return moment.tz(dateValue, localTimezone).format("YYYY-MM-DD HH:mm:ss");
}
export function getEndDateFilterValue(dateValue) {
  return moment(`${dateValue} 23:59:59`).clone().tz('America/New_York').format("YYYY-MM-DD HH:mm:ss");
}
