import masterdataconstants from "./masterdataconstants";

const initialState = {
  getDriversListResp: undefined,
  getSalesmanListResp: undefined,
  getMobileProvidersResp: undefined,
  getStatesListResp: undefined,
  getZipcodeResp: undefined,
  getMsaReportAsResp: undefined,
  getStateReportAsResp: undefined,
  masterDataErrorResp: undefined,
};

export default function masterData(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case masterdataconstants.GET_DRIVERS_LIST_SUCCESS:
      return { ...state, getDriversListResp: payload };
    case masterdataconstants.GET_SALESMAN_LIST_SUCCESS:
      return { ...state, getSalesmanListResp: payload };
    case masterdataconstants.GET_MOBILE_PROVIDERS_SUCCESS:
      return { ...state, getMobileProvidersResp: payload };
    case masterdataconstants.GET_STATES_LIST_SUCCESS:
      return { ...state, getStatesListResp: payload };
    case masterdataconstants.GET_ZIPCODE_SUCCESS:
      return { ...state, getZipcodeResp: payload };
    case masterdataconstants.GET_MSA_REPORT_AS_SUCCESS:
      return { ...state, getMsaReportAsResp: payload };
    case masterdataconstants.GET_STATE_REPORT_AS_SUCCESS:
      return { ...state, getStateReportAsResp: payload };

    case masterdataconstants.GET_DRIVERS_LIST_FAIL:
    case masterdataconstants.GET_SALESMAN_LIST_FAIL:
    case masterdataconstants.GET_MOBILE_PROVIDERS_FAIL:
    case masterdataconstants.GET_STATES_LIST_FAIL:
    case masterdataconstants.GET_ZIPCODE_FAIL:
    case masterdataconstants.GET_MSA_REPORT_AS_FAIL:
    case masterdataconstants.GET_STATE_REPORT_AS_FAIL:
      return { ...state, masterDataErrorResp: payload };
    default:
      return state;
  }
}
