import { call, put, takeLatest, delay } from "redux-saga/effects";
import { getData, postData, putData, deleteData } from "../../api/apiactions";
import { handleApiResponse } from "../../utility/sagautility";
import salesconstants from "./salesconstants";
import { changeSalesApiLoadingStateAct } from "./salesactions"

function withLoading(fn) {
    return function* withLoadingGenerator(...args) {
        yield put(changeSalesApiLoadingStateAct(true));
        yield delay(500);
        yield* fn(...args);
        yield put(changeSalesApiLoadingStateAct(false));
    }
}

function* createInvoiceGen(action) {
    const response = yield call(postData, action.apiEndpoint, action.payload, action.optionalConfig);
    yield call(handleApiResponse, response, {
        successAction:salesconstants.CREATE_INVOICE_SUCCESS,
        failAction:salesconstants.CREATE_INVOICE_FAIL,
    })
}

function* updateInvoiceGen(action) {
    const response = yield call(putData, action.apiEndpoint, action.payload, action.optionalConfig);
    yield call(handleApiResponse, response, {
        successAction:salesconstants.UPDATE_INVOICE_SUCCESS,
        failAction:salesconstants.UPDATE_INVOICE_FAIL,
    })
}

function* deleteInvoiceGen(action) {
    const response = yield call(deleteData, action.apiEndpoint, action.payload, action.optionalConfig);
    yield call(handleApiResponse, response, {
        successAction:salesconstants.DELETE_INVOICE_SUCCESS,
        failAction:salesconstants.DELETE_INVOICE_FAIL,
    })
}

function* getMaxInvoiceNoGen(action) {
    const response = yield call(getData, action.apiEndpoint, action.payload, action.optionalConfig);
    yield call(handleApiResponse, response, {
        successAction:salesconstants.GET_MAX_INVOICE_NO_SUCCESS,
        failAction:salesconstants.GET_MAX_INVOICE_NO_FAIL,
    })
}

function* getInvoiceDetailsGen(action) {
    const response = yield call(getData, action.apiEndpoint, action.payload, action.optionalConfig);
    yield call(handleApiResponse, response, {
        successAction:salesconstants.GET_INVOICE_DETAILS_SUCCESS,
        failAction:salesconstants.GET_INVOICE_DETAILS_FAIL,
    })
}

function* changePostedStatusOfInvoiceGen(action) {
    const response = yield call(postData, action.apiEndpoint, action.payload, action.optionalConfig);
    yield call(handleApiResponse, response, {
        successAction:salesconstants.CHANGE_POSTED_STATUS_OF_INVOICE_SUCCESS,
        failAction:salesconstants.CHANGE_POSTED_STATUS_OF_INVOICE_FAIL,
    })
}

function* downloadInvoicePdfGen(action) {
    const response = yield call(getData, action.apiEndpoint, action.payload, action.optionalConfig);
    yield call(handleApiResponse, response, {
        successAction:salesconstants.DOWNLOAD_INVOICE_PDF_SUCCESS,
        failAction:salesconstants.DOWNLOAD_INVOICE_PDF_FAIL,
    })
}

export function* salesSaga() {
    yield takeLatest(salesconstants.CREATE_INVOICE_REQUEST, withLoading(createInvoiceGen));
    yield takeLatest(salesconstants.UPDATE_INVOICE_REQUEST, withLoading(updateInvoiceGen));
    yield takeLatest(salesconstants.DELETE_INVOICE_REQUEST, withLoading(deleteInvoiceGen));
    yield takeLatest(salesconstants.GET_MAX_INVOICE_NO_REQUEST, getMaxInvoiceNoGen);
    yield takeLatest(salesconstants.GET_INVOICE_DETAILS_REQUEST, withLoading(getInvoiceDetailsGen));
    yield takeLatest(salesconstants.CHANGE_POSTED_STATUS_OF_INVOICE_REQUEST, withLoading(changePostedStatusOfInvoiceGen));
    yield takeLatest(salesconstants.DOWNLOAD_INVOICE_PDF_REQUEST, withLoading(downloadInvoicePdfGen));
}
