import React, {useEffect, useRef, useState} from 'react';
import {Modal, ModalHeader, ModalBody} from 'reactstrap';
import F12CloseModalListener from '../../Components/Common/F12close';

const InvoiceResetConfirm = ({
  isOpen,
  toggleModal,
  confirmHandlder,
  rejectHandler,
}) => {
  const [focusedButton, setFocusedButton] = useState ('yes');
  const yesButtonRef = useRef (null);
  const noButtonRef = useRef (null);

  useEffect (
    () => {
      const handleKeyDown = e => {
        if (isOpen && e.key === 'ArrowRight') {
          e.preventDefault ();
          setFocusedButton (
            prevButton => (prevButton === 'yes' ? 'no' : 'yes')
          );
        } else if (isOpen && e.key === 'ArrowLeft') {
          e.preventDefault ();
          setFocusedButton (prevButton => (prevButton === 'no' ? 'yes' : 'no'));
        }
      };
      window.addEventListener ('keydown', handleKeyDown);
    },
    [isOpen]
  );
  useEffect(() => {
    if (isOpen) {
      const buttonRef = focusedButton === 'yes' ? yesButtonRef : noButtonRef;
      setTimeout(() => {
        if (buttonRef.current) {
          buttonRef.current.focus();
        }
      }, 0);
    }
  }, [isOpen, focusedButton, yesButtonRef, noButtonRef]);

  return (
    <Modal isOpen={isOpen} toggle={toggleModal} centered>
      <F12CloseModalListener onClose={toggleModal} />
      <ModalHeader className="border-bottom py-2">Save the changes</ModalHeader>
      <ModalBody className="py-0 pb-2">
        <div className="mt-2">
          <div className="pt-2 fs-15">
            {/* <h4>Are you sure ?</h4> */}
            <p className="text-muted mb-0">
              Do you want save the changed infomation?
            </p>
          </div>
        </div>
        <div className="d-flex gap-2 justify-content-end mt-2 mb-2">
          <button
            ref={yesButtonRef}
            type="button"
            className= {`btn w-sm  ${focusedButton === "yes" ? "btn-primary border border-danger" : "btn-light"}`}
            id="delete-record"
            onClick={confirmHandlder}
            onKeyDown={(e) => {
              if (e.key === "ArrowRight") {
                e.preventDefault();
                setFocusedButton("no");
              }
            }}
          >
            Yes
          </button>
          <button
            type="button"
            ref={noButtonRef}
            className={`btn w-sm  ${focusedButton === "no" ? "btn-primary border border-danger" : "btn-light"}`}
            data-bs-dismiss="modal"
            onClick={rejectHandler}
            onKeyDown={(e) => {
              if (e.key === "ArrowLeft") {
                e.preventDefault();
                setFocusedButton("yes");
              }
            }}
          >
            No
          </button>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default InvoiceResetConfirm;
