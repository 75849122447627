import React, { useEffect } from 'react';

const F12CloseModalListener = ({ onClose }) => {
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'F12' || e.key === "Escape" || e.key === "Esc") {
        onClose();
      }
    };
    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [onClose]);
  return null;
};

export default F12CloseModalListener;
