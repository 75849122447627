import employeeconstants from "./employeeconstants";

const initialState = {
  employeeApiLoading: undefined,
  createEmployeeResp: undefined,
  updateEmployeeResp: undefined,
  getEmployeesResp: undefined,
  deleteEmployeesResp: undefined,
  employeeErrorResp: undefined,
};

export default function employee(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case employeeconstants.CHANGE_EMPLOYEE_API_LOADING_STATUS:
      return { ...state, employeeApiLoading: payload };
    case employeeconstants.CREATE_EMPLOYEE_SUCCESS:
      return { ...state, createEmployeeResp: payload };
    case employeeconstants.UPDATE_EMPLOYEE_SUCCESS:
      return { ...state, updateEmployeeResp: payload };
    case employeeconstants.GET_EMPLOYEES_SUCCESS:
      return (state = { ...state, getEmployeesResp: action.payload });
    case employeeconstants.DELETE_EMPLOYEES_SUCCESS:
      return (state = { ...state, deleteEmployeesResp: action.payload });

    case employeeconstants.CREATE_EMPLOYEE_FAIL:
    case employeeconstants.GET_EMPLOYEES_FAIL:
    case employeeconstants.UPDATE_EMPLOYEE_FAIL:
    case employeeconstants.DELETE_EMPLOYEES_FAIL:
      return { ...state, employeeErrorResp: payload };
    default:
      return state;
  }
}
