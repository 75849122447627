import * as Yup from "yup";
export const inventoryFinancialInitialValues = {
    cost : "",
    retailPrice : "",
    profit:"",
    mfgPromotion:"",
    addToCost:"",
    costFixed:true,
    costPerQty:true,
    osCost:"",
    osRetail:"",
    salePrice : "",
    saleFrom:"",
    saleTo:"",
    salePriceOos:"",
    discount:"",
    discountValue:"",
    discFrom:"",
    discTo:"",
    discR:false,
    discA:false,
    discB:false,
    discC:false,
    discD:false,
    custDisc:false,
    priceA:"",
    priceB:"",
    priceC:"",
    priceD:"",
};

export const inventoryFinancialValidationSchema = Yup.object({
    cost: Yup.string().required("Cost is required"),
    retailPrice: Yup.string().required("Retail Price is required"),
})

export const calculateProfitPercent = (cost,retailPrice) => {
    if(cost && retailPrice)
      return  ((((Number(retailPrice) - Number(cost)) / Number(retailPrice)) * 100) || 0).toFixed(2);
    else return ""
}