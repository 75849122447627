import React from "react";
import { Redirect } from "react-router-dom";

//Warehouse Routes
import WarehouseLogin from "../pages/WarehouseLogin";
import WarehouseMenu from "../pages/WarehouseMenu";
import ReportsMenu from "../pages/ReportsMenuWH";

//Warehouse Sales Routes
import SalesWH from "../pages/SalesWH";
import WarehouseSales from "../pages/WarehouseSales";
import WarehouseCustomers from "../pages/WarehouseCustomers";
import Customer from "../pages/CustomerWH";
import Inventory from "../pages/InventoryWH";
import WarehouseInventory from "../pages/WarehouseInventory";
import WarehouseVendors from "../pages/WarehouseVendors";
import WarehouseVendor from "../pages/VendorWH";
import PurchaseOrder from "../pages/PurchaseOrderWH";
import WarehousePurchaseOrders from "../pages/WarehousePurchaseOrders";

//Warehouse Adminitrative Routes
import GeneralSettings from "../pages/AdministrationWH/GeneralSettings";
import UserManagement from "../pages/AdministrationWH/UserManagement";
import SystemLevel from "../pages/AdminSystemLevel";
import ProfitLoss from "../pages/AdminProfitLoss";
import DataAnalysis from "../pages/AdminDataAnalysis";
import AdminTools from "../pages/AdminTools";

//Reports Routes
import SupplierReport from "../pages/ReportsWH/SupplierReport";
import POAnalysis from "../pages/ReportsWH/POAnalysis";
import InventoryPricingReport from "../pages/ReportsWH/InventoryPricingReport";
import CustomerRoutesReport from "../pages/ReportsWH/CustomerRoutesReport";
import PaymentHistory from "../pages/ReportsWH/PaymentAndSalesReport/PaymentHistory";
import SalesHistory from "../pages/ReportsWH/PaymentAndSalesReport/SalesHistory";
import SalesByHighestOrder from "../pages/ReportsWH/PaymentAndSalesReport/SalesByHighestOrder";
import InventorySales from "../pages/ReportsWH/InventoryPurchasesAndSales/Sales";
import InventoryPurchases from "../pages/ReportsWH/InventoryPurchasesAndSales/Purchases";
import Purchases from "../pages/ReportsWH/CashPaidout/Purchases";
import Sales from "../pages/ReportsWH/CashPaidout/Sales";
import DriversReport from "../pages/ReportsWH/DriversReport";
import CustomersReport from "../pages/ReportsWH/CustomersReport";
import ReturnSalesReport from "../pages/ReportsWH/ReturnSalesReport";
import CreateBundle from "../pages/InventoryWH/CreateBundle";
import Bundles from "../pages/InventoryWH/Bundles";
import WarehouseOrdersHistory from "../pages/WarehouseOrdersHistory";

const warehousePublicRoutes = [
  {
    path: "/warehouselogin", component: WarehouseLogin
  },
]

const authWarehouseBlankRoutes = [
  {
    path: '/warehousemenu',
    component: WarehouseMenu
  },
  {
    path: '/reportsmenu',
    component: ReportsMenu
  },
  {
    path: '/supplierreport',
    component: SupplierReport
  },
  {
    path: '/poanalysis',
    component: POAnalysis
  },
  {
    path: '/inventorypricingreport',
    component: InventoryPricingReport
  },
  {
    path: '/customersreport',
    component: CustomersReport
  },
  {
    path: '/returnsalesreport',
    component: ReturnSalesReport
  },
  {
    path: '/customerroutesreport',
    component: CustomerRoutesReport
  },
  {
    path: '/paymentandsalesreport/paymenthistory',
    component: PaymentHistory
  },
  {
    path: '/paymentandsalesreport/saleshistory',
    component: SalesHistory
  },
  {
    path: '/paymentandsalesreport/salesbyhighestorder',
    component: SalesByHighestOrder
  },
  {
    path: '/inventorypurchasesandsales/purchases',
    component: InventoryPurchases
  },
  {
    path: '/inventorypurchasesandsales/sales',
    component: InventorySales
  },
  {
    path: '/cashpaidout/purchases',
    component: Purchases
  },
  {
    path: '/cashpaidout/sales',
    component: Sales
  },
  {
    path: '/driversreport',
    component: DriversReport
  },
]

const authWareHouseRoutes = [
  {
    path: '/warehousesales',
    component: WarehouseSales
  },
  {
    path: '/warehousesales/:id',
    component: SalesWH
  },
  {
    path: '/warehousecustomers',
    component: WarehouseCustomers
  },
  {
    path: '/warehousecustomers/:id',
    component: Customer
  },
  {
    path: '/warehouseinventory',
    component: WarehouseInventory
  },
  {
    path: '/warehouseinventory/bundles-createbundle/:id',
    component: Inventory,
    routes: [
      {
        path: '/warehouseinventory/bundles-createbundle/:id',
        component: CreateBundle,
      },
    ],
  },
  
  {
    path: '/warehouseinventory/:id',
    component: Inventory
  },

  {
    path: '/warehousevendors',
    component: WarehouseVendors
  },
  {
    path: '/warehousevendors/:id',
    component: WarehouseVendor
  },
  {
    path: '/warehousepurchaseorders',
    component: WarehousePurchaseOrders
  },
  {
    path: '/warehousepurchaseorders/:id',
    component: PurchaseOrder
  },
  {
    path: '/warehouseorderhistory',
    component: WarehouseOrdersHistory
  },
]

const adminWareHouseRoutes = [
  {
    path: '/admin/generalsettings',
    component: GeneralSettings
  },
  {
    path: '/admin/usermanagement',
    component: UserManagement
  },
  {
    path: '/admin/systemlevel',
    component: SystemLevel
  },
  {
    path: '/admin/profitloss',
    component: ProfitLoss
  },
  {
    path: '/admin/analysisreport',
    component: DataAnalysis
  }, {
    path: '/admin/admintools',
    component: AdminTools
  },
  {
    path: '/',
    component: () => <Redirect to="/warehouselogin" />
  }
]

export { warehousePublicRoutes, authWarehouseBlankRoutes, authWareHouseRoutes, adminWareHouseRoutes };