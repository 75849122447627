//Utility
import { getMmddyyyyDateFormatted } from "../../../../../utility/dateutility"
//Constants
import { currencySymbol } from "../../../../../constants";

export function generateColumns() {
    return [
        {
            Header: "Invoice No",
            accessor: "invoiceNumber",
            // Cell:({cell:{value}}) => <Link to={`/warehousesales/${value}`}>{value}</Link>
        }, 
        {
            Header: "Invoice Date",
            accessor: "invoiceDate",
            Cell:({cell:{value}}) => value ? getMmddyyyyDateFormatted(value) : "-"
        }, 
        {
            Header: "Sales",
            accessor: "quantity",
        }, 
        {
            Header: "Return",
            accessor: "return",
        }, 
        {
            Header: "Damage",
            accessor: "damage",
        }, 
        {
            Header: "Price",
            accessor: "price",
            Cell : ({cell : {value}}) =>(
                <div style={{ textAlign: 'right'}}>
                  {typeof value === "number" ? `${currencySymbol}${value.toFixed(2)}` : ""}
                </div>
              ),
        },
        {
            Header: "Total",
            accessor: "RAmount",
            Cell : ({cell : {value}}) => (
                <div style={{ textAlign: 'right'}}>
                {typeof value == "number" ? `${currencySymbol}${value.toFixed(2)}` : ""}
                </div>
                  ),
        },
    ]
}