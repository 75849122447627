import React from "react";
import { Modal, ModalHeader, ModalBody, Form } from "reactstrap";
import F12CloseModalListener from "../../../Common/F12close";
import Draggable from "react-draggable";

const PaymentsModal = ({ isOpen, toggle, children }) => {
  return (
    <Draggable cancel=".cancledrag">
    <Modal id="showPaymentsModal" isOpen={isOpen} toggle={toggle} centered size="xl">
       <F12CloseModalListener onClose={toggle} />
      <ModalHeader className="py-2 px-3" toggle={toggle}>
        General Payments
      </ModalHeader>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <ModalBody className="px-0 pt-0 cancledrag">{children}</ModalBody>
      </Form>
    </Modal>
    </Draggable>
  );
};

export default PaymentsModal;
