import designationconstants from "./designationconstants";

export const changeDesignationApiLoadingStatusAct = (status) => {
    return {
      type: designationconstants.CHANGE_DESIGNATION_API_LOADING_STATUS,
      payload: status,
    };
};
  

export const createDesignationAct = (apiEndpoint, payload, optionalConfig) => {
  return {
    type: designationconstants.CREATE_DESIGNATION_REQUEST,
    apiEndpoint,
    payload,
    optionalConfig,
  };
};

export const updateDesignationAct = (apiEndpoint, payload, optionalConfig) => {
    return {
      type: designationconstants.UPDATE_DESIGNATION_REQUEST,
      apiEndpoint,
      payload,
      optionalConfig,
    };
};

export const getDesignationsAct = (apiEndpoint, payload, optionalConfig) => {
    return {
      type: designationconstants.GET_DESIGNATIONS_REQUEST,
      apiEndpoint,
      payload,
      optionalConfig,
    };
};

export const deleteDesignationsAct = (apiEndpoint, payload, optionalConfig) => {
    return {
      type: designationconstants.DELETE_DESIGNATIONS_REQUEST,
      apiEndpoint,
      payload,
      optionalConfig,
    };
};
