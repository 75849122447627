import PrintIcon from "../../../../assets/images/warehouse/salespage/printer.png";
import GenerateIcon from "../../../../assets/images/warehouse/salespage/generate.png";
import ExportIcon from "../../../../assets/images/warehouse/salespage/excel.png";
import ResetIcon from "../../../../assets/images/warehouse/salespage/reset.png";
import { Link } from "react-router-dom";

export const actions = (togglePrintModal,getSuppliersReportApiCall,resetFilters) => [
  {
    id: "reset",
    label: "Reset",
    icon: ResetIcon,
    clickHandler: resetFilters
  },
  {
    id: "print",
    label: "Print",
    icon: PrintIcon,
    // clickHandler:togglePrintModal
  },
  {
    id: "export",
    label: "Export",
    icon: ExportIcon,
    clickHandler : () => getSuppliersReportApiCall(0,10,true)
  },
];

export function generateColumns() {
  return [
    {
      Header: "Customer No",
      accessor: "customerId",
      Cell:({cell:{value}}) => <Link target="_blank" to={`/warehousecustomers/${value}`}>{value}</Link>,
    },
    {
      Header: "Company",
      accessor: "company",
      Cell:({row:{original:{customerId,company}}}) => <Link target="_blank" to={`/warehousecustomers/${customerId}`}>{company}</Link>,
    },
    {
      Header: "Total Invoices",
      accessor: "totalInvoices",
    },
    {
      Header: "Total Inv Total",
      accessor: "totalInvAmount",
    },
    {
      Header: "Total Paid",
      accessor: "totalPaid",
    },
  ];
}
export const tableData = [
  {
    id: "1503",
    item: "New Salem Conv Store",
    description: "28",
    quantity: "$132,094.52",
    caseQuantity: "$132,094.52",
  },
  {
    id: "1503",
    item: "New Salem Conv Store",
    description: "28",
    quantity: "$132,094.52",
    caseQuantity: "$132,094.52",
  },
  {
    id: "1503",
    item: "New Salem Conv Store",
    description: "28",
    quantity: "$132,094.52",
    caseQuantity: "$132,094.52",
  },
  {
    id: "1503",
    item: "New Salem Conv Store",
    description: "28",
    quantity: "$132,094.52",
    caseQuantity: "$132,094.52",
  },
  {
    id: "1503",
    item: "New Salem Conv Store",
    description: "28",
    quantity: "$132,094.52",
    caseQuantity: "$132,094.52",
  },
  {
    id: "1503",
    item: "New Salem Conv Store",
    description: "28",
    quantity: "$132,094.52",
    caseQuantity: "$132,094.52",
  },
  {
    id: "1503",
    item: "New Salem Conv Store",
    description: "28",
    quantity: "$132,094.52",
    caseQuantity: "$132,094.52",
  },
];
