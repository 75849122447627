import React, { useState, useRef, useEffect, useMemo, useCallback, useContext } from "react";
import moment from "moment";
import {toast} from"react-toastify";
import {
  Button,
  Input,
  Label,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import classnames from "classnames";
import SelectItem from "../SelectItem";
import { PaymentsTab } from "./PaymentsTab";
import UnpaidInvoicesTab from "./UnpaidInvoicesTab";
import {getCkValue} from "./PaymentsTab"
//Actions
import {
  getInvoicesOfCustomerAct,
  getAllCustomersAct,
  getCustomerAct,
  acceptPaymentsAnywhereAct,
} from "../../../store/customer/customeractions";
//Helper
import { generateApiUrl } from "../../../api/apihelper";
import { customerColumnsList } from "./helper";
//Context
import { PermissionsContext } from "../../../context/PermissionsContext";
//Assets
import PrinterIcon from "../../../assets/images/warehouse/salespage/printer_old.png";
import Draggable from "react-draggable";
import F12CloseModalListener from "../F12close";
import { formatAmountSymbol } from "../../../utility/commonutility";

const tabs = [
  {
    id: "payments",
    name: "Payments",
  },
  {
    id: "unpaid_invoices",
    name: "Unpaid Invoices",
  },
];

function getActiveComponent(activeId) {
  switch (activeId) {
    case "payments":
      return PaymentsTab;
    case "unpaid_invoices":
      return UnpaidInvoicesTab;
    default:
      return null;
  }
}

const PaymentsAnywhere = ({customerNo="", invoices=[], isPaymentAnywhereModelOpen}) => {
  const [showModal, setShowModal] = useState(false);
  const [search, setSearch] = useState("");
  const [customersBalance, setCustomersBalance] = useState(0);
  const [searchListOptions, setSearchListOptions] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [justifyPillsTab, setjustifyPillsTab] = useState("payments");
  const [customerNumber, setCustomerNumber] = useState("");
  const [invoicesForPayOrder, setInvoicesForPayOrder] = useState([]);
  const [payments, setPayments] = useState([]);
  const [negativeBalancePaymentDetails, setNegativeBalancePaymentDetails] = useState({
    paymentMethod:"",
    creditCustomersBalance:true,
  })
  const [adjustInBalance, setAdjustInBalance] = useState(false);
  const dropdownBtnClicked = useRef(false);
  const callbackOnCustomerListApiResp = useRef(() => {});

  const {permissions} = useContext(PermissionsContext);

  const initializePaymentsAnywhereData = () => {
    if(!showModal) {
      setCustomersBalance(0);
      setPayments([{
        amountPaid: "",
        amountAlloc: "",
        paymentType: "",
        chequeNo: "",
        tax: "",
        holdDate: "",
      }]);
      setInvoicesForPayOrder([]);
      setCustomerNumber("");
      setjustifyPillsTab("payments");
      setAdjustInBalance(false);
      setNegativeBalancePaymentDetails({
        paymentMethod:"",
        creditCustomersBalance:true,
      });
      setSearch("")
    } else {
      setInvoicesForPayOrder(invoices);
      setCustomerNumber(customerNo);
    }
  }

  useEffect(() => {
    if(customerNumber)
      dispatch(getCustomerAct(generateApiUrl(`get_customer`, { customerNo:customerNumber })));
  }, [customerNumber])

  const togglePaymentModal = () => {
    if(customersBalance >= 0 ? permissions["ACCEPT_PAYMENTS"] : permissions["RETURN_PAYMENTS"])
      setShowModal(prev => !prev);
  }
  const justifyPillsToggle = (tab) => {
    if (justifyPillsTab !== tab) {
      setjustifyPillsTab(tab);
    }
  };
  const dispatch = useDispatch();
  const { getAllCustomersResp, getInvoicesOfCustomerResp, acceptPaymentsAnywhereResp, getCustomerResp, customerErrResp } = useSelector(
    ({ customer }) => ({
      getAllCustomersResp: customer.getAllCustomersResp,
      getInvoicesOfCustomerResp: customer.getInvoicesOfCustomerResp,
      acceptPaymentsAnywhereResp: customer.acceptPaymentsAnywhereResp,
      getCustomerResp: customer.getCustomerResp,
      customerErrResp: customer.customerErrResp,
    }),
    shallowEqual
  );
  const apiResp = useRef({
    prevGetAllCustomersResp: getAllCustomersResp,
    prevGetInvoicesOfCustomerResp: getInvoicesOfCustomerResp,
    prevAcceptPaymentsAnywhereResp: acceptPaymentsAnywhereResp,
    prevGetCustomerResp: getCustomerResp,
    prevCustomerErrResp: customerErrResp,
  });
  useEffect(() => {
    const { prevGetAllCustomersResp, prevGetInvoicesOfCustomerResp, prevGetCustomerResp, prevAcceptPaymentsAnywhereResp, prevCustomerErrResp } = apiResp.current;
    if (getAllCustomersResp && prevGetAllCustomersResp !== getAllCustomersResp && showModal) {
      const list = [];
      (getAllCustomersResp?.data?.customers || []).forEach(({ customerNumber, firstName, lastName, companyName }) => {
        list.push({
          customerNumber,
          customerName: `${firstName || ""} ${lastName || ""}`,
          companyName,
        });
      });
      setSearchListOptions(list);
    }
    if (getInvoicesOfCustomerResp && prevGetInvoicesOfCustomerResp !== getInvoicesOfCustomerResp && showModal) {
      setTableData(getInvoicesOfCustomerResp.data.invoices);
    }
    if (getCustomerResp && prevGetCustomerResp !== getCustomerResp && showModal) {
      const {
        data: {
          customer: { firstName, lastName, companyName, customerNumber, currentBalance },
        },
      } = getCustomerResp;
      setCustomersBalance(currentBalance);
      setCustomerNumber(customerNumber);
      setSearch(`${companyName || ""}${firstName || lastName ? "-" : ""}${firstName || ""} ${lastName || ""}`);
    }
    if (acceptPaymentsAnywhereResp && prevAcceptPaymentsAnywhereResp !== acceptPaymentsAnywhereResp && showModal) {
      toast.success("Successfully accepted the payments");
      togglePaymentModal();
    }
    if (customerErrResp && prevCustomerErrResp !== customerErrResp && showModal) {
      const { errors, error, message } = customerErrResp || {};
      toast.error((Array.isArray(errors) && errors[0].msg) || error || message);
    }
    apiResp.current.prevGetAllCustomersResp = getAllCustomersResp;
    apiResp.current.prevGetInvoicesOfCustomerResp = getInvoicesOfCustomerResp;
    apiResp.current.prevGetCustomerResp = getCustomerResp;
    apiResp.current.prevAcceptPaymentsAnywhereResp = acceptPaymentsAnywhereResp;
    apiResp.current.prevCustomerErrResp = customerErrResp;
  }, [getAllCustomersResp, getInvoicesOfCustomerResp, getCustomerResp, acceptPaymentsAnywhereResp, customerErrResp]);

  useEffect(() => {
    if (search === "") setSearchListOptions([]);
  }, [search]);
  const getAllCustomersApiCall = useCallback(
    (showAllCustomers, page) => {
      if (showAllCustomers) {
        let params = { page: page || 1, pageSize: 50, orderBy: "company", sort: "asc" };
        dispatch(getAllCustomersAct(generateApiUrl(`get_all_customers`), params));
        dropdownBtnClicked.current = true;
      } else if (search) {
        let params = { page: 1, pageSize: 5, search, orderBy: "company", sort: "asc" };
        dispatch(getAllCustomersAct(generateApiUrl(`get_all_customers`), params));
        dropdownBtnClicked.current = false;
      }
    },
    [search]
  );
  useEffect(() => {
    const debounceFn = setTimeout(() => {
      getAllCustomersApiCall();
    }, 200);
    return () => {
      clearTimeout(debounceFn);
    };
  }, [getAllCustomersApiCall]);

  useEffect(() => {
    if(customerNumber && showModal) {
      dispatch(
        getInvoicesOfCustomerAct(generateApiUrl("get_invoices_of_customer"), {
          customerNumber: customerNumber,
          status: "unpaid",
          posted: true,
          page: 1,
          pageSize: 10,
        })
      );
    }
  }, [customerNumber]);

  useEffect(() => {
    initializePaymentsAnywhereData();
    if (showModal) {
      if(isPaymentAnywhereModelOpen) isPaymentAnywhereModelOpen.current = true;
    } else {
      if(isPaymentAnywhereModelOpen) isPaymentAnywhereModelOpen.current = false;
    }
  }, [showModal]);

  function selectItemCallbck(itemDetails) {
    const { customerNumber } = itemDetails;
    setCustomerNumber(customerNumber);
  }
  function handleAcceptPaymentsAnywhere() {
    const paymentsArr = [];
    for (let i in payments) {
      const { amountPaid, amountAlloc } = payments[i];
      paymentsArr.push({ ...payments[i], change: adjustInBalance ? 0 : amountPaid - amountAlloc, comments: getCkValue(payments[i])});
    }
    dispatch(
      acceptPaymentsAnywhereAct(generateApiUrl(`accept_payments_anywhere`,{customerNumber}), {
        invoices: invoicesForPayOrder,
        payments: paymentsArr,
        creditCustomersBalance: negativeBalancePaymentDetails.creditCustomersBalance,
        paymentMethodForPayCustomer: negativeBalancePaymentDetails.paymentMethod,
        // currentDate: moment() created at backend
      })
    );
  }
  const TabComponent = useMemo(() => getActiveComponent(justifyPillsTab), [justifyPillsTab, invoicesForPayOrder]);

  const f9Handler = (e) => {
    const { key } = e;
    if (key === "F9") {
      e.preventDefault();
      togglePaymentModal();
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", f9Handler);
    return () => {
      window.removeEventListener("keydown", f9Handler);
    };
  },[permissions, customersBalance]);

  return (
    <Draggable cancel=".cancledrag">
    <Modal id="showPaymentsModal" isOpen={showModal} toggle={togglePaymentModal} size="xl">
    <F12CloseModalListener onClose={togglePaymentModal} />
      <ModalHeader toggle={togglePaymentModal} className="py-2 border-bottom">
        Customer Payments
      </ModalHeader>
      <ModalBody className="px-0 pt-0 cancledrag">
        <div className="px-4 py-2 mb-2 d-flex justify-content-between align-items-end">
          <div className="flex-grow-1 me-5">
            <Label htmlFor="userrole" className="form-label mb-1">
              Search Customer
            </Label>
            <SelectItem
              searchValue={search}
              setSearchValue={setSearch}
              listOptions={searchListOptions}
              columnsList={customerColumnsList}
              placeholder="Select Customer"
              showDropdownBtn={true}
              selectedItemHandler={selectItemCallbck}
              fieldKey="companyName"
              dataIdKey="customerNumber"
              getAllCustomersApiCall={getAllCustomersApiCall}
              containerClassName={"flex-grow-1"}
              dropdownBtnClickHndlr={(setShowOptions) => {
                getAllCustomersApiCall(true);
                setShowOptions(true);
              }}
            />
          </div>
          <div className="d-flex align-items-center">
            <Label className="form-label mb-0 text-nowrap me-2">Balance Due :</Label>
            <div className="form-icon">
              <Input
                name="invoiceTotal"
                id="invoiceTotal"
                className="form-control form-control-icon"
                placeholder="0.00"
                type="text"
                readOnly
                value={customersBalance.toFixed(2)}
              />
              <i style={{ fontStyle: "normal" }}>{formatAmountSymbol()}</i>
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-end px-4 pt-3">
            <Button
              color="primary"
              outline
              className="me-2 wh-btn"
              onClick={handleAcceptPaymentsAnywhere}
              disabled={justifyPillsTab !== "payments" || !customerNumber}
            >
              <span className="d-flex justify-content-center align-items-center">
                <img src={PrinterIcon} style={{ height: "16px", width: "16px" }} />
                <span className="ms-2">Print</span>
              </span>
            </Button>
            <Button
              color="primary"
              className="wh-btn"
              outline
              onClick={handleAcceptPaymentsAnywhere}
              disabled={justifyPillsTab !== "payments" || !customerNumber}
            >
              <span className="d-flex justify-content-center align-items-center">
                <span className="ms-2">Silent</span>
              </span>
            </Button>
          </div>
        </div>

        <Nav pills className="mb-3 bg-light px-4">
          {tabs.map((item) => (
            <NavItem key={item.id}>
              <NavLink
                style={{ cursor: "pointer" }}
                className={classnames({ active: justifyPillsTab === item.id })}
                onClick={() => {
                  justifyPillsToggle(item.id);
                }}
              >
                {item.name}
              </NavLink>
            </NavItem>
          ))}
        </Nav>
        <TabContent activeTab={justifyPillsTab} className="text-muted">
          <TabPane tabId={justifyPillsTab} id="pill-justified-home-1">
            {TabComponent && (
              <TabComponent
                tableData={tableData}
                dispatch={dispatch}
                getInvoicesOfCustomerResp={getInvoicesOfCustomerResp}
                customerNumber={customerNumber}
                invoicesForPayOrder={invoicesForPayOrder}
                setInvoicesForPayOrder={setInvoicesForPayOrder}
                payments={payments}
                setPayments={setPayments}
                customersBalance={customersBalance}
                adjustInBalance={adjustInBalance}
                setAdjustInBalance={setAdjustInBalance}
                negativeBalancePaymentDetails={negativeBalancePaymentDetails}
                setNegativeBalancePaymentDetails={setNegativeBalancePaymentDetails}
              />
            )}
          </TabPane>
        </TabContent>
      </ModalBody>
    </Modal>
    </Draggable>
  );
};

export default PaymentsAnywhere;
