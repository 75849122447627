import { createContext, useState, useEffect, useRef } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
//Api Helper
import { generateApiUrl } from "../api/apihelper";
//Actions
import { getAllPermissionsAct, getPermissionsByRoleAct } from "../store/usermanagement/usermanagementactions";

export const PermissionsContext = createContext();

export const PermissionsContextProvider = ({ children }) => {
  const [permissions, setPermissions] = useState([]);
  const [loadingPermissions, setLoadingPermissions] = useState(true);
  const [userId, setUserId] = useState(JSON.parse(localStorage.getItem("user"))?.role?.id || null);

  const dispatch = useDispatch();

  useEffect(() => {
    if (userId) {
      setLoadingPermissions(true);
      dispatch(getAllPermissionsAct(generateApiUrl("get_all_permissions")));
      dispatch(getPermissionsByRoleAct(generateApiUrl("get_permissions_by_role", { id: userId })));
    }
  }, [userId]);
  const { getAllPermissionsResp, getPermissionsByRoleResp, userManagementErrorResp } = useSelector(
    ({ userManagement }) => ({
      getAllPermissionsResp: userManagement.getAllPermissionsResp,
      getPermissionsByRoleResp: userManagement.getPermissionsByRoleResp,
      userManagementErrorResp: userManagement.userManagementErrorResp
    }),
    shallowEqual
  );
  const apiResp = useRef({
    prevGetAllPermissionsResp: getAllPermissionsResp,
    prevGetPermissionsByRoleResp: getPermissionsByRoleResp,
    prevUserManagementErrorResp: userManagementErrorResp
  });
  useEffect(() => {
    const { prevGetAllPermissionsResp, prevGetPermissionsByRoleResp, prevUserManagementErrorResp } = apiResp.current;
    if (
      (getAllPermissionsResp && prevGetAllPermissionsResp !== getAllPermissionsResp && getPermissionsByRoleResp) ||
      (getPermissionsByRoleResp && prevGetPermissionsByRoleResp !== getPermissionsByRoleResp && getAllPermissionsResp)
    ) {
      const { permissions: allPermissions } = getAllPermissionsResp.data;
      const { rows } = getPermissionsByRoleResp.data.rolePermissions;
      const userPermissions = {};
      rows.forEach((item) => {
        const { value } = item;
        const { code } = allPermissions.find((it) => it.id === item.permissionId);
        userPermissions[code] = value || true;
      });
      setPermissions(userPermissions);
      setLoadingPermissions(false);
    }
    else if(userManagementErrorResp && prevUserManagementErrorResp !== userManagementErrorResp) {
      setLoadingPermissions(false);
    }
    apiResp.current.prevGetAllPermissionsResp = getAllPermissionsResp;
    apiResp.current.prevGetPermissionsByRoleResp = getPermissionsByRoleResp;
    apiResp.current.prevUserManagementErrorResp = userManagementErrorResp;
  }, [getAllPermissionsResp, getPermissionsByRoleResp, userManagementErrorResp]);

  const handleLogin = (userId) => {
    setUserId(userId);
  };
  const handleLogout = () => {
    localStorage.clear();
    setUserId(null);
    setPermissions([]);
  };
  const value = { permissions, loadingPermissions, handleLogin, handleLogout };
  return <PermissionsContext.Provider value={value}>{children}</PermissionsContext.Provider>;
};
