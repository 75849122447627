export default {
    CHANGE_DESIGNATION_API_LOADING_STATUS: "CHANGE_DESIGNATION_API_LOADING_STATUS",
  
    CREATE_DESIGNATION_REQUEST: "CREATE_DESIGNATION_REQUEST",
    CREATE_DESIGNATION_SUCCESS: "CREATE_DESIGNATION_SUCCESS",
    CREATE_DESIGNATION_FAIL: "CREATE_DESIGNATION_FAIL",
  
    UPDATE_DESIGNATION_REQUEST: "UPDATE_DESIGNATION_REQUEST",
    UPDATE_DESIGNATION_SUCCESS: "UPDATE_DESIGNATION_SUCCESS",
    UPDATE_DESIGNATION_FAIL: "UPDATE_DESIGNATION_FAIL",
  
    GET_DESIGNATIONS_REQUEST: "GET_DESIGNATIONS_REQUEST",
    GET_DESIGNATIONS_SUCCESS: "GET_DESIGNATIONS_SUCCESS",
    GET_DESIGNATIONS_FAIL: "GET_DESIGNATIONS_FAIL",

    DELETE_DESIGNATIONS_REQUEST: "DELETE_DESIGNATIONS_REQUEST",
    DELETE_DESIGNATIONS_SUCCESS: "DELETE_DESIGNATIONS_SUCCESS",
    DELETE_DESIGNATIONS_FAIL: "DELETE_DESIGNATIONS_FAIL",
  };
  