export const customerColumnsList = [
    {
      id: "customerNumber",
      name: "Cust. Num",
      cellClassName: "fw-medium",
    },
    {
      id: "companyName",
      name: "Company",
    },
    {
      id: "customerName",
      name: "Customer Name",
      cellClassName: "fbadge badge-soft-primary",
    },
  ];
  