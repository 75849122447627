import React, { useState, useEffect, useMemo, useCallback } from "react";
import { Col, Container, Input, Label, Row, Button } from "reactstrap";
import WhTableContainer from "../../Components/Common/WhTables";
import { getSearchLabel } from "./helper";
import { generateColumns } from "./searchinventoryhelper";
import { getStartDateFilterValue } from "../../utility/dateutility";

export default function SearchInventory({
  setFilters,
  setShowSearchModal,
  modalTableData,
  modalPageCount,
  getInventoryListAct,
  generateApiUrl,
  dispatch,
  getInventoryListLoading,
}) {
  const [search, setSearch] = useState("");
  const [searchBy, setSearchBy] = useState("description");
  const getAllInventoryApiCall = useCallback(
    (page, pageSize) => {
      let params = {
        page: page ? page + 1 : 1,
        pageSize: pageSize || 30,
      };
      if (search) {
        if (searchBy !== "createdDate" && searchBy !== "lastUsedDate") params[searchBy] = search;
        else params[searchBy] = getStartDateFilterValue(search);
      }
      dispatch(getInventoryListAct(generateApiUrl(`get_inventory_list`), params));
    },
    [search, searchBy]
  );
  useEffect(() => {
    const debounceFn = setTimeout(() => {
      getAllInventoryApiCall();
    }, 400);
    return () => {
      clearTimeout(debounceFn);
    };
  }, [getAllInventoryApiCall]);
  const handlePageChange = (page, pageSize) => {
    getAllInventoryApiCall(page, pageSize);
  };
  const handleChange = (e) => {
    const { name } = e.target;
    if (searchBy != name) {
      setSearchBy(name);
      if (name === "createdDate" || searchBy === "createdDate") setSearch("");
      else if (name === "lastUsedDate" || searchBy === "lastUsedDate") setSearch("");
    }
  };
  // const searchHandler = () => {
  //   setFilters(prev => ({...prev, search, searchBy}));
  //   setShowSearchModal(false);
  // }
  const columns = useMemo(() => generateColumns(), []);
  return (
    <Container fluid>
      <Row className="mb-4">
        <Col xs={12} className="bg-primary text-center fs-5 text-white py-2">
          Smart Search-Inventory
        </Col>
      </Row>
      <Row className="mb-4">
        <Col xs={12}>
          <div className="px-3" style={{ position: "relative" }}>
            <Label
              className="fs-5 font-weight-bold "
              style={{ position: "absolute", transform: "translateY(-50%)" }}
            >
               <span className=" px-1 wh-item-bg"> Type Of Search</span>
            </Label>
            <Row className="border pt-4 pb-3">
              <Col xs="4" className="mb-2">
                <Input
                  type="radio"
                  name="description"
                  className="me-2"
                  onChange={handleChange}
                  checked={searchBy == "description"}
                />{" "}
                By Item Name
              </Col>
              <Col xs="4">
                <Input
                  type="radio"
                  name="itemNumber"
                  className="me-2"
                  onChange={handleChange}
                  checked={searchBy == "itemNumber"}
                />{" "}
                By Item Number
              </Col>
              <Col xs="4" className="mb-2">
                <Input
                  type="radio"
                  name="category"
                  className="me-2"
                  onChange={handleChange}
                  checked={searchBy == "category"}
                />{" "}
                By Category
              </Col>
              <Col xs="4" className="mb-2">
                <Input
                  type="radio"
                  name="family"
                  className="me-2"
                  onChange={handleChange}
                  checked={searchBy == "family"}
                />{" "}
                By Family
              </Col>
              <Col xs="4" className="mb-2">
                <Input
                  type="radio"
                  name="manufacturer"
                  className="me-2"
                  onChange={handleChange}
                  checked={searchBy == "manufacturer"}
                />{" "}
                By Manufacturer
              </Col>

              <Col xs="4" className="mb-2">
                <Input
                  type="radio"
                  name="model"
                  className="me-2"
                  onChange={handleChange}
                  checked={searchBy == "model"}
                />{" "}
                By Model
              </Col>
              {/* <Col xs="4" className="mb-2">
                  <Input type="radio" name="searchType" className="me-2" /> By Company*
                </Col> */}
              <Col xs="4">
                <Input
                  type="radio"
                  name="upcCode"
                  className="me-2"
                  onChange={handleChange}
                  checked={searchBy == "upcCode"}
                />{" "}
                By UPC Code
              </Col>
              <Col xs="4">
                <Input
                  type="radio"
                  name="createdDate"
                  className="me-2"
                  onChange={handleChange}
                  checked={searchBy == "createdDate"}
                />{" "}
                By Created Date
              </Col>
              <Col xs="4">
                <Input
                  type="radio"
                  name="lastUsedDate"
                  className="me-2"
                  onChange={handleChange}
                  checked={searchBy == "lastUsedDate"}
                />{" "}
                By Last Used Date
              </Col>
              {/* <Col xs="4">
                  <Input type="radio" name="searchType" className="me-2" /> By Other Reference
                </Col> */}
            </Row>
          </div>
        </Col>
      </Row>

      {searchBy && (
        <Row className="mb-2">
          <Col xs={12}>
            <Label htmlFor="userrole" className="form-label mb-1">
              Search By {getSearchLabel(searchBy)}
            </Label>
            <div className="d-flex align-items-center">
              {searchBy !== "createdDate" && searchBy !== "lastUsedDate" ? (
                <Input
                  className="form-control me-2"
                  type="text"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
              ) : (
                <Input
                  className="form-control me-2"
                  type="date"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
              )}
              {/* <Button color="primary" onClick={searchHandler} disabled={search ? false : true}>Search</Button> */}
            </div>
          </Col>
        </Row>
      )}

      {/* <Row>
          <Col xs="12" className="d-flex justify-content-end"></Col>
        </Row> */}
      <Row>
        <Col xs={12} style={{ minHeight: "350px", maxHeight: "350px", overflowY: "auto" }}>
          <WhTableContainer
            columns={columns}
            data={modalTableData}
            className="custom-header-css"
            divClass="table-responsive mt-3"
            tableClass="align-middle"
            theadClass="table-light"
            handlePageChange={handlePageChange}
            pageCount={modalPageCount}
            customPageSize={30}
            showNoDataComponent={true}
            loading={getInventoryListLoading}
          />
        </Col>
      </Row>
    </Container>
  );
}
