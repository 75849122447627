import employeeconstants from "./employeeconstants";

export const changeEmployeeApiLoadingStatusAct = (status) => {
    return {
      type: employeeconstants.CHANGE_EMPLOYEE_API_LOADING_STATUS,
      payload: status,
    };
};
  

export const createEmployeeAct = (apiEndpoint, payload, optionalConfig) => {
  return {
    type: employeeconstants.CREATE_EMPLOYEE_REQUEST,
    apiEndpoint,
    payload,
    optionalConfig,
  };
};

export const updateEmployeeAct = (apiEndpoint, payload, optionalConfig) => {
    return {
      type: employeeconstants.UPDATE_EMPLOYEE_REQUEST,
      apiEndpoint,
      payload,
      optionalConfig,
    };
};

export const getEmployeesAct = (apiEndpoint, payload, optionalConfig) => {
    return {
      type: employeeconstants.GET_EMPLOYEES_REQUEST,
      apiEndpoint,
      payload,
      optionalConfig,
    };
};

export const deleteEmployeesAct = (apiEndpoint, payload, optionalConfig) => {
    return {
      type: employeeconstants.DELETE_EMPLOYEES_REQUEST,
      apiEndpoint,
      payload,
      optionalConfig,
    };
};
