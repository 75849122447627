import { customerUrl } from "./apiurl/customerurl";
import { masterDataUrl } from "./apiurl/masterdataurl";
import { authUrl } from "./apiurl/authurl";
import { supplierUrl } from "./apiurl/supplierurl";
import { inventoryUrl } from "./apiurl/inventoryurl";
import { purchaseOrderUrl } from "./apiurl/purchaseordreurl";
import { salesUrl } from "./apiurl/salesurl";
import {userManagementUrl} from "./apiurl/usermanagementurl";
import {employeeUrl} from "./apiurl/employeeurl";
import {departmentUrl} from"./apiurl/departmenturl";
import { designationUrl } from "./apiurl/designationurl";
import { reportsUrl } from "./apiurl/reportsurl"

export const errorCodes = [400, 401];

// crud before api name is used to represent all api action will use this url
// add is used to show post action
// update is used to show put action
// get is used to show get action

// const authUrl = {
//     login_user: `api/auth/login`,
//     logout_user: `api/auth/logout`
// }

const apiUrlKeys = {
  ...customerUrl,
  ...masterDataUrl,
  ...authUrl,
  ...supplierUrl,
  ...inventoryUrl,
  ...purchaseOrderUrl,
  ...salesUrl,
  ...userManagementUrl,
  ...employeeUrl,
  ...departmentUrl,
  ...designationUrl,
  ...reportsUrl
};

export function generateApiUrl(apiKey, payload) {
  const apiUrl = apiUrlKeys[apiKey];
  return typeof apiUrl === "function" ? apiUrl(payload || {}) : apiUrl;
}
