import React, { useState } from "react";
import { Modal, ModalBody, Input, FormFeedback } from "reactstrap";
//Constants
import { overridePassword } from "../../../../constants";

const OverridePassword = ({ isOpen, toggle, tempTableDataForOverridePassword, setTableData, isOverridePasswordEnteredDetails, 
    getInventoryItemApiCall,tempItemNumber, overrideModaltext = [] }) => {
  const [password, setPassword] = useState("");
  const [showInvalidPassText, setShowInvalidPassText] = useState(false);

  const handlePasswordChange = (e) => {
    if (showInvalidPassText) setShowInvalidPassText(false);
    setPassword(e.target.value);
  };

  const validatePassword = () => {
    if (password === overridePassword) {
      setShowInvalidPassText(false);
      setTableData(tempTableDataForOverridePassword.current);
      tempTableDataForOverridePassword.current = [];
      if (isOverridePasswordEnteredDetails.current.overridePasswordFor === "customerSalesLimit") {
        isOverridePasswordEnteredDetails.current.customerSalesLimit = true;
      } else if (isOverridePasswordEnteredDetails.current.overridePasswordFor === "itemSalesLimit") {
        isOverridePasswordEnteredDetails.current.itemSalesLimit = true;
      } else if (isOverridePasswordEnteredDetails.current.overridePasswordFor === "both") {
        isOverridePasswordEnteredDetails.current.customerSalesLimit = true;
        isOverridePasswordEnteredDetails.current.itemSalesLimit = true;
      }
      toggle();
      if (tempItemNumber.current) {
        getInventoryItemApiCall(tempItemNumber.current);
        tempItemNumber.current = "";
      }
    } else {
      setShowInvalidPassText(true);
    }
  };
  return (
    <Modal isOpen={isOpen} toggle={toggle} centered={true}>
    <ModalBody className="py-3 px-5">
      <>
        <div className="mt-2">
          <div className="text-center">
            <i className="ri-error-warning-line text-warning" style={{ fontSize: "48px" }}></i>
          </div>
          <div className="pt-2 fs-15">
            <p className="text-muted mb-2">
              {overrideModaltext.map((item, index) => (
                <div key={index}>{item}</div>
              ))}
            </p>
          </div>
          <Input type="password" value={password} onChange={handlePasswordChange} invalid={showInvalidPassText} />
          <FormFeedback>Wrong Password</FormFeedback>
        </div>
        <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
          <button type="button" className="btn w-sm btn-success" id="delete-record" onClick={validatePassword}>
            Yes
          </button>
          <button
            type="button"
            className="btn w-sm btn-light"
            data-bs-dismiss="modal"
            onClick={toggle}
          >
            Close
          </button>
        </div>
      </>
    </ModalBody>
  </Modal>
  );
};

export default OverridePassword;
