import salesconstants from "./salesconstants";

export const createInvoiceAct = (apiEndpoint, payload, optionalConfig) => {
  return {
    type: salesconstants.CREATE_INVOICE_REQUEST,
    apiEndpoint,
    payload,
    optionalConfig,
  };
};

export const updateInvoiceAct = (apiEndpoint, payload, optionalConfig) => {
  return {
    type: salesconstants.UPDATE_INVOICE_REQUEST,
    apiEndpoint,
    payload,
    optionalConfig,
  };
};

export const deleteInvoiceAct = (apiEndpoint, payload, optionalConfig) => {
  return {
    type: salesconstants.DELETE_INVOICE_REQUEST,
    apiEndpoint,
    payload,
    optionalConfig,
  };
};

export const getMaxInvoiceNoAct = (apiEndpoint, payload, optionalConfig) => {
  return {
    type: salesconstants.GET_MAX_INVOICE_NO_REQUEST,
    apiEndpoint,
    payload,
    optionalConfig,
  };
};

export const getInvoiceDetailsAct = (apiEndpoint, payload, optionalConfig) => {
  return {
    type: salesconstants.GET_INVOICE_DETAILS_REQUEST,
    apiEndpoint,
    payload,
    optionalConfig,
  };
};

export const changePostedStatusOfInvoiceAct = (apiEndpoint, payload, optionalConfig) => {
  return {
    type: salesconstants.CHANGE_POSTED_STATUS_OF_INVOICE_REQUEST,
    apiEndpoint,
    payload,
    optionalConfig,
  };
};

export const downloadInvoicePdfAct = (apiEndpoint, payload, optionalConfig) => {
  return {
    type: salesconstants.DOWNLOAD_INVOICE_PDF_REQUEST,
    apiEndpoint,
    payload,
    optionalConfig,
  };
};

export const changeSalesApiLoadingStateAct = (status) => {
  return {
    type: salesconstants.CHANGE_SALES_API_LOADING_STATUS,
    payload: status,
  };
};