import ReportIcon from "../../assets/images/warehouse/menu/report.png";
import BackIcon from "../../assets/images/warehouse/menu/back.png";

export const menuList = [
    {
        id: "supplier_vendor_activities",
        name: "Supplier Vendor Activities",
        imgSrc: ReportIcon,
        url:"/supplierreport",
    },
    {
        id: "po_analysis",
        name: "PO Analysis",
        imgSrc: ReportIcon,
        url:"/poanalysis",
    },
    {
        id: "inventory_and_pricing_report",
        name: "Inventory & Pricing Report",
        imgSrc: ReportIcon,
        url:"/inventorypricingreport",
    },
    // {
    //     id: "po_item_price_change",
    //     name: "PO Item Price Change",
    //     imgSrc: ReportIcon,
    // },
    {
        id: "customers_report",
        name: "Customers Report",
        imgSrc: ReportIcon,
        url:"/customersreport"
    },
    {
        id: "route_and_delivery",
        name: "Route & Delivery",
        imgSrc: ReportIcon,
        url:"/customerroutesreport",
    },
    {
        id: "payment_and_sales_report",
        name: "Payment & Sales Report",
        imgSrc: ReportIcon,
        url:"/paymentandsalesreport/paymenthistory"
    },
    {
        id: "inventory_purchases_and_sales",
        name: "Inventory Purchases & Sales",
        imgSrc: ReportIcon,
        url:"/inventorypurchasesandsales/purchases"
    },
    // {
    //     id: "cash_paidout",
    //     name: "Cash Paidout",
    //     imgSrc: ReportIcon,
    //     url:"/cashpaidout/purchases"
    // },
    {
        id: "drivers_report",
        name: "Drivers Report",
        imgSrc: ReportIcon,
        url:"/driversreport"
    },
    {
        id: "return_sales_report",
        name: "Return Sales Report",
        imgSrc: ReportIcon,
        url:"/returnsalesreport"
    },
    {
        id: "main_menu",
        name: "Main Menu",
        imgSrc: BackIcon,
        url:"/warehousemenu"
    },

]