import React, { useMemo, useState, useEffect, useRef, useCallback } from "react";
import { Col, Container, Row, Nav, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";

import WhTableContainer from "../../../../Components/Common/WhTables";
import Payments from "./payments";

import { generateColumns } from "./helper";
//Actions
import { getInvoicesOfCustomerAct } from "../../../../store/customer/customeractions";

export const UnpaidInvoicesTab = ({
  history,
  dispatch,
  generateApiUrl,
  id,
  useSelector,
  shallowEqual,
  invoicesForPayOrder,
  setInvoicesForPayOrder,
  isPaymentAnywhereModelOpen
}) => {
  const [customActiveTab, setcustomActiveTab] = useState("1");
  const toggleCustom = (tab) => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab);
    }
  };
  const [tableData, setTableData] = useState([]);
  const { getInvoicesOfCustomerResp, acceptPaymentsAnywhereResp } = useSelector(
    ({ customer }) => ({
      getInvoicesOfCustomerResp: customer.getInvoicesOfCustomerResp,
      acceptPaymentsAnywhereResp: customer.acceptPaymentsAnywhereResp,
    }),
    shallowEqual
  );
  const apiResp = useRef({
    prevGetInvoicesOfCustomerResp: getInvoicesOfCustomerResp,
    prevAcceptPaymentsAnywhereResp: acceptPaymentsAnywhereResp,
  });
  useEffect(() => {
    const { prevGetInvoicesOfCustomerResp, prevAcceptPaymentsAnywhereResp } = apiResp.current;
    if (getInvoicesOfCustomerResp && prevGetInvoicesOfCustomerResp !== getInvoicesOfCustomerResp && !isPaymentAnywhereModelOpen.current) {
      setTableData(getInvoicesOfCustomerResp.data.invoices);
    }
    if (acceptPaymentsAnywhereResp && prevAcceptPaymentsAnywhereResp !== acceptPaymentsAnywhereResp) {
      dispatch(
        getInvoicesOfCustomerAct(generateApiUrl("get_invoices_of_customer"), {
          customerNumber: id,
          status: "unpaid",
          posted: customActiveTab === "1" ? true : false,
          page: 1,
          pageSize: 10,
        })
      );
    }
    apiResp.current.prevGetInvoicesOfCustomerResp = getInvoicesOfCustomerResp;
    apiResp.current.prevAcceptPaymentsAnywhereResp = acceptPaymentsAnywhereResp;
  }, [getInvoicesOfCustomerResp, acceptPaymentsAnywhereResp]);
  useEffect(() => {
    if (id && id !== "new")
      dispatch(
        getInvoicesOfCustomerAct(generateApiUrl("get_invoices_of_customer"), {
          customerNumber: id,
          status: "unpaid",
          posted: customActiveTab === "1" ? true : false,
          page: 1,
          pageSize: 10,
          orderBy:"invoiceDate",
          sort:"desc"
        })
      );
  }, [customActiveTab]);
  const handlePageChange = (page, pageSize) => {
    dispatch(
      getInvoicesOfCustomerAct(generateApiUrl("get_invoices_of_customer"), {
        customerNumber: id,
        status: "unpaid",
        page: page + 1,
        pageSize,
        orderBy:"invoiceDate",
        sort:"desc"
      })
    );
  };

  const columns = useMemo(
    () => generateColumns(history, customActiveTab, invoicesForPayOrder, setInvoicesForPayOrder),
    [customActiveTab, history, invoicesForPayOrder, setInvoicesForPayOrder]
  );

  return (
    <React.Fragment>
      <Container fluid className="px-4 whbrand-text-color">
        <Row className="m-0">
          <Col xs={12} className="whbrand-navs">
            <Nav tabs className="nav nav-tabs nav-tabs-custom nav-success mb-2">
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer", }}
                  className={classnames({ active: customActiveTab === "1" })}
                  onClick={() => {
                    toggleCustom("1");
                  }}
                >
                  Posted
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({ active: customActiveTab === "2" })}
                  onClick={() => {
                    toggleCustom("2");
                  }}
                >
                  Open
                </NavLink>
              </NavItem>
            </Nav>
          </Col>
        </Row>
        <Row className="m-0">
          <Col className="px-0" xs={12}>
            <WhTableContainer
              columns={columns}
              data={tableData || []}
              className="custom-header-css"
              divClass="table-responsive mt-3 whbrand-table"
              tableClass="align-middle table-bordered"
              theadClass="table-light"
              handlePageChange={handlePageChange}
              pageCount={Math.ceil((getInvoicesOfCustomerResp?.data?.total || 0) / 10)}
              customPageSize={30}
              showNoDataComponent={true}
            />
          </Col>
        </Row>
      </Container>
      {/* <Payments
        id={id}
        history={history}
        showModal={showPaymentModal}
        togglePaymentModal={togglePaymentModal}
        invoicesForPayOrder={invoicesForPayOrder}
        setInvoicesForPayOrder={setInvoicesForPayOrder}
      /> */}
    </React.Fragment>
  );
};
