import PrintIcon from "../../../../assets/images/warehouse/salespage/printer.png";
import ExportIcon from "../../../../assets/images/warehouse/salespage/excel.png";
import GenerateTableIcon from "../../../../assets/images/warehouse/salespage/generate_tables.png";

export const actions = (toggle, togglePrintModal) => [
    {
        id: "reset",
        label: "Generate",
        icon: GenerateTableIcon
    }, {
        id: "print",
        label: "Print",
        icon: PrintIcon,
        clickHandler: togglePrintModal,
    }, {
        id: "export",
        label: "Export",
        icon: ExportIcon
    }
]

export function generateColumns() {
    return [
        {
            Header: "Item",
            accessor: "item"
        },
        {
            Header: "Description",
            accessor: "description"
        }, {
            Header: "Inventory Date",
            accessor: "inventoryDate"
        }, {
            Header: "Qty",
            accessor: "qty"
        }, {
            Header: "Purchases",
            accessor: "purchases"
        }, {
            Header: "Sales",
            accessor: "sales"
        }, {
            Header: "Adjustments",
            accessor: "adjustments"
        }, {
            Header: "Accurate Qty",
            accessor: "accurateQty"
        }, {
            Header: "Current Qty",
            accessor: "currentQty"
        }
    ]
}
export const tableData = [
    {
        item: "2402",
        description: "4K 4 / 99C Mango",
        inventoryDate: "02-07-2022",
        qty: "76",
        purchases: "259",
        sales: "240",
        adjustments: "0",
        accurateQty: "103",
        currentQty: "102"
    }, {
        item: "2402",
        description: "4K 4 / 99C Mango",
        inventoryDate: "02-07-2022",
        qty: "76",
        purchases: "259",
        sales: "240",
        adjustments: "0",
        accurateQty: "103",
        currentQty: "102"
    }, {
        item: "2402",
        description: "4K 4 / 99C Mango",
        inventoryDate: "02-07-2022",
        qty: "76",
        purchases: "259",
        sales: "240",
        adjustments: "0",
        accurateQty: "103",
        currentQty: "102"
    }, {
        item: "2402",
        description: "4K 4 / 99C Mango",
        inventoryDate: "02-07-2022",
        qty: "76",
        purchases: "259",
        sales: "240",
        adjustments: "0",
        accurateQty: "103",
        currentQty: "102"
    }, {
        item: "2402",
        description: "4K 4 / 99C Mango",
        inventoryDate: "02-07-2022",
        qty: "76",
        purchases: "259",
        sales: "240",
        adjustments: "0",
        accurateQty: "103",
        currentQty: "102"
    }, {
        item: "2402",
        description: "4K 4 / 99C Mango",
        inventoryDate: "02-07-2022",
        qty: "76",
        purchases: "259",
        sales: "240",
        adjustments: "0",
        accurateQty: "103",
        currentQty: "102"
    }, {
        item: "2402",
        description: "4K 4 / 99C Mango",
        inventoryDate: "02-07-2022",
        qty: "76",
        purchases: "259",
        sales: "240",
        adjustments: "0",
        accurateQty: "103",
        currentQty: "102"
    }, {
        item: "2402",
        description: "4K 4 / 99C Mango",
        inventoryDate: "02-07-2022",
        qty: "76",
        purchases: "259",
        sales: "240",
        adjustments: "0",
        accurateQty: "103",
        currentQty: "102"
    }, {
        item: "2402",
        description: "4K 4 / 99C Mango",
        inventoryDate: "02-07-2022",
        qty: "76",
        purchases: "259",
        sales: "240",
        adjustments: "0",
        accurateQty: "103",
        currentQty: "102"
    }, {
        item: "2402",
        description: "4K 4 / 99C Mango",
        inventoryDate: "02-07-2022",
        qty: "76",
        purchases: "259",
        sales: "240",
        adjustments: "0",
        accurateQty: "103",
        currentQty: "102"
    }, {
        item: "2402",
        description: "4K 4 / 99C Mango",
        inventoryDate: "02-07-2022",
        qty: "76",
        purchases: "259",
        sales: "240",
        adjustments: "0",
        accurateQty: "103",
        currentQty: "102"
    },
];