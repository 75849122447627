import React, { useEffect } from "react";
import { Link, withRouter } from 'react-router-dom';
import { menuList } from "./helper";
import classnames from "classnames";
//import images
import UserIcon from "../../assets/images/warehouse/header/user_icon.png";

export function HeaderWH(props) {
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, [props.location.pathname]);
    return (
        <header className="wh-header">
            <ul className="wh-navbar-nav">
                {(menuList || []).map((item, idx) => (
                    <Link to={item.link} key={item.id}>
                        <li
                            className={classnames("nav-item", {
                                "active-nav-item":
                                    item.link === props.location.pathname
                            })}
                        >
                            {/* <span className="main-label">{item.mainLabel}</span> */}
                            <img src={item.icon} className="header-icon" />
                            <span className="sub-label">{item.subLabel}</span>
                        </li>
                    </Link>
                ))}
            </ul>
            <div className="d-flex align-items-center">
                <img className="rounded-circle header-profile-user" src={UserIcon}
                    alt="Header Avatar" />
                <div className="text-start ms-xl-2">
                    <span className="d-none d-xl-inline-block ms-1 fw-medium user-name-text">User Name</span>
                    <span className="d-none d-xl-block ms-1 fs-12 text-muted user-name-sub-text">Admin</span>
                </div>
            </div>
        </header>
    )
}

export default withRouter(HeaderWH);