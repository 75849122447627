import React from "react";
import { Col, Container, Input, Label, Row, Button } from "reactstrap";
import SaveIcon from "../../../../../../../assets/images/warehouse/salespage/save.png";

export const Tools = ({permissions, handleSalesPermissionsChange}) => {
  return (
    <Container fluid>
      <Row>
        <Col xs="12" className="d-flex justify-content-end px-0">
          <Button color="primary" outline className="wh-btn">
            <span className="d-flex justify-content-center align-items-center">
              {/* <Spinner size="sm" type="grow" className="flex-shrink-0" role="status"> Loading... </Spinner> */}
              <img src={SaveIcon} style={{ height: "24px", width: "24px" }} />
              <span className="ms-2">Save</span>
            </span>
          </Button>
        </Col>
        <Col xs="4">
          <div className="form-check mb-1">
            <Input className="form-check-input" type="checkbox" name="PRINT_REPORT" checked={permissions.PRINT_REPORT} onChange={handleSalesPermissionsChange} />
            <Label className="form-check-label text-nowrap">
              Print Report
            </Label>
          </div>

          <div className="form-check mb-1">
            <Input className="form-check-input" type="checkbox" name="EXPORT_REPORT" checked={permissions.EXPORT_REPORT} onChange={handleSalesPermissionsChange} />
            <Label className="form-check-label text-nowrap">
              Export Report
            </Label>
          </div>

          <div className="mb-3 d-flex align-items-center">
            <Input type="checkbox" className="form-check-input" name="SHOW_REPORT_DATE" checked={permissions.SHOW_REPORT_DATE} onChange={handleSalesPermissionsChange}  />
            <Label className="w-50 ps-2 mb-0">Show Report Date</Label>
          </div>

          <div className="mb-3 d-flex align-items-center">
            <Input type="input" className="form-control w-50" name="REPORT_DAYS" value={permissions.REPORT_DAYS} onChange={handleSalesPermissionsChange}  />
            <Label className="w-50 ps-2 mb-0">Report Days</Label>
          </div>

          <div className="form-check mb-1">
            <Input className="form-check-input" type="checkbox" name="EXTD_REPT" checked={permissions.EXTD_REPT} onChange={handleSalesPermissionsChange} />
            <Label className="form-check-label text-nowrap">
              Extended Report
            </Label>
          </div>

          <div className="form-check mb-1">
            <Input className="form-check-input" type="checkbox" name="PRINT_FILT_IN_REPT" checked={permissions.PRINT_FILT_IN_REPT} onChange={handleSalesPermissionsChange} />
            <Label className="form-check-label text-nowrap">
              Print Filter In Report
            </Label>
          </div>

          <div className="form-check mb-1">
            <Input className="form-check-input" type="checkbox" name="COMP_PURC_SALES" checked={permissions.COMP_PURC_SALES} onChange={handleSalesPermissionsChange} />
            <Label className="form-check-label text-nowrap">
              Compare Purchase / Sales
            </Label>
          </div>

          <div className="form-check mb-1">
            <Input className="form-check-input" type="checkbox" name="VIEW_AMT_PURC_SALES" checked={permissions.VIEW_AMT_PURC_SALES} onChange={handleSalesPermissionsChange} />
            <Label className="form-check-label text-nowrap">
              View Amount Purchases / Sales
            </Label>
          </div>
        </Col>
        <Col xs="4">
          <select className="form-select mb-2">
            <option>Print All Receivable</option>
          </select>

          <select className="form-select mb-2">
            <option>Can't Print Daily Reports</option>
          </select>

          <select className="form-select mb-2">
            <option>Edit Customers Billing Info</option>
          </select>

          <select className="form-select mb-2">
            <option>Open Inv/Chk Without Password</option>
          </select>

          <select className="form-select mb-2">
            <option>Can't View Admin Notes</option>
          </select>

          <select className="form-select mb-2">
            <option>Can't Manage Cash Transactions</option>
          </select>
        </Col>
      </Row>
    </Container>
  );
};
