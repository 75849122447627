export default {
    GET_SUPPLIERS_REPORT_REQUEST:"GET_SUPPLIERS_REPORT_REQUEST",
    GET_SUPPLIERS_REPORT_SUCCESS:"GET_SUPPLIERS_REPORT_SUCCESS",
    GET_SUPPLIERS_REPORT_FAIL:"GET_SUPPLIERS_REPORT_FAIL",

    CHANGE_SUPPLIERS_REPORT_LOADING_STATUS: "CHANGE_GET_SUPPLIERS_REPORT_LOADING_STATUS",

    GET_PO_ANALYSIS_REPORT_REQUEST:"GET_PO_ANALYSIS_REPORT_REQUEST",
    GET_PO_ANALYSIS_REPORT_SUCCESS:"GET_PO_ANALYSIS_REPORT_SUCCESS",
    GET_PO_ANALYSIS_REPORT_FAIL:"GET_PO_ANALYSIS_REPORT_FAIL",

    CHANGE_PO_ANALYSIS_REPORT_LOADING_STATUS: "CHANGE_PO_ANALYSIS_REPORT_LOADING_STATUS",

    GET_INV_PRICING_REPORT_REQUEST:"GET_INV_PRICING_REPORT_REQUEST",
    GET_INV_PRICING_REPORT_SUCCESS:"GET_INV_PRICING_REPORT_SUCCESS",
    GET_INV_PRICING_REPORT_FAIL:"GET_INV_PRICING_REPORT_FAIL",

    GET_INV_PRICING_REPORT_PRINT_REQUEST:"GET_INV_PRICING_REPORT_PRINT_REQUEST",
    GET_INV_PRICING_REPORT_PRINT_SUCCESS:"GET_INV_PRICING_REPORT_PRINT_SUCCESS",
    GET_INV_PRICING_REPORT_PRINT_FAIL:"GET_INV_PRICING_REPORT_PRINT_FAIL",

    CHANGE_GET_INV_PRICING_REPORT_LOADING_STATUS: "CHANGE_GET_INV_PRICING_REPORT_LOADING_STATUS",
    
    CHANGE_GET_INV_PRICING_REPORT_PRINT_LOADING_STATUS: "CHANGE_GET_INV_PRICING_REPORT_PRINT_LOADING_STATUS",

    GET_INV_PRICING_STATS_REQUEST:"GET_INV_PRICING_STATS_REQUEST",
    GET_INV_PRICING_STATS_SUCCESS:"GET_INV_PRICING_STATS_SUCCESS",
    GET_INV_PRICING_STATS_FAIL:"GET_INV_PRICING_STATS_FAIL",

    GET_CUSTOMERS_REPORT_REQUEST:"GET_CUSTOMERS_REPORT_REQUEST",
    GET_CUSTOMERS_REPORT_SUCCESS:"GET_CUSTOMERS_REPORT_SUCCESS",
    GET_CUSTOMERS_REPORT_FAIL:"GET_CUSTOMERS_REPORT_FAIL",

    CHANGE_GET_CUSTOMERS_REPORT_LOADING_STATUS: "CHANGE_GET_CUSTOMERS_REPORT_LOADING_STATUS",


    GET_CUSTOMERS_STATS_REQUEST:"GET_CUSTOMERS_STATS_REQUEST",
    GET_CUSTOMERS_STATS_SUCCESS:"GET_CUSTOMERS_STATS_SUCCESS",
    GET_CUSTOMERS_STATS_FAIL:"GET_CUSTOMERS_STATS_FAIL",

    GET_INV_PRICING_UPDATE_COLS_REQUEST:"GET_INV_PRICING_UPDATE_COLS_REQUEST",
    GET_INV_PRICING_UPDATE_COLS_SUCCESS:"GET_INV_PRICING_UPDATE_COLS_SUCCESS",
    GET_INV_PRICING_UPDATE_COLS_FAIL:"GET_INV_PRICING_UPDATE_COLS_FAIL",

    UPDATE_INV_PRICING_REQUEST:"UPDATE_INV_PRICING_REQUEST",
    UPDATE_INV_PRICING_SUCCESS:"UPDATE_INV_PRICING_SUCCESS",
    UPDATE_INV_PRICING_FAIL:"UPDATE_INV_PRICING_FAIL",

    GET_INV_PRICE_CODE_REQUEST:"GET_INV_PRICE_CODE_REQUEST",
    GET_INV_PRICE_CODE_SUCCESS:"GET_INV_PRICE_CODE_SUCCESS",
    GET_INV_PRICE_CODE_FAIL:"GET_INV_PRICE_CODE_FAIL",

    UPDATE_INV_PRICE_CODE_REQUEST:"UPDATE_INV_PRICE_CODE_REQUEST",
    UPDATE_INV_PRICE_CODE_SUCCESS:"UPDATE_INV_PRICE_CODE_SUCCESS",
    UPDATE_INV_PRICE_CODE_FAIL:"UPDATE_INV_PRICE_CODE_FAIL",

    GET_CUSTOMER_ROUTE_REPORT_REQUEST:"GET_CUSTOMER_ROUTE_REPORT_REQUEST",
    GET_CUSTOMER_ROUTE_REPORT_SUCCESS:"GET_CUSTOMER_ROUTE_REPORT_SUCCESS",
    GET_CUSTOMER_ROUTE_REPORT_FAIL:"GET_CUSTOMER_ROUTE_REPORT_FAIL",

    GET_PAYMENTS_REPORT_REQUEST:"GET_PAYMENTS_REPORT_REQUEST",
    GET_PAYMENTS_REPORT_SUCCESS:"GET_PAYMENTS_REPORT_SUCCESS",
    GET_PAYMENTS_REPORT_FAIL:"GET_PAYMENTS_REPORT_FAIL",

    CHANGE_GET_PAYMENTS_REPORT_LOADING_STATUS: "CHANGE_GET_PAYMENTS_REPORT_LOADING_STATUS",

    GET_SALES_HISTORY_REPORT_REQUEST:"GET_SALES_HISTORY_REPORT_REQUEST",
    GET_SALES_HISTORY_REPORT_SUCCESS:"GET_SALES_HISTORY_REPORT_SUCCESS",
    GET_SALES_HISTORY_REPORT_FAIL:"GET_SALES_HISTORY_REPORT_FAIL",

    CHANGE_GET_SALES_HISTORY_REPORT_LOADING_STATUS: "CHANGE_GET_SALES_HISTORY_REPORT_LOADING_STATUS",

    GET_SALES_HIGHEST_REQUEST:"GET_SALES_HIGHEST_REQUEST",
    GET_SALES_HIGHEST_SUCCESS:"GET_SALES_HIGHEST_SUCCESS",
    GET_SALES_HIGHEST_FAIL:"GET_SALES_HIGHEST_FAIL",

    GET_CUSTOMER_BALANCE_STATEMENT_REQUEST:"GET_CUSTOMER_BALANCE_STATEMENT_REQUEST",
    GET_CUSTOMER_BALANCE_STATEMENT_SUCCESS:"GET_CUSTOMER_BALANCE_STATEMENT_SUCCESS",
    GET_CUSTOMER_BALANCE_STATEMENT_FAIL:"GET_CUSTOMER_BALANCE_STATEMENT_FAIL",

    CHANGE_GET_CUSTOMER_BALANCE_STATEMENT_LOADING_STATUS: "CHANGE_GET_CUSTOMER_BALANCE_STATEMENT_LOADING_STATUS",

    GET_PURCHASES_REPORT_REQUEST:"GET_PURCHASES_REPORT_REQUEST",
    GET_PURCHASES_REPORT_SUCCESS:"GET_PURCHASES_REPORT_SUCCESS",
    GET_PURCHASES_REPORT_FAIL:"GET_PURCHASES_REPORT_FAIL",

    CHANGE_GET_PURCHASES_REPORT_LOADING_STATUS: "CHANGE_GET_PURCHASES_REPORT_LOADING_STATUS",

    GET_SALES_REPORT_ITEMS_REQUEST:"GET_SALES_REPORT_ITEMS_REQUEST",
    GET_SALES_REPORT_ITEMS_SUCCESS:"GET_SALES_REPORT_ITEMS_SUCCESS",
    GET_SALES_REPORT_ITEMS_FAIL:"GET_SALES_REPORT_ITEMS_FAIL",

    CHANGE_GET_SALES_REPORT_ITEMS_LOADING_STATUS: "CHANGE_GET_SALES_REPORT_ITEMS_LOADING_STATUS",

    GET_INV_PURCHASES_REPORT_REQUEST:"GET_INV_PURCHASES_REPORT_REQUEST",
    GET_INV_PURCHASES_REPORT_SUCCESS:"GET_INV_PURCHASES_REPORT_SUCCESS",
    GET_INV_PURCHASES_REPORT_FAIL:"GET_INV_PURCHASES_REPORT_FAIL",

    GET_INV_SALES_REPORT_REQUEST:"GET_INV_SALES_REPORT_REQUEST",
    GET_INV_SALES_REPORT_SUCCESS:"GET_INV_SALES_REPORT_SUCCESS",
    GET_INV_SALES_REPORT_FAIL:"GET_INV_SALES_REPORT_FAIL",

    GET_RETURN_SALES_REPORT_REQUEST:"GET_RETURN_SALES_REPORT_REQUEST",
    GET_RETURN_SALES_REPORT_SUCCESS:"GET_RETURN_SALES_REPORT_SUCCESS",
    GET_RETURN_SALES_REPORT_FAIL:"GET_RETURN_SALES_REPORT_FAIL",

    GET_INVENTORY_DETECTION_NEWKIND_REPORT_REQUEST:"GET_INVENTORY_DETECTION_NEWKIND_REPORT_REQUEST",
    GET_INVENTORY_DETECTION_NEWKIND_REPORT_SUCCESS:"GET_INVENTORY_DETECTION_NEWKIND_REPORT_SUCCESS",
    GET_INVENTORY_DETECTION_NEWKIND_REPORT_FAIL:"GET_INVENTORY_DETECTION_NEWKIND_REPORT_FAIL",

    CHANGE_GET_INVENTORY_DETECTION_NEWKIND_REPORT_LOADING_STATUS:"CHANGE_GET_INVENTORY_DETECTION_NEWKIND_REPORT_LOADING_STATUS",

    GET_INVENTORY_DETECTION_NEWBOO_REPORT_REQUEST:"GET_INVENTORY_DETECTION_NEWBOO_REPORT_REQUEST",
    GET_INVENTORY_DETECTION_NEWBOO_REPORT_SUCCESS:"GET_INVENTORY_DETECTION_NEWBOO_REPORT_SUCCESS",
    GET_INVENTORY_DETECTION_NEWBOO_REPORT_FAIL:"GET_INVENTORY_DETECTION_NEWBOO_REPORT_FAIL",

    CHANGE_GET_INVENTORY_DETECTION_NEWBOO_REPORT_LOADING_STATUS:"CHANGE_GET_INVENTORY_DETECTION_NEWBOO_REPORT_LOADING_STATUS",
    
    GET_INVENTORY_DETECTION_AMAZON_REPORT_REQUEST:"GET_INVENTORY_DETECTION_AMAZON_REPORT_REQUEST",
    GET_INVENTORY_DETECTION_AMAZON_REPORT_SUCCESS:"GET_INVENTORY_DETECTION_AMAZON_REPORT_SUCCESS",
    GET_INVENTORY_DETECTION_AMAZON_REPORT_FAIL:"GET_INVENTORY_DETECTION_AMAZON_REPORT_FAIL",

    CHANGE_GET_INVENTORY_DETECTION_AMAZON_REPORT_LOADING_STATUS:"CHANGE_GET_INVENTORY_DETECTION_AMAZON_REPORT_LOADING_STATUS",

    GET_INVENTORY_ADJUSTMENT_REPORT_REQUEST:"GET_INVENTORY_ADJUSTMENT_REPORT_REQUEST",
    GET_INVENTORY_ADJUSTMENT_REPORT_SUCCESS:"GET_INVENTORY_ADJUSTMENT_REPORT_SUCCESS",
    GET_INVENTORY_ADJUSTMENT_REPORT_FAIL:"GET_INVENTORY_ADJUSTMENT_REPORT_FAIL",

    CHANGE_GET_INVENTORY_ADJUSTMENT_REPORT_LOADING_STATUS:"CHANGE_GET_INVENTORY_ADJUSTMENT_REPORT_LOADING_STATUS",

    CHANGE_GET_RETURN_SALES_REPORT_LOADING_STATUS: "CHANGE_GET_RETURN_SALES_REPORT_LOADING_STATUS",

    GET_DRIVERS_REPORT_REQUEST:"GET_DRIVERS_REPORT_REQUEST",
    GET_DRIVERS_REPORT_SUCCESS:"GET_DRIVERS_REPORT_SUCCESS",
    GET_DRIVERS_REPORT_FAIL:"GET_DRIVERS_REPORT_FAIL",

    GET_MSA_FILE_REPORT_REQUEST:"GET_MSA_FILE_REPORT_REQUEST",
    GET_MSA_FILE_REPORT_SUCCESS:"GET_MSA_FILE_REPORT_SUCCESS",
    GET_MSA_FILE_REPORT_FAIL:"GET_MSA_FILE_REPORT_FAIL",

    CHANGE_GET_MSA_FILE_REPORT_LOADING_STATUS: "CHANGE_GET_MSA_FILE_REPORT_LOADING_STATUS",

    GET_ECOMMERCE_ORDERS_REPORT_REQUEST:"GET_ECOMMERCE_ORDERS_REPORT_REQUEST",
    GET_ECOMMERCE_ORDERS_REPORT_SUCCESS:"GET_ECOMMERCE_ORDERS_REPORT_SUCCESS",
    GET_ECOMMERCE_ORDERS_REPORT_FAIL:"GET_ECOMMERCE_ORDERS_REPORT_FAIL",

    CHANGE_ECOMMERCE_ORDERS_REPORT_LOADING_STATUS: "CHANGE_GET_ECOMMERCE_ORDERS_REPORT_LOADING_STATUS",

    CHANGE_GET_DRIVERS_REPORT_LOADING_STATUS: "CHANGE_GET_DRIVERS_REPORT_LOADING_STATUS",
}