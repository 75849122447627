import React from "react";

const IndeterminateCheckbox = React.forwardRef(
    ({ indeterminate, ...rest }, ref) => {
        const defaultRef = React.useRef()
        const resolvedRef = ref || defaultRef

        React.useEffect(() => {
            resolvedRef.current.indeterminate = indeterminate
        }, [resolvedRef, indeterminate])

        return (
            <>
                <label className="checkbox checkbox-primary ml-2 mb-0 d-flex justify-content-center">
                    <input
                        type="checkbox"
                        ref={resolvedRef}
                        {...rest}
                    />
                    <span></span>
                </label>
            </>
        )
    }
)
IndeterminateCheckbox.displayName = "IndeterminateCheckbox"

export default IndeterminateCheckbox;