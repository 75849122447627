import React, { useEffect, useMemo, useState } from "react";
import { Col, Container, Input, Label, Row, Button } from "reactstrap";
import CancelIcon from "../../../assets/images/warehouse/salespage/cancel_icon.png";
import PrinterIcon from "../../../assets/images/warehouse/salespage/printer_old.png";
import BackIcon from "../../../assets/images/warehouse/menu/back.png";
//Constants
import { ccTaxPercent, currencySymbol } from "../../../constants";
import { formatAmountSymbol } from "../../../utility/commonutility";

export function getCkValue(payment) {
  const { paymentType, amountPaid, chequeNo } = payment;
  const tax = (ccTaxPercent / 100) * amountPaid;
  if (paymentType === "CC") {
    return `@${ccTaxPercent}% = ${currencySymbol}${(Number(amountPaid) + tax).toFixed(2)}`;
  } else if (paymentType === "CHEQUE") return chequeNo;
  else return "";
}

const paymentTypes = [
  {
    value: "CASH",
    label: "Cash",
  },
  {
    value: "CHEQUE",
    label: "Cheque",
  },
  {
    value: "ONLINE",
    label: "Online",
  },
  {
    value: "CC",
    label: "Credit Card",
  },
  {
    value: "UPI",
    label: "UPI",
  },
];

export const PaymentsTab = ({
  payments,
  setPayments,
  customersBalance,
  adjustInBalance,
  setAdjustInBalance,
  negativeBalancePaymentDetails,
  setNegativeBalancePaymentDetails,
}) => {
  const addNewPaymentsRow = () => {
    setPayments((prev) => [
      ...prev,
      {
        amountAlloc: "",
        amountPaid: "",
        paymentType: "",
        chequeNo: "",
        holdDate: "",
        tax: "",
      },
    ]);
  };
  const removePaymentsRow = (index) => {
    const updatedPayments = [...payments];
    updatedPayments.splice(index, 1);
    setPayments(updatedPayments);
  };
  const paymentsChngHndlr = (e, index) => {
    const { value, name } = e.target;
    const updatedPayments = [...payments];
    updatedPayments.splice(index, 1, { ...updatedPayments[index], [name]: value });
    if (name === "amountPaid") {
      let totalRemaining = customersBalance;
      for (let i in updatedPayments) {
        const { amountPaid } = updatedPayments[i];
        if (totalRemaining - amountPaid > 0) {
          updatedPayments[i].amountAlloc = amountPaid;
          totalRemaining -= amountPaid;
        } else if (totalRemaining > 0 && totalRemaining < amountPaid) {
          updatedPayments[i].amountAlloc = totalRemaining;
          totalRemaining = 0;
        } else updatedPayments[i].amountAlloc = 0;
      }
    }
    setPayments(updatedPayments);
  };
  const handleCreditMethodChange = (e) => {
    const { name, checked } = e.target;
    setNegativeBalancePaymentDetails((prev) => ({
      ...prev,
      paymentMethod: "",
      creditCustomersBalance: name === "creditCustomer" ? checked : !checked,
    }));
  };
  useEffect(() => {
    const updatedPayments = [...payments];
    let totalRemaining = customersBalance;
    for (let i in updatedPayments) {
      const { amountPaid } = updatedPayments[i];
      if (adjustInBalance) {
        updatedPayments[i].amountAlloc = amountPaid;
      } else if (totalRemaining - amountPaid >= 0) {
        updatedPayments[i].amountAlloc = amountPaid;
        totalRemaining -= amountPaid;
      } else if (totalRemaining > 0 && totalRemaining < amountPaid) {
        updatedPayments[i].amountAlloc = totalRemaining;
        totalRemaining = 0;
      } else updatedPayments[i].amountAlloc = 0;
    }
    setPayments(updatedPayments);
  }, [adjustInBalance]);
  const totalPaid = useMemo(() => {
    let total = 0;
    payments.forEach((item) => {
      total += Number(item.amountPaid);
    });
    return total;
  }, [payments]);
  const change = useMemo(() => {
    if (adjustInBalance) return 0;
    else {
      const c = totalPaid - customersBalance;
      return c > 0 ? c : 0;
    }
  }, [payments, adjustInBalance]);
  const balance = useMemo(() => {
    if (totalPaid - customersBalance < 0) {
      return customersBalance - totalPaid;
    } else {
      if (adjustInBalance) return customersBalance - totalPaid;
      else return 0;
    }
  }, [payments, adjustInBalance]);
  return (
    <Container className="px-0 pt-0">
      {/* <Row className="m-0 pt-0">
        <Col xs={12} className="bg-primary text-center fs-5 text-white py-2">
          General Payments
        </Col>
      </Row> */}

      {customersBalance >= 0 && (
        <Row className="p-3 pt-0 m-0 border-bottom">
          <h4>Collect Payments</h4>
          <Col xs="12 pt-4 mb-3">
            <div className="d-flex mb-2">
              <div className="w-18 ">
                <Label className="fw-bold text-center w-100">Amount Paid</Label>
              </div>
              <div className="w-18">
                <Label className="fw-bold text-center w-100">Amount Allocated</Label>
              </div>
              <div className="w-18">
                <Label className="fw-bold text-center w-100">Payment Type</Label>
              </div>
              <div className="w-18">
                <Label className="fw-bold text-center w-100">CK/Card Number</Label>
              </div>
              <div className="w-18">
                <Label className="fw-bold text-center w-100">Hold date</Label>
              </div>
              <div className="w-10"></div>
            </div>
            {payments.map((item, index) => (
              <div key={index} className="d-flex mb-2">
                <div className="w-18 pe-2">
                  <div className="form-icon">
                    <Input
                      className="form-control form-control-icon"
                      placeholder="0.00"
                      type="number"
                      onWheel={(e) => e.target.blur()}
                      name="amountPaid"
                      value={payments[index]["amountPaid"]}
                      onChange={(e) => paymentsChngHndlr(e, index)}
                    />
                    <i style={{ fontStyle: "normal" }}>{formatAmountSymbol()}</i>
                  </div>
                </div>
                <div className="w-18 px-2">
                  <div className="form-icon">
                    <Input
                      className="form-control form-control-icon"
                      placeholder="0.00"
                      type="number"
                      onWheel={(e) => e.target.blur()}
                      readOnly={true}
                      value={Number(payments[index]["amountAlloc"]).toFixed(2)}
                    />
                    <i style={{ fontStyle: "normal" }}>{formatAmountSymbol()}</i>
                  </div>
                </div>
                <div className="w-18 px-2">
                  <Input
                    type="select"
                    className="form-select"
                    name="paymentType"
                    value={payments[index].paymentType}
                    onChange={(e) => paymentsChngHndlr(e, index)}
                  >
                    <option value="">Select</option>
                    {paymentTypes.map((item) => (
                      <option key={item.value} value={item.value}>
                        {item.label}
                      </option>
                    ))}
                  </Input>
                </div>
                <div className="w-18 px-2">
                  {payments[index].paymentType === "CHEQUE" ? (
                    <Input
                      type="number"
                      onWheel={(e) => e.target.blur()}
                      name="chequeNo"
                      value={payments[index].chequeNo}
                      onChange={(e) => paymentsChngHndlr(e, index)}
                    />
                  ) : (
                    <Input readOnly type="text" value={getCkValue(payments[index])} />
                  )}
                </div>
                <div className="w-18 ps-2">
                  <Input
                    type="date"
                    name="holdDate"
                    value={payments[index].holdDate}
                    onChange={(e) => paymentsChngHndlr(e, index)}
                  />
                </div>

                <div className="w-10 d-flex align-items-center justify-content-center fs-5">
                  <i className="ri-delete-bin-line text-danger" onClick={() => removePaymentsRow(index)}></i>
                </div>
              </div>
            ))}

            <Button color="primary" onClick={addNewPaymentsRow}>
              + Add New
            </Button>
          </Col>
        </Row>
      )}

      {customersBalance < 0 && (
        <Row className="p-3 m-0 border-bottom">
          <h4>Select Credit Method</h4>
          <Col xs="12 pt-3 mb-3">
            <div className="d-flex align-items-center mb-3 form-radio-outline form-check form-radio-primary">
              <Input
                type="radio"
                className="form-check-input my-0 me-2"
                checked={negativeBalancePaymentDetails.creditCustomersBalance}
                onChange={handleCreditMethodChange}
                name="creditCustomer"
              />
              <Label className="my-0">Credit Customer's Balance</Label>
            </div>
            <div className="d-flex align-items-center mb-3 form-radio-outline form-check form-radio-primary">
              <Input
                type="radio"
                className="form-check-input my-0 me-2"
                checked={!negativeBalancePaymentDetails.creditCustomersBalance}
                onChange={handleCreditMethodChange}
                name="payCustomer"
              />
              <Label className="my-0 f">Pay Customer : ${customersBalance.toFixed(2)}</Label>
            </div>
          </Col>
          <Col xs="8">
            <Row className="mb-3">
              <Col xs={4} className="d-flex align-items-center">
                <Label className="form-label mb-0 text-nowrap">Payment Method :</Label>
              </Col>
              <Col xs={8}>
                <Input
                  name="paymentMethod"
                  id="paymentMethod"
                  className="form-control form-control-icon"
                  placeholder="0.00"
                  type="select"
                  value={negativeBalancePaymentDetails.paymentMethod}
                  onChange={(e) =>
                    setNegativeBalancePaymentDetails((prev) => ({ ...prev, paymentMethod: e.target.value }))
                  }
                  disabled={negativeBalancePaymentDetails.creditCustomersBalance}
                >
                  <option value="">Select</option>
                  {paymentTypes.map((item) => (
                    <option key={item.value} value={item.value}>
                      {item.label}
                    </option>
                  ))}
                </Input>
              </Col>
            </Row>
          </Col>
        </Row>
      )}

      {customersBalance >= 0 && (
        <Row className="p-3 m-0">
          <Col xs={8}>
            <Row className="mb-3">
              <Col xs={4} className="d-flex align-items-center">
                <Label className="form-label mb-0 text-nowrap">Total Allocated :</Label>
              </Col>
              <Col xs={8}>
                <div className="form-icon">
                  <Input
                    className="form-control form-control-icon"
                    placeholder="0.00"
                    type="text"
                    readOnly
                    // value={(prevBalance + (totalAmount + totalTax - totalDiscount)).toFixed(2)}
                  />
                  <i style={{ fontStyle: "normal" }}>{formatAmountSymbol()}</i>
                </div>
              </Col>
            </Row>
          </Col>
          <Col xs={8}>
            <Row className="mb-3">
              <Col xs={4} className="d-flex align-items-center">
                <Label className="form-label mb-0 text-nowrap">Total Paid :</Label>
              </Col>
              <Col xs={8}>
                <div className="form-icon">
                  <Input
                    className="form-control form-control-icon"
                    placeholder="0.00"
                    type="text"
                    readOnly
                    value={totalPaid.toFixed(2)}
                  />
                  <i style={{ fontStyle: "normal" }}>{formatAmountSymbol()}</i>
                </div>
              </Col>
            </Row>
          </Col>
          <Col xs={8}>
            <Row className="mb-3">
              <Col xs={4} className="d-flex align-items-center">
                <Label className="form-label mb-0 text-nowrap">Change :</Label>
              </Col>
              <Col xs={8}>
                <div className="form-icon">
                  <Input
                    name="change"
                    id="change"
                    className="form-control form-control-icon"
                    placeholder="0.00"
                    type="text"
                    readOnly
                    value={change.toFixed(2)}
                  />
                  <i style={{ fontStyle: "normal" }}>{formatAmountSymbol()}</i>
                </div>
              </Col>
            </Row>
          </Col>
          <Col xs={4} className="d-flex align-items-center">
            <div
              onClick={totalPaid - customersBalance <= 0 ? () => {} : () => setAdjustInBalance((prev) => !prev)}
              className={`d-flex align-items-center mb-3 ${
                totalPaid - customersBalance <= 0 ? "show-cursor-not-allowed opacity-50" : "show-cursor-pointer"
              }`}
            >
              <img src={BackIcon} width="24" height="24" alt="back-icon" />
              <div className="text-decoration-underline mb-0 text-primary ms-2">Adjust in Balance</div>
            </div>
          </Col>
          <Col xs={8}>
            <Row className="mb-3">
              <Col xs={4} className="d-flex align-items-center">
                <Label className="form-label mb-0 text-nowrap">Balance :</Label>
              </Col>
              <Col xs={8}>
                <div className="form-icon">
                  <Input
                    name="invoiceTotal"
                    id="invoiceTotal"
                    className="form-control form-control-icon"
                    placeholder="0.00"
                    type="text"
                    readOnly
                    value={balance.toFixed(2)}
                  />
                  <i style={{ fontStyle: "normal" }}>{formatAmountSymbol()}</i>
                </div>
              </Col>
            </Row>
          </Col>
          <Col xs={4} />
        </Row>
      )}
    </Container>
  );
};
