import { call, takeLatest, put, delay } from "redux-saga/effects";
import { getData, postData, putData, deleteData } from "../../api/apiactions";
import { handleApiResponse } from "../../utility/sagautility";
import departmentconstants from "./departmentconstants";
import { changeDepartmentApiLoadingStatusAct } from "./departmentactions"

function withLoading(fn) {
    return function* withLoadingGenerator(...args) {
        yield put(changeDepartmentApiLoadingStatusAct(true));
        yield delay(500);
        yield* fn(...args);
        yield put(changeDepartmentApiLoadingStatusAct(false));
    }
}

function* createDepartmentGen(action) {
    const response = yield call(postData, action.apiEndpoint, action.payload, action.optionalConfig);
    yield call(handleApiResponse, response, {
        successAction:departmentconstants.CREATE_DEPARTMENT_SUCCESS,
        failAction:departmentconstants.CREATE_DEPARTMENT_FAIL,
    });
}

function* updateDepartmentGen(action) {
    const response = yield call(putData, action.apiEndpoint, action.payload, action.optionalConfig);
    yield call(handleApiResponse, response, {
        successAction:departmentconstants.UPDATE_DEPARTMENT_SUCCESS,
        failAction:departmentconstants.UPDATE_DEPARTMENT_FAIL,
    });
}

function* getDepartmentsGen(action) {
    const response = yield call(getData, action.apiEndpoint, action.payload, action.optionalConfig);
    yield call(handleApiResponse, response, {
      successAction: departmentconstants.GET_DEPARTMENTS_SUCCESS,
      failAction: departmentconstants.GET_DEPARTMENTS_FAIL
    })
}

function* deleteDepartmentsGen(action) {
    const response = yield call(deleteData, action.apiEndpoint, action.payload, action.optionalConfig);
    yield call(handleApiResponse, response, {
      successAction: departmentconstants.DELETE_DEPARTMENTS_SUCCESS,
      failAction: departmentconstants.DELETE_DEPARTMENTS_FAIL
    })
}

export function* departmentSaga() {
    yield takeLatest(departmentconstants.CREATE_DEPARTMENT_REQUEST, withLoading(createDepartmentGen));
    yield takeLatest(departmentconstants.UPDATE_DEPARTMENT_REQUEST, withLoading(updateDepartmentGen));
    yield takeLatest(departmentconstants.GET_DEPARTMENTS_REQUEST, getDepartmentsGen);
    yield takeLatest(departmentconstants.DELETE_DEPARTMENTS_REQUEST, withLoading(deleteDepartmentsGen));
}
