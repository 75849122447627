import { call, takeLatest, put, delay } from "redux-saga/effects";
import { getData, postData, putData, deleteData } from "../../api/apiactions";
import { handleApiResponse } from "../../utility/sagautility";
import designationconstants from "./designationconstants";
import { changeDesignationApiLoadingStatusAct } from "./designationactions"

function withLoading(fn) {
    return function* withLoadingGenerator(...args) {
        yield put(changeDesignationApiLoadingStatusAct(true));
        yield delay(500);
        yield* fn(...args);
        yield put(changeDesignationApiLoadingStatusAct(false));
    }
}

function* createDesignationGen(action) {
    const response = yield call(postData, action.apiEndpoint, action.payload, action.optionalConfig);
    yield call(handleApiResponse, response, {
        successAction:designationconstants.CREATE_DESIGNATION_SUCCESS,
        failAction:designationconstants.CREATE_DESIGNATION_FAIL,
    });
}

function* updateDesignationGen(action) {
    const response = yield call(putData, action.apiEndpoint, action.payload, action.optionalConfig);
    yield call(handleApiResponse, response, {
        successAction:designationconstants.UPDATE_DESIGNATION_SUCCESS,
        failAction:designationconstants.UPDATE_DESIGNATION_FAIL,
    });
}

function* getDesignationsGen(action) {
    const response = yield call(getData, action.apiEndpoint, action.payload, action.optionalConfig);
    yield call(handleApiResponse, response, {
      successAction: designationconstants.GET_DESIGNATIONS_SUCCESS,
      failAction: designationconstants.GET_DESIGNATIONS_FAIL
    })
}

function* deleteDesignationsGen(action) {
    const response = yield call(deleteData, action.apiEndpoint, action.payload, action.optionalConfig);
    yield call(handleApiResponse, response, {
      successAction: designationconstants.DELETE_DESIGNATIONS_SUCCESS,
      failAction: designationconstants.DELETE_DESIGNATIONS_FAIL
    })
}

export function* designationSaga() {
    yield takeLatest(designationconstants.CREATE_DESIGNATION_REQUEST, withLoading(createDesignationGen));
    yield takeLatest(designationconstants.UPDATE_DESIGNATION_REQUEST, withLoading(updateDesignationGen));
    yield takeLatest(designationconstants.GET_DESIGNATIONS_REQUEST, getDesignationsGen);
    yield takeLatest(designationconstants.DELETE_DESIGNATIONS_REQUEST, withLoading(deleteDesignationsGen));
}
