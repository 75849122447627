import React, { useMemo, useState, useEffect, useRef } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import {
  Col,
  Container,
  Row,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Card,
  CardBody,
  CardHeader,
} from "reactstrap";
import { generateColumns, tabs, findActiveRoleName, perimissionsInitialValues, generatePayload, mapPermissions } from "./helper";
import { Administration, Sales, Tools } from "./tabs";
import classnames from "classnames";
//Api Helper
import { generateApiUrl } from "../../../../../api/apihelper";
//Actions
import {
  getUserRolesAct,
  getAllPermissionsAct,
  createRolePermissionAct,
  getPermissionsByRoleAct,
} from "../../../../../store/usermanagement/usermanagementactions";

function getActiveComponent(activeId) {
  switch (activeId) {
    case "administration":
      return Administration;
    case "sales":
      return Sales;
    case "tools":
      return Tools;
    default:
      return <></>;
  }
}

export const Permission = () => {
  const [permissions, setPermissions] = useState({ ...perimissionsInitialValues });
  const [justifyPillsTab, setjustifyPillsTab] = useState("administration");
  const [activeRole, setActiveRole] = useState(null);
  const justifyActiveRole = (id) => {
    if (activeRole !== id) {
      setActiveRole(id);
    }
  };
  const justifyPillsToggle = (tab) => {
    if (justifyPillsTab !== tab) {
      setjustifyPillsTab(tab);
    }
  };
  const { getUserRolesResp, getAllPermissionsResp, createRolePermissionResp, getPermissionsByRoleResp, userManagementErrorResp } = useSelector(
    ({ userManagement }) => ({
      getUserRolesResp: userManagement.getUserRolesResp,
      getAllPermissionsResp: userManagement.getAllPermissionsResp,
      createRolePermissionResp: userManagement.createRolePermissionResp,
      getPermissionsByRoleResp: userManagement.getPermissionsByRoleResp,
      userManagementErrorResp: userManagement.userManagementErrorResp,
    }),
    shallowEqual
  );
  const prevApiResp = useRef({
    prevGetUserRolesResp: getUserRolesResp,
    prevCreateRolePermissionResp: createRolePermissionResp,
    prevGetPermissionsByRoleResp: getPermissionsByRoleResp,
    prevUserManagementErrorResp: userManagementErrorResp,
  });
  useEffect(() => {
    const { prevGetUserRolesResp, prevCreateRolePermissionResp, prevGetPermissionsByRoleResp, prevUserManagementErrorResp } = prevApiResp.current || {};
    if (getUserRolesResp && prevGetUserRolesResp !== getUserRolesResp) {
      if(getUserRolesResp?.data?.userRoles.length > 0) setActiveRole(getUserRolesResp?.data?.userRoles[0].id)
    }
    else if (createRolePermissionResp && prevCreateRolePermissionResp !== createRolePermissionResp) {
      toast.success("Successfully saved permissions");
      dispatch(getPermissionsByRoleAct(generateApiUrl("get_permissions_by_role",{id:activeRole})));
    } else if (getPermissionsByRoleResp && prevGetPermissionsByRoleResp !== getPermissionsByRoleResp) {
      mapPermissions(setPermissions, getPermissionsByRoleResp, getAllPermissionsResp)
    } else if (userManagementErrorResp && prevUserManagementErrorResp !== userManagementErrorResp) {
      const { errors, error, message } = userManagementErrorResp || {};
      toast.error((Array.isArray(errors) && errors[0]?.msg) || error || message);
    }
    prevApiResp.current.prevGetUserRolesResp = getUserRolesResp;
    prevApiResp.current.prevCreateRolePermissionResp = createRolePermissionResp;
    prevApiResp.current.prevGetPermissionsByRoleResp = getPermissionsByRoleResp;
    prevApiResp.current.prevUserManagementErrorResp = userManagementErrorResp;
  }, [getUserRolesResp, createRolePermissionResp, getPermissionsByRoleResp, userManagementErrorResp]);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getUserRolesAct(generateApiUrl("get_user_roles")));
    dispatch(getAllPermissionsAct(generateApiUrl("get_all_permissions")));
  }, []);
  useEffect(() => {
   if(activeRole)  dispatch(getPermissionsByRoleAct(generateApiUrl("get_permissions_by_role",{id:activeRole})));
  },[activeRole])
  const handleSalesPermissionsChange = (e) => {
    const { name, checked, value, type } = e.target;
    setPermissions((prev) => ({ ...prev, [name]: type === "checkbox" ? checked : value }));
  };
  const handleSalesMenuPermissionsChange = (e) => {
    const { name, checked, type } = e.target;
    setPermissions((prev) => {
      const menuKeys = ["SALES_MENU","POS_MENU","CUSTOMER_MENU","PO_MENU","VENDORS_MENU","INVENTORY_MENU"].filter(item => item !== name);
      const updatedPermissions = {...prev};
      updatedPermissions[name] = checked;
      if(name === "ALL_MENU") {
          for(let i of menuKeys) {
            updatedPermissions[i] = checked;
          }
      } else {    
        if(checked) {
          let isAllChecked = true;
          for(let i of menuKeys) {
            if(!updatedPermissions[i]) {
              isAllChecked= false;
              break;
            }
          }
          if(isAllChecked) {
            updatedPermissions["ALL_MENU"] = true;
          }
        } else updatedPermissions["ALL_MENU"] = false;
      }
      return (updatedPermissions)
    });
  };
  const handlePermissionsSave = () => {
    const payload = generatePayload(permissions, getAllPermissionsResp);
    // console.log(payload);
    dispatch(createRolePermissionAct(generateApiUrl("create_role_permission",{id:activeRole}),{permissionIds:payload}));
  }
  const columns = useMemo(() => generateColumns(), []);
  const TabComponent = useMemo(() => getActiveComponent(justifyPillsTab), [justifyPillsTab]);
  return (
    <Container fluid className="px-5 pt-3 pb-5">
      <Row>
        <Col xs="3">
          <Card className="shadow-lg h-100 wh-card-bg wh-card-border">
            <CardHeader className="my-0 py-2 wh-card-bg">
              <h4 className="mb-0 wh-color-white">Roles</h4>
            </CardHeader>
            <CardBody className="ps-0 pt-0"> 
              <div className="d-flex flex-column mt-3 selectable-left-sidebar">
                {(getUserRolesResp?.data?.userRoles || []).map((item, index) => (
                  <div
                    className={`item mb-0 py-1 show-cursor-pointer ${activeRole == item.id ? "active" : ""}`}
                    key={item.id}
                    onClick={() => justifyActiveRole(item.id)}
                  >
                    {item.name}
                  </div>
                ))}
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col xs="9" className="ps-5">
          <Card className="shadow-lg h-100 wh-card-bg wh-card-border">
            <CardHeader className="my-0 py-2 wh-card-bg">
              <div>
                <span className="fs-5 fw-bold">Assign Permissions to : </span>{" "}
                <span className="fs-4 fw-bolder">{findActiveRoleName(getUserRolesResp, activeRole)}</span>
              </div>
            </CardHeader>
            <CardBody>
              <Nav pills className="mb-3 bg-light px-5">
                {tabs.map((item, index) => (
                  <NavItem key={index}>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({ active: justifyPillsTab === item.id })}
                      onClick={() => {
                        justifyPillsToggle(item.id);
                      }}
                    >
                      {item.name}
                    </NavLink>
                  </NavItem>
                ))}
              </Nav>
              <TabContent activeTab={justifyPillsTab} className="text-muted">
                <TabPane tabId={justifyPillsTab} id="pill-justified-home-1">
                  <TabComponent permissions={permissions} handleSalesPermissionsChange={handleSalesPermissionsChange} handlePermissionsSave={handlePermissionsSave} handleSalesMenuPermissionsChange={handleSalesMenuPermissionsChange}/>
                </TabPane>
              </TabContent>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};
