import { Input, Label } from 'reactstrap';
import { Link } from "react-router-dom";
import ResetIcon from "../../../../assets/images/warehouse/salespage/reset.png";
import PrintIcon from "../../../../assets/images/warehouse/salespage/printer.png";
import ExportIcon from "../../../../assets/images/warehouse/salespage/excel.png";
import DeleteIcon from "../../../../assets/images/warehouse/salespage/delete.png";
import NewIcon from "../../../../assets/images/warehouse/salespage/new.png";

export function generateColumns(setEditModalData, toggle, toggleDeleteConfirmation, deleteDepartmentConfig) {
    return [
      {
        Header: "Department Name",
        accessor: "name",
      },
      {
        Header: <div className='d-flex justify-content-center'>Status</div>,
        accessor: "status",
        style:{width:"180px", textAlign:"center"},
        Cell: ({cell:{value}}) => {
          return <button className={`btn btn-sm btn-soft-${value ? "success" : "danger"}`}>{value ? "Active" : "Inactive"}</button>;
        },
      },
      {
        Header: <div className='d-flex justify-content-center'>Actions</div>,
        style:{width:"120px"},
        accessor: "actions",
        Cell: ({row}) => (
          <div className="hstack gap-3 flex-wrap justify-content-center">
            <div className="wh-link-color-green fs-15"  onClick={() => {
              setEditModalData(row.original);
              toggle();
            }}>
              <i className="ri-edit-2-line"></i>
            </div>
            <div className="link-danger fs-15"  onClick={() => {
              deleteDepartmentConfig.current = {
                deleteMultiple: false,
                idOfDepartmentToBeDeleted: row.original.id,
              };
              toggleDeleteConfirmation();
            }}>
              <i className="ri-delete-bin-line"></i>
            </div>
          </div>
        ),
      },
    ];
}

export const actions = (toggle, togglePrintModal, toggleDeleteConfirmation, deleteDepartmentConfig) => [
    {
        id: "print",
        label: "Print",
        icon: PrintIcon,
        clickHandler: togglePrintModal,
    }, {
        id: "delete",
        label: "Delete",
        icon: DeleteIcon,
        clickHandler: () => {
          deleteDepartmentConfig.current = {
            deleteMultiple: true,
            idOfDepartmentToBeDeleted: "",
          };
          toggleDeleteConfirmation();
        },
    }, {
        id: "new",
        label: "New",
        icon: NewIcon,
        clickHandler: toggle,
    }
]