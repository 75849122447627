import moment from "moment";
import { Badge } from "reactstrap";
import ClickedSelectItem from "../../Components/Common/InputConvertItems/ClickedSelectItem";
import ClickedNumber from "../../Components/Common/InputConvertItems/ClickedNumber";
import ClickedInput from "../../Components/Common/InputConvertItems/ClickedInput";
//Utility
import { blockInvalidChar, formatAmount, formatAmountSymbol } from "../../utility/commonutility";
import { getYyyymmddDateFormatted, getStartDateFilterValue } from "../../utility/dateutility";
import { getCkValue } from "../../Components/SalesWH/modals/paymentsmodal/paymentsmodalbody";

export const initialTableRowValues = {
  itemNumber: "",
  description: "",
  quantity: "",
  amount: "",
  discount: "",
  discountVal: "",
  unit: "",
  price: "",
  originalDisc: "",
};

export const inventoryItemsColumnsList = [
  {
    id: "itemNumber",
    name: "Item Number",
    cellClassName: "fw-medium",
  },
  {
    id: "description",
    name: "Item Name",
    cellClassName: "fbadge badge-soft-primary",
  },
  {
    id: "retail",
    name: "Price",
    cellClassName: "fw-medium",
  },
  // {
  //   id: "customerSpecialPrice",
  //   name: "Cust. Sp. Price",
  //   cellClassName: "fw-medium",
  // },
  {
    id: "quantityInStock",
    name: "Stock Quantity",
    cellClassName: "fw-medium",
  },
];

export function generateColumns(
  removeItem,
  getInventoryListApiCall,
  callbackAfterGetInventoryListApiCall,
  selectItemCallbck,
  fieldChangeHndlr,
  openPurchaseHistoryModal,
  status,
  id,
  isReturnInvoice,
  totalItems,
  permissions
) {
  return [
    {
      Header: "S.No.",
      accessor: "sNo",
      Cell: ({ row }) => row.index + 1,
      style: { width: "3%",},
    },
    {
      Header: "Item",
      accessor: "item",
      Cell: ({ row }) => {
        const inputStyle = {
          width : '150px'
        }
        return id !== "new" && status === "posted" ? (
          row.original.itemNumber
        ) : (
          <ClickedSelectItem
            inputType="number"
            columnsList={inventoryItemsColumnsList}
            placeholder="Search by Item Number"
            dataIdKey="itemNumber"
            callback={getInventoryListApiCall}
            reduxDetails={"inventory.getInventoryListResp"}
            searchBy={"itemNumber"}
            selectedItemHandler={selectItemCallbck}
            value={row.original.itemNumber}
            index={row.index}
            row={row}
            callbackAfterCallback={callbackAfterGetInventoryListApiCall}
            doubleClickHandler={() => {
              if (permissions["VIEW_ADV_INV"]) window.open(`/warehouseinventory/${row.original.itemNumber}`);
            }}
            containerClassName="select-item-number-wrapper"
            isLast={totalItems === row.index + 1}
            addObserver={true}
            addItemByEnter={true}
            addItemByEnterKey="itemNumber"
            inputClassName="p-0 mt-1"
            inputStyle={inputStyle}
          />
        )
      },
      style: { width: "6%", },
    },
    {
      Header: (
        <span>
          Description {isReturnInvoice && <Badge color="info ms-2">Return</Badge>}
        </span>
      ),
      accessor: "description",
      Cell: ({ row }) =>
        id !== "new" && status === "posted" ? (
          row.original.description
        ) : (
          <ClickedSelectItem
            columnsList={inventoryItemsColumnsList}
            placeholder="Search by Item Name"
            selectedItemHandler={selectItemCallbck}
            dataIdKey="itemNumber"
            callback={getInventoryListApiCall}
            reduxDetails={"inventory.getInventoryListResp"}
            searchBy={"description"}
            value={row.original.description}
            index={row.index}
            callbackAfterCallback={callbackAfterGetInventoryListApiCall}
            doubleClickHandler={() => {
              openPurchaseHistoryModal({ itemNumber: row.original.itemNumber, description: row.original.description });
            }}
            containerClassName="select-description-wrapper"
            inputClassName="p-0 mt-1"
            // quantityKey="quantityInStock"
          />
        ),
      style: { width: "40%" },
    },
    {
      Header: <div style={{textAlign:'center'}}>Quantity</div>,
      accessor: "quantity",
      Cell: ({ row }) =>{
        const innerDivStyle = {
          height:'25px',
          marginTop:'.25em'
        }
        return id !== "new" && status === "posted" ? (
          row.original.quantity
        ) : row.original.itemNumber ? (
          <ClickedNumber
            value={row.original.quantity}
            itemKey="quantity"
            fieldChangeHndlr={fieldChangeHndlr}
            index={row.index}
            spanClassName={"justify-content-center"}
            containerClassName="quantity-wrapper"
            addObserver={true}
            allowNegative={!!permissions["ALLOW_RETURNS"]}
            innerDivStyle={innerDivStyle}
          />
        ) : (
          ""
        )
      },
      style: { width: "5%", textAlign: "center", },
    },
    {
      Header: <div style={{textAlign:'center'}}>Amount</div>,
      accessor: "amount",
      // Cell: ({ row }) =>
      //   id !== "new" && status === "posted" ? (
      //     row.original.amount ? (
      //       `${formatAmountSymbol()}${row.original.amount}`
      //     ) : (
      //       ""
      //     )
      //   ) : row.original.itemNumber ? (
      //     <ClickedInput
      //       inputType="number"
      //       value={row.original.amount}
      //       itemKey="amount"
      //       fieldChangeHndlr={fieldChangeHndlr}
      //       index={row.index}
      //       icon={<i style={{ fontStyle: "normal" }}>{formatAmountSymbol()}</i>}
      //       prefix={formatAmountSymbol()}
      //       spanClassName={"justify-content-end"}
      //       spanPlaceholder={"$0.00"}
      //     />
      //   ) : (
      //     ""
      //   ),
      Cell: ({ row }) => (row.original.itemNumber ? `${formatAmountSymbol()}${parseFloat(row.original.amount).toFixed(2)}` : ""),
      style: { width: "5%", textAlign: "right" },
    },
    {
      Header: <div className="d-flex justify-content-center">Discount</div>,
      accessor: "discount",
      Cell: ({ row }) => {
        const { discount, discountVal, amount, itemNumber } = row.original;
        const inputStyle = {
          width : '100%',
          paddingLeft:'2.7rem'
        }
        const rightInputStyle={
          width : '100%',
          paddingLeft : '0rem',
          paddingRight : '1.5rem'
        }
        // const discountVal = ((price || 0) * (discount || 0)) / 100;
        return (
          <div className="d-flex" style={{ height: "30px" }}>
            <div className="w-50 border-end d-flex align-items-center justify-content-end px-2 whbrand-border-right">
              {/* {row.original.itemNumber ? `${formatAmountSymbol()}${discountVal.toFixed(2)}` : ""} */}
              {id !== "new" && status === "posted" ? (
                discountVal ? (
                  `${formatAmountSymbol()}${discountVal}`
                ) : (
                  "Rs 0.00"
                )
              ) : itemNumber ? (
                <ClickedInput
                  inputType="number"
                  value={discountVal}
                  itemKey="discountVal"
                  fieldChangeHndlr={fieldChangeHndlr}
                  index={row.index}
                  icon={<i style={{ fontStyle: "normal" }}>{formatAmountSymbol()}</i>}
                  prefix={formatAmountSymbol()}
                  containerClass="w-100 discount-val-wrapper full-width"
                  spanPlaceholder="$0.00"
                  inputMin={0}
                  inputMax={amount}
                  onKeyDown={blockInvalidChar}
                  spanClassName={"justify-content-end px-2"}
                  allowEdit={!!permissions["CHNG_INV_PRICE_AND_DISC"]}
                  innerInputClass='py-0 mt-1 pe-1'
                  inputStyle={inputStyle}
                />
              ) : (
                ""
              )}
            </div>
            <div className="w-50 d-flex align-items-center justify-content-end px-2">
              {id !== "new" && status === "posted" ? (
                discount ? (
                  `${discount}%`
                ) : (
                  "0%"
                )
              ) : itemNumber ? (
                <ClickedInput
                  inputType="number"
                  value={discount}
                  itemKey="discount"
                  fieldChangeHndlr={fieldChangeHndlr}
                  index={row.index}
                  icon={<i style={{ fontStyle: "normal" }}>%</i>}
                  iconRightAlign={true}
                  suffix={"%"}
                  containerClass="w-100 discount-per-wrapper full-width"
                  spanPlaceholder="0%"
                  inputMin={0}
                  inputMax={100}
                  onKeyDown={blockInvalidChar}
                  spanClassName={"justify-content-end px-2"}
                  allowEdit={!!permissions["CHNG_INV_PRICE_AND_DISC"]}
                  innerInputClass='py-0 mt-1'
                  inputStyle={rightInputStyle}
                />
              ) : (
                ""
              )}
            </div>
          </div>
        );
      },
      style: { width: "21%",},
    },
    {
      Header: <div className="d-flex justify-content-center">Units</div>,
      accessor: "unit",
      style: {width: "10%" },
      Cell: ({
        row: {
          original: { amount, discount, units },
        },
      }) => {
        let amountVal = Number(amount) || 0;
        let disPerVal = Number(discount) || 0;
        let disVal = (amount * disPerVal) / 100;
        return (
          <div className="d-flex" style={{ height: "30px" }}>
            <div className="w-50 border-end d-flex align-items-center justify-content-center px-2 whbrand-border-right">{Number(units) || "-"}</div>
            <div className={`w-50 d-flex align-items-center justify-content-${Number(units) ? "end" : "center"} px-2 ps-5`}>
              {Number(units) ? `${formatAmountSymbol()}${((amountVal - disVal) / units).toFixed(2)}` : "-"}
            </div>
          </div>
        );
      },
    },
    {
      Header: <div style={{textAlign:'center'}}>Price</div>,
      accessor: "price",
      // Cell: ({ row }) =>
      //   id !== "new" && status === "posted" ? (
      //     row.original.price ? (
      //       `${formatAmountSymbol()}${row.original.price}`
      //     ) : (
      //       ""
      //     )
      //   ) : row.original.itemNumber ? (
      //     <ClickedInput
      //       inputType="number"
      //       placeholder="Enter Price"
      //       value={row.original.price}
      //       itemKey="price"
      //       fieldChangeHndlr={fieldChangeHndlr}
      //       index={row.index}
      //       icon={<i style={{ fontStyle: "normal" }}>{formatAmountSymbol()}</i>}
      //       prefix={formatAmountSymbol()}
      //     />
      //   ) : (
      //     ""
      //   ),
      // Cell: ({
      //   row: {
      //     original: { amount, discount, itemNumber },
      //   },
      // }) => {
      //   let amountVal = Number(amount) || 0;
      //   let disPerVal = Number(discount) || 0;
      //   let disVal = (amount * disPerVal) / 100;
      //   return itemNumber ? `${formatAmountSymbol()}${(amountVal - disVal).toFixed(2)}` : "";
      // },
      Cell: ({ row }) => {

        const inputStyle = {
          width : '130px',
        }
        return id !== "new" && status === "posted" ? (
          row.original.price ? (
            `${formatAmountSymbol()}${row.original.price}`
          ) : (
            ""
          )
        ) : row.original.itemNumber ? (
          <ClickedInput
            inputType="number"
            value={parseFloat(row.original.price).toFixed(2)}
            itemKey="price"
            fieldChangeHndlr={fieldChangeHndlr}
            index={row.index}
            icon={<i style={{ fontStyle: "normal" }}>{formatAmountSymbol()}</i>}
            prefix={formatAmountSymbol()}
            spanClassName={"justify-content-end"}
            spanPlaceholder={`${formatAmountSymbol()}0.00`}
            inputMin={0}
            inputMax={row.original.amount}
            containerClass="price-wrapper"
            allowEdit={!!permissions["CHNG_INV_PRICE_AND_DISC"]}
            innerInputClass='py-0 mt-1'
            inputStyle={inputStyle}
          />
        ) : (
          ""
        )
      },
      style: { width: "5%", textAlign: "right" },
    },
    {
      Header:<div style={{textAlign:'center'}}>Total</div>,
      accessor: "total",
      Cell: ({
        row: {
          original: { amount, price, quantity, discount, itemNumber },
        },
      }) => {
        // let priceVal = Number(price) || 0;
        // let disPerVal = Number(discount) || 0;
        // let total = priceVal * Number(quantity);
        // let disVal = (total * disPerVal) / 100;
        // let amountVal = Number(price).toFixed(2) || 0;
        // let disPerVal = Number(discount).toFixed(2) || 0;
        // let disVal = ((amount * disPerVal).toFixed(2)) / 100;
        return itemNumber ? `${formatAmount((price * quantity).toFixed(2))}` : "";
      },
      style: { width: "5%", textAlign: "right" },
    },
    {
      Header:<div style={{textAlign:'center'}}>Action</div>,
      accessor: "action",
      Cell: ({ row }) =>
        row.original.itemNumber ? (
          <i
            className={`ri-delete-bin-line text-danger fs-5 ${
              id !== "new" && status === "posted" ? "show-cursor-not-allowed opacity-50" : "show-cursor-pointer"
            }`}
            onClick={id !== "new" && status === "posted" ? () => {} : () => removeItem(row.index)}
          ></i>
        ) : (
          ""
        ),
      style: { width: "5%", textAlign: "center" },
    },
  ];
}

export const generatePayload = (
  tableData,
  customerNumber,
  invDetails,
  paymentDetails,
  totalWeight,
  totalCount,
  totalPaid,
  change,
  adjustInBalance,
  tax,
  freight,
  discount,
  discountValue,
  totalAmount,
  prevBalance,
  totalSalesDiscountValue,
  other,
  // isSalesDisc,
  sgst,
  cgst,
  igst,
  cgstValue,
  invDiscount
) => {
   // Calculate sgstAmount and cgstAmount
   const sgstAmount = (totalAmount * sgst) / 100;
   const cgstAmount = (totalAmount * cgst) / 100;
   const igstAmount = (totalAmount * igst) / 100;
   // Calculate subtotal
   const subtotal = totalAmount + sgstAmount + cgstAmount + igstAmount;
  const todayDate = moment();
  const payments = paymentDetails.payments.map((item) => ({
    id: item.id || null,
    amountPaid: item.amountPaid,
    amountAlloc: item.amountPaid,
    paymentType: item.paymentType,
    chequeNo: item.chequeNo,
    holdDate: item.holdDate ? getStartDateFilterValue(item.holdDate) : "",
    comments: getCkValue(item),
    // datePaid: getStartDateFilterValue(moment().format("YYYY-MM-DD")), created from backend
  }));
  const payload = {
    soldToCustomer: customerNumber,
    ...invDetails,
    tax,
    payments:
      totalAmount + Number(tax) + Number(freight) + Number(other) - discountValue < 0
        ? null
        : payments.filter((item) => item.amountPaid),
    tWeight: totalWeight,
    tCount: totalCount,
    change: change ? Number(change.toFixed(2)) : 0,
    adjustInBalance,
    discount: discountValue,
    discountPer:invDiscount,
    discP: discount,
    posted: true,
    // totalInvAmount: (totalAmount + Number(tax) + Number(freight) + Number(other) - discountValue).toFixed(2),
     totalInvAmount: (subtotal + Number(tax) + Number(freight) + Number(other) - discountValue).toFixed(2),
    isReturnInvoice: Number(prevBalance) + totalAmount + Number(tax) + Number(freight) + Number(other) - discountValue < 0,
    creditCustomersBalance:
      Number(prevBalance) + totalAmount + Number(tax) + Number(freight) + Number(other) - discountValue < 0
        ? paymentDetails.creditCustomersBalance
        : true,
    // getSalesDiscount: isSalesDisc || false,
    tSavings: totalSalesDiscountValue,
    other,
    freight,
    tStateTaxableAmt:totalAmount,
    sgst: sgst || null,
    cgst: cgst || null,
    igst: igst || null
  };
  if (totalAmount + Number(tax) + Number(freight) + Number(other) - discountValue <= 0) payload.paid = true;
  const items = [];
  tableData
    .filter((item) => item.itemNumber)
    .forEach((item) =>
      items.push({
        lineNumber: item.lineNumber || null,
        itemNumber: item.itemNumber,
        soldToCustomer: customerNumber,
        // tDate: todayDate, It is created at backend
        description: item.description,
        quantity: item.quantity,
        shipQty: item.quantity,
        discountPer: item.discount,
        discount: item.discountVal,
        RAmount: (item.price * item.quantity).toFixed(2),
        price: item.amount - item.discountVal,
        cPrice: item.amount,
        weight: item.weight,
        size: item.units,
      })
    );
  payload.items = items;
  return payload;
};

export const mapInvoiceDetails = (
  apiResp,
  setTableData,
  setCustomerDetails,
  setStatus,
  setInvDetails,
  setPaymentDetails,
  setTax,
  setDiscount,
  setDiscountValue,
  setIsSalesDisc,
  setFreight,
  setOther,
  setSgstPercentageParent,
  setCgstPercentageParent,
  setIgstPercentageParent,
  setInvDiscount
) => {
  const {
    data: {
      invoice: {
        items,
        soldToCustomer,
        posted,
        salesman_id,
        paymentTerms,
        shippedVia,
        driverName,
        tWeight,
        tCount,
        totalPaid,
        paymentsHistory,
        tax,
        discount,
        discountPer,
        discP,
        getSalesDiscount,
        creditCustomersBalance,
        paymentMethod,
        other,
        freight,
        sgst,
        cgst,
        igst
      },
    },
  } = apiResp;
  const tempItems = [];
  items.forEach((item) => {
    tempItems.push({
      lineNumber: item.lineNumber || null,
      itemNumber: item.itemNumber,
      description: item.description,
      quantity: item.quantity,
      amount: item.cPrice,
      discount: item.discountPer,
      originalDisc: item.discountPer,
      discountVal: item.discount,
      units: item.size,
      price: item.price,
      weight: item.weight || "",
    });
  });
  if (!posted) tempItems.push(initialTableRowValues);
  setTableData(tempItems);
  setCustomerDetails({
    customerNumber: soldToCustomer,
    customerName: "",
  });
  setStatus(posted ? "posted" : "open");
  setInvDetails((prev) => ({ ...prev, salesman_id, paymentTerms, shippedVia, driverName, tWeight, tCount }));
  const payments = paymentsHistory.map((item) => ({
    id: item.id || "",
    amountPaid: item.amountPaid || "",
    amountAlloc: item.amountAlloc || "",
    paymentType: item.pType || "",
    chequeNo: item.chequeNo || "",
    holdDate: item.holdDate ? getYyyymmddDateFormatted(item.holdDate) : "",
  }));
  // setPaymentDetails((prev) => ({ ...prev, payments, creditCustomersBalance, paymentMethod }));
  setTax(tax);
  setDiscount(discP);
  setDiscountValue(discount);
  setInvDiscount(discountPer || 0)
  setIsSalesDisc(getSalesDiscount);
  setFreight(freight);
  setOther(other);
  setSgstPercentageParent(sgst || 0)
  setCgstPercentageParent(cgst || 0);
  setIgstPercentageParent(igst || 0);
};

export const getPaymentTypes = ({ creditCardHold, isNoChecks }) => {
  const paymentsTypes = [
    {
      value: "Cash",
      label: "Cash",
    },
    {
      value: "Check",
      label: "Cheque",
    },
    {
      value: "Debit Card",
      label: "Debit Card",
    },
    {
      value: "Credit Card",
      label: "Credit Card",
    },
    {
      value: "UPI",
      label: "UPI",
    },
  ];
    if (creditCardHold) {
      paymentsTypes.splice(3, 1);
    }
    if (isNoChecks) {
      paymentsTypes.splice(1, 1);
    }
  return paymentsTypes;
};

export const focusLastSelectItemNumberInput = () => {
  const items = document.getElementsByClassName("select-item-number-wrapper");
  if (items.length) {
    items[items.length - 1].classList.add("active");
  }
};

export const focusQuantityNumberInput = (index) => {
  const items = document.getElementsByClassName("quantity-wrapper");
  if (items.length && items[index]) {
    items[index].classList.add("active");
  }
};


export const initialPaymentDetailsValues = {
  amountPaid: "",
  paymentType: "",
  chequeNo: "",
  chequeDate: "",
  inTheNameOf: "",
  bankName: "",
  emailCopy: true,
  textCopy: true,
  creditCustomersBalance: true,
  paymentMethod: "",
  payments: [
    {
      amountAlloc: "",
      amountPaid: "",
      paymentType: "",
      chequeNo: "",
      holdDate: "",
    },
  ],
};

export const initialInvDetailsValues = {
  salesman_id: "",
  paymentTerms: "",
  shippedVia: "",
  driverName: "",
  tWeight: "",
  tCount: "",
};

export const totalLimit = 9950;


