import React from "react";
import {
     Col, Container, Input, Label, Row, Button,
} from 'reactstrap';
import ResetIcon from "../../../../assets/images/warehouse/salespage/reset-blue.png";
import SaveIcon from "../../../../assets/images/warehouse/salespage/save.png";

export const CodePrefixes = () => {
  return (
    <Container fluid className="px-5 py-2">
      <Row>
        <Col xs="9">
          <Row className="mb-1">
            <Col xs={2} className="d-flex align-items-center">
              <Label htmlFor="companyName" className="form-label mb-0 text-nowrap">
                Customer Prefix :
              </Label>
            </Col>
            <Col xs={10}>
              <Input
                name="companyName"
                id="companyName"
                className="form-control py-1"
                placeholder="Enter Customer Prefix"
                type="text"
              />
            </Col>
          </Row>
          <Row className="mb-1">
            <Col xs={2} className="d-flex align-items-center">
              <Label htmlFor="companyName" className="form-label mb-0 text-nowrap">
                Employee Prefix :
              </Label>
            </Col>
            <Col xs={10}>
              <Input
                name="companyName"
                id="companyName"
                className="form-control py-1"
                placeholder="Enter Employee Prefix"
                type="text"
              />
            </Col>
          </Row>
          <Row className="mb-1">
            <Col xs={2} className="d-flex align-items-center">
              <Label htmlFor="companyName" className="form-label mb-0 text-nowrap">
                Supplier Prefix :
              </Label>
            </Col>
            <Col xs={10}>
              <Input
                name="companyName"
                id="companyName"
                className="form-control py-1"
                placeholder="Enter Supplier Prefix"
                type="text"
              />
            </Col>
          </Row>
          <Row className="mb-1">
            <Col xs={2} className="d-flex align-items-center">
              <Label htmlFor="companyName" className="form-label mb-0 text-nowrap">
                Invoice Prefix :
              </Label>
            </Col>
            <Col xs={10}>
              <Input
                name="companyName"
                id="companyName"
                className="form-control py-1"
                placeholder="Enter Invoice Prefix"
                type="text"
              />
            </Col>
          </Row>
          <Row className="mb-1">
            <Col xs={2} className="d-flex align-items-center">
              <Label htmlFor="companyName" className="form-label mb-0 text-nowrap">
                Item Code Prefix :
              </Label>
            </Col>
            <Col xs={10}>
              <Input
                name="companyName"
                id="companyName"
                className="form-control py-1"
                placeholder="Enter Item Code Prefix"
                type="text"
              />
            </Col>
          </Row>
          <Row className="mb-1">
            <Col xs={2} className="d-flex align-items-center">
              <Label htmlFor="companyName" className="form-label mb-0 text-nowrap">
                Purchase Order Prefix :
              </Label>
            </Col>
            <Col xs={10}>
              <Input
                name="companyName"
                id="companyName"
                className="form-control py-1"
                placeholder="Enter Purchase Order Prefix"
                type="text"
              />
            </Col>
          </Row>
        </Col>
        <Col xs="3">
          <Button color="primary" className="mb-2 w-100 wh-btn" outline>
            <span className="d-flex justify-content-center align-items-center">
              {/* <Spinner size="sm" type="grow" className="flex-shrink-0" role="status"> Loading... </Spinner> */}
              <img src={ResetIcon} style={{ height: "32px", width: "32px" }} />
              <span className="ms-2">Update Details</span>
            </span>
          </Button>
          <Button color="primary" className="mb-2 w-100 wh-btn" outline>
            <span className="d-flex justify-content-center align-items-center">
              {/* <Spinner size="sm" type="grow" className="flex-shrink-0" role="status"> Loading... </Spinner> */}
              <img src={SaveIcon} style={{ height: "32px", width: "32px" }} />
              <span className="ms-2">Save</span>
            </span>
          </Button>
        </Col>
      </Row>
    </Container>
  );
};