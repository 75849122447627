import * as Yup from "yup";
export const billingInfoInitialValues = {
  isTaxExempt: false,
  getSalePrices:false,
  isOutOfState:false,
  pricingType:"",
  markupPercent: "",
  markup: "",
  discountPercent: "",
  discount: "",
  code: "",
  dlState:"",
  paymentTerms: "",
  creditLimit: "",
  salesLimit: "",
  isCreditHold: false,
  creditCardHold: false,
  isBillToBill: false,
  isNoChecks: false,
  isExcludeOverduePopup: false,
  check3pCharge: "",
  cashBackBalance: "",
  scheduleFrom: "",
  scheduleTo: "",
  scheduleMemo:"",
  popUp:false,
  popMemo:"",
};

export const billingInfoValidationSchema = Yup.object({
    // shipToRef: Yup.string().required("Company Name is required"),
    // shippedVia: Yup.string().required("Customer Name is required"),
})