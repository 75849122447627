import {Link} from "react-router-dom";

export function generateColumns() {
    return [
        {
            Header: "Company Name",
            accessor: "companyName"
        }, {
            Header: "Customer Name",
            accessor: "customerName",
            Cell:({row:{original : {firstName, lastName}}}) => `${firstName || ""} ${lastName || ""} ${(!firstName && !lastName) ? "-" : ""}`
        }, {
            Header: "Cust. Num",
            accessor: "customerNumber",
            Cell:({cell:{value}}) => <Link to={`/warehousecustomers/${value}`}>{value}</Link>
        },
    ]
}