import React, {  useState, useEffect, useMemo, useCallback } from "react";
import { Col, Container, Input, Label, Row, Button } from 'reactstrap';
import WhTableContainer from "../../Components/Common/WhTables";
import {getSearchLabel} from "./helper";
import {generateColumns} from "./searchpurchaseordershelper";
import { getStartDateFilterValue, getEndDateFilterValue } from "../../utility/dateutility";

export default function SearchPurchaseOrders({ setFilters, setShowSearchModal, modalTableData, modalPageCount, getAllPurchaseOrdersAct, generateApiUrl, dispatch, getAllPurchaseOrdersLoading}) {
    const [search,setSearch] = useState("");
    const [startDate,setStarDate] = useState("");
    const [endDate,setEndDate] = useState("");
    const [searchBy, setSearchBy] = useState("supplierName");
    const getAllPurchaseOrdersApiCall = useCallback(
      (page, pageSize) => {
        let params = {
          page: page ? page + 1 : 1,
          pageSize: pageSize || 30,
        };
        if (["paidStatus", "receivedStatus"].indexOf(searchBy) > -1) {
          if (search === "true") params[searchBy] = true;
          else params[searchBy] = false;
        } else if (["poDate"].indexOf(searchBy) > -1) {
          params.poStartDate = getStartDateFilterValue(startDate);
          params.poEndDate = getEndDateFilterValue(endDate);
        } else if (search) params[searchBy] = search;
        if (searchBy === "poDate" ? startDate && endDate : true)
          dispatch(getAllPurchaseOrdersAct(generateApiUrl(`get_all_purchase_orders`), params));
      },
      [search, searchBy, startDate, endDate]
    );
    useEffect(() => {
      const debounceFn = setTimeout(() => {
        getAllPurchaseOrdersApiCall();
      }, 400);
      return () => {
        clearTimeout(debounceFn);
      };
    }, [getAllPurchaseOrdersApiCall]);
    const handlePageChange = (page, pageSize) => {
      getAllPurchaseOrdersApiCall(page, pageSize);
    };
    const handleChange = (e) => {
     const {name} = e.target;
     if(searchBy != name) {
       setSearchBy(name);
       setSearch("");
      }
    }
    // const searchHandler = () => {
    //   setFilters(prev => ({...prev, search, searchBy, startDate, endDate}));
    //   setShowSearchModal(false);
    // }
    const columns = useMemo(() => generateColumns(), []);
    return (
      <Container fluid>
        <Row className="mb-4">
          <Col xs={12} className="bg-primary text-center fs-5 text-white py-2">
            Smart Search-Purchase Orders
          </Col>
        </Row>
        <Row className="mb-4">
          <Col xs={12}>
            <div className="px-3" style={{ position: "relative" }}>
              <Label
                className="fs-5 font-weight-bold "
                style={{ position: "absolute", transform: "translateY(-50%)" }}
              >
               <span className=" px-1 wh-item-bg"> Type Of Search</span>
              </Label>
              <Row className="border pt-4 pb-3">
                <Col xs="4" className="mb-2">
                  <Input
                    type="radio"
                    name="supplierName"
                    className="me-2"
                    onChange={handleChange}
                    checked={searchBy == "supplierName"}
                  />{" "}
                  By Supplier Name
                </Col>
                <Col xs="4" className="mb-2">
                  <Input
                    type="radio"
                    name="purchaseOrderNumber"
                    className="me-2"
                    onChange={handleChange}
                    checked={searchBy == "purchaseOrderNumber"}
                  />{" "}
                  By Purchase Order Number
                </Col>
                <Col xs="4">
                  <Input
                    type="radio"
                    name="poDate"
                    className="me-2"
                    onChange={handleChange}
                    checked={searchBy == "poDate"}
                  />{" "}
                  By PO Date
                </Col>
                <Col xs="4" className="mb-2">
                  <Input
                    type="radio"
                    name="paymentTerm"
                    className="me-2"
                    onChange={handleChange}
                    checked={searchBy == "paymentTerm"}
                  />{" "}
                  By Payment Term
                </Col>
                {/* <Col xs="4" className="mb-2">
                  <Input
                    type="radio"
                    name="shipDate"
                    className="me-2"
                    onChange={handleChange}
                    checked={searchBy == "shipDate"}
                  />{" "}
                  By Ship Date
                </Col> */}

                <Col xs="4" className="mb-2">
                  <Input
                    type="radio"
                    name="receivedStatus"
                    className="me-2"
                    onChange={handleChange}
                    checked={searchBy == "receivedStatus"}
                  />{" "}
                  By Received Status
                </Col>
                <Col xs="4">
                  <Input
                    type="radio"
                    name="paidStatus"
                    className="me-2"
                    onChange={handleChange}
                    checked={searchBy == "paidStatus"}
                  />{" "}
                  By Paid Status
                </Col>
                <Col xs="4">
                  <Input
                    type="radio"
                    name="supplierNumber"
                    className="me-2"
                    onChange={handleChange}
                    checked={searchBy == "supplierNumber"}
                  />{" "}
                  By Supplier Number
                </Col>
              </Row>
            </div>
          </Col>
        </Row>

        {searchBy && (
          <Row className="mb-2">
            <Col xs={12}>
              <Label htmlFor="userrole" className="form-label mb-1">
                Search By {getSearchLabel(searchBy)}
              </Label>
              <div className="d-flex align-items-center">
                {["purchaseOrderNumber", "paymentTerm", "supplierNumber", "supplierName"].indexOf(searchBy) > -1 && (
                  <Input
                    className="form-control me-2"
                    type="text"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                )}
                {["shipDate"].indexOf(searchBy) > -1 && (
                  <Input
                    className="form-control me-2"
                    type="date"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                )}
                {["poDate"].indexOf(searchBy) > -1 && (
                  <div className="d-flex align-items-center flex-grow-1">
                    <Input
                      className="form-control me-2"
                      type="date"
                      value={startDate}
                      onChange={(e) => setStarDate(e.target.value)}
                    />
                    <span className="me-2">to</span>
                    <Input
                      className="form-control me-2 flex-grow-1"
                      type="date"
                      value={endDate}
                      onChange={(e) => setEndDate(e.target.value)}
                    />
                  </div>
                )}
                {searchBy === "receivedStatus" && (
                  <Input
                    className="form-control me-2"
                    type="select"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                  >
                    <option value={false}>Not Received</option>
                    <option value={true}>Received</option>
                  </Input>
                )}
                {searchBy === "paidStatus" && (
                  <Input
                    className="form-control me-2"
                    type="select"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                  >
                    <option value={false}>Unpaid</option>
                    <option value={true}>Paid</option>
                  </Input>
                )}
                {/* <Button
                  color="primary"
                  onClick={searchHandler}
                  disabled={["paidStatus", "receivedStatus"].indexOf(searchBy) > -1 ? false : (searchBy === "poDate" ? (startDate && endDate) :search) ? false : true}
                >
                  Search
                </Button> */}
              </div>
            </Col>
          </Row>
        )}

        {/* <Row>
          <Col xs="12" className="d-flex justify-content-end"></Col>
        </Row> */}
        <Row>
          <Col xs={12} style={{  minHeight: "350px", maxHeight: "350px", overflowY: "auto" }}>
            <WhTableContainer
              columns={columns}
              data={modalTableData}
              className="custom-header-css"
              divClass="table-responsive mt-3"
              tableClass="align-middle"
              theadClass="table-light"
              handlePageChange={handlePageChange}
              pageCount={modalPageCount}
              customPageSize={30}
              showNoDataComponent={true}
              getAllPurchaseOrdersLoading={getAllPurchaseOrdersLoading}
            />
          </Col>
        </Row>
      </Container>
    );
}