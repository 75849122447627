//Utility
import {formatAmount} from "../../../../utility/commonutility";

export function generateColumns(history,page) {
    return [
      {
        Header: "S.No",
        accessor: "id",
        Cell:({row}) => (((page - 1) * 10 ) + (row.index + 1))
    },
      {
        Header: "Supplier Number",
        accessor: "supplierNumber",
        Cell: ({ cell: { value } }) => (
          <div
            className="wh-link-color-green"
            style={{ cursor: "pointer" }}
            onClick={() => {
              history.push(`/warehousevendors/${value}`, { redirected: true });
              localStorage.setItem("activeTab",JSON.stringify({warehouseinventory:"suppliers"}));
            }}
          >
            {value}
          </div>
        ),
      },
      {
        Header: "Supplier Name",
        accessor: "supplier.supplierName",
      },
      {
        Header: "Supplier Item #",
        accessor: "supplierItemNumber",
      },
      {
        Header: "Case Qty",
        accessor: "caseQty",
      },
      {
        Header: "Cost",
        accessor: "cost",
        Cell : ({cell : {value}}) => (typeof value == "number") ? <>{formatAmount(value.toFixed(2))}</> : "",
        style: {textAlign:"right"}
      },
      {
        Header: "Discount",
        accessor: "vDisc",
      },
      {
        Header: "Single Cost",
        accessor: "singleCost",
        Cell : ({cell : {value}}) => (typeof value == "number") ? <>{formatAmount(value.toFixed(2))}</> : "",
        style: {textAlign:"right"}
      },
      {
        Header: "Net Cost",
        accessor: "netCost",
        Cell : ({cell : {value}}) => (typeof value == "number") ? <>{formatAmount(value.toFixed(2))}</> : "",
        style: {textAlign:"right"}
      },
    ];
}