import ResetIcon from "../../assets/images/warehouse/salespage/reset.png";
import PrintIcon from "../../assets/images/warehouse/salespage/printer.png";
import ExportIcon from "../../assets/images/warehouse/salespage/excel.png";
import DeleteIcon from "../../assets/images/warehouse/salespage/delete.png";
import NewIcon from "../../assets/images/warehouse/salespage/new.png";
import { Link } from "react-router-dom";
//Utility
import { getMmddyyyyDateFormatted, getCustomDateFormatted } from "../../utility/dateutility"
//Constants
import { currencySymbol } from "../../constants";

export const actions = (history,toggleDeleteModal,resetFilters,toggleSearchModal) => [
    {
        id: "new",
        label: "New",
        icon: NewIcon,
        clickHandler:() => history.push("/warehousesales/new")
    }, 
    {
        id: "reset",
        label: "Reset",
        icon: ResetIcon,
        clickHandler:resetFilters
    }, 
    // {
    //     id: "print",
    //     label: "Print",
    //     icon: PrintIcon,
    //     clickHandler:() =>{}
    // }, {
    //     id: "export",
    //     label: "Export",
    //     icon: ExportIcon,
    //     clickHandler:() =>{}
    // }, 
    {
        id: "delete",
        label: "Delete",
        icon: DeleteIcon,
        clickHandler:toggleDeleteModal,
    }, {
        id: "smart_search",
        label: "Smart-Search",
        icon: <i className="ri-search-2-fill text-primary" style={{fontSize:"20px"}}></i>,
        clickHandler:toggleSearchModal,
    }
]

export function generateColumns(page) {
    return [
        // {
        //     Header: "S.No",
        //     accessor: "id",
        //     Cell:({row}) => (((page - 1) * 10 ) + (row.index + 1))
        // },
        {
            Header: "Invoice No",
            accessor: "invoiceNumber",
            Cell:({cell:{value}}) => <Link to={`/warehousesales/${value}`}>{value}</Link>
        }, 
        {
            Header: "Customer Name",
            accessor: "customerName",
            Cell:({row:{original : {customer,soldToCustomer}}}) => <Link to={`/warehousecustomers/${soldToCustomer}`}>{`${customer?.firstName || ""} ${customer?.lastName || ""}`}</Link>
        },
        {
            Header: "Company Name",
            accessor: "customer.companyName",
            Cell:({cell:{value}}) => value || "-"
        }, 
        {
            Header: "Invoice Date",
            accessor: "invoiceDate",
            Cell:({cell:{value}}) => value ? getCustomDateFormatted(value, "MM-DD-YYYY hh:mm A") : "-"
        }, 
        {
            Header: "Salesman",
            accessor: "salesman.name",
            Cell:({cell:{value}}) => value || "-"
        }, 
        {
            Header: "Total Amount",
            accessor: "totalInvAmount",
            Cell : ({cell : {value}}) => (typeof value == "number") ? <>{value < 0 && "-"}{currencySymbol}{Math.abs(value).toFixed(2)}</> : "",
        },
        {
            Header: "Total Paid",
            accessor: "totalPaid",
            Cell : ({cell : {value}}) => (typeof value == "number") ? <>{value < 0 && "-"}{currencySymbol}{Math.abs(value).toFixed(2)}</> : "",
        },
        {
            Header: "Amount Due",
            accessor: "amountDue",
            Cell : ({cell : {value}}) => (typeof value == "number") ? <>{value < 0 && "-"}{currencySymbol}{Math.abs(value).toFixed(2)}</> : "",
        },
    ]
}

export function getSearchLabel(searchBy) {
    switch(searchBy) {
      case "invoiceNumber": return "Invoice Number";
      case "customerNumber": return "Customer Number";
      case "invoiceDate": return "Invoice Date";
      case "salesman": return "Salesman";
      case "status": return "Status";
      default :  return "";
    }
}
