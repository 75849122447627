import customerconstants from "./customerconstants";

const initialState = {
  getAllCustomersResp: undefined,
  getAllCustomersLoading: false,
  getCustomerResp: undefined,
  createCustomerResp: undefined,
  getMaxCustomerNoResp: undefined,
  getMasterDataAllResp: undefined,
  updateBasicInfoResp: undefined,
  updateClassificationResp: undefined,
  updateBillingInfoResp: undefined,
  getPaymentsOfCustomerResp: undefined,
  getInvoicesOfCustomerResp: undefined,
  getInvoicesOfCustomerLoading: false,
  deleteCustomerResp: undefined,
  getCustomerAccountBalanceHistoryResp: undefined,
  createSpecialPriceForCustomerResp: undefined,
  deleteSpecialPriceForCustomerResp: undefined,
  acceptPaymentsAnywhereResp: undefined,
  customerApiLoading: false,
  getCustomerInventoryDiscountsResp: undefined,
  customerErrResp: undefined,
};

export default function customer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case customerconstants.GET_ALL_CUSTOMERS_SUCCESS:
      return { ...state, getAllCustomersResp: payload };
    case customerconstants.CHANGE_GET_ALL_CUSTOMERS_LOADING_STATUS:
      return { ...state, getAllCustomersLoading: payload };
    case customerconstants.GET_CUSTOMER_SUCCESS:
      return { ...state, getCustomerResp: payload };
    case customerconstants.CREATE_CUSTOMER_SUCCESS:
      return { ...state, createCustomerResp: payload };
    case customerconstants.GET_MAX_CUSTOMER_NO_SUCCESS:
      return { ...state, getMaxCustomerNoResp: payload };
    case customerconstants.GET_MASTER_DATA_ALL_SUCCESS:
      return { ...state, getMasterDataAllResp: payload };
    case customerconstants.GET_MASTER_DATA_BY_CATEGORY_SUCCESS:
      return { ...state, getMasterDataByCategoryResp: payload };
    case customerconstants.UPDATE_BASIC_INFO_SUCCESS:
      return { ...state, updateBasicInfoResp: payload };
    case customerconstants.UPDATE_CLASSIFICATION_SUCCESS:
      return { ...state, updateClassificationResp: payload };
    case customerconstants.UPDATE_BILLING_INFO_SUCCESS:
      return { ...state, updateBillingInfoResp: payload };
    case customerconstants.GET_PAYMENTS_OF_CUSTOMER_SUCCESS:
      return { ...state, getPaymentsOfCustomerResp: payload };
    case customerconstants.GET_INVOICES_OF_CUSTOMER_SUCCESS:
      return { ...state, getInvoicesOfCustomerResp: payload };
    case customerconstants.CHANGE_GET_INVOICES_LOADING_STATUS:
      return { ...state, getInvoicesOfCustomerLoading: payload };
    case customerconstants.DELETE_CUSTOMER_SUCCESS:
      return { ...state, deleteCustomerResp: payload };
    case customerconstants.GET_CUSTOMER_ACCOUNT_BALANCE_HISTORY_SUCCESS:
      return { ...state, getCustomerAccountBalanceHistoryResp: payload };
    case customerconstants.CREATE_SPECIAL_PRICE_FOR_CUSTOMER_SUCCESS:
      return { ...state, createSpecialPriceForCustomerResp: payload };
    case customerconstants.DELETE_SPECIAL_PRICE_FOR_CUSTOMER_SUCCESS:
      return { ...state, deleteSpecialPriceForCustomerResp: payload };
    case customerconstants.ACCEPT_PAYMENTS_ANYWHERE_SUCCESS:
      return { ...state, acceptPaymentsAnywhereResp: payload };
    case customerconstants.CHANGE_CUSTOMER_API_LOADING_STATUS:
      return { ...state, customerApiLoading: payload };
    case customerconstants.GET_CUSTOMER_INVENTORY_DISCOUNTS_SUCCESS:
      return { ...state, getCustomerInventoryDiscountsResp: payload };

    case customerconstants.GET_ALL_CUSTOMERS_FAIL:
    case customerconstants.GET_CUSTOMER_FAIL:
    case customerconstants.CREATE_CUSTOMER_FAIL:
    case customerconstants.GET_MAX_CUSTOMER_NO_FAIL:
    case customerconstants.GET_MASTER_DATA_ALL_FAIL:
    case customerconstants.GET_MASTER_DATA_BY_CATEGORY_FAIL:
    case customerconstants.UPDATE_BASIC_INFO_FAIL:
    case customerconstants.UPDATE_CLASSIFICATION_FAIL:
    case customerconstants.UPDATE_BILLING_INFO_FAIL:
    case customerconstants.GET_PAYMENTS_OF_CUSTOMER_FAIL:
    case customerconstants.GET_INVOICES_OF_CUSTOMER_FAIL:
    case customerconstants.DELETE_CUSTOMER_FAIL:
    case customerconstants.GET_CUSTOMER_ACCOUNT_BALANCE_HISTORY_FAIL:
    case customerconstants.CREATE_SPECIAL_PRICE_FOR_CUSTOMER_FAIL:
    case customerconstants.DELETE_SPECIAL_PRICE_FOR_CUSTOMER_FAIL:
    case customerconstants.ACCEPT_PAYMENTS_ANYWHERE_FAIL:
    case customerconstants.GET_CUSTOMER_INVENTORY_DISCOUNTS_FAIL:
      return { ...state, customerErrResp: payload };
    default:
      return state;
  }
}
