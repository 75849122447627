import React from "react";
import {
     Col, Container, Input, Label, Row, Button,
} from 'reactstrap';
import ResetIcon from "../../../../assets/images/warehouse/salespage/reset-blue.png";
import SaveIcon from "../../../../assets/images/warehouse/salespage/save.png";

export const CompanyProfile = () => {
  return (
    <Container fluid className="px-5 py-3">
      <Row>
        <Col xs="9">
          <Row className="mb-1">
            <Col xs={2} className="d-flex align-items-center">
              <Label htmlFor="companyName" className="form-label mb-0 text-nowrap">
                Company Name :
              </Label>
            </Col>
            <Col xs={10}>
              <Input
                name="companyName"
                id="companyName"
                className="form-control py-1"
                placeholder="Enter Company Name"
                type="text"
              />
            </Col>
          </Row>
          <Row className="mb-1">
            <Col xs={2} className="d-flex align-items-center">
              <Label htmlFor="customerName" className="form-label mb-0 text-nowrap">
                Slogan :
              </Label>
            </Col>
            <Col xs={10}>
              <Input
                name="customerName"
                id="customerName"
                className="form-control py-1"
                placeholder="Enter Slogan"
                type="text"
              />
            </Col>
          </Row>
          <Row className="mb-1">
            <Col xs={2} className="d-flex align-items-center">
              <Label htmlFor="customerName" className="form-label mb-0 text-nowrap">
                Contact :
              </Label>
            </Col>
            <Col xs={5}>
              <Input className="form-control py-1" type="text" />
            </Col>
            <Col xs={5}>
              <Input className="form-control py-1" type="text" />
            </Col>
          </Row>
          <Row className="mb-1">
            <Col xs={2} className="d-flex align-items-center">
              <Label htmlFor="companyName" className="form-label mb-0 text-nowrap">
                Branding :
              </Label>
            </Col>
            <Col xs={10}>
              <Input className="form-control py-1" placeholder="Enter Branding" type="text" />
            </Col>
          </Row>
          <Row className="mb-1">
            <Col xs={2} className="d-flex align-items-center">
              <Label htmlFor="companyName" className="form-label mb-0 text-nowrap">
                Street :
              </Label>
            </Col>
            <Col xs={10} className="d-flex align-items-center">
              <Input className="form-control me-2 py-1" placeholder="Enter Street" type="text" />
              <i className="ri-map-pin-line fs-5"></i>
            </Col>
          </Row>
          <Row className="mb-1">
            <Col xs={2} className="d-flex align-items-center">
              <Label htmlFor="companyName" className="form-label mb-0 text-nowrap">
                Suit :
              </Label>
            </Col>
            <Col xs={10}>
              <Input className="form-control py-1" placeholder="Enter Suit" type="text" />
            </Col>
          </Row>
          <Row className="mb-1">
            <Col xs={2} className="d-flex align-items-center">
              <Label htmlFor="companyName" className="form-label mb-0 text-nowrap">
                City :
              </Label>
            </Col>
            <Col xs={10}>
              <Input className="form-control py-1" placeholder="Enter City" type="text" />
            </Col>
          </Row>
          <Row className="mb-1">
            <Col xs={2} className="d-flex align-items-center">
              <Label htmlFor="companyName" className="form-label mb-0 text-nowrap">
                State :
              </Label>
            </Col>
            <Col xs={4}>
              <Input className="form-control py-1" placeholder="Enter State" type="text" />
            </Col>
            <Col xs="1"></Col>
            <Col xs={1} className="d-flex align-items-center">
              <Label htmlFor="companyName" className="form-label mb-0 text-nowrap">
                Zip :
              </Label>
            </Col>
            <Col xs={4}>
              <Input className="form-control py-1" placeholder="Enter Zip" type="text" />
            </Col>
          </Row>
          <Row className="mb-1">
            <Col xs={2} className="d-flex align-items-center">
              <Label htmlFor="companyName" className="form-label mb-0 text-nowrap">
                Phone :
              </Label>
            </Col>
            <Col xs={4}>
              <Input className="form-control py-1" placeholder="Enter Phone" type="text" />
            </Col>
            <Col xs="1"></Col>
            <Col xs={1} className="d-flex align-items-center">
              <Label htmlFor="companyName" className="form-label mb-0 text-nowrap">
                Fax :
              </Label>
            </Col>
            <Col xs={4}>
              <Input className="form-control py-1" placeholder="Enter Fax" type="text" />
            </Col>
          </Row>
          <Row className="mb-1">
            <Col xs={2} className="d-flex align-items-center">
              <Label htmlFor="companyName" className="form-label mb-0 text-nowrap">
                Email Id :
              </Label>
            </Col>
            <Col xs={10}>
              <Input className="form-control py-1" placeholder="Enter Email Id" type="text" />
            </Col>
          </Row>
          <Row className="mb-1">
            <Col xs={2} className="d-flex align-items-center">
              <Label htmlFor="companyName" className="form-label mb-0 text-nowrap">
                Company Logo :
              </Label>
            </Col>
            <Col xs={4}>
              <Input className="form-control py-1" type="file" id="formFile" />
            </Col>
          </Row>
          <Row className="mb-1">
            <Col xs={2} className="d-flex align-items-center">
              <Label htmlFor="companyName" className="form-label mb-0 text-nowrap">
                Company Header :
              </Label>
            </Col>
            <Col xs={4}>
              <Input className="form-control py-1" type="file" id="formFile" />
            </Col>
            <Col xs="2" className="d-flex align-items-center">
              <div className="form-check">
                <Input className="form-check-input" type="checkbox" id="formCheck6" defaultChecked />
                <Label className="form-check-label text-nowrap" htmlFor="formCheck6">
                  Use Company Header on Report
                </Label>
              </div>
            </Col>
          </Row>
        </Col>
        <Col xs="3">
          <Button color="primary" className="mb-2 w-100 wh-btn" outline>
            <span className="d-flex justify-content-center align-items-center">
              {/* <Spinner size="sm" type="grow" className="flex-shrink-0" role="status"> Loading... </Spinner> */}
              <img src={ResetIcon} style={{ height: "32px", width: "32px" }} />
              <span className="ms-2">Update Details</span>
            </span>
          </Button>
          <Button color="primary" className="mb-2 w-100 wh-btn" outline>
            <span className="d-flex justify-content-center align-items-center">
              {/* <Spinner size="sm" type="grow" className="flex-shrink-0" role="status"> Loading... </Spinner> */}
              <img src={SaveIcon} style={{ height: "32px", width: "32px" }} />
              <span className="ms-2">Save</span>
            </span>
          </Button>
        </Col>
      </Row>
    </Container>
  );
};