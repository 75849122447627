import React, { useState } from "react";
import { Col, Container, Input, Label, Row, Button } from "reactstrap";
//Actions
import { createQtyAdjustmentAct } from "../../store/inventory/inventoryactions";

export default function QtyAdjustment({
  adjustmentHistoryDetails,
  dispatch,
  generateApiUrl,
  id,
  qtyInStock,
  toggleQtyAdjustmentModal,
  toggleMultiQtyAdjustmentModal,
}) {
  const { lastInventoryDate, quantity, quantityAdj: qtyAdj } = adjustmentHistoryDetails;
  const [quantityAdj, setQuantityAdj] = useState();
  const createQtyAdjustment = () => {
    dispatch(
      createQtyAdjustmentAct(generateApiUrl("create_qty_adjustment", { itemNumber: id }), { quantityAdj: quantityAdj })
    );
  };
  return (
    <Container fluid>
      <Row className="mb-4">
        <Col xs={12} className="border-bottom">
          <div className="px-3" style={{ position: "relative" }}>
            <Row className="pt-4 pb-3">
              <Col xs="6" className="mb-2">
                <Label className="fw-bold">Last Inventory Date</Label>
              </Col>
              <Col xs="6" className="mb-2">
                <Label>{lastInventoryDate || "-"}</Label>
              </Col>
              <Col xs="6" className="mb-2">
                <Label className="fw-bold">Inv. Date Qty</Label>
              </Col>
              <Col xs="6" className="mb-2">
                <Label>{quantity || "-"}</Label>
              </Col>

              <Col xs="6" className="mb-2">
                <Label className="fw-bold">Adjustments</Label>
              </Col>
              <Col xs="6" className="mb-2">
                <Label>{qtyAdj || "-"}</Label>
              </Col>

              <Col xs="6" className="mb-2">
                <Label className="fw-bold">Purchases</Label>
              </Col>
              <Col xs="6" className="mb-2">
                <Label>-</Label>
              </Col>

              <Col xs="6" className="mb-2">
                <Label className="fw-bold">Sales</Label>
              </Col>
              <Col xs="6" className="mb-2">
                <Label>-</Label>
              </Col>
            </Row>
          </div>
        </Col>
        <Col xs="12">
          <Row className="px-3 pt-4 pb-3">
            <Col xs="3" className="mb-2 d-flex align-items-center">
              {" "}
              <Label className="fw-bold">Qty in Stock :</Label>
            </Col>
            <Col xs="9" className="mb-2">
              {" "}
              <Input type="text" className="form-control" value={qtyInStock} readOnly />
            </Col>

            <Col xs="3" className="d-flex align-items-center mb-2">
              {" "}
              <Label className="fw-bold text-nowrap">Accurate Qty :</Label>
            </Col>
            <Col xs="9" className="mb-2">
              {" "}
              <Input type="number" onWheel={(e) => e.target.blur()} value={qtyInStock} readOnly className="form-control" />
            </Col>

            <Col xs="3" className="d-flex align-items-center mb-2">
              {" "}
              <Label className="fw-bold">New Qty :</Label>
            </Col>
            <Col xs="9" className="mb-2">
              {" "}
              <Input
                type="number" onWheel={(e) => e.target.blur()}
                className="form-control"
                value={quantityAdj}
                onChange={(e) => setQuantityAdj(e.target.value)}
              />
            </Col>

            <Col xs="12" className="d-flex align-items-center my-2">
              <Input type="checkbox" className="my-0" />
              <Label className="my-0 ms-2">Update Last Inventory Date</Label>
            </Col>
          </Row>
        </Col>
        <Col xs="12" className="d-flex justify-content-center">
          <Button color="primary" className="me-2" onClick={createQtyAdjustment}>
            Update Qty
          </Button>
          <Button
            color="light"
            className="bg-gradient border"
            onClick={() => {
              toggleQtyAdjustmentModal();
              toggleMultiQtyAdjustmentModal();
            }}
          >
            Multi Update
          </Button>
        </Col>
      </Row>
    </Container>
  );
}
