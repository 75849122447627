import React, { useMemo, useState, useRef, useEffect, useCallback } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { Col, Container, Label, Row, Modal, ModalHeader, ModalBody, Form } from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import WhTableContainer from "../../Components/Common/WhTables";
import { actions, generateColumns, supplierColumnsList } from "./helper";
import DeleteModal from "../../Components/Common/DeleteModal";
import SearchVendor from "./searchvendor";
import SelectItem from "../../Components/Common/SelectItem";
import PaymentsAnywhere from "../../Components/Common/paymentsanywhere";
import ApiLoader from "../../Components/Common/ApiLoader";
//Api Helper
import { generateApiUrl } from "../../api/apihelper";
//Actions
import { getSuppliersListAct, deleteSupplierAct } from "../../store/supplier/supplieractions";
//HOC
import withPermission from "../../hoc/withPermission"

function WarehouseVendors({ history }) {
  const [tableData, setTableData] = useState(null);
  const [modalTableData, setModalTableData] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [modalPageCount, setModalPageCount] = useState(0);
  const [showSearchModal, setShowSearchModal] = useState(false);
  const [search, setSearch] = useState("");
  const [searchListOptions, setSearchListOptions] = useState([]);
  function toggleSearchModal() {
    setShowSearchModal(!showSearchModal);
  }
  const [rowSelection, setRowSelection] = useState({});
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const toggleDeleteModal = () => setShowDeleteModal(!showDeleteModal);

  const dropdownBtnClicked = useRef(false);
  const currentSupplierListPage = useRef(1);
  const callbackOnSupplierListApiResp = useRef(() => {});

  const dispatch = useDispatch();
  const { getSuppliersListResp, deleteSupplierResp, supplierErrorResp, supplierApiLoading, getSuppliersListLoading } =
    useSelector(
      ({ supplier }) => ({
        getSuppliersListResp: supplier.getSuppliersListResp,
        deleteSupplierResp: supplier.deleteSupplierResp,
        supplierErrorResp: supplier.supplierErrorResp,
        supplierApiLoading: supplier.supplierApiLoading,
        getSuppliersListLoading: supplier.getSuppliersListLoading,
      }),
      shallowEqual
    );
  const columns = useMemo(() => generateColumns(), []);
  const customerResp = useRef({
    prevGetSuppliersListResp: getSuppliersListResp,
    prevDeleteSupplierResp: deleteSupplierResp,
    prevSupplierErrorResp: supplierErrorResp,
  });
  useEffect(() => {
    const { prevGetSuppliersListResp, prevDeleteSupplierResp, prevSupplierErrorResp } = customerResp.current || {};
    if (getSuppliersListResp && prevGetSuppliersListResp !== getSuppliersListResp) {
      const list = [];
      (getSuppliersListResp?.data?.suppliers || []).forEach(({ supplierNumber, firstName, lastName, supplierName }) => {
        list.push({
          supplierNumber,
          supplierName: `${firstName || ""} ${lastName || ""}`,
          companyName: supplierName,
        });
      });
      if (!dropdownBtnClicked.current) {
        if (!showSearchModal) {
          setTableData(getSuppliersListResp?.data?.suppliers || []);
          setPageCount(getSuppliersListResp?.data?.total);
        } else {
          setModalTableData(getSuppliersListResp?.data?.suppliers || []);
          setModalPageCount(getSuppliersListResp?.data?.total);
        }
        setSearchListOptions(list);
      } else {
        setSearchListOptions((prev) => [...prev, ...list]);
        if (getSuppliersListResp?.data?.suppliers?.length > 0) callbackOnSupplierListApiResp.current();
        currentSupplierListPage.current = Number(getSuppliersListResp?.data?.page) + 1;
      }
    }
    if (deleteSupplierResp && prevDeleteSupplierResp !== deleteSupplierResp) {
      toast.success("Successfully deleted suppliers");
      getAllSuppliersApiCall();
      toggleDeleteModal();
    }
    if (supplierErrorResp && prevSupplierErrorResp !== supplierErrorResp) {
      const { errors, error, message } = supplierErrorResp || {};
      toast.error((Array.isArray(errors) && errors[0]) || error || message);
    }
    customerResp.current.prevGetSuppliersListResp = getSuppliersListResp;
    customerResp.current.prevDeleteSupplierResp = deleteSupplierResp;
    customerResp.current.prevSupplierErrorResp = supplierErrorResp;
  }, [getSuppliersListResp, deleteSupplierResp, supplierErrorResp]);
  const getAllSuppliersApiCall = useCallback(
    (page, pageSize, showAllSuppliers, currentSupplierListPage) => {
      let params = {
        page: page ? page + 1 : 1,
        pageSize: pageSize || 30,
        orderBy: "supplierName",
        sort: "asc",
      };
      if (showAllSuppliers) {
        params = {
          page: currentSupplierListPage || 1,
          pageSize: pageSize || 50,
          orderBy: "supplierName",
          sort: "asc",
        };
        dropdownBtnClicked.current = true;
      } else if (search && isNaN(Number(search))) {
        params.supplierName = search;
        dropdownBtnClicked.current = false;
      } else if (search && !isNaN(Number(search))) {
        params.supplierNumber = search;
        dropdownBtnClicked.current = false;
      } else dropdownBtnClicked.current = false;
      dispatch(getSuppliersListAct(generateApiUrl(`get_suppliers_list`), params));
    },
    [search]
  );

  useEffect(() => {
    if (history.location?.state?.showCreateSupplierSuccess) {
      toast.success("Successfully saved the supplier details");
      history.replace();
    } else if (history.location?.state?.showDeleteSupplierSuccess) {
      toast.success("Successfully deleted supplier");
      history.replace();
    }
  }, []);

  useEffect(() => {
    const debounceFn = setTimeout(() => {
      getAllSuppliersApiCall();
    }, 400);
    return () => {
      clearTimeout(debounceFn);
    };
  }, [getAllSuppliersApiCall]);
  const handlePageChange = (page, pageSize) => {
    getAllSuppliersApiCall(page, pageSize);
  };
  const handleDeleteCustomer = () => {
		const idsArray = getSuppliersListResp?.data?.suppliers
    .filter((item, index) => rowSelection[index])
    .map((item) => item.supplierNumber);
		const payload = { ids: idsArray };
		dispatch(deleteSupplierAct(generateApiUrl("delete_supplier"), payload));
  };
  function selectItemCallbck(itemDetails) {
    const { supplierNumber } = itemDetails;
    history.push(`/warehousevendors/${supplierNumber}`);
  }
  const noOfRowsSelected = Object.keys(rowSelection).length;
  return (
    <React.Fragment>
      <ToastContainer />
      <div className="sale-wh-wrapper whbrand-text-color">
        <Container fluid>
          <Row className="px-4 mb-0">
            <Col xs={12}>
              <Row>
                <Col xl="3" md="4" xs="4" className="border-section p-3 py-0">
                  <Label>Search Supplier</Label>
                  <SelectItem
                    searchValue={search}
                    setSearchValue={setSearch}
                    listOptions={searchListOptions}
                    setSearchListOptions={setSearchListOptions}
                    columnsList={supplierColumnsList}
                    placeholder="Select Supplier"
                    selectedItemHandler={selectItemCallbck}
                    fieldKey="supplierName"
                    dataIdKey="supplierNumber"
                    showDropdownBtn={true}
                    dropdownBtnClickHndlr={(setShowOptions) => {
                      getAllSuppliersApiCall(null, null, true);
                      setShowOptions(true);
                    }}
                    callbackAfterBottomReached={(fn) => {
                      getAllSuppliersApiCall(null, null, true, currentSupplierListPage.current);
                      callbackOnSupplierListApiResp.current = fn;
                    }}
                  />
                </Col>
                <Col xl="5" lg="6" xs="8">
                  <div className="d-flex justify-content-center border border-section py-2 px-1 h-100">
                    {actions(history, toggleDeleteModal, toggleSearchModal).map((item) => {
                      return (
                        <div
                          key={item.id}
                          className={`action-icon-container ${
                            item.id === "delete" && noOfRowsSelected === 0
                              ? "show-cursor-not-allowed opacity-50"
                              : "show-cursor-pointer"
                          }`}
                          onClick={item.id === "delete" && noOfRowsSelected === 0 ? () => {} : item.clickHandler}
                        >
                          {item.id === "smart_search" ? item.icon : <img className="action-icon" src={item.icon} />}
                          <span className="text-nowrap">{item.label}</span>
                        </div>
                      );
                    })}
                  </div>
                </Col>
              </Row>
              {history.location?.state?.redirected && (
                <div className="d-flex mt-3">
                  <div
                    className="d-flex align-items-center text-primary fs-5"
                    style={{ cursor: "pointer" }}
                    onClick={history.goBack}
                  >
                    <i className="ri-arrow-left-line me-1"></i>
                    <div className="mb-0 text-decoration-underline">Go Back</div>
                  </div>
                </div>
              )}
            </Col>
          </Row>
          <Row className="m-0">
            <Col className="px-0" xs={12}>
              <WhTableContainer
                columns={columns}
                data={tableData || []}
                className="custom-header-css"
                divClass="table-responsive mt-3 whbrand-table table-container"
                tableClass="align-middle"
                theadClass="table-light"
                handlePageChange={handlePageChange}
                pageCount={pageCount || 0}
                customPageSize={30}
                showNoDataComponent={true}
                showRowSelection={true}
                setRowSelection={setRowSelection}
                loading={(getSuppliersListLoading || !tableData) && !showSearchModal}
              />
            </Col>
          </Row>
        </Container>
      </div>
      <Modal id="showSearchModal" isOpen={showSearchModal} toggle={toggleSearchModal} centered size="lg">
        <ModalHeader className="py-2 px-3" toggle={toggleSearchModal}>
          Smart Search-Vendor
        </ModalHeader>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <ModalBody className="px-0 pt-0">
            <SearchVendor
              modalTableData={modalTableData}
              modalPageCount={modalPageCount || 0}
              getSuppliersListAct={getSuppliersListAct}
              dispatch={dispatch}
              generateApiUrl={generateApiUrl}
              getSuppliersListLoading={getSuppliersListLoading}
            />
          </ModalBody>
        </Form>
      </Modal>
      <DeleteModal show={showDeleteModal} onCloseClick={toggleDeleteModal} onDeleteClick={handleDeleteCustomer} />
      <PaymentsAnywhere />
      <ApiLoader loading={supplierApiLoading} />
    </React.Fragment>
  );
}
export default withPermission(WarehouseVendors, "VENDORS_MENU");
