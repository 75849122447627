import {Link} from "react-router-dom"
import PrintIcon from "../../../assets/images/warehouse/salespage/printer.png";
import GenerateIcon from "../../../assets/images/warehouse/salespage/generate.png";
import ExportIcon from "../../../assets/images/warehouse/salespage/excel.png";
import ResetIcon from "../../../assets/images/warehouse/salespage/reset.png";
//Utility
import { getMmddyyyyDateFormatted } from "../../../utility/dateutility";
import { formatAmount } from "../../../utility/commonutility"

export const supplierColumnsList = [
  {
    id: "supplierNumber",
    name: "Supplier. Num",
    cellClassName: "fw-medium",
  },
  {
    id: "companyName",
    name: "Company",
    cellClassName: "fbadge badge-soft-primary",
  },
  {
    id: "supplierName",
    name: "Supplier Name",
  },
];

export const actions = (togglePrintModal, getSuppliersReportApiCall,resetFilters) => [
  {
    id: "reset",
    label: "Reset",
    icon: ResetIcon,
    clickHandler: resetFilters
  },
  {
    id: "print",
    label: "Print",
    icon: PrintIcon,
    clickHandler:togglePrintModal
  },
  {
    id: "export",
    label: "Export",
    icon: ExportIcon,
    clickHandler: () => getSuppliersReportApiCall(0,10,true)
  },
];

export function generateColumns() {
  return [
    {
      Header: "PO Num",
      accessor: "purchaseOrderNumber",
      Cell: ({ cell: { value } }) => <Link target="_blank" to={`/warehousepurchaseorders/${value}`}>{value}</Link>,
    },
    {
      Header: "Supplier Name",
      accessor: "supplierName",
      Cell: ({
        row: {
          original: { supplierNumber, supplierName },
        },
      }) => <Link target="_blank" to={`/warehousevendors/${supplierNumber}`}>{supplierName}</Link>,
    },
    {
      Header: "Inv Number",
      accessor: "invNumber",
      
    },
    {
      Header: "PO Amount",
      accessor: "poAmount",
    },
    {
      Header: "Balance",
      accessor: "balance",
      Cell: ({cell:{value}}) => value ? formatAmount(value) : ""
    },
    {
      Header: "PO Date",
      accessor: "poDate",
      Cell: ({cell:{value}}) => value ? getMmddyyyyDateFormatted(value) : ""
    },
    {
      Header: "Received Date",
      accessor: "receivedDate",
      Cell: ({cell:{value}}) => value ? getMmddyyyyDateFormatted(value) : ""
    },
  ];
}
export const tableData = [
  {
    id: "101",
    item: "ITG Brands / Altadis USA",
    description: "60916",
    quantity: "$5,011.45",
    caseQuantity: "$5,011.45",
    amount: "05-05-2022",
    received: "05-05-2022",
  },
  {
    id: "101",
    item: "ITG Brands / Altadis USA",
    description: "60916",
    quantity: "$5,011.45",
    caseQuantity: "$5,011.45",
    amount: "05-05-2022",
    received: "05-05-2022",
  },
  {
    id: "101",
    item: "ITG Brands / Altadis USA",
    description: "60916",
    quantity: "$5,011.45",
    caseQuantity: "$5,011.45",
    amount: "05-05-2022",
    received: "05-05-2022",
  },
  {
    id: "101",
    item: "ITG Brands / Altadis USA",
    description: "60916",
    quantity: "$5,011.45",
    caseQuantity: "$5,011.45",
    amount: "05-05-2022",
    received: "05-05-2022",
  },
  {
    id: "101",
    item: "ITG Brands / Altadis USA",
    description: "60916",
    quantity: "$5,011.45",
    caseQuantity: "$5,011.45",
    amount: "05-05-2022",
    received: "05-05-2022",
  },
  {
    id: "101",
    item: "ITG Brands / Altadis USA",
    description: "60916",
    quantity: "$5,011.45",
    caseQuantity: "$5,011.45",
    amount: "05-05-2022",
    received: "05-05-2022",
  },
  {
    id: "101",
    item: "ITG Brands / Altadis USA",
    description: "60916",
    quantity: "$5,011.45",
    caseQuantity: "$5,011.45",
    amount: "05-05-2022",
    received: "05-05-2022",
  },
];
