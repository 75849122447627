import designationconstants from "./designationconstants";

const initialState = {
  designationApiLoading: undefined,
  createDesignationResp: undefined,
  updateDesignationResp: undefined,
  getDesignationsResp: undefined,
  deleteDesignationsResp: undefined,
  designationErrorResp: undefined,
};

export default function designation(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case designationconstants.CHANGE_DESIGNATION_API_LOADING_STATUS:
      return { ...state, designationApiLoading: payload };
    case designationconstants.CREATE_DESIGNATION_SUCCESS:
      return { ...state, createDesignationResp: payload };
    case designationconstants.UPDATE_DESIGNATION_SUCCESS:
      return { ...state, updateDesignationResp: payload };
    case designationconstants.GET_DESIGNATIONS_SUCCESS:
      return (state = { ...state, getDesignationsResp: action.payload });
    case designationconstants.DELETE_DESIGNATIONS_SUCCESS:
      return (state = { ...state, deleteDesignationsResp: action.payload });

    case designationconstants.CREATE_DESIGNATION_FAIL:
    case designationconstants.GET_DESIGNATIONS_FAIL:
    case designationconstants.UPDATE_DESIGNATION_FAIL:
    case designationconstants.DELETE_DESIGNATIONS_FAIL:
      return { ...state, designationErrorResp: payload };
    default:
      return state;
  }
}
