import React, {useMemo, useState, useRef, useEffect, useCallback} from 'react';
import {useSelector, useDispatch, shallowEqual} from 'react-redux';
import {
  Container,
  Row,
  Col,
  Label,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
} from 'reactstrap';
import {
  actions,
  generateColumns,
  tableData,
  inventoryItemColumnsList,
  supplierColumnsList,
} from './helper';
import BackIcon from '../../../../../assets/images/warehouse/menu/back.png';
import WhTableContainer from '../../../../../Components/Common/WhTables';
import SelectItem from '../../../../../Components/Common/SelectItem';
import ApiLoader from '../../../../../Components/Common/ApiLoader';
import PrintItem from './PrintItem';
//Api Helper
import {generateApiUrl} from '../../../../../api/apihelper';
//Actions
import {
  getInventoryAllMasterDataAct,
  getInventoryListAct,
} from '../../../../../store/inventory/inventoryactions';
import {getSuppliersListAct} from '../../../../../store/supplier/supplieractions';
import {
  getInvPricingReportAct,
  getInvPricingReportPrintAct,
} from '../../../../../store/reports/reportsactions';
import F12CloseModalListener from '../../../../../Components/Common/F12close';
import SelectOption from '../../../../../Components/Common/SelectOption';

export const ListBy = ({history, toast}) => {
  const [filters, setFilters] = useState({
    status: '',
    quantityMode: 'all',
    quantityFrom: '',
    quantityTo: '',
    profitMode: 'all',
    profitPercentFrom: '',
    profitPercentTo: '',
    itemNumber: '',
    category: '',
    manufacturer: '',
    subCategory: '',
    groupCode: '',
    family: '',
    supplierNumber: '',
  });
  const [tableData, setTableData] = useState([]);
  const [showPrintModal, setShowPrintModal] = useState(false);
  const [invListSearch, setInvListSearch] = useState('');
  const [invListSrchOptions, setInvListSrchOptions] = useState([]);
  const [suppListSearch, setSuppListSearch] = useState('');
  const [suppListSrchOptions, setSuppListSrchOptions] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [dropdownState, setDropdownState] = useState({
    category: [],
    subCategory: [],
    family: [],
    manufacturer: [],
    model: [],
    priceUser: [],
    groupCode: [],
    msaReportAs: [],
    stateReportAs: [],
    customCategory: '',
    customSubCategory: '',
    customFamily: '',
    customManufacturer: '',
    customModel: '',
    customGroupCode: '',
    modalCustomField: '',
  });

  function togglePrintModal() {
    setShowPrintModal(!showPrintModal);
  }
  const isDownloadBtnClicked = useRef(false);
  const dispatch = useDispatch();

  //Inventory List Api Helpers
  const currentInventoryListPage = useRef(1);
  const invListDropdownBtnClicked = useRef(false);
  const callbackOnInventoryListApiResp = useRef(() => {});

  //Supplier List Api Helpers
  const currentSupplierListPage = useRef(1);
  const suppListDropdownBtnClicked = useRef(false);
  const callbackOnSupplierListApiResp = useRef(() => {});

  const {
    getInvPricingReportResp,
    getInvPricingReportPrintResp,
    getInvPricingReportLoading,
    getSuppliersListResp,
    getInventoryAllMasterDataResp,
    getInventoryListResp,
    inventoryErrorResp,
    supplierErrorResp,
    reportsErrorResp,
  } = useSelector(
    ({reports, supplier, inventory}) => ({
      getInvPricingReportResp: reports.getInvPricingReportResp,
      getInvPricingReportPrintResp: reports.getInvPricingReportPrintResp,
      getInvPricingReportLoading: reports.getInvPricingReportLoading,
      getSuppliersListResp: supplier.getSuppliersListResp,
      getInventoryAllMasterDataResp: inventory.getInventoryAllMasterDataResp,
      getInventoryListResp: inventory.getInventoryListResp,
      inventoryErrorResp: inventory.inventoryErrorResp,
      supplierErrorResp: supplier.supplierErrorResp,
      reportsErrorResp: reports.reportsErrorResp,
    }),
    shallowEqual
  );
  const inventoryResp = useRef({
    prevGetInvPricingReportResp: getInvPricingReportResp,
    prevGetInvPricingReportPrintResp: getInvPricingReportPrintResp,
    prevGetSuppliersListResp: getSuppliersListResp,
    prevGetInventoryAllMasterDataResp: getInventoryAllMasterDataResp,
    prevGetInventoryListResp: getInventoryListResp,
    prevInventoryErrorResp: inventoryErrorResp,
    prevSupplierErrorResp: supplierErrorResp,
    prevReportsErrorResp: reportsErrorResp,
  });

  useEffect(() => {
    const {
      prevGetInvPricingReportResp,
      prevGetInvPricingReportPrintResp,
      prevGetSuppliersListResp,
      prevGetInventoryAllMasterDataResp,
      prevGetInventoryListResp,
      prevInventoryErrorResp,
      prevSupplierErrorResp,
      prevReportsErrorResp,
    } = inventoryResp.current || {};
    if (
      (getInvPricingReportResp &&
      prevGetInvPricingReportResp !== getInvPricingReportResp)
    ) {
      if (isDownloadBtnClicked.current) {

        const url = window.URL.createObjectURL(new Blob([getInvPricingReportResp]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
         "inventory-pricing.csv"
        );
        document.body.appendChild(link);
        link.click();
        link.remove();
          isDownloadBtnClicked.current = false;
        } 
      else {
        setTableData(getInvPricingReportResp?.data?.report || []);
        setPageCount((getInvPricingReportResp?.data?.total))
      }
    } 
    else if (
      getInventoryAllMasterDataResp &&
      prevGetInventoryAllMasterDataResp !== getInventoryAllMasterDataResp
    ) {
      const {
        data: {
          lists: {
            category,
            subCategory,
            family,
            manufacturer,
            model,
            priceUser,
            groupCode,
          },
        },
      } = getInventoryAllMasterDataResp;
      setDropdownState((prev) => ({
        ...prev,
        category: category.map((item) => ({value: item, label: item})),
        subCategory: subCategory.map((item) => ({value: item, label: item})),
        family: family.map((item) => ({value: item, label: item})),
        manufacturer: manufacturer.map((item) => ({value: item, label: item})),
        model: model.map((item) => ({value: item, label: item})),
        priceUser: priceUser.map((item) => ({value: item, label: item})),
        groupCode: groupCode.map((item) => ({value: item, label: item})),
      }));
    } else if (
      getSuppliersListResp &&
      prevGetSuppliersListResp !== getSuppliersListResp
    ) {
      const list = [];
      (getSuppliersListResp?.data?.suppliers || []).forEach(
        ({supplierNumber, firstName, lastName, supplierName}) => {
          list.push({
            supplierNumber,
            supplierName: `${firstName || ''} ${lastName || ''}`,
            companyName: supplierName,
          });
        }
      );
      if (!suppListDropdownBtnClicked.current) setSuppListSrchOptions(list);
      else {
        setSuppListSrchOptions((prev) => [...prev, ...list]);
        if (getSuppliersListResp?.data?.suppliers?.length > 0)
          callbackOnSupplierListApiResp.current();
        currentSupplierListPage.current =
          Number(getSuppliersListResp?.data?.page) + 1;
      }
    } else if (
      getInventoryListResp &&
      prevGetInventoryListResp !== getInventoryListResp
    ) {
      const list = [];
      (getInventoryListResp?.data?.inventories || []).forEach(
        ({itemNumber, description, category}) => {
          list.push({
            itemNumber,
            itemName: description,
            category,
          });
        }
      );
      if (!invListDropdownBtnClicked.current) setInvListSrchOptions(list);
      else {
        setInvListSrchOptions((prev) => [...prev, ...list]);
        if (getInventoryListResp?.data?.inventories?.length > 0)
          callbackOnInventoryListApiResp.current();
        currentInventoryListPage.current =
          Number(getInventoryListResp?.data?.page) + 1;
      }
    } else if (
      inventoryErrorResp &&
      prevInventoryErrorResp !== inventoryErrorResp
    ) {
      const {errors, error, message} = inventoryErrorResp || {};
      toast.error(
        (Array.isArray(errors) && errors[0] && errors[0].msg) ||
          error ||
          message
      );
    } else if (
      supplierErrorResp &&
      prevSupplierErrorResp !== supplierErrorResp
    ) {
      const {errors, error, message} = supplierErrorResp || {};
      toast.error(
        (Array.isArray(errors) && errors[0] && errors[0].msg) ||
          error ||
          message
      );
    } else if (reportsErrorResp && prevReportsErrorResp !== reportsErrorResp) {
      const {errors, error, message} = reportsErrorResp || {};
      toast.error(
        (Array.isArray(errors) && errors[0] && errors[0].msg) ||
          error ||
          message
      );
    }
    else {
      setTableData(getInvPricingReportResp?.data?.report || []);
    }
    inventoryResp.current.prevGetInvPricingReportResp = getInvPricingReportResp;
    inventoryResp.current.prevGetInvPricingReportPrintResp = getInvPricingReportPrintResp;
    inventoryResp.current.prevGetSuppliersListResp = getSuppliersListResp;
    inventoryResp.current.prevGetInventoryAllMasterDataResp =
      getInventoryAllMasterDataResp;
    inventoryResp.current.prevGetInventoryListResp = getInventoryListResp;
    inventoryResp.current.prevInventoryErrorResp = inventoryErrorResp;
    inventoryResp.current.prevSupplierErrorResp = supplierErrorResp;
    inventoryResp.current.prevReportsErrorResp = reportsErrorResp;
  }, [
    getInvPricingReportResp,
    getInvPricingReportPrintResp,
    getSuppliersListResp,
    getInventoryAllMasterDataResp,
    getInventoryListResp,
    inventoryErrorResp,
    supplierErrorResp,
    reportsErrorResp,
  ]);

  const getInventoryListApiCall = useCallback(
    (showAllSuppliers, page) => {
      if (showAllSuppliers) {
        let params = {
          page: page || 1,
          pageSize: 50,
        };
        dispatch(
          getInventoryListAct(generateApiUrl(`get_inventory_list`), params)
        );
        invListDropdownBtnClicked.current = true;
      } else if (invListSearch) {
        let params = {
          page: 1,
          pageSize: 5,
        };
        if (isNaN(Number(invListSearch))) params.description = invListSearch;
        else params.itemNumber = invListSearch;
        dispatch(
          getInventoryListAct(generateApiUrl(`get_inventory_list`), params)
        );
        invListDropdownBtnClicked.current = false;
      }
    },
    [invListSearch]
  );
  useEffect(() => {
    const debounceFn = setTimeout(() => {
      getInventoryListApiCall();
    }, 200);
    return () => {
      clearTimeout(debounceFn);
    };
  }, [getInventoryListApiCall]);

  const getSuppliersListApiCall = useCallback(
    (showAllCustomers, page) => {
      if (showAllCustomers) {
        let params = {
          page: page || 1,
          pageSize: 50,
          orderBy: 'supplierName',
          sort: 'asc',
          active: true,
        };
        dispatch(
          getSuppliersListAct(generateApiUrl(`get_suppliers_list`), params)
        );
        suppListDropdownBtnClicked.current = true;
      } else if (suppListSearch) {
        let params = {
          page: 1,
          pageSize: 5,
          orderBy: 'supplierName',
          sort: 'asc',
          active: true,
        };
        if (suppListSearch && isNaN(Number(suppListSearch)))
          params.supplierName = suppListSearch;
        else params.supplierNumber = suppListSearch;
        dispatch(
          getSuppliersListAct(generateApiUrl(`get_suppliers_list`), params)
        );
        suppListDropdownBtnClicked.current = false;
      }
    },
    [suppListSearch]
  );

  useEffect(() => {
    const debounceFn = setTimeout(() => {
      getSuppliersListApiCall();
    }, 200);
    return () => {
      clearTimeout(debounceFn);
    };
  }, [getSuppliersListApiCall]);

  useEffect(() => {
    dispatch(
      getInventoryAllMasterDataAct(
        generateApiUrl(`get_inventory_all_master_data`)
      )
    );
  }, []);

  const getInvPricingReportApiCall = useCallback(
    (page, pageSize, download, downloadType = 'pdf',columns) => {
      const {
        status,
        quantityMode,
        quantityFrom,
        quantityTo,
        profitMode,
        profitPercentFrom,
        profitPercentTo,
        itemNumber,
        category,
        manufacturer,
        subCategory,
        groupCode,
        family,
        supplierNumber,
        isOutOfState,
        isLocalTaxesPaid,
        isReportToState,
        dateType,
      } = filters || {};
      let params = {
        isOutOfState,
        isLocalTaxesPaid,
        isReportToState,
        dateType,
      };
      if (!download) {
        params.page = page ? page + 1 : 1;
        params.pageSize = pageSize || 30;
        params.columns = [
          'quantityInStock',
          'cost',
          'retail',
          'priceA',
          'priceB',
          'priceC',
          'priceD',
        ];
      }
      if (status) params.status = status;
      if (quantityMode === 'between') {
        if (quantityFrom) params.quantityFrom = quantityFrom;
        if (quantityTo) params.quantityTo = quantityTo;
      }
      if (profitMode === 'between') {
        if (profitPercentFrom) params.profitPercentFrom = profitPercentFrom;
        if (profitPercentTo) params.profitPercentTo = profitPercentTo;
      }
      if (itemNumber) params.itemNumber = itemNumber;
      if (category) params.category = category.value;
      if (manufacturer) params.manufacturer = manufacturer.value;
      if (subCategory) params.subCategory = subCategory.value;
      if (groupCode) params.groupCode = groupCode.value;
      if (family) params.family = family.value;
      if (supplierNumber) params.supplierNumber = supplierNumber;
      if (download && downloadType === 'html') {
        params.download = true;
        params.downloadType = downloadType;
        params.columns = columns.map((value) => `${value}`);
        // isDownloadBtnClicked.current = true;
        dispatch(
          getInvPricingReportPrintAct(
            generateApiUrl(`get_inv_pricing_report`),
            params
          )
        );
      } else if (download){
        params.download = true;
        // params.downloadType = downloadType;
        params.columns = [
          'quantityInStock',
          'cost',
          'retail',
          'priceA',
          'priceB',
          'priceC',
          'priceD',
        ];
        // params.columns = columns.map((value) => `${value}`);
        isDownloadBtnClicked.current = true; 
        dispatch(
          getInvPricingReportAct(
            generateApiUrl(`get_inv_pricing_report`),
            params,
            {responseType: 'blob'}
          )
        );}
      else {
        dispatch(
          getInvPricingReportAct(
            generateApiUrl(`get_inv_pricing_report`),
            params
          )
        );
      }
    },
    [filters]
  );

  useEffect(() => {
    const debounceFn = setTimeout(() => {
      getInvPricingReportApiCall();
    }, 400);
    return () => {
      clearTimeout(debounceFn);
    };
  }, [getInvPricingReportApiCall]);

  const handlePageChange = (page, pageSize) => {
    getInvPricingReportApiCall(page, pageSize);
  };

  function selectInvListItemCallbck(itemDetails) {
    const {itemNumber, itemName} = itemDetails;
    setFilters((prev) => ({...prev, itemNumber}));
    setInvListSearch(itemName);
  }

  function selectSuppListItemCallbck(itemDetails) {
    const {supplierNumber, companyName} = itemDetails;
    setFilters((prev) => ({...prev, supplierNumber}));
    setSuppListSearch(companyName);
  }

  const statusChngHndlr = (name) => {
    setFilters((prev) => ({...prev, status: name === 'all' ? '' : name}));
  };

  const qtyChngHndlr = (name) => {
    if (name !== filters.quantityMode) {
      setFilters((prev) => ({
        ...prev,
        quantityMode: name,
        quantityFrom: '',
        quantityTo: '',
      }));
    }
  };

  const profitChngHndlr = (name) => {
    if (name !== filters.profitMode) {
      setFilters((prev) => ({
        ...prev,
        profitMode: name,
        profitPercentFrom: '',
        profitPercentFrom: '',
      }));
    }
  };

  const filtersChngHndlr = (e) => {
    const {name, value} = e.target;
    setFilters((prev) => ({...prev, [name]: value}));
  };

  const filtersDropdownChngHndlr = (selected, name) => {
    setFilters((prev) => ({...prev, [name]: selected}));
  };

  const resetFilters = () => {
    setFilters({
      status: '',
      quantityMode: 'all',
      quantityFrom: '',
      quantityTo: '',
      profitMode: 'all',
      profitPercentFrom: '',
      profitPercentTo: '',
      itemNumber: '',
      category: '',
      manufacturer: '',
      subCategory: '',
      groupCode: '',
      family: '',
      supplierNumber: '',
    });
    setSuppListSearch('');
    setInvListSearch('');
  };

  const columns = useMemo(() => generateColumns(), []);
  return (
    <>
      <Container fluid>
        <Row>
          <Col xs="12" className="mb-0">
            <div className="border ps-4 my-2" style={{position: 'relative'}}>
              <Label
                className="text-dark fs-5"
                style={{position: 'absolute', transform: 'translateY(-50%)'}}
              >
              <span className=" px-1 wh-item-bg"> Filters</span>
              </Label>
              <Row>
                <Col xs="9" className="border-end pe-5">
                  <Row className="my-3">
                    <Col xs={5}>
                      <div className="d-flex align-items-center justify-content-around border py-1 flex-grow-1 mb-2">
                        <div className="d-flex align-items-center">
                          <Input
                            name="active"
                            type="radio"
                            checked={filters.status === 'active'}
                            className="mb-1"
                            onChange={() => statusChngHndlr('active')}
                          />
                          <Label className="ms-1 mb-0">Active Items</Label>
                        </div>
                        <div className="d-flex align-items-center">
                          <Input
                            type="radio"
                            checked={filters.status === 'inactive'}
                            className="mb-1"
                            onChange={() => statusChngHndlr('inactive')}
                          />
                          <Label className="ms-1 mb-0">Non Active</Label>
                        </div>
                        <div className="d-flex align-items-center">
                          <Input
                            type="radio"
                            className="mb-1"
                            checked={filters.status === ''}
                            onChange={() => statusChngHndlr('all')}
                          />
                          <Label className="ms-1 mb-0">All Items</Label>
                        </div>
                      </div>
                      <div className="d-flex align-items-center justify-content-between border p-2 py-1 mb-2">
                        <Label className="mb-0">Quantity</Label>
                        <div className="d-flex align-items-center">
                          <Input
                            className="mb-1 me-1"
                            type="radio"
                            checked={filters.quantityMode === 'all'}
                            onChange={() => qtyChngHndlr('all')}
                          />
                          <Label className="mb-0">All</Label>
                        </div>
                        <div className="d-flex align-items-center">
                          <Input
                            className="mb-1 me-1"
                            type="radio"
                            checked={filters.quantityMode === 'between'}
                            onChange={() => qtyChngHndlr('between')}
                          />
                          <Label className="mb-0">Between</Label>
                        </div>
                        <div className="d-flex">
                          <Input
                            type="text"
                            style={{width: '60px'}}
                            className="me-1"
                            value={filters.quantityFrom}
                            name="quantityFrom"
                            onChange={filtersChngHndlr}
                          />
                          <Input
                            type="text"
                            style={{width: '60px'}}
                            value={filters.quantityTo}
                            name="quantityTo"
                            onChange={filtersChngHndlr}
                          />
                        </div>
                      </div>
                      <div className="d-flex align-items-center justify-content-between border p-2 py-1 mb-2">
                        <Label className="mb-0">Profit%</Label>
                        <div className="d-flex align-items-center">
                          <Input
                            className="mb-1 me-1"
                            type="radio"
                            checked={filters.profitMode === 'all'}
                            onChange={() => profitChngHndlr('all')}
                          />
                          <Label className="mb-0">All</Label>
                        </div>
                        <div className="d-flex align-items-center">
                          <Input
                            className="mb-1 me-1"
                            type="radio"
                            checked={filters.profitMode === 'between'}
                            onChange={() => profitChngHndlr('between')}
                          />
                          <Label className="mb-0">Between</Label>
                        </div>
                        <div className="d-flex">
                          <Input
                            type="text"
                            style={{width: '60px'}}
                            className="me-1"
                            value={filters.profitPercentFrom}
                            name="profitPercentFrom"
                            onChange={filtersChngHndlr}
                          />
                          <Input
                            type="text"
                            style={{width: '60px'}}
                            value={filters.profitPercentTo}
                            name="profitPercentTo"
                            onChange={filtersChngHndlr}
                          />
                        </div>
                      </div>
                    </Col>
                    <Col xs={7}>
                      <Row>
                        <Col xs={6} className="mb-1">
                          <Label
                            htmlFor="companyName"
                            className="form-label mb-0 text-nowrap"
                          >
                            Item No/Description:
                          </Label>
                          <SelectItem
                            searchValue={invListSearch}
                            setSearchValue={setInvListSearch}
                            listOptions={invListSrchOptions}
                            setSearchListOptions={setInvListSrchOptions}
                            columnsList={inventoryItemColumnsList}
                            placeholder="Select Item"
                            selectedItemHandler={selectInvListItemCallbck}
                            fieldKey="itemName"
                            dataIdKey="itemNumber"
                            showDropdownBtn={true}
                            addF3Shortcut={false}
                            dropdownBtnClickHndlr={(setShowOptions) => {
                              getInventoryListApiCall(true);
                              setShowOptions(true);
                            }}
                            callbackAfterBottomReached={(fn) => {
                              getInventoryListApiCall(
                                true,
                                currentInventoryListPage.current
                              );
                              callbackOnInventoryListApiResp.current = fn;
                            }}
                          />
                        </Col>
                        <Col xs={6} className="mb-1">
                          <Label
                            htmlFor="category"
                            className="form-label mb-0 text-nowrap"
                          >
                            Category:
                          </Label>
                          <SelectOption
                            id="category"
                            options={dropdownState.category}
                            categoryValue={filters.category}
                            filtersDropdownChngHndlr={filtersDropdownChngHndlr}
                           
                          />
                        </Col>
                        <Col xs={6} className="mb-1">
                          <Label
                            htmlFor="manufacturer"
                            className="form-label mb-0 text-nowrap"
                          >
                            Manufacturer:
                          </Label>
                          <SelectOption
                            id="manufacturer"
                            options={dropdownState.manufacturer}
                            categoryValue={filters.manufacturer}
                            filtersDropdownChngHndlr={filtersDropdownChngHndlr}
                          />
                        </Col>
                        <Col xs={6} className="mb-1">
                          <Label
                            htmlFor="subCategory"
                            className="form-label mb-0 text-nowrap"
                          >
                            Sub Category:
                          </Label>
                          <SelectOption
                            // ref={selectRef}
                            id="subCategory"
                            options={dropdownState.subCategory}
                            categoryValue={filters.subCategory}
                            filtersDropdownChngHndlr={filtersDropdownChngHndlr}
                            
                          />
                        </Col>
                        <Col xs={6} className="mb-1">
                          <Label
                            htmlFor="groupCode"
                            className="form-label mb-0 text-nowrap"
                          >
                            Group Code:
                          </Label>
                          <SelectOption
                            id="groupCode"
                            options={dropdownState.groupCode}
                            categoryValue={filters.groupCode}
                            filtersDropdownChngHndlr={filtersDropdownChngHndlr}
                          
                          />
                        </Col>
                        <Col xs={6} className="mb-1">
                          <Label
                            htmlFor="family"
                            className="form-label mb-0 text-nowrap"
                          >
                            Family:
                          </Label>
                          <SelectOption
                            id="family"
                            options={dropdownState.family}
                            categoryValue={filters.family}
                            filtersDropdownChngHndlr={filtersDropdownChngHndlr}
                          
                          />
                        </Col>
                        <Col xs={6} className="mb-1">
                          <Label
                            htmlFor="companyName"
                            className="form-label mb-0 text-nowrap"
                          >
                            Supplier:
                          </Label>
                          <SelectItem
                            searchValue={suppListSearch}
                            setSearchValue={setSuppListSearch}
                            listOptions={suppListSrchOptions}
                            setSearchListOptions={setSuppListSrchOptions}
                            columnsList={supplierColumnsList}
                            placeholder="Select Supplier"
                            selectedItemHandler={selectSuppListItemCallbck}
                            fieldKey="supplierName"
                            dataIdKey="supplierNumber"
                            showDropdownBtn={true}
                            addF3Shortcut={false}
                            dropdownBtnClickHndlr={(setShowOptions) => {
                              getSuppliersListApiCall(true);
                              setShowOptions(true);
                            }}
                            callbackAfterBottomReached={(fn) => {
                              getSuppliersListApiCall(
                                true,
                                currentSupplierListPage.current
                              );
                              callbackOnSupplierListApiResp.current = fn;
                            }}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
                <Col xs="3">
                  <div className="d-flex align-items-center pe-xxl-5 pe-4 pt-3">
                    <div className="sale-wh-wrapper flex-grow-1 mt-0 me-xxl-4 me-2">
                      <div className="d-flex justify-content-around border border-section py-1 h-100">
                        {actions(
                          togglePrintModal,
                          getInvPricingReportApiCall,
                          resetFilters
                        ).map((item) => {
                          return (
                            <div
                              key={item.id}
                              className="action-icon-container"
                              style={{cursor:"pointer"}}
                              onClick={item.clickHandler || (() => {})}
                            >
                              <img className="action-icon" src={item.icon} />
                              <span>{item.label}</span>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                    <div
                      className="d-flex flex-column align-items-center"
                      style={{cursor: 'pointer'}}
                      onClick={() => history.push('/reportsmenu')}
                    >
                      <img
                        src={BackIcon}
                        width="32"
                        height="32"
                        alt="back-icon"
                      />
                      <div className="text-decoration-underline mb-0">
                        Go Back
                      </div>
                    </div>
                  </div>

                  <div>
                    <div className="form-check my-1">
                      <Input
                        className="form-check-input"
                        type="checkbox"
                        id="formCheck6"
                      />
                      <Label
                        className="form-check-label text-nowrap"
                        htmlFor="formCheck6"
                      >
                        Sale or Discount
                      </Label>
                    </div>
                    <div className="form-check mb-1">
                      <Input
                        className="form-check-input"
                        type="checkbox"
                        id="formCheck6"
                      />
                      <Label
                        className="form-check-label text-nowrap"
                        htmlFor="formCheck6"
                      >
                        Promotion Items
                      </Label>
                    </div>
                    <div className="form-check mb-1">
                      <Input
                        className="form-check-input"
                        type="checkbox"
                        id="formCheck6"
                      />
                      <Label
                        className="form-check-label text-nowrap"
                        htmlFor="formCheck6"
                      >
                        Best Deals
                      </Label>
                    </div>
                    <div className="form-check mb-1">
                      <Input
                        className="form-check-input"
                        type="checkbox"
                        id="formCheck6"
                      />
                      <Label
                        className="form-check-label text-nowrap"
                        htmlFor="formCheck6"
                      >
                        Items To Orders
                      </Label>
                    </div>
                    <div className="form-check mb-1">
                      <Input
                        className="form-check-input"
                        type="checkbox"
                        id="formCheck6"
                      />
                      <Label
                        className="form-check-label text-nowrap"
                        htmlFor="formCheck6"
                      >
                        View Profit %
                      </Label>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
          <Col xs="12">
            <WhTableContainer
              columns={columns}
              data={tableData || []}
              className="custom-header-css"
              divClass="table-responsive table-container-inventroypricingreport"
              tableClass="align-middle table-bordered"
              theadClass="table-light"
              handlePageChange={handlePageChange}
              pageCount={pageCount || 0}
              customPageSize={30}
              showNoDataComponent={true}
            />
          </Col>
        </Row>
      </Container>
      <Modal
        id="showPrintModal"
        isOpen={showPrintModal}
        toggle={togglePrintModal}
        centered
        size="lg"
      >
        <F12CloseModalListener onClose={togglePrintModal} />
        <ModalHeader className="py-2 px-3" toggle={togglePrintModal}>
          Print Options
        </ModalHeader>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <ModalBody className="px-0 pt-0">
            <PrintItem
              getInvPricingReportApiCall={getInvPricingReportApiCall}
              filters={filters}
              showPrintModal={showPrintModal}
            />
          </ModalBody>
        </Form>
      </Modal>
      <ApiLoader loading={getInvPricingReportLoading} />
    </>
  );
};
