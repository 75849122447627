import React, { useMemo } from "react";
import { Container, Row, Col, Label, Input, Pagination, PaginationItem, PaginationLink, } from "reactstrap";
import { actions, generateColumns, tableData } from "./helper";
import BackIcon from "../../../../assets/images/warehouse/menu/back.png";
import WhTableContainer from "../../../../Components/Common/WhTables";

const Sales = ({ history }) => {
  const columns = useMemo(() => generateColumns(), []);

  const handleMainFilterChange = (e) => {
    const { id } = e.target;
    switch (id) {
      case "purchases":
        return history.push("/cashpaidout/purchases");
      default:
        return null;
    }
  };
  return (
    <Container fluid className=" pt-3 pb-5">
      <Row>
        <Col xs="12" className="mb-3">
          <div className="border ps-4 my-3" style={{ position: "relative" }}>
            <Label className="text-dark fs-5" style={{ position: "absolute", transform: "translateY(-50%)" }}>
              <span className=" px-1 wh-item-bg"> Filters</span>
            </Label>
            <Row>
              <Col xs="9" className="pe-5">
                <Row className="my-3">
                  <Col xs={2}>
                    <div className="form-check my-3">
                      <Input className="form-check-input" type="checkbox" id="purchases"  onChange={handleMainFilterChange} />
                      <Label className="form-check-label text-nowrap" htmlFor="purchases">
                        Purchases
                      </Label>
                    </div>

                    <div className="form-check my-3">
                      <Input
                        className="form-check-input"
                        type="checkbox"
                        id="sales"
                        checked={true} 
                      />
                      <Label className="form-check-label text-nowrap" htmlFor="sales">
                        Sales
                      </Label>
                    </div>

                    <div className="form-check my-3">
                      <Input className="form-check-input" type="checkbox" id="compare" disabled />
                      <Label className="form-check-label text-nowrap" htmlFor="compare">
                        Compare
                      </Label>
                    </div>
                  </Col>
                  <Col xs={10}>
                    <Row className="my-3">
                      <Col xs={4}>
                        <Label htmlFor="companyName" className="form-label mb-0 text-nowrap">
                          Date From:
                        </Label>
                        <Input className="form-control" type="date" />
                      </Col>
                      <Col xs={4}>
                        <Label htmlFor="companyName" className="form-label mb-0 text-nowrap">
                          Item Num:
                        </Label>
                        <select className="form-select">
                          <option>Game 2/99 c Blue</option>
                        </select>
                      </Col>
                      <Col xs={4}>
                        <Label htmlFor="companyName" className="form-label mb-0 text-nowrap">
                          Manufacturer:
                        </Label>
                        <Input className="form-control" type="text" />
                      </Col>
                    </Row>

                    <Row className="mb-3">
                      <Col xs={4}>
                        <Label htmlFor="companyName" className="form-label mb-0 text-nowrap">
                          Date To:
                        </Label>
                        <Input className="form-control" type="date" />
                      </Col>
                      <Col xs={4}>
                        <Label htmlFor="companyName" className="form-label mb-0 text-nowrap">
                          Description:
                        </Label>
                        <select className="form-select">
                          <option>Game 2/99 c Blue</option>
                        </select>
                      </Col>
                      <Col xs={4}>
                        <Label htmlFor="companyName" className="form-label mb-0 text-nowrap">
                          Category:
                        </Label>
                        <Input className="form-control" type="text" />
                      </Col>
                    </Row>

                    <Row className="mb-3">
                      <Col xs={4}>
                        <Label htmlFor="companyName" className="form-label mb-0 text-nowrap">
                          Customer:
                        </Label>
                        <select className="form-select">
                          <option>Select</option>
                        </select>
                      </Col>
                      <Col xs={4}>
                        <Label htmlFor="companyName" className="form-label mb-0 text-nowrap">
                          Family:
                        </Label>
                        <select className="form-select">
                          <option>Select</option>
                        </select>
                      </Col>
                      <Col xs={4}>
                        <Label htmlFor="companyName" className="form-label mb-0 text-nowrap">
                          Sub-Category:
                        </Label>
                        <Input className="form-control" type="text" />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
              <Col xs="3" className="d-flex flex-column justify-content-center pe-xxl-5 pe-4">
                <div className="d-flex align-items-center w-100 mb-3">
                  <div className="sale-wh-wrapper flex-grow-1 mt-0 me-xxl-4 me-2">
                    <div className="d-flex justify-content-around border border-section py-2 h-100">
                      {actions().map((item) => {
                        return (
                          <div
                            key={item.id}
                            className="action-icon-container"
                            onClick={item.clickHandler || (() => {})}
                          >
                            <img className="action-icon" src={item.icon} />
                            <span>{item.label}</span>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <div
                    className="d-flex flex-column align-items-center"
                    style={{ cursor: "pointer" }}
                    onClick={() => history.push("/reportsmenu")}
                  >
                    <img src={BackIcon} width="32" height="32" alt="back-icon" />
                    <div className="text-decoration-underline mb-0">Go Back</div>
                  </div>
                </div>
                <div className="d-flex justify-content-start">
                  <div className="form-check me-3">
                    <Input className="form-check-input" type="checkbox" id="purchases" checked={true} />
                    <Label className="form-check-label text-nowrap" htmlFor="purchases">
                      By PO
                    </Label>
                  </div>
                  <div className="form-check me-3">
                    <Input className="form-check-input" type="checkbox" id="purchases" checked={true} />
                    <Label className="form-check-label text-nowrap" htmlFor="purchases">
                      By Category
                    </Label>
                  </div>
                  <div className="form-check">
                    <Input className="form-check-input" type="checkbox" id="purchases" checked={true} />
                    <Label className="form-check-label text-nowrap" htmlFor="purchases">
                      By Supplier
                    </Label>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
        <Col xs="12" className="d-flex justify-content-end">
          <Pagination className="pagination-separated">
            <PaginationItem disabled>
              {" "}
              <PaginationLink to="#"> ← </PaginationLink>{" "}
            </PaginationItem>
            <PaginationItem active>
              {" "}
              <PaginationLink to="#"> 1 </PaginationLink>{" "}
            </PaginationItem>
            <PaginationItem>
              {" "}
              <PaginationLink to="#"> 2 </PaginationLink>{" "}
            </PaginationItem>
            <PaginationItem>
              {" "}
              <PaginationLink to="#"> → </PaginationLink>{" "}
            </PaginationItem>
          </Pagination>
        </Col>
        <Col xs="12">
          <WhTableContainer
            columns={columns}
            data={tableData || []}
            customPageSize={7}
            className="custom-header-css"
            divClass="table-responsive"
            tableClass="align-middle table-bordered"
            theadClass="table-light"
          />
        </Col>
      </Row>
    </Container>
  );
};

export default Sales;
