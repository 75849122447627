import DeleteIcon from "../../../../../assets/images/warehouse/salespage/delete.png";
import NewIcon from "../../../../../assets/images/warehouse/salespage/new.png";

export const actions = (toggleAddRoleModal) => [
  {
    id: "delete",
    label: "Delete",
    icon: DeleteIcon,
  },
  {
    id: "new",
    label: "New",
    icon: NewIcon,
    clickHandler: () => {
      toggleAddRoleModal();
    },
  },
];

export function generateColumns(toggleAddRoleModal, setEditModalData) {
  return [
    {
      Header: "Role Name",
      accessor: "name",
    },
    {
      Header: <div className="text-center">Status</div>,
      accessor: "isActive",
      style: { width: "180px", textAlign: "center" },
      Cell: ({cell:{value}}) => (
        <span className={`badge badge-soft-${value ? "success" : "danger"}`} style={{ fontSize: "13px" }}>
          {value ? "Active" : "Inactive"}
        </span>
      ),
    },
    {
      Header: <div className="text-center">Actions</div>,
      accessor: "actions",
      style: { width: "180px", textAlign: "center" },
      Cell: ({ row }) => (
        <div className="hstack gap-3 flex-wrap justify-content-center">
          <span
            className="wh-link-color-green fs-15 show-cursor-pointer"
            onClick={() => {
              toggleAddRoleModal();
              setEditModalData(row.original);
            }}
          >
            <i className="ri-edit-2-line"></i>
          </span>
          <span className="link-danger fs-15 show-cursor-pointer">
            <i className="ri-delete-bin-line"></i>
          </span>
        </div>
      ),
    },
  ];
}
