import customerconstants from "./customerconstants";

export const getAllCustomersAct = (apiEndpoint, payload, optionalConfig) => {
  return {
      type:customerconstants.GET_ALL_CUSTOMERS_REQUEST,
      apiEndpoint,
      payload,
      optionalConfig
  }
};

export const changeGetAllCustomersLoadingStatusAct = (status) => {
    return {
        type: customerconstants.CHANGE_GET_ALL_CUSTOMERS_LOADING_STATUS,
        payload:status,
    }
};

export const getCustomerAct = (apiEndpoint, payload, optionalConfig) => {
    return {
        type:customerconstants.GET_CUSTOMER_REQUEST,
        apiEndpoint,
        payload,
        optionalConfig
    }
  };

export const createCustomerAct = (apiEndpoint, payload, optionalConfig) => {
    return {
        type:customerconstants.CREATE_CUSTOMER_REQUEST,
        apiEndpoint,
        payload,
        optionalConfig
    }
};

export const getMaxCustomerNoAct = (apiEndpoint, payload, optionalConfig) => {
    return {
        type:customerconstants.GET_MAX_CUSTOMER_NO_REQUEST,
        apiEndpoint,
        payload,
        optionalConfig
    }
};

export const getMasterDataAllAct = (apiEndpoint, payload, optionalConfig) => {
    return {
        type:customerconstants.GET_MASTER_DATA_ALL_REQUEST,
        apiEndpoint,
        payload,
        optionalConfig
    }
};

export const getMasterDataByCategoryAct = (apiEndpoint, payload, optionalConfig) => {
    return {
        type:customerconstants.GET_MASTER_DATA_BY_CATEGORY_REQUEST,
        apiEndpoint,
        payload,
        optionalConfig
    }
};

export const updateBasicInfoAct = (apiEndpoint, payload, optionalConfig) => {
    return {
        type:customerconstants.UPDATE_BASIC_INFO_REQUEST,
        apiEndpoint,
        payload,
        optionalConfig
    }
};

export const updateClassificationAct = (apiEndpoint, payload, optionalConfig) => {
    return {
        type:customerconstants.UPDATE_CLASSIFICATION_REQUEST,
        apiEndpoint,
        payload,
        optionalConfig
    }
};

export const updateBillingInfoAct = (apiEndpoint, payload, optionalConfig) => {
    return {
        type:customerconstants.UPDATE_BILLING_INFO_REQUEST,
        apiEndpoint,
        payload,
        optionalConfig
    }
};

export const getPaymentsOfCustomerAct = (apiEndpoint, payload, optionalConfig) => {
    return {
        type:customerconstants.GET_PAYMENTS_OF_CUSTOMER_REQUEST,
        apiEndpoint,
        payload,
        optionalConfig
    }
};

export const getInvoicesOfCustomerAct = (apiEndpoint, payload, optionalConfig) => {
    return {
        type:customerconstants.GET_INVOICES_OF_CUSTOMER_REQUEST,
        apiEndpoint,
        payload,
        optionalConfig
    }
};

export const changeGetInvoicesLoadingStatusAct = (status) => {
    return {
        type: customerconstants.CHANGE_GET_INVOICES_LOADING_STATUS,
        payload:status,
    }
};

export const deleteCustomerAct = (apiEndpoint, payload, optionalConfig) => {
    return {
        type:customerconstants.DELETE_CUSTOMER_REQUEST,
        apiEndpoint,
        payload,
        optionalConfig
    }
};

export const getCustomerAccountBalanceHistoryAct = (apiEndpoint, payload, optionalConfig) => {
    return {
        type:customerconstants.GET_CUSTOMER_ACCOUNT_BALANCE_HISTORY_REQUEST,
        apiEndpoint,
        payload,
        optionalConfig
    }
};

export const createSpecialPriceForCustomerAct = (apiEndpoint, payload, optionalConfig) => {
    return {
        type:customerconstants.CREATE_SPECIAL_PRICE_FOR_CUSTOMER_REQUEST,
        apiEndpoint,
        payload,
        optionalConfig
    }
};

export const deleteSpecialPriceForCustomerAct = (apiEndpoint, payload, optionalConfig) => {
    return {
        type:customerconstants.DELETE_SPECIAL_PRICE_FOR_CUSTOMER_REQUEST,
        apiEndpoint,
        payload,
        optionalConfig
    }
};

export const acceptPaymentsAnywhereAct = (apiEndpoint, payload, optionalConfig) => {
    return {
        type:customerconstants.ACCEPT_PAYMENTS_ANYWHERE_REQUEST,
        apiEndpoint,
        payload,
        optionalConfig
    }
};

export const changeCustomerApiLoadingStateAct = (status) => {
    return {
      type: customerconstants.CHANGE_CUSTOMER_API_LOADING_STATUS,
      payload: status,
    };
};

export const getCustomerInventoryDiscountsAct = (apiEndpoint, payload, optionalConfig) => {
    return {
        type:customerconstants.GET_CUSTOMER_INVENTORY_DISCOUNTS_REQUEST,
        apiEndpoint,
        payload,
        optionalConfig
    }
};