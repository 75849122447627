import masterdataconstants from "./masterdataconstants";

export const getDriversListAct = (apiEndpoint, payload, optionalConfig) => {
  return {
    type: masterdataconstants.GET_DRIVERS_LIST_REQUEST,
    apiEndpoint,
    payload,
    optionalConfig,
  };
};

export const getSalesmanListAct = (apiEndpoint, payload, optionalConfig) => {
    return {
      type: masterdataconstants.GET_SALESMAN_LIST_REQUEST,
      apiEndpoint,
      payload,
      optionalConfig,
    };
};

export const getMobileProvidersAct = (apiEndpoint, payload, optionalConfig) => {
    return {
        type:masterdataconstants.GET_MOBILE_PROVIDERS_REQUEST,
        apiEndpoint,
        payload,
        optionalConfig
    }
};

export const getStatesListAct = (apiEndpoint, payload, optionalConfig) => {
    return {
        type:masterdataconstants.GET_STATES_LIST_REQUEST,
        apiEndpoint,
        payload,
        optionalConfig
    }
};

export const getZipcodeAct = (apiEndpoint, payload, optionalConfig) => {
  return {
      type:masterdataconstants.GET_ZIPCODE_REQUEST,
      apiEndpoint,
      payload,
      optionalConfig
  }
};

export const getMsaReportAsAct = (apiEndpoint, payload, optionalConfig) => {
  return {
    type: masterdataconstants.GET_MSA_REPORT_AS_REQUEST,
    apiEndpoint,
    payload,
    optionalConfig,
  };
};

export const getStateReportAsAct = (apiEndpoint, payload, optionalConfig) => {
  return {
    type: masterdataconstants.GET_STATE_REPORT_AS_REQUEST,
    apiEndpoint,
    payload,
    optionalConfig,
  };
};