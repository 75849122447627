
import React, { useMemo } from "react";
import { Col, Container, Row } from 'reactstrap';
import WhTableContainer from "../../../../Components/Common/WhTables";
import { generateColumns, tableData } from "./helper";

export const AttachmentTab = () => {
    const columns = useMemo(() => generateColumns(), []);
    return (
        <Container fluid>
            <Row className="px-4">
                <Col xs={6}>
                    <h6>
                        Attachment History
                    </h6>
                    <WhTableContainer
                        columns={columns}
                        data={(tableData || [])}
                        customPageSize={100}
                        className="custom-header-css"
                        divClass="table-responsive mt-3 whbrand-table"
                        tableClass="align-middle table-bordered text-center"
                        theadClass="table-light"
                    />
                </Col>
            </Row>
        </Container>
    )
}