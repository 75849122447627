import { Input } from "reactstrap";
import SelectItem from "../../../../Components/Common/SelectItem";
//Constants
import { currencySymbol } from "../../../../constants";
//Utility
import { blockInvalidChar } from "../../../../utility/commonutility";

const customerColumnsList = [
  {
    id: "customerNumber",
    name: "Customer No",
    cellClassName: "fw-medium",
  },
  {
    id: "companyName",
    name: "Company",
    cellClassName: "fbadge badge-soft-primary",
  },
  {
    id: "customerName",
    name: "Customer Name",
    cellClassName: "fw-medium",
  },
];

export function generateColumns(selectItemHndlr, searchValueChngHndlr, handleCustomerPriceChange, handleRowDelete, handleCustomerPriceBlur) {
  return [
    {
      Header: "Customer #",
      accessor: "customerNumber",
      style: { width: "25%" },
      Cell: ({ row }) => {
        const { customerNumber, customerNumberSearch, customerNumberListOptions } = row.original;
        return customerNumber ? (
          customerNumber
        ) : (
          <SelectItem
            searchValue={customerNumberSearch}
            searchValueChngHndlr={(value) => searchValueChngHndlr(value, row.index, "customerNumberSearch")}
            listOptions={customerNumberListOptions || []}
            columnsList={customerColumnsList}
            placeholder="Search Customer Number"
            selectedItemHandler={(itemDetails) => selectItemHndlr(itemDetails, row.index)}
            fieldKey="customerNumber"
            dataIdKey="customerNumber"
            hideListOnBlur={true}
          />
        );
      },
    },
    {
      Header: "Company",
      accessor: "company",
      style: { width: "50%" },
      Cell: ({ row }) => {
        const { company, companySearch, companyListOptions } = row.original;
        return company ? (
          company
        ) : (
          <SelectItem
            searchValue={companySearch}
            searchValueChngHndlr={(value) => searchValueChngHndlr(value, row.index, "companySearch")}
            listOptions={companyListOptions || []}
            columnsList={customerColumnsList}
            placeholder="Search Customer Number"
            selectedItemHandler={(itemDetails) => selectItemHndlr(itemDetails, row.index)}
            fieldKey="companyName"
            dataIdKey="companyName"
            hideListOnBlur={true}
          />
        );
      },
    },
    {
      Header: "Customer Price",
      accessor: "customerPrice",
      style: { width: "20%" },
      Cell: ({ row }) => {
        const { customerPrice, customerNumber, id } = row.original;
        return !id && customerNumber ? (
          <div className={`form-icon`}>
            <Input
              type="number"
              onWheel={(e) => e.target.blur()}
              className="form-control form-control-icon"
              value={customerPrice}
              onChange={(e) => handleCustomerPriceChange(e, row.index)}
              onKeyDown={blockInvalidChar}
              onBlur={() => handleCustomerPriceBlur(row.index)}
            />
            <i style={{ fontStyle: "normal" }}>{currencySymbol}</i>
          </div>
        ) : customerPrice ? (
          `${currencySymbol}${customerPrice}`
        ) : (
          ""
        );
      },
    },
    {
      Header: "Action",
      accessor: "action",
      Cell: ({ row }) => (
        <i
          className="ri-delete-bin-line text-danger fs-5"
          onClick={() => handleRowDelete(row.original.id, row.index)}
        ></i>
      ),
    },
  ];
}

export const mapSpecialPrices = (apiResp, setSpecialPrices) => {
  const specialPrices = [];
  (apiResp?.data?.specialPrices || []).forEach((item) => {
    specialPrices.push({
      id: item?.id,
      customerNumber: item?.customer?.customerNumber,
      company: item?.customer?.company,
      customerPrice: item?.cPrice,
    });
  });
  setSpecialPrices(specialPrices);
};
