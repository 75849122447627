export const tabsOption = [
    {
        id: "daily_analysis",
        name: "Daily Analysis Summary Report"
    },
    {
        id: "customer_balance",
        name: "Customer Balance Analysis"
    },
    {
        id: "inventory_quantity",
        name: "Inventory Quantity Analysis"
    },
]