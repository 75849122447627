import purchaseorderconstants from "./purchaseorderconstants";

export const getAllPurchaseOrdersAct = (apiEndpoint, payload, optionalConfig) => {
  return {
      type:purchaseorderconstants.GET_ALL_PURCHASE_ORDERS_REQUEST,
      apiEndpoint,
      payload,
      optionalConfig
  }
};

export const changeGetAllPurchaseOrdersLoadingStatusAct = (status) => {
  return {
      type:purchaseorderconstants.CHANGE_GET_ALL_PURCHASE_ORDERS_LOADING_STATUS,
      payload:status
  }
};

export const createPurchaseOrderAct = (apiEndpoint, payload, optionalConfig) => {
  return {
      type:purchaseorderconstants.CREATE_PURCHASE_ORDER_REQUEST,
      apiEndpoint,
      payload,
      optionalConfig
  }
};

export const getPurchaseOrderDetailsAct = (apiEndpoint, payload, optionalConfig) => {
  return {
      type:purchaseorderconstants.GET_PURCHASE_ORDER_DETAILS_REQUEST,
      apiEndpoint,
      payload,
      optionalConfig
  }
};

export const updatePurchaseOrderDetailsAct = (apiEndpoint, payload, optionalConfig) => {
  return {
      type:purchaseorderconstants.UPDATE_PURCHASE_ORDER_DETAILS_REQUEST,
      apiEndpoint,
      payload,
      optionalConfig
  }
};

export const getMaxPONoAct = (apiEndpoint, payload, optionalConfig) => {
  return {
      type:purchaseorderconstants.GET_MAX_PO_NO_REQUEST,
      apiEndpoint,
      payload,
      optionalConfig
  }
};

export const receivePoAct = (apiEndpoint, payload, optionalConfig) => {
  return {
      type:purchaseorderconstants.RECEIVE_PO_REQUEST,
      apiEndpoint,
      payload,
      optionalConfig
  }
};

export const deletePoAct = (apiEndpoint, payload, optionalConfig) => {
  return {
      type:purchaseorderconstants.DELETE_PO_REQUEST,
      apiEndpoint,
      payload,
      optionalConfig
  }
};

export const openPoAct = (apiEndpoint, payload, optionalConfig) => {
  return {
      type:purchaseorderconstants.OPEN_PO_REQUEST,
      apiEndpoint,
      payload,
      optionalConfig
  }
};

export const downloadPoPdfAct = (apiEndpoint, payload, optionalConfig) => {
  return {
      type:purchaseorderconstants.DOWNLOAD_PO_PDF_REQUEST,
      apiEndpoint,
      payload,
      optionalConfig
  }
};

export const changePoAPiLoadingStatusAct = (status) => {
  return {
      type:purchaseorderconstants.CHANGE_PO_API_LOADING_STATUS,
      payload:status
  }
};