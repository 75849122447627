import PrintIcon from "../../../../assets/images/warehouse/salespage/printer.png";
import GenerateIcon from "../../../../assets/images/warehouse/salespage/generate.png";
import ExportIcon from "../../../../assets/images/warehouse/salespage/excel.png";
import ResetIcon from "../../../../assets/images/warehouse/salespage/reset.png";
import { getYyyymmddDateFormatted, getCustomDateFormatted } from "../../../../utility/dateutility";
import { formatAmount } from "../../../../utility/commonutility";
import { Link } from "react-router-dom";
export const actions = (togglePrintModal,getSalesHistoryReportApiCall, resetFilters) => [
  {
    id: "reset",
    label: "Reset",
    icon: ResetIcon,
    clickHandler: resetFilters
  },
  {
    id: "print",
    label: "Print",
    icon: PrintIcon,
    clickHandler:togglePrintModal
  },
  {
    id: "export",
    label: "Export",
    icon: ExportIcon,
    clickHandler : () => getSalesHistoryReportApiCall(0,10,true)
  },
];

export function generateColumns() {
  return [
    {
      Header: "Invoice No",
      accessor: "invoiceNumber",

    },
    {
      Header: "Date",
      accessor: "invoiceDate",
      Cell : ({cell:{value}}) => value ? getYyyymmddDateFormatted(value) : ""
    },
    {
      Header: "Customer",
      accessor: "company",
      Cell:({row:{original:{soldToCustomer,customer}}}) => <Link target="_blank" to={`/warehousecustomers/${soldToCustomer}`}>{customer?.companyName}</Link>,
    },
    {
      Header: "Discount",
      accessor: "discount",
      Cell : ({cell:{value}}) => formatAmount(value)
    },
    {
      Header: "Total Amount",
      accessor: "totalInvAmount",
      Cell : ({cell:{value}}) => formatAmount(value)
    },
    {
      Header: "Total Paid",
      accessor: "totalPaid",
      Cell : ({cell:{value}}) => formatAmount(value)
    },
    // {
    //   Header: "Due",
    //   accessor: "received",
    //   Cell : ({cell:{value}}) => formatAmount(value)
    // },
    // {
    //   Header: "Amount Allocated",
    //   accessor: "totalAllocated",
    //   Cell : ({cell:{value}}) => formatAmount(value)
    // },
    // {
    //   Header: "Change",
    //   accessor: "change",
    //   Cell : ({cell:{value}}) => formatAmount(value)
    // },
  ];
}
export const tableData = [
  {
    id: "1503",
    item: "05-05-2022",
    description: "New Salem Conv Store",
    quantity: "",
    caseQuantity: "$300.90",
    amount: "$300.90",
    received: "$0.00",
  },
  {
    id: "1503",
    item: "05-05-2022",
    description: "New Salem Conv Store",
    quantity: "",
    caseQuantity: "$300.90",
    amount: "$300.90",
    received: "$0.00",
  },
  {
    id: "1503",
    item: "05-05-2022",
    description: "New Salem Conv Store",
    quantity: "",
    caseQuantity: "$300.90",
    amount: "$300.90",
    received: "$0.00",
  },
  {
    id: "1503",
    item: "05-05-2022",
    description: "New Salem Conv Store",
    quantity: "",
    caseQuantity: "$300.90",
    amount: "$300.90",
    received: "$0.00",
  },
  {
    id: "1503",
    item: "05-05-2022",
    description: "New Salem Conv Store",
    quantity: "",
    caseQuantity: "$300.90",
    amount: "$300.90",
    received: "$0.00",
  },
  {
    id: "1503",
    item: "05-05-2022",
    description: "New Salem Conv Store",
    quantity: "",
    caseQuantity: "$300.90",
    amount: "$300.90",
    received: "$0.00",
  },
  {
    id: "1503",
    item: "05-05-2022",
    description: "New Salem Conv Store",
    quantity: "",
    caseQuantity: "$300.90",
    amount: "$300.90",
    received: "$0.00",
  },
];
