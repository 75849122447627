import React, { useEffect, useContext, useMemo, useState,useRef } from "react";
import { Link, withRouter } from 'react-router-dom';
import { useDispatch } from "react-redux";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { menuList } from "./helper";
import classnames from "classnames";
//import images
import UserIcon from "../../assets/images/warehouse/header/user_icon.png";
import { useProfile } from "../../Components/Hooks/UserHooks";
import { logoutUser } from "../../store/actions";
//Context
import { PermissionsContext } from "../../context/PermissionsContext"
import { generateApiUrl } from "../../api/apihelper";
import F12CloseModalListener from "../../Components/Common/F12close";

function HeaderWH(props) {
    const {permissions,handleLogout} = useContext(PermissionsContext);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [focusedButton, setFocusedButton] = useState("yes"); 

    const yesButtonRef = useRef("");
    const noButtonRef = useRef("");

    const toggleModal = () => setIsModalOpen(prev => !prev);
    const dispatch = useDispatch();
    const { userProfile, } = useProfile();
    
    useEffect(() => {
        const handleKeyDown = (e) => {
            if (isModalOpen && (e.key === "ArrowRight")) {
              e.preventDefault();
              setFocusedButton((prevButton) => (prevButton === "yes" ? "no" : "yes"));
            }
            else if (isModalOpen && (e.key === "ArrowLeft")) {
              e.preventDefault();
              setFocusedButton((prevButton) => (prevButton === "no" ? "yes" : "no"));
            }
          
          };
        window.addEventListener('keydown', handleKeyDown);
       
    }, [isModalOpen])

    useEffect(() => {
        if (isModalOpen) {
          const buttonRef = focusedButton === 'yes' ? yesButtonRef : noButtonRef;
          setTimeout(() => {
            if (buttonRef.current) {
              buttonRef.current.focus();
            }
          }, 0);
        }
      }, [isModalOpen, focusedButton, yesButtonRef, noButtonRef]);
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, [props.location.pathname]);
    const {user:{username,role:{name:roleName}}} = userProfile;
    const handleLogoutUser = () => {
        dispatch(logoutUser(generateApiUrl('logout_user')));
        handleLogout();
    }
    const filteredMenu = useMemo(() => {
        const menus = menuList.filter(item => item.permission ? permissions[item.permission] :true);
        return menus;
    }, [menuList, permissions]);
    return (
        <header className="wh-header">
            <ul className="wh-navbar-nav">
                {(filteredMenu || []).map((item, idx) => (
                    item.id !== "exit" ? 
                    <Link to={item.link[0]} key={item.id}>
                        <li 
                            className={classnames("nav-item", {
                                "active-nav-item":
                                props.location.pathname.startsWith(item.link) || 
                                item.link.includes(props.location.pathname),
                            })} 
                        >
                            {/* <span className="main-label">{item.mainLabel}</span> */}
                            <img src={item.icon} className="header-icon" />
                            <span className="sub-label">{item.subLabel}</span>
                        </li>
                    </Link>
                    :
                    <li key={idx}
                        className={classnames("nav-item","cursor-pointer", {
                            "active-nav-item":
                            props.location.pathname.startsWith(item.link)
                        })}
                        onClick={toggleModal}
                    >
                        {/* <span className="main-label">{item.mainLabel}</span> */}
                        <img src={item.icon} className="header-icon" />
                        <span className="sub-label">{item.subLabel}</span>
                    </li>
                ))}
            </ul>
            <div className="d-flex align-items-center">
                <img className="rounded-circle header-profile-user" src={UserIcon}
                    alt="Header Avatar" />
                <div className="text-start ms-xl-2">
                    <span className="d-none d-xl-inline-block ms-1 fw-medium user-name-text">{username}</span>
                    <span className="d-none d-xl-block ms-1 fs-12 text-muted user-name-sub-text">{roleName}</span>
                </div>
            </div>
            <Modal isOpen={isModalOpen} toggle={toggleModal} centered>
            <F12CloseModalListener onClose={toggleModal} />
                <ModalHeader className="border-bottom py-2">Caution</ModalHeader>
                <ModalBody className="py-0 pb-2">
                <div className="mt-2">
                    <div className="pt-2 fs-15">
                    {/* <h4>Are you sure ?</h4> */}
                    <p className="text-muted mb-0">Any unsaved changes will be lost, do you want to exit?</p>
                    </div>
                </div>
                <div className="d-flex gap-2 justify-content-end mt-2 mb-2">
                    <button
                    ref={yesButtonRef}
                    type="button"
                    className= {`btn w-sm  ${focusedButton === "yes" ? "btn-primary border border-danger" : "btn-light"}`}
                    id="delete-record"
                    onClick={handleLogoutUser}
                    onKeyDown={(e) => {
                        if (e.key === "ArrowRight") {
                          e.preventDefault();
                          setFocusedButton("no");
                        }
                      }}
                    >
                    Yes
                    </button>
                    <button
                    ref={noButtonRef}
                    type="button"
                    className={`btn w-sm  ${focusedButton === "no" ? "btn-primary border border-danger" : "btn-light"}`}
                    data-bs-dismiss="modal"
                    onClick={toggleModal}
                    onKeyDown={(e) => {
                        if (e.key === "ArrowLeft") {
                          e.preventDefault();
                          setFocusedButton("yes");
                        }
                      }}
                    >
                    No
                    </button>
                </div>
                </ModalBody>
            </Modal>
        </header>
    )
}

export default withRouter(HeaderWH);