import React from "react";
import {  Col, Container, Input, Label, Row, Button, } from 'reactstrap';
import CheckboxIcon from "../../assets/images/warehouse/salespage/checkbox_icon.png";
import CancelIcon from "../../assets/images/warehouse/salespage/cancel_icon.png";

export default function AddNewItem() {
    return (
        <Container fluid>
            <Row>
                <Col xs={12} className="bg-primary text-center fs-5 text-white py-2">
                    Customer's Info
                </Col>
            </Row>
            <Row className="px-3 py-2 border-bottom">
                <Col xs={12} className="fs-5 d-flex flex-column">
                    <span>1023</span>
                    <span>All In One Step</span>
                </Col>
            </Row>
            <Row className="p-3">
                <Col xs={9}>
                    <Row className="mb-3">
                        <Col xs={2} className="d-flex align-items-center">
                            <Label htmlFor="street" className="form-label mb-0 text-nowrap">Street :</Label>
                        </Col>
                        <Col xs={10}>
                            <Input name="street" id="street" className="form-control" placeholder="Enter Street" type="text" />
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col xs={2} className="d-flex align-items-center">
                            <Label htmlFor="street" className="form-label mb-0 text-nowrap">City :</Label>
                        </Col>
                        <Col xs={10}>
                            <Input name="street" id="street" className="form-control" placeholder="Enter City" type="text" />
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col xs={2} className="d-flex align-items-center">
                            <Label htmlFor="street" className="form-label mb-0 text-nowrap">State :</Label>
                        </Col>
                        <Col xs={5}>
                            <Input name="street" id="street" className="form-control" placeholder="Enter State" type="text" />
                        </Col>
                        <Col xs={5}>
                            <Row className="h-100">
                                <Col xs={5} className="d-flex align-items-center">
                                    <Label htmlFor="street" className="form-label mb-0 text-nowrap">Pin Code :</Label>
                                </Col>
                                <Col xs={7}>
                                    <Input name="street" id="street" className="form-control" placeholder="Enter Zip" type="text" />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col xs={2} className="d-flex align-items-center">
                            <Label htmlFor="street" className="form-label mb-0 text-nowrap">Phone :</Label>
                        </Col>
                        <Col xs={10}>
                            <Input name="street" id="street" className="form-control" placeholder="Enter Phone" type="text" />
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col xs={2} className="d-flex align-items-center">
                            <Label htmlFor="street" className="form-label mb-0 text-nowrap">Cell :</Label>
                        </Col>
                        <Col xs={10}>
                            <Input name="street" id="street" className="form-control" placeholder="Enter Cell" type="text" />
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col xs={2} className="d-flex align-items-center">
                            <Label htmlFor="street" className="form-label mb-0 text-nowrap">Tax Id :</Label>
                        </Col>
                        <Col xs={10} className="d-flex align-items-center">
                            <Input name="street" id="street" className="form-control" placeholder="Enter Tax Id" type="text" />
                            <div className="form-check ms-2">
                                <Input className="form-check-input" type="checkbox" id="formCheck6" defaultChecked />
                                <Label className="form-check-label text-nowrap" htmlFor="formCheck6">
                                    Tax Exempt
                                </Label>
                            </div>
                        </Col>
                        <Col xs={4}>

                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col xs={2} className="d-flex align-items-center">
                            <Label htmlFor="street" className="form-label mb-0 text-nowrap">Email Id :</Label>
                        </Col>
                        <Col xs={10} className="d-flex align-items-center">
                            <Input name="street" id="street" className="form-control" placeholder="Enter Email Id" type="text" />
                            <div className="form-check ms-2">
                                <Input className="form-check-input" type="checkbox" id="formCheck6" defaultChecked />
                                <Label className="form-check-label text-nowrap" htmlFor="formCheck6">
                                    Add To List
                                </Label>
                            </div>
                        </Col>
                    </Row>

                </Col>
                <Col xs={3}>

                    <Button color="danger" className="w-100 mb-2" outline>
                        <span className="d-flex justify-content-center align-items-center">
                            {/* <Spinner size="sm" type="grow" className="flex-shrink-0" role="status"> Loading... </Spinner> */}
                            <img src={CancelIcon} style={{ height: "16px", width: "16px" }} />
                            <span className="ms-2">
                                Cancel
                            </span>
                        </span>
                    </Button>
                    <Button color="primary" className="w-100" outline>
                        <span className="d-flex justify-content-center align-items-center">
                            {/* <Spinner size="sm" type="grow" className="flex-shrink-0" role="status"> Loading... </Spinner> */}
                            <img src={CheckboxIcon} style={{ height: "16px", width: "16px" }} />
                            <span className="ms-2">
                                Check Address
                            </span>
                        </span>
                    </Button>
                </Col>
            </Row>
        </Container>

    )
}