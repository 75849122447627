import React, {
  useMemo,
  useState,
  useRef,
  useEffect,
  useCallback,
} from "react";
import moment from "moment";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { toast } from "react-toastify";
import {
  Container,
  Row,
  Col,
  Label,
  Input,
  Modal,
  Form,
  ModalHeader,
  ModalBody,
  ListGroup,
} from "reactstrap";
import {
  actions,
  generateColumns,
  inventoryItemColumnsList,
  customerColumnsList,
} from "./helper";
import BackIcon from "../../../../assets/images/warehouse/menu/back.png";
import WhTableContainer from "../../../../Components/Common/WhTables";
import ApiLoader from "../../../../Components/Common/ApiLoader";
import SelectItem from "../../../../Components/Common/SelectItem";
import {
  getStartDateFilterValue,
  getEndDateFilterValue,
} from "../../../../utility/dateutility";

//Api Helper
import { generateApiUrl } from "../../../../api/apihelper";
//Actions
import {
  getInventoryAllMasterDataAct,
  getInventoryListAct,
} from "../../../../store/inventory/inventoryactions";
import {
  getInvPricingReportPrintAct,
  getInvSalesReportAct,
  getSalesReportItemsAct,
} from "../../../../store/reports/reportsactions";
import { getAllCustomersAct } from "../../../../store/customer/customeractions";
import F12CloseModalListener from "../../../../Components/Common/F12close";
import PrintItem from "./PrintItem";
import SelectOption from "../../../../Components/Common/SelectOption";
const InventorySales = ({ history }) => {
  const [filters, setFilters] = useState({
    startDate: moment().format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
    itemNumber: "",
    category: "",
    manufacturer: "",
    subCategory: "",
    groupCode: "",
    family: "",
    customerNumber: "",
  });
  const [selectedRadio, setSelectedRadio] = useState('byInvoice');

  const handleRadioChange = (event) => {
     setSelectedRadio(event.target.value);
  };
  const [tableData, setTableData] = useState([]);
  const [showPrintModal, setShowPrintModal] = useState(false);
  const [invListSearch, setInvListSearch] = useState("");
  const [invListSrchOptions, setInvListSrchOptions] = useState([]);
  const [custListSearch, setCustListSearch] = useState("");
  const [custListSrchOptions, setCustListSrchOptions] = useState([]);
  const [dropdownState, setDropdownState] = useState({
    category: [],
    subCategory: [],
    family: [],
    manufacturer: [],
    groupCode: [],
  });
  function togglePrintModal() {
    setShowPrintModal(!showPrintModal);
  }
  const isDownloadBtnClicked = useRef(false);
  const dispatch = useDispatch();
  const currentPageSize = useRef(30);

  //Inventory List Api Helpers
  const currentInventoryListPage = useRef(1);
  const invListDropdownBtnClicked = useRef(false);
  const callbackOnInventoryListApiResp = useRef(() => {});

  //Supplier List Api Helpers
  const currentCustomerListPage = useRef(1);
  const custListDropdownBtnClicked = useRef(false);
  const callbackOnCustomerListApiResp = useRef(() => {});

  const {
    getInventoryAllMasterDataResp,
    getInventoryListResp,
    getAllCustomersResp,
    getInvSalesReportResp,
    getSalesReportItemsResp,
    getSalesReportItemsLoading,
    getInvPricingReportPrintLoading,
    getInvPricingReportLoading,
    reportsErrorResp,
    getInvPricingReportPrintResp,
  } = useSelector(
    ({ reports, inventory, customer }) => ({
      getInventoryAllMasterDataResp: inventory.getInventoryAllMasterDataResp,
      getInventoryListResp: inventory.getInventoryListResp,
      getAllCustomersResp: customer.getAllCustomersResp,
      getInvSalesReportResp: reports.getInvSalesReportResp,
      getSalesReportItemsResp: reports.getSalesReportItemsResp,
      getSalesReportItemsLoading: reports.getSalesReportItemsLoading,
      getInvPricingReportPrintLoading: reports.getInvPricingReportPrintLoading,
      getInvPricingReportLoading: reports.getInvPricingReportLoading,
      reportsErrorResp: reports.reportsErrorResp,
      getInvPricingReportPrintResp: reports.getInvPricingReportPrintResp,
    }),
    shallowEqual
  );
  const customerResp = useRef({
    prevGetInventoryAllMasterDataResp: getInventoryAllMasterDataResp,
    prevGetInventoryListResp: getInventoryListResp,
    prevGetAllCustomersResp: getAllCustomersResp,
    prevGetInvSalesReportResp: getInvSalesReportResp,
    prevGetSalesReportItemsResp: getSalesReportItemsResp,
    prevReportsErrorResp: reportsErrorResp,
    prevGetInvPricingReportPrintResp: getInvPricingReportPrintResp,
  });
  useEffect(() => {
    const {
      prevGetInventoryAllMasterDataResp,
      prevGetInventoryListResp,
      prevGetAllCustomersResp,
      prevGetInvSalesReportResp,
      prevGetSalesReportItemsResp,
      prevReportsErrorResp,
    } = customerResp.current || {};
    if (
      getInvSalesReportResp &&
      prevGetInvSalesReportResp !== getInvSalesReportResp
    ) {
      setTableData(getInvSalesReportResp?.data?.report || []);
      currentPageSize.current = getInvSalesReportResp?.data?.pageSize;
    } else if (
      getSalesReportItemsResp &&
      prevGetSalesReportItemsResp !== getSalesReportItemsResp
    ) {
      if (isDownloadBtnClicked.current) {
        const url = window.URL.createObjectURL(new Blob([getSalesReportItemsResp]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
         "invoice_totals_details.csv"
        );
        document.body.appendChild(link);
        link.click();
        link.remove();
        isDownloadBtnClicked.current = false;
      }
    } else if (
      getAllCustomersResp &&
      prevGetAllCustomersResp !== getAllCustomersResp
    ) {
      const list = [];
      (getAllCustomersResp?.data?.customers || []).forEach(
        ({ customerNumber, firstName, lastName, companyName }) => {
          list.push({
            customerNumber,
            customerName: `${firstName || ""} ${lastName || ""}`,
            companyName,
          });
        }
      );
      if (!custListDropdownBtnClicked.current) setCustListSrchOptions(list);
      else {
        setCustListSrchOptions((prev) => [...prev, ...list]);
        if (getAllCustomersResp?.data?.customers?.length > 0)
          callbackOnCustomerListApiResp.current();
        currentCustomerListPage.current =
          Number(getAllCustomersResp?.data?.page) + 1;
      }
    } else if (
      getInventoryListResp &&
      prevGetInventoryListResp !== getInventoryListResp
    ) {
      const list = [];
      (getInventoryListResp?.data?.inventories || []).forEach(
        ({ itemNumber, description, category }) => {
          list.push({
            itemNumber,
            itemName: description,
            category,
          });
        }
      );
      if (!invListDropdownBtnClicked.current) setInvListSrchOptions(list);
      else {
        setInvListSrchOptions((prev) => [...prev, ...list]);
        if (getInventoryListResp?.data?.inventories?.length > 0)
          callbackOnInventoryListApiResp.current();
        currentInventoryListPage.current =
          Number(getInventoryListResp?.data?.page) + 1;
      }
    } else if (
      getInventoryAllMasterDataResp &&
      prevGetInventoryAllMasterDataResp !== getInventoryAllMasterDataResp
    ) {
      const {
        data: {
          lists: {
            category,
            subCategory,
            family,
            manufacturer,
            model,
            priceUser,
            groupCode,
          },
        },
      } = getInventoryAllMasterDataResp;
      setDropdownState((prev) => ({
        ...prev,
        category: category.map((item) => ({ value: item, label: item })),
        subCategory: subCategory.map((item) => ({ value: item, label: item })),
        family: family.map((item) => ({ value: item, label: item })),
        manufacturer: manufacturer.map((item) => ({
          value: item,
          label: item,
        })),
        groupCode: groupCode.map((item) => ({ value: item, label: item })),
      }));
    } else if (reportsErrorResp && prevReportsErrorResp !== reportsErrorResp) {
      const { errors, error, message } = reportsErrorResp || {};
      toast.error((Array.isArray(errors) && errors[0].msg) || error || message);
    }
    customerResp.current.prevGetInventoryAllMasterDataResp =
      getInventoryAllMasterDataResp;
    customerResp.current.prevGetInventoryListResp = getInventoryListResp;
    customerResp.current.prevGetInvPricingReportPrintResp =
      getInvPricingReportPrintResp;
    customerResp.current.prevGetAllCustomersResp = getAllCustomersResp;
    customerResp.current.prevGetInvSalesReportResp = getInvSalesReportResp;
    customerResp.current.prevGetSalesReportItemsResp = getSalesReportItemsResp;
    customerResp.current.prevReportsErrorResp = reportsErrorResp;
  }, [
    getInventoryAllMasterDataResp,
    getInventoryListResp,
    getInvPricingReportPrintResp,
    getAllCustomersResp,
    getInvSalesReportResp,
    getSalesReportItemsResp,
    reportsErrorResp,
  ]);

  const getSuppliersReportApiCall = useCallback(
    (page, pageSize) => {
      const {
        startDate,
        endDate,
        itemNumber,
        category,
        manufacturer,
        subCategory,
        groupCode,
        family,
        customerNumber,
      } = filters;
      let params = {
        page: page ? page + 1 : 1,
        pageSize: pageSize || currentPageSize.current,
      };
      if (startDate) params.startDate = getStartDateFilterValue(startDate);
      if (endDate) params.endDate = getEndDateFilterValue(endDate);
      if (itemNumber) params.itemNumber = itemNumber;
      if (category) params.category = category.value;
      if (manufacturer) params.manufacturer = manufacturer.value;
      if (subCategory) params.subCategory = subCategory.value;
      if (groupCode) params.groupCode = groupCode.value;
      if (family) params.family = family.value;
      if (customerNumber) params.customerNumber = customerNumber;
      dispatch(
        getInvSalesReportAct(generateApiUrl(`get_inv_sales_report`), params)
      );
    },
    [filters]
  );


  const generateSalesReportItems = useCallback(
    (download, downloadType , pdfType) => {
      const {
        startDate,
        endDate,
        itemNumber,
        category,
        manufacturer,
        subCategory,
        groupCode,
        family,
        customerNumber,
      } = filters;
      const params = {
        startDate: getStartDateFilterValue(startDate),
        endDate: getEndDateFilterValue(endDate),
        download: true,
        downloadType: downloadType,
      };
      if (itemNumber) params.itemNumber = itemNumber;
      if (category) params.category = category.value;
      if (manufacturer) params.manufacturer = manufacturer.value;
      if (subCategory) params.subCategory = subCategory.value;
      if (groupCode) params.groupCode = groupCode.value;
      if (family) params.family = family.value;
      if (customerNumber) params.customerNumber = customerNumber;
      if (download && downloadType === "html") {
        params.download = true;
        params.downloadType = downloadType;
        params.pdfType = pdfType;
        // isDownloadBtnClicked.current = true;
        dispatch(
          getInvPricingReportPrintAct(
            generateApiUrl(`get_sales_report_items`),
            params
          )
        );
      } else if (download) {
        params.download = true;
        params.pdfType = pdfType || selectedRadio;
        isDownloadBtnClicked.current = true;
        dispatch(
          getSalesReportItemsAct(
            generateApiUrl(`get_sales_report_items`),
            params,
            { responseType: "blob" }
          )
        );
      } else {
        dispatch(
          getSalesReportItemsAct(
            generateApiUrl(`get_sales_report_items`),
            params
          )
        );
      }
    },
    [filters,selectedRadio]
  );

  useEffect(() => {
    const debounceFn = setTimeout(() => {
      getSuppliersReportApiCall();
    }, 400);
    return () => {
      clearTimeout(debounceFn);
    };
  }, [getSuppliersReportApiCall]);

  const getInventoryListApiCall = useCallback(
    (showAllSuppliers, page) => {
      if (showAllSuppliers) {
        let params = {
          page: page || 1,
          pageSize: 50,
        };
        dispatch(
          getInventoryListAct(generateApiUrl(`get_inventory_list`), params)
        );
        invListDropdownBtnClicked.current = true;
      } else if (invListSearch) {
        let params = {
          page: 1,
          pageSize: 5,
        };
        if (isNaN(Number(invListSearch))) params.description = invListSearch;
        else params.itemNumber = invListSearch;
        dispatch(
          getInventoryListAct(generateApiUrl(`get_inventory_list`), params)
        );
        invListDropdownBtnClicked.current = false;
      }
    },
    [invListSearch]
  );
  useEffect(() => {
    const debounceFn = setTimeout(() => {
      getInventoryListApiCall();
    }, 200);
    return () => {
      clearTimeout(debounceFn);
    };
  }, [getInventoryListApiCall]);

  const getAllCustomersApiCall = useCallback(
    (showAllCustomers, page) => {
      if (showAllCustomers) {
        let params = {
          page: page || 1,
          pageSize: 50,
          orderBy: "company",
          sort: "asc",
        };
        dispatch(
          getAllCustomersAct(generateApiUrl(`get_all_customers`), params)
        );
        custListDropdownBtnClicked.current = true;
      } else if (custListSearch) {
        let params = {
          page: 1,
          pageSize: 5,
          search: custListSearch,
          orderBy: "company",
          sort: "asc",
        };
        dispatch(
          getAllCustomersAct(generateApiUrl(`get_all_customers`), params)
        );
        custListDropdownBtnClicked.current = false;
      }
    },
    [custListSearch]
  );

  useEffect(() => {
    const debounceFn = setTimeout(() => {
      getAllCustomersApiCall();
    }, 200);
    return () => {
      clearTimeout(debounceFn);
    };
  }, [getAllCustomersApiCall]);

  useEffect(() => {
    dispatch(
      getInventoryAllMasterDataAct(
        generateApiUrl(`get_inventory_all_master_data`)
      )
    );
  }, []);

  const handlePageChange = (page, pageSize) => {
    getSuppliersReportApiCall(page, pageSize);
  };

  const filtersChngHndlr = (e) => {
    const { value, checked, name, type } = e.target;
    setFilters((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const columns = useMemo(() => generateColumns(), []);

  const handleMainFilterChange = (e) => {
    const { id } = e.target;
    switch (id) {
      case "purchases":
        return history.push("/inventorypurchasesandsales/purchases");
      default:
        return null;
    }
  };

  function selectInvListItemCallbck(itemDetails) {
    const { itemNumber, itemName } = itemDetails;
    setFilters((prev) => ({ ...prev, itemNumber }));
    setInvListSearch(itemName);
  }

  const filtersDropdownChngHndlr = (selected, name) => {
    setFilters((prev) => ({ ...prev, [name]: selected }));
  };

  function selectCustListItemCallbck(itemDetails) {
    const { customerNumber, companyName } = itemDetails;
    setFilters((prev) => ({ ...prev, customerNumber }));
    setCustListSearch(companyName);
  }

  const resetFilters = () => {
    setFilters({
      startDate: moment().format("YYYY-MM-DD"),
      endDate: moment().format("YYYY-MM-DD"),
      itemNumber: "",
      category: "",
      manufacturer: "",
      subCategory: "",
      groupCode: "",
      family: "",
      customerNumber: "",
    });
    setCustListSearch("");
    setInvListSearch("");
  };

  return (
    <>
      <Container fluid className=" pt-3 pb-0">
        <Row>
          <Col xs="12" className="mb-0">
          <div className="px-3 mb-3 pt-1 border rounded-1 report-header" style={{display:"inline-block"}}>
          <h3 style={{color:'#fff'}}>Inventory Sales Report</h3>
         </div>
            <div className="border ps-4 my-1" style={{ position: "relative" }}>
              <Label
                className="text-dark fs-5"
                style={{ position: "absolute", transform: "translateY(-50%)" }}
              >
               <span className=" px-1 wh-item-bg"> Filters</span>
              </Label>
              <Row>
                <Col xs={1}>
                  <div className="form-check my-3">
                    <Input
                      className="form-check-input"
                      type="checkbox"
                      id="purchases"
                      onChange={handleMainFilterChange}
                    />
                    <Label
                      className="form-check-label text-nowrap"
                      htmlFor="purchases"
                    >
                      Purchases
                    </Label>
                  </div>
                  <div className="form-check my-3">
                    <Input
                      className="form-check-input"
                      type="checkbox"
                      id="sales"
                      checked
                    />
                    <Label
                      className="form-check-label text-nowrap"
                      htmlFor="sales"
                    >
                      Sales
                    </Label>
                  </div>
                </Col>
                <Col xs={2}>
                  <Row className="my-3">
                    <Col xs="12">
                      <Label className="mb-1">Date From</Label>
                      <Input
                        type="date"
                        className="form-control py-1"
                        name="startDate"
                        value={filters.startDate}
                        onChange={filtersChngHndlr}
                      />
                    </Col>
                  </Row>
                  <Row className="mb-1">
                    <Col xs="12">
                      <Label className="mb-1">Date To</Label>
                      <Input
                        type="date"
                        className="form-control py-1"
                        name="endDate"
                        value={filters.endDate}
                        onChange={filtersChngHndlr}
                      />
                    </Col>
                  </Row>
                </Col>

                <Col xs={5}>
                  <Row>
                    <Col xs={6} className="my-3 mb-1">
                      <Label
                        htmlFor="companyName"
                        className="form-label mb-1 text-nowrap"
                      >
                        Item No/Description:
                      </Label>
                      <SelectItem
                        searchValue={invListSearch}
                        setSearchValue={setInvListSearch}
                        listOptions={invListSrchOptions}
                        setSearchListOptions={setInvListSrchOptions}
                        columnsList={inventoryItemColumnsList}
                        placeholder="Select Item"
                        selectedItemHandler={selectInvListItemCallbck}
                        fieldKey="itemName"
                        dataIdKey="itemNumber"
                        showDropdownBtn={true}
                        addF3Shortcut={false}
                        dropdownBtnClickHndlr={(setShowOptions) => {
                          getInventoryListApiCall(true);
                          setShowOptions(true);
                        }}
                        callbackAfterBottomReached={(fn) => {
                          getInventoryListApiCall(
                            true,
                            currentInventoryListPage.current
                          );
                          callbackOnInventoryListApiResp.current = fn;
                        }}
                      />
                    </Col>
                    <Col xs={6} className="my-3 mb-1">
                      <Label
                        htmlFor="category"
                        className="form-label mb-1 text-nowrap"
                      >
                        Category:
                      </Label>
                      <SelectOption
                        id="category"
                        options={dropdownState.category}
                        categoryValue={filters.category}
                        filtersDropdownChngHndlr={filtersDropdownChngHndlr}
                      />
                    </Col>
                    <Col xs={6} className="mb-1">
                      <Label
                        htmlFor="manufacturer"
                        className="form-label mb-1 text-nowrap"
                      >
                        Manufacturer:
                      </Label>
                      <SelectOption
                        id="manufacturer"
                        options={dropdownState.manufacturer}
                        categoryValue={filters.manufacturer}
                        filtersDropdownChngHndlr={filtersDropdownChngHndlr}
                      />
                    </Col>
                    <Col xs={6} className="mb-1">
                      <Label
                        htmlFor="subCategory"
                        className="form-label mb-1 text-nowrap"
                      >
                        Sub Category:
                      </Label>
                      <SelectOption
                        id="subCategory"
                        options={dropdownState.subCategory}
                        categoryValue={filters.subCategory}
                        filtersDropdownChngHndlr={filtersDropdownChngHndlr}
                      />
                    </Col>
                    <Col xs={6} className="mb-1">
                      <Label
                        htmlFor="groupCode"
                        className="form-label mb-1 text-nowrap"
                      >
                        Group Code:
                      </Label>
                      <SelectOption
                        id="groupCode"
                        options={dropdownState.groupCode}
                        categoryValue={filters.groupCode}
                        filtersDropdownChngHndlr={filtersDropdownChngHndlr}
                      />
                    </Col>
                    <Col xs={6} className="mb-1">
                      <Label
                        htmlFor="family"
                        className="form-label mb-1 text-nowrap"
                      >
                        Family:
                      </Label>
                      <SelectOption
                        id="family"
                        options={dropdownState.family}
                        categoryValue={filters.family}
                        filtersDropdownChngHndlr={filtersDropdownChngHndlr}
                      />
                    </Col>
                    <Col xs={6} className="mb-1">
                      <Label
                        htmlFor="companyName"
                        className="form-label mb-1 text-nowrap"
                      >
                        Customer:
                      </Label>
                      <SelectItem
                        searchValue={custListSearch}
                        setSearchValue={setCustListSearch}
                        listOptions={custListSrchOptions}
                        setSearchListOptions={setCustListSrchOptions}
                        columnsList={customerColumnsList}
                        placeholder="Select Customer"
                        selectedItemHandler={selectCustListItemCallbck}
                        fieldKey="companyName"
                        dataIdKey="customerNumber"
                        containerClassName={"flex-grow-1"}
                        addF3Shortcut={false}
                        showDropdownBtn={true}
                        dropdownBtnClickHndlr={(setShowOptions) => {
                          getAllCustomersApiCall(true);
                          setShowOptions(true);
                        }}
                        callbackAfterBottomReached={(fn) => {
                          getAllCustomersApiCall(
                            true,
                            currentCustomerListPage.current
                          );
                          callbackOnCustomerListApiResp.current = fn;
                        }}
                      />
                    </Col>
                  </Row>
                </Col>

                <Col
                  xs="4"
                  xxl="3"
                  className="d-flex flex-column justify-content-center pe-xxl-5 pe-4"
                >
                  <div className="d-flex align-items-center w-100 mb-1">
                    <div className="sale-wh-wrapper flex-grow-1 mt-0 me-xxl-4 me-2">
                      <div className="d-flex justify-content-around border border-section py-2 h-100">
                        {actions(
                          togglePrintModal,
                          generateSalesReportItems,
                          resetFilters
                        ).map((item) => {
                          return (
                            <div
                              key={item.id}
                              className="action-icon-container"
                              style={{cursor:"pointer"}}
                              onClick={item.clickHandler || (() => {})}
                            >
                              <img className="action-icon" src={item.icon} />
                              <span>{item.label}</span>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                    <div
                      className="d-flex flex-column align-items-center"
                      style={{ cursor: "pointer" }}
                      onClick={() => history.push("/reportsmenu")}
                    >
                      <img
                        src={BackIcon}
                        width="32"
                        height="32"
                        alt="back-icon"
                      />
                      <div className="text-decoration-underline mb-0">
                        Go Back
                      </div>
                    </div>
                  </div>
                  <div className="d-flex flex-wrap justify-content-start border p-3">
                    <div className="form-check me-4 mb-1">
                      <Input
                        className="form-check-input"
                        type="radio"
                        name="pdfTypeOuter"
                        id="byInvoice"
                        value="byInvoice"
                        checked={selectedRadio === 'byInvoice' || selectedRadio === ''}
                        onChange={handleRadioChange}
                      />
                      <Label
                        className="form-check-label text-nowrap"
                        htmlFor="byInvoice"
                      >
                        Invoices
                      </Label>
                    </div>
                    <div className="form-check me-4">
                      <Input
                        className="form-check-input"
                        type="radio"
                        name="pdfTypeOuter"
                        id="byCategory"
                        value="byCategory"
                        checked={selectedRadio === 'byCategory'}
                        onChange={handleRadioChange}
                      />
                      <Label
                        className="form-check-label text-nowrap"
                        htmlFor="byCategory"
                      >
                          Categoty
                      </Label>
                    </div>
                    <div className="form-check me-4">
                      <Input
                        className="form-check-input"
                        type="radio"
                        name="pdfTypeOuter"
                        id="byVelocity"
                        value="byVelocity"
                        checked={selectedRadio === 'byVelocity'}
                        onChange={handleRadioChange}
                      />
                      <Label
                        className="form-check-label text-nowrap"
                        htmlFor="byVelocity"
                      >
                        Velocity
                      </Label>
                    </div>
                    <div className="form-check me-4">
                      <Input
                        className="form-check-input"
                        type="radio"
                        name="pdfTypeOuter"
                        id="byItem"
                        value="byItem"
                        checked={selectedRadio === 'byItem'}
                        onChange={handleRadioChange}
                        disabled
                      />
                      <Label
                        className="form-check-label text-nowrap"
                        htmlFor="byItem"
                      >
                        By Item
                      </Label>
                    </div>
                    <div className="form-check me-4">
                      <Input
                        className="form-check-input"
                        type="radio"
                        name="pdfTypeOuter"
                        id="byTotal"
                        value="byTotal"
                        checked={selectedRadio === 'byTotal'}
                        onChange={handleRadioChange}
                      />
                      <Label
                        className="form-check-label text-nowrap"
                        htmlFor="byTotal"
                      >
                        Totals
                      </Label>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
          <Col xs="12">
            <WhTableContainer
              columns={columns}
              data={tableData || []}
              className="custom-header-css"
              divClass="table-responsive table-container-inventory-sales"
              tableClass="align-middle table-bordered"
              theadClass="table-light"
              handlePageChange={handlePageChange}
              pageCount={Math.ceil(
                (getInvSalesReportResp?.data?.total || 0) / 10
              )}
              customPageSize={30}
              showNoDataComponent={true}
            />
          </Col>
        </Row>
        <ApiLoader loading={getSalesReportItemsLoading} />
      </Container>
      <Modal
        id="showPrintModal"
        isOpen={showPrintModal}
        toggle={togglePrintModal}
        centered
        size="lg"
      >
        <F12CloseModalListener onClose={togglePrintModal} />
        <ModalHeader className="py-2 px-3" toggle={togglePrintModal}>
          Print Options
        </ModalHeader>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <ModalBody className="px-0 pt-0">
            <PrintItem
              generateSalesReportItems={generateSalesReportItems}
              filters={filters}
              showPrintModal={showPrintModal}
            />
          </ModalBody>
        </Form>
      </Modal>
      <ApiLoader loading={getSalesReportItemsLoading} />
      <ApiLoader loading={getInvPricingReportLoading} />
    </>
  );
};

export default InventorySales;
