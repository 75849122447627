import PrintIcon from "../../../../assets/images/warehouse/salespage/printer.png";
import GenerateIcon from "../../../../assets/images/warehouse/salespage/generate.png";
import ExportIcon from "../../../../assets/images/warehouse/salespage/excel.png";

export const actions = () => [
  {
    id: "generate",
    label: "Generate",
    icon: GenerateIcon,
  },
  {
    id: "print",
    label: "Print",
    icon: PrintIcon,
  },
  {
    id: "export",
    label: "Export",
    icon: ExportIcon,
  },
];

export function generateColumns() {
  return [
    {
      Header: "Item#",
      accessor: "id",
    },
    {
      Header: "Description",
      accessor: "item",
    },
    {
      Header: "Manufacturer",
      accessor: "description",
    },
    {
      Header: "Category",
      accessor: "quantity",
    },
    {
      Header: "Sub-Category",
      accessor: "caseQuantity",
    },
    {
      Header: "Oty",
      accessor: "amount",
    },
  ];
}
export const tableData = [
  {
    id: "15-932",
    item: "Game 2/99 c Blue",
    description: "Swedish Match",
    quantity: "Tobacco",
    caseQuantity: "",
    amount: "372",
  },
  {
    id: "15-932",
    item: "Game 2/99 c Blue",
    description: "Swedish Match",
    quantity: "Tobacco",
    caseQuantity: "",
    amount: "372",
  },
  {
    id: "15-932",
    item: "Game 2/99 c Blue",
    description: "Swedish Match",
    quantity: "Tobacco",
    caseQuantity: "",
    amount: "372",
  },
  {
    id: "15-932",
    item: "Game 2/99 c Blue",
    description: "Swedish Match",
    quantity: "Tobacco",
    caseQuantity: "",
    amount: "372",
  },
  {
    id: "15-932",
    item: "Game 2/99 c Blue",
    description: "Swedish Match",
    quantity: "Tobacco",
    caseQuantity: "",
    amount: "372",
  },
  {
    id: "15-932",
    item: "Game 2/99 c Blue",
    description: "Swedish Match",
    quantity: "Tobacco",
    caseQuantity: "",
    amount: "372",
  },
  {
    id: "15-932",
    item: "Game 2/99 c Blue",
    description: "Swedish Match",
    quantity: "Tobacco",
    caseQuantity: "",
    amount: "372",
  },
];
