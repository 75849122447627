import { put } from "redux-saga/effects";

export function* handleApiResponse(response, action) {
  const { successAction, failAction } = action || {};
  try {
    if (response && response.error) {
      yield put({
        type: failAction,
        payload: response.error,
      });
    } else if (response) {
      yield put({
        type: successAction,
        payload: response.data || { status: 200 },
      });
    }
  } catch (e) {
    console.error("Error occuered", e);
  }
}
