import React from "react";
import { toAbsoluteUrl } from "../../utility/commonutility";

const NoDataComponent = ({ className }) => {
  return (
    <div className={`d-flex flex-column align-items-center justify-content-center ${className}`}>
      <img 
      src={toAbsoluteUrl("/Empty.svg")} 
      alt="No Data"
      // style={{ filter: 'contrast(550%)' }}
      />
    </div>
  );
};

export default NoDataComponent;