import React from "react";
import {
    Col, Container, Input, Label, Row, Button,
} from 'reactstrap';
import ResetIcon from "../../../../assets/images/warehouse/salespage/reset-blue.png";
import SaveIcon from "../../../../assets/images/warehouse/salespage/save.png";

export const TaxTab = () => {
    return (
        <Container fluid className="px-5 py-3">
            <Row>
                <Col xs="7">
                    <Row className="mb-1">
                        <Col xs={2} className="d-flex align-items-center">
                            <Label htmlFor="companyName" className="form-label mb-0 text-nowrap">
                                Fed Tax Id :
                            </Label>
                        </Col>
                        <Col xs={10}>
                            <Input
                                name="companyName"
                                id="companyName"
                                className="form-control py-1"
                                type="text"
                            />
                        </Col>
                    </Row>
                    <Row className="mb-1">
                        <Col xs={2} className="d-flex align-items-center">
                            <Label htmlFor="customerName" className="form-label mb-0 text-nowrap">
                                State Tax Id :
                            </Label>
                        </Col>
                        <Col xs={10}>
                            <Input
                                name="customerName"
                                id="customerName"
                                className="form-control py-1"
                                type="text"
                            />
                        </Col>
                    </Row>
                    <Row className="mb-1">
                        <Col xs={2} className="d-flex align-items-center">
                            <Label htmlFor="customerName" className="form-label mb-0 text-nowrap">
                                Default Sales Tax :
                            </Label>
                        </Col>
                        <Col xs={10}>
                            <Input className="form-control py-1" type="text" />
                        </Col>
                    </Row>
                    <Row className="mb-1">
                        <Col xs={3} className="d-flex align-items-center">
                            <Label htmlFor="companyName" className="form-label mb-0 text-nowrap">
                                Default Credit Charge :
                            </Label>
                        </Col>
                        <Col xs={3}>
                            <Input className="form-control py-1" placeholder="Enter Phone" type="text" />
                        </Col>
                        <Col xs={2} className="d-flex align-items-center">
                            <Label htmlFor="companyName" className="form-label mb-0 text-nowrap">
                                Debit Charge :
                            </Label>
                        </Col>
                        <Col xs={4}>
                            <Input className="form-control py-1" placeholder="Enter Fax" type="text" />
                        </Col>
                    </Row>
                </Col>
                <Col xs="5">
                    <div className="d-flex justify-content-center">
                        <Button color="primary" className="mb-2 w-50 wh-btn" outline>
                            <span className="d-flex justify-content-center align-items-center">
                                {/* <Spinner size="sm" type="grow" className="flex-shrink-0" role="status"> Loading... </Spinner> */}
                                <img src={SaveIcon} style={{ height: "32px", width: "32px" }} />
                                <span className="ms-2">Save</span>
                            </span>
                        </Button>
                    </div>
                </Col>
            </Row>
        </Container>
    )
}