import { Link } from "react-router-dom";
//Utility
import { getMmddyyyyDateFormatted } from "../../utility/dateutility";
export function generateColumns() {
    return [
        {
            Header: "Purchase Order Num",
            accessor: "purchaseOrderNumber",
            Cell:({cell:{value}}) => <Link to={`/warehousepurchaseorders/${value}`}>{value}</Link>
        },
        {
            Header: "Supplier Name",
            accessor: "supplierName",
            // Cell:({cell:{value}}) => <Link to={`/warehousepurchaseorders/${value}`}>{value}</Link>
        },
        {
            Header: "Invoice Num",
            accessor: "invNumber",
            Cell:({cell:{value}}) => value || "-"
        }, 
        {
            Header: "PO Date",
            accessor: "poDate",
            Cell:({cell:{value}}) => value ? getMmddyyyyDateFormatted(value) : "-"
        },
        {
            Header: "Payment Status",
            accessor: "paymentStatus",
            Cell: ({ cell: { value } }) => (
              <span className={`badge badge-soft-${value ? "success" : "danger"}`}>{value ? "Paid" : "Unpaid"}</span>
            ),
          },
    ]
}