import PrintIcon from "../../../../assets/images/warehouse/salespage/printer.png";
import ExportIcon from "../../../../assets/images/warehouse/salespage/excel.png";
import GenerateTableIcon from "../../../../assets/images/warehouse/salespage/generate_tables.png";

export const actions = (toggle, togglePrintModal) => [
    {
        id: "reset",
        label: "Generate",
        icon: GenerateTableIcon
    }, {
        id: "print",
        label: "Print",
        icon: PrintIcon,
        clickHandler: togglePrintModal,
    }, {
        id: "export",
        label: "Export",
        icon: ExportIcon
    }
]

export function generateColumns() {
    return [
        {
            Header: "Date",
            accessor: "date"
        },
        {
            Header: "CNT",
            accessor: "cnt"
        }, {
            Header: "Total Sales",
            accessor: "totalSales"
        }, {
            Header: "Cash Paid",
            accessor: "cashPaid"
        }, {
            Header: "Check",
            accessor: "check"
        }, {
            Header: "Credit Cards",
            accessor: "creditCards"
        }, {
            Header: "Total Payments",
            accessor: "totalPayments"
        }, {
            Header: "INV Payments",
            accessor: "invPayments"
        }, {
            Header: "AR Payments",
            accessor: "arPayments"
        }, {
            Header: "Unpaid Invoices",
            accessor: "unpaidInvoices"
        }, {
            Header: "Acc Receivable",
            accessor: "accReceivable"
        }
    ]
}
export const tableData = [
    {
        date: "01-07-2022",
        cnt: "15",
        totalSales: "15000",
        cashPaid: "4000",
        check: "444",
        creditCards: "900",
        totalPayments: "44444",
        invPayments: "1344",
        arPayments: "",
        unpaidInvoices: "12233",
        accReceivable: "55",
    }, {
        date: "01-07-2022",
        cnt: "15",
        totalSales: "15000",
        cashPaid: "4000",
        check: "444",
        creditCards: "900",
        totalPayments: "44444",
        invPayments: "1344",
        arPayments: "",
        unpaidInvoices: "12233",
        accReceivable: "55",
    }, {
        date: "01-07-2022",
        cnt: "15",
        totalSales: "15000",
        cashPaid: "4000",
        check: "444",
        creditCards: "900",
        totalPayments: "44444",
        invPayments: "1344",
        arPayments: "",
        unpaidInvoices: "12233",
        accReceivable: "55",
    }, {
        date: "01-07-2022",
        cnt: "15",
        totalSales: "15000",
        cashPaid: "4000",
        check: "444",
        creditCards: "900",
        totalPayments: "44444",
        invPayments: "1344",
        arPayments: "",
        unpaidInvoices: "12233",
        accReceivable: "55",
    }, {
        date: "01-07-2022",
        cnt: "15",
        totalSales: "15000",
        cashPaid: "4000",
        check: "444",
        creditCards: "900",
        totalPayments: "44444",
        invPayments: "1344",
        arPayments: "",
        unpaidInvoices: "12233",
        accReceivable: "55",
    }, {
        date: "01-07-2022",
        cnt: "15",
        totalSales: "15000",
        cashPaid: "4000",
        check: "444",
        creditCards: "900",
        totalPayments: "44444",
        invPayments: "1344",
        arPayments: "",
        unpaidInvoices: "12233",
        accReceivable: "55",
    }, {
        date: "01-07-2022",
        cnt: "15",
        totalSales: "15000",
        cashPaid: "4000",
        check: "444",
        creditCards: "900",
        totalPayments: "44444",
        invPayments: "1344",
        arPayments: "",
        unpaidInvoices: "12233",
        accReceivable: "55",
    }, {
        date: "01-07-2022",
        cnt: "15",
        totalSales: "15000",
        cashPaid: "4000",
        check: "444",
        creditCards: "900",
        totalPayments: "44444",
        invPayments: "1344",
        arPayments: "",
        unpaidInvoices: "12233",
        accReceivable: "55",
    }, {
        date: "01-07-2022",
        cnt: "15",
        totalSales: "15000",
        cashPaid: "4000",
        check: "444",
        creditCards: "900",
        totalPayments: "44444",
        invPayments: "1344",
        arPayments: "",
        unpaidInvoices: "12233",
        accReceivable: "55",
    }, {
        date: "01-07-2022",
        cnt: "15",
        totalSales: "15000",
        cashPaid: "4000",
        check: "444",
        creditCards: "900",
        totalPayments: "44444",
        invPayments: "1344",
        arPayments: "",
        unpaidInvoices: "12233",
        accReceivable: "55",
    }, {
        date: "01-07-2022",
        cnt: "15",
        totalSales: "15000",
        cashPaid: "4000",
        check: "444",
        creditCards: "900",
        totalPayments: "44444",
        invPayments: "1344",
        arPayments: "",
        unpaidInvoices: "12233",
        accReceivable: "55",
    },
];