import PrintIcon from "../../../../../assets/images/warehouse/salespage/printer.png";
import GenerateIcon from "../../../../../assets/images/warehouse/salespage/generate.png";
import ExportIcon from "../../../../../assets/images/warehouse/salespage/excel.png";
import ResetIcon from "../../../../../assets/images/warehouse/salespage/reset.png";
import { formatAmount } from "../../../../../utility/commonutility";
import { Link } from "react-router-dom";

export const actions = (togglePrintModal, getInvPricingReportApiCall, resetFilters) => [
  {
    id: "reset",
    label: "Reset",
    icon: ResetIcon,
    clickHandler: resetFilters
  },
  {
    id: "print",
    label: "Print",
    icon: PrintIcon,
    clickHandler: togglePrintModal,
  },
  {
    id: "export",
    label: "Export",
    icon: ExportIcon,
    clickHandler : () => getInvPricingReportApiCall(0,10,true)
  },
];

export function generateColumns() {
  return [
    {
      Header: "Item#",
      accessor: "itemNumber",
      Cell:({cell:{value}}) => <Link target="_blank" to={`/warehouseinventory/${value}`}>{value}</Link>
  },
  {
      Header: "Description",
      accessor: "description",
      Cell:({row:{original:{itemNumber, description}}}) => <Link target="_blank" to={`/warehouseinventory/${itemNumber}`}>{description}</Link>
  },
    {
      Header: "Cost",
      accessor: "cost",
      Cell: ({cell:{value}}) => formatAmount(value)
    },
    {
      Header: "Retail",
      accessor: "retail",
      Cell: ({cell:{value}}) => formatAmount(value)
    },
  ];
}