import React, { useState, useMemo, useCallback, useEffect } from "react";
import { Col, Container, Input, Label, Row, Button } from "reactstrap";
import WhTableContainer from "../../Components/Common/WhTables";
import { getSearchLabel } from "./helper";
import { generateColumns } from "./searchsaleshelper";
import { getStartDateFilterValue, getEndDateFilterValue} from "../../utility/dateutility";

export default function SearchSales({
  setFilters,
  setShowSearchModal,
  modalTableData,
  modalPageCount,
  getInvoicesOfCustomerAct,
  generateApiUrl,
  dispatch,
  customActiveTab,
  getInvoicesOfCustomerLoading
}) {
  const [search, setSearch] = useState("");
  const [startDate, setStarDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [searchBy, setSearchBy] = useState("invoiceNumber");
  const getInvoicesOfCustomerApiCall = useCallback(
    (page, pageSize) => {
      let params = {
        page: page ? page + 1 : 1,
        pageSize: pageSize || 10,
      };
      if (["status"].indexOf(searchBy) > -1) {
        if (!search || search === "unpaid") params[searchBy] = "unpaid";
        else if (search || search === "paid") params[searchBy] = "paid";
      } else if (["invoiceDate"].indexOf(searchBy) > -1) {
        params.startDate = getStartDateFilterValue(startDate);
        params.endDate = getEndDateFilterValue(endDate);
      } else if (["invoiceNumber","customerNumber","salesman"].indexOf(searchBy) > -1) params[searchBy] = search;
      if (searchBy === "invoiceDate" ? startDate && endDate : true)
        dispatch(getInvoicesOfCustomerAct(generateApiUrl(`get_invoices_of_customer`), params));
    },
    [search, searchBy, startDate, endDate]
  );
  useEffect(() => {
    const debounceFn = setTimeout(() => {
      getInvoicesOfCustomerApiCall();
    }, 400);
    return () => {
      clearTimeout(debounceFn);
    };
  }, [getInvoicesOfCustomerApiCall]);
  const handlePageChange = (page, pageSize) => {
    getInvoicesOfCustomerApiCall(page, pageSize);
  };
  const handleChange = (e) => {
    const { name } = e.target;
    if (searchBy != name) {
      setSearchBy(name);
      setSearch("");
    }
  };
  // const searchHandler = () => {
  //   setFilters((prev) => ({ ...prev, search, searchBy }));
  //   setShowSearchModal(false);
  // };
  const columns = useMemo(() => generateColumns(), []);
  return (
    <Container fluid>
      <Row className="mb-4">
        <Col xs={12} className="bg-primary text-center fs-5 text-white py-2">
          Smart Search-Sales
        </Col>
      </Row>
      <Row className="mb-4">
        <Col xs={12}>
          <div className="px-3" style={{ position: "relative" }}>
            <Label
              className="fs-5 font-weight-bold "
              style={{ position: "absolute", transform: "translateY(-50%)" }}
            >
               <span className=" px-1 wh-item-bg"> Type Of Search</span>
            </Label>
            <Row className="border pt-4 pb-3">
              <Col xs="4" className="mb-2">
                <Input
                  type="radio"
                  name="invoiceNumber"
                  className="me-2"
                  onChange={handleChange}
                  checked={searchBy == "invoiceNumber"}
                />{" "}
                By Invoice Number
              </Col>
              <Col xs="4" className="mb-2">
                <Input
                  type="radio"
                  name="customerNumber"
                  className="me-2"
                  onChange={handleChange}
                  checked={searchBy == "customerNumber"}
                />{" "}
                By Customer Number
              </Col>
              <Col xs="4">
                <Input
                  type="radio"
                  name="invoiceDate"
                  className="me-2"
                  onChange={handleChange}
                  checked={searchBy == "invoiceDate"}
                />{" "}
                By Invoice Date
              </Col>
              <Col xs="4" className="mb-2">
                <Input
                  type="radio"
                  name="salesman"
                  className="me-2"
                  onChange={handleChange}
                  checked={searchBy == "salesman"}
                />{" "}
                By Salesman
              </Col>
              <Col xs="4" className="mb-2">
                <Input
                  type="radio"
                  name="status"
                  className="me-2"
                  onChange={handleChange}
                  checked={searchBy == "status"}
                />{" "}
                By Status
              </Col>
            </Row>
          </div>
        </Col>
      </Row>

      {searchBy && (
        <Row className="mb-2">
          <Col xs={12}>
            <Label htmlFor="userrole" className="form-label mb-1">
              Search By {getSearchLabel(searchBy)}
            </Label>
            <div className="d-flex align-items-center">
              {["invoiceNumber", "customerNumber", "salesman"].indexOf(searchBy) > -1 && (
                <Input
                  className="form-control me-2"
                  type="text"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
              )}
              {["invoiceDate"].indexOf(searchBy) > -1 && (
                <div className="d-flex align-items-center flex-grow-1">
                  <Input
                    className="form-control me-2"
                    type="date"
                    value={startDate}
                    onChange={(e) => setStarDate(e.target.value)}
                  />
                  <span className="me-2">to</span>
                  <Input
                    className="form-control me-2 flex-grow-1"
                    type="date"
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                  />
                </div>
              )}
              {searchBy === "status" && (
                <Input
                  className="form-control me-2"
                  type="select"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                >
                  <option value={"unpaid"}>Unpaid</option>
                  <option value={"paid"}>Paid</option>
                </Input>
              )}
              {/* <Button
                color="primary"
                onClick={searchHandler}
                disabled={["paidStatus", "receivedStatus"].indexOf(searchBy) > -1 ? false : search ? false : true}
              >
                Search
              </Button> */}
            </div>
          </Col>
        </Row>
      )}

      {/* <Row>
          <Col xs="12" className="d-flex justify-content-end"></Col>
        </Row> */}
      <Row>
        <Col xs={12} style={{ minHeight: "350px", maxHeight: "350px", overflowY: "auto" }}>
          <WhTableContainer
            columns={columns}
            data={modalTableData}
            className="custom-header-css"
            divClass="table-responsive mt-3"
            tableClass="align-middle"
            theadClass="table-light"
            handlePageChange={handlePageChange}
            pageCount={modalPageCount}
            customPageSize={10}
            showNoDataComponent={true}
            loading={getInvoicesOfCustomerLoading}
          />
        </Col>
      </Row>
    </Container>
  );
}
