import PrintIcon from "../../../assets/images/warehouse/salespage/printer.png";
import GenerateIcon from "../../../assets/images/warehouse/salespage/generate.png";
import ExportIcon from "../../../assets/images/warehouse/salespage/excel.png";
import ResetIcon from "../../../assets/images/warehouse/salespage/reset.png";
import { Link } from "react-router-dom";

export const actions = (togglePrintModal, getCustomerRouteReportApiCall, resetFilters) => [
  {
    id: "reset",
    label: "Reset",
    icon: ResetIcon,
    clickHandler: resetFilters
  },
  {
    id: "print",
    label: "Print",
    icon: PrintIcon,
    clickHandler:togglePrintModal
  },
  {
    id: "export",
    label: "Export",
    icon: ExportIcon,
    clickHandler: () => getCustomerRouteReportApiCall(0,10,true)
  },
];

export function generateColumns() {
  return [
    {
      Header: "Customer No",
      accessor: "customerNumber",
      Cell:({cell:{value}}) => <Link target="_blank" to={`/warehousecustomers/${value}`}>{value}</Link>,
  },
  {
      Header: "Company",
      accessor: "companyName",
      Cell:({row:{original:{customerNumber,companyName}}}) => <Link target="_blank" to={`/warehousecustomers/${customerNumber}`}>{companyName}</Link>,
  },
    {
      Header: "Shipped Via.",
      accessor: "shippedVia",
    },
    {
      Header: "Route",
      accessor: "routeName",
    },
    {
      Header: "Delivery Day",
      accessor: "routeDeliveryDay",
    },
    {
      Header: "Driver",
      accessor: "driver.name",
    },
    {
      Header: "Salesman",
      accessor: "salesman",
    },
    {
      Header: "Zone",
      accessor: "zone",
    },
  ];
}
export const tableData = [
  {
    id: "101",
    item: "IDN Distributors INC",
  },
  {
    id: "101",
    item: "IDN Distributors INC",
  },
  {
    id: "101",
    item: "IDN Distributors INC",
  },
  {
    id: "101",
    item: "IDN Distributors INC",
  },
  {
    id: "101",
    item: "IDN Distributors INC",
  },
  {
    id: "101",
    item: "IDN Distributors INC",
  },
  {
    id: "101",
    item: "IDN Distributors INC",
  },
];
