import React, { useMemo, useEffect, useState, useRef } from "react";
import { Col, Container, Row } from "reactstrap";
import WhTableContainer from "../../../../Components/Common/WhTables";
import { generateColumns } from "./helper";
//Actions
import { getPaymentsOfCustomerAct } from "../../../../store/customer/customeractions";

export const PaymentsTab = ({ history, dispatch, generateApiUrl, id, useSelector, shallowEqual }) => {
  const [tableData, setTableData] = useState([]);
  const { getPaymentsOfCustomerResp } = useSelector(
    ({ customer }) => ({
      getPaymentsOfCustomerResp: customer.getPaymentsOfCustomerResp,
    }),
    shallowEqual
  );
  const apiResp = useRef({
    prevGetPaymentsOfCustomerResp: getPaymentsOfCustomerResp,
  });
  useEffect(() => {
    const { prevGetPaymentsOfCustomerResp } = apiResp.current;
    if (getPaymentsOfCustomerResp && prevGetPaymentsOfCustomerResp !== getPaymentsOfCustomerResp) {
      setTableData(getPaymentsOfCustomerResp.data.payments);
    }
    apiResp.current.prevGetPaymentsOfCustomerResp = getPaymentsOfCustomerResp;
  }, [getPaymentsOfCustomerResp]);
  useEffect(() => {
    if (id && id !== "new")
      dispatch(
        getPaymentsOfCustomerAct(generateApiUrl("get_payments_of_customer"), {
          customerNumber: id,
          page: 1,
          pageSize: 10,
        })
      );
  }, []);
  const handlePageChange = (page, pageSize) => {
    dispatch(
      getPaymentsOfCustomerAct(generateApiUrl("get_payments_of_customer"), {
        customerNumber: id,
        page: page + 1,
        pageSize,
        orderBy:"datePaid",
        sort:"desc"
      })
    );
  };
  const columns = useMemo(() => generateColumns(history), []);
  return (
    <React.Fragment>
      <Container fluid className="px-4">
        <Row className="m-0">
          {/* <Col xs="12" className="d-flex justify-content-end">
              <div>
                <select className="form-select">
                  <option>10</option>
                  <option>25</option>
                  <option>50</option>
                  <option>75</option>
                </select>
              </div>
            </Col> */}
          <Col className="px-0" xs={12}>
            <WhTableContainer
              columns={columns}
              data={tableData || []}
              className="custom-header-css"
              divClass="table-responsive mt-3 whbrand-table"
              tableClass="align-middle table-bordered"
              theadClass="table-light"
              handlePageChange={handlePageChange}
              pageCount={Math.ceil((getPaymentsOfCustomerResp?.data?.total || 0) / 10)}
              customPageSize={30}
              showNoDataComponent={true}
            />
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};
