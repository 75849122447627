import PrintIcon from "../../../assets/images/warehouse/salespage/printer.png";
import SalesIcon from "../../../assets/images/warehouse/header/sales.png";
import ResetIcon from "../../../assets/images/warehouse/salespage/reset.png";
import DeleteIcon from "../../../assets/images/warehouse/salespage/delete.png";
import NewIcon from "../../../assets/images/warehouse/salespage/new.png";
import PaymentsIcon from "../../../assets/images/warehouse/salespage/payments.png";
import { toast } from "react-toastify";

export const actions = ( handelOpenPaymentsModal, history, toggleDeleteModal, initializeInvoiceDetails, id, getInvoiceDetailsApiCall,
    status, downloadInvoicePdfApiCall, toggleInvoiceResetConfirmModal, isBlocked, createInvoiceHandler, togglePrintModal, customerDetails ) => {
    const actionsList = [
      {
        id: "new",
        label: "New",
        icon: NewIcon,
        clickHandler: () => {
          if(id === "new") {
            if(isBlocked) toggleInvoiceResetConfirmModal();
            else initializeInvoiceDetails();
          }
          else history.push("/warehousesales/new");
        },
      },
      {
        id: "reset",
        label: "Reset",
        icon: ResetIcon,
        clickHandler: () => {
          if (id && id !== "new") {
            if(isBlocked) getInvoiceDetailsApiCall();
            else initializeInvoiceDetails();
          }
          else initializeInvoiceDetails();
        },
      },
      {
        id: "pos",
        label: "POS",
        icon: SalesIcon,
        clickHandler: () => history.push("/warehousesales"),
      },
      {
        id: "delete",
        label: "Delete",
        icon: DeleteIcon,
        clickHandler: toggleDeleteModal,
      },
    ];
    if (id === "new" || status === "open")
      actionsList.push({
        id: "payments",
        label: "Payments",
        icon: PaymentsIcon,
        clickHandler: handelOpenPaymentsModal,
      },
      {
        id: "print",
        label: "Print",
        icon: PrintIcon,
        clickHandler: () => {
          if (customerDetails.customerName) {     
            createInvoiceHandler(false);
            togglePrintModal();
          }
          else
          toast.error("Customer Not Found");
        },
      }
      );
      
    else
      actionsList.push({
        id: "print",
        label: "Print",
        icon: PrintIcon,
        // clickHandler: () => downloadInvoicePdfApiCall(id),
        clickHandler: togglePrintModal,
      });
    return actionsList;
  };
  