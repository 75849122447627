import React, { useMemo, useState } from "react";
import { Container, Row, Col, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import classnames from "classnames";
import { ListBy, Statistics, Balance } from "./tabs";
import { tabs } from "./helper";

function getActiveComponent(activeId) {
  switch (activeId) {
    case "list_by":
      return ListBy;
    case "statistics":
      return Statistics;
    case "balance":
      return Balance;
    default:
      return null;
  }
}

const InventoryPricingReport = ({ history }) => {
  const [justifyPillsTab, setjustifyPillsTab] = useState("list_by");
  const justifyPillsToggle = (tab) => {
    if (justifyPillsTab !== tab) {
      setjustifyPillsTab(tab);
    }
  };
  const TabComponent = useMemo(() => getActiveComponent(justifyPillsTab), [justifyPillsTab]);
  return (
    <>
      <ToastContainer />
      <Container fluid className=" pt-3 pb-0">
        <Row>
          <Col className="px-0" xs={12}>
          <div className="px-3 mb-3 pt-1 ms-2 border rounded-1 report-header" style={{display:"inline-block"}}>
          <h3 style={{color:'#fff'}}>Customers Report</h3>
         </div>
            <Nav pills className="mb-3 bg-light px-4">
              {tabs.map((item) => (
                <NavItem key={item.id}>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({ active: justifyPillsTab === item.id })}
                    onClick={() => {
                      justifyPillsToggle(item.id);
                    }}
                  >
                    {item.name}
                  </NavLink>
                </NavItem>
              ))}
            </Nav>
            <TabContent activeTab={justifyPillsTab}>
              <TabPane tabId={justifyPillsTab} id="pill-justified-home-1">
                <TabComponent history={history} toast={toast} />
              </TabPane>
            </TabContent>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default InventoryPricingReport;
