import React,{useEffect,useState,useRef} from 'react';
import { Modal, ModalBody } from 'reactstrap';
import F12CloseModalListener from '../../../Common/F12close';


const OpenInvoiceConfirmModal = ({isOpen, toggle, changePostedStatusOfInvoiceHandler, status, id}) => {
  const [focusedButton, setFocusedButton] = useState("yes"); 
  const yesButtonRef = useRef("");
  const closeRef = useRef("");
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (isOpen && (e.key === "ArrowRight")) {
        e.preventDefault();
        setFocusedButton((prevButton) => (prevButton === "yes" ? "close" : "yes"));
      }
      if (isOpen && (e.key === "ArrowLeft")) {
        e.preventDefault();
        setFocusedButton((prevButton) => (prevButton === "close" ? "yes" : "close"));
      }
    };
    window.addEventListener('keydown', handleKeyDown);
  }, [isOpen])
  useEffect(() => {
    if (isOpen) {
      if (focusedButton === "yes" && yesButtonRef.current) {
        yesButtonRef.current.focus();
      } else if (focusedButton === "close" && closeRef.current) {
        closeRef.current.focus();
      }
    }
  }, [isOpen, focusedButton]);

    return ( 
    <Modal isOpen={isOpen} toggle={toggle} centered={true}>
       <F12CloseModalListener onClose={toggle} />
        <ModalBody className="py-3 px-5">
          <div className="mt-2 text-center">
            <i className="ri-error-warning-line text-warning" style={{ fontSize: "48px" }}></i>
            <div className="pt-2 fs-15 mx-4 mx-sm-5">
              <p className="text-muted mx-4 mb-0">
                Are You Sure You Want to {status === "posted" ? "Open" : "Post"} Invoice No #{id}?
              </p>
            </div>
          </div>
          <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
            <button
               ref={yesButtonRef}
              type="button"
              className= {`btn w-sm  ${focusedButton === "yes" ? "btn-success border border-danger" : "btn-light"}`}
              id="delete-record"
              onClick={changePostedStatusOfInvoiceHandler}
              onKeyDown={(e) => {
                if (e.key === "ArrowLeft") {
                  e.preventDefault();
                  setFocusedButton("close");
                }
              }}
            >
              Yes
            </button>
            <button
              ref={closeRef}
              type="button"
              className={`btn w-sm  ${focusedButton === "close" ? "btn-success border border-danger" : "btn-light"}`}
              data-bs-dismiss="modal"
              onClick={toggle}
              onKeyDown={(e) => {
                if (e.key === "ArrowRight") {
                  e.preventDefault();
                  setFocusedButton("yes");
                }
              }}
            >
              Close
            </button>
          </div>
        </ModalBody>
    </Modal> );
}
 
export default OpenInvoiceConfirmModal;