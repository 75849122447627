import { Spinner } from "reactstrap";

const ApiLoader = ({ loading }) => {
  return (
    <>
      {loading && (
        <div className="d-flex justify-content-center align-items-center api-loader-container">
          <Spinner color="light" size="lg">Loading...</Spinner>
        </div>
      )}
    </>
  );
};

export default ApiLoader;
