
import React, { useMemo, useState } from "react";
import { Col, Container, Row, ModalHeader, Modal, ModalBody, Form } from 'reactstrap';
import WhTableContainer from "../../../../Components/Common/WhTables";
import { generateColumns, tableData, actions } from "./helper";
import AddProductSubcategory from "./AddProductSubcategory";

export const ProductSubcategoryTab = () => {
    const columns = useMemo(() => generateColumns(), []);
    const [modal, setModal] = useState(false);
    function newBtnToggle() {
        setModal(true);
    }
    function togglePrintModal() {
    }
    function toggle() {
        setModal(!modal);
    }
    return (
        <React.Fragment>
            <Container fluid className="g-0">
                <Row className="g-0">
                    <Col className="offset-sm-10 px-4" xs={2}>
                        <div className="d-flex justify-content-between border border-section py-2 px-1 h-100">
                            {actions(newBtnToggle, togglePrintModal).map((item) => {
                                return (
                                    <div key={item.id} className="action-icon-container" onClick={item.clickHandler || (() => { })}>
                                        <img className="action-icon" src={item.icon} />
                                        <span>{item.label}</span>
                                    </div>
                                )
                            })}
                        </div>
                    </Col>
                </Row>
                <Row className="g-0">
                    <Col xs={12}>
                        <WhTableContainer
                            columns={columns}
                            data={(tableData || [])}
                            customPageSize={100}
                            className="custom-header-css"
                            divClass="table-responsive mt-2 table-height-66vh"
                            tableClass="align-middle table-bordered text-center"
                            theadClass="table-light"
                        />
                    </Col>
                </Row>
            </Container>
            <Modal id="showModal" isOpen={modal} toggle={toggle} centered size="lg">
                <ModalHeader className="py-2 px-3" toggle={toggle}>
                    Add Product Sub-Category
                </ModalHeader>
                <Form onSubmit={(e) => {
                    e.preventDefault();
                }}>
                    <ModalBody className="px-0 pt-0">
                        <AddProductSubcategory />
                    </ModalBody>
                </Form>
            </Modal>
        </React.Fragment>
    )
}