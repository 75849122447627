export const tabs = [
  {
    name: "List By",
    id: "list_by",
  },
  {
    name: "Update",
    id: "update",
  },
  {
    name: "Price Code",
    id: "price_code",
  },
  {
    name: "Statistics",
    id: "statistics",
  },
];
