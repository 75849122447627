import ResetIcon from "../../assets/images/warehouse/salespage/reset.png";
import PrintIcon from "../../assets/images/warehouse/salespage/printer.png";
import ExportIcon from "../../assets/images/warehouse/salespage/excel.png";
import DeleteIcon from "../../assets/images/warehouse/salespage/delete.png";
import NewIcon from "../../assets/images/warehouse/salespage/new.png";
import { Link } from "react-router-dom";
//Utility
import { getMmddyyyyDateFormatted } from "../../utility/dateutility";
import {formatAmount} from "../../utility/commonutility";

export const actions = (history, toggleDeleteModal, resetFilters, toggleSearchModal) => [
  {
    id: "new",
    label: "New",
    icon: NewIcon,
    clickHandler: () => history.push("/warehousepurchaseorders/new"),
  },
  {
    id: "reset",
    label: "Reset",
    icon: ResetIcon,
    clickHandler: resetFilters,
  },
  // {
  //   id: "print",
  //   label: "Print",
  //   icon: PrintIcon,
  //   clickHandler: () => {},
  // },
  // {
  //   id: "export",
  //   label: "Export",
  //   icon: ExportIcon,
  //   clickHandler: () => {},
  // },
  {
    id: "delete",
    label: "Delete",
    icon: DeleteIcon,
    clickHandler: toggleDeleteModal,
  },
  {
    id: "smart_search",
    label: "Smart-Search",
    icon: <i className="ri-search-2-fill text-primary" style={{ fontSize: "20px" }}></i>,
    clickHandler: toggleSearchModal,
  },
];

export function generateColumns() {
  return [
    {
      Header: "Purchase Order Num",
      accessor: "purchaseOrderNumber",
      Cell: ({ cell: { value } }) => <Link to={`/warehousepurchaseorders/${value}`}>{value}</Link>,
    },
    {
      Header: "Supplier Name",
      accessor: "supplier.supplierName",
      Cell: ({
        row: {
          original: { supplierNumber, supplier },
        },
      }) => <Link to={`/warehousevendors/${supplierNumber}`}>{supplier?.supplierName}</Link>,
    },
    {
      Header: "Invoice Num",
      accessor: "invNumber",
      Cell: ({ cell: { value } }) => value || "-",
    },
    // {
    //     Header: "Ship Date",
    //     accessor: "shipDate",
    //     Cell:({cell:{value}}) => value ? getMmddyyyyDateFormatted(value) : "-"
    // },
    {
      Header: "PO Date",
      accessor: "poDate",
      Cell: ({ cell: { value } }) => (value ? getMmddyyyyDateFormatted(value) : "-"),
    },
    {
      Header: "Received Date",
      accessor: "receivedDate",
      Cell: ({ cell: { value } }) => (value ? getMmddyyyyDateFormatted(value) : "-"),
    },
    {
      Header: "Total PO Amount",
      accessor: "totalPoAmount",
      Cell: ({ cell: { value } }) => (value ? `${formatAmount(value)}` : "-"),
    },
    // {
    //   Header: "Payment Term",
    //   accessor: "paymentTerm",
    //   Cell: ({ cell: { value } }) => value || "-",
    // },
    {
        Header: "Amount Paid",
        accessor: "amountPaid",
        Cell: ({ cell: { value } }) => (value ? `${formatAmount(value)}` : "-"),
      },
      {
        Header: "Balance",
        accessor: "balance",
        Cell: ({ row: { original : {totalPoAmount, amountPaid} } }) => ((typeof totalPoAmount === "number" && typeof amountPaid === "number") ? `${formatAmount((amountPaid || 0) - totalPoAmount)}` : "-"),
      },
    {
      Header: "Payment Status",
      accessor: "paymentStatus",
      Cell: ({ cell: { value } }) => (
        <span className={`badge badge-soft-${value ? "success" : "danger"}`}>{value ? "Paid" : "Unpaid"}</span>
      ),
    },
  ];
}

export function getSearchLabel(searchBy) {
  switch (searchBy) {
    case "supplierName":
      return "Supplier Name";
    case "purchaseOrderNumber":
      return "Purchase Order Number";
    case "poDate":
      return "PO Date";
    case "paymentTerm":
      return "Payment Term";
    case "shipDate":
      return "Ship Date";
    case "receivedStatus":
      return "Received Status";
    case "paidStatus":
      return "Paid Status";
    case "supplierNumber":
      return "Supplier Number";
    default:
      return "";
  }
}
