import { Input } from "reactstrap";
//Utility
import { getMmddyyyyDateFormatted, getCustomDateFormatted } from "../../../../utility/dateutility";
import {formatAmount} from "../../../../utility/commonutility"
//Constants
import { currencySymbol } from "../../../../constants";

function handlePayOrderChange(setInvoicesForPayOrder, invoiceNumber) {
  setInvoicesForPayOrder(prev => {
    const updatedData = [...prev];
    let index = updatedData.indexOf(invoiceNumber);
    if(index !== -1) {
      updatedData.splice(index,1);
    } else {
      updatedData.push(invoiceNumber)
    }
    return updatedData;
  })

}

export function generateColumns(history, customActiveTab, invoicesForPayOrder, setInvoicesForPayOrder) {
  const columns = [
    {
      Header: "Invoice#",
      accessor: "invoiceNumber",
      Cell: ({ cell: { value } }) => (
        <div
          className="wh-link-color-green"
          style={{ cursor: "pointer" }}
          onClick={() => {
            history.push(`/warehousesales/${value}`, { redirected: true });
            localStorage.setItem("activeTab", JSON.stringify({ warehousecustomer: "unpaid_invoices" }));
          }}
        >
          {value}
        </div>
      ),
    },
    {
      Header: "Invoice Date",
      accessor: "invoiceDate",
      Cell: ({ cell: { value } }) => (value ? getMmddyyyyDateFormatted(value) : "-"),
    },
    {
      Header: "Days",
      accessor: "days",
    },
    {
      Header: "Invoice Total",
      accessor: "totalInvAmount",
      Cell: ({ cell: { value } }) =>
        typeof value == "number" ? (
          <>
            {formatAmount(value.toFixed(2))}
          </>
        ) : (
          ""
        ),
      style: { textAlign: "right" },
    },
    {
      Header: "Total Paid",
      accessor: "totalPaid",
      Cell: ({ cell: { value } }) =>
        typeof value == "number" ? (
          <>
            {formatAmount(value.toFixed(2))}
          </>
        ) : (
          ""
        ),
      style: { textAlign: "right" },
    },
    {
      Header: "Invoice Balance",
      accessor: "amountDue",
      Cell: ({ cell: { value } }) =>
        typeof value == "number" ? (
          <>
            {formatAmount(value.toFixed(2))}
          </>
        ) : (
          ""
        ),
      style: { textAlign: "right" },
    },
    {
      Header: "Pay Order",
      accessor: "payOrder",
      Cell: ({ row: { original : {invoiceNumber} } }) => (
        <div className="d-flex justify-content-between">
          <div className="form-check form-radio-outline form-radio-primary me-2">
            <Input className="form-check-input" type="radio" checked={invoicesForPayOrder.indexOf(invoiceNumber) !== -1} onClick={() => handlePayOrderChange( setInvoicesForPayOrder, invoiceNumber)} onChange={() => {}}/>
          </div>
          <div className="form-check form-radio-outline form-radio-primary me-2">
            <Input className="form-check-input" type="radio" checked={invoicesForPayOrder.length === 0} disabled />
          </div>
        </div>
      ),
    },
    {
      Header: "Comments",
      accessor: "comments",
    },
  ];
  if (customActiveTab === "1") {
    return columns.filter((item) => ["comments"].findIndex((it) => item.accessor == it) === -1);
  } else {
    return columns.filter(
      (item) => ["totalPaid", "amountDue", "payOrder"].findIndex((it) => item.accessor == it) === -1
    );
  }
}
