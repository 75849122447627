import React, { useMemo, useState, useRef, useEffect, useCallback } from "react";
import moment from "moment";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { Container, Row, Col, Label, Input, Modal, ModalHeader, ModalBody, Form, FormGroup } from "reactstrap";
import { actions, generateColumns } from "./helper";
import BackIcon from "../../../../../assets/images/warehouse/menu/back.png";
import WhTableContainer from "../../../../../Components/Common/WhTables";
import PrintItem from "./PrintItem";
import { getStartDateFilterValue, getEndDateFilterValue } from "../../../../../utility/dateutility";
//Api Helper
import { generateApiUrl } from "../../../../../api/apihelper";
//Actions
import { getInvPricingReportPrintAct, getInvPricingStatsAct } from "../../../../../store/reports/reportsactions";
//Utility
import {getYyyymmddDateFormatted} from "../../../../../utility/dateutility"

export const Statistics = ({ history, toast }) => {
  const [showPrintModal, setShowPrintModal] = useState(false);
  const [filters, setFilters] = useState({
   from:getYyyymmddDateFormatted(moment()),
   to:getYyyymmddDateFormatted(moment()),
  });
  const [tableData, setTableData] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  
  function togglePrintModal() {
    setShowPrintModal(!showPrintModal);
  }

  const dispatch = useDispatch();
  const isDownloadBtnClicked = useRef(false);

  const {
    getInvPricingStatsResp,
    reportsErrorResp,
  } = useSelector(
    ({ reports }) => ({
      getInvPricingStatsResp: reports.getInvPricingStatsResp,
      reportsErrorResp: reports.reportsErrorResp,
    }),
    shallowEqual
  );
  const inventoryResp = useRef({
    prevGetInvPricingStatsResp: getInvPricingStatsResp,
    prevReportsErrorResp: reportsErrorResp,
  });

  useEffect(() => {
    const {
      prevGetInvPricingStatsResp,
      prevReportsErrorResp,
    } = inventoryResp.current || {};
    if (getInvPricingStatsResp && prevGetInvPricingStatsResp !== getInvPricingStatsResp) {
      if(isDownloadBtnClicked.current) {
        const url = window.URL.createObjectURL(new Blob([getInvPricingStatsResp]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
         "statistics.csv"
        );
        document.body.appendChild(link);
        link.click();
        link.remove();
        isDownloadBtnClicked.current = false;
      }
      else {
        setTableData(getInvPricingStatsResp?.data?.report || []);
        setPageCount((getInvPricingStatsResp?.data?.total))
      }
    } else if (reportsErrorResp && prevReportsErrorResp !== reportsErrorResp) {
      const { errors, error, message } = reportsErrorResp || {};
      toast.error((Array.isArray(errors) && errors[0] && errors[0].msg) || error || message);
    }
    inventoryResp.current.prevGetInvPricingStatsResp = getInvPricingStatsResp;
    inventoryResp.current.prevReportsErrorResp = reportsErrorResp;
  }, [
    getInvPricingStatsResp,
    reportsErrorResp,
  ]);

  const getInvPricingReportApiCall = useCallback(
    (page, pageSize, download, downloadType = 'pdf',) => {
      const {
       from,
       to,
      } = filters || {};
      let params = {
        page: page ? page + 1 : 1,
        pageSize: pageSize || 30,
      };
      if(!download){
        params.page = page ? page + 1 : 1;
        params. pageSize = pageSize || 30;
      }
      if (from) params.from = getStartDateFilterValue(from);
      if (to) params.to = getEndDateFilterValue(to);
      if (download && downloadType === 'html') {
        params.download = true;
        params.downloadType = downloadType;
        dispatch(
          getInvPricingReportPrintAct(
            generateApiUrl(`get_inv_pricing_stats`),
            params
          )
        );
      } 
      else if (download){
        params.download = true;
        isDownloadBtnClicked.current = true; 
        dispatch(
          getInvPricingStatsAct(
            generateApiUrl(`get_inv_pricing_stats`),
            params,
            {responseType: 'blob'}
          )
        );}
        else {
          dispatch(
            getInvPricingStatsAct(
              generateApiUrl(`get_inv_pricing_stats`),
              params
            )
          );
        }
    },
    [filters]
  );

  useEffect(() => {
    const debounceFn = setTimeout(() => {
      getInvPricingReportApiCall();
    }, 400);
    return () => {
      clearTimeout(debounceFn);
    };
  }, [getInvPricingReportApiCall]);

  const handlePageChange = (page, pageSize) => {
    getInvPricingReportApiCall(page, pageSize);
  };

  const filtersChngHndlr = (e) => {
    const { name, value } = e.target;
    setFilters((prev) => ({ ...prev, [name]: value }));
  };

  const resetFilters = () => {
    setFilters({
      from:getYyyymmddDateFormatted(moment()),
      to:getYyyymmddDateFormatted(moment()),
     });
  }

  const columns = useMemo(() => generateColumns(), []);
  return (
    <>
      <Container fluid>
        <Row>
          <Col xs="12" className="mb-0">
            <div className="border ps-4 my-2" style={{ position: "relative" }}>
              <Label className="text-dark fs-5" style={{ position: "absolute", transform: "translateY(-50%)" }}>
              <span className=" px-1 wh-item-bg"> Filters</span>
              </Label>
              <Row>
                <Col xs="9" className="border-end pe-5">
                  <Row className="pt-3">
                    <Col xs="3">
                      <FormGroup className="mb-2">
                        <Label className="mb-0">From</Label>
                        <Input type="date" className="py-1" name="from" value={filters.from} onChange={filtersChngHndlr} />
                      </FormGroup>
                      <FormGroup>
                        <Label className="mb-0">To</Label>
                        <Input type="date" className="py-1" name="to" value={filters.to} onChange={filtersChngHndlr} />
                      </FormGroup>
                      <FormGroup>
                        <Label className="mb-0">Selected Option</Label>
                        <Input type="select" className="py-1">
                          <option>Sales</option>
                          <option>Created</option>
                          <option>Last Used</option>
                          <option>No Sales in Range</option>
                          <option>Active:No Sales in range</option>
                          <option>Sales Activity</option>
                          <option>Inventory Date</option>
                          <option>Cost Change</option>
                          <option>Retail Change</option>
                          <option>Inactive Items Sold</option>
                          <option>Inventory Adjustments</option>
                          <option>No Such Sales</option>
                          <option>Expiration Date</option>
                          <option>Track Deleted Items</option>
                        </Input>
                      </FormGroup>
                    </Col>
                  </Row>
                </Col>
                <Col xs="3">
                  <div className="d-flex align-items-center pe-xxl-5 pe-4 pt-3">
                    <div className="sale-wh-wrapper flex-grow-1 mt-0 me-xxl-4 me-2">
                      <div className="d-flex justify-content-around border border-section py-2 h-100">
                        {actions(togglePrintModal, getInvPricingReportApiCall, resetFilters).map((item) => {
                          return (
                            <div
                              key={item.id}
                              className="action-icon-container"
                              onClick={item.clickHandler || (() => {})}
                              style={{cursor:"pointer"}}
                            >
                              <img className="action-icon" src={item.icon} />
                              <span>{item.label}</span>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                    <div
                      className="d-flex flex-column align-items-center"
                      style={{ cursor: "pointer" }}
                      onClick={() => history.push("/reportsmenu")}
                    >
                      <img src={BackIcon} width="32" height="32" alt="back-icon" />
                      <div className="text-decoration-underline mb-0">Go Back</div>
                    </div>
                  </div>

                  <div className="mt-1">
                    <div className="form-check mb-1">
                      <Input className="form-check-input" type="checkbox" id="formCheck6" />
                      <Label className="form-check-label text-nowrap" htmlFor="formCheck6">
                        Sale or Discount
                      </Label>
                    </div>
                    <div className="form-check mb-1">
                      <Input className="form-check-input" type="checkbox" id="formCheck6" />
                      <Label className="form-check-label text-nowrap" htmlFor="formCheck6">
                        Promotion Items
                      </Label>
                    </div>
                    <div className="form-check mb-1">
                      <Input className="form-check-input" type="checkbox" id="formCheck6" />
                      <Label className="form-check-label text-nowrap" htmlFor="formCheck6">
                        Best Deals
                      </Label>
                    </div>
                    <div className="form-check mb-1">
                      <Input className="form-check-input" type="checkbox" id="formCheck6" />
                      <Label className="form-check-label text-nowrap" htmlFor="formCheck6">
                        Items To Orders
                      </Label>
                    </div>
                    <div className="form-check mb-1">
                      <Input className="form-check-input" type="checkbox" id="formCheck6" />
                      <Label className="form-check-label text-nowrap" htmlFor="formCheck6">
                        View Profit %
                      </Label>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
          <Col xs="12">
            <WhTableContainer
              columns={columns}
              data={tableData || []}
              className="custom-header-css"
              divClass="table-responsive table-container-statics"
              tableClass="align-middle table-bordered"
              theadClass="table-light"
              handlePageChange={handlePageChange}
              pageCount={pageCount || 0}
              customPageSize={30}
              showNoDataComponent={true}
            />
          </Col>
        </Row>
      </Container>
      <Modal id="showPrintModal" isOpen={showPrintModal} toggle={togglePrintModal} centered size="lg">
        <ModalHeader className="py-2 px-3" toggle={togglePrintModal}>
          Print Options
        </ModalHeader>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <ModalBody className="px-0 pt-0">
         <PrintItem
           getInvPricingReportApiCall={getInvPricingReportApiCall}
           filters={filters}
           showPrintModal={showPrintModal}
         />
       </ModalBody>
        </Form>
      </Modal>
    </>
  );
};
