import { call, takeLatest, put, delay } from "redux-saga/effects";
import { getData, postData, putData, deleteData } from "../../api/apiactions";
import { handleApiResponse } from "../../utility/sagautility";
import supplierconstants from "./supplierconstants";
import { changeSupplierApiLoadingStatusAct, changeGetSuppliersListLoadingStatusAct } from "./supplieractions"

function withLoading(fn) {
    return function* withLoadingGenerator(...args) {
        yield put(changeSupplierApiLoadingStatusAct(true));
        yield delay(500);
        yield* fn(...args);
        yield put(changeSupplierApiLoadingStatusAct(false));
    }
}

function* getSuppliersListGen(action) {
    yield put(changeGetSuppliersListLoadingStatusAct(true));
    const response = yield call(getData, action.apiEndpoint, action.payload, action.optionalConfig);
    yield call(handleApiResponse, response, {
        successAction:supplierconstants.GET_SUPPLIERS_LIST_SUCCESS,
        failAction:supplierconstants.GET_SUPPLIERS_LIST_FAIL,
    });
    yield delay(500);
    yield put(changeGetSuppliersListLoadingStatusAct(false));
}

function* createSupplierGen(action) {
    const response = yield call(postData, action.apiEndpoint, action.payload, action.optionalConfig);
    yield call(handleApiResponse, response, {
        successAction:supplierconstants.CREATE_SUPPLIER_SUCCESS,
        failAction:supplierconstants.CREATE_SUPPLIER_FAIL,
    })
}

function* getSupplierGen(action) {
    const response = yield call(getData, action.apiEndpoint, action.payload, action.optionalConfig);
    yield call(handleApiResponse, response, {
        successAction:supplierconstants.GET_SUPPLIER_SUCCESS,
        failAction:supplierconstants.GET_SUPPLIER_FAIL,
    })
}

function* updateSupplierDetailsGen(action) {
    const response = yield call(putData, action.apiEndpoint, action.payload, action.optionalConfig);
    yield call(handleApiResponse, response, {
        successAction:supplierconstants.UPDATE_SUPPLIER_DETAILS_SUCCESS,
        failAction:supplierconstants.UPDATE_SUPPLIER_DETAILS_FAIL,
    })
}

function* getSupplierInvoicesGen(action) {
    const response = yield call(getData, action.apiEndpoint, action.payload, action.optionalConfig);
    yield call(handleApiResponse, response, {
        successAction:supplierconstants.GET_SUPPLIER_INVOICES_SUCCESS,
        failAction:supplierconstants.GET_SUPPLIER_INVOICES_FAIL,
    })
}

function* getMasterDataForSupplierGen(action) {
    const response = yield call(getData, action.apiEndpoint, action.payload, action.optionalConfig);
    yield call(handleApiResponse, response, {
        successAction:supplierconstants.GET_MASTER_DATA_FOR_SUPPLIER_SUCCESS,
        failAction:supplierconstants.GET_MASTER_DATA_FOR_SUPPLIER_FAIL,
    })
}

function* deleteSupplierGen(action) {
    const response = yield call(deleteData, action.apiEndpoint, action.payload, action.optionalConfig);
    yield call(handleApiResponse, response, {
        successAction:supplierconstants.DELETE_SUPPLIER_SUCCESS,
        failAction:supplierconstants.DELETE_SUPPLIER_FAIL,
    })
}

function* getItemsOfSupplierGen(action) {
    const response = yield call(getData, action.apiEndpoint, action.payload, action.optionalConfig);
    yield call(handleApiResponse, response, {
        successAction:supplierconstants.GET_ITEMS_OF_SUPPLIER_SUCCESS,
        failAction:supplierconstants.GET_ITEMS_OF_SUPPLIER_FAIL,
    })
}

function* getMaxSupplierNumGen(action) {
    const response = yield call(getData, action.apiEndpoint, action.payload, action.optionalConfig);
    yield call(handleApiResponse, response, {
        successAction:supplierconstants.GET_MAX_SUPPLIER_NUM_SUCCESS,
        failAction:supplierconstants.GET_MAX_SUPPLIER_NUM_FAIL,
    })
}

export function* supplierSaga() {
    yield takeLatest(supplierconstants.GET_SUPPLIERS_LIST_REQUEST, getSuppliersListGen);
    yield takeLatest(supplierconstants.CREATE_SUPPLIER_REQUEST, withLoading(createSupplierGen));
    yield takeLatest(supplierconstants.GET_SUPPLIER_REQUEST, getSupplierGen);
    yield takeLatest(supplierconstants.UPDATE_SUPPLIER_DETAILS_REQUEST, withLoading(updateSupplierDetailsGen));
    yield takeLatest(supplierconstants.GET_SUPPLIER_INVOICES_REQUEST, getSupplierInvoicesGen);
    yield takeLatest(supplierconstants.GET_MASTER_DATA_FOR_SUPPLIER_REQUEST, getMasterDataForSupplierGen);
    yield takeLatest(supplierconstants.DELETE_SUPPLIER_REQUEST, withLoading(deleteSupplierGen));
    yield takeLatest(supplierconstants.GET_ITEMS_OF_SUPPLIER_REQUEST, getItemsOfSupplierGen);
    yield takeLatest(supplierconstants.GET_MAX_SUPPLIER_NUM_REQUEST, getMaxSupplierNumGen);
}
