import React, { useEffect, useState, useRef } from "react";
import { useSelector, shallowEqual } from "react-redux";
import { Row, Col, Table } from "reactstrap";

export default function SelectItem({
  innerRef,
  listOptions,
  columnsList,
  placeholder,
  inputClassName="",
  selectedItemHandler = () => {},
  dataIdKey,
  callback,
  reduxDetails,
  searchBy,
  setShowValueState,
  index,
  callbackAfterCallback,
  quantityKey,
  inputType = "text",
  addItemByEnter = false,
  addItemByEnterKey="",
  onKeyDown=()=>{},
  inputStyle,
}) {
  const [showOptions, setShowOptions] = useState(true);
  const [searchValue, setSearchValue] = useState("");
  const [availableOptions, setAvailableOptions] = useState(listOptions);
  const [activeOption, setActiveOption] = useState(0);
  const optionsContainer = useRef(null);
  const inputRef = useRef(null);
  const reduxOptions = reduxDetails.split(".");
  const { listResp } = useSelector(
    (state) => ({
      listResp: state[reduxOptions[0]][reduxOptions[1]],
    }),
    shallowEqual
  );
  const apiResp = useRef({
    prevListResp: listResp,
  });
  useEffect(() => {
    const { prevlistResp } = apiResp.current || {};
    if (listResp && prevlistResp !== listResp) {
      callbackAfterCallback(listResp, setAvailableOptions);
    }
    apiResp.current.prevlistResp = listResp;
  }, [listResp]);
  function onChangeHandler(event) {
    const { value } = event.target;
    setSearchValue(value);
  }

  // useEffect(() => {
  //   //Check if options container going off screen
  //   if (optionsContainer.current) {
  //     const { top, bottom } = optionsContainer.current.getBoundingClientRect();
  //     const viewportHeight = window.innerHeight || document.documentElement.clientHeight;
  //     if (top < 0 || bottom > viewportHeight) {
  //       const yOffset = -100; 
  //       window.scrollTo({ top: window.pageYOffset + top + yOffset, behavior: "smooth" });
  //     }
  //   }
  // }, [availableOptions]);
  function onClickSelectHandler() {
    setShowOptions(true);
  }
  useEffect(() => {
    callback({ [searchBy]: searchValue });
  }, [searchValue]);
  useEffect(() => {
    const timeout = setTimeout(() => inputRef.current.focus(), 200);
    return () => clearTimeout(timeout);
  }, []);
  function onClickRowHandler(event) {
    // event.stopPropagation();
    setShowValueState(false);
    const rowId = event.currentTarget.getAttribute("data-id");
    const selectedRowData = availableOptions.find((item) => item[dataIdKey] === rowId);
    if (selectedRowData) {
      selectedItemHandler(selectedRowData, index);
    }
    setShowOptions(false);
  }

  function downHandler(e) {
    const { key } = e;
    const active = document.activeElement;
    if (key === "ArrowUp") {
      e.preventDefault();
      const items = document.getElementsByClassName("select-item");
      if(items[activeOption-2])
      items[activeOption-2].scrollIntoView({ behavior: 'smooth', block: 'center' });
      if (activeOption > 1) setActiveOption((prev) => prev - 1);
    } else if (key === "ArrowDown") {
      e.preventDefault();
      const items = document.getElementsByClassName("select-item");
      if(items[activeOption])
      items[activeOption].scrollIntoView({ behavior: 'smooth', block: 'center' });
      if (activeOption <= availableOptions.length - 1) setActiveOption((prev) => prev + 1);
      
    } else if (key === "Enter") {
      if (activeOption) {
        selectedItemHandler({ ...availableOptions[activeOption - 1] }, index);
        setShowOptions(false);
        setShowValueState(false);
      } else if(addItemByEnter) {
        const itemIndex = availableOptions.findIndex(item => item[addItemByEnterKey] == searchValue);
        if(itemIndex > -1) {
          selectedItemHandler({ ...availableOptions[itemIndex] }, index);
          setShowOptions(false);
          setShowValueState(false);
        }
      }
    }
  }

  useEffect(() => {
    if (showOptions) {
      window.addEventListener("keydown", downHandler, true);
    } else window.removeEventListener("keydown", downHandler, true);
    return () => {
      window.removeEventListener("keydown", downHandler, true);
    };
  });

  return (
    <Row>
      <Col xs={12}>
        <div className="select-item-wrapper" ref={innerRef}>
          <input
          id="a"
            type={inputType}
            className={`form-control select-item-input ${inputClassName}`}
            onChange={onChangeHandler}
            onClick={onClickSelectHandler}
            onWheel={(e) => {
              if (e.deltaY !== 0) {
                e.currentTarget.blur();
              }
              setTimeout(()=> {
                if(inputRef.current){
                  inputRef.current.focus();
                }
              },300)
            }}
            value={searchValue}
            placeholder={placeholder}
            autoComplete="off"
            ref={inputRef}
            onKeyDown={onKeyDown}
            style={{...inputStyle}}
          />
          {showOptions && searchValue && Array.isArray(availableOptions) && availableOptions.length > 0 && (
            <div className="options-container" ref={optionsContainer}>
              <div className="table-responsive" style={{maxHeight:"350px",overflowY:'auto'}}>
                <Table className="table-sm table-nowrap mb-0">
                  <thead className="wh-table-head">
                    <tr>
                      {columnsList.map((item) => (
                        <th key={item.id} align="center">
                          <div className="d-flex justify-content-center"> {item.name}</div>
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {availableOptions.map((item, index) => (
                      <tr
                        className={`select-item ${activeOption === index + 1 ? "active text-dark" : ""} ${
                          (quantityKey ? item[quantityKey] > 0 : true)
                            ? "cursor-pointer"
                            : "show-cursor-not-allowed opacity-50"
                        }`}
                        id={"item-" + (index+1)}
                        data-id={item[dataIdKey]}
                        onClick={(quantityKey ? item[quantityKey] > 0 : true) ? onClickRowHandler : () => {}}
                        key={index}
                      >
                        {columnsList.map((it) => (
                          <td key={it.id} className={it.cellClassName} align="center">
                            {item[it.id]}
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </div>
          )}
          {searchValue && Array.isArray(availableOptions) && availableOptions.length === 0 && (
            <div className=" text-muted text-center border p-1 shadow-lg">No Items Found</div>
          )}
        </div>
      </Col>
    </Row>
  );
}
