import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Input, Table, Button } from "reactstrap";

function findMatchedOption(options,inputValue) {
  const normalizedInput = inputValue.replace(/\s/g, '').toLowerCase();

  for (const option of options) {
    const normalizedOptionValue = option.value.replace(/\s/g, '').toLowerCase();

    if (normalizedOptionValue === normalizedInput) {
      return option;
    }
  }

  // Return null if no match is found
  return {value:'',label:''};
}

const useActiveElement = () => {
  const [active, setActive] = useState(document.activeElement);

  const handleFocusIn = (e) => {
    setActive(document.activeElement);
    //   if(!document.activeElement.classList.contains("item")) setTempQty("");
  };

  useEffect(() => {
    document.addEventListener("focusin", handleFocusIn);
    return () => {
      document.removeEventListener("focusin", handleFocusIn);
    };
  }, []);

  return active;
};

export default function SelectOption({
  id,
  categoryValue,
  options,
  filtersDropdownChngHndlr,
  onInputBlur=()=>{},
  
}) {
  // const [showOptions, setShowOptions] = useState(false);
  const inputRef = useRef(null);
  const focusedElement = useActiveElement();
  const selectItemRef = useRef(null);
  const [selectedValue,setSelectedvalue] = useState('')
  const executeInputBlur = useRef(false);
  const inputBlurTimeoutRef = useRef(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [value,setValue] = useState(categoryValue.label || '')
  const [showDropdown, setShowDropdown] = useState(false);
  const [filteredOptions,setFilteredOptions] = useState(options)
  useEffect(() => {
    if(!categoryValue){
      setValue('');
    }
  },[categoryValue])
  function downHandler(e) {
    const { key } = e;
    const active = document.activeElement;
    executeInputBlur.current = false;
  
    if (key === "ArrowUp") {
      e.preventDefault();
      if (active.classList.contains("item")) {
        if (active.previousSibling) active.previousSibling.focus();
      } else {
        const firstItem = document.getElementById("item-1");
        if (firstItem) firstItem.focus();
      }
    } else if (key === "ArrowDown") {
      e.preventDefault();
      if (active.classList.contains("item")) {
        if (active.nextSibling) {
          active.nextSibling.focus();
        } else {
      
          const firstItem = document.getElementById("item-1");
          if (firstItem) firstItem.focus();
        }
      } else {
        const firstItem = document.getElementById("item-1");
        if (firstItem) firstItem.focus();
      }
    } else if (key === "Enter") {
      if (focusedElement.id.startsWith("item-")) {
        const eleValue = document.getElementById(focusedElement.id).innerText.toString();
        setValue(eleValue);
        setSearchTerm(eleValue);
        const option = findMatchedOption(options,eleValue)
        if(eleValue.toString().toUpperCase() !== selectedValue.toString().toUpperCase()){
          filtersDropdownChngHndlr(option,id)
          setSelectedvalue(eleValue.toString().toUpperCase())
          setShowDropdown(false);
        }
        
      }
    }
  }
  
  const handleInputChange = (event) => {
    const newValue = event.target.value;
    setValue(newValue.toString().toUpperCase());
    setSearchTerm(newValue);
    setShowDropdown(true);
  };
  useEffect(()=> {
    if(!value){
      filtersDropdownChngHndlr({value:'',label:''},id)
      setSelectedvalue('')
    }
  },[value,id])
  const handleDropdownClick = () => {
    setShowDropdown(!showDropdown);
    setFilteredOptions(options)
    if(inputRef.current){
      inputRef.current.focus();
    }
    
  };
  const handleOptionClick = (option) => {
    const newValue = option ? option.label : '';
    if (newValue !== value) {
        setValue(newValue);
        filtersDropdownChngHndlr(option, id);
        setShowDropdown(false);
        setSelectedvalue(newValue)
    }
};

  useEffect(()=> {
    const filteredData = options.filter((option) =>
    option.label.toLowerCase().includes(searchTerm.toLowerCase())
  );
  setFilteredOptions(filteredData);
  },[searchTerm])

  const handleClickOutside = (e) => {
    if (selectItemRef.current && !selectItemRef.current.contains(e.target)) {
      executeInputBlur.current=true;
      setShowDropdown(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside, true);
    return () => document.removeEventListener("mousedown", handleClickOutside, true);
  }, []);

  const handleInputBlur = () => {
    inputBlurTimeoutRef.current = setTimeout(()=>{
      if(executeInputBlur.current) onInputBlur();
    },300)
  }
  // const handleScroll = () => {
  //   const element = document.getElementById("options-container");
  // }
  useEffect(() => {

      if(showDropdown) {
        window.addEventListener("keydown", downHandler);
        // document.getElementById("options-container").addEventListener("scroll", handleScroll);
      } else window.removeEventListener("keydown", downHandler);
  
    return () => {
        if(showDropdown){
          window.removeEventListener("keydown", downHandler);
        }
        // if (document.getElementById("options-container")){
        //   document.getElementById("options-container").removeEventListener("scroll", handleScroll);
        // }
    };
  });
  const onClickHandler = () => {
    if(value){
      setSearchTerm(value)
    }
    if(!selectedValue){
    setTimeout(() => setFilteredOptions(options),300)
    }
    setShowDropdown((prev) => !prev)
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
       
       if(inputRef.current && (inputRef.current.value).toString().toUpperCase() !== selectedValue.toString().toUpperCase() ){
        const matchedOption = findMatchedOption(options, value);
        if(matchedOption.label){
          filtersDropdownChngHndlr(matchedOption,id)
          setSelectedvalue(value)
        }
      }
      setShowDropdown(false)
    }
  }
  
  return (
    <div 
    ref={selectItemRef} 
    >
      <Row>
        <Col xs={12}>
          <div className="select-option-wrapper">
            <div className="d-flex" 
            >
              <input
                ref={inputRef}
                id={`search-item`}
                type="text"
                value={value}
               
                placeholder="Select..."
                className={`form-control py-0`}
                onClick={onClickHandler}
                onChange={handleInputChange}
                onKeyDown={handleKeyDown}
                onBlur={handleInputBlur}
                style={{border:' 1px solid #D3D3D3',borderRadius:'0.3rem'}}
              />
              {/* <span className="align-self-center" style={{borderRight:'2px solid #D3D3D3',height:'1rem',}}></span> */}
              <Button
                  color="light"
                  onClick={handleDropdownClick}
                  className={`py-0`}
                >
                  <i style={{ color:'black',fontWeight:'bold',fontSize:'12px'}} className="ri-arrow-down-s-line"></i>
                </Button>
              
            </div>
          
           {filteredOptions.length > 0 && showDropdown && (
                <div
                  className="dropdown-container mt-2"
                  style={{
                    position: 'absolute',
                    top: '100%',
                    left: '0',
                    width: '100%',
                    border: '1px solid #D3D3D3',
                    borderRadius: '0.3rem',
                    maxHeight: '300px',
                    overflowY: 'auto', 
                    overscrollBehavior:'contain',
        
                  }}
                >
           
                  {filteredOptions.map((item, index) => {
                    
                    const focusColor = (item.label.toUpperCase() === value.toUpperCase()) || filteredOptions.length=== 1 ? {
                      backgroundColor : '#007BFF',
                      color : '#ffffff'
                    } :'';
                
                    return  <div
                          tabIndex={"0"}
                          className={`dropdown-option cursor-pointer px-2 py-1 item ${
                            "item-" + (index + 1) === focusedElement.id ? "active" : ""
                          }`}
                            key={index}
                            id={`item-${index+1}`}
                            onClick={() => handleOptionClick(item)}
                            style={{ ...focusColor }}

                            onMouseOver={(e) => e.currentTarget.classList.add("hovered")}
                            onMouseOut={(e) => e.currentTarget.classList.remove("hovered")}
                          >
                            {item.label}
                          </div>
                  })}
                </div>
          
                  )}
                  { searchTerm && filteredOptions.length === 0 && (
                    <div
                    className="dropdown-container mt-2"
                        style={{
                          position: 'absolute',
                          top: '100%',
                          left: '0',
                          width: '100%',
                          border: '1px solid #D3D3D3',
                          borderRadius: '0.3rem',
                          maxHeight: '300px',
                          overflowY: 'auto', 
                          overscrollBehavior:'contain',
              
                        }}
                    >
                          <div className="dropdown-option px-2 py-1" style={{ textAlign:'center'}}>No options</div>
                    </div>
                  )
                  }
          
          </div>
        </Col>
      </Row>
    </div>
  );
}