import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Input, Table, Button } from "reactstrap";

const useActiveElement = () => {
  const [active, setActive] = useState(document.activeElement);

  const handleFocusIn = (e) => {
    setActive(document.activeElement);
    //   if(!document.activeElement.classList.contains("item")) setTempQty("");
  };

  useEffect(() => {
    document.addEventListener("focusin", handleFocusIn);
    return () => {
      document.removeEventListener("focusin", handleFocusIn);
    };
  }, []);

  return active;
};

export default function SelectItem({
//  onRef = '',
  autoFocus = false,
  listOptions,
  setSearchListOptions = () => {},
  columnsList,
  placeholder,
  selectedItemHandler = () => {},
  fieldKey,
  searchValue,
  setSearchValue,
  searchValueChngHndlr,
  dataIdKey,
  containerClassName,
  hideListOnBlur,
  disabled = false,
  showDropdownBtn = false,
  addF3Shortcut = true,
  dropdownBtnClickHndlr,
  callbackAfterBottomReached,
  onDoubleClick=()=>{},
  // onKeyDown = () => {},
  inputClassname="",
  btnClassName= "",
  onInputBlur=()=>{},
  hideDropdownBtnFromUi=false,
  boldText
}) {
  const [showOptions, setShowOptions] = useState(false);
  const inputRef = useRef(null);
  const focusedElement = useActiveElement();
  const bottomReached = useRef(false);
  const selectItemRef = useRef(null);
  const isDropdownBtnClicked = useRef(false);
  const executeInputBlur = useRef(false);
  // const [searchValue, setSearchValue] = useState("");
  // const [availableOptions, setAvailableOptions] = useState(listOptions);
  function onChangeHandler(event) {
    const { name, value } = event.target;
    if(!value && selectedItemHandler){
      selectedItemHandler('');
    }
    if (!searchValueChngHndlr) setSearchValue(value);
    else searchValueChngHndlr(value);
  }
  function onClickRowHandler(event) {
    if(inputBlurTimeoutRef.current)
     clearTimeout(inputBlurTimeoutRef.current);
    const rowId = event.currentTarget.getAttribute("data-id");
    const selectedRowData = listOptions.find((item) => item[dataIdKey] === rowId);
    if (selectedRowData) {
      // const { company } = selectedRowData;
      if (!searchValueChngHndlr) setSearchValue(selectedRowData[fieldKey]);
      else searchValueChngHndlr(selectedRowData[fieldKey]);
      selectedItemHandler(selectedRowData);
    }
    setShowOptions(false);
  }
  // useEffect(()=>{
  //   if(!searchValue){
  //     selectedItemHandler('');
  //   }
  // },[searchValue])

  function downHandler(e) {
    const { key } = e;
    const active = document.activeElement;
    executeInputBlur.current = false;
    if (key === "ArrowUp") {
      e.preventDefault();
      if (active.classList.contains("item")) {
        if (active.previousSibling) active.previousSibling.focus();
      } else {
        const firstItem = document.getElementById("item-1");
        if (firstItem) firstItem.focus();
      }
    } else if (key === "ArrowDown") {
      e.preventDefault();
      if (active.classList.contains("item")) {
        if (active.nextSibling) active.nextSibling.focus();
      } else {
        const firstItem = document.getElementById("item-1");
        if (firstItem) firstItem.focus();
      }
    } else if (key === "Enter") {
      if(focusedElement.id.startsWith("item-")) {
        selectedItemHandler({ ...listOptions[focusedElement.id.split("-")[1] - 1] });
        setShowOptions(false);
      }
    }
  }

  function f3Handler(e) {
    const { key } = e;
    if(key == "F3") {
      e.preventDefault();
      if(!showOptions) {
        dropdownBtnClickHndlr(setShowOptions);
        inputRef.current.focus();
       }
      else setShowOptions(false)
    }
  }

  function handleScroll() {
    const element = document.getElementById("scroll-table-data");
    const bottom = element.scrollHeight - element.scrollTop < element.clientHeight + 100;
    if (bottom && !bottomReached.current) {
      bottomReached.current = true;
      callbackAfterBottomReached(() => (bottomReached.current = false));
    }
  }

  useEffect(() => {
    if(showDropdownBtn) {
      if(addF3Shortcut) window.addEventListener("keydown", f3Handler);
      if(showOptions) {
        window.addEventListener("keydown", downHandler);
        if(document.getElementById("scroll-table-data")){
          document.getElementById("scroll-table-data").addEventListener("scroll", handleScroll);
        }
      } else  window.removeEventListener("keydown", downHandler);
    }  
    return () => {
      if (showDropdownBtn) {
        window.removeEventListener("keydown", downHandler);
        if(addF3Shortcut) window.removeEventListener("keydown", f3Handler);
        if (document.getElementById("scroll-table-data"))
          document.getElementById("scroll-table-data").removeEventListener("scroll", handleScroll);
      }
    };
  });

  const handleClickOutside = (e) => {
    if (selectItemRef.current && !selectItemRef.current.contains(e.target)) {
      executeInputBlur.current=true;
      setShowOptions(false);
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside, true);
    return () => document.removeEventListener("mousedown", handleClickOutside, true);
  }, []);

  useEffect(() => {
    if (!showOptions) {
      setSearchListOptions([]);
      isDropdownBtnClicked.current = false;
    }
  }, [showOptions]);

  const handleDropdownClick = () => {
    if(!isDropdownBtnClicked.current) {
      dropdownBtnClickHndlr(setShowOptions);
      inputRef.current.focus();
      isDropdownBtnClicked.current = true;
    }
    else {
      isDropdownBtnClicked.current = false;
      setShowOptions(false);
    }
  }

  const inputBlurTimeoutRef = useRef(null);

  const handleInputBlur = () => {
    inputBlurTimeoutRef.current = setTimeout(()=>{
      if(executeInputBlur.current) onInputBlur();
    },300)
  }

  return (
    <div ref={selectItemRef} className={`${containerClassName}`}>
      <Row>
        <Col xs={12}>
          <div className="select-item-wrapper">
            <div className="d-flex">
              <input
                id="search-item"
                type="text"
                className={`form-control ${inputClassname} py-0`}
                onChange={onChangeHandler}
                onDoubleClick={onDoubleClick}
                value={searchValue}
                placeholder={placeholder}
                autoComplete="off"
                onFocus={() => setShowOptions(true)}
                onBlur={handleInputBlur}
                disabled={disabled}
                ref={inputRef}
                // onKeyDown={onKeyDown}
                style={{...boldText,zIndex:0}}
                autoFocus={autoFocus}
              />
              {showDropdownBtn && !hideDropdownBtnFromUi && (
                <Button
                  color="light"
                  onClick={handleDropdownClick}
                  className={`py-0 ${btnClassName}`}
                >
                  <i className="ri-arrow-down-s-fill"></i>
                </Button>
              )}
            </div>
            <div id="options-container" className="options-container mt-2" >
              {showOptions && listOptions.length > 0 && (
                <div id="scroll-table-data" className="table-responsive" style={{maxHeight:'400px',overflow:'auto'}}>
                  <Table className="table-sm table-nowrap table-hover mb-0">
                    <thead className={`wh-table-head`}>
                      <tr className={'wh-table-border'}>
                        {columnsList.map((item) => (
                          <th key={item.id} scope="col"
                            className={`wh-table-border ${item.id === 'title'?'text-center':''}`}
                          >
                            {item.name}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {listOptions.map((item, index) => (
                        <tr
                          tabIndex={"0"}
                          className={`cursor-pointer item ${
                            "item-" + (index + 1) === focusedElement.id ? "active" : ""
                          }`}
                          id={`item-${index + 1}`}
                          data-id={item[dataIdKey]}
                          onClick={onClickRowHandler}
                          key={item[dataIdKey]}
                        >
                          {columnsList.map((it) => (
                            <td key={it.id} className={it.cellClassName}>
                              {item[it.id]}
                            </td>
                          ))}
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                
                </div>
              )}
                {/* {showOptions && searchValue && Array.isArray(listOptions) && listOptions.length === 0 && (
                        <div className=" text-muted text-center border p-1 shadow-lg">No Items Found</div>
                      )} */}
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
}
