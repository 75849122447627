import React, { useMemo, useState, useEffect, useCallback, useRef } from "react";
import moment from "moment";
import { Container, Row, Col, Label, Input, Button, Modal, ModalHeader, Form, ModalBody } from "reactstrap";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import {toast} from "react-toastify";
import { actions, generateColumns, tableData } from "./helper";
import BackIcon from "../../../../assets/images/warehouse/menu/back.png";
import WhTableContainer from "../../../../Components/Common/WhTables";
import { getStartDateFilterValue, getEndDateFilterValue } from "../../../../utility/dateutility";
//Api Helper
import { generateApiUrl } from "../../../../api/apihelper";
//Actions
import { getInvPricingReportPrintAct, getSalesHighestAct } from "../../../../store/reports/reportsactions";
import F12CloseModalListener from "../../../../Components/Common/F12close";
import PrintItem from "./PrintItem";

const SalesByHighestOrder = ({ history }) => {
  const [showPrintModal, setShowPrintModal] = useState(false);
  const [filters, setFilters] = useState({
    startDate:moment().format("YYYY-MM-DD"),
    endDate:moment().format("YYYY-MM-DD"),
    pageSize:5
  });
  const [tableData, setTableData] = useState([]);
  function togglePrintModal() {
    setShowPrintModal(!showPrintModal);
  }
  const dispatch = useDispatch();
  const isDownloadBtnClicked = useRef(false);
  const currentPageSize = useRef(30);
  const {
    getSalesHighestResp,
    getPaymentsReportLoading,
    reportsErrorResp,
  } = useSelector(
    ({ reports }) => ({
      getSalesHighestResp: reports.getSalesHighestResp,
      getPaymentsReportLoading: reports.getPaymentsReportLoading,
      reportsErrorResp: reports.reportsErrorResp,
    }),
    shallowEqual
  );
  const customerResp = useRef({
    prevGetSalesHighestResp: getSalesHighestResp,
    prevReportsErrorResp: reportsErrorResp,
  });
  useEffect(() => {
    const {
      prevGetSalesHighestResp,
      prevReportsErrorResp
    } = customerResp.current || {};
    if (getSalesHighestResp && prevGetSalesHighestResp !== getSalesHighestResp) {
      if(isDownloadBtnClicked.current) {
        const url = window.URL.createObjectURL(new Blob([getSalesHighestResp]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
         "sales_highest.csv"
        );
        document.body.appendChild(link);
        link.click();
        link.remove();
        isDownloadBtnClicked.current = false;
      }
      else {
        setTableData(getSalesHighestResp?.data?.report || []);
        currentPageSize.current = getSalesHighestResp?.data?.pageSize;
      }
    }
    else if (reportsErrorResp && prevReportsErrorResp !== reportsErrorResp) {
      const { errors, error, message } = reportsErrorResp || {};
      toast.error((Array.isArray(errors) && errors[0].msg) || error || message);
    }
    customerResp.current.prevGetSalesHighestResp = getSalesHighestResp;
    customerResp.current.prevReportsErrorResp = reportsErrorResp;
  }, [ getSalesHighestResp, reportsErrorResp ]);

  const getSuppliersReportApiCall = useCallback(
    (page, pageSize, download,  downloadType = 'pdf') => {
      const {  startDate, endDate, pageSize : selectTop } = filters;
      let params = {};
      if(!download) {
        params.page = page ? page + 1 : 1;
        params.pageSize = pageSize || (currentPageSize.current);
      }
      if(startDate) params.startDate = getStartDateFilterValue(startDate);
      if(endDate) params.endDate = getEndDateFilterValue(endDate);
      if(selectTop) params.pageSize = selectTop;
      if (download && downloadType === 'html') {
        params.download = true;
        params.downloadType = downloadType;
        dispatch(
          getInvPricingReportPrintAct(
            generateApiUrl(`get_sales_highest`),
            params
          )
        );
      } 
      else if (download){
        params.download = true;
        isDownloadBtnClicked.current = true; 
        dispatch(
          getSalesHighestAct(
            generateApiUrl(`get_sales_highest`),
            params,
            {responseType: 'blob'}
          )
        );}
        else {
          dispatch(
            getSalesHighestAct(
              generateApiUrl(`get_sales_highest`),
              params
            )
          );
        }
      },
    [filters]
  );

  useEffect(() => {
    const debounceFn = setTimeout(() => {
      getSuppliersReportApiCall();
    }, 400);
    return () => {
      clearTimeout(debounceFn);
    };
  }, [getSuppliersReportApiCall]);

  const handlePageChange = (page, pageSize) => {
    getSuppliersReportApiCall(page, pageSize);
  };

  const filtersChngHndlr = (e) => {
    const {value, checked, name, type} = e.target;
    setFilters(prev => ({...prev, [name] : type === "checkbox" ? checked : value}));
  }

  const resetFilters = () => {
    setFilters({
      startDate:moment().format("YYYY-MM-DD"),
      endDate:moment().format("YYYY-MM-DD"),
      pageSize:5
    })
  }

  const columns = useMemo(() => generateColumns(), []);

  const handleMainFilterChange = e => {
    const {id} = e.target;
    switch (id) {
      case "payment-history":
        return history.push("/paymentandsalesreport/paymenthistory");
      case "sales-history":
        return history.push("/paymentandsalesreport/saleshistory");
      default:
        return null;
    }
  }
  return (
   <>
    <Container fluid className=" pt-3 pb-0">
      <Row>
        <Col xs="12" className="mb-1">
        <div className="px-3 mb-3 pt-1 border rounded-1 report-header" style={{display:"inline-block"}}>
          <h3 style={{color:'#fff'}}>Sales History Report</h3>
         </div>
          <div className="border ps-4 my-1" style={{ position: "relative" }}>
            <Label className="text-dark fs-5" style={{ position: "absolute", transform: "translateY(-50%)" }}>
            <span className=" px-1 wh-item-bg"> Filters</span>
            </Label>
            <Row>
              <Col xs="6" xxl="7" className="pe-5">
                <Row className="my-3">
                  <Col xs={4}>
                    <div className="form-check my-1">
                      <Input className="form-check-input" type="checkbox" id="payment-history"  onChange={handleMainFilterChange} />
                      <Label className="form-check-label text-nowrap" htmlFor="payment-history">
                        Payment History
                      </Label>
                    </div>

                    <div className="form-check my-1">
                      <Input
                        className="form-check-input"
                        type="checkbox"
                        id="sales-history"
                        onChange={handleMainFilterChange} 
                      />
                      <Label className="form-check-label text-nowrap" htmlFor="sales-history" >
                        Sales History
                      </Label>
                    </div>

                    {/* <div className="form-check my-1">
                      <Input className="form-check-input" type="checkbox" id="cashback-award" disabled />
                      <Label className="form-check-label text-nowrap" htmlFor="cashback-award">
                        Cashback Award
                      </Label>
                    </div> */}

                    <div className="form-check my-1">
                      <Input
                        className="form-check-input"
                        type="checkbox"
                        id="sales-by-highest-order"
                        checked
                      />
                      <Label className="form-check-label text-nowrap" htmlFor="sales-by-highest-order">
                        Sales by highest Order
                      </Label>
                    </div>
                  </Col>
                  <Col xs={4}>
                    <Row className="my-1">
                      <Col xs="12">
                        <Label className="mb-1">Date From</Label>
                        <Input type="date" className="form-control py-1" name="startDate" value={filters.startDate} onChange={filtersChngHndlr} />
                      </Col>
                    </Row>
                    <Row className="mb-1">
                      <Col xs="12">
                        <Label className="mb-1">Date To</Label>
                        <Input type="date" className="form-control py-1" name="endDate" value={filters.endDate} onChange={filtersChngHndlr} />
                      </Col>
                    </Row>
                    <Row className="mb-1">
                      <Col xs="12">
                        <Label className="mb-1">Select Top</Label>
                        <select className="form-select py-1" name="pageSize" value={filters.pageSize} onChange={filtersChngHndlr}>
                          <option>5</option>
                          <option>10</option>
                          <option>15</option>
                          <option>20</option>
                          <option>25</option>
                          <option>30</option>
                          <option>35</option>
                          <option>40</option>
                          <option>45</option>
                          <option>50</option>
                        </select>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
              <Col xs="2"></Col>
              <Col xs="4" xxl="3" className="d-flex flex-column justify-content-center pe-xxl-5 pe-4">
                <div className="d-flex align-items-center w-100 mb-1">
                  <div className="sale-wh-wrapper flex-grow-1 mt-0 me-xxl-4 me-2">
                    <div className="d-flex justify-content-around border border-section py-2 h-100">
                      {actions(togglePrintModal,getSuppliersReportApiCall,resetFilters).map((item) => {
                        return (
                          <div
                            key={item.id}
                            className="action-icon-container"
                            onClick={item.clickHandler || (() => {})}
                            style={{cursor:"pointer"}}
                          >
                            <img className="action-icon" src={item.icon} />
                            <span>{item.label}</span>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <div
                    className="d-flex flex-column align-items-center"
                    style={{ cursor: "pointer" }}
                    onClick={() => history.push("/reportsmenu")}
                  >
                    <img src={BackIcon} width="32" height="32" alt="back-icon" />
                    <div className="text-decoration-underline mb-0">Go Back</div>
                  </div>
                </div>
                <div className="d-flex justify-content-start">
                  <Button color="light" className="border border-dark me-2 text-nowrap wh-btn">Customer Balance</Button>
                  <Button color="light" className="border border-dark text-nowrap wh-btn">Balance Sheet</Button>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
        <Col xs="12">
          <WhTableContainer
            columns={columns}
            data={tableData || []}
            className="custom-header-css"
            divClass="table-responsive table-container-sales-highest-order"
            tableClass="align-middle table-bordered"
            theadClass="table-light"
            handlePageChange={handlePageChange}
            pageCount={Math.ceil((getSalesHighestResp?.data?.total || 0) / 10)}
            customPageSize={30}
            showNoDataComponent={true}
          />
        </Col>
      </Row>
    </Container>
    <Modal
     id="showPrintModal"
     isOpen={showPrintModal}
     toggle={togglePrintModal}
     centered
     size="lg"
   >
     <F12CloseModalListener onClose={togglePrintModal} />
     <ModalHeader className="py-2 px-3" toggle={togglePrintModal}>
       Print Options
     </ModalHeader>
     <Form
       onSubmit={(e) => {
         e.preventDefault();
       }}
     >
       <ModalBody className="px-0 pt-0">
         <PrintItem
           getSuppliersReportApiCall={getSuppliersReportApiCall}
           filters={filters}
           showPrintModal={showPrintModal}
         />
       </ModalBody>
     </Form>
   </Modal>
    </>
  );
};

export default SalesByHighestOrder;
