import React, { useMemo, useState, useRef, useEffect, useCallback, useContext } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { useParams } from "react-router";
import { useLocation } from "react-router-dom";
import {
  Col,
  Container,
  Input,
  Label,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { actions, tabs, mapInventoryDetails, generatePayload, inventoryItemColumnsList } from "./helper";
import classnames from "classnames";
import SelectItem from "../../Components/Common/SelectItem";
import DeleteModal from "../../Components/Common/DeleteModal";
import QtyAdjustment from "./QtyAdjustment";
import MultiQtyAdjustment from "./MultiQtyAdjustment";
import AdjustmentHistory from "./AdjustmentHistory";
import RouteChangeConfirmation from "../../Components/Common/RouteChangeConfirmation";
import PaymentsAnywhere from "../../Components/Common/paymentsanywhere";
import ApiLoader from "../../Components/Common/ApiLoader";
// Formik validation
import { useFormik } from "formik";
import { inventoryDetailsInitialValues, inventoryDetailsValidationSchema } from "./tabs/Details/helper";
import { inventoryFinancialInitialValues, inventoryFinancialValidationSchema } from "./tabs/Financial/helper";
import { DetailsTab, FinancialTab, SalesTab, SuppliersTab, PurchasesTab, FeaturesTab } from "./tabs";
//Api Helper
import { generateApiUrl } from "../../api/apihelper";
//Actions
import {
  getInventoryAllMasterDataAct,
  createInventoryItemAct,
  getInventoryItemAct,
  getInventoryListAct,
  updateInventoryItemAct,
  deleteInventoryItemAct,
  getMaxInventoryNumAct,
  getAdjustmentHistoryOfItemAct,
  deleteBundleItemAct,
  getBundleItemAct,
} from "../../store/inventory/inventoryactions";
import { getMsaReportAsAct, getStateReportAsAct } from "../../store/masterdata/masterdataactions";
//Utility
import { getCustomDateFormatted } from "../../utility/dateutility";
//Context
import { PermissionsContext } from "../../context/PermissionsContext";
//HOC
import withPermission from "../../hoc/withPermission"
import F12CloseModalListener from "../../Components/Common/F12close";
import Draggable from "react-draggable";
import CreateBundle from "./CreateBundle";
import Bundles from "./Bundles";

function getActiveComponent(activeId) {
  switch (activeId) {
    case "details":
      return DetailsTab;
    // case "financial":
    //   return FinancialTab;
    case "sales":
      return SalesTab;
    case "suppliers":
      return SuppliersTab;
    case "purchases":
      return PurchasesTab;
    case "features":
      return FeaturesTab;
    default:
      return null;
  }
}
function getActiveTabId(history, changeToInitialTab) {
  const activeTab = JSON.parse(localStorage.getItem("activeTab") || "{}");
  let activeTabId = "";
  if (activeTab.warehouseinventory && history.action === "POP") {
    activeTabId = activeTab.warehouseinventory;
    delete activeTab.warehouseinventory;
    localStorage.setItem("activeTab", JSON.stringify(activeTab));
    changeToInitialTab.current = false;
  } else activeTabId = "details";
  return activeTabId;
}

function getRouteChngSaveDetailsHndlr(
  id,
  justifyPillsTab,
  inventoryDetailsValidation,
  // inventoryFinancialValidation,
  isRouteChangeApiCalled
) {
  isRouteChangeApiCalled.current = true;
  if (id === "new") {
    inventoryDetailsValidation.handleSubmit();
    // inventoryFinancialValidation.handleSubmit();
  } else {
    if (justifyPillsTab === "details") return inventoryDetailsValidation.handleSubmit();
    // else if (justifyPillsTab === "financial") return inventoryFinancialValidation.handleSubmit();
  }
}

function getRouteChngApiResp(id, createInventoryItemResp, updateInventoryItemResp) {
  if (id === "new") return createInventoryItemResp;
  else return updateInventoryItemResp;
}

function getFormValidation(id, justifyPillsTab, inventoryDetailsValidation) {
  if (id === "new") return [inventoryDetailsValidation];
  else {
    if (justifyPillsTab === "details") return inventoryDetailsValidation;
    // else if (justifyPillsTab === "financial") return inventoryFinancialValidation;
  }
}

function WarehouseInventory({ history }) {
  const parentRef = useRef(null);
  const [resetBundle, setResetBundle] = useState(false);
  const [rowSelectedIds, setRowSelectedIds] = useState([]);
  const [columnsList,setColumnsList] = useState([])
  const {permissions} = useContext(PermissionsContext);
  const [isBlocked, setIsBlocked] = useState(false);
  const [search, setSearch] = useState("");
  const [upcSearch, setUpcSearch] = useState("");
  const [searchListOptions, setSearchListOptions] = useState([]);
  const isSearchUpc = useRef(false);
  const { id } = useParams();
  const changeToInitialTab = useRef(true);
  const [justifyPillsTab, setjustifyPillsTab] = useState(getActiveTabId(history, changeToInitialTab));
  const justifyPillsToggle = (tab) => {
    if (justifyPillsTab !== tab) {
      setjustifyPillsTab(tab);
    }
  };
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showQtyAdjustmentModal, setShowQtyAdjustmentModal] = useState(false);
  const [showMultiQtyAdjustmentModal, setShowMultiQtyAdjustmentModal] = useState(false);
  const [showAdjusmentHistoryModal, setShowAdjusmentHistoryModal] = useState(false);
  const toggleDeleteModal = () => setShowDeleteModal(!showDeleteModal);
  const toggleQtyAdjustmentModal = () => setShowQtyAdjustmentModal(!showQtyAdjustmentModal);
  const toggleMultiQtyAdjustmentModal = () => setShowMultiQtyAdjustmentModal(!showMultiQtyAdjustmentModal);
  const toggleAdjustmentHistoryModal = () => setShowAdjusmentHistoryModal(!showAdjusmentHistoryModal);
  const inventoryDetailsValidation = useFormik({
    enableReinitialize: true,
    initialValues: inventoryDetailsInitialValues,
    validationSchema: inventoryDetailsValidationSchema,
    onSubmit: (values) => {
      if(Number(inventoryDetailsValidation.values.cost) > Number(inventoryDetailsValidation.values.retail)){
        return toast.error('Please set the reatil price greater than cost price'); 
      }
      if (id === "new")
        dispatch(
          createInventoryItemAct(
            generateApiUrl("create_inventory_item"),
            generatePayload(inventoryDetailsValidation.values, values)
          )
        );
      else
        dispatch(
          updateInventoryItemAct(
            generateApiUrl("update_inventory_item", { itemNumber: id }),
            generatePayload(inventoryDetailsValidation.values, values)
          )
        );
    },
  });
 
  const TabComponent = useMemo(() => getActiveComponent(justifyPillsTab) || <></>, [justifyPillsTab]);

  //Change isBlocked to true whenever user changes the customer info
  const isRouteChangeApiCalled = useRef(false);
  const initialValidationValues = useRef({
    details: inventoryDetailsInitialValues,
  });

  useEffect(() => {
    const { details} = initialValidationValues.current;
    console.log(initialValidationValues.current);
    if (JSON.stringify(details) !== JSON.stringify(inventoryDetailsValidation.values)) setIsBlocked(true);
    else setIsBlocked(false);
    isRouteChangeApiCalled.current = false;
  }, [inventoryDetailsValidation.values]);

  const dispatch = useDispatch();

  const currentInventoryListPage = useRef(1);
  const dropdownBtnClicked = useRef(false);
  const callbackOnInventoryListApiResp = useRef(() => {});

  const isCreateInventoryBtnClicked = useRef(false);

  const {
    createInventoryItemResp,
    getInventoryAllMasterDataResp,
    getInventoryItemResp,
    // getBundleItemResp,
    getInventoryListResp,
    // getInventoryBundlesListResp,
    updateInventoryItemResp,
    deleteInventoryItemResp,
    getMaxInventoryNumResp,
    getAdjustmentHistoryOfItemResp,
    createQtyAdjustmentResp,
    createMultiQtyAdjustmentResp,
    getSpecialPricesResp,
    getAllCustomersResp,
    createCustomerInventoryPriceResp,
    deleteSpecialPriceForCustomerResp,
    getMsaReportAsResp,
    getStateReportAsResp,
    inventoryErrorResp,
    masterDataErrorResp,
    inventoryApiLoading
  } = useSelector(
    ({ inventory, customer, masterData }) => ({
      createInventoryItemResp: inventory.createInventoryItemResp,
      getInventoryAllMasterDataResp: inventory.getInventoryAllMasterDataResp,
      getInventoryItemResp: inventory.getInventoryItemResp,
      // getBundleItemResp: inventory.getBundleItemResp,
      getInventoryListResp: inventory.getInventoryListResp,
      // getInventoryBundlesListResp: inventory.getInventoryBundlesListResp,
      updateInventoryItemResp: inventory.updateInventoryItemResp,
      deleteInventoryItemResp: inventory.deleteInventoryItemResp,
      getMaxInventoryNumResp: inventory.getMaxInventoryNumResp,
      getAdjustmentHistoryOfItemResp: inventory.getAdjustmentHistoryOfItemResp,
      createQtyAdjustmentResp: inventory.createQtyAdjustmentResp,
      createMultiQtyAdjustmentResp: inventory.createMultiQtyAdjustmentResp,
      getSpecialPricesResp: inventory.getSpecialPricesResp,
      getAllCustomersResp: customer.getAllCustomersResp,
      createCustomerInventoryPriceResp: inventory.createCustomerInventoryPriceResp,
      deleteSpecialPriceForCustomerResp: customer.deleteSpecialPriceForCustomerResp,
      getMsaReportAsResp: masterData.getMsaReportAsResp,
      getStateReportAsResp: masterData.getStateReportAsResp,
      inventoryErrorResp: inventory.inventoryErrorResp,
      masterDataErrorResp: masterData.masterDataErrorResp,
      inventoryApiLoading: inventory.inventoryApiLoading
    }),
    shallowEqual
  );
  const inventoryResp = useRef({
    prevCreateInventoryItemResp: createInventoryItemResp,
    prevGetInventoryAllMasterDataResp: getInventoryAllMasterDataResp,
    prevGetInventoryItemResp: getInventoryItemResp,
    // prevGetBundleItemResp: getBundleItemResp,
    prevGetInventoryListResp: getInventoryListResp,
    // prevGetInventoryBundlesListResp: getInventoryBundlesListResp,
    prevUpdateInventoryItemResp: updateInventoryItemResp,
    prevDeleteInventoryItemResp: deleteInventoryItemResp,
    prevCreateQtyAdjustmentResp: createQtyAdjustmentResp,
    prevCreateMultiQtyAdjustmentResp: createMultiQtyAdjustmentResp,
    prevGetMsaReportAsResp: getMsaReportAsResp,
    prevGetStateReportAsResp: getStateReportAsResp,
    prevInventoryErrorResp: inventoryErrorResp,
    prevMasterDataErrorResp: masterDataErrorResp,
  });
  const [dropdownState, setDropdownState] = useState({
    category: [],
    subCategory: [],
    family: [],
    manufacturer: [],
    model: [],
    priceUser: [],
    groupCode: [],
    msaReportAs: [],
    stateReportAs: [],
    customCategory: "",
    customSubCategory: "",
    customFamily: "",
    customManufacturer: "",
    customModel: "",
    customGroupCode: "",
    modalCustomField: "",
  });
  useEffect(() => {
    const {
      prevCreateInventoryItemResp,
      prevGetInventoryAllMasterDataResp,
      prevGetInventoryItemResp,
      // prevGetBundleItemResp,
      prevGetInventoryListResp,
      prevUpdateInventoryItemResp,
      prevDeleteInventoryItemResp,
      prevCreateQtyAdjustmentResp,
      prevCreateMultiQtyAdjustmentResp,
      prevGetMsaReportAsResp,
      prevGetStateReportAsResp,
      prevInventoryErrorResp,
      prevMasterDataErrorResp,
    } = inventoryResp.current || {};
    if (createInventoryItemResp && prevCreateInventoryItemResp !== createInventoryItemResp) {
      if (!isRouteChangeApiCalled.current) {
        isCreateInventoryBtnClicked.current = true;
        setIsBlocked(false);
      } else isRouteChangeApiCalled.current = false;
    }
    if (getInventoryAllMasterDataResp && prevGetInventoryAllMasterDataResp !== getInventoryAllMasterDataResp) {
      const {
        data: {
          lists: { category, subCategory, family, manufacturer, model, priceUser, groupCode },
        },
      } = getInventoryAllMasterDataResp;
      setDropdownState((prev) => ({
        ...prev,
        category: category.map((item) => ({ value: item, label: item })),
        subCategory: subCategory.map((item) => ({ value: item, label: item })),
        family: family.map((item) => ({ value: item, label: item })),
        manufacturer: manufacturer.map((item) => ({ value: item, label: item })),
        model: model.map((item) => ({ value: item, label: item })),
        priceUser: priceUser.map((item) => ({ value: item, label: item })),
        groupCode: groupCode.map((item) => ({ value: item, label: item })),
      }));
    }
    if (getInventoryItemResp && prevGetInventoryItemResp !== getInventoryItemResp) {
      const {
        data: {
          inventory: { description },
        },
      } = getInventoryItemResp;
      setSearch(`${description || ""} ${description ? "-" : ""}${id || ""}`);
      mapInventoryDetails(
        getInventoryItemResp,
        inventoryDetailsValidation.setValues,
        initialValidationValues
      );
      if (changeToInitialTab.current && history.action !== "POP") justifyPillsToggle("details");
      setIsBlocked(false);
    }
    // if (getBundleItemResp && prevGetBundleItemResp !== getBundleItemResp) {
    //   setBundleData(getBundleItemResp)
    // }
    if (getInventoryListResp && prevGetInventoryListResp !== getInventoryListResp && !showMultiQtyAdjustmentModal) {
      const list = [];
      (getInventoryListResp?.data?.inventories || []).forEach(({ itemNumber, description, category }) => {
        list.push({
          itemNumber,
          itemName: description,
          category,
        });
      });
      if (!dropdownBtnClicked.current) {
        if (isSearchUpc.current) {
          if (
            (getInventoryListResp?.data?.inventories || []).length === 1 &&
            getInventoryListResp.data.inventories[0].upcCode === upcSearch
          )
            history.push(`/warehouseinventory/${getInventoryListResp?.data?.inventories[0].itemNumber}`);
          else toast.error("Item not found with given UPC Code");
          isSearchUpc.current = false;
        } else {
          setSearchListOptions(list);
        }
      } else {
        setSearchListOptions((prev) => [...prev, ...list]);
        if (getInventoryListResp?.data?.inventories?.length > 0) callbackOnInventoryListApiResp.current();
        currentInventoryListPage.current = Number(getInventoryListResp?.data?.page) + 1;
      }
    }
    if (updateInventoryItemResp && prevUpdateInventoryItemResp !== updateInventoryItemResp) {
      toast.success("Successfully updated inventory item details.");
      if (!isRouteChangeApiCalled.current) {
        dispatch(getInventoryItemAct(generateApiUrl(`get_inventory_item`, { itemNumber: id })));
        changeToInitialTab.current = false;
      } else isRouteChangeApiCalled.current = false;
    }
    if (deleteInventoryItemResp && prevDeleteInventoryItemResp !== deleteInventoryItemResp) {
      history.push("/warehouseinventory", { showDeleteInventoryItemSuccess: true });
    }
    if (createQtyAdjustmentResp && prevCreateQtyAdjustmentResp !== createQtyAdjustmentResp) {
      toast.success("Successfully adjusted the quantity");
      getInventoryItemApiCall();
      toggleQtyAdjustmentModal();
    }
    if (createMultiQtyAdjustmentResp && prevCreateMultiQtyAdjustmentResp !== createMultiQtyAdjustmentResp) {
      toast.success("Successfully adjusted the quantities for multiple items");
      getInventoryItemApiCall();
      toggleMultiQtyAdjustmentModal();
    }
    if (getMsaReportAsResp && prevGetMsaReportAsResp !== getMsaReportAsResp) {
      setDropdownState((prev) => ({ ...prev, msaReportAs: getMsaReportAsResp?.data?.msa_categories || [] }));
    }
    if (getStateReportAsResp && prevGetStateReportAsResp !== getStateReportAsResp) {
      setDropdownState((prev) => ({ ...prev, stateReportAs: getStateReportAsResp?.data?.msa_state_categories || [] }));
    }
    if (inventoryErrorResp && prevInventoryErrorResp !== inventoryErrorResp) {
      const { errors, error, message } = inventoryErrorResp || {};
      toast.error((Array.isArray(errors) && errors[0] && errors[0].msg) || error || message);
      isCreateInventoryBtnClicked.current = false;
    }
    if (masterDataErrorResp && prevMasterDataErrorResp !== masterDataErrorResp) {
      const { errors, error, message } = masterDataErrorResp || {};
      toast.error((Array.isArray(errors) && errors[0] && errors[0].msg) || error || message);
    }
    inventoryResp.current.prevCreateInventoryItemResp = createInventoryItemResp;
    inventoryResp.current.prevGetInventoryAllMasterDataResp = getInventoryAllMasterDataResp;
    inventoryResp.current.prevGetInventoryItemResp = getInventoryItemResp;
    // inventoryResp.current.prevGetBundleItemResp = getBundleItemResp;
    inventoryResp.current.prevGetInventoryListResp = getInventoryListResp;
    // inventoryResp.current.prevGetInventoryBundlesListResp = getInventoryBundlesListResp;
    inventoryResp.current.prevUpdateInventoryItemResp = updateInventoryItemResp;
    inventoryResp.current.prevDeleteInventoryItemResp = deleteInventoryItemResp;
    inventoryResp.current.prevCreateQtyAdjustmentResp = createQtyAdjustmentResp;
    inventoryResp.current.prevCreateMultiQtyAdjustmentResp = createMultiQtyAdjustmentResp;
    inventoryResp.current.prevGetMsaReportAsResp = getMsaReportAsResp;
    inventoryResp.current.prevGetStateReportAsResp = getStateReportAsResp;
    inventoryResp.current.prevInventoryErrorResp = inventoryErrorResp;
    inventoryResp.current.prevMasterDataErrorResp = masterDataErrorResp;
  }, [
    createInventoryItemResp,
    getInventoryAllMasterDataResp,
    getInventoryItemResp,
    // getBundleItemResp,
    getInventoryListResp,
    // getInventoryBundlesListResp,
    updateInventoryItemResp,
    deleteInventoryItemResp,
    createQtyAdjustmentResp,
    createMultiQtyAdjustmentResp,
    getMsaReportAsResp,
    getStateReportAsResp,
    inventoryErrorResp,
    masterDataErrorResp,
  ]);

  useEffect(() => {
    if (!isBlocked && isCreateInventoryBtnClicked.current) {
      history.push("/warehouseinventory", { showCreateInventoryItemSuccess: true });
    }
  }, [isBlocked]);
  // useEffect(() => {
  //   dispatch(getInventoryAllMasterDataAct(generateApiUrl(`get_inventory_all_master_data`)));
  //   dispatch(getMsaReportAsAct(generateApiUrl(`get_msa_report_as`)));
  //   dispatch(getStateReportAsAct(generateApiUrl(`get_state_report_as`)));
  // }, []);
  const getInventoryItemApiCall = () =>
    dispatch(getInventoryItemAct(generateApiUrl(`get_inventory_item`, { itemNumber: id })));

  const getBundleItemApiCall = () =>
    dispatch(getBundleItemAct(generateApiUrl(`get_bundle_item`, { id: id })));

  const getAdjustmentHistoryOfItemApiCall = (page, pageSize) =>
    dispatch(
      getAdjustmentHistoryOfItemAct(generateApiUrl(`get_adjustment_history_of_item`, { itemNumber: id }), {
        page: page || 1,
        pageSize: pageSize || 10,
      })
    );
  useEffect(() => {
    if (id && id !== "new" && id !== "bundles-createbundle" && id !== "bundles" && (!path.startsWith("/warehouseinventory/bundles-createbundle/"))) {
      getInventoryItemApiCall();
      getAdjustmentHistoryOfItemApiCall();
      changeToInitialTab.current = true;
    }
    if (path.startsWith("/warehouseinventory/bundles-createbundle/")) {
      getBundleItemApiCall();
    }
    if (id && id === "new") {
      dispatch(getMaxInventoryNumAct(generateApiUrl(`get_max_inventory_num`)));
      justifyPillsToggle("details");
      setSearch("");
      setUpcSearch("");
      setSearchListOptions([])
    }
    if(id && id !== 'bundles'){
      setColumnsList(inventoryItemColumnsList);

    }
    if(id && id === 'bundles'){
      setSearch("");
      setUpcSearch("");
      setSearchListOptions([])
    }
    if(id !== "bundles-createbundle" && id !== "bundles" && (!path.startsWith("/warehouseinventory/bundles-createbundle/") )){
      dispatch(getInventoryAllMasterDataAct(generateApiUrl(`get_inventory_all_master_data`)));
      dispatch(getMsaReportAsAct(generateApiUrl(`get_msa_report_as`)));
      dispatch(getStateReportAsAct(generateApiUrl(`get_state_report_as`)));
    }
    inventoryDetailsValidation.resetForm();
    // inventoryFinancialValidation.resetForm();
  }, [id]);
  useEffect(() => {
    if (search === "") setSearchListOptions([]);
  }, [search]);
  const getInventoryListApiCall = useCallback(
    (showAllSuppliers, page) => {
      if( id !== 'bundles'){
        if (showAllSuppliers) {
          let params = {
            page: page || 1,
            pageSize: 50,
          };
          dispatch(getInventoryListAct(generateApiUrl(`get_inventory_list`), params));
          dropdownBtnClicked.current = true;
        } else if (search) {
          let params = {
            page: 1,
            pageSize: 50,
          };
          if (isNaN(Number(search))) params.description = search;
          else params.itemNumber = search;
          dispatch(getInventoryListAct(generateApiUrl(`get_inventory_list`), params));
          dropdownBtnClicked.current = false;
        }
      }
    },
    [search,id]
  );
  const handleBundleReset = () => {
    setResetBundle(true);
  };
  
  useEffect(() => {
    const debounceFn = setTimeout(() => {
      getInventoryListApiCall();
    }, 200);
    return () => {
      clearTimeout(debounceFn);
    };
  }, [getInventoryListApiCall]);
  function selectItemCallbck(itemDetails) {
    const { itemNumber } = itemDetails;
    if(!itemNumber){
      return history.push(`/warehouseinventory/${id}`);
    } else {
      history.push(`/warehouseinventory/${itemNumber}`);
    }
  }
  const handleDeleteInventoryItem = () => {

    if (path.startsWith("/warehouseinventory/bundles-createbundle/")) {
    dispatch(deleteBundleItemAct(generateApiUrl("delete_bundle" ),{ ids: [id] }));
    history.push("/warehouseinventory/bundles",{showDeleteBundleItemSuccess:true})  
    return;
    }

    if (id === "bundles") {
      dispatch(deleteBundleItemAct(generateApiUrl("delete_bundle" ),{ ids: [...rowSelectedIds] }));
      toggleDeleteModal()
    return;
    }

    if (!path.startsWith("/warehouseinventory/bundles") && id) {
      dispatch(deleteInventoryItemAct(generateApiUrl("delete_inventory_item"), { ids: [id] }));
    }
  };
  const adjustmentHistoryDetails = useMemo(() => {
    const { dateStamp, quantity, quantityAdj } = getAdjustmentHistoryOfItemResp?.data?.qtyAdg[0] || {};
    return {
      lastInventoryDate: dateStamp ? getCustomDateFormatted(dateStamp, "MM-DD-YYYY hh:mm:ss A") : "",
      quantity: quantity || "",
      quantityAdj: quantityAdj || "",
    };
  }, [getAdjustmentHistoryOfItemResp]);


  const getInventoryBundlesListApiCall = (showAllBundles,page) => {
    if(parentRef.current){
      if(showAllBundles){
        parentRef.current.getInventoryBundlesListApiCall(true);
      }
      if(page && showAllBundles){
        parentRef.current.getInventoryBundlesListApiCall(true,page);
      }
    }
  }
  const location = useLocation();
  const path = location.pathname;
  return (
    <React.Fragment>
      <ToastContainer />
      <div className="sale-wh-wrapper whbrand-text-color">
        <Container fluid>
          <Row  className={`px-4 mb-3 ${ path.startsWith("/warehouseinventory/bundles-createbundle")?'justify-content-end px-0':''}`}>
            <Col xs={`${((id === 'bundles'))? 10 : 7}`} >
              <Row className={`${ path.startsWith("/warehouseinventory/bundles-createbundle")?'justify-content-end':''}`}>
                {!path.startsWith("/warehouseinventory/bundles-createbundle") ?  (
                  <Col xs={`${((id === 'bundles'))? 4 : 5}`} className="border-section p-3 py-0">
                  <div>
                    <Label htmlFor="userrole" className="form-label mb-1">
                      Search Inventory Item
                    </Label>
                    <SelectItem
                      searchValue={search}
                      setSearchValue={setSearch}
                      listOptions={searchListOptions}
                      setSearchListOptions={setSearchListOptions}
                      columnsList={columnsList}
                      placeholder="Select Supplier"
                      selectedItemHandler={selectItemCallbck}
                      fieldKey="itemName"
                      dataIdKey="itemNumber"
                      showDropdownBtn={true}
                      dropdownBtnClickHndlr={(setShowOptions) => {
                        if(id === 'bundles'){
                          
                          getInventoryBundlesListApiCall(true)
                        }else{ 
                          
                          getInventoryListApiCall(true)
                        }
                        setShowOptions(true);
                      }}
                      callbackAfterBottomReached={(fn) => {
                        if(id === 'bundles'){
                          getInventoryBundlesListApiCall(true, currentInventoryListPage.current)
                        }else{
                          getInventoryListApiCall(true, currentInventoryListPage.current);
                        }
                        
                        callbackOnInventoryListApiResp.current = fn;
                      }}
                    />
                
                  </div>
                </Col>
                ):''}
                <Col xs={`${(path.startsWith("/warehouseinventory/bundles-createbundle"))? 8 : 7}`}>
                  <div className={`d-flex justify-content-center border border-section py-2 px-1 h-100`}>
                    {actions(
                      history,
                      inventoryDetailsValidation,
                      // inventoryFinancialValidation,
                      justifyPillsToggle,
                      setSearch,
                      toggleDeleteModal,
                      id,
                      getInventoryItemApiCall,
                      // getBundleItemApiCall,
                      setUpcSearch,
                      path,
                      handleBundleReset,
                    ).map((item) => {
                      return (
                        <div
                          key={item.id}
                          className="action-icon-container show-cursor-pointer"
                          onClick={item.clickHandler || (() => {})}
                        >
                          <img className="action-icon" src={item.icon} />
                          <span>{item.label}</span>
                        </div>
                      );
                    })}
                  </div>
                </Col>
              </Row>
              {history.location?.state?.redirected && (
                <div className="d-flex mt-3">
                  <div
                    className="d-flex align-items-center text-primary fs-5"
                    style={{ cursor: "pointer" }}
                    onClick={history.goBack}
                  >
                    <i className="ri-arrow-left-line me-1"></i>
                    <div className="mb-0 text-decoration-underline">Go Back</div>
                  </div>
                </div>
              )}
            </Col>
            {/* {id !== "bundles-createbundle" && id !== 'bundles' && ( */}
            {!path.startsWith("/warehouseinventory/bundles-createbundle") && id !== 'bundles' && (
              <Col xs={5}>
              <Row>
                <Col xs="6" className="d-flex align-items-end justify-content-center mb-0">
                  <div className="d-flex align-items-center">
                    <div
                      className="fs-5 fs-lg-4 fw-bold d-flex align-items-center justify-content-center p-2 p-lg-2 w-100 wh-item-bg"
                      style={{ background: "#FFC10754" }}
                    >
                      Item Number :{" "}
                      {id === "new"
                        ? getMaxInventoryNumResp && Number(getMaxInventoryNumResp?.data?.inventoryNumber) + 1
                        : id}
                    </div>
                  </div>
                </Col>
                <Col xs="6" className="d-flex align-items-start">
                  <div className="d-flex align-items-center">
                    <Label className="fs-5 fs-lg-4 me-3 mb-0">Qty in Stock: </Label>
                    <div
                      style={{ minWidth: "80px" }}
                      className="h-100 border d-flex align-items-center justify-content-center fs-4 fs-lg-2 fw-bold p-2 py-2 flex-grow-1"
                    >
                      {id !== "new" ? getInventoryItemResp?.data?.inventory?.quantityInStock : 0}
                    </div>
                  </div>
                </Col>
                <Col xs="6">
                  <div className="d-flex align-items-end pt-0 w-100">
                    <div className="me-2 flex-1">
                      <Label className="my-0 fs-5 text-center w-100">Num Or UPC</Label>
                      <Input
                        className="form-control"
                        type="number"
                        onWheel={(e) => e.target.blur()}
                        value={upcSearch}
                        onChange={(e) => setUpcSearch(e.target.value)}
                        onKeyDown={(e) => {
                          if (["+", "-", ".", "e", "E"].includes(e.key)) e.preventDefault();
                          else if (e.key === "Enter" && upcSearch) {
                            dispatch(getInventoryListAct(generateApiUrl(`get_inventory_list`), { upcCode: upcSearch }));
                            isSearchUpc.current = true;
                          }
                        }}
                      />
                    </div>
                    <i
                      className="ri-restart-line text-success fs-4 fw-bold mb-1 show-cursor-pointer"
                      onClick={() => {
                        if (upcSearch) {
                          dispatch(getInventoryListAct(generateApiUrl(`get_inventory_list`), { upcCode: upcSearch }));
                          isSearchUpc.current = true;
                        }
                      }}
                    ></i>
                  </div>
                </Col>
                {id !== "new" && (
                  <Col xs="6" className="d-flex align-items-center">
                    <div className="d-flex align-items-center">
                      <Label className="fs-5 fs-lg-4 me-3 mb-0">Adjustment: </Label>
                      {/* <div className="d-flex align-items-center">
                      <div
                        style={{ minWidth: "80px" }}
                        className="h-100 border d-flex align-items-center justify-content-center fs-4 fs-lg-2 fw-bold text-muted p-2 p-lg-3"
                      >
                        {id !== "new" ? getInventoryItemResp?.data?.inventory?.quantityAdj : 0}
                      </div>
                      {id !== "new" && <i className="ri-edit-line ms-2 fs-3" onClick={toggleQtyAdjustmentModal}></i>}
                    </div> */}
                      <div className="d-flex align-items-center">
                        <div
                          className={`text-decoration-underline wh-link-color-green fs-5 me-2 show-cursor-${permissions["ADJ_INV_COUNT"] ? "pointer" : "not-allowed opacity-50"}`}
                          onClick={permissions["ADJ_INV_COUNT"] ? toggleAdjustmentHistoryModal : () => {}}
                        >
                          History
                        </div>
                        <i
                          className={`ri-edit-line ms-2 fs-3 show-cursor-${permissions["ADJ_INV_COUNT"] ? "pointer" : "not-allowed opacity-50"}`}
                          onClick={permissions["ADJ_INV_COUNT"] ? toggleQtyAdjustmentModal : () => {}}
                        ></i>
                      </div>
                    </div>
                  </Col>
                )}
              </Row>
            </Col>
            )}
          </Row>
          {path.startsWith("/warehouseinventory/bundles-createbundle")? (
            <Row>
              <Col className={"px-0"} xs="12">
                <CreateBundle 
                  setIsBlocked={setIsBlocked} 
                  path={path} 
                  resetBundle={resetBundle} 
                  setResetBundle={setResetBundle} 
                  // bundleData={bundleData}
                />
              </Col>
            </Row>
          ) : (
            id === 'bundles' ? (
            <Bundles 
              parentRef={parentRef}
              search={search}
              setRowSelectedIds={setRowSelectedIds}
              setIsBlocked={setIsBlocked}
              setSearchValue={setSearch}
              setSearchListOptions={setSearchListOptions}
              setColumnsList={setColumnsList}
              dropdownBtnClicked={dropdownBtnClicked}
              toggleDeleteModal={toggleDeleteModal}
              isRouteChangeApiCalled={isRouteChangeApiCalled}
            />
          ) : (
              <Row>
            <Col className="px-0" xs={12}>
              <Nav pills className="mb-3 bg-light px-4 wh-text-dark">
                {tabs.map((item) => (
                  <NavItem key={item.id}>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({ active: justifyPillsTab === item.id })}
                      onClick={() => {
                        justifyPillsToggle(item.id);
                      }}
                    >
                      {item.name}
                    </NavLink>
                  </NavItem>
                ))}
              </Nav>
              <TabContent activeTab={justifyPillsTab} className="text-muted">
                <TabPane tabId={justifyPillsTab} id="pill-justified-home-1">
                  <TabComponent
                    history={history}
                    dropdownState={dropdownState}
                    setDropdownState={setDropdownState}
                    id={id}
                    inventoryDetailsValidation={inventoryDetailsValidation}
                    // inventoryFinancialValidation={inventoryFinancialValidation}
                    dispatch={dispatch}
                    generateApiUrl={generateApiUrl}
                    getSpecialPricesResp={getSpecialPricesResp}
                    getAllCustomersResp={getAllCustomersResp}
                    createCustomerInventoryPriceResp={createCustomerInventoryPriceResp}
                    deleteSpecialPriceForCustomerResp={deleteSpecialPriceForCustomerResp}
                    toast={toast}
                    permissions={permissions}
                  />
                </TabPane>
              </TabContent>
            </Col>
          </Row>
            )
          )}
        </Container>
      </div>
      <Draggable cancel=".cancledrag">
      <Modal
        id="showQtyAdjustmentModal"
        isOpen={showQtyAdjustmentModal}
        toggle={toggleQtyAdjustmentModal}
        centered
        size="md"
      >
        <F12CloseModalListener onClose={toggleQtyAdjustmentModal} />
        <ModalHeader className="py-2 px-3 border-bottom" toggle={toggleQtyAdjustmentModal}>
          Qty Adjustment for -23-404
        </ModalHeader>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <ModalBody className="px-0 pt-0 cancledrag">
            <QtyAdjustment
              adjustmentHistoryDetails={adjustmentHistoryDetails}
              dispatch={dispatch}
              generateApiUrl={generateApiUrl}
              id={id}
              qtyInStock={getInventoryItemResp?.data?.inventory?.quantityInStock || 0}
              toggleQtyAdjustmentModal={toggleQtyAdjustmentModal}
              toggleMultiQtyAdjustmentModal={toggleMultiQtyAdjustmentModal}
            />
          </ModalBody>
        </Form>
      </Modal>
      </Draggable>
      <Draggable cancel=".cancledrag" >
      <Modal
        id="showMultiQtyAdjustmentModal"
        isOpen={showMultiQtyAdjustmentModal}
        toggle={toggleMultiQtyAdjustmentModal}
        centered
        size="lg"
        style={{width:"680px"}}
      >
        <F12CloseModalListener onClose={toggleMultiQtyAdjustmentModal} />
        <ModalHeader className="py-2 px-3 border-bottom" toggle={toggleMultiQtyAdjustmentModal}>
          Inv Qty Adjustment
        </ModalHeader>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <ModalBody className="px-0 pt-0 cancledrag">
            <MultiQtyAdjustment
              dispatch={dispatch}
              generateApiUrl={generateApiUrl}
              toggleMultiQtyAdjustmentModal={toggleMultiQtyAdjustmentModal}
              getInventoryListAct={getInventoryListAct}
              getInventoryListResp={getInventoryListResp}
            />
          </ModalBody>
        </Form>
      </Modal>
      </Draggable>
      <Draggable cancel=".cancledrag">
      <Modal
        id="showAdjustmentHistoryModal"
        isOpen={showAdjusmentHistoryModal}
        toggle={toggleAdjustmentHistoryModal}
        centered
        size="lg"
      >
        <F12CloseModalListener onClose={toggleAdjustmentHistoryModal} />
        <ModalHeader className="py-2 px-3 border-bottom" toggle={toggleAdjustmentHistoryModal}>
          Adjustment History
        </ModalHeader>
        <ModalBody className="px-0 pt-0 cancledrag">
          <AdjustmentHistory
            getAdjustmentHistoryOfItemResp={getAdjustmentHistoryOfItemResp}
            getAdjustmentHistoryOfItemApiCall={getAdjustmentHistoryOfItemApiCall}
          />
        </ModalBody>
      </Modal>
      </Draggable>
      <DeleteModal show={showDeleteModal} onCloseClick={toggleDeleteModal} onDeleteClick={handleDeleteInventoryItem} />
      {!path.startsWith("/warehouseinventory/bundles-createbundle") && id !== "bundles" && (
        <RouteChangeConfirmation
        isBlocked={isBlocked}
        title="Leave Page?"
        content="You have unsaved changes. Are you sure you want to leave this page?"
        saveDetailsHndlr={() => {
          getRouteChngSaveDetailsHndlr(
            id,
            justifyPillsTab,
            inventoryDetailsValidation,
            // inventoryFinancialValidation,
            isRouteChangeApiCalled
          );
        }}
        routeChangeApiResp={getRouteChngApiResp(id, createInventoryItemResp, updateInventoryItemResp)}
        routeChangeApiErrResp={inventoryErrorResp}
        formValidation={getFormValidation(
          id,
          justifyPillsTab,
          inventoryDetailsValidation,
          // inventoryFinancialValidation
        )}
        toast={toast}
      />
      )}
      <PaymentsAnywhere />
      <ApiLoader loading={inventoryApiLoading} />
    </React.Fragment>
  );
}
export default withPermission(WarehouseInventory, "INVENTORY_MENU");
