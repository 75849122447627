import React, {
  useMemo,
  useState,
  useRef,
  useEffect,
  useCallback,
} from "react";
import moment from "moment";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import {
  Container,
  Row,
  Col,
  Label,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
} from "reactstrap";
import { actions, generateColumns } from "./helper";
import BackIcon from "../../../../../assets/images/warehouse/menu/back.png";
import WhTableContainer from "../../../../../Components/Common/WhTables";
import PrintItem from "./PrintItem";
import ApiLoader from "../../../../../Components/Common/ApiLoader";
import {
  getStartDateFilterValue,
  getEndDateFilterValue,
} from "../../../../../utility/dateutility";
//Api Helper
import { generateApiUrl } from "../../../../../api/apihelper";
//Actions
import { getInventoryAllMasterDataAct } from "../../../../../store/inventory/inventoryactions";
import {
  getCustomersReportAct,
  getCustomerBalanceStatementAct,
} from "../../../../../store/reports/reportsactions";
import { getStatesListAct } from "../../../../../store/masterdata/masterdataactions";

export const Balance = ({ history, toast }) => {
  const [filters, setFilters] = useState({
    search: "",
    status: "",
    state: "",
  });
  const [customerNumber, setCustomerNumber] = useState("");
  const [startDate, setStartDate] = useState(moment().format("YYYY-MM-DD"));
  const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));
  const [tableData, setTableData] = useState([]);
  const [showPrintModal, setShowPrintModal] = useState(false);
  const isDownloadBtnClicked = useRef(false);

  function togglePrintModal() {
    if (!customerNumber) toast.error("Please select customer");
    else setShowPrintModal(!showPrintModal);
  }

  const dispatch = useDispatch();

  const {
    getCustomersReportResp,
    getCustomerBalanceStatementResp,
    getCustomerBalanceStatementLoading,
    getStatesListResp,
    reportsErrorResp,
  } = useSelector(
    ({ reports, masterData }) => ({
      getCustomersReportResp: reports.getCustomersReportResp,
      getCustomerBalanceStatementResp: reports.getCustomerBalanceStatementResp,
      getCustomerBalanceStatementLoading:
        reports.getCustomerBalanceStatementLoading,
      getStatesListResp: masterData.getStatesListResp,
      reportsErrorResp: reports.reportsErrorResp,
    }),
    shallowEqual
  );
  const inventoryResp = useRef({
    prevGetCustomersReportResp: getCustomersReportResp,
    prevGetCustomerBalanceStatementResp: getCustomerBalanceStatementResp,
    prevReportsErrorResp: reportsErrorResp,
  });

  useEffect(() => {
    const {
      prevGetCustomersReportResp,
      prevGetCustomerBalanceStatementResp,
      prevReportsErrorResp,
    } = inventoryResp.current || {};
    if (
      getCustomersReportResp &&
      prevGetCustomersReportResp !== getCustomersReportResp
    ) {
      setTableData(getCustomersReportResp?.data?.report || []);
    } else if (
      getCustomerBalanceStatementResp &&
      isDownloadBtnClicked.current &&
      prevGetCustomerBalanceStatementResp !== getCustomerBalanceStatementResp
    ) {
      const file = new File(
        [getCustomerBalanceStatementResp],
        "customer_balance_statement.pdf",
        {
          type: "application/pdf",
        }
      );
      const url = window.URL.createObjectURL(file);
      const a = document.createElement("a");
      a.href = url;
      a.setAttribute("download", "customer_balance_statement.pdf");
      document.body.appendChild(a);
      a.click();
      a.remove();
      isDownloadBtnClicked.current = false;
    } else if (reportsErrorResp && prevReportsErrorResp !== reportsErrorResp) {
      const { errors, error, message } = reportsErrorResp || {};
      toast.error(
        (Array.isArray(errors) && errors[0] && errors[0].msg) ||
          error ||
          message
      );
    }
    inventoryResp.current.prevGetCustomersReportResp = getCustomersReportResp;
    inventoryResp.current.prevGetCustomerBalanceStatementResp =
      getCustomerBalanceStatementResp;
    inventoryResp.current.prevReportsErrorResp = reportsErrorResp;
  }, [
    getCustomersReportResp,
    getCustomerBalanceStatementResp,
    reportsErrorResp,
  ]);

  useEffect(() => {
    dispatch(
      getInventoryAllMasterDataAct(
        generateApiUrl(`get_inventory_all_master_data`)
      )
    );
    dispatch(getStatesListAct(generateApiUrl(`get_states_list`)));
  }, []);

  const getCustomersReportApiCall = useCallback(
    (page, pageSize, download, downloadType = "pdf") => {
      const { status, search, state } = filters || {};
      let params = {
        page: page ? page + 1 : 1,
        pageSize: pageSize || 30,
      };
      if (status) params.status = status;
      if (search) params.search = search;
      if (state) params.state = state;
      // if (startDate) params.startDate = getStartDateFilterValue(startDate);
      // if (endDate) params.endDate = getEndDateFilterValue(endDate);
      dispatch(
        getCustomersReportAct(generateApiUrl(`get_customers_report`), params)
      );
    },
    [filters]
  );

  useEffect(() => {
    const debounceFn = setTimeout(() => {
      getCustomersReportApiCall();
    }, 400);
    return () => {
      clearTimeout(debounceFn);
    };
  }, [getCustomersReportApiCall]);

  const handlePageChange = (page, pageSize) => {
    getCustomersReportApiCall(page, pageSize);
  };

  const statusChngHndlr = (name) => {
    setFilters((prev) => ({ ...prev, status: name === "all" ? "" : name }));
  };

  const filtersChngHndlr = (e) => {
    const { name, value } = e.target;
    setFilters((prev) => ({ ...prev, [name]: value }));
  };

  const onRowClick = (index) => {
    if (index !== "") setCustomerNumber(tableData[index].customerNumber);
    else setCustomerNumber("");
  };

  const generateCustomerBalanceReport = () => {
    if (!customerNumber) toast.error("Please select customer");
    else isDownloadBtnClicked.current = true;
    dispatch(
      getCustomerBalanceStatementAct(
        generateApiUrl(`get_customer_balance_statement`),
        {
          startDate: getStartDateFilterValue(startDate),
          endDate: getEndDateFilterValue(endDate),
          customerNumber,
          download: true,
          downloadType: "pdf",
        },
        { responseType: "blob" }
      )
    );
  };

  const restFilters = () => {
    setFilters({
      search: "",
      status: "",
      state: "",
    });
    setCustomerNumber("");
    setStartDate(moment().format("YYYY-MM-DD"));
    setEndDate(moment().format("YYYY-MM-DD"));
  };

  const columns = useMemo(() => generateColumns(), []);
  return (
    <>
      <Container fluid>
        <Row>
          <Col xs="12" className="mb-0">
            <div className="border ps-4 my-2" style={{ position: "relative" }}>
              <Label
                className="text-dark fs-5"
                style={{ position: "absolute", transform: "translateY(-50%)" }}
              >
                <span className=" px-1 wh-item-bg"> Filters</span>
              </Label>
              <Row>
                <Col xs="9" className="border-end pe-5">
                  <Row className="my-3">
                    <Col xs={3}>
                      <div className="border p-3 py-2">
                        <div className="fs-4 fw-bold d-flex justify-content-between">
                          <span>Charges</span>
                          <span>-</span>
                        </div>
                        <div className="fs-4 fw-bold d-flex justify-content-between">
                          <span>Credit</span>
                          <span>-</span>
                        </div>
                        <div className="fs-4 fw-bold d-flex justify-content-between">
                          <span>Total</span>
                          <span>-</span>
                        </div>
                      </div>
                    </Col>
                    <Col xs={5}>
                      <div className="d-flex align-items-center justify-content-around border py-1 mt-3 flex-grow-1 mb-1">
                        <div className="d-flex align-items-center">
                          <Input
                            type="radio"
                            checked={filters.status === "active"}
                            className="mb-1"
                            onChange={() => statusChngHndlr("active")}
                          />
                          <Label className="ms-1 mb-0">Active</Label>
                        </div>
                        <div className="d-flex align-items-center">
                          <Input
                            type="radio"
                            checked={filters.status === "inactive"}
                            className="mb-1"
                            onChange={() => statusChngHndlr("inactive")}
                          />
                          <Label className="ms-1 mb-0">Non Active</Label>
                        </div>
                        <div className="d-flex align-items-center">
                          <Input
                            type="radio"
                            className="mb-1"
                            checked={filters.status === ""}
                            onChange={() => statusChngHndlr("all")}
                          />
                          <Label className="ms-1 mb-0">All</Label>
                        </div>
                      </div>

                      <Row>
                        <Col xs="8">
                          <Label className="mb-0">Search</Label>
                          <Input
                            type="text"
                            className="py-1"
                            name="search"
                            value={filters.search}
                            onChange={filtersChngHndlr}
                          />
                        </Col>
                        <Col xs="4">
                          <Label className="mb-0">State</Label>
                          <Input
                            type="select"
                            className="py-1"
                            name="state"
                            value={filters.state}
                            onChange={filtersChngHndlr}
                          >
                            <option value=""></option>
                            {(getStatesListResp?.data?.states || []).map(
                              (item) => (
                                <option key={item.id} value={item.code}>
                                  {item.name}
                                </option>
                              )
                            )}
                          </Input>
                        </Col>
                      </Row>
                    </Col>
                    <Col xs="4">
                      <Label className="mb-0">Report Option</Label>
                      <Input type="select" className="py-1">
                        <option>Customer Statements</option>
                      </Input>
                      <div className="d-flex mt-2">
                        <div className="w-50 pe-2">
                          <Label className="mb-0">From</Label>
                          <Input
                            type="date"
                            className="py-1"
                            value={startDate}
                            onChange={(e) => setStartDate(e.target.value)}
                          />
                        </div>
                        <div className="w-50 ps-2">
                          <Label className="mb-0">To</Label>
                          <Input
                            type="date"
                            className="py-1"
                            value={endDate}
                            onChange={(e) => setEndDate(e.target.value)}
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col xs="3">
                  <div className="d-flex align-items-center pe-xxl-5 pe-4 pt-3">
                    <div className="sale-wh-wrapper flex-grow-1 mt-0 me-xxl-4 me-2">
                      <div className="d-flex justify-content-around border border-section py-2 h-100">
                        {actions(
                          togglePrintModal,
                          generateCustomerBalanceReport,
                          restFilters
                        ).map((item) => {
                          return (
                            <div
                              key={item.id}
                              className="action-icon-container"
                              onClick={item.clickHandler || (() => {})}
                              style={{ cursor: "pointer" }}
                            >
                              <img className="action-icon" src={item.icon} />
                              <span>{item.label}</span>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                    <div
                      className="d-flex flex-column align-items-center"
                      style={{ cursor: "pointer" }}
                      onClick={() => history.push("/reportsmenu")}
                    >
                      <img
                        src={BackIcon}
                        width="32"
                        height="32"
                        alt="back-icon"
                      />
                      <div className="text-decoration-underline mb-0">
                        Go Back
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
          <Col xs="12">
            <WhTableContainer
              columns={columns}
              data={tableData || []}
              className="custom-header-css"
              divClass="table-responsive table-container-customersreport-bal"
              tableClass="align-middle table-bordered"
              theadClass="table-light"
              handlePageChange={handlePageChange}
              pageCount={Math.ceil(
                (getCustomersReportResp?.data?.total || 0) / 10
              )}
              customPageSize={30}
              showNoDataComponent={true}
              highlightSelectedRow={true}
              tableHover={false}
              onRowClick={onRowClick}
            />
          </Col>
        </Row>
      </Container>
      <Modal
        id="showPrintModal"
        isOpen={showPrintModal}
        toggle={togglePrintModal}
        centered
        size="lg"
      >
        <ModalHeader className="py-2 px-3" toggle={togglePrintModal}>
          Print Options
        </ModalHeader>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <ModalBody className="px-0 pt-0">
            <PrintItem
              generateCustomerBalanceReport={generateCustomerBalanceReport}
              filters={filters}
              showPrintModal={showPrintModal}
            />
          </ModalBody>
        </Form>
      </Modal>
      <ApiLoader loading={getCustomerBalanceStatementLoading} />
      {/* <ApiLoader loading={getInvPricingReportLoading} /> */}
    </>
  );
};
