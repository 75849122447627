import { Link } from "react-router-dom";

export function generateColumns() {
    return [
        {
            Header: "Supplier. Num",
            accessor: "supplierNumber",
            Cell:({cell:{value}}) => <Link to={`/warehousevendors/${value}`}>{value}</Link>
        },
        {
            Header: "Company Name",
            accessor: "supplierName",
            Cell:({cell:{value}}) => value || "-"
        }, 
        {
            Header: "Supplier Name",
            accessor: "customerName",
            Cell:({row:{original : {firstName, lastName}}}) => `${firstName || "-"} ${lastName || ""}`
        },
    ]
}