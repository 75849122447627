//Utility
import { getCustomDateFormatted } from "../../../../utility/dateutility";
//Constants
import { currencySymbol } from "../../../../constants";

export function generateColumns() {
    return [
        {
            Header: "Attachment File",
            accessor: "attachmentFile",
            Cell : ({cell : {value}}) => value ? getCustomDateFormatted(value, "DD-MM-YYY hh:mm A") : ""
        },
        {
            Header: "Accurate Balance",
            accessor: "accurateBalance",
            Cell : ({cell : {value}}) => (typeof value == "number") ? <>{currencySymbol}{value.toFixed(2)}</> : "",
            style: {textAlign:"right"}
        }, {
            Header: "Date Time",
            accessor: "dateTime"
        }
    ]
}
export const tableData = [{}, {}, {}, {}, {}];