import { call, takeLatest, put, delay } from "redux-saga/effects";
import { getData, postData, putData, deleteData } from "../../api/apiactions";
import { handleApiResponse } from "../../utility/sagautility";
import employeeconstants from "./employeeconstants";
import { changeEmployeeApiLoadingStatusAct } from "./employeeactions"

function withLoading(fn) {
    return function* withLoadingGenerator(...args) {
        yield put(changeEmployeeApiLoadingStatusAct(true));
        yield delay(500);
        yield* fn(...args);
        yield put(changeEmployeeApiLoadingStatusAct(false));
    }
}

function* createEmployeeGen(action) {
    const response = yield call(postData, action.apiEndpoint, action.payload, action.optionalConfig);
    yield call(handleApiResponse, response, {
        successAction:employeeconstants.CREATE_EMPLOYEE_SUCCESS,
        failAction:employeeconstants.CREATE_EMPLOYEE_FAIL,
    });
}

function* updateEmployeeGen(action) {
    const response = yield call(putData, action.apiEndpoint, action.payload, action.optionalConfig);
    yield call(handleApiResponse, response, {
        successAction:employeeconstants.UPDATE_EMPLOYEE_SUCCESS,
        failAction:employeeconstants.UPDATE_EMPLOYEE_FAIL,
    });
}

function* getEmployeesGen(action) {
    const response = yield call(getData, action.apiEndpoint, action.payload, action.optionalConfig);
    yield call(handleApiResponse, response, {
      successAction: employeeconstants.GET_EMPLOYEES_SUCCESS,
      failAction: employeeconstants.GET_EMPLOYEES_FAIL
    })
}

function* deleteEmployeesGen(action) {
    const response = yield call(deleteData, action.apiEndpoint, action.payload, action.optionalConfig);
    yield call(handleApiResponse, response, {
      successAction: employeeconstants.DELETE_EMPLOYEES_SUCCESS,
      failAction: employeeconstants.DELETE_EMPLOYEES_FAIL
    })
}

export function* employeeSaga() {
    yield takeLatest(employeeconstants.CREATE_EMPLOYEE_REQUEST, withLoading(createEmployeeGen));
    yield takeLatest(employeeconstants.UPDATE_EMPLOYEE_REQUEST, withLoading(updateEmployeeGen));
    yield takeLatest(employeeconstants.GET_EMPLOYEES_REQUEST, getEmployeesGen);
    yield takeLatest(employeeconstants.DELETE_EMPLOYEES_REQUEST, withLoading(deleteEmployeesGen));
}
