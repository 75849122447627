export default {
    GET_DRIVERS_LIST_REQUEST:"GET_DRIVERS_LIST_REQUEST",
    GET_DRIVERS_LIST_SUCCESS:"GET_DRIVERS_LIST_SUCCESS",
    GET_DRIVERS_LIST_FAIL:"GET_DRIVERS_LIST_FAIL",

    GET_SALESMAN_LIST_REQUEST:"GET_SALESMAN_LIST_REQUEST",
    GET_SALESMAN_LIST_SUCCESS:"GET_SALESMAN_LIST_SUCCESS",
    GET_SALESMAN_LIST_FAIL:"GET_SALESMAN_LIST_FAIL",

    GET_MOBILE_PROVIDERS_REQUEST: "GET_MOBILE_PROVIDERS_REQUEST",
    GET_MOBILE_PROVIDERS_SUCCESS: "GET_MOBILE_PROVIDERS_SUCCESS",
    GET_MOBILE_PROVIDERS_FAIL: "GET_MOBILE_PROVIDERS_FAIL",
  
    GET_STATES_LIST_REQUEST: "GET_STATES_LIST_REQUEST",
    GET_STATES_LIST_SUCCESS: "GET_STATES_LIST_SUCCESS",
    GET_STATES_LIST_FAIL: "GET_STATES_LIST_FAIL",

    GET_ZIPCODE_REQUEST: "GET_ZIPCODE_REQUEST",
    GET_ZIPCODE_SUCCESS: "GET_ZIPCODE_SUCCESS",
    GET_ZIPCODE_FAIL: "GET_ZIPCODE_FAIL",

    GET_MSA_REPORT_AS_REQUEST:"GET_MSA_REPORT_AS_REQUEST",
    GET_MSA_REPORT_AS_SUCCESS:"GET_MSA_REPORT_AS_SUCCESS",
    GET_MSA_REPORT_AS_FAIL:"GET_MSA_REPORT_AS_FAIL",

    GET_STATE_REPORT_AS_REQUEST:"GET_STATE_REPORT_AS_REQUEST",
    GET_STATE_REPORT_AS_SUCCESS:"GET_STATE_REPORT_AS_SUCCESS",
    GET_STATE_REPORT_AS_FAIL:"GET_STATE_REPORT_AS_FAIL",
}