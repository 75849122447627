import React, { useContext, useEffect, useMemo, useState } from "react";
import { Link } from 'react-router-dom';
import { Card, CardBody, Col, Container, Row, } from 'reactstrap';
import { useDispatch } from "react-redux";
// Import Images
import HeaderLogo from "../../assets/images/warehouse/menu/header_logo.png";
//Helper
import { menuList } from "./helper";
//Actions
import { logoutUser } from "../../store/actions";
//Context
import { PermissionsContext } from "../../context/PermissionsContext";
import { generateApiUrl } from "../../api/apihelper";

function filterMenu(item, permissions, role) {
    let hasRole, hasPermission;
    if(item.permission) {
          if(permissions[item.permission]) hasPermission=true;
          else hasPermission=false; 
    } else hasPermission=true;
    if(item.role) {
        if(item.role.some((item) => item?.toLowerCase() === role?.toLowerCase())) hasRole = true;
        else hasRole=false;
    } else hasRole=true;
    return hasPermission && hasRole;
}

export default function WarehouseMenu() {
    const dispatch = useDispatch();
    const [currentDateTime, setCurrentDateTime] = useState(new Date());
    const { permissions, handleLogout } = useContext(PermissionsContext);
     const handleLogoutUser = () => {
        dispatch(logoutUser(generateApiUrl('logout_user')));
        handleLogout();
    }
    const {role} = JSON.parse(localStorage.getItem("user") || "{}");
    const filteredMenu = useMemo(() => {
        const menus = menuList.filter(item => filterMenu(item,permissions,role?.name.trim()));
        return menus;
    }, [menuList, permissions]);
    useEffect(() => {
        const intervalId = setInterval(() => {
          setCurrentDateTime(new Date());
        }, 1000);
        return () => clearInterval(intervalId);
      }, []);
    return (
        <div className="warehouse-menu-wrapper overflow-hidden min-vh-100 wh-background-image ">
            <div className="menu-header d-flex justify-content-between align-items-center py-1 px-4 ">
                <img className="header-logo" src={HeaderLogo} />
                <div className="text-muted wh-text-muted">
                     {currentDateTime.toLocaleString('en-US', {
                       weekday: 'long',
                       year: 'numeric',
                       month: 'long',
                       day: 'numeric',
                       hour: '2-digit',
                       minute: '2-digit',
                       second: '2-digit',
                       hour12: true,
                     })}
                     {JSON.stringify(role?.name)}
                 </div>
            </div>
            <div className="page-menu-content">
                <div>
                <Col>
                        {(filteredMenu || []).map((item, key) => (
                              <Col 
                              xs={12}
                               md={key === 0 ? 4 : key + 4} 
                               xl={key === 0 ? 2 : key + 2}
                                key={key}>
                                <Link to={item.url} onClick={item.id === "exit" ? handleLogoutUser : () => {}}>
                                    <div className="mb-4 card-animate wh-menu-card " style={{ paddingLeft: key === 0 ? '0%' : `${key * 10}%`, opacity:1,}}>
                                        <div className="menu-card-body">
                                            <div className="d-flex justify-content-start px-3 py-3 "> 
                                            
                                                <div className="d-flex gap-3 align-items-center  justify-content-center" >
                                                <img className="menu-image-icon" src={item.imgSrc} />
                                                <h3 className="font-weight-bold wh-menu-card-text text-nowrap">{item.name}</h3>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            </Col>
                        ))}
                    </Col>
                </div>
               
            </div>

        </div>
    )
}