export const reportsUrl = {
    get_suppliers_report : `/reports/suppliers`,
    get_po_analysis_report : `/reports/po-analysis`,
    get_inv_pricing_report : `/reports/inventory-pricing`,
    get_inv_pricing_stats : `/reports/inventory-pricing/statistics`,
    get_customers_report : `/reports/customers`,
    get_customers_stats : `/reports/customers/statistics`,
    get_inv_pricing_update_cols: `/reports/inventory-pricing/options`,
    update_inv_pricing: `/reports/inventory-pricing/`,
    get_inv_price_code: `/reports/inventory-pricing/pricecode`,
    update_inv_price_code: `/reports/inventory-pricing/pricecode`,
    get_customer_route_report: `/reports/customers/route`,
    get_payments_report: `/reports/payments`,
    get_sales_history_report: `/reports/sales`,
    get_sales_highest: `/reports/sales/highest`,
    get_customer_balance_statement: `/reports/customers/balance-statement`,
    get_purchases_report: `/reports/purchases`,
    get_sales_report_items: `/reports/sales/invoicelineitems`,
    get_inv_purchases_report: `/reports/purchases/polineitems`,
    get_inv_sales_report: `/reports/invoice/invoicelineitems`,

    get_inventory_detection_newkind_report : `/shopify/orders-quantity/adjust`,
    get_inventory_detection_newboo_report : `/shopify/second/orders-quantity/adjust`,
    get_inventory_detection_amazon_report : `/amazon/orders`,
    get_inventory_adjustment_report : `/shopify/inventory_levels/adjust`,

    get_return_sales_report: `/reports/sales/returns`,
    get_drivers_report: `/reports/drivers`,
    get_msa_report:`/sendMsaFile`,

    get_shopify_report : `/ecommerce-orders`,
}