import PrintIcon from "../../../../../assets/images/warehouse/salespage/printer.png";
import GenerateIcon from "../../../../../assets/images/warehouse/salespage/generate.png";
import ExportIcon from "../../../../../assets/images/warehouse/salespage/excel.png";
import ResetIcon from "../../../../../assets/images/warehouse/salespage/reset.png";
import { formatAmount } from "../../../../../utility/commonutility";
import { Link } from "react-router-dom";

export const actions = (togglePrintModal,getInvPricingReportApiCall, resetFilters) => [
    {
        id: "reset",
        label: "Reset",
        icon: ResetIcon,
        clickHandler: resetFilters
      }, {
        id: "print",
        label: "Print",
        icon: PrintIcon,
        clickHandler:togglePrintModal
    }, {
        id: "export",
        label: "Export",
        icon: ExportIcon,
        clickHandler: () => getInvPricingReportApiCall(0,10,true)
    },
];

export function generateColumns() {
    return [
        {
            Header: "Item#",
            accessor: "itemNumber",
            Cell:({cell:{value}}) => <Link target="_blank" to={`/warehouseinventory/${value}`}>{value}</Link>
        },
        {
            Header: "Description",
            accessor: "description",
            Cell:({row:{original:{itemNumber, description}}}) => <Link target="_blank" to={`/warehouseinventory/${itemNumber}`}>{description}</Link>
        },
        {
            Header: "Quantity",
            accessor: "quantityInStock"
        }, {
            Header: "Cost",
            accessor: "cost",
            Cell: ({cell:{value}}) => formatAmount(value)
        }, {
            Header: "Retail",
            accessor: "retail",
            Cell: ({cell:{value}}) => formatAmount(value)
        },{
            Header: "Unit Charge",
            accessor: "unitCharge"
        },
        {
            Header: "PriceA",
            accessor: "priceA",
        },
        {
            Header: "PriceB",
            accessor: "priceB",
        },
        {
            Header: "PriceC",
            accessor: "priceC",
        },
        {
            Header: "PriceD",
            accessor: "priceD"
        },
    ]
}
export const tableData = [
    {
        id: "Cherokee Red 100",
        item: "11-014",
        description: "27",
        quantity: "$34.90",
        caseQuantity: "$33.90",
    }, {
        id: "Cherokee Red 100",
        item: "11-014",
        description: "27",
        quantity: "$34.90",
        caseQuantity: "$33.90",
    }, {
        id: "Cherokee Red 100",
        item: "11-014",
        description: "27",
        quantity: "$34.90",
        caseQuantity: "$33.90",
    }, {
        id: "Cherokee Red 100",
        item: "11-014",
        description: "27",
        quantity: "$34.90",
        caseQuantity: "$33.90",
    }, {
        id: "Cherokee Red 100",
        item: "11-014",
        description: "27",
        quantity: "$34.90",
        caseQuantity: "$33.90",
    }, {
        id: "Cherokee Red 100",
        item: "11-014",
        description: "27",
        quantity: "$34.90",
        caseQuantity: "$33.90",
    }, {
        id: "Cherokee Red 100",
        item: "11-014",
        description: "27",
        quantity: "$34.90",
        caseQuantity: "$33.90",
    }
];

export const inventoryItemColumnsList = [
    {
      id: "itemNumber",
      name: "Item Num",
      cellClassName: "fw-medium",
    },
    {
      id: "itemName",
      name: "Item Name",
      cellClassName: "fbadge badge-soft-primary",
    },
    {
      id: "category",
      name: "Category",
      cellClassName: "fw-medium",
    },
  ];

  export const supplierColumnsList = [
    {
      id: "supplierNumber",
      name: "Supplier. Num",
      cellClassName: "fw-medium",
    },
    {
      id: "companyName",
      name: "Company",
      cellClassName: "fbadge badge-soft-primary",
    },
    {
      id: "supplierName",
      name: "Supplier Name",
    },
  ];

  export function getInputType(type) {
    if(type.includes("varchar")) return { label:"Set Value", type:"text"};
    else if(type === "DECIMAL" || type === "INTEGER") return { label:"Set Value", type:"number"};
    else if(type === "money") return { label:"Set Amount", type};
    else if (type === "BOOLEAN") return { label:"Set Value", type:"boolean"} ;
    else if(type.includes("datetime")) return { label:"Set Date", type:"date"} ;
    else return { label:"Set Value", type:"text"};
  }
  
  