import PropTypes from "prop-types";
import React,{useRef,useState,useEffect} from "react";
import { Modal, ModalBody } from "reactstrap";
import F12CloseModalListener from "./F12close";

const DeleteModal = ({ show, onDeleteClick, onCloseClick }) => {
 
  const [focusedButton, setFocusedButton] = useState("yes"); 
  const yesButtonRef = useRef("");
  const closeRef = useRef("");
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (show && (e.key === "ArrowRight")) {
        e.preventDefault();
        setFocusedButton((prevButton) => (prevButton === "yes" ? "close" : "yes"));
      }
      if (show && (e.key === "ArrowLeft")) {
        e.preventDefault();
        setFocusedButton((prevButton) => (prevButton === "close" ? "yes" : "close"));
      }
    };
    window.addEventListener('keydown', handleKeyDown);
  }, [show])
  useEffect(() => {
    if (show) {
      const buttonRef = focusedButton === 'yes' ? yesButtonRef : closeRef;
      setTimeout(() => {
        if (buttonRef.current) {
          buttonRef.current.focus();
        }
      }, 0);
    }
  }, [show, focusedButton, yesButtonRef, closeRef]);

  return (
    <Modal isOpen={show} toggle={onCloseClick} centered={true}>
       <F12CloseModalListener onClose={onCloseClick} />
      <ModalBody className="py-3 px-5">
        <div className="mt-2 text-center">
          <lord-icon
            src="https://cdn.lordicon.com/gsqxdxog.json"
            trigger="loop"
            colors="primary:#f7b84b,secondary:#f06548"
            style={{ width: "100px", height: "100px" }}
          ></lord-icon>
          <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
            <h4>Are you sure ?</h4>
            <p className="text-muted mx-4 mb-0">
              Are you sure you want to remove this record ?
            </p>
          </div>
        </div>
        <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
          <button
            ref={closeRef}
            type="button"
            className={`btn w-sm  ${focusedButton === "close" ? "btn-danger border border-danger" : "btn-light"}`}
            data-bs-dismiss="modal"
            onClick={onCloseClick}
            onKeyDown={(e) => {
              if (e.key === "ArrowRight") {
                e.preventDefault();
                setFocusedButton("yes");
              }
            }}
          >
            Close
          </button>
          <button
            ref={yesButtonRef}
            type="button"
            className= {`btn w-sm  ${focusedButton === "yes" ? "btn-danger border border-danger" : "btn-light"}`}
            id="delete-record"
            onClick={onDeleteClick}
            onKeyDown={(e) => {
              if (e.key === "ArrowLeft") {
                e.preventDefault();
                setFocusedButton("close");
              }
            }}
          >
            Yes, Delete It!
          </button>
        </div>
      </ModalBody>
    </Modal>
  );
};

DeleteModal.propTypes = {
  onCloseClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
  show: PropTypes.any,
};

export default DeleteModal;