export const purchaseOrderUrl = {
  get_all_purchase_orders: `/purchase-orders/all`,
  create_purchase_order: `/purchase-orders`,
  get_purchase_order_details: ({poNumber}) => `/purchase-orders/${poNumber}`,
  update_purchase_order_details : ({poNumber}) => `/purchase-orders/${poNumber}`,
  get_max_po_no : `/purchase-orders/max-number`,
  receive_po : ({poNumber}) => `/purchase-orders/${poNumber}/received`,
  delete_po : `/purchase-orders/delete`,
  open_po : ({poNumber}) => `purchase-orders/${poNumber}/po-open`,
  download_po_pdf: ({poNumber}) => `purchase-orders/${poNumber}/download-pdf`
};