import axios from "axios";

const baseURL = process.env.REACT_APP_TEST_API_ENDPOINT;
// const baseURL = "http://localhost:5000/api";

export const axiosInstance = axios.create({
    baseURL,
    headers: {
      "Content-type": "application/json",
    },
    // withCredentials: true,
  });

  axiosInstance.interceptors.request.use(
    async (config) => {
      const token = localStorage.getItem("token");
      if (token) {
        config.headers["Authorization"] = `Bearer ${token}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  const refreshToken = async () => {
    try {
      const token = localStorage.getItem("token");
      const refreshToken = localStorage.getItem("refreshToken")
      const resp = await axios.post(`${baseURL}/auth/refresh-token`,{ token }, { 
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${refreshToken}`,
        }
      });
      return resp.data;
    } catch (e) {
      console.log("Error",e);   
    }
  };

  const requestQueue = [];

  axiosInstance.interceptors.response.use(
    (response) => {
      return response;
    },
    async function (error) {
      const originalRequest = error.config;
      if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
  
        // Check if a token refresh is already in progress
        if (!axiosInstance.isRefreshing) {
          axiosInstance.isRefreshing = true;
  
          try {
            const resp = await refreshToken();
  
            const { token, tokenExpiresAt, refreshToken: rToken } = resp.data;
  
            localStorage.setItem("token", token);
            localStorage.setItem("tokenExpiresAt", tokenExpiresAt);
            localStorage.setItem("refreshToken", rToken);
  
            axiosInstance.defaults.headers.common[
              "Authorization"
            ] = `Bearer ${token}`;
  
            // Replay the original request
            return axiosInstance(originalRequest);
          } catch (refreshError) {
            // Handle refresh token failure
            return Promise.reject(refreshError);
          } finally {
            // Reset the flag once the refresh is complete
            axiosInstance.isRefreshing = false;
          }
        } else {
          // If a refresh is already in progress, enqueue the request
          return new Promise((resolve) => {
            requestQueue.push(() => resolve(axiosInstance(originalRequest)));
          });
        }
      }
      return Promise.reject(error);
    }
  );

// Intercept requests and attach a function to retry them after the token is refreshed
axiosInstance.interceptors.request.use(
  (config) => {
    // Attach a function to retry the request
    config.retryFunction = () => axiosInstance(config);
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Helper function to replay enqueued requests after token refresh
function replayRequests(error, response = null) {
  requestQueue.forEach((resolveFn) => {
    if (response) {
      resolveFn(response);
    } else {
      resolveFn(error.retryFunction());
    }
  });
  // Clear the request queue
  requestQueue.length = 0;
}

// Attach the replayRequests function to handle token refresh success
axiosInstance.interceptors.response.use(
  (response) => {
    // Replay enqueued requests after successful response
    replayRequests(null, response);
    return response;
  },
  (error) => {
    // Replay enqueued requests after failed response
    replayRequests(error);
    return Promise.reject(error);
  }
);

// axios.defaults.baseURL = process.env.REACT_APP_DEV_API_ENDPOINT;

//test baseUrl
// axios.defaults.baseURL = process.env.REACT_APP_TEST_API_ENDPOINT;

// axios.defaults.headers.post['Content-Type'] = "application/json";
// axios.defaults.headers.put['Content-Type'] = "application/json";

// axios.defaults.baseURL = "http://localhost:5000/api"

// axios.interceptors.request.use(
//     requestInterceptor
// )

// axios.interceptors.response.use(responseInterceptor, responseInterceptor);


const checkErrorResponse = (error, optionalConfig) => {
    const ignoreErrorCodes = [500, 405, 404, 401], // these errors are not sent to requesting method
        { errorHandler } = optionalConfig || {};
    if (error.response && error.response.data) {
        return { error: error.response.data };
    } else {
        return { error: { errorMessages: [`Error with satus code:${error.response.status}`] } };
    }

    // }
}

export async function getData(apiUrl, params = {}, optionalConfig = {}) {
    try {
        return await axiosInstance.get(apiUrl, { params, ...optionalConfig })
    }
    catch (error) {
        return checkErrorResponse(error, optionalConfig);
    }
}

export async function postData(apiUrl, payload = {}, optionalConfig = {}) {
    try {
        return await axiosInstance.post(apiUrl, payload, optionalConfig);
    }
    catch (error) {
        return checkErrorResponse(error, optionalConfig);
    }
}

export async function putData(apiUrl, payload = {}, optionalConfig = {}) {
    try {
        return await axiosInstance.put(apiUrl, payload, optionalConfig);
    }
    catch (error) {
        return checkErrorResponse(error, optionalConfig);
    }
}

export async function deleteData(apiUrl, payload = {}, optionalConfig = {}) {
    try {
        return await axiosInstance.delete(apiUrl, { data: payload }, optionalConfig);
    }
    catch (error) {
        return checkErrorResponse(error, optionalConfig);
    }
}