import PrintIcon from "../../../assets/images/warehouse/salespage/printer.png";
import GenerateIcon from "../../../assets/images/warehouse/salespage/generate.png";
import ExportIcon from "../../../assets/images/warehouse/salespage/excel.png";
import { formatAmount } from "../../../utility/commonutility";
import { Link } from "react-router-dom";

export const actions = (togglePrintModal, getSuppliersReportApiCall) => [
    {
        id: "generate",
        label: "Generate",
        icon: GenerateIcon
    }, {
        id: "print",
        label: "Print",
        icon: PrintIcon,
        clickHandler:togglePrintModal
    }, {
        id: "export",
        label: "Export",
        icon: ExportIcon,
        clickHandler: () => getSuppliersReportApiCall(0, 10, true),
    },
];

export function generateColumns() {
    return [
        {
            Header: "Workstation Id",
            accessor: "sId"
        },
        {
            Header: "Driver",
            accessor: "driver.name",
            Cell: ({cell:{value}}) => value || "-"
        },
        {
            Header: "Invoice#",
            accessor: "invoiceNumber"
        },
        {
            Header: "Salesman",
            accessor: "salesman.name",
            Cell: ({cell:{value}}) => value || "-"
        },
        {
            Header: "Customer Id",
            accessor: "soldToCustomer",
            Cell:({cell:{value}}) => <Link target="_blank" to={`/warehousecustomers/${value}`}>{value}</Link>,
        }, {
            Header: "Customer Name",
            accessor: "company",
            Cell:({row:{original:{customerNumber,company}}}) => <Link target="_blank" to={`/warehousecustomers/${customerNumber}`}>{company}</Link>,
        },  {
            Header: "Total Amount",
            accessor: "totalInvAmount",
            Cell: ({cell:{value}}) => formatAmount(value)
        },{
            Header: "Bal. Due",
            accessor: "balanceDue",
            Cell: ({row:{original : {totalInvAmount, totalPaid}}}) => formatAmount(totalInvAmount - totalPaid)
        },
    ]
}
export const tableData = [
    {
        id: "0404222-714",
        item: "372",
        description: "Khodal Business",
        quantity: "Nanda",
        caseQuantity: "$1,814.45",
        amount: "$1,814.45",
    }, {
        id: "0404222-714",
        item: "372",
        description: "Khodal Business",
        quantity: "Nanda",
        caseQuantity: "$1,814.45",
        amount: "$1,814.45",
    }, {
        id: "0404222-714",
        item: "372",
        description: "Khodal Business",
        quantity: "Nanda",
        caseQuantity: "$1,814.45",
        amount: "$1,814.45",
    },{
        id: "0404222-714",
        item: "372",
        description: "Khodal Business",
        quantity: "Nanda",
        caseQuantity: "$1,814.45",
        amount: "$1,814.45",
    }, {
        id: "0404222-714",
        item: "372",
        description: "Khodal Business",
        quantity: "Nanda",
        caseQuantity: "$1,814.45",
        amount: "$1,814.45",
    },{
        id: "0404222-714",
        item: "372",
        description: "Khodal Business",
        quantity: "Nanda",
        caseQuantity: "$1,814.45",
        amount: "$1,814.45",
    },{
        id: "0404222-714",
        item: "372",
        description: "Khodal Business",
        quantity: "Nanda",
        caseQuantity: "$1,814.45",
        amount: "$1,814.45",
    },
];