import { Input, Label } from 'reactstrap';
import { Link } from "react-router-dom";
import ResetIcon from "../../../../assets/images/warehouse/salespage/reset.png";
import PrintIcon from "../../../../assets/images/warehouse/salespage/printer.png";
import ExportIcon from "../../../../assets/images/warehouse/salespage/excel.png";
import DeleteIcon from "../../../../assets/images/warehouse/salespage/delete.png";
import NewIcon from "../../../../assets/images/warehouse/salespage/new.png";

export function generateColumns() {
    return [
      {
        Header: (
          <div className="d-flex align-items-center form-check text-center">
            <Input className="form-check-input me-2" type="checkbox" id="formCheck6" />
            <Label className="form-check-label text-nowrap fw-bold" htmlFor="formCheck6">
              S.No.
            </Label>
          </div>
        ),
        accessor: "sno",
        Cell: ({ row: { original } }) => {
          return (
            <div className="form-check text-start">
              <Input className="form-check-input me-2" type="checkbox" id="formCheck6" />
              <Label className="form-check-label text-nowrap" htmlFor="formCheck6">
                {original.sno}
              </Label>
            </div>
          );
        },
        style: { width: "120px", },
      },
      {
        Header: "Category Name",
        accessor: "categoryName",
      },
      {
        Header: "Sub-Category Code",
        accessor: "subCategoryCode",
      },
      {
        Header: "Sub-Category Name",
        accessor: "subCategoryName",
      },
      {
        Header: "Status",
        Cell: () => {
          return <button className="btn btn-sm btn-soft-success">Active</button>;
        },
      },
      {
        Header: "Actions",
        style:{width:"120px"},
        Cell: () => (
          <div className="hstack gap-3 flex-wrap justify-content-center">
            <div to="#" className="wh-link-color-green fs-15">
              <i className="ri-edit-2-line"></i>
            </div>
            <div to="#" className="link-danger fs-15">
              <i className="ri-delete-bin-line"></i>
            </div>
          </div>
        ),
      },
    ];
}
export const tableData = [
    {
        sno: "1",
        categoryName: "Drinks",
        subCategoryCode: "APC-01",
        subCategoryName: "",
        status: true,
    }, {
        sno: "2",
        categoryName: "Drinks",
        subCategoryCode: "APC-01",
        subCategoryName: "",
    }, {
        sno: "3",
        categoryName: "Drinks",
        subCategoryCode: "APC-01",
        subCategoryName: "",
    }, {
        sno: "4",
        categoryName: "Drinks",
        subCategoryCode: "APC-01",
        subCategoryName: "",
    }, {
        sno: "5",
        categoryName: "Drinks",
        subCategoryCode: "APC-01",
        subCategoryName: "",
    }, {
        sno: "6",
        categoryName: "Drinks",
        subCategoryCode: "APC-01",
        subCategoryName: "",
    }, {
        sno: "7",
        categoryName: "Drinks",
        subCategoryCode: "APC-01",
        subCategoryName: "",
    }, {
        sno: "8",
        categoryName: "Drinks",
        subCategoryCode: "APC-01",
        subCategoryName: "",
    }
];
export const actions = (toggle, togglePrintModal) => [
    {
        id: "print",
        label: "Print",
        icon: PrintIcon,
        clickHandler: togglePrintModal,
    }, {
        id: "delete",
        label: "Delete",
        icon: DeleteIcon
    }, {
        id: "new",
        label: "New",
        icon: NewIcon,
        clickHandler: toggle,
    }
]