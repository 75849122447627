export const tabs = [
  {
    name: "List By",
    id: "list_by",
  },
  {
    name: "Statistics",
    id: "statistics",
  },
  {
    name: "Balance",
    id: "balance",
  },
];
