import React, { Fragment, useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import {
  useTable,
  useGlobalFilter,
  useAsyncDebounce,
  useSortBy,
  useFilters,
  useExpanded,
  useRowSelect,
} from "react-table";
import { Table, Row, Col, Button, Input } from "reactstrap";
import { DefaultColumnFilter } from "./filters";
import ReactPaginate from "react-paginate";
import { Form, MenuItem, Select } from "reactstrap";
import IndeterminateCheckbox from "./IndeterminateCheckbox";
import NoDataComponent from "./NoDataComponent";
import TableLoader from "./TableLoader";

const WhTables = ({
  columns,
  data,
  tableRef,
  isGlobalSearch,
  isGlobalFilter,
  isAddOptions,
  isAddUserList,
  handleOrderClicks,
  handleUserClick,
  handleCustomerClick,
  isAddCustList,
  customPageSize,
  tableStyles,
  thStyle,
  tableClass,
  theadClass,
  thClass,
  tdClass,
  divClass,
  showPagination = true,
  handlePageChange,
  pageCount,
  pageNumber,
  showRowSelection = false,
  setRowSelection,
  rowSelection = {},
  showNoDataComponent = false,
  noDataComponentClassname,
  tableHover = true,
  loading = false,
  onRowClick = () => {},
  highlightSelectedRow = false
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        selectedRowIds: {
          ...rowSelection
        },
      },
      defaultColumn: { Filter: DefaultColumnFilter },
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    useExpanded,
    useRowSelect,
    (hooks) => {
      if (showRowSelection)
        hooks.visibleColumns.push((columns) => [
          // Let's make a column for selection
          {
            // id: "selection",
            // The header can use the table's getToggleAllRowsSelectedProps method
            // to render a checkbox
            Header: ({ getToggleAllRowsSelectedProps }) => (
              <div>
                <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
              </div>
            ),
            style: { flex: "none", width: "48px", minWidth: 0 },
            // The cell can use the individual row's getToggleRowSelectedProps method
            // to the render a checkbox
            Cell: ({ row }) => (
              <div>
                <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
              </div>
            ),
          },
          ...columns,
        ]);
    }
  );
  const { selectedRowIds } = state;
  const prevRowsData = useRef({
    prevSelectedRowIds: selectedRowIds,
  });
  useEffect(() => {
    const { prevSelectedRowIds } = prevRowsData.current || {};
    if (selectedRowIds && prevSelectedRowIds !== selectedRowIds) {
      if (setRowSelection) setRowSelection(selectedRowIds);
    }
    prevRowsData.current.prevSelectedRowIds = selectedRowIds;
  }, [selectedRowIds]);

  useEffect(() => {
    setActiveRowIndex("");
    onRowClick("");
  }, [data])

  const [pageSize, setPageSize] = useState(customPageSize);
  const [activeRowIndex, setActiveRowIndex] = useState("");
  const [numPages,setNumPages] = useState(0);
  const onChangeInSelect = (event) => {
    setPageSize(Number(event.target.value));
    handlePageChange(0, event.target.value);
  };

  const handlePageClick = (event) => {
    handlePageChange(event.selected, pageSize);
  };

  const generateSortingIndicator = (column) => {
    return column.isSorted ? (column.isSortedDesc ? " " : "") : "";
  };

  // const onChangeInInput = (event) => {
  //   const page = event.target.value ? Number(event.target.value) - 1 : 0;
  //   gotoPage(page);
  // };
  const rowClickHandler = (index) => {
    if(highlightSelectedRow) {
      setActiveRowIndex(index);
      onRowClick(index);
    }
  }

  useEffect( () => {
    if(pageSize){
      let tempNumPages = Math.ceil((pageCount) /pageSize  );
     
      setNumPages(tempNumPages);
    }
  },[pageSize])

  return (
    <Fragment>
      <div className={`${divClass} custom-table`}>
        <Table ref={tableRef}  hover={tableHover} {...getTableProps()} className={`${tableClass}`} style={{zIndex:998}}>
          <thead className={`wh-table-head sticky-top`} >
            {headerGroups.map((headerGroup, index) => (
              <tr className={'wh-table-border'} key={headerGroup.id || index} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, index) =>
                  column.show == false ? null : (
                    <th
                      key={column.id || index}
                      className={`${thClass} py-1 wh-table-border`}
                      style={{...thStyle}}
                      // {...column.getSortByToggleProps()}
                    >
                      {column.render("Header")}
                      {generateSortingIndicator(column)}
                      {/* <Filter column={column} /> */}
                    </th>
                  )
                )}
              </tr>
            ))}
          </thead>

          {!loading && (
            <tbody {...getTableBodyProps()}>
              {rows.map((row, index) => {
                prepareRow(row);
                return (
                  <Fragment key={row.getRowProps().key}>
                    <tr className={'wh-table-border'} onClick={() => rowClickHandler(index)} style={(index === activeRowIndex && highlightSelectedRow) ? {backgroundColor:"#ababab"} : {}}>
                      {row.cells.map((cell) => {
                        return cell.column.show == false ? null : (
                          <td key={cell.id} 
                          {...cell.getCellProps({ style: { ...cell.column.style } })}
                          className={`${tdClass} py-0 wh-table-border text-nowrap`}
                          >
                            {cell.render("Cell")}
                          </td>
                        );
                      })}
                    </tr>
                  </Fragment>
                );
              })}
            </tbody>
          )}
        </Table>
      </div>

      {showNoDataComponent && data.length === 0 && !loading && (
        <NoDataComponent className={`py-5 ${noDataComponentClassname}`} />
      )}

      {loading && <TableLoader />}

      {showPagination!== false && pageCount > 0 && (
        <Row className="justify-content-md-end justify-content-center align-items-center pe-2">
          <Col className="col-md-auto d-none d-md-block pe-2">
            <span>Rows per page:</span>
          </Col>
          <Col className="col-md-auto d-none d-md-block px-0">
            <Form variant="standard" sx={{ m: 1, width: 35 }}>
              <select value={pageSize} onChange={onChangeInSelect} className="form-select">
                <option value={10}>10</option>
                <option value={20}>20</option>
                <option value={30}>30</option>
              </select>
            </Form>
          </Col>

          <Col className="col-md-auto mb-0">
            <ReactPaginate
              breakLabel="..."
              nextLabel="Next"
              color="btncolor"
              onPageChange={handlePageClick}
              forcePage={pageNumber}
              pageCount={numPages|| Math.ceil(pageCount/customPageSize)}
              previousLabel="Previous"
              breakClassName={"page-item"}
              breakLinkClassName={"page-link"}
              containerClassName={"pagination mb-0"}
              pageClassName={"page-item"}
              pageLinkClassName={"page-link"}
              previousClassName={"page-item"}
              previousLinkClassName={"page-link"}
              nextClassName={"page-item"}
              nextLinkClassName={"page-link"}
              activeClassName={"active"}
            />
          </Col>
        </Row>
      )}

      {/* <Row className="justify-content-md-end justify-content-center align-items-center pe-2">
        <Col className="col-md-auto">
          <div className="d-flex gap-1">
            <Button
              color="primary"
              onClick={previousPage}
              disabled={!canPreviousPage}
            >
              {"<"}
            </Button>
          </div>
        </Col>
        <Col className="col-md-auto d-none d-md-block">
          Page{" "}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>
        </Col>
        <Col className="col-md-auto">
          <Input
            type="number" onWheel={(e) => e.target.blur()}
            min={1}
            style={{ width: 70 }}
            max={pageOptions.length}
            defaultValue={pageIndex + 1}
            onChange={onChangeInInput}
          />
        </Col>

        <Col className="col-md-auto">
          <div className="d-flex gap-1">
            <Button color="primary" onClick={nextPage} disabled={!canNextPage}>
              {">"}
            </Button>
          </div>
        </Col>
      </Row> */}
    </Fragment>
  );
};

WhTables.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
  tableRef: PropTypes.object,
};

export default WhTables;
