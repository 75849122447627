import { call, takeLatest } from "redux-saga/effects";
import { getData } from "../../api/apiactions";
import { handleApiResponse } from "../../utility/sagautility";
import masterdataconstants from "./masterdataconstants";

function* getDriversListGen(action) {
    const response = yield call(getData, action.apiEndpoint, action.payload, action.optionalConfig);
    yield call(handleApiResponse, response, {
        successAction:masterdataconstants.GET_DRIVERS_LIST_SUCCESS,
        failAction:masterdataconstants.GET_DRIVERS_LIST_FAIL,
    })
}

function* getSalesmanListGen(action) {
    const response = yield call(getData, action.apiEndpoint, action.payload, action.optionalConfig);
    yield call(handleApiResponse, response, {
        successAction:masterdataconstants.GET_SALESMAN_LIST_SUCCESS,
        failAction:masterdataconstants.GET_SALESMAN_LIST_FAIL,
    })
}

function* getMobileProvidersGen(action) {
    const response = yield call(getData,action.apiEndpoint,action.payload,action.optionalConfig);
    yield call(handleApiResponse, response, {
        successAction : masterdataconstants.GET_MOBILE_PROVIDERS_SUCCESS,
        failAction :  masterdataconstants.GET_MOBILE_PROVIDERS_FAIL,
    });
}

function* getStatesListGen(action) {
    const response = yield call(getData,action.apiEndpoint,action.payload,action.optionalConfig);
    yield call(handleApiResponse, response, {
        successAction : masterdataconstants.GET_STATES_LIST_SUCCESS,
        failAction :  masterdataconstants.GET_STATES_LIST_FAIL,
    });
}

function* getZipcodeGen(action) {
    const response = yield call(getData,action.apiEndpoint,action.payload,action.optionalConfig);
    yield call(handleApiResponse, response, {
        successAction : masterdataconstants.GET_ZIPCODE_SUCCESS,
        failAction :  masterdataconstants.GET_ZIPCODE_FAIL,
    });
}

function* getMsaReportAsGen(action) {
    const response = yield call(getData, action.apiEndpoint, action.payload, action.optionalConfig);
    yield call(handleApiResponse, response, {
        successAction:masterdataconstants.GET_MSA_REPORT_AS_SUCCESS,
        failAction:masterdataconstants.GET_MSA_REPORT_AS_FAIL,
    })
}

function* getStateReportAsGen(action) {
    const response = yield call(getData, action.apiEndpoint, action.payload, action.optionalConfig);
    yield call(handleApiResponse, response, {
        successAction:masterdataconstants.GET_STATE_REPORT_AS_SUCCESS,
        failAction:masterdataconstants.GET_STATE_REPORT_AS_FAIL,
    })
}

export function* masterDataSaga() {
    yield takeLatest(masterdataconstants.GET_DRIVERS_LIST_REQUEST, getDriversListGen);
    yield takeLatest(masterdataconstants.GET_SALESMAN_LIST_REQUEST, getSalesmanListGen);
    yield takeLatest(masterdataconstants.GET_MOBILE_PROVIDERS_REQUEST, getMobileProvidersGen);
    yield takeLatest(masterdataconstants.GET_STATES_LIST_REQUEST, getStatesListGen);
    yield takeLatest(masterdataconstants.GET_ZIPCODE_REQUEST, getZipcodeGen);
    yield takeLatest(masterdataconstants.GET_MSA_REPORT_AS_REQUEST, getMsaReportAsGen);
    yield takeLatest(masterdataconstants.GET_STATE_REPORT_AS_REQUEST, getStateReportAsGen);
}
