//Utility
import { getMmddyyyyDateFormatted, getCustomDateFormatted } from "../../../../utility/dateutility";
import { formatAmount } from "../../../../utility/commonutility"
//Constants
import { currencySymbol } from "../../../../constants";

export function generateColumns(history) {
    return [
      {
        Header: "Invoice#",
        accessor: "invoiceNumber",
        Cell: ({ cell: { value } }) => {
          return (
            <div
              className="wh-link-color-green"
              style={{ cursor: "pointer" }}
              onClick={() => {
                history.push(`/warehousesales/${value}`, { redirected: true });
                localStorage.setItem("activeTab", JSON.stringify({ warehousecustomer: "sales" }));
              }}
            >
              {value}
            </div>
          )
        },
      },
      {
        Header: "Invoice Date",
        accessor: "invoiceDate",
        Cell : ({cell:{value}}) => value ? getMmddyyyyDateFormatted(value) : "-",
      },
      {
        Header: "Print Date / Time",
        accessor: "printDateTime",
        Cell : ({cell:{value}}) => value ? getCustomDateFormatted(value, "DD-MM-YYYY hh:mm A") : "-",
      },
      {
        Header: "Salesman",
        accessor: "salesman.name",
        Cell : ({cell:{value}}) => value || "-"
      },
      // {
      //   Header: "User",
      //   accessor: "user",
      //   Cell : ({cell:{value}}) => value || "-"
      // },
      {
        Header: "Total Amount",
        accessor: "totalInvAmount",
        Cell : ({cell : {value}}) => (typeof value == "number") ? <>{formatAmount(value.toFixed(2))}</> : "",
        style: {textAlign:"right"}
      },
      {
        Header: "Total Paid",
        accessor: "totalPaid",
        Cell : ({cell : {value}}) => (typeof value == "number") ?  <>{formatAmount(value.toFixed(2))}</> : "",
        style: {textAlign:"right"}
      },
      {
        Header: "Amount Due",
        accessor: "amountDue",
        Cell : ({cell : {value}}) => (typeof value == "number") ?  <>{formatAmount(value.toFixed(2))}</> : "",
        style: {textAlign:"right"}
      },
    ];
}