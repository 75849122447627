import React, {
  useRef,
  useEffect,
  useState,
  useMemo,
  useCallback,
} from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import {
  Col,
  Container,
  Label,
  Row,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import classnames from "classnames";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
//Api Helper
import { generateApiUrl } from "../../api/apihelper";
import SelectItem from "../../Components/Common/SelectItem";
import DeleteModal from "../../Components/Common/DeleteModal";
import RouteChangeConfirmation from "../../Components/Common/RouteChangeConfirmation";
import PaymentsAnywhere from "../../Components/Common/paymentsanywhere";
import ApiLoader from "../../Components/Common/ApiLoader";
// Formik validation
import { useFormik } from "formik";
import {
  actions,
  tabsOptions,
  supplierColumnsList,
  mapSupplierDetails,
} from "./helper";
import {
  DetailsTab,
  AllInvoicesTab,
  PaidTab,
  UnpaidTab,
  AttachmentTab,
} from "./tabs";
import {
  detailsInitialValues,
  detailsValidationSchema,
  generatePayload,
} from "./tabs/detailshelper";
import {
  createSupplierAct,
  updateSupplierDetailsAct,
  getSuppliersListAct,
  deleteSupplierAct,
  getMaxSupplierNumAct,
  getSupplierAct,
} from "../../store/supplier/supplieractions";
//HOC
import withPermission from "../../hoc/withPermission";

function getActiveComponent(activeId) {
  switch (activeId) {
    case "details":
      return DetailsTab;
    case "all_invoices":
      return AllInvoicesTab;
    case "paid":
      return PaidTab;
    case "unpaid":
      return UnpaidTab;
    case "attachment":
      return AttachmentTab;
    default:
      return <></>;
  }
}

function WarehouseVendors({ history }) {
  const { id } = useParams();
  const [search, setSearch] = useState("");
  const [searchListOptions, setSearchListOptions] = useState([]);
  const [activeTabState, setActiveTabState] = useState("details");
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isBlocked, setIsBlocked] = useState(false);
  const toggleDeleteModal = () => setShowDeleteModal(!showDeleteModal);
  const TabComponent = useMemo(
    () => getActiveComponent(activeTabState),
    [activeTabState]
  );
  const dispatch = useDispatch();
  const validation = useFormik({
    initialValues: detailsInitialValues,
    validationSchema: detailsValidationSchema,
    onSubmit: (values) => {
      saveDetailsHndlr(values);
    },
  });
  const initialValidationValues = useRef(detailsInitialValues);
  const isRouteChangeApiCalled = useRef(false);
  useEffect(() => {
    if (
      JSON.stringify(validation.values) !==
      JSON.stringify(initialValidationValues.current)
    )
      setIsBlocked(true);
    else setIsBlocked(false);
    isRouteChangeApiCalled.current = false;
  }, [validation.values]);
  const saveDetailsHndlr = (values = validation.values) => {
    if (id && id === "new")
      dispatch(
        createSupplierAct(
          generateApiUrl("create_supplier"),
          generatePayload(values)
        )
      );
    else
      dispatch(
        updateSupplierDetailsAct(
          generateApiUrl("update_supplier_details", { supplierNumber: id }),
          generatePayload(values)
        )
      );
  };
  const currentSupplierListPage = useRef(1);
  const dropdownBtnClicked = useRef(false);
  const callbackOnSupplierListApiResp = useRef(() => {});

  const isCreateVendorBtnClicked = useRef(false);

  const {
    getSuppliersListResp,
    createSupplierResp,
    updateSupplierDetailsResp,
    deleteSupplierResp,
    getMaxSupplierNumResp,
    getSupplierResp,
    supplierErrorResp,
    masterDataErrorResp,
    supplierApiLoading,
  } = useSelector(
    ({ supplier, masterData }) => ({
      getSuppliersListResp: supplier.getSuppliersListResp,
      createSupplierResp: supplier.createSupplierResp,
      updateSupplierDetailsResp: supplier.updateSupplierDetailsResp,
      deleteSupplierResp: supplier.deleteSupplierResp,
      getMaxSupplierNumResp: supplier.getMaxSupplierNumResp,
      getSupplierResp: supplier.getSupplierResp,
      supplierErrorResp: supplier.supplierErrorResp,
      masterDataErrorResp: masterData.masterDataErrorResp,
      supplierApiLoading: supplier.supplierApiLoading,
    }),
    shallowEqual
  );
  const apiResp = useRef({
    prevGetSuppliersListResp: getSuppliersListResp,
    prevCreateSupplierResp: createSupplierResp,
    prevUpdateSupplierDetailsResp: updateSupplierDetailsResp,
    prevDeleteSupplierResp: deleteSupplierResp,
    prevGetSupplierResp: getSupplierResp,
    prevSupplierErrorResp: supplierErrorResp,
    prevMasterDataErrorResp: masterDataErrorResp,
  });
  useEffect(() => {
    const {
      prevGetSuppliersListResp,
      prevCreateSupplierResp,
      prevUpdateSupplierDetailsResp,
      prevDeleteSupplierResp,
      prevGetSupplierResp,
      prevSupplierErrorResp,
      prevMasterDataErrorResp,
    } = apiResp.current || {};
    if (
      getSuppliersListResp &&
      prevGetSuppliersListResp !== getSuppliersListResp
    ) {
      const list = [];
      (getSuppliersListResp?.data?.suppliers || []).forEach(
        ({ supplierNumber, firstName, lastName, supplierName }) => {
          list.push({
            supplierNumber,
            supplierName: `${firstName || ""} ${lastName || ""}`,
            companyName: supplierName,
          });
        }
      );
      if (!dropdownBtnClicked.current) setSearchListOptions(list);
      else {
        setSearchListOptions((prev) => [...prev, ...list]);
        if (getSuppliersListResp?.data?.suppliers?.length > 0)
          callbackOnSupplierListApiResp.current();
        currentSupplierListPage.current =
          Number(getSuppliersListResp?.data?.page) + 1;
      }
    }
    if (createSupplierResp && prevCreateSupplierResp !== createSupplierResp) {
      if (!isRouteChangeApiCalled.current) {
        setIsBlocked(false);
        isCreateVendorBtnClicked.current = true;
      } else isRouteChangeApiCalled.current = false;
    }
    if (
      updateSupplierDetailsResp &&
      prevUpdateSupplierDetailsResp !== updateSupplierDetailsResp
    ) {
      if (!isRouteChangeApiCalled.current) {
        toast.success("Successfully updated supplier details");
        getSupplierApiCall();
      } else isRouteChangeApiCalled.current = false;
    }
    if (deleteSupplierResp && prevDeleteSupplierResp !== deleteSupplierResp) {
      history.push("/warehousevendors", { showDeleteSupplierSuccess: true });
    }
    if (getSupplierResp && prevGetSupplierResp !== getSupplierResp) {
      const {
        data: {
          supplier: { firstName, lastName, supplierName },
        },
      } = getSupplierResp;
      setSearch(
        `${firstName || ""} ${lastName || ""}${
          firstName || lastName ? "-" : ""
        }${supplierName || ""}`
      );
      mapSupplierDetails(
        getSupplierResp,
        validation.setValues,
        initialValidationValues
      );
      setActiveTabState("details");
      setIsBlocked(false);
    }
    if (supplierErrorResp && prevSupplierErrorResp !== supplierErrorResp) {
      const { errors, error, message } = supplierErrorResp || {};
      toast.error((Array.isArray(errors) && errors[0].msg) || error || message);
      isCreateVendorBtnClicked.current = false;
    }
    if (
      masterDataErrorResp &&
      prevMasterDataErrorResp !== masterDataErrorResp
    ) {
      const { errors, error, message } = masterDataErrorResp || {};
      toast.error((Array.isArray(errors) && errors[0].msg) || error || message);
    }
    apiResp.current.prevGetSuppliersListResp = getSuppliersListResp;
    apiResp.current.prevCreateSupplierResp = createSupplierResp;
    apiResp.current.prevUpdateSupplierDetailsResp = updateSupplierDetailsResp;
    apiResp.current.prevDeleteSupplierResp = deleteSupplierResp;
    apiResp.current.prevGetSupplierResp = getSupplierResp;
    apiResp.current.prevSupplierErrorResp = supplierErrorResp;
    apiResp.current.prevMasterDataErrorResp = masterDataErrorResp;
  }, [
    getSuppliersListResp,
    createSupplierResp,
    updateSupplierDetailsResp,
    deleteSupplierResp,
    getSupplierResp,
    supplierErrorResp,
    masterDataErrorResp,
  ]);

  useEffect(() => {
    if (!isBlocked && isCreateVendorBtnClicked.current) {
      history.push("/warehousevendors", { showCreateSupplierSuccess: true });
    }
  }, [isBlocked]);

  useEffect(() => {
    if (search === "") setSearchListOptions([]);
  }, [search]);
  const getSupplierApiCall = () =>
    dispatch(
      getSupplierAct(generateApiUrl(`get_supplier`, { supplierNumber: id }))
    );
  useEffect(() => {
    if (id && id !== "new") {
      getSupplierApiCall();
    }
    if (id && id === "new") {
      dispatch(getMaxSupplierNumAct(generateApiUrl(`get_max_supplier_num`)));
      setSearch("");
      setActiveTabState("details");
      initialValidationValues.current = detailsInitialValues;
    }
    setIsBlocked(false);
    validation.resetForm();
  }, [id]);
  const getSuppliersListApiCall = useCallback(
    (showAllSuppliers, page) => {
      if (showAllSuppliers) {
        let params = {
          page: page || 1,
          pageSize: 50,
          orderBy: "supplierName",
          sort: "asc",
        };
        dispatch(
          getSuppliersListAct(generateApiUrl(`get_suppliers_list`), params)
        );
        dropdownBtnClicked.current = true;
      } else if (search) {
        let params = {
          page: 1,
          pageSize: 5,
        };
        if (search && isNaN(Number(search))) params.supplierName = search;
        else params.supplierNumber = search;
        dispatch(
          getSuppliersListAct(generateApiUrl(`get_suppliers_list`), params)
        );
        dropdownBtnClicked.current = false;
      }
    },
    [search]
  );
  useEffect(() => {
    const debounceFn = setTimeout(() => {
      getSuppliersListApiCall();
    }, 200);
    return () => {
      clearTimeout(debounceFn);
    };
  }, [getSuppliersListApiCall]);

  function selectItemCallbck(itemDetails) {
    const { supplierNumber } = itemDetails;
    history.push(`/warehousevendors/${supplierNumber}`);
  }

  const handleDeleteSupplier = () => {
    if (id) {
      dispatch(
        deleteSupplierAct(generateApiUrl("delete_supplier"), { ids: [id] })
      );
    }
  };
  return (
    <React.Fragment>
      <ToastContainer />
      <div className="sale-wh-wrapper whbrand-text-color">
        <Container fluid>
          <Row className="px-4 mb-4">
            <Col xs={6}>
              <Row>
                <Col xs={6} className="border-section p-3 py-2">
                  <div className="py-0">
                    <Label htmlFor="userrole" className="form-label mb-1">
                      Search Supplier
                    </Label>
                    <SelectItem
                      searchValue={search}
                      setSearchValue={setSearch}
                      listOptions={searchListOptions}
                      setSearchListOptions={setSearchListOptions}
                      columnsList={supplierColumnsList}
                      placeholder="Select Supplier"
                      selectedItemHandler={selectItemCallbck}
                      fieldKey="supplierName"
                      dataIdKey="supplierNumber"
                      showDropdownBtn={true}
                      dropdownBtnClickHndlr={(setShowOptions) => {
                        getSuppliersListApiCall(true);
                        setShowOptions(true);
                      }}
                      callbackAfterBottomReached={(fn) => {
                        getSuppliersListApiCall(
                          true,
                          currentSupplierListPage.current
                        );
                        callbackOnSupplierListApiResp.current = fn;
                      }}
                    />
                  </div>
                </Col>
                <Col xs={6}>
                  <div className="d-flex justify-content-center border border-section py-0 px-1 h-100">
                    {actions(
                      id,
                      history,
                      validation,
                      toggleDeleteModal,
                      setSearch,
                      setActiveTabState,
                      getSupplierApiCall
                    ).map((item) => {
                      return (
                        <div
                          key={item.id}
                          className={`action-icon-container ${
                            id === "new" && item.id === "delete"
                              ? "opacity-50 show-cursor-not-allowed"
                              : "show-cursor-pointer"
                          }`}
                          onClick={
                            (id !== "new" || item.id !== "delete") &&
                            item.clickHandler
                              ? item.clickHandler
                              : () => {}
                          }
                        >
                          <img className="action-icon" src={item.icon} />
                          <span>{item.label}</span>
                        </div>
                      );
                    })}
                  </div>
                </Col>
              </Row>
              {history.location?.state?.redirected && (
                <div className="d-flex mt-3">
                  <div
                    className="d-flex align-items-center text-primary fs-5"
                    style={{ cursor: "pointer" }}
                    onClick={history.goBack}
                  >
                    <i className="ri-arrow-left-line me-1"></i>
                    <div className="mb-0 text-decoration-underline">
                      Go Back
                    </div>
                  </div>
                </div>
              )}
            </Col>
            <Col xs={6} className="d-flex">
              <div className="d-flex align-items-center me-5 flex-grow-1">
                <div
                  className="fs-5 fs-lg-4 fw-bold d-flex align-items-center justify-content-center p-3 p-lg-3 wh-item-bg"
                  style={{ background: "#FFC10754" }}
                >
                  Supplier Number :{" "}
                  {id === "new"
                    ? getMaxSupplierNumResp &&
                      Number(getMaxSupplierNumResp?.data?.supplierNumber) + 1
                    : id}
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col className="px-0" xs={12}>
              <Nav pills className="mb-3 bg-light px-5 wh-text-dark">
                {tabsOptions.map((item) => (
                  <NavItem key={item.id}>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: activeTabState === item.id,
                      })}
                      onClick={() => {
                        setActiveTabState(item.id);
                      }}
                    >
                      {item.name}
                    </NavLink>
                  </NavItem>
                ))}
              </Nav>
              <TabContent activeTab={activeTabState}>
                <TabPane tabId={activeTabState} id="pill-justified-home-1">
                  <TabComponent
                    toast={toast}
                    validation={validation}
                    id={id}
                    dispatch={dispatch}
                    generateApiUrl={generateApiUrl}
                    history={history}
                    getSupplierApiCall={getSupplierApiCall}
                  />
                </TabPane>
              </TabContent>
            </Col>
          </Row>
        </Container>
      </div>
      <DeleteModal
        show={showDeleteModal}
        onCloseClick={toggleDeleteModal}
        onDeleteClick={handleDeleteSupplier}
      />
      <RouteChangeConfirmation
        isBlocked={isBlocked}
        title="Leave Page?"
        content="You have unsaved changes. Are you sure you want to leave this page?"
        saveDetailsHndlr={() => {
          isRouteChangeApiCalled.current = true;
          validation.handleSubmit();
        }}
        routeChangeApiResp={
          id === "new" ? createSupplierResp : updateSupplierDetailsResp
        }
        routeChangeApiErrResp={supplierErrorResp}
        formValidation={validation}
        toast={toast}
      />
      <PaymentsAnywhere />
      <ApiLoader loading={supplierApiLoading} />
    </React.Fragment>
  );
}
export default withPermission(WarehouseVendors, "VENDORS_MENU");
