import React, {
  useMemo,
  useState,
  useRef,
  useEffect,
  useCallback,
} from "react";
import moment from "moment";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { toast } from "react-toastify";
import { Container, Row, Col, Label, Input, Modal, ModalHeader, Form, ModalBody } from "reactstrap";
import {
  actions,
  generateColumns,
  inventoryItemColumnsList,
  supplierColumnsList,
} from "./helper";
import BackIcon from "../../../../assets/images/warehouse/menu/back.png";
import WhTableContainer from "../../../../Components/Common/WhTables";
import ApiLoader from "../../../../Components/Common/ApiLoader";
import SelectItem from "../../../../Components/Common/SelectItem";
import {
  getStartDateFilterValue,
  getEndDateFilterValue,
} from "../../../../utility/dateutility";

//Api Helper
import { generateApiUrl } from "../../../../api/apihelper";
//Actions
import {
  getInventoryAllMasterDataAct,
  getInventoryListAct,
} from "../../../../store/inventory/inventoryactions";
import { getSuppliersListAct } from "../../../../store/supplier/supplieractions";
import {
  getInvPricingReportPrintAct,
  getInvPurchasesReportAct,
  getPurchasesReportAct,
} from "../../../../store/reports/reportsactions";
import F12CloseModalListener from "../../../../Components/Common/F12close";
import PrintItem from "./PrintItem";
import SelectOption from "../../../../Components/Common/SelectOption";
const InventoryPurchases = ({ history }) => {
  const [filters, setFilters] = useState({
    startDate: moment().format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
    itemNumber: "",
    category: "",
    manufacturer: "",
    subCategory: "",
    groupCode: "",
    family: "",
    supplierNumber: "",
  });

  
 
  const [selectedRadio, setSelectedRadio] = useState('byPO');
  const [tableData, setTableData] = useState([]);
  const [showPrintModal, setShowPrintModal] = useState(false);
  const [invListSearch, setInvListSearch] = useState("");
  const [invListSrchOptions, setInvListSrchOptions] = useState([]);
  const [suppListSearch, setSuppListSearch] = useState("");
  const [suppListSrchOptions, setSuppListSrchOptions] = useState([]);
  const [dropdownState, setDropdownState] = useState({
    category: [],
    subCategory: [],
    family: [],
    manufacturer: [],
    groupCode: [],
  });

  const handleRadioChange = (event) => {
    setSelectedRadio(event.target.value);
  };

  function togglePrintModal() {
    setShowPrintModal(!showPrintModal);
  }
  const isDownloadBtnClicked = useRef(false);
  const dispatch = useDispatch();
  const currentPageSize = useRef(30);

  //Inventory List Api Helpers
  const currentInventoryListPage = useRef(1);
  const invListDropdownBtnClicked = useRef(false);
  const callbackOnInventoryListApiResp = useRef(() => {});

  //Supplier List Api Helpers
  const currentSupplierListPage = useRef(1);
  const suppListDropdownBtnClicked = useRef(false);
  const callbackOnSupplierListApiResp = useRef(() => {});

  const {
    getInventoryAllMasterDataResp,
    getInventoryListResp,
    getInvPricingReportPrintResp,
    getSuppliersListResp,
    getInvPurchasesReportResp,
    getPurchasesReportResp,
    getPurchasesReportLoading,
    reportsErrorResp,
    getInvPricingReportLoading
  } = useSelector(
    ({ reports, supplier, inventory }) => ({
      getInventoryAllMasterDataResp: inventory.getInventoryAllMasterDataResp,
      getInvPricingReportPrintResp: reports.getInvPricingReportPrintResp,
      getInventoryListResp: inventory.getInventoryListResp,
      getSuppliersListResp: supplier.getSuppliersListResp,
      getInvPurchasesReportResp: reports.getInvPurchasesReportResp,
      getPurchasesReportResp: reports.getPurchasesReportResp,
      getPurchasesReportLoading: reports.getPurchasesReportLoading,
      reportsErrorResp: reports.reportsErrorResp,
      getInvPricingReportPrintLoading: reports.getInvPricingReportPrintLoading,
      getInvPricingReportLoading: reports.getInvPricingReportLoading,
    }),
    shallowEqual
  );
  const customerResp = useRef({
    prevGetInventoryAllMasterDataResp: getInventoryAllMasterDataResp,
    prevGetInvPricingReportPrintResp: getInvPricingReportPrintResp,
    prevGetInventoryListResp: getInventoryListResp,
    prevGetSuppliersListResp: getSuppliersListResp,
    prevGetInvPurchasesReportResp: getInvPurchasesReportResp,
    prevGetPurchasesReportResp: getPurchasesReportResp,
    prevReportsErrorResp: reportsErrorResp,
  });
  useEffect(() => {
    const {
      prevGetInventoryAllMasterDataResp,
      prevGetInventoryListResp,
      prevGetSuppliersListResp,
      prevGetInvPurchasesReportResp,
      prevGetPurchasesReportResp,
      prevReportsErrorResp,
    } = customerResp.current || {};
    if (
      getInvPurchasesReportResp &&
      prevGetInvPurchasesReportResp !== getInvPurchasesReportResp
    ) 
    {
      setTableData(getInvPurchasesReportResp?.data?.report || []);
      currentPageSize.current = getInvPurchasesReportResp?.data?.pageSize;
    } else if (
      getPurchasesReportResp &&
      prevGetPurchasesReportResp !== getPurchasesReportResp
    ) {
      if (isDownloadBtnClicked.current) {
        const url = window.URL.createObjectURL(new Blob([getPurchasesReportResp]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
         "Inventory_purchases_report.csv"
        );
        document.body.appendChild(link);
        link.click();
        link.remove();
      isDownloadBtnClicked.current = false;
    } 
    else{
      setTableData(getPurchasesReportResp?.data?.report || []);
    }
  }
  else if (
      getSuppliersListResp &&
      prevGetSuppliersListResp !== getSuppliersListResp
    ) {
      const list = [];
      (getSuppliersListResp?.data?.suppliers || []).forEach(
        ({ supplierNumber, firstName, lastName, supplierName }) => {
          list.push({
            supplierNumber,
            supplierName: `${firstName || ""} ${lastName || ""}`,
            companyName: supplierName,
          });
        }
      );
      if (!suppListDropdownBtnClicked.current) setSuppListSrchOptions(list);
      else {
        setSuppListSrchOptions((prev) => [...prev, ...list]);
        if (getSuppliersListResp?.data?.suppliers?.length > 0)
          callbackOnSupplierListApiResp.current();
        currentSupplierListPage.current =
          Number(getSuppliersListResp?.data?.page) + 1;
      }
    } else if (
      getInventoryListResp &&
      prevGetInventoryListResp !== getInventoryListResp
    ) {
      const list = [];
      (getInventoryListResp?.data?.inventories || []).forEach(
        ({ itemNumber, description, category }) => {
          list.push({
            itemNumber,
            itemName: description,
            category,
          });
        }
      );
      if (!invListDropdownBtnClicked.current) setInvListSrchOptions(list);
      else {
        setInvListSrchOptions((prev) => [...prev, ...list]);
        if (getInventoryListResp?.data?.inventories?.length > 0)
          callbackOnInventoryListApiResp.current();
        currentInventoryListPage.current =
          Number(getInventoryListResp?.data?.page) + 1;
      }
    } else if (
      getInventoryAllMasterDataResp &&
      prevGetInventoryAllMasterDataResp !== getInventoryAllMasterDataResp
    ) {
      const {
        data: {
          lists: {
            category,
            subCategory,
            family,
            manufacturer,
            model,
            priceUser,
            groupCode,
          },
        },
      } = getInventoryAllMasterDataResp;
      setDropdownState((prev) => ({
        ...prev,
        category: category.map((item) => ({ value: item, label: item })),
        subCategory: subCategory.map((item) => ({ value: item, label: item })),
        family: family.map((item) => ({ value: item, label: item })),
        manufacturer: manufacturer.map((item) => ({
          value: item,
          label: item,
        })),
        groupCode: groupCode.map((item) => ({ value: item, label: item })),
      }));
    } else if (reportsErrorResp && prevReportsErrorResp !== reportsErrorResp) {
      const { errors, error, message } = reportsErrorResp || {};
      toast.error((Array.isArray(errors) && errors[0].msg) || error || message);
    }
    customerResp.current.prevGetInventoryAllMasterDataResp =
      getInventoryAllMasterDataResp;
    customerResp.current.prevGetInventoryListResp = getInventoryListResp;
    customerResp.current.prevGetSuppliersListResp = getSuppliersListResp;
    customerResp.current.prevGetInvPurchasesReportResp =
      getInvPurchasesReportResp;
    customerResp.current.prevGetPurchasesReportResp = getPurchasesReportResp;
    customerResp.current.prevReportsErrorResp = reportsErrorResp;
  }, [
    getInventoryAllMasterDataResp,
    getInventoryListResp,
    getSuppliersListResp,
    getInvPurchasesReportResp,
    getPurchasesReportResp,
    reportsErrorResp,
  ]);

  const getSuppliersReportApiCall = useCallback(
    (page, pageSize) => {
      const {
        startDate,
        endDate,
        itemNumber,
        category,
        manufacturer,
        subCategory,
        groupCode,
        family,
        supplierNumber,
      } = filters;
      let params = {
        page: page ? page + 1 : 1,
        pageSize: pageSize || currentPageSize.current,
      };
      if (startDate) params.startDate = getStartDateFilterValue(startDate);
      if (endDate) params.endDate = getEndDateFilterValue(endDate);
      if (itemNumber) params.stockItemNumber = itemNumber;
      if (category) params.category = category.value;
      if (manufacturer) params.manufacturer = manufacturer.value;
      if (subCategory) params.subCategory = subCategory.value;
      if (groupCode) params.groupCode = groupCode.value;
      if (family) params.family = family.value;
      if (supplierNumber) params.supplierNumber = supplierNumber;
      dispatch(
        getInvPurchasesReportAct(
          generateApiUrl(`get_inv_purchases_report`),
          params
        )
      );
    },
    [filters]
  );

  const generatePurchasesReport = useCallback(
    (download, downloadType, pdfType) => {
      const {
        startDate,
        endDate,
        itemNumber,
        category,
        manufacturer,
        subCategory,
        groupCode,
        family,
        supplierNumber,
      } = filters;
      const params = {
        startDate: getStartDateFilterValue(startDate),
        endDate: getEndDateFilterValue(endDate),
        download: true,
        downloadType: downloadType,
      };
      if (itemNumber) params.itemNumber = itemNumber;
      if (category) params.category = category.value;
      if (manufacturer) params.manufacturer = manufacturer.value;
      if (subCategory) params.subCategory = subCategory.value;
      if (groupCode) params.groupCode = groupCode.value;
      if (family) params.family = family.value;
      if (supplierNumber) params.supplierNumber = supplierNumber;
      if (download && downloadType === "html") {
        params.download = true;
        params.downloadType = downloadType;
        params.pdfType = pdfType;
        dispatch(
          getInvPricingReportPrintAct(
            generateApiUrl(`get_purchases_report`),
            params
          )
        );
      } else if (download) {
        params.download = true;
        params.pdfType = pdfType || selectedRadio;
        isDownloadBtnClicked.current = true;
        dispatch(
          getPurchasesReportAct(
            generateApiUrl(`get_purchases_report`),
            params,
            { responseType: "blob" }
          )
        );
      } else {
        dispatch(
          getPurchasesReportAct(
            generateApiUrl(`get_purchases_report`),
            params
          )
        );
      }
    },
    [filters,selectedRadio]
  );

  useEffect(() => {
    const debounceFn = setTimeout(() => {
      getSuppliersReportApiCall();
    }, 400);
    return () => {
      clearTimeout(debounceFn);
    };
  }, [getSuppliersReportApiCall]);

  const getInventoryListApiCall = useCallback(
    (showAllSuppliers, page) => {
      if (showAllSuppliers) {
        let params = {
          page: page || 1,
          pageSize: 50,
        };
        dispatch(
          getInventoryListAct(generateApiUrl(`get_inventory_list`), params)
        );
        invListDropdownBtnClicked.current = true;
      } else if (invListSearch) {
        let params = {
          page: 1,
          pageSize: 5,
        };
        if (isNaN(Number(invListSearch))) params.description = invListSearch;
        else params.itemNumber = invListSearch;
        dispatch(
          getInventoryListAct(generateApiUrl(`get_inventory_list`), params)
        );
        invListDropdownBtnClicked.current = false;
      }
    },
    [invListSearch]
  );
  useEffect(() => {
    const debounceFn = setTimeout(() => {
      getInventoryListApiCall();
    }, 200);
    return () => {
      clearTimeout(debounceFn);
    };
  }, [getInventoryListApiCall]);

  const getSuppliersListApiCall = useCallback(
    (showAllCustomers, page) => {
      if (showAllCustomers) {
        let params = {
          page: page || 1,
          pageSize: 50,
          orderBy: "supplierName",
          sort: "asc",
          active: true,
        };
        dispatch(
          getSuppliersListAct(generateApiUrl(`get_suppliers_list`), params)
        );
        suppListDropdownBtnClicked.current = true;
      } else if (suppListSearch) {
        let params = {
          page: 1,
          pageSize: 5,
          orderBy: "supplierName",
          sort: "asc",
          active: true,
        };
        if (suppListSearch && isNaN(Number(suppListSearch)))
          params.supplierName = suppListSearch;
        else params.supplierNumber = suppListSearch;
        dispatch(
          getSuppliersListAct(generateApiUrl(`get_suppliers_list`), params)
        );
        suppListDropdownBtnClicked.current = false;
      }
    },
    [suppListSearch]
  );

  useEffect(() => {
    const debounceFn = setTimeout(() => {
      getSuppliersListApiCall();
    }, 200);
    return () => {
      clearTimeout(debounceFn);
    };
  }, [getSuppliersListApiCall]);

  useEffect(() => {
    dispatch(
      getInventoryAllMasterDataAct(
        generateApiUrl(`get_inventory_all_master_data`)
      )
    );
  }, []);

  const handlePageChange = (page, pageSize) => {
    getSuppliersReportApiCall(page, pageSize);
  };

  const filtersChngHndlr = (e) => {
    const { value, checked, name, type } = e.target;
    setFilters((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const columns = useMemo(() => generateColumns(), []);

  const handleMainFilterChange = (e) => {
    const { id } = e.target;
    switch (id) {
      case "sales":
        return history.push("/inventorypurchasesandsales/sales");
      default:
        return null;
    }
  };

  function selectInvListItemCallbck(itemDetails) {
    const { itemNumber, itemName } = itemDetails;
    setFilters((prev) => ({ ...prev, itemNumber }));
    setInvListSearch(itemName);
  }

  const filtersDropdownChngHndlr = (selected, name) => {
    setFilters((prev) => ({ ...prev, [name]: selected }));
  };

  function selectSuppListItemCallbck(itemDetails) {
    const { supplierNumber, companyName } = itemDetails;
    setFilters((prev) => ({ ...prev, supplierNumber }));
    setSuppListSearch(companyName);
  }

  const resetFilters = () => {
    setFilters({
      startDate: moment().format("YYYY-MM-DD"),
      endDate: moment().format("YYYY-MM-DD"),
      itemNumber: "",
      category: "",
      manufacturer: "",
      subCategory: "",
      groupCode: "",
      family: "",
      supplierNumber: "",
    });
    setSuppListSearch("");
    setInvListSearch("");
  };

  return (
    <>
    <Container fluid className=" pt-3 pb-0">
      <Row>
        <Col xs="12" className="mb-0">
        <div className="px-3 mb-3 pt-1 border rounded-1 report-header" style={{display:"inline-block"}}>
          <h3 style={{color:'#fff'}}>Inventory Purchases Report</h3>
         </div>
          <div className="border ps-4 my-2" style={{ position: "relative" }}>
            <Label
              className="text-dark fs-5"
              style={{ position: "absolute", transform: "translateY(-50%)" }}
            >
            <span className=" px-1 wh-item-bg"> Filters</span>
            </Label>
            <Row>
              {/* <Col xs="6" xxl="7" className="pe-5">
                <Row className="my-3">
                
                </Row>
              </Col> */}

              <Col xs={1}>
                <div className="form-check my-3">
                  <Input
                    className="form-check-input"
                    type="checkbox"
                    id="purchases"
                    checked
                  />
                  <Label
                    className="form-check-label text-nowrap"
                    htmlFor="purchases"
                  >
                    Purchases
                  </Label>
                </div>
                <div className="form-check my-3">
                  <Input
                    className="form-check-input"
                    type="checkbox"
                    id="sales"
                    onChange={handleMainFilterChange}
                  />
                  <Label
                    className="form-check-label text-nowrap"
                    htmlFor="sales"
                  >
                    Sales
                  </Label>
                </div>
              </Col>

              <Col xs={2}>
                <Row className="my-3 mb-1">
                  <Col xs="12">
                    <Label className="mb-1">Date From</Label>
                    <Input
                      type="date"
                      className="form-control py-1"
                      name="startDate"
                      value={filters.startDate}
                      onChange={filtersChngHndlr}
                    />
                  </Col>
                </Row>
                <Row className="mb-1">
                  <Col xs="12">
                    <Label className="mb-1">Date To</Label>
                    <Input
                      type="date"
                      className="form-control py-1"
                      name="endDate"
                      value={filters.endDate}
                      onChange={filtersChngHndlr}
                    />
                  </Col>
                </Row>
              </Col>

              <Col xs={5}>
                <Row>
                  <Col xs={6} className="my-3 mb-1">
                    <Label
                      htmlFor="companyName"
                      className="form-label mb-1 text-nowrap"
                    >
                      Item No/Description:
                    </Label>
                    <SelectItem
                      searchValue={invListSearch}
                      setSearchValue={setInvListSearch}
                      listOptions={invListSrchOptions}
                      setSearchListOptions={setInvListSrchOptions}
                      columnsList={inventoryItemColumnsList}
                      placeholder="Select Item"
                      selectedItemHandler={selectInvListItemCallbck}
                      fieldKey="itemName"
                      dataIdKey="itemNumber"
                      showDropdownBtn={true}
                      addF3Shortcut={false}
                      dropdownBtnClickHndlr={(setShowOptions) => {
                        getInventoryListApiCall(true);
                        setShowOptions(true);
                      }}
                      callbackAfterBottomReached={(fn) => {
                        getInventoryListApiCall(
                          true,
                          currentInventoryListPage.current
                        );
                        callbackOnInventoryListApiResp.current = fn;
                      }}
                    />
                  </Col>
                  <Col xs={6} className="my-3 mb-1">
                    <Label
                      htmlFor="category"
                      className="form-label mb-1 text-nowrap"
                    >
                      Category:
                    </Label>
                    <SelectOption
                      id="category"
                      options={dropdownState.category}
                      categoryValue={filters.category}
                      filtersDropdownChngHndlr={filtersDropdownChngHndlr}
                    />
                  </Col>
                  <Col xs={6} className="mb-1">
                    <Label
                      htmlFor="manufacturer"
                      className="form-label mb-1 text-nowrap"
                    >
                      Manufacturer:
                    </Label>
                    <SelectOption
                      id="manufacturer"
                      options={dropdownState.manufacturer}
                      categoryValue={filters.manufacturer}
                      filtersDropdownChngHndlr={filtersDropdownChngHndlr}
                    />
                  </Col>
                  <Col xs={6} className="mb-1">
                    <Label
                      htmlFor="subCategory"
                      className="form-label mb-1 text-nowrap"
                    >
                      Sub Category:
                    </Label>
                    <SelectOption
                      id="subCategory"
                      options={dropdownState.subCategory}
                      categoryValue={filters.subCategory}
                      filtersDropdownChngHndlr={filtersDropdownChngHndlr}
                    />
                  </Col>
                  <Col xs={6} className="mb-1">
                    <Label
                      htmlFor="groupCode"
                      className="form-label mb-1 text-nowrap"
                    >
                      Group Code:
                    </Label>
                    <SelectOption
                      id="groupCode"
                      options={dropdownState.groupCode}
                      categoryValue={filters.groupCode}
                      filtersDropdownChngHndlr={filtersDropdownChngHndlr}
                    />
                  </Col>
                  <Col xs={6} className="mb-1">
                    <Label
                      htmlFor="family"
                      className="form-label mb-1 text-nowrap"
                    >
                      Family:
                    </Label> 
                    <SelectOption
                      id="family"
                      options={dropdownState.family}
                      categoryValue={filters.family}
                      filtersDropdownChngHndlr={filtersDropdownChngHndlr}
                    />
                  </Col>
                  <Col xs={6} className="mb-1">
                    <Label
                      htmlFor="companyName"
                      className="form-label mb-1 text-nowrap"
                    >
                      Supplier:
                    </Label>
                    <SelectItem
                      searchValue={suppListSearch}
                      setSearchValue={setSuppListSearch}
                      listOptions={suppListSrchOptions}
                      setSearchListOptions={setSuppListSrchOptions}
                      columnsList={supplierColumnsList}
                      placeholder="Select Supplier"
                      selectedItemHandler={selectSuppListItemCallbck}
                      fieldKey="supplierName"
                      dataIdKey="supplierNumber"
                      showDropdownBtn={true}
                      addF3Shortcut={false}
                      dropdownBtnClickHndlr={(setShowOptions) => {
                        getSuppliersListApiCall(true);
                        setShowOptions(true);
                      }}
                      callbackAfterBottomReached={(fn) => {
                        getSuppliersListApiCall(
                          true,
                          currentSupplierListPage.current
                        );
                        callbackOnSupplierListApiResp.current = fn;
                      }}
                    />
                  </Col>
                </Row>
              </Col>

              <Col
                xs="4"
                xxl="3"
                className="d-flex flex-column justify-content-center pe-xxl-4 pe-4"
              >
                <div className="d-flex align-items-center w-100 mb-1">
                  <div className="sale-wh-wrapper flex-grow-1 mt-0 me-xxl-4 me-2">
                    <div className="d-flex justify-content-around border border-section py-2 h-100">
                      {actions(togglePrintModal, generatePurchasesReport, resetFilters).map(
                        (item) => {
                          return (
                            <div
                              key={item.id}
                              className="action-icon-container"
                              style={{cursor:"pointer"}}
                              onClick={item.clickHandler || (() => {})}
                            >
                              <img className="action-icon" src={item.icon} />
                              <span>{item.label}</span>
                            </div>
                          );
                        }
                      )}
                    </div>
                  </div>
                  <div
                    className="d-flex flex-column align-items-center"
                    style={{ cursor: "pointer" }}
                    onClick={() => history.push("/reportsmenu")}
                  >
                    <img
                      src={BackIcon}
                      width="32"
                      height="32"
                      alt="back-icon"
                    />
                    <div className="text-decoration-underline mb-0">
                      Go Back
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-start border p-3">
                  <div className="form-check me-4">
                    <Input
                      className="form-check-input"
                      type="radio"
                      name="pdfTypeOuter"
                        id="byPO"
                        value="byPO"
                        checked={selectedRadio === 'byPO' || selectedRadio === ''}
                        onChange={handleRadioChange}
                    />
                    <Label
                      className="form-check-label text-nowrap"
                      htmlFor="byPO"
                    >
                      By PO
                    </Label>
                  </div>
                  <div className="form-check me-4">
                    <Input
                      className="form-check-input"
                      type="radio"
                        name="pdfTypeOuter"
                        id="byCategory"
                        value="byCategory"
                        checked={selectedRadio === 'byCategory'}
                        onChange={handleRadioChange}
                      
                    />
                    <Label
                      className="form-check-label text-nowrap"
                      htmlFor="byCategory"
                    >
                      By Category
                    </Label>
                  </div>
                  <div className="form-check me-4">
                    <Input
                      className="form-check-input"
                      type="radio"
                      name="pdfTypeOuter"
                      id="bySupplier"
                      value="bySupplier"
                      checked={selectedRadio === 'bySupplier'}
                      onChange={handleRadioChange}
                      
                    />
                    <Label
                      className="form-check-label text-nowrap"
                      htmlFor="bySupplier"
                    >
                      By Supplier
                    </Label>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
        <Col xs="12">
          <WhTableContainer
            columns={columns}
            data={tableData || []}
            className="custom-header-css"
            divClass="table-responsive table-container-inventory-purchases"
            tableClass="align-middle table-bordered"
            theadClass="table-light"
            handlePageChange={handlePageChange}
            pageCount={Math.ceil(
              (getInvPurchasesReportResp?.data?.total || 0) / 10
            )}
            customPageSize={30}
            showNoDataComponent={true}
          />
        </Col>
      </Row>
      <ApiLoader loading={getPurchasesReportLoading} />
    </Container>
     <Modal
     id="showPrintModal"
     isOpen={showPrintModal}
     toggle={togglePrintModal}
     centered
     size="lg"
   >
     <F12CloseModalListener onClose={togglePrintModal} />
     <ModalHeader className="py-2 px-3" toggle={togglePrintModal}>
       Print Options
     </ModalHeader>
     <Form
       onSubmit={(e) => {
         e.preventDefault();
       }}
     >
       <ModalBody className="px-0 pt-0">
         <PrintItem
           generatePurchasesReport={generatePurchasesReport}
           filters={filters}
           showPrintModal={showPrintModal}
         />
       </ModalBody>
     </Form>
   </Modal>
   <ApiLoader loading={getPurchasesReportLoading} />
   <ApiLoader loading={getInvPricingReportLoading} />
   </>
  );
};

export default InventoryPurchases;
