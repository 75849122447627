import departmentconstants from "./departmentconstants";

const initialState = {
  departmentApiLoading: undefined,
  createDepartmentResp: undefined,
  updateDepartmentResp: undefined,
  getDepartmentsResp: undefined,
  deleteDepartmentsResp: undefined,
  departmentErrorResp: undefined,
};

export default function department(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case departmentconstants.CHANGE_DEPARTMENT_API_LOADING_STATUS:
      return { ...state, departmentApiLoading: payload };
    case departmentconstants.CREATE_DEPARTMENT_SUCCESS:
      return { ...state, createDepartmentResp: payload };
    case departmentconstants.UPDATE_DEPARTMENT_SUCCESS:
      return { ...state, updateDepartmentResp: payload };
    case departmentconstants.GET_DEPARTMENTS_SUCCESS:
      return (state = { ...state, getDepartmentsResp: action.payload });
    case departmentconstants.DELETE_DEPARTMENTS_SUCCESS:
      return (state = { ...state, deleteDepartmentsResp: action.payload });

    case departmentconstants.CREATE_DEPARTMENT_FAIL:
    case departmentconstants.GET_DEPARTMENTS_FAIL:
    case departmentconstants.UPDATE_DEPARTMENT_FAIL:
    case departmentconstants.DELETE_DEPARTMENTS_FAIL:
      return { ...state, departmentErrorResp: payload };
    default:
      return state;
  }
}
