export default {
    CHANGE_DEPARTMENT_API_LOADING_STATUS: "CHANGE_DEPARTMENT_API_LOADING_STATUS",
  
    CREATE_DEPARTMENT_REQUEST: "CREATE_DEPARTMENT_REQUEST",
    CREATE_DEPARTMENT_SUCCESS: "CREATE_DEPARTMENT_SUCCESS",
    CREATE_DEPARTMENT_FAIL: "CREATE_DEPARTMENT_FAIL",
  
    UPDATE_DEPARTMENT_REQUEST: "UPDATE_DEPARTMENT_REQUEST",
    UPDATE_DEPARTMENT_SUCCESS: "UPDATE_DEPARTMENT_SUCCESS",
    UPDATE_DEPARTMENT_FAIL: "UPDATE_DEPARTMENT_FAIL",
  
    GET_DEPARTMENTS_REQUEST: "GET_DEPARTMENTS_REQUEST",
    GET_DEPARTMENTS_SUCCESS: "GET_DEPARTMENTS_SUCCESS",
    GET_DEPARTMENTS_FAIL: "GET_DEPARTMENTS_FAIL",

    DELETE_DEPARTMENTS_REQUEST: "DELETE_DEPARTMENTS_REQUEST",
    DELETE_DEPARTMENTS_SUCCESS: "DELETE_DEPARTMENTS_SUCCESS",
    DELETE_DEPARTMENTS_FAIL: "DELETE_DEPARTMENTS_FAIL",
  };
  