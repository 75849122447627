import React, { useMemo, useState, useEffect, useRef } from "react";
import { Col, Container, Row } from 'reactstrap';
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { toast } from "react-toastify";
import { actions, generateColumns } from "./helper";
import AddRole from "./AddRole";
import WhTableContainer from "../../../../../Components/Common/WhTables";
import ApiLoader from "../../../../../Components/Common/ApiLoader"
//Api Helper
import { generateApiUrl } from "../../../../../api/apihelper";
//Actions
import { getUserRolesAct } from "../../../../../store/usermanagement/usermanagementactions";

export function Roles() {
  const [tableData, setTableData] = useState([]);
  const [showAddRoleModal, setShowAddRoleModal] = useState(false);
  const [editModalData, setEditModalData] = useState(null);
  const dispatch = useDispatch();
  const toggleAddRoleModal = () => {
    if(showAddRoleModal) setEditModalData(null);
    setShowAddRoleModal(prev => !prev);
    
  }
  const { userManagementApiLoading, getUserRolesResp, createUserRoleResp, updateUserRoleResp, userManagementErrorResp } = useSelector(({ userManagement}) => ({
    userManagementApiLoading: userManagement.userManagementApiLoading,
    getUserRolesResp:userManagement.getUserRolesResp,
    createUserRoleResp: userManagement.createUserRoleResp,
    updateUserRoleResp: userManagement.updateUserRoleResp,
    userManagementErrorResp: userManagement.userManagementErrorResp
  }),shallowEqual);
  const prevApiResp = useRef({
    prevGetUserRolesResp: getUserRolesResp,
    prevCreateUserRoleResp: createUserRoleResp,
    prevUpdateUserRoleResp: updateUserRoleResp,
    prevUserManagementErrorResp: userManagementErrorResp
  });
  useEffect(() => {
    const { prevGetUserRolesResp, prevCreateUserRoleResp, prevUpdateUserRoleResp, prevUserManagementErrorResp } = prevApiResp.current || {};
    if (getUserRolesResp && prevGetUserRolesResp !== getUserRolesResp) {
     setTableData(getUserRolesResp?.data?.userRoles || [])
    }
    else if (createUserRoleResp && prevCreateUserRoleResp !== createUserRoleResp) {
      toast.success("Successfully created a user role")
      toggleAddRoleModal();
      dispatch(getUserRolesAct(generateApiUrl("get_user_roles")));
    }
    else if (updateUserRoleResp && prevUpdateUserRoleResp !== updateUserRoleResp) {
      toast.success("Successfully updated a user role")
      toggleAddRoleModal();
      dispatch(getUserRolesAct(generateApiUrl("get_user_roles")));
    }
    else if (userManagementErrorResp && prevUserManagementErrorResp !== userManagementErrorResp) {
      const { errors,error, message } = userManagementErrorResp || {};
      toast.error((Array.isArray(errors) && errors[0]) || error || message);
    }
    prevApiResp.current.prevGetUserRolesResp = getUserRolesResp;
    prevApiResp.current.prevCreateUserRoleResp = createUserRoleResp;
    prevApiResp.current.prevUpdateUserRoleResp = updateUserRoleResp;
    prevApiResp.current.prevUserManagementErrorResp = userManagementErrorResp;
  }, [ createUserRoleResp, updateUserRoleResp, getUserRolesResp, userManagementErrorResp ]);
  useEffect(() => {
    dispatch(getUserRolesAct(generateApiUrl("get_user_roles")));
  },[])
  const columns = useMemo(() => generateColumns(toggleAddRoleModal,setEditModalData), []);
  return (
    <React.Fragment>
      <div className="sale-wh-wrapper">
        <Container fluid>
          <Row className="px-4">
            <Col xs={12} className="d-flex justify-content-end align-items-end">
              <div className="d-flex justify-content-center border border-section py-2 ps-4 h-100">
                {actions(toggleAddRoleModal).map((item) => {
                  return (
                    <div key={item.id} className="action-icon-container me-4 px-2 show-cursor-pointer" onClick={item.clickHandler || (() => {})}>
                      <img className="action-icon" src={item.icon} />
                      <span>{item.label}</span>
                    </div>
                  );
                })}
              </div>
            </Col>
          </Row>
          <Row>
            <Col className="px-0" xs={12}>
              <WhTableContainer
                columns={columns}
                data={tableData}
                customPageSize={7}
                className="custom-header-css"
                divClass="table-responsive mt-3 table-height-66vh"
                tableClass="align-middle table-bordered"
                theadClass="table-light"
                showRowSelection={true}
              />
            </Col>
          </Row>
        </Container>
      </div>
      {showAddRoleModal && <AddRole isOpen={showAddRoleModal} toggle={toggleAddRoleModal} editModalData={editModalData} dispatch={dispatch} generateApiUrl={generateApiUrl}/>}
      <ApiLoader loading={userManagementApiLoading}/>
    </React.Fragment>
  );
}
