import React, { useState } from 'react';
//helper
import {actions} from "./helper";
import { Form, Modal, ModalBody, ModalHeader } from 'reactstrap';
import F12CloseModalListener from '../../Common/F12close';
import ApiLoader from '../../Common/ApiLoader';
import PrintItem from './PrintItem';

const SalesActions = ({ handelOpenPaymentsModal, history, toggleDeleteModal, initializeInvoiceDetails, id, getAllCustomersApiCall,getInvPricingReportLoading, outerDivClassName='py-2', 
  getInvoiceDetailsApiCall,  status, downloadInvoicePdfApiCall, toggleInvoiceResetConfirmModal, isBlocked, permissions, createInvoiceHandler, customerDetails}) => {
    const [showPrintModal, setShowPrintModal] = useState(false);
    function togglePrintModal() {
      setShowPrintModal(!showPrintModal);
    }
    return ( 
      <>
      <div className={`d-flex justify-content-center border border-section px-1 h-100 ${outerDivClassName} pt-1 pb-0`}>
      {actions( handelOpenPaymentsModal, history, toggleDeleteModal, initializeInvoiceDetails, id,
        getInvoiceDetailsApiCall, status, downloadInvoicePdfApiCall, toggleInvoiceResetConfirmModal, isBlocked, createInvoiceHandler, togglePrintModal, customerDetails).map((item) => {
        return (
          <div
            key={item.id}
            className={`action-icon-container ${(id === "new" || status === "posted" || !permissions["DELETE_OPEN_INV"]) && item.id === "delete" ? "opacity-50 show-cursor-not-allowed" : "show-cursor-pointer"}`}
            onClick={
              (id === "new" ||
                status === "posted" ||
                !permissions["DELETE_OPEN_INV"]) &&
              item.id === "delete"
                ? () => {}
                : item.clickHandler
                ? item.clickHandler
                : () => createInvoiceHandler(false)
            }
          >
            <img className="action-icon" src={item.icon} />
            <span>{item.label}</span>
          </div>
        );
      })}
    </div>
   
    <Modal
    id="showPrintModal"
    isOpen={showPrintModal}
    toggle={togglePrintModal}
    centered
    size="lg"
  >
    <F12CloseModalListener onClose={togglePrintModal} />
    <ModalHeader className="py-2 px-3" toggle={togglePrintModal}>
      Print Options
    </ModalHeader>
    <Form
      onSubmit={(e) => {
        e.preventDefault();
      }}
    >
      <ModalBody className="px-0 pt-0">
        <PrintItem
          getAllCustomersApiCall={getAllCustomersApiCall}
          showPrintModal={showPrintModal}
          togglePrintModal={togglePrintModal}
        />
      </ModalBody>
    </Form>
  </Modal>
  <ApiLoader loading={getInvPricingReportLoading} />
  </>
    );

}
 
export default SalesActions;