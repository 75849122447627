export const tabsOption = [
    {
        id:"product_category",
        name:"Product Category"
    },
    {
        id:"product_subcategory",
        name:"Product Subcategory"
    },
    {
        id:"manufacturers",
        name:"Manufacturers"
    },
    {
        id:"departments",
        name:"Departments"
    },
    {
        id:"designation",
        name:"Designation"
    },
    {
        id:"tax",
        name:"Tax"
    },
]