import GenerateTableIcon from "../../../../assets/images/warehouse/salespage/generate_tables.png";
import PrintIcon from "../../../../assets/images/warehouse/salespage/printer.png";
import ExportIcon from "../../../../assets/images/warehouse/salespage/excel.png";

export const actions = (toggle, togglePrintModal) => [
    {
        id: "reset",
        label: "Generate",
        icon: GenerateTableIcon
    }, {
        id: "print",
        label: "Print",
        icon: PrintIcon,
        clickHandler: togglePrintModal,
    }, {
        id: "export",
        label: "Export",
        icon: ExportIcon
    }
]

export function generateColumns() {
    return [
        {
            Header: "Code",
            accessor: "code"
        },
        {
            Header: "Company",
            accessor: "company"
        }, {
            Header: "Active",
            accessor: "active"
        }, {
            Header: "Sales",
            accessor: "sales"
        }, {
            Header: "Payments",
            accessor: "payments"
        }, {
            Header: "Total Owned",
            accessor: "totalOwned"
        }, {
            Header: "Customer Balance",
            accessor: "customerBalance"
        }, {
            Header: "INV Balance",
            accessor: "invBalance"
        }
    ]
}
export const tableData = [
    {
        code: "2402",
        company: "A & B Food Mart / Jamnaji 1 INC",
        active: "Y",
        sales: "4000",
        payments: "444",
        totalOwned: "900",
        customerBalance: "44444",
        invBalance: "1344",
    }, {
        code: "2402",
        company: "A & B Food Mart / Jamnaji 1 INC",
        active: "Y",
        sales: "4000",
        payments: "444",
        totalOwned: "900",
        customerBalance: "44444",
        invBalance: "1344",
    }, {
        code: "2402",
        company: "A & B Food Mart / Jamnaji 1 INC",
        active: "Y",
        sales: "4000",
        payments: "444",
        totalOwned: "900",
        customerBalance: "44444",
        invBalance: "1344",
    }, {
        code: "2402",
        company: "A & B Food Mart / Jamnaji 1 INC",
        active: "Y",
        sales: "4000",
        payments: "444",
        totalOwned: "900",
        customerBalance: "44444",
        invBalance: "1344",
    }, {
        code: "2402",
        company: "A & B Food Mart / Jamnaji 1 INC",
        active: "Y",
        sales: "4000",
        payments: "444",
        totalOwned: "900",
        customerBalance: "44444",
        invBalance: "1344",
    }, {
        code: "2402",
        company: "A & B Food Mart / Jamnaji 1 INC",
        active: "Y",
        sales: "4000",
        payments: "444",
        totalOwned: "900",
        customerBalance: "44444",
        invBalance: "1344",
    }, {
        code: "2402",
        company: "A & B Food Mart / Jamnaji 1 INC",
        active: "Y",
        sales: "4000",
        payments: "444",
        totalOwned: "900",
        customerBalance: "44444",
        invBalance: "1344",
    }, {
        code: "2402",
        company: "A & B Food Mart / Jamnaji 1 INC",
        active: "Y",
        sales: "4000",
        payments: "444",
        totalOwned: "900",
        customerBalance: "44444",
        invBalance: "1344",
    }, {
        code: "2402",
        company: "A & B Food Mart / Jamnaji 1 INC",
        active: "Y",
        sales: "4000",
        payments: "444",
        totalOwned: "900",
        customerBalance: "44444",
        invBalance: "1344",
    }, {
        code: "2402",
        company: "A & B Food Mart / Jamnaji 1 INC",
        active: "Y",
        sales: "4000",
        payments: "444",
        totalOwned: "900",
        customerBalance: "44444",
        invBalance: "1344",
    }, {
        code: "2402",
        company: "A & B Food Mart / Jamnaji 1 INC",
        active: "Y",
        sales: "4000",
        payments: "444",
        totalOwned: "900",
        customerBalance: "44444",
        invBalance: "1344",
    },
];