//Utility
import { getMmddyyyyDateFormatted, getCustomDateFormatted } from "../../../../utility/dateutility";
import {formatAmount} from "../../../../utility/commonutility"

export function generateColumns(history) {
    return [
        {
            Header: "Date Paid",
            accessor: "datePaid",
            Cell:({cell:{value}}) => value ? getMmddyyyyDateFormatted(value) : "",
        }, 
        {
            Header: "Time",
            accessor: "time",
            Cell:({row:{original:{datePaid}}}) => datePaid ? getCustomDateFormatted(datePaid,"hh:mm A") : "",
        }, 
        {
            Header: "Hold Date",
            accessor: "holdDate",
            Cell:({cell:{value}}) => value ? getMmddyyyyDateFormatted(value) : "",
        }, 
        {
            Header: "SID",
            accessor: "sId"
        }, {
            Header: "Salesman",
            accessor: "salesman.name"
        }, {
            Header: "Payment Mode",
            accessor: "paymentMode"
        }, {
            Header: "Check#",
            accessor: "chequeNo",
            Cell:({cell:{value}}) => value || "-"
        }, {
            Header: "Amount Paid",
            accessor: "amountPaid",
            Cell : ({cell : {value}}) => (typeof value == "number") ? <>{formatAmount(value.toFixed(2))}</> : "",
            style: {textAlign:"right"}
        },{
            Header: "Amount Allocated",
            accessor: "amountAllocated",
            Cell : ({cell : {value}}) => (typeof value == "number") ? <>{formatAmount(value.toFixed(2))}</> : "",
            style: {textAlign:"right"}
        }, {
            Header: "Change",
            accessor: "change",
            Cell : ({cell : {value}}) => (typeof value == "number") ? <>{formatAmount(value.toFixed(2))}</> : `${formatAmount(0)}`,
            style: {textAlign:"right"}
        }
    ]
}