import React, { useMemo, useState, useRef, useCallback, useEffect } from "react";
import moment from "moment";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { Container, Row, Col, Label, Input, Button, Modal, ModalHeader, Form, ModalBody } from "reactstrap";
import { toast } from "react-toastify";
import { actions, generateColumns, customerColumnsList } from "./helper";
import BackIcon from "../../../assets/images/warehouse/menu/back.png";
import WhTableContainer from "../../../Components/Common/WhTables";
import ApiLoader from "../../../Components/Common/ApiLoader";
import SelectItem from "../../../Components/Common/SelectItem";
import { getStartDateFilterValue, getEndDateFilterValue } from "../../../utility/dateutility";
//Api Helper
import { generateApiUrl } from "../../../api/apihelper";
//Actions
import { getInvPricingReportPrintAct, getReturnSalesReportAct } from "../../../store/reports/reportsactions";
import { getAllCustomersAct } from "../../../store/customer/customeractions";
import F12CloseModalListener from "../../../Components/Common/F12close";
import PrintItem from "./PrintItem";

const PaymentHistory = ({ history }) => {
  const [filters, setFilters] = useState({
    startDate: moment().format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
    type: "all",
    customerNumber: "",
    invoiceNumber: "",
  });
  const [showPrintModal, setShowPrintModal] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [custListSearch, setCustListSearch] = useState("");
  const [custListSrchOptions, setCustListSrchOptions] = useState([]);
  const dispatch = useDispatch();
  const isDownloadBtnClicked = useRef(false);
  const custListDropdownBtnClicked = useRef(false);
  const callbackOnCustomerListApiResp = useRef(() => {});
  const currentCustomerListPage = useRef(1);
  const currentPageSize = useRef(30);
  function togglePrintModal() {
    setShowPrintModal(!showPrintModal);
  }
  const [pageCount, setPageCount] = useState(0);
  const { getAllCustomersResp,getInvPricingReportLoading, getReturnSalesReportResp, getReturnSalesReportLoading, reportsErrorResp } = useSelector(
    ({ customer, reports }) => ({
      getAllCustomersResp: customer.getAllCustomersResp,
      getReturnSalesReportResp: reports.getReturnSalesReportResp,
      getInvPricingReportLoading: reports.getInvPricingReportLoading,
      getReturnSalesReportLoading: reports.getReturnSalesReportLoading,
      reportsErrorResp: reports.reportsErrorResp,
    }),
    shallowEqual
  );
  const customerResp = useRef({
    prevGetAllCustomersResp: getAllCustomersResp,
    prevGetReturnSalesReportResp: getReturnSalesReportResp,
    prevReportsErrorResp: reportsErrorResp,
  });
  useEffect(() => {
    const { prevGetAllCustomersResp, prevGetReturnSalesReportResp, prevReportsErrorResp } = customerResp.current || {};
    if (getAllCustomersResp && prevGetAllCustomersResp !== getAllCustomersResp) {
      const list = [];
      (getAllCustomersResp?.data?.customers || []).forEach(({ customerNumber, firstName, lastName, companyName }) => {
        list.push({
          customerNumber,
          customerName: `${firstName || ""} ${lastName || ""}`,
          companyName,
        });
      });
      if (!custListDropdownBtnClicked.current) setCustListSrchOptions(list);
      else {
        setCustListSrchOptions((prev) => [...prev, ...list]);
        if (getAllCustomersResp?.data?.customers?.length > 0) callbackOnCustomerListApiResp.current();
        currentCustomerListPage.current = Number(getAllCustomersResp?.data?.page) + 1;
      }
    } else if (getReturnSalesReportResp && prevGetReturnSalesReportResp !== getReturnSalesReportResp) {
      if (isDownloadBtnClicked.current) {
        const url = window.URL.createObjectURL(new Blob([getReturnSalesReportResp]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
         "return_sales_report.csv"
        );
        document.body.appendChild(link);
        link.click();
        link.remove();
        isDownloadBtnClicked.current = false;
      } else {
        setTableData(getReturnSalesReportResp?.data?.report || []);
        currentPageSize.current = getReturnSalesReportResp?.data?.pageSize;
        setPageCount((getReturnSalesReportResp?.data?.total))
      }
    } else if (reportsErrorResp && prevReportsErrorResp !== reportsErrorResp) {
      const { errors, error, message } = reportsErrorResp || {};
      toast.error((Array.isArray(errors) && errors[0].msg) || error || message);
    }
    customerResp.current.prevGetAllCustomersResp = getAllCustomersResp;
    customerResp.current.prevGetReturnSalesReportResp = getReturnSalesReportResp;
    customerResp.current.prevReportsErrorResp = reportsErrorResp;
  }, [getAllCustomersResp, getReturnSalesReportResp, reportsErrorResp]);

  const getSuppliersReportApiCall = useCallback(
    (page, pageSize, download, downloadType='pdf') => {
      const { startDate, endDate, type, customerNumber, invoiceNumber } = filters;
      let params = {};
      if (!download) {
        params.page = page ? page + 1 : 1;
        params.pageSize = pageSize || currentPageSize.current;
      }
      if (startDate) params.startDate = getStartDateFilterValue(startDate);
      if (endDate) params.endDate = getEndDateFilterValue(endDate);
      if (type) params.type = type;
      if (customerNumber) params.customerNumber = customerNumber;
      if (invoiceNumber) params.invoiceNumber = invoiceNumber;
      if (download && downloadType === "html") {
        params.download = true;
        params.downloadType = downloadType;
        dispatch(
          getInvPricingReportPrintAct(
            generateApiUrl(`get_return_sales_report`),
            params
          )
        );
      } else if (download) {
        params.download = true;
        isDownloadBtnClicked.current = true;
        dispatch(
          getReturnSalesReportAct(
            generateApiUrl(`get_return_sales_report`),
            params,
            { responseType: "blob" }
          )
        );
      } else {
        dispatch(
          getReturnSalesReportAct(
            generateApiUrl(`get_return_sales_report`),
            params
          )
        );
      }
    },
    [filters]
  );

  useEffect(() => {
    const debounceFn = setTimeout(() => {
      getSuppliersReportApiCall();
    }, 400);
    return () => {
      clearTimeout(debounceFn);
    };
  }, [getSuppliersReportApiCall]);

  const getAllCustomersApiCall = useCallback(
    (showAllCustomers, page) => {
      if (showAllCustomers) {
        let params = { page: page || 1, pageSize: 50, orderBy: "company", sort: "asc" };
        dispatch(getAllCustomersAct(generateApiUrl(`get_all_customers`), params));
        custListDropdownBtnClicked.current = true;
      } else if (custListSearch) {
        let params = { page: 1, pageSize: 5, search: custListSearch, orderBy: "company", sort: "asc" };
        dispatch(getAllCustomersAct(generateApiUrl(`get_all_customers`), params));
        custListDropdownBtnClicked.current = false;
      }
    },
    [custListSearch]
  );

  useEffect(() => {
    const debounceFn = setTimeout(() => {
      getAllCustomersApiCall();
    }, 200);
    return () => {
      clearTimeout(debounceFn);
    };
  }, [getAllCustomersApiCall]);

  const handlePageChange = (page, pageSize) => {
    getSuppliersReportApiCall(page, pageSize);
  };

  const filtersChngHndlr = (e) => {
    const { value, checked, name, type } = e.target;
    setFilters((prev) => ({ ...prev, [name]: type === "checkbox" ? checked : value }));
  };

  const columns = useMemo(() => generateColumns(), []);

  const handleMainFilterChange = (e) => {
    const { id } = e.target;
    setFilters((prev) => ({ ...prev, type: id }));
  };
  function selectItemCallbck(itemDetails) {
    const { customerNumber } = itemDetails;
    setFilters((prev) => ({ ...prev, customerNumber }));
  }

  const resetFilters = () => {
    setFilters({
      startDate: moment().format("YYYY-MM-DD"),
      endDate: moment().format("YYYY-MM-DD"),
      type: "all",
      customerNumber: "",
      invoiceNumber: "",
    });
    setCustListSearch("");
  }
  return (
   <>
    <Container fluid className=" pt-3 pb-0">
      <Row>
        <Col xs="12" className="mb-0">
        <div className="px-3 mb-3 pt-1 border rounded-1 report-header" style={{display:"inline-block"}}>
          <h3 style={{color:'#fff'}}>Returns and Sales Report</h3>
         </div>
          <div className="border ps-4 my-2" style={{ position: "relative" }}>
            <Label className="text-dark fs-5" style={{ position: "absolute", transform: "translateY(-50%)" }}>
            <span className=" px-1 wh-item-bg"> Filters</span>
            </Label>
            <Row>
              <Col xs="6" xxl="7" className="pe-5">
                <Row className="my-2">
                  <Col xs={4}>
                    <div className="form-check my-3">
                      <Input
                        className="form-check-input"
                        type="checkbox"
                        id="all"
                        checked={filters.type === "all"}
                        onChange={handleMainFilterChange}
                      />
                      <Label className="form-check-label text-nowrap" htmlFor="all">
                        All Sales
                      </Label>
                    </div>

                    <div className="form-check my-3">
                      <Input
                        className="form-check-input"
                        type="checkbox"
                        id="sales"
                        checked={filters.type === "sales"}
                        onChange={handleMainFilterChange}
                      />
                      <Label className="form-check-label text-nowrap" htmlFor="sales">
                        Sales
                      </Label>
                    </div>

                    <div className="form-check my-3">
                      <Input
                        className="form-check-input"
                        type="checkbox"
                        id="returns"
                        checked={filters.type === "returns"}
                        onChange={handleMainFilterChange}
                      />
                      <Label className="form-check-label text-nowrap" htmlFor="returns">
                        Returns
                      </Label>
                    </div>
                  </Col>
                  <Col xs={4}>
                    <Row className="my-3 mb-1">
                      <Col xs="12">
                        <Label className="mb-1">Date From</Label>
                        <Input
                          type="date"
                          className="form-control py-1"
                          name="startDate"
                          value={filters.startDate}
                          onChange={filtersChngHndlr}
                        />
                      </Col>
                    </Row>
                    <Row className="mb-1">
                      <Col xs="12">
                        <Label className="mb-1">Date To</Label>
                        <Input
                          type="date"
                          className="form-control py-1"
                          name="endDate"
                          value={filters.endDate}
                          onChange={filtersChngHndlr}
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={4}>
                    <Row className="mt-3 mb-1">
                      <Col xs="12">
                        <Label htmlFor="userrole" className="form-label mb-0">
                          Customer
                        </Label>
                        {/* {JSON.stringify(custListSrchOptions)} */}
                        <div className="d-flex align-items-center">
                          <SelectItem
                            searchValue={custListSearch}
                            setSearchValue={setCustListSearch}
                            listOptions={custListSrchOptions}
                            setSearchListOptions={setCustListSrchOptions}
                            columnsList={customerColumnsList}
                            placeholder="Select Customer"
                            selectedItemHandler={selectItemCallbck}
                            fieldKey="companyName"
                            dataIdKey="customerNumber"
                            containerClassName={"flex-grow-1"}
                            addF3Shortcut={false}
                            showDropdownBtn={true}
                            dropdownBtnClickHndlr={(setShowOptions) => {
                              getAllCustomersApiCall(true);
                              setShowOptions(true);
                            }}
                            callbackAfterBottomReached={(fn) => {
                              getAllCustomersApiCall(true, currentCustomerListPage.current);
                              callbackOnCustomerListApiResp.current = fn;
                            }}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs="12">
                        <Label className="mb-0 me-3 mt-3">Search Invoice No: </Label>
                        <div className="form-icon d-flex align-items-center">
                          <Input
                            type="text"
                            className="form-control py-1 form-control py-1-icon"
                            name="invoiceNumber"
                            value={filters.invoiceNumber}
                            onChange={filtersChngHndlr}
                          />
                          <i className="ri-search-line fs-5"></i>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
              <Col xs="2" className="pt-3"></Col>
              <Col xs="4" xxl="3" className="d-flex flex-column justify-content-center pe-xxl-5 pe-4">
                <div className="d-flex align-items-center w-100 mb-1">
                  <div className="sale-wh-wrapper flex-grow-1 mt-0 me-xxl-4 me-2">
                    <div className="d-flex justify-content-around border border-section py-2 h-100">
                      {actions(togglePrintModal,getSuppliersReportApiCall,resetFilters).map((item) => {
                        return (
                          <div
                            key={item.id}
                            className="action-icon-container"
                            onClick={item.clickHandler || (() => {})}
                          >
                            <img className="action-icon" src={item.icon} />
                            <span>{item.label}</span>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <div
                    className="d-flex flex-column align-items-center"
                    style={{ cursor: "pointer" }}
                    onClick={() => history.push("/reportsmenu")}
                  >
                    <img src={BackIcon} width="32" height="32" alt="back-icon" />
                    <div className="text-decoration-underline mb-0">Go Back</div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
        <Col xs="12">
          <WhTableContainer
            columns={columns}
            data={tableData || []}
            className="custom-header-css"
            divClass="table-responsive table-container-return-sales"
            tableClass="align-middle table-bordered"
            theadClass="table-light"
            handlePageChange={handlePageChange}
            pageCount={pageCount || 0}
            customPageSize={30}
            showNoDataComponent={true}
          />
        </Col>
      </Row>
      <ApiLoader loading={getReturnSalesReportLoading} />
    </Container>
    <Modal
     id="showPrintModal"
     isOpen={showPrintModal}
     toggle={togglePrintModal}
     centered
     size="lg"
   >
     <F12CloseModalListener onClose={togglePrintModal} />
     <ModalHeader className="py-2 px-3" toggle={togglePrintModal}>
       Print Options
     </ModalHeader>
     <Form
       onSubmit={(e) => {
         e.preventDefault();
       }}
     >
       <ModalBody className="px-0 pt-0">
         <PrintItem
           getSuppliersReportApiCall={getSuppliersReportApiCall}
           filters={filters}
           showPrintModal={showPrintModal}
         />
       </ModalBody>
     </Form>
   </Modal>
   <ApiLoader loading={getInvPricingReportLoading} />
   </>
  );
};

export default PaymentHistory;
