import React, { useMemo, useEffect, useRef, useState, useCallback } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { Container, Row, Col, Label, Input, Button, Modal, ModalHeader, Form, ModalBody } from "reactstrap";
import { actions, generateColumns, tableData, supplierColumnsList } from "./helper";
import BackIcon from "../../../assets/images/warehouse/menu/back.png";
import WhTableContainer from "../../../Components/Common/WhTables";
import SelectItem from "../../../Components/Common/SelectItem";
import ApiLoader from "../../../Components/Common/ApiLoader";
import { getStartDateFilterValue, getEndDateFilterValue } from "../../../utility/dateutility";
//Api Helper
import { generateApiUrl } from "../../../api/apihelper";
//Actions
import { getInvPricingReportPrintAct, getPoAnalysisReportAct } from "../../../store/reports/reportsactions";
import { getSuppliersListAct } from "../../../store/supplier/supplieractions";
import F12CloseModalListener from "../../../Components/Common/F12close";
import PrintItem from "./PrintItem";

const POAnalysis = ({ history }) => {
  const [showPrintModal, setShowPrintModal] = useState(false);
  const [filters, setFilters] = useState({
    supplierNumber: "",
    poNumber: "",
    paymentStatus: "",
    isOutOfState: false,
    isLocalTaxesPaid: false,
    isReportToState: false,
    startDate: "",
    endDate: "",
    dateType: "poDate",
    notReceived: false,
  });
  const [search, setSearch] = useState("");
  const [pageCount, setPageCount] = useState(0);
  const [searchListOptions, setSearchListOptions] = useState([]);
  function togglePrintModal() {
    setShowPrintModal(!showPrintModal);
  }
  const [tableData, setTableData] = useState([]);
  const currentSupplierListPage = useRef(1);
  const isDownloadBtnClicked = useRef(false);
  const dropdownBtnClicked = useRef(false);
  const callbackOnSupplierListApiResp = useRef(() => {});
  const dispatch = useDispatch();
  const { getPoAnalysisReportResp, getPoAnalysisReportLoading, getInvPricingReportLoading, getSuppliersListResp, reportsErrorResp, supplierErrorResp } = useSelector(
    ({ reports, supplier }) => ({
      getPoAnalysisReportResp: reports.getPoAnalysisReportResp,
      getPoAnalysisReportLoading: reports.getPoAnalysisReportLoading,
      getInvPricingReportLoading: reports.getInvPricingReportLoading,
      getSuppliersListResp: supplier.getSuppliersListResp,
      reportsErrorResp: reports.reportsErrorResp,
      supplierErrorResp: supplier.supplierErrorResp,
    }),
    shallowEqual
  );
  const customerResp = useRef({
    prevgetPoAnalysisReportResp: getPoAnalysisReportResp,
    prevGetSuppliersListResp: getSuppliersListResp,
    prevReportsErrorResp: reportsErrorResp,
    prevSupplierErrorResp: supplierErrorResp,
  });
  useEffect(() => {
    const { prevgetPoAnalysisReportResp, prevGetSuppliersListResp, prevReportsErrorResp, prevSupplierErrorResp } =
      customerResp.current || {};
    if (getPoAnalysisReportResp && prevgetPoAnalysisReportResp !== getPoAnalysisReportResp) {
      if (isDownloadBtnClicked.current) {
        const url = window.URL.createObjectURL(new Blob([getPoAnalysisReportResp]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
         "po_analysis_report.csv"
        );
        document.body.appendChild(link);
        link.click();
        link.remove();
        isDownloadBtnClicked.current = false;
      } else {
        setTableData(getPoAnalysisReportResp?.data?.report || []);
        setPageCount((getPoAnalysisReportResp?.data?.total))
      }
    } else if (getSuppliersListResp && prevGetSuppliersListResp !== getSuppliersListResp) {
      const list = [];
      (getSuppliersListResp?.data?.suppliers || []).forEach(({ supplierNumber, firstName, lastName, supplierName }) => {
        list.push({
          supplierNumber,
          supplierName: `${firstName || ""} ${lastName || ""}`,
          companyName: supplierName,
        });
      });
      if (!dropdownBtnClicked.current) setSearchListOptions(list);
      else {
        setSearchListOptions((prev) => [...prev, ...list]);
        if (getSuppliersListResp?.data?.suppliers?.length > 0) callbackOnSupplierListApiResp.current();
        currentSupplierListPage.current = Number(getSuppliersListResp?.data?.page) + 1;
      }
    } else if (reportsErrorResp && prevReportsErrorResp !== reportsErrorResp) {
      const { errors, error, message } = reportsErrorResp || {};
      toast.error((Array.isArray(errors) && errors[0].msg) || error || message);
    } else if (supplierErrorResp && prevSupplierErrorResp !== supplierErrorResp) {
      const { errors, error, message } = supplierErrorResp || {};
      toast.error((Array.isArray(errors) && errors[0].msg) || error || message);
    }
    customerResp.current.prevgetPoAnalysisReportResp = getPoAnalysisReportResp;
    customerResp.current.prevGetSuppliersListResp = getSuppliersListResp;
    customerResp.current.prevReportsErrorResp = reportsErrorResp;
    customerResp.current.prevSupplierErrorResp = supplierErrorResp;
  }, [getPoAnalysisReportResp, getSuppliersListResp, reportsErrorResp, supplierErrorResp]);

  const getSuppliersReportApiCall = useCallback(
    (page, pageSize, download, downloadType = 'pdf') => {
      const {
        supplierNumber,
        poNumber,
        paymentStatus,
        isOutOfState,
        isLocalTaxesPaid,
        isReportToState,
        startDate,
        endDate,
        dateType,
        notReceived,
      } = filters;
      let params = {
        dateType,
      };
      if (!download) {
        params.page = page ? page + 1 : 1;
        params.pageSize = pageSize || getPoAnalysisReportResp?.data?.pageSize || 30;
      }
      if (isOutOfState) params.isOutOfState = isOutOfState;
      if (isLocalTaxesPaid) params.isLocalTaxesPaid = isLocalTaxesPaid;
      if (isReportToState) params.isReportToState = isReportToState;
      if (supplierNumber) params.supplierNumber = supplierNumber;
      if (poNumber) params.poNumber = poNumber;
      if (paymentStatus) params.paymentStatus = paymentStatus;
      if (startDate) params.startDate = getStartDateFilterValue(startDate);
      if (endDate) params.endDate = getEndDateFilterValue(endDate);
      if (notReceived) params.notReceived = notReceived;
      if (download && downloadType === 'html') {
        params.download = true;
        params.downloadType = downloadType;
        dispatch(
          getInvPricingReportPrintAct(
            generateApiUrl(`get_po_analysis_report`),
            params
          )
        );
      } 
      else if (download){
        params.download = true;
        isDownloadBtnClicked.current = true; 
        dispatch(
          getPoAnalysisReportAct(
            generateApiUrl(`get_po_analysis_report`),
            params,
            {responseType: 'blob'}
          )
        );}
        else {
          dispatch(
            getPoAnalysisReportAct(
              generateApiUrl(`get_po_analysis_report`),
              params
            )
          );
        }
      },
    [filters]
  );

  const getSuppliersListApiCall = useCallback(
    (showAllCustomers, page) => {
      if (showAllCustomers) {
        let params = {
          page: page || 1,
          pageSize: 50,
          orderBy: "supplierName",
          sort: "asc",
          active: true,
        };
        dispatch(getSuppliersListAct(generateApiUrl(`get_suppliers_list`), params));
        dropdownBtnClicked.current = true;
      } else if (search) {
        let params = {
          page: 1,
          pageSize: 5,
          orderBy: "supplierName",
          sort: "asc",
          active: true,
        };
        if (search && isNaN(Number(search))) params.supplierName = search;
        else params.supplierNumber = search;
        dispatch(getSuppliersListAct(generateApiUrl(`get_suppliers_list`), params));
        dropdownBtnClicked.current = false;
      }
    },
    [search]
  );

  useEffect(() => {
    const debounceFn = setTimeout(() => {
      getSuppliersListApiCall();
    }, 200);
    return () => {
      clearTimeout(debounceFn);
    };
  }, [getSuppliersListApiCall]);

  useEffect(() => {
    const debounceFn = setTimeout(() => {
      getSuppliersReportApiCall();
    }, 400);
    return () => {
      clearTimeout(debounceFn);
    };
  }, [getSuppliersReportApiCall]);

  const handlePageChange = (page, pageSize) => {
    getSuppliersReportApiCall(page, pageSize);
  };

  const filtersChngHndlr = (e) => {
    const { value, checked, name, type } = e.target;
    setFilters((prev) => ({ ...prev, [name]: type === "checkbox" ? checked : value }));
  };

  const handlePaymentStatusChange = (e) => {
    const { name } = e.target;
    setFilters((prev) => ({ ...prev, paymentStatus: name === "all" ? "" : name }));
  };

  const handleDateTypeChng = (e) => {
    const { name } = e.target;
    setFilters((prev) => ({ ...prev, dateType: name }));
  };

  function selectItemCallbck(itemDetails) {
    const { supplierNumber, companyName } = itemDetails;
    setFilters((prev) => ({ ...prev, supplierNumber }));
    setSearch(companyName);
  }

  const resetFilters = () => {
    setFilters({
      supplierNumber: "",
      poNumber: "",
      paymentStatus: "",
      isOutOfState: false,
      isLocalTaxesPaid: false,
      isReportToState: false,
      startDate: "",
      endDate: "",
      dateType: "poDate",
      notReceived: false,
    });
    setSearch("");
  }

  const columns = useMemo(() => generateColumns(), []);
  return (
   <>
    <Container fluid className=" pt-2 pb-0">
      <ToastContainer />
      <Row>
        <Col xs="12" className="mb-2">
        <div className="px-3 mb-3 pt-1 border rounded-1 report-header" style={{display:"inline-block"}}>
          <h3 style={{color:'#fff'}}>PO Analysis Report</h3>
         </div>
     
          <div className="border ps-4 my-2" style={{ position: "relative" }}>
            <Label className="fs-5" style={{ position: "absolute", transform: "translateY(-50%)" }}>
            <span className=" px-1 wh-item-bg"> Filters</span>
            </Label>
            <Row>
              <Col xs="7" className="border-end pe-5">
                <Row className="my-2 mt-3">
                  <Col xs={4}>
                    <Label htmlFor="companyName" className="form-label mb-0 text-nowrap">
                      Supplier
                    </Label>
                    <SelectItem
                      searchValue={search}
                      setSearchValue={setSearch}
                      listOptions={searchListOptions}
                      setSearchListOptions={setSearchListOptions}
                      columnsList={supplierColumnsList}
                      placeholder="Select Supplier"
                      selectedItemHandler={selectItemCallbck}
                      fieldKey="supplierName"
                      dataIdKey="supplierNumber"
                      showDropdownBtn={true}
                      // addF3Shortcut={false}
                      dropdownBtnClickHndlr={(setShowOptions) => {
                        getSuppliersListApiCall(true);
                        setShowOptions(true);
                      }}
                      callbackAfterBottomReached={(fn) => {
                        getSuppliersListApiCall(true, currentSupplierListPage.current);
                        callbackOnSupplierListApiResp.current = fn;
                      }}
                    />
                  </Col>
                  <Col xs={4} className="d-flex align-items-end">
                    <div className="d-flex align-items-center justify-content-around border py-2 flex-grow-1">
                      <div className="d-flex align-items-center">
                        <Input
                          name="all"
                          type="radio"
                          checked={filters.paymentStatus === ""}
                          className="mb-1"
                          onChange={handlePaymentStatusChange}
                        />
                        <Label className="ms-1 mb-0">All</Label>
                      </div>
                      <div className="d-flex align-items-center">
                        <Input
                          name="paid"
                          type="radio"
                          checked={filters.paymentStatus === "paid"}
                          className="mb-1"
                          onChange={handlePaymentStatusChange}
                        />
                        <Label className="ms-1 mb-0">Paid</Label>
                      </div>
                      <div className="d-flex align-items-center">
                        <Input
                          name="unpaid"
                          type="radio"
                          checked={filters.paymentStatus === "unpaid"}
                          className="mb-1"
                          onChange={handlePaymentStatusChange}
                        />
                        <Label className="ms-1 mb-0">Unpaid</Label>
                      </div>
                    </div>
                  </Col>
                  <Col xs={4}>
                    <Label htmlFor="companyName" className="form-label mb-0 text-nowrap">
                      Date From
                    </Label>
                    <Input
                      className="form-control py-1"
                      type="date"
                      name="startDate"
                      value={filters.startDate}
                      onChange={filtersChngHndlr}
                    />
                  </Col>
                </Row>

                <Row className="mb-2">
                  <Col xs={4}>
                    <Label htmlFor="companyName" className="form-label mb-0 text-nowrap">
                      User
                    </Label>
                    <select className="form-select py-1">
                      <option>Select User</option>
                    </select>
                    <Label className="mb-0 me-3 mt-3">Search PO, Inv Num: </Label>
                    <div className="form-icon d-flex align-items-center">
                      <Input
                        type="email"
                        className="form-control py-1 form-control-icon"
                        name="poNumber"
                        value={filters.poNumber}
                        onChange={filtersChngHndlr}
                      />
                      <i className="ri-search-line fs-5"></i>
                    </div>
                  </Col>
                  <Col xs={4} className="ps-4">
                    <div className="form-check my-2">
                      <Input
                        className="form-check-input"
                        type="checkbox"
                        id="isOutOfState"
                        name="isOutOfState"
                        checked={filters.isOutOfState}
                        onChange={filtersChngHndlr}
                      />
                      <Label className="form-check-label text-nowrap" htmlFor="isOutOfState">
                        Out of State
                      </Label>
                    </div>
                    <div className="form-check mb-2">
                      <Input
                        className="form-check-input"
                        type="checkbox"
                        id="isLocalTaxesPaid"
                        name="isLocalTaxesPaid"
                        checked={filters.isLocalTaxesPaid}
                        onChange={filtersChngHndlr}
                      />
                      <Label className="form-check-label text-nowrap" htmlFor="isLocalTaxesPaid">
                        Local Taxes Paid
                      </Label>
                    </div>
                    <div className="form-check mb-2">
                      <Input
                        className="form-check-input"
                        type="checkbox"
                        id="isReportToState"
                        name="isReportToState"
                        checked={filters.isReportToState}
                        onChange={filtersChngHndlr}
                      />
                      <Label className="form-check-label text-nowrap" htmlFor="isReportToState">
                        Report to State
                      </Label>
                    </div>
                  </Col>
                  <Col xs={4}>
                    <Label htmlFor="companyName" className="form-label mb-0 text-nowrap">
                      Date To:
                    </Label>
                    <Input
                      className="form-control py-1"
                      type="date"
                      name="endDate"
                      value={filters.endDate}
                      onChange={filtersChngHndlr}
                    />
                  </Col>
                </Row>
              </Col>
              <Col xs="2" className="border-end">
                <div className="form-check my-3">
                  <Input
                    className="form-check-input"
                    type="radio"
                    id="poDate"
                    name="poDate"
                    checked={filters.dateType === "poDate"}
                    onChange={handleDateTypeChng}
                  />
                  <Label className="form-check-label text-nowrap" htmlFor="poDate">
                    PO Date
                  </Label>
                </div>
                <div className="form-check mb-3">
                  <Input
                    className="form-check-input"
                    type="radio"
                    id="received"
                    name="received"
                    checked={filters.dateType === "received"}
                    onChange={handleDateTypeChng}
                  />
                  <Label className="form-check-label text-nowrap" htmlFor="received">
                    Received Date
                  </Label>
                </div>
                <div className="form-check mb-3">
                  <Input
                    className="form-check-input"
                    type="checkbox"
                    id="notReceived"
                    name="notReceived"
                    checked={filters.notReceived}
                    onChange={filtersChngHndlr}
                  />
                  <Label className="form-check-label text-nowrap" htmlFor="notReceived">
                    Not Received
                  </Label>
                </div>
              </Col>
              <Col xs="3" className="d-flex align-items-center pe-xxl-5 pe-4">
                <div className="sale-wh-wrapper flex-grow-1 mt-0 me-xxl-4 me-2">
                  <div className="d-flex justify-content-around border border-section py-2 h-100">
                    {actions(togglePrintModal,getSuppliersReportApiCall,resetFilters).map((item) => {
                      return (
                        <div key={item.id} className="action-icon-container" onClick={item.clickHandler || (() => {})}  style={{cursor:"pointer"}}>
                          <img className="action-icon" src={item.icon} />
                          <span>{item.label}</span>
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div
                  className="d-flex flex-column align-items-center"
                  style={{ cursor: "pointer" }}
                  onClick={() => history.push("/reportsmenu")}
                >
                  <img src={BackIcon} width="32" height="32" alt="back-icon" />
                  <div className="text-decoration-underline mb-0">Go Back</div>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
        <Col xs="12" className="d-flex justify-content-between align-items-center"></Col>
        <Col xs="12">
          <WhTableContainer
            columns={columns}
            data={tableData || []}
            className="custom-header-css"
            divClass="table-responsive table-container-poanalysis"
            tableClass="align-middle table-bordered"
            theadClass="table-light"
            handlePageChange={handlePageChange}
            pageCount={pageCount || 0}
            customPageSize={30}
            showNoDataComponent={true}
          />
        </Col>
      </Row>
      <ApiLoader loading={getPoAnalysisReportLoading} /> 
      <ApiLoader loading={getInvPricingReportLoading} />
    </Container>
    <Modal
     id="showPrintModal"
     isOpen={showPrintModal}
     toggle={togglePrintModal}
     centered
     size="lg"
   >
     <F12CloseModalListener onClose={togglePrintModal} />
     <ModalHeader className="py-2 px-3" toggle={togglePrintModal}>
       Print Options
     </ModalHeader>
     <Form
       onSubmit={(e) => {
         e.preventDefault();
       }}
     >
       <ModalBody className="px-0 pt-0">
         <PrintItem
           getSuppliersReportApiCall={getSuppliersReportApiCall}
           filters={filters}
           showPrintModal={showPrintModal}
         />
       </ModalBody>
     </Form>
   </Modal>
   </>
  );
};

export default POAnalysis;
