import PrintIcon from "../../../../assets/images/warehouse/salespage/printer.png";
import ExportIcon from "../../../../assets/images/warehouse/salespage/excel.png";
import GenerateTableIcon from "../../../../assets/images/warehouse/salespage/generate_tables.png";

export const actions = (toggle, togglePrintModal) => [
    {
        id: "reset",
        label: "Generate",
        icon: GenerateTableIcon
    }, {
        id: "print",
        label: "Print",
        icon: PrintIcon,
        clickHandler: togglePrintModal,
    }, {
        id: "export",
        label: "Export",
        icon: ExportIcon
    }
]

export function generateColumns() {
    return [
        {
            Header: "Invoice Number",
            accessor: "invoiceNumber"
        },
        {
            Header: "Invoice Date",
            accessor: "invoiceDate"
        }, {
            Header: "User Name",
            accessor: "userName"
        }, {
            Header: "Customer",
            accessor: "customer"
        }, {
            Header: "Company",
            accessor: "company"
        }, {
            Header: "Edit Date",
            accessor: "editDate"
        }, {
            Header: "Count",
            accessor: "count"
        }, {
            Header: "Total",
            accessor: "total"
        }
    ]
}
export const tableData = [
    {
        invoiceNumber: "0519222-779",
        invoiceDate: "02-07-2022",
        userName: "Ali",
        customer: "2490",
        company: "4K 4 / 99C Mango",
        editDate: "02-07-2022",
        count: "103",
        total: "400"
    }, {
        invoiceNumber: "0519222-779",
        invoiceDate: "02-07-2022",
        userName: "Ali",
        customer: "2490",
        company: "4K 4 / 99C Mango",
        editDate: "02-07-2022",
        count: "103",
        total: "400"
    }, {
        invoiceNumber: "0519222-779",
        invoiceDate: "02-07-2022",
        userName: "Ali",
        customer: "2490",
        company: "4K 4 / 99C Mango",
        editDate: "02-07-2022",
        count: "103",
        total: "400"
    }, {
        invoiceNumber: "0519222-779",
        invoiceDate: "02-07-2022",
        userName: "Ali",
        customer: "2490",
        company: "4K 4 / 99C Mango",
        editDate: "02-07-2022",
        count: "103",
        total: "400"
    }, {
        invoiceNumber: "0519222-779",
        invoiceDate: "02-07-2022",
        userName: "Ali",
        customer: "2490",
        company: "4K 4 / 99C Mango",
        editDate: "02-07-2022",
        count: "103",
        total: "400"
    }, {
        invoiceNumber: "0519222-779",
        invoiceDate: "02-07-2022",
        userName: "Ali",
        customer: "2490",
        company: "4K 4 / 99C Mango",
        editDate: "02-07-2022",
        count: "103",
        total: "400"
    }, {
        invoiceNumber: "0519222-779",
        invoiceDate: "02-07-2022",
        userName: "Ali",
        customer: "2490",
        company: "4K 4 / 99C Mango",
        editDate: "02-07-2022",
        count: "103",
        total: "400"
    }, {
        invoiceNumber: "0519222-779",
        invoiceDate: "02-07-2022",
        userName: "Ali",
        customer: "2490",
        company: "4K 4 / 99C Mango",
        editDate: "02-07-2022",
        count: "103",
        total: "400"
    }, {
        invoiceNumber: "0519222-779",
        invoiceDate: "02-07-2022",
        userName: "Ali",
        customer: "2490",
        company: "4K 4 / 99C Mango",
        editDate: "02-07-2022",
        count: "103",
        total: "400"
    }, {
        invoiceNumber: "0519222-779",
        invoiceDate: "02-07-2022",
        userName: "Ali",
        customer: "2490",
        company: "4K 4 / 99C Mango",
        editDate: "02-07-2022",
        count: "103",
        total: "400"
    }, {
        invoiceNumber: "0519222-779",
        invoiceDate: "02-07-2022",
        userName: "Ali",
        customer: "2490",
        company: "4K 4 / 99C Mango",
        editDate: "02-07-2022",
        count: "103",
        total: "400"
    },
];