import React from "react";
import { Col, Container, Input, Label, Row, Button, Form } from "reactstrap";
import SaveIcon from "../../../../../../../assets/images/warehouse/salespage/save.png";
import { formatAmountSymbol } from "../../../../../../../utility/commonutility";

export const Sales = ({permissions, handleSalesPermissionsChange, handlePermissionsSave, handleSalesMenuPermissionsChange}) => {
 
  return (
    <Container fluid>
      <Row>
        <Col xs="12" className="d-flex justify-content-end px-0">
          <Button color="primary" outline onClick={handlePermissionsSave} className="wh-btn">
            <span className="d-flex justify-content-center align-items-center">
              {/* <Spinner size="sm" type="grow" className="flex-shrink-0" role="status"> Loading... </Spinner> */}
              <img src={SaveIcon} style={{ height: "24px", width: "24px" }} />
              <span className="ms-2">Save</span>
            </span>
          </Button>
        </Col>
        <Col xs="4">
          <div className="form-check mb-1">
            <Input className="form-check-input" type="checkbox" name="ADD_INV_INVOICE" checked={permissions.ADD_INV_INVOICE} onChange={handleSalesPermissionsChange}/>
            <Label className="form-check-label text-nowrap">
              Quickly Add Inventory from Invoice
            </Label>
          </div>

          <div className="form-check mb-1">
            <Input className="form-check-input" type="checkbox" name="SET_CUST_PRICE" checked={permissions.SET_CUST_PRICE} onChange={handleSalesPermissionsChange} />
            <Label className="form-check-label text-nowrap">
              Set Customers Prices from Invoice
            </Label>
          </div>

          <div className="form-check mb-1">
            <Input className="form-check-input" type="checkbox" name="CHNG_INV_PRICE_AND_DISC" checked={permissions.CHNG_INV_PRICE_AND_DISC} onChange={handleSalesPermissionsChange} />
            <Label className="form-check-label text-nowrap">
              Change Invoice Price and Discount
            </Label>
          </div>

          <div className="form-check mb-1">
            <Input className="form-check-input" type="checkbox" name="CHNG_CUST_CODE" checked={permissions.CHNG_CUST_CODE} onChange={handleSalesPermissionsChange} />
            <Label className="form-check-label text-nowrap">
              Change Customer's Code in Invoice
            </Label>
          </div>

          <div className="form-check mb-1">
            <Input className="form-check-input" type="checkbox" name="VIEW_ADV_INV" checked={permissions.VIEW_ADV_INV} onChange={handleSalesPermissionsChange} />
            <Label className="form-check-label text-nowrap">
              View Advanced Inventory
            </Label>
          </div>

          <div className="form-check mb-1">
            <Input className="form-check-input" type="checkbox" name="VIEW_ADV_CUST" checked={permissions.VIEW_ADV_CUST} onChange={handleSalesPermissionsChange} />
            <Label className="form-check-label text-nowrap">
              View Advanced Customer
            </Label>
          </div>

          <div className="form-check mb-1">
            <Input className="form-check-input" type="checkbox" name="VIEW_OWN_CUST_ONLY" checked={permissions.VIEW_OWN_CUST_ONLY} onChange={handleSalesPermissionsChange} />
            <Label className="form-check-label text-nowrap">
              View Own Customer Only
            </Label>
          </div>

          <div className="form-check mb-1">
            <Input className="form-check-input" type="checkbox" name="ALLOW_RETURNS" checked={permissions.ALLOW_RETURNS} onChange={handleSalesPermissionsChange} />
            <Label className="form-check-label text-nowrap">
              Allow Returns
            </Label>
          </div>

          <div className="form-check mb-1">
            <Input className="form-check-input" type="checkbox" name="ACCEPT_PAYMENTS" checked={permissions.ACCEPT_PAYMENTS} onChange={handleSalesPermissionsChange} />
            <Label className="form-check-label text-nowrap">
              Accept Payments
            </Label>
          </div>

          <div className="form-check mb-1">
            <Input className="form-check-input" type="checkbox" name="RETURN_PAYMENTS" checked={permissions.RETURN_PAYMENTS} onChange={handleSalesPermissionsChange} />
            <Label className="form-check-label text-nowrap">
              Return Payments
            </Label>
          </div>
        </Col>
        <Col xs="4">
          <div className="form-check mb-1">
            <Input className="form-check-input" type="checkbox" name="SELL_BELOW_COST" checked={permissions.SELL_BELOW_COST} onChange={handleSalesPermissionsChange} />
            <Label className="form-check-label text-nowrap">
              Sell Bellow Cost
            </Label>
          </div>

          <div className="form-check mb-1">
            <Input className="form-check-input" type="checkbox" name="MAKE_CREDITS" checked={permissions.MAKE_CREDITS} onChange={handleSalesPermissionsChange} />
            <Label className="form-check-label text-nowrap">
              Make Credits
            </Label>
          </div>

          <div className="mb-3 d-flex align-items-center">
            <div className="form-icon">
              <Input type="text" className="form-control py-1 form-control-icon" name="INV_MAX_DISC" value={permissions.INV_MAX_DISC} onChange={handleSalesPermissionsChange} />
              <i style={{ fontStyle: "normal" }}>{formatAmountSymbol()}</i>
            </div>
            <Label className="w-50 ps-2 mb-0">Invoice max Discount</Label>
          </div>

          <div className="form-check mb-1">
            <Input className="form-check-input" type="checkbox" name="OPEN_POSTED_INVOICE" checked={permissions.OPEN_POSTED_INVOICE} onChange={handleSalesPermissionsChange} />
            <Label className="form-check-label text-nowrap">
              Open Posted Invoice
            </Label>
          </div>

          <div className="form-check mb-1">
            <Input className="form-check-input" type="checkbox" name="OPEN_RECEIVED_PO" checked={permissions.OPEN_RECEIVED_PO} onChange={handleSalesPermissionsChange} />
            <Label className="form-check-label text-nowrap">
              Open Received PO
            </Label>
          </div>

          <div className="form-check mb-1">
            <Input className="form-check-input" type="checkbox" name="DELETE_OPEN_INV" checked={permissions.DELETE_OPEN_INV} onChange={handleSalesPermissionsChange} />
            <Label className="form-check-label text-nowrap">
              Delete Open Invoices
            </Label>
          </div>

          <div className="form-check mb-1">
            <Input className="form-check-input" type="checkbox" name="DELETE_OPEN_PO" checked={permissions.DELETE_OPEN_PO} onChange={handleSalesPermissionsChange} />
            <Label className="form-check-label text-nowrap">
              Delete Open PO
            </Label>
          </div>

          <div className="form-check mb-1">
            <Input className="form-check-input" type="checkbox" name="TRACK_DELETED_INVS" checked={permissions.TRACK_DELETED_INVS} onChange={handleSalesPermissionsChange} />
            <Label className="form-check-label text-nowrap">
              Track Deleted Invoices
            </Label>
          </div>

          <div className="form-check mb-1">
            <Input className="form-check-input" type="checkbox" name="ADJ_INV_COUNT" checked={permissions.ADJ_INV_COUNT} onChange={handleSalesPermissionsChange} />
            <Label className="form-check-label text-nowrap">
              Adjust Inventory Count
            </Label>
          </div>

          <div className="form-check mb-1">
            <Input className="form-check-input" type="checkbox" name="UPDATE_INV_PRICES" checked={permissions.UPDATE_INV_PRICES} onChange={handleSalesPermissionsChange} />
            <Label className="form-check-label text-nowrap">
              Update Inventory Prices
            </Label>
          </div>
        </Col>
        <Col xs="4">
          {/* <div className="border ps-4 my-3" style={{ position: "relative" }}>
            <Label className="text-dark" style={{ position: "absolute", transform: "translateY(-50%)" }}>
              Dropdown List
            </Label>
            <div className="mt-4">
              <div className="form-check mb-1">
                <Input className="form-check-input" type="checkbox" name="CHNG_CUST_CODE" checked={permissions.CHNG_CUST_CODE} onChange={handleSalesPermissionsChange} />
                <Label className="form-check-label text-nowrap">
                  All
                </Label>
              </div>

              <div className="form-check mb-1">
                <Input className="form-check-input" type="checkbox" name="CHNG_CUST_CODE" checked={permissions.CHNG_CUST_CODE} onChange={handleSalesPermissionsChange} />
                <Label className="form-check-label text-nowrap">
                  10
                </Label>
              </div>

              <div className="form-check mb-1">
                <Input className="form-check-input" type="checkbox" name="CHNG_CUST_CODE" checked={permissions.CHNG_CUST_CODE} onChange={handleSalesPermissionsChange} />
                <Label className="form-check-label text-nowrap">
                  25
                </Label>
              </div>

              <div className="form-check mb-1">
                <Input className="form-check-input" type="checkbox" name="CHNG_CUST_CODE" checked={permissions.CHNG_CUST_CODE} onChange={handleSalesPermissionsChange} />
                <Label className="form-check-label text-nowrap">
                  50
                </Label>
              </div>

              <div className="form-check mb-1">
                <Input className="form-check-input" type="checkbox" name="CHNG_CUST_CODE" checked={permissions.CHNG_CUST_CODE} onChange={handleSalesPermissionsChange} />
                <Label className="form-check-label text-nowrap">
                  100
                </Label>
              </div>

              <div className="form-check mb-1">
                <Input className="form-check-input" type="checkbox" name="CHNG_CUST_CODE" checked={permissions.CHNG_CUST_CODE} onChange={handleSalesPermissionsChange} />
                <Label className="form-check-label text-nowrap">
                  500
                </Label>
              </div>
            </div>
          </div> */}

          <div className="border ps-4 my-3 wh-border-thick" style={{ position: "relative" }}>
            <Label style={{ position: "absolute", transform: "translateY(-50%)" }}>
            <span className=" px-1 wh-item-bg"> Menu Option</span>
            </Label>
            <div className="mt-4">
              <div className="form-check mb-1">
                <Input className="form-check-input" type="checkbox" name="ALL_MENU" checked={permissions.ALL_MENU} onChange={handleSalesMenuPermissionsChange} />
                <Label className="form-check-label text-nowrap">
                  All Menu
                </Label>
              </div>

              <div className="form-check mb-1">
                <Input className="form-check-input" type="checkbox" name="SALES_MENU" checked={permissions.SALES_MENU} onChange={handleSalesMenuPermissionsChange} />
                <Label className="form-check-label text-nowrap">
                  Sales Menu
                </Label>
              </div>

              <div className="form-check mb-1">
                <Input className="form-check-input" type="checkbox" name="POS_MENU" checked={permissions.POS_MENU} onChange={handleSalesMenuPermissionsChange} />
                <Label className="form-check-label text-nowrap">
                  POS Menu
                </Label>
              </div>

              <div className="form-check mb-1">
                <Input className="form-check-input" type="checkbox" name="CUSTOMER_MENU" checked={permissions.CUSTOMER_MENU} onChange={handleSalesMenuPermissionsChange} />
                <Label className="form-check-label text-nowrap">
                  Customer Menu
                </Label>
              </div>

              <div className="form-check mb-1">
                <Input className="form-check-input" type="checkbox" name="PO_MENU" checked={permissions.PO_MENU} onChange={handleSalesMenuPermissionsChange} />
                <Label className="form-check-label text-nowrap">
                  PO Menu
                </Label>
              </div>

              <div className="form-check mb-1">
                <Input className="form-check-input" type="checkbox" name="VENDORS_MENU" checked={permissions.VENDORS_MENU} onChange={handleSalesMenuPermissionsChange} />
                <Label className="form-check-label text-nowrap">
                  Vendors Menu
                </Label>
              </div>

              <div className="form-check mb-1">
                <Input className="form-check-input" type="checkbox" name="INVENTORY_MENU" checked={permissions.INVENTORY_MENU} onChange={handleSalesMenuPermissionsChange} />
                <Label className="form-check-label text-nowrap">
                  Inventory Menu
                </Label>
              </div>

            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};
