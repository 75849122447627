import React, { useState, useRef, useCallback, useEffect } from "react";
import { Container, Row, Col, Label, Button } from "reactstrap";
import shopifyIcon from "../../../../assets/images/warehouse/salespage/shopify-icon.png";
import updateIcon from "../../../../assets/images/warehouse/salespage/update-icon.png";
import amazonIcon from "../../../../assets/images/warehouse/salespage/amazon-icon.png";

import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { getInventoryAdjustmentReportAct, getInventoryDetectionNewKindReportAct,getInventoryDetectionAmazonReportAct, getInventoryDetectionNewBooReportAct } from "../../../../store/reports/reportsactions";
import { generateApiUrl } from "../../../../api/apihelper";
import { toast } from "react-toastify";
import ApiLoader from "../../../../Components/Common/ApiLoader";

export function InventoryDetectionTab() {

  const dispatch = useDispatch();
  const isDownloadBtnClicked = useRef(false);

  const { 
    getInventoryDetectionNewKindReportResp,
    getInventoryDetectionNewBooReportResp,
    // getInventoryDetectionAmazonReportResp,
    getInventoryDetectionAmazonReportLoading,getInventoryDetectionNewKindReportLoading, getInventoryAdjustmentReportResp,getInventoryAdjustmentReportLoading,getInventoryDetectionNewBooReportLoading, reportsErrorResp } = useSelector(
    ({ reports }) => ({
      getInventoryDetectionNewKindReportResp: reports.getInventoryDetectionNewKindReportResp,
      getInventoryDetectionNewKindReportLoading: reports.getInventoryDetectionNewKindReportLoading,
      getInventoryDetectionNewBooReportResp: reports.getInventoryDetectionNewBooReportResp,
      getInventoryDetectionNewBooReportLoading: reports.getInventoryDetectionNewBooReportLoading,
      // getInventoryDetectionAmazonReportResp: reports.getInventoryDetectionAmazonReportResp,
      getInventoryDetectionAmazonReportLoading: reports.getInventoryDetectionAmazonReportLoading,
      getInventoryAdjustmentReportResp: reports.getInventoryAdjustmentReportResp,
      getInventoryAdjustmentReportLoading: reports.getInventoryAdjustmentReportLoading,
      reportsErrorResp: reports.reportsErrorResp,
    }),
    shallowEqual
  );

  const customerResp = useRef({
    prevGetInventoryDetectionNewKindReportResp: getInventoryDetectionNewKindReportResp,
    prevGetInventoryDetectionNewBooReportResp: getInventoryDetectionNewBooReportResp,
    // prevGetInventoryDetectionAmazonReportResp: getInventoryDetectionAmazonReportResp,
    prevgetInventoryAdjustmentReportResp: getInventoryAdjustmentReportResp,
    prevReportsErrorResp: reportsErrorResp,
  });

  useEffect(() => {
    const { 
      prevGetInventoryDetectionNewKindReportResp,
      prevGetInventoryDetectionNewBooReportResp,
      prevReportsErrorResp } =
      customerResp.current || {};
    if (
      getInventoryDetectionNewKindReportResp &&
      prevGetInventoryDetectionNewKindReportResp !== getInventoryDetectionNewKindReportResp
    ) {
      if (isDownloadBtnClicked.current) {
        const url = window.URL.createObjectURL(
          new Blob([getInventoryDetectionNewKindReportResp])
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "inventory_detection_newkind_report.csv");
        document.body.appendChild(link);
        link.click();
        link.remove();
        isDownloadBtnClicked.current = false;
      }
    } else if (
      getInventoryDetectionNewBooReportResp &&
      prevGetInventoryDetectionNewBooReportResp !== getInventoryDetectionNewBooReportResp
    ) {
      if (isDownloadBtnClicked.current) {
        const url = window.URL.createObjectURL(
          new Blob([getInventoryDetectionNewBooReportResp])
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "inventory_detection_newboo_report.csv");
        document.body.appendChild(link);
        link.click();
        link.remove();
        isDownloadBtnClicked.current = false;
      }
    }
    else if (reportsErrorResp && prevReportsErrorResp !== reportsErrorResp) {
      const { errors, error, message } = reportsErrorResp || {};
      toast.error((Array.isArray(errors) && errors[0].msg) || error || message);
    }
   
    
    customerResp.current.prevGetInventoryDetectionNewKindReportResp = getInventoryDetectionNewKindReportResp;
    customerResp.current.prevGetInventoryDetectionNewBooReportResp = getInventoryDetectionNewBooReportResp;
    // customerResp.current.prevGetInventoryDetectionAmazonReportResp = getInventoryDetectionAmazonReportResp;
  
    customerResp.current.prevReportsErrorResp = reportsErrorResp;
  }, [
    getInventoryDetectionNewKindReportResp,
    getInventoryDetectionNewBooReportResp, 
    reportsErrorResp]);

  const getInventoryDetectionNewKindReportApiCall = useCallback((download) => {
    let params = {};
    if (download) {
      params.download = true;
      isDownloadBtnClicked.current = true;
      dispatch(
        getInventoryDetectionNewKindReportAct(
          generateApiUrl(`get_inventory_detection_newkind_report`),
          params,
          { responseType: "blob" }
        )
      );
    } else {
      dispatch(
        getInventoryDetectionNewKindReportAct(
          generateApiUrl(`get_inventory_detection_newkind_report`),
          params
        )
      );
    }
  }, []);

  const getInventoryDetectionNewBooReportApiCall = useCallback((download) => {
    let params = {};
    if (download) {
      params.download = true;
      isDownloadBtnClicked.current = true;
      dispatch(
        getInventoryDetectionNewBooReportAct(
          generateApiUrl(`get_inventory_detection_newboo_report`),
          params,
          { responseType: "blob" }
        )
      );
    } else {
      dispatch(
        getInventoryDetectionNewBooReportAct(
          generateApiUrl(`get_inventory_detection_newboo_report`),
          params
        )
      );
    }
  }, []);

 
  const getInventoryAdjustmentReportApiCall = useCallback(() => {
    let params = {};
    dispatch(
      getInventoryAdjustmentReportAct(
        generateApiUrl(`get_inventory_adjustment_report`),
        params
      )
    )
    setTimeout(()=>{
      if(!getInventoryAdjustmentReportLoading){
        toast.success("Successfully updated inventory adjustment");
      }
    },2000)

  }, []);

  const handleInventoryDetectionNewKindDownloadClick = () => {
    getInventoryDetectionNewKindReportApiCall(true);
  };

  const handleInventoryDetectionNewBooDownloadClick = () => {
    getInventoryDetectionNewBooReportApiCall(true);
  };


  const handleInventoryAdjustmentUpdateClick = () => {
    getInventoryAdjustmentReportApiCall();
    
  };

  return (
    <>
    <Container fluid className=" pt-3 pb-0">
      <Row>
        <Col xs="12" className="mb-1">
          <div
            className="border ps-4 my-3 mb-1"
            style={{ position: "relative" }}
          >
            <Label
              className="text-dark fs-5" htmlFor="inventoryDeduction"
              style={{ position: "absolute", transform: "translateY(-50%)" }}
            >
              <span className=" px-1 wh-item-bg py-1">Inventory Deduction</span>
            </Label>
            <Row xs={12}>
              <Col xs="12" md="12" className="pe-5 py-2">
                <Row className="my-3 mb-1">
                  <Col xs="3" md="2">
                    <Button
                      color="primary"
                      className="w-100 mb-2 wh-btn mx-2"
                      outline
                      onClick={handleInventoryDetectionNewKindDownloadClick}
                    >
                      <span className="d-flex justify-content-center align-items-center">
                        <img
                          src={shopifyIcon}
                          style={{ height: "20px", width: "30px" }}
                        />
                        <span className="ms-2 text-nowrap">New Kind</span>
                      </span>
                    </Button>
                  </Col>
                  <Col xs="3" md="2">
                    <Button
                      color="primary"
                      className="w-100 mb-2 wh-btn mx-2"
                      outline
                      onClick={handleInventoryDetectionNewBooDownloadClick}
                    >
                      <span className="d-flex justify-content-center align-items-center">
                        <img
                          src={shopifyIcon}
                          style={{ height: "20px", width: "30px" }}
                        />
                        <span className="ms-2 text-nowrap">New Boo</span>
                      </span>
                    </Button>
                  </Col>
                  {/* <Col xs="3" md="2">
                    <Button
                      color="primary"
                      className="w-100 mb-2 wh-btn mx-2"
                      outline
                      onClick={handleInventoryDetectionAmazonDownloadClick}
                    >
                      <span className="d-flex justify-content-center align-items-center">
                        <img
                          src={amazonIcon}
                          style={{ height: "20px", width: "20px" }}
                        />
                        <span className="ms-2 text-nowrap">Download</span>
                      </span>
                    </Button>
                  </Col> */}
                </Row>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs="12" className="mb-1">
          <div
            className="border ps-4 my-3 mb-1"
            style={{ position: "relative" }}
          >
            <Label
              className="text-dark fs-5"
              style={{ position: "absolute", transform: "translateY(-50%)" }}
              htmlFor="inventoryAdjustment"
            >
              <span className=" px-1 wh-item-bg py-1">Inventory Adjustment</span>
            </Label>
            <Row xs={12}>
              <Col xs="12" md="12" className="pe-5 py-2">
                <Row className="my-3 mb-1">
                  <Col xs="3" md="2">
                    <Button
                      color="primary"
                      className="w-100 mb-2 wh-btn mx-2"
                      outline
                      onClick={handleInventoryAdjustmentUpdateClick}
                    >
                      <span className="d-flex justify-content-center align-items-center">
                        <img
                          src={updateIcon}
                          style={{ height: "18px", width: "18px" }}
                        />
                        <span className="ms-2 text-nowrap">Update</span>
                      </span>
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </Container>

    <ApiLoader loading={getInventoryAdjustmentReportLoading}/>
    <ApiLoader loading={getInventoryDetectionAmazonReportLoading}/>
    <ApiLoader loading={getInventoryDetectionNewKindReportLoading}/>
    <ApiLoader loading={getInventoryDetectionNewBooReportLoading}/>
    
    
    </>
  );
}
