import React, {useState, useEffect, useRef} from 'react';
import {Input, Label, Container, Row, Col} from 'reactstrap';
import {useSelector, shallowEqual} from 'react-redux';
import './smartsearch.scss';
import Draggable from 'react-draggable';
import TableLoader from '../../Components/Common/TableLoader';
import NoDataComponent from '../../Components/Common/NoDataComponent';
import {formatAmountSymbol} from '../../utility/commonutility'
const ListItem = ({item, active, id}) => (
  <div
    className={`item d-flex py-2 ps-2 ${active ? 'active' : ''}`}
    tabIndex={'0'}
    id={'item-' + id}
  >
    <div className="w-75">{item.description}</div>
     <div className="w-25 ps-3">{formatAmountSymbol()}{(+item?.retail?.slice(1))?.toFixed(2)}</div>
  </div>
);

const useActiveElement = setTempQty => {
  const [active, setActive] = useState (document.activeElement);

  const handleFocusIn = e => {
    setActive (document.activeElement);
    if (!document.activeElement.classList.contains ('item')) setTempQty ('');
  };

  useEffect (() => {
    document.addEventListener ('focusin', handleFocusIn);
    return () => {
      document.removeEventListener ('focusin', handleFocusIn);
    };
  }, []);

  return active;
};

const SmartSearch = ({
  getInventoryListApiCall,
  callbackAfterGetInventoryListApiCall,
  selectedItemHandler,
  toast,
  loading
}) => {
  const [search, setSearch] = useState ('');
  const [itemsList, setItemsList] = useState ([]);
  const [tempQty, setTempQty] = useState ('');
  const focusedElement = useActiveElement(setTempQty);
  const [showQty,setShowQty] = useState(0);
  const [filterActive, setFilterActive] = useState(true); 
  const inputRef = useRef (null);

  // useEffect(() => {
  //   if (focusedElement) {
  //     focusedElement.value && console.log(focusedElement.value);
  //   }
  //   console.log(focusedElement);
  // }, [focusedElement]);

  useEffect (() => {
    inputRef.current.focus ();
  }, []);
useEffect(()=> {
  if(focusedElement.id){
    setShowQty(0)
  }
},[focusedElement.id])
  const {getInventoryListResp} = useSelector (
    ({inventory}) => ({
      getInventoryListResp: inventory.getInventoryListResp,
    }),
    shallowEqual
  );

  const apiResp = useRef ({
    prevGetInventoryListResp: getInventoryListResp,
  });
  useEffect (
    () => {
      const {prevGetInventoryListResp} = apiResp.current || {};
      if (
        getInventoryListResp &&
        prevGetInventoryListResp !== getInventoryListResp
      ) {
        callbackAfterGetInventoryListApiCall (
          getInventoryListResp,
          setItemsList,
          100
        );
      }
      apiResp.current.prevGetInventoryListResp = getInventoryListResp;
    },
    [getInventoryListResp, callbackAfterGetInventoryListApiCall]
  );

  useEffect (
    () => {
      const debounceFn = setTimeout (
        () => {
          getInventoryListApiCall ({description: search, pageSize: 50, active: filterActive,});
        },
        [400]
      );
      return () => clearTimeout (debounceFn);
    },
    [search, getInventoryListApiCall,filterActive]
  );

  function downHandler (e) {
    const {key} = e;
    const active = document.activeElement;
    if (key === 'ArrowUp') {
      e.preventDefault ();
      if (active.classList.contains ('item')) {
        if (active.previousSibling) active.previousSibling.focus ();
      } else {
        if (itemsList.length > 0) document.getElementById ('item-1').focus ();
      }
      setTempQty ('');
    } else if (key === 'ArrowDown') {
      e.preventDefault ();
      if (active.classList.contains ('item')) {
        if (active.nextSibling) active.nextSibling.focus ();
      } else {
        if (itemsList.length > 0) document.getElementById ('item-1').focus ();
      }
      setTempQty ('');
    } else if (!isNaN (Number (key))) {
      setShowQty(0);
      if (active.classList.contains ('item')) setTempQty (prev => prev + key);
    } else if (key === 'Backspace') {
      if (active.classList.contains ('item'))
        if (tempQty.length > 0)
          setTempQty (prev => prev.slice (0, prev.length - 1));
    } else if (key === 'Enter' && focusedElement.id.startsWith("item")) {
      if (!tempQty) {
        setTempQty ('1');
        setShowQty(prev => prev+1)
        selectedItemHandler ({
          ...itemsList[focusedElement.id.split ('-')[1] - 1],
          quantity: tempQty,
        });
        setTempQty ('');
      }
      if (tempQty) {
        selectedItemHandler ({
          ...itemsList[focusedElement.id.split ('-')[1] - 1],
          quantity: tempQty,
        });
        setTempQty ('');
      }
      // else toast.error("Please enter quantity");
    }
  }

  useEffect (() => {
    window.addEventListener ('keydown', downHandler);
    return () => {
      window.removeEventListener ('keydown', downHandler);
    };
  });
  const handleFilterChange = () => {
    setFilterActive(!filterActive); // Toggle filter state
  };
  
  useEffect(() => {
    const handleDoubleClick = () => {
      if (focusedElement.id.startsWith("item")) {
        if (!tempQty) {
          setTempQty('1');
          selectedItemHandler({
            ...itemsList[focusedElement.id.split('-')[1] - 1],
            quantity: tempQty,
          });
          setTempQty('');
        }
        if (tempQty) {
          selectedItemHandler({
            ...itemsList[focusedElement.id.split('-')[1] - 1],
            quantity: tempQty,
          });
          setTempQty('');
        }
      }
    };
    document.addEventListener('dblclick', handleDoubleClick);
    return () => {
      document.removeEventListener('dblclick', handleDoubleClick);
    };
  }, [focusedElement, tempQty, setTempQty, selectedItemHandler, itemsList]);

  return (
    <Container className="po-smart-search-wrapper">
      <Row className="my-2">
        <Col xs="6">
          <div className="d-flex align-items-center cancledrag">
            <Label className="me-2 my-0">Search</Label>
            <Input
              className="me-2 my-0"
              type="text"
              value={search}
              onChange={e => setSearch (e.target.value)}
              innerRef={inputRef}
            />
          </div>
  <div className="form-check form-check-inline">
    <input
      type="radio"
      id="activeRadio"
      className="form-check-input"
      checked={filterActive}
      onChange={handleFilterChange}
    />
    <label className="form-check-label" htmlFor="activeRadio">
      Active
    </label>
  </div>
  <div className="form-check form-check-inline">
    <input
      type="radio"
      id="inactiveRadio"
      className="form-check-input"
      checked={!filterActive}
      onChange={handleFilterChange}
    />
    <label className="form-check-label" htmlFor="inactiveRadio">
      Inactive
    </label>
  </div>
        </Col>
        <Col
          xs="6"
          className="d-flex align-items-center justify-content-end fs-5 fw-bold pe-5"
        >
          {showQty || tempQty ? <div className='rounded-badge'>
            <span className='badge-number'>{showQty || tempQty}</span>
          </div> : ''}
        </Col>
        <Col xs="12">
          <div className="d-flex py-2 ps-2">
            <div className="w-75 fw-bold">Description</div>
            <div className="w-25 fw-bold">Retail Price</div>
          </div>
        </Col>
      </Row>
      {!loading && itemsList.length === 0 && <NoDataComponent className={'pt-4'}/>}

      {!loading && itemsList.length > 0 && <Row id="list" style={{height: '400px', overflowY: 'auto'}}>
        <Col xs="12" style={{minHeight:'340px',maxHeight:'340px'}}>
          {itemsList.map ((item, i) => (
            <ListItem 
              key={item.itemNumber}
              active={'item-' + (i + 1) === focusedElement.id}
              item={item}
              id={i + 1}
            />
          ))}
        </Col>
      </Row>}
      {loading && <TableLoader />}
    </Container>
  );
};

export default SmartSearch;
