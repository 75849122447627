import React from "react";
import { Card, CardBody, Col, Container, Input, Label, Row, Button, Table, Modal } from 'reactstrap';
import SaveIcon from "../../../../assets/images/warehouse/salespage/save.png";

export default function AddProductCategory() {
    return (
        <Container fluid>
            <Row>
                <Col xs={12} className="bg-primary text-center fs-5 text-white py-2">
                    Add Product Category
                </Col>
            </Row>
            <Row className="p-3">
                <Col xs={7}>
                    <Row className="mb-3">
                        <Col xs={2} className="d-flex align-items-center">
                            <Label htmlFor="street" className="form-label mb-0 text-nowrap">Name :</Label>
                        </Col>
                        <Col xs={10}>
                            <Input name="street" id="street" className="form-control" placeholder="" type="text" />
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col xs={2} className="d-flex align-items-center">
                            <Label htmlFor="street" className="form-label mb-0 text-nowrap">Status :</Label>
                        </Col>
                        <Col xs={10}>
                            <select id="mrMiss" className="form-select" data-choices data-choices-sorting="true" >
                                <option>Select</option>
                                <option>Active</option>
                                <option>Inactive</option>
                            </select>
                        </Col>
                    </Row>
                </Col>
                <Col xs={5}>
                    <div className="d-flex justify-content-center">
                        <Button color="primary" className="w-50 wh-btn" outline>
                            <span className="d-flex justify-content-center align-items-center">
                                {/* <Spinner size="sm" type="grow" className="flex-shrink-0" role="status"> Loading... </Spinner> */}
                                <img src={SaveIcon} style={{ height: "16px", width: "16px" }} />
                                <span className="ms-2 text-nowrap">
                                    Save
                                </span>
                            </span>
                        </Button>
                    </div>
                </Col>
            </Row>
        </Container>

    )
}