import React, { useMemo, useState } from "react";
import {
     Col, Container, Row, Nav, NavItem, NavLink, TabContent, TabPane
} from 'reactstrap';
import { ToastContainer } from "react-toastify";
import { tabsOption } from "./helper";
import classnames from "classnames";
import {
    ProductCategoryTab,
    ProductSubcategoryTab,
    DepartmentsTab, DesignationsTab, TaxTab, ManufacturersTab
} from "./tabs";

function getActiveComponent(activeId) {
    switch (activeId) {
      case "product_category":
        return ProductCategoryTab;
      case "product_subcategory":
        return ProductSubcategoryTab;
      case "manufacturers":
        return ManufacturersTab;
      case "departments":
        return DepartmentsTab;
      case "designation":
        return DesignationsTab;
      case "tax":
        return TaxTab;
      default:
        return null;
    }
}

export default function SystemLevel() {
    const [justifyPillsTab, setjustifyPillsTab] = useState("product_category");
    const justifyPillsToggle = (tab) => {
        if (justifyPillsTab !== tab) {
            setjustifyPillsTab(tab);
        }
    };

    const TabComponent = useMemo(() => getActiveComponent(justifyPillsTab), [justifyPillsTab]);

    return (
        <React.Fragment>
            <ToastContainer />
            <div className="sale-wh-wrapper" style={{minHeight:"500px"}}>
                <Container fluid>
                    <Row>
                        <Col className="px-0" xs={12}>
                            <Nav pills className="nav mb-2 bg-light px-5">
                                {tabsOption.map(item => <NavItem key={item.id}>
                                    <NavLink
                                        style={{ cursor: "pointer" }}
                                        className={classnames({ active: justifyPillsTab === item.id })}
                                        onClick={() => {
                                            justifyPillsToggle(item.id);
                                        }}
                                    >
                                        {item.name}
                                    </NavLink>
                                </NavItem>)}
                            </Nav>
                            <TabContent activeTab={justifyPillsTab} className="text-muted">
                                <TabPane tabId={justifyPillsTab} id="pill-justified-home-1">
                                    <TabComponent />
                                </TabPane>
                            </TabContent>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}