import PrintIcon from "../../../../assets/images/warehouse/salespage/printer.png";
import GenerateIcon from "../../../../assets/images/warehouse/salespage/generate.png";
import ExportIcon from "../../../../assets/images/warehouse/salespage/excel.png";
import ResetIcon from "../../../../assets/images/warehouse/salespage/reset.png";
import { getCustomDateFormatted, getMmddyyyyDateFormatted } from "../../../../utility/dateutility"
import { formatAmount } from "../../../../utility/commonutility";
import { Link } from "react-router-dom";
export const actions = (togglePrintModal, getSuppliersReportApiCall,resetFilters) => [
  {
    id: "reset",
    label: "Reset",
    icon: ResetIcon,
    clickHandler: resetFilters
  },
  {
    id: "print",
    label: "Print",
    icon: PrintIcon,
    clickHandler:togglePrintModal
  },
  {
    id: "export",
    label: "Export",
    icon: ExportIcon,
    clickHandler : () => getSuppliersReportApiCall(0,10,true)
  },
];

export function generateColumns() {
  return [
    {
      Header: "Customer No",
      accessor: "soldToCustomer",
      Cell:({cell:{value}}) => <Link target="_blank" to={`/warehousecustomers/${value}`}>{value}</Link>,
    },
    {
      Header: "Customer",
      accessor: "customer.companyName",
      Cell:({row:{original:{soldToCustomer,customer}}}) => <Link target="_blank" to={`/warehousecustomers/${soldToCustomer}`}>{customer?.companyName}</Link>,
      
     
    },
    {
      Header: "Type",
      accessor: "pType",
    },
    {
      Header: "Comments",
      accessor: "comments",
    },
    {
      Header: "Date Paid",
      accessor: "datePaid",
      Cell : ({cell:{value}}) => value ? getMmddyyyyDateFormatted(value) : ""
    },
    {
      Header: "Time",
      accessor: "time",
      Cell : ({row : {original : {datePaid}}}) => datePaid ? getCustomDateFormatted(datePaid, "hh:mm A") : ""
    },
    {
      Header: "Amount Paid",
      accessor: "amountPaid",
      Cell : ({cell:{value}}) => formatAmount(value)
    },
    {
      Header: "Amount Allocated",
      accessor: "amountAlloc",
      Cell : ({cell:{value}}) => formatAmount(value)
    },
    {
      Header: "Change",
      accessor: "change",
      Cell : ({cell:{value}}) => formatAmount(value)
    },
  ];
}
export const tableData = [
  {
    id: "1503",
    item: "New Salem Conv Store",
    description: "Debit Card",
    quantity: "",
    caseQuantity: "05-05-2022",
    amount: "09 : 05 AM",
    received: "$300.90",
    amountAllocated:'$300.90',
    change:"$0.00"
  },
  {
    id: "1503",
    item: "New Salem Conv Store",
    description: "Debit Card",
    quantity: "",
    caseQuantity: "05-05-2022",
    amount: "09 : 05 AM",
    received: "$300.90",
    amountAllocated:'$300.90',
    change:"$0.00"
  },
  {
    id: "1503",
    item: "New Salem Conv Store",
    description: "Debit Card",
    quantity: "",
    caseQuantity: "05-05-2022",
    amount: "09 : 05 AM",
    received: "$300.90",
    amountAllocated:'$300.90',
    change:"$0.00"
  },
  {
    id: "1503",
    item: "New Salem Conv Store",
    description: "Debit Card",
    quantity: "",
    caseQuantity: "05-05-2022",
    amount: "09 : 05 AM",
    received: "$300.90",
    amountAllocated:'$300.90',
    change:"$0.00"
  },
  {
    id: "1503",
    item: "New Salem Conv Store",
    description: "Debit Card",
    quantity: "",
    caseQuantity: "05-05-2022",
    amount: "09 : 05 AM",
    received: "$300.90",
    amountAllocated:'$300.90',
    change:"$0.00"
  },
  {
    id: "1503",
    item: "New Salem Conv Store",
    description: "Debit Card",
    quantity: "",
    caseQuantity: "05-05-2022",
    amount: "09 : 05 AM",
    received: "$300.90",
    amountAllocated:'$300.90',
    change:"$0.00"
  },
  {
    id: "1503",
    item: "New Salem Conv Store",
    description: "Debit Card",
    quantity: "",
    caseQuantity: "05-05-2022",
    amount: "09 : 05 AM",
    received: "$300.90",
    amountAllocated:'$300.90',
    change:"$0.00"
  },
];
