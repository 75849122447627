import React from "react";
import { Col, Container, Input, Label, Row, Button, FormFeedback } from 'reactstrap';
import { useFormik } from "formik";
import * as yup from "yup";
import SaveIcon from "../../../../assets/images/warehouse/salespage/save.png";
//Actions
import { createDesignationAct, updateDesignationAct } from "../../../../store/designation/designationactions";

function getInitialValues(editModalData) {
    if (editModalData) {
      const { name, status } = editModalData;
      return { name, status };
    } else return { name: "", status: true };
  }

export default function AddDesignation({dispatch, generateApiUrl, editModalData}) {
    const addDesignationValidation = useFormik({
        initialValues:getInitialValues(editModalData),
        validationSchema:yup.object().shape({
            name:yup.string().required("Designation name is required")
        }),
        onSubmit:(values) => {
            if(!!editModalData) dispatch(updateDesignationAct(generateApiUrl("update_designation",{id:editModalData.id}), values));
            else dispatch(createDesignationAct(generateApiUrl("create_designation"), values));
        }
    })
    return (
        <Container fluid>
            <Row>
                <Col xs={12} className="bg-primary text-center fs-5 text-white py-2">
                    {!!editModalData ? "Edit" : "Add"} Designation
                </Col>
            </Row>
            <Row className="p-3">
                <Col xs={7}>
                    <Row className="mb-3">
                        <Col xs={2} className="d-flex align-items-center">
                            <Label htmlFor="street" className="form-label mb-0 text-nowrap">Name :</Label>
                        </Col>
                        <Col xs={10}>
                            <Input name="name" className="form-control" placeholder="" type="text" value={addDesignationValidation.values.name} onChange={addDesignationValidation.handleChange} invalid={addDesignationValidation.touched.name && addDesignationValidation.errors.name} />
                            <FormFeedback>{addDesignationValidation.touched.name && addDesignationValidation.errors.name}</FormFeedback>
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col xs={2} className="d-flex align-items-center">
                            <Label htmlFor="street" className="form-label mb-0 text-nowrap">Status :</Label>
                        </Col>
                        <Col xs={10}>
                            <select name="status" className="form-select" data-choices data-choices-sorting="true" value={addDesignationValidation.values.status} onChange={addDesignationValidation.handleChange} >
                                <option value={true}>Active</option>
                                <option value={false}>Inactive</option>
                            </select>
                        </Col>
                    </Row>
                </Col>
                <Col xs={5}>
                    <div className="d-flex justify-content-center">
                        <Button color="primary" className="w-50" outline onClick={addDesignationValidation.handleSubmit}>
                            <span className="d-flex justify-content-center align-items-center">
                                {/* <Spinner size="sm" type="grow" className="flex-shrink-0" role="status"> Loading... </Spinner> */}
                                <img src={SaveIcon} style={{ height: "16px", width: "16px" }} />
                                <span className="ms-2 text-nowrap">
                                    Save
                                </span>
                            </span>
                        </Button>
                    </div>
                </Col>
            </Row>
        </Container>

    )
}