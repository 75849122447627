import { Link } from "react-router-dom";
import PrintIcon from "../../../assets/images/warehouse/salespage/printer.png";
import GenerateIcon from "../../../assets/images/warehouse/salespage/generate.png";
import ExportIcon from "../../../assets/images/warehouse/salespage/excel.png";
import ResetIcon from "../../../assets/images/warehouse/salespage/reset.png";

export const actions = (togglePrintModal, getSuppliersReportApiCall, resetFilters) => [
  {
    id: "reset",
    label: "Reset",
    icon: ResetIcon,
    clickHandler: resetFilters
  },
  {
    id: "print",
    label: "Print",
    icon: PrintIcon,
    clickHandler:togglePrintModal
  },
  {
    id: "export",
    label: "Export",
    icon: ExportIcon,
    clickHandler: () => getSuppliersReportApiCall(0, 10, true),
  },
];

export function generateColumns() {
  return [
    {
      Header: "Supplier Number",
      accessor: "supplierNumber",
      Cell:({cell:{value}}) => <Link target="_blank" to={`/warehousevendors/${value}`}>{value}</Link>,
    },
    {
      Header: "Supplier Name",
      accessor: "supplierName",
      Cell:({row:{original:{supplierNumber,supplierName}}}) => <Link target="_blank" to={`/warehousevendors/${supplierNumber}`}>{supplierName}</Link>
    },
    {
      Header: "Address",
      accessor: "Address",
    },
    {
      Header: "Phone Num",
      accessor: "phone",
    },
    {
      Header: "Fax",
      accessor: "fax",
    },
    {
      Header: "ST",
      accessor: "st",
    },
  ];
}
