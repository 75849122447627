
import React, { useMemo } from "react";
import { Col, Container, Row, Input, Label } from 'reactstrap';
//Import Flatepicker
import Flatpickr from "react-flatpickr";

import WhTableContainer from "../../../../Components/Common/WhTables";
import { generateColumns, tableData, actions } from "./helper";

export const CustomerBalanceAnalysisTab = () => {
    const columns = useMemo(() => generateColumns(), []);
    return (
        <div className="sale-wh-wrapper">
            <Container fluid className="g-0">
                <Row className="g-0">
                    <Col xs={12} className="px-5 mt-2 mb-2" >
                        <div className="border position-relative p-3">
                            <Row>
                                <Label className="fs-5 ml-2 text-dark position-absolute" style={{ transform: "translateY(-50%)", top: 0, left: "10px" }}> <span className=" px-1 wh-item-bg"> Filters</span></Label>
                                <Col xs={10}>
                                    <Row className="h-100 align-items-center">
                                        <Col xs={4}>
                                            <Row>
                                                <Col xs={3} className="d-flex align-items-center">
                                                    <Label htmlFor="companyName" className="form-label mb-0 text-nowrap">
                                                        Active :
                                                    </Label>
                                                </Col>
                                                <Col xs={9}>
                                                    <select id="mrMiss" className="form-select py-1" data-choices data-choices-sorting="true" >
                                                        <option>All/</option>
                                                    </select>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col xs={4}>
                                            <Row>
                                                <Col xs={3} className="d-flex align-items-center">
                                                    <Label htmlFor="companyName" className="form-label mb-0 text-nowrap">
                                                        Flag :
                                                    </Label>
                                                </Col>
                                                <Col xs={9}>
                                                    <select id="mrMiss" className="form-select py-1" data-choices data-choices-sorting="true" >
                                                        <option>All/</option>
                                                    </select>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col xs={4}>
                                            <Row>
                                                <Col xs={3} className="d-flex align-items-center">
                                                    <Label htmlFor="companyName" className="form-label mb-0 text-nowrap">
                                                        With Balance :
                                                    </Label>
                                                </Col>
                                                <Col xs={9}>
                                                    <select id="mrMiss" className="form-select py-1" data-choices data-choices-sorting="true" >
                                                        <option>All/</option>
                                                    </select>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xs={2}>
                                    <div className="d-flex justify-content-center border border-section py-2 h-100">
                                        {actions(() => { }, () => { }).map((item) => {
                                            return (
                                                <div
                                                    key={item.id}
                                                    className="action-icon-container"
                                                    style={{ width: "30%" }}
                                                    onClick={item.clickHandler || (() => { })}
                                                >
                                                    <img className="action-icon" src={item.icon} />
                                                    <span>{item.label}</span>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </Col>
                            </Row>
                        </div>

                    </Col>
                </Row>
                <Row className="g-0">
                    <Col xs={12}>
                        <WhTableContainer
                            columns={columns}
                            data={(tableData || [])}
                            customPageSize={100}
                            className="custom-header-css"
                            divClass="table-responsive mt-2 table-height-62vh"
                            tableClass="align-middle table-bordered text-center"
                            theadClass="table-light"
                        />
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

