export default {
    CHANGE_EMPLOYEE_API_LOADING_STATUS: "CHANGE_EMPLOYEE_API_LOADING_STATUS",
  
    CREATE_EMPLOYEE_REQUEST: "CREATE_EMPLOYEE_REQUEST",
    CREATE_EMPLOYEE_SUCCESS: "CREATE_EMPLOYEE_SUCCESS",
    CREATE_EMPLOYEE_FAIL: "CREATE_EMPLOYEE_FAIL",
  
    UPDATE_EMPLOYEE_REQUEST: "UPDATE_EMPLOYEE_REQUEST",
    UPDATE_EMPLOYEE_SUCCESS: "UPDATE_EMPLOYEE_SUCCESS",
    UPDATE_EMPLOYEE_FAIL: "UPDATE_EMPLOYEE_FAIL",
  
    GET_EMPLOYEES_REQUEST: "GET_EMPLOYEES_REQUEST",
    GET_EMPLOYEES_SUCCESS: "GET_EMPLOYEES_SUCCESS",
    GET_EMPLOYEES_FAIL: "GET_EMPLOYEES_FAIL",

    DELETE_EMPLOYEES_REQUEST: "DELETE_EMPLOYEES_REQUEST",
    DELETE_EMPLOYEES_SUCCESS: "DELETE_EMPLOYEES_SUCCESS",
    DELETE_EMPLOYEES_FAIL: "DELETE_EMPLOYEES_FAIL",
  };
  