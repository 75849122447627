import React from "react";
import { Col, Container, Input, Label, Row, Button,} from 'reactstrap';
import CancelIcon from "../../assets/images/warehouse/salespage/cancel_icon.png";
import ExcelIcon from "../../assets/images/warehouse/salespage/Excel-Icon.png";
import PreviewIcon from "../../assets/images/warehouse/salespage/preview-icon.png";
import PrinterIcon from "../../assets/images/warehouse/salespage/printer_old.png";

export default function PrintItem() {
    return (
      <Container fluid>
        <Row>
          <Col xs={12} className="bg-primary text-center fs-5 text-white py-2">
            Invoice Payments
          </Col>
        </Row>
        <Row className="p-3 border-bottom">
          <Col xs={6}>
            <Row className="mb-3">
              <Col xs={4} className="d-flex align-items-center">
                <Label htmlFor="street" className="form-label mb-0 text-nowrap">
                  Invoice Number :
                </Label>
              </Col>
              <Col xs={8}>
                <Input
                  name="street"
                  id="street"
                  className="form-control"
                  placeholder="Enter Invoice Number"
                  type="text"
                />
              </Col>
            </Row>
            <Row className="mb-3">
              <Col xs={4} className="d-flex align-items-center">
                <Label htmlFor="street" className="form-label mb-0 text-nowrap">
                  Prev. Balance :
                </Label>
              </Col>
              <Col xs={8}>
                <Input
                  name="street"
                  id="street"
                  className="form-control"
                  placeholder="Enter Prev. Balance"
                  type="text"
                />
              </Col>
            </Row>
            <Row className="mb-3">
              <Col xs={4} className="d-flex align-items-center">
                <Label htmlFor="street" className="form-label mb-0 text-nowrap">
                  Invoice Amount :
                </Label>
              </Col>
              <Col xs={8}>
                <Input
                  name="street"
                  id="street"
                  className="form-control"
                  placeholder="Enter Invoice Amount"
                  type="text"
                />
              </Col>
            </Row>
            <Row className="mb-3">
              <Col xs={4} className="d-flex align-items-center">
                <Label htmlFor="street" className="form-label mb-0 text-nowrap">
                  Amount Due :
                </Label>
              </Col>
              <Col xs={8}>
                <Input name="street" id="street" className="form-control" placeholder="Enter Amont Due" type="text" />
              </Col>
            </Row>
            <Row className="mb-3">
              <Col xs={4} className="d-flex align-items-center">
                <Label htmlFor="street" className="form-label mb-0 text-nowrap">
                  Payments :
                </Label>
              </Col>
              <Col xs={8}>
                <Input name="street" id="street" className="form-control" placeholder="Enter Payments" type="text" />
              </Col>
            </Row>
            <Row className="mb-3">
              <Col xs={4} className="d-flex align-items-center">
                <Label htmlFor="street" className="form-label mb-0 text-nowrap">
                  Final Balanace :
                </Label>
              </Col>
              <Col xs={8} className="d-flex align-items-center">
                <Input
                  name="street"
                  id="street"
                  className="form-control"
                  placeholder="Enter Final Balanace"
                  type="text"
                />
              </Col>
            </Row>
          </Col>
          <Col xs={6}>
            <Button color="primary" className="w-100 mb-2 wh-btn" outline>
              <span className="d-flex justify-content-center align-items-center">
                {/* <Spinner size="sm" type="grow" className="flex-shrink-0" role="status"> Loading... </Spinner> */}
                <img src={ExcelIcon} style={{ height: "16px", width: "16px" }} />
                <span className="ms-2">Sales Order</span>
              </span>
            </Button>
            <Button color="primary" className="w-100 mb-2 wh-btn" outline>
              <span className="d-flex justify-content-center align-items-center">
                {/* <Spinner size="sm" type="grow" className="flex-shrink-0" role="status"> Loading... </Spinner> */}
                <img src={PrinterIcon} style={{ height: "16px", width: "16px" }} />
                <span className="ms-2">Print Invoice</span>
              </span>
            </Button>
            <Button color="primary" className="w-100 mb-2 wh-btn" outline>
              <span className="d-flex justify-content-center align-items-center">
                {/* <Spinner size="sm" type="grow" className="flex-shrink-0" role="status"> Loading... </Spinner> */}
                <img src={PreviewIcon} style={{ height: "16px", width: "16px" }} />
                <span className="ms-2">Preview</span>
              </span>
            </Button>
            <Button color="danger" className="w-100 mb-2 wh-btn" outline>
              <span className="d-flex justify-content-center align-items-center">
                {/* <Spinner size="sm" type="grow" className="flex-shrink-0" role="status"> Loading... </Spinner> */}
                <img src={CancelIcon} style={{ height: "16px", width: "16px" }} />
                <span className="ms-2">Close</span>
              </span>
            </Button>
          </Col>
        </Row>
        <Row className="p-3">
          <Col xs={6}>
            <Row className="mb-3">
              <Col xs={4} className="d-flex align-items-center">
                <div className="form-check ms-2">
                  <Input className="form-check-input" type="checkbox" id="formCheck6" defaultChecked />
                  <Label className="form-check-label text-nowrap" htmlFor="formCheck6">
                    Email Copy
                  </Label>
                </div>
              </Col>
              <Col xs={8} className="d-flex align-items-center">
                <Input name="street" id="street" className="form-control" type="text" />
              </Col>
            </Row>
          </Col>
          <Col xs={6}>
            <Row className="mb-3">
              <Col xs={5} className="d-flex align-items-center">
                <Label htmlFor="street" className="form-label mb-0 text-nowrap">
                  Invoice Date :
                </Label>
              </Col>
              <Col xs={7} className="d-flex align-items-center">
                <Input
                  name="street"
                  id="street"
                  className="form-control"
                  type="text"
                />
              </Col>
            </Row>
          </Col>

          <Col xs={6}>
            <Row className="mb-3">
              <Col xs={4} className="d-flex align-items-center">
                <div className="form-check ms-2">
                  <Input className="form-check-input" type="checkbox" id="formCheck6" defaultChecked />
                  <Label className="form-check-label text-nowrap" htmlFor="formCheck6">
                    Text Copy
                  </Label>
                </div>
              </Col>
              <Col xs={8} className="d-flex align-items-center">
                <Input name="street" id="street" className="form-control" type="text" />
              </Col>
            </Row>
          </Col>
          <Col xs={6}>
            <Row className="mb-3">
              <Col xs={5} className="d-flex align-items-center">
                <Label htmlFor="street" className="form-label mb-0 text-nowrap">
                  Ship Date :
                </Label>
              </Col>
              <Col xs={7} className="d-flex align-items-center">
                <Input
                  name="street"
                  id="street"
                  className="form-control"
                  type="text"
                />
              </Col>
            </Row>
          </Col>

          <Col xs={6}></Col>
          <Col xs={6}>
            <Row className="mb-3">
              <Col xs={5} className="d-flex align-items-center">
                <Label htmlFor="street" className="form-label mb-0 text-nowrap">
                  State Reporting Date :
                </Label>
              </Col>
              <Col xs={7} className="d-flex align-items-center">
                <Input
                  name="street"
                  id="street"
                  className="form-control"
                  type="text"
                />
              </Col>
            </Row>
          </Col>

          <Col xs={6}></Col>
          <Col xs={6}>
            <Row className="mb-3">
              <Col xs={5} className="d-flex align-items-center">
                <Label htmlFor="street" className="form-label mb-0 text-nowrap">
                  Station :
                </Label>
              </Col>
              <Col xs={7} className="d-flex align-items-center">
                <Input
                  name="street"
                  id="street"
                  className="form-control"
                  type="text"
                />
              </Col>
            </Row>
          </Col>

          <Col xs={6}></Col>
          <Col xs={6}>
            <Row className="mb-3">
              <Col xs={5} className="d-flex align-items-center">
                <Label htmlFor="street" className="form-label mb-0 text-nowrap">
                  User :
                </Label>
              </Col>
              <Col xs={7} className="d-flex align-items-center">
                <Input
                  name="street"
                  id="street"
                  className="form-control"
                  type="text"
                />
              </Col>
            </Row>
          </Col>

          <Col xs={6}></Col>
          <Col xs={6}>
            <Row className="mb-3">
              <Col xs={5} className="d-flex align-items-center">
                <Label htmlFor="street" className="form-label mb-0 text-nowrap">
                  Printed On :
                </Label>
              </Col>
              <Col xs={7} className="d-flex align-items-center">
                <Input
                  name="street"
                  id="street"
                  className="form-control"
                  type="text"
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    );
}