import { Link } from "react-router-dom";
import ResetIcon from "../../assets/images/warehouse/salespage/reset.png";
import PrintIcon from "../../assets/images/warehouse/salespage/printer.png";
import ExportIcon from "../../assets/images/warehouse/salespage/excel.png";
import DeleteIcon from "../../assets/images/warehouse/salespage/delete.png";
import NewIcon from "../../assets/images/warehouse/salespage/new.png";

export const customerColumnsList = [
    {
      id: "customerNumber",
      name: "Cust. Num",
      cellClassName: "fw-medium",
    },
    {
      id: "companyName",
      name: "Company",
    },
    {
      id: "customerName",
      name: "Customer Name",
      cellClassName: "fbadge badge-soft-primary",
    },
  ];

export const actions = (togglePrintModal, history, toggleDeleteModal, toggleSearchModal) => [
    {
        id: "new",
        label: "New",
        icon: NewIcon,
        clickHandler:() => history.push("/warehousecustomers/new"),
    }, {
        id: "reset",
        label: "Reset",
        icon: ResetIcon,
        clickHandler:() => {}
    }, {
        id: "print",
        label: "Print",
        icon: PrintIcon,
        clickHandler:togglePrintModal,
    }, {
        id: "export",
        label: "Export",
        icon: ExportIcon
    }, {
        id: "delete",
        label: "Delete",
        icon: DeleteIcon,
        clickHandler : toggleDeleteModal
    }, {
        id: "smart_search",
        label: "Smart-Search",
        icon: <i className="ri-search-2-fill text-primary" style={{fontSize:"20px"}}></i>,
        clickHandler:toggleSearchModal,
    }
]

export function generateColumns(page) {
    return [
        // {
        //     Header: "#",
        //     Cell: () => {
        //         return (
        //             <div className="form-check">
        //                 <input className="form-check-input" type="checkbox" name="checkAll" value="option1" />
        //             </div>
        //         );
        //     },
        // },
        {
            Header: "S.No",
            accessor: "sNo",
            Cell:({row}) => (((page - 1) * 10 ) + (row.index + 1)),
            style:{whiteSpace:'nowrap'}
        },
        {
            Header: "Cust. Num",
            accessor: "customerNumber",
            Cell:({cell:{value}}) => <Link to={`/warehousecustomers/${value}`}>{value}</Link>,
            style:{whiteSpace:'nowrap'}
        }, {
            Header: "Company Name",
            accessor: "companyName",
            Cell:({row:{original:{customerNumber,companyName}}}) => <Link to={`/warehousecustomers/${customerNumber}`}>{companyName}</Link>,
            style:{whiteSpace:'nowrap'}
        }, {
            Header: "Customer Name",
            accessor: "customerName",
            Cell:({row:{original : {firstName, lastName }}}) => `${firstName || ""} ${lastName || ""} ${(!firstName && !lastName) ? "-" : ""}`,
            style:{whiteSpace:'nowrap'}
        }, 
        // {
        //     Header: "Customer Type",
        //     accessor: "customerType",
        //     Cell:({cell:{value}}) => value || "-",
        //     style:{whiteSpace:'nowrap'}
        // },
         {
            Header: "Phone",
            accessor: "phone",
            Cell:({cell:{value}}) => value || "-",
            style:{whiteSpace:'nowrap'}
        }, {
            Header: "Email",
            accessor: "email",
            Cell:({cell:{value}}) => value || "-",
            style:{whiteSpace:'nowrap'}
        }, 
        // {
        //     Header: "License Number",
        //     accessor: "licenseNumber",
        //     Cell:({cell:{value}}) => value || "-",
        //     style:{whiteSpace:'nowrap'}
        // }, 
        {
            Header: "Street",
            accessor: "street",
            Cell:({cell:{value}}) => value || "-",
            style:{whiteSpace:'nowrap'}
        },{
            Header: "City",
            accessor: "city",
            Cell:({cell:{value}}) => value || "-",
            style:{whiteSpace:'nowrap'}
        },
        // {
        //     Header: "Country",
        //     accessor: "country",
        //     Cell:({cell:{value}}) => value || "-",
        //     style:{whiteSpace:'nowrap'}
        // }
    ]
}
export function getSearchLabel(searchBy) {
    switch(searchBy) {
      case "phone": return "Phone";
      case "customerNumber": return "Customer Number";
      case "city": return "City";
      case "company": return "Company";
      case "name": return "Customer Name";
      case "dateCreated": return "Date Created";
      case "license": return "License";
      default :  return "";
    }
}