import React, { useMemo, useState, useRef, useEffect, useCallback } from "react";
import moment from "moment";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { Container, Row, Col, Label, Input, Modal, ModalHeader, ModalBody, Form, FormGroup } from "reactstrap";
import { actions, generateColumns } from "./helper";
import BackIcon from "../../../../../assets/images/warehouse/menu/back.png";
import WhTableContainer from "../../../../../Components/Common/WhTables";
import PrintItem from "./PrintItem";
import { getStartDateFilterValue, getEndDateFilterValue } from "../../../../../utility/dateutility";
//Api Helper
import { generateApiUrl } from "../../../../../api/apihelper";
//Actions
import { getCustomersStatsAct, getInvPricingReportPrintAct } from "../../../../../store/reports/reportsactions";
//Utility
import { getYyyymmddDateFormatted } from "../../../../../utility/dateutility";
import F12CloseModalListener from "../../../../../Components/Common/F12close";
import ApiLoader from "../../../../../Components/Common/ApiLoader";

export const Statistics = ({ history, toast }) => {
  const [filters, setFilters] = useState({
    dateType:"created",
    startDate: getYyyymmddDateFormatted(moment()),
    endDate: getYyyymmddDateFormatted(moment()),
  });
  const [tableData, setTableData] = useState([]);
  const [showPrintModal, setShowPrintModal] = useState(false);
  const isDownloadBtnClicked = useRef(false);
  const [pageCount, setPageCount] = useState(0);

  function togglePrintModal() {
    setShowPrintModal(!showPrintModal);
  }

  const dispatch = useDispatch();

  const { getCustomersStatsResp, reportsErrorResp, getCustomersReportLoading,getInvPricingReportLoading } = useSelector(
    ({ reports }) => ({
      getCustomersStatsResp: reports.getCustomersStatsResp,
      getCustomersReportLoading: reports.getCustomersReportLoading,
      getInvPricingReportLoading: reports.getInvPricingReportLoading,
      reportsErrorResp: reports.reportsErrorResp,
    }),
    shallowEqual
  );
  const inventoryResp = useRef({
    prevGetCustomersStatsResp: getCustomersStatsResp,
    prevReportsErrorResp: reportsErrorResp,
  });

  useEffect(() => {
    const { prevGetCustomersStatsResp, prevReportsErrorResp } = inventoryResp.current || {};
    if (getCustomersStatsResp && prevGetCustomersStatsResp !== getCustomersStatsResp) {
      if (isDownloadBtnClicked.current) {

        const url = window.URL.createObjectURL(new Blob([getCustomersStatsResp]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
         "customers_report_stats.csv"
        );
        document.body.appendChild(link);
        link.click();
        link.remove();
        isDownloadBtnClicked.current = false;
      } else {
        setTableData(getCustomersStatsResp?.data?.report || []);
        setPageCount((getCustomersStatsResp?.data?.total))
      }
      
    } else if (reportsErrorResp && prevReportsErrorResp !== reportsErrorResp) {
      const { errors, error, message } = reportsErrorResp || {};
      toast.error((Array.isArray(errors) && errors[0] && errors[0].msg) || error || message);
    }
    inventoryResp.current.prevGetCustomersStatsResp = getCustomersStatsResp;
    inventoryResp.current.prevReportsErrorResp = reportsErrorResp;
  }, [getCustomersStatsResp, reportsErrorResp]);

  const getCustomersReportApiCall = useCallback(
    (page, pageSize, download, downloadType='pdf') => {
      const { startDate, endDate, dateType } = filters || {};
      let params = {
        page: page ? page + 1 : 1,
        pageSize: pageSize || 30,
        dateType,
      };
      if (startDate) params.startDate = getStartDateFilterValue(startDate);
      if (endDate) params.endDate = getEndDateFilterValue(endDate);
      if (download && downloadType === 'html') {
        params.download = true;
        params.downloadType = downloadType;
        dispatch(
          getInvPricingReportPrintAct(
            generateApiUrl(`get_customers_stats`),
            params
          )
        );
      } 
      else if (download){
        params.download = true;
        isDownloadBtnClicked.current = true; 
        dispatch(
          getCustomersStatsAct(
            generateApiUrl(`get_customers_stats`),
            params,
            {responseType: 'blob'}
          )
        );}
        else {
          dispatch(
            getCustomersStatsAct(
              generateApiUrl(`get_customers_stats`),
              params
            )
          );
        }
    },
    [filters]
  );

  useEffect(() => {
    const debounceFn = setTimeout(() => {
      getCustomersReportApiCall();
    }, 400);
    return () => {
      clearTimeout(debounceFn);
    };
  }, [getCustomersReportApiCall]);

  const handlePageChange = (page, pageSize) => {
    getCustomersReportApiCall(page, pageSize);
  };

  const filtersChngHndlr = (e) => {
    const { name, value } = e.target;
    setFilters((prev) => ({ ...prev, [name]: value }));
  };

  const dateTypeChngHndlr = (e) => {
    const {name} = e.target;
    if(filters.dateType !== name) setFilters((prev) => ({ ...prev, dateType: name }));
  }

  const resetFilters = () => {
    setFilters({
      dateType:"created",
      startDate: getYyyymmddDateFormatted(moment()),
      endDate: getYyyymmddDateFormatted(moment()),
    });
  }

  const columns = useMemo(() => generateColumns(), []);
  return (
    <>
      <Container fluid>
        <Row>
          <Col xs="12" className="mb-0">
            <div className="border ps-4 my-2" style={{ position: "relative" }}>
              <Label className="text-dark fs-5" style={{ position: "absolute", transform: "translateY(-50%)" }}>
              <span className=" px-1 wh-item-bg"> Filters</span>
              </Label>
              <Row>
                <Col xs="9" className="border-end pe-5">
                  <Row className="pt-2">
                    <Col xs="3">
                      <div className="form-check my-3 mb-1">
                        <Input className="form-check-input" type="checkbox" id="created" name="created" checked={filters.dateType === "created"} onChange={dateTypeChngHndlr} />
                        <Label className="form-check-label text-nowrap" htmlFor="created">
                          Created
                        </Label>
                      </div>
                      <div className="form-check mb-1">
                        <Input className="form-check-input" type="checkbox" id="lastUsed" name="lastUsed" checked={filters.dateType === "lastUsed"} onChange={dateTypeChngHndlr} />
                        <Label className="form-check-label text-nowrap" htmlFor="lastUsed">
                          Last Used
                        </Label>
                      </div>
                      <div className="form-check mb-1">
                        <Input className="form-check-input" type="checkbox" id="activeCustomer" name="activeCustomer" checked={filters.dateType === "activeCustomer"} onChange={dateTypeChngHndlr} />
                        <Label className="form-check-label text-nowrap" htmlFor="activeCustomer">
                          Active Customer
                        </Label>
                      </div>
                      <div className="form-check mb-1">
                        <Input className="form-check-input" type="checkbox" id="holdpayment" name="holdpayment" checked={filters.dateType === "holdpayment"} onChange={dateTypeChngHndlr} />
                        <Label className="form-check-label text-nowrap" htmlFor="holdpayment">
                          Hold payment
                        </Label>
                      </div>
                    </Col>
                    <Col xs="3">
                      <FormGroup className="mb-2">
                        <Label className="mb-0">From</Label>
                        <Input type="date" className="py-1" name="startDate" value={filters.startDate} onChange={filtersChngHndlr}  />
                      </FormGroup>
                      <FormGroup>
                        <Label className="mb-0">To</Label>
                        <Input type="date" className="py-1" name="endDate" value={filters.endDate} onChange={filtersChngHndlr} />
                      </FormGroup>
                    </Col>
                  </Row>
                </Col>
                <Col xs="3">
                  <div className="d-flex align-items-center pe-xxl-5 pe-4 pt-3">
                    <div className="sale-wh-wrapper flex-grow-1 mt-0 me-xxl-4 me-2">
                      <div className="d-flex justify-content-around border border-section py-2 h-100">
                        {actions(togglePrintModal,getCustomersReportApiCall, resetFilters).map((item) => {
                          return (
                            <div
                              key={item.id}
                              className="action-icon-container"
                              onClick={item.clickHandler || (() => {})}
                              style={{cursor:"pointer"}}
                            >
                              <img className="action-icon" src={item.icon} />
                              <span>{item.label}</span>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                    <div
                      className="d-flex flex-column align-items-center"
                      style={{ cursor: "pointer" }}
                      onClick={() => history.push("/reportsmenu")}
                    >
                      <img src={BackIcon} width="32" height="32" alt="back-icon" />
                      <div className="text-decoration-underline mb-0">Go Back</div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
          <Col xs="12">
            <WhTableContainer
              columns={columns}
              data={tableData || []}
              className="custom-header-css"
              divClass="table-responsive table-container-customersreport-statics"
              tableClass="align-middle table-bordered"
              theadClass="table-light"
              handlePageChange={handlePageChange}
              pageCount={pageCount || 0}
              customPageSize={30}
              showNoDataComponent={true}
            />
          </Col>
        </Row>
      </Container>
      <Modal
     id="showPrintModal"
     isOpen={showPrintModal}
     toggle={togglePrintModal}
     centered
     size="lg"
   >
     <F12CloseModalListener onClose={togglePrintModal} />
     <ModalHeader className="py-2 px-3" toggle={togglePrintModal}>
       Print Options
     </ModalHeader>
     <Form
       onSubmit={(e) => {
         e.preventDefault();
       }}
     >
       <ModalBody className="px-0 pt-0">
         <PrintItem
           getCustomersReportApiCall={getCustomersReportApiCall}
           filters={filters}
           showPrintModal={showPrintModal}
         />
       </ModalBody>
     </Form>
   </Modal>
   <ApiLoader loading={getCustomersReportLoading}/>
   <ApiLoader loading={getInvPricingReportLoading}/>
    </>
  );
};
