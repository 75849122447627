import { takeLatest, call, put, delay } from "redux-saga/effects";
import { getData, postData, putData, deleteData } from "../../api/apiactions";
import { handleApiResponse } from "../../utility/sagautility";
import purchaseorderconstants from "./purchaseorderconstants";
import {changePoAPiLoadingStatusAct, changeGetAllPurchaseOrdersLoadingStatusAct} from "./purchaseorderactions"

function withLoading(fn) {
    return function* withLoadingGenerator(...args) {
        yield put(changePoAPiLoadingStatusAct(true));
        yield delay(500);
        yield* fn(...args);
        yield put(changePoAPiLoadingStatusAct(false));
    }
}

function* getAllPurchaseOrdersGen(action) {
    yield put(changeGetAllPurchaseOrdersLoadingStatusAct(true));
    const response = yield call(getData,action.apiEndpoint,action.payload,action.optionalConfig);
    yield call(handleApiResponse, response, {
        successAction : purchaseorderconstants.GET_ALL_PURCHASE_ORDERS_SUCCESS,
        failAction :  purchaseorderconstants.GET_ALL_PURCHASE_ORDERS_FAIL,
    });
    yield delay(500);
    yield put(changeGetAllPurchaseOrdersLoadingStatusAct(false)); 
}

function* createPurchaseOrderGen(action) {
    const response = yield call(postData,action.apiEndpoint,action.payload,action.optionalConfig);
    yield call(handleApiResponse, response, {
        successAction : purchaseorderconstants.CREATE_PURCHASE_ORDER_SUCCESS,
        failAction :  purchaseorderconstants.CREATE_PURCHASE_ORDER_FAIL,
    });
}

function* getPurchaseOrderDetailsGen(action) {
    const response = yield call(getData,action.apiEndpoint,action.payload,action.optionalConfig);
    yield call(handleApiResponse, response, {
        successAction : purchaseorderconstants.GET_PURCHASE_ORDER_DETAILS_SUCCESS,
        failAction :  purchaseorderconstants.GET_PURCHASE_ORDER_DETAILS_FAIL,
    });
}

function* updatePurchaseOrderDetailsGen(action) {
    const response = yield call(putData,action.apiEndpoint,action.payload,action.optionalConfig);
    yield call(handleApiResponse, response, {
        successAction : purchaseorderconstants.UPDATE_PURCHASE_ORDER_DETAILS_SUCCESS,
        failAction :  purchaseorderconstants.UPDATE_PURCHASE_ORDER_DETAILS_FAIL,
    });
}

function* getMaxPONoGen(action) {
    const response = yield call(getData,action.apiEndpoint,action.payload,action.optionalConfig);
    yield call(handleApiResponse, response, {
        successAction : purchaseorderconstants.GET_MAX_PO_NO_SUCCESS,
        failAction :  purchaseorderconstants.GET_MAX_PO_NO_FAIL,
    });
}

function* receivePoGen(action) {
    const response = yield call(postData,action.apiEndpoint,action.payload,action.optionalConfig);
    yield call(handleApiResponse, response, {
        successAction : purchaseorderconstants.RECEIVE_PO_SUCCESS,
        failAction :  purchaseorderconstants.RECEIVE_PO_FAIL,
    });
}

function* deletePoGen(action) {
    const response = yield call(deleteData,action.apiEndpoint,action.payload,action.optionalConfig);
    yield call(handleApiResponse, response, {
        successAction : purchaseorderconstants.DELETE_PO_SUCCESS,
        failAction :  purchaseorderconstants.DELETE_PO_FAIL,
    });
}

function* openPoGen(action) {
    const response = yield call(postData,action.apiEndpoint,action.payload,action.optionalConfig);
    yield call(handleApiResponse, response, {
        successAction : purchaseorderconstants.OPEN_PO_SUCCESS,
        failAction :  purchaseorderconstants.OPEN_PO_FAIL,
    });
}

function* downloadPoPdfGen(action) {
    const response = yield call(getData,action.apiEndpoint,action.payload,action.optionalConfig);
    yield call(handleApiResponse, response, {
        successAction : purchaseorderconstants.DOWNLOAD_PO_PDF_SUCCESS,
        failAction :  purchaseorderconstants.DOWNLOAD_PO_PDF_FAIL,
    });
}

export function* purchaseOrderSaga() {
    yield takeLatest(purchaseorderconstants.GET_ALL_PURCHASE_ORDERS_REQUEST, getAllPurchaseOrdersGen);
    yield takeLatest(purchaseorderconstants.CREATE_PURCHASE_ORDER_REQUEST, withLoading(createPurchaseOrderGen));
    yield takeLatest(purchaseorderconstants.GET_PURCHASE_ORDER_DETAILS_REQUEST, getPurchaseOrderDetailsGen);
    yield takeLatest(purchaseorderconstants.UPDATE_PURCHASE_ORDER_DETAILS_REQUEST, withLoading(updatePurchaseOrderDetailsGen));
    yield takeLatest(purchaseorderconstants.GET_MAX_PO_NO_REQUEST, getMaxPONoGen);
    yield takeLatest(purchaseorderconstants.RECEIVE_PO_REQUEST, withLoading(receivePoGen));
    yield takeLatest(purchaseorderconstants.DELETE_PO_REQUEST, withLoading(deletePoGen));
    yield takeLatest(purchaseorderconstants.OPEN_PO_REQUEST, withLoading(openPoGen));
    yield takeLatest(purchaseorderconstants.DOWNLOAD_PO_PDF_REQUEST, withLoading(downloadPoPdfGen));
}
