import React, {  useState, useEffect, useMemo, useCallback } from "react";
import { Col, Container, Input, Label, Row, Button } from 'reactstrap';
import WhTableContainer from "../../Components/Common/WhTables";
import {getSearchLabel} from "./helper";
import {generateColumns} from "./searchvendorhelper"

export default function SearchVendor({ modalTableData, modalPageCount, getSuppliersListAct, dispatch, generateApiUrl, getSuppliersListLoading}) {
    // const {searchBy} = filters
    const [search,setSearch] = useState("");
    const [searchBy, setSearchBy] = useState("supplierName");
    const getAllSuppliersApiCall = useCallback(
      (page, pageSize) => {
        let params = {
          page: page ? page + 1 : 1,
          pageSize: pageSize || 30,
        };
        if (search) params[searchBy] = search;
        dispatch(getSuppliersListAct(generateApiUrl(`get_suppliers_list`), params));
      },
      [search, searchBy]
    );
    useEffect(() => {
      const debounceFn = setTimeout(() => {
        getAllSuppliersApiCall();
      }, 400);
      return () => {
        clearTimeout(debounceFn);
      };
    }, [getAllSuppliersApiCall]);
    const handlePageChange = (page, pageSize) => {
      getAllSuppliersApiCall(page, pageSize);
    };
    const handleChange = (e) => {
     const {name} = e.target;
     if(searchBy != name) {
       setSearchBy(name);
       if(name === "createdDate" || searchBy === "createdDate") setSearch("");
      }
    }
    // const searchHandler = () => {
    //   setFilters(prev => ({...prev, search, searchBy}));
    //   setShowSearchModal(false);
    // }
    const columns = useMemo(() => generateColumns(), []);
    return (
      <Container fluid>
        <Row className="mb-4">
          <Col xs={12} className="bg-primary text-center fs-5 text-white py-2">
            Smart Search-Vendor
          </Col>
        </Row>
        <Row className="mb-4">
          <Col xs={12}>
            <div className="px-3" style={{ position: "relative" }}>
              <Label
                className="fs-5 font-weight-bold "
                style={{ position: "absolute", transform: "translateY(-50%)" }}
              >
                <span className=" px-1 wh-item-bg"> Type Of Search</span>
              </Label>
              <Row className="border pt-4 pb-3">
                <Col xs="4" className="mb-2">
                  <Input
                    type="radio"
                    name="supplierName"
                    className="me-2"
                    onChange={handleChange}
                    checked={searchBy == "supplierName"}
                  />{" "}
                  By Company Name
                </Col>
                <Col xs="4">
                  <Input
                    type="radio"
                    name="supplierNumber"
                    className="me-2"
                    onChange={handleChange}
                    checked={searchBy == "supplierNumber"}
                  />{" "}
                  By Supplier Number
                </Col>
                <Col xs="4" className="mb-2">
                  <Input
                    type="radio"
                    name="city"
                    className="me-2"
                    onChange={handleChange}
                    checked={searchBy == "city"}
                  />{" "}
                  By City
                </Col>
                <Col xs="4" className="mb-2">
                  <Input
                    type="radio"
                    name="country"
                    className="me-2"
                    onChange={handleChange}
                    checked={searchBy == "country"}
                  />{" "}
                  By County
                </Col>
                {/* <Col xs="4">
                  <Input type="radio" name="searchType" className="me-2" /> By Other Reference
                </Col> */}
              </Row>
            </div>
          </Col>
        </Row>

        {searchBy && (
          <Row className="mb-2">
            <Col xs={12}>
              <Label htmlFor="userrole" className="form-label mb-1">
                Search By {getSearchLabel(searchBy)}
              </Label>
              <div className="d-flex align-items-center">
                {(searchBy !== "createdDate") ? <Input className="form-control me-2" type="text" value={search} onChange={e => setSearch(e.target.value)} /> : 
                <Input className="form-control me-2" type="date" value={search} onChange={e => setSearch(e.target.value)} />
                }
                {/* <Button color="primary" onClick={searchHandler} disabled={search ? false : true}>Search</Button> */}
              </div>
            </Col>
          </Row>
        )}

        {/* <Row>
          <Col xs="12" className="d-flex justify-content-end"></Col>
        </Row> */}
        <Row>
          <Col xs={12} style={{ minHeight: "350px", maxHeight: "350px", overflowY: "auto" }}>
            <WhTableContainer
              columns={columns}
              data={modalTableData}
              className="custom-header-css"
              divClass="table-responsive mt-3"
              tableClass="align-middle"
              theadClass="table-light"
              handlePageChange={handlePageChange}
              pageCount={modalPageCount}
              customPageSize={30}
              showNoDataComponent={true}
              loading={getSuppliersListLoading}
            />
          </Col>
        </Row>
      </Container>
    );
}