import React, { useMemo, useEffect, useCallback } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { Col, Container, Row } from 'reactstrap';
import WhTableContainer from "../../../../Components/Common/WhTables";
import { generateColumns } from "./helper";
//Api Helper
import { generateApiUrl } from "../../../../api/apihelper";
//Actions
import { getSalesOfGivenItemAct } from "../../../../store/inventory/inventoryactions";

export const SalesTab = ({history,id}) => {
    const dispatch = useDispatch();
    const { getSalesOfGivenItemResp,  } = useSelector(({inventory}) => ({
      getSalesOfGivenItemResp:inventory.getSalesOfGivenItemResp,
    }),shallowEqual);
    const purchaseOrdersApiCall = useCallback((page,pageSize) => {
        dispatch(
            getSalesOfGivenItemAct(generateApiUrl(`get_sales_of_given_item`,{itemNumber : id}), {
                page : page || 1,
                pageSize : pageSize || 10,
                orderBy:"invoiceDate",
                sort:"desc",
                posted:true
            })
          );
    },[])
    useEffect(() => {
        purchaseOrdersApiCall();
    },[purchaseOrdersApiCall]);
    const handlePageChange = (page,pageSize) => {
        purchaseOrdersApiCall(page+1,pageSize)
    }
    const { data : {page}} = getSalesOfGivenItemResp || {data : {page:1}};
    const columns = useMemo(() => generateColumns(history,page), [page]);
    return (
        <React.Fragment>
            <Container fluid className="px-4">
                <Row className="m-0">
                    <Col className="px-0" xs={12}>
                    <WhTableContainer
                            columns={columns}
                            data={(getSalesOfGivenItemResp?.data?.invoiceLineItems || [])}
                            className="custom-header-css"
                            divClass="table-responsive mt-3 whbrand-table"
                            tableClass="align-middle table-bordered"
                            theadClass="table-light"
                            handlePageChange={handlePageChange}
                            pageCount={Math.ceil((getSalesOfGivenItemResp?.data?.total || 0) / 10)}
                            customPageSize={30}
                            showNoDataComponent={true}
                        />
                    </Col>
                </Row>
            </Container>
        </React.Fragment>
    )
}