import React from "react";
import {  Col, Container, Input, Label, Row, Button, } from "reactstrap";
import SaveIcon from "../../../../../../../assets/images/warehouse/salespage/save.png";

export const Administration = ({permissions, handleSalesPermissionsChange}) => {
  return (
    <Container fluid>
      <Row className="mb-5">
        <Col xs="12" className="d-flex justify-content-end px-0">
          <Button color="primary" className="wh-btn" outline>
            <span className="d-flex justify-content-center align-items-center">
              {/* <Spinner size="sm" type="grow" className="flex-shrink-0" role="status"> Loading... </Spinner> */}
              <img src={SaveIcon} style={{ height: "24px", width: "24px" }} />
              <span className="ms-2">Save</span>
            </span>
          </Button>
        </Col>
        <Col xs="5">
          <Row className="mb-3">
            <Col xs={4} className="d-flex align-items-center">
              <Label htmlFor="companyName" className="form-label mb-0 text-nowrap">
              Sales Comm. :
              </Label>
            </Col>
            <Col xs={8}>
              <Input type="text" className="form-control py-1" placeholder="Enter Sales Comm."  name="SALES_COMM" value={permissions.SALES_COMM} onChange={handleSalesPermissionsChange} />
            </Col>
          </Row>
          <Row className="mb-3">
            <Col xs={4} className="d-flex align-items-center">
              <Label htmlFor="companyName" className="form-label mb-0 text-nowrap">
              Profit Comm. :
              </Label>
            </Col>
            <Col xs={8}>
              <Input type="text" className="form-control py-1" placeholder="Enter Profit Comm." name="PROFIT_COMM" value={permissions.PROFIT_COMM} onChange={handleSalesPermissionsChange} />
            </Col>
          </Row>
          <div className="form-check mb-3">
            <Input className="form-check-input" type="checkbox" name="VIEW_MENU_FUNCS" checked={permissions.VIEW_MENU_FUNCS} onChange={handleSalesPermissionsChange} />
            <Label className="form-check-label text-nowrap">
            View Menu Functions
            </Label>
          </div>

          <div className="form-check mb-3">
            <Input className="form-check-input" type="checkbox" name="VIEW_ALL_MENU_ITEMS" checked={permissions.VIEW_ALL_MENU_ITEMS} onChange={handleSalesPermissionsChange} />
            <Label className="form-check-label text-nowrap">
              View All Menu Items
            </Label>
          </div>

          <div className="form-check mb-3">
            <Input className="form-check-input" type="checkbox" name="WORK_OFFLINE" checked={permissions.WORK_OFFLINE} onChange={handleSalesPermissionsChange} />
            <Label className="form-check-label text-nowrap">
            Work Offline
             </Label>
          </div>

          <div className="form-check mb-3">
            <Input className="form-check-input" type="checkbox" name="LOG_TIME_IN_OUT" checked={permissions.LOG_TIME_IN_OUT} onChange={handleSalesPermissionsChange} />
            <Label className="form-check-label text-nowrap">
              Log Time In/Out
            </Label>
          </div>

          <div className="mb-3 d-flex align-items-center">
            <Input type="text" className="form-control py-1 w-50" name="IDLE_TIME__IN_MINS" value={permissions.IDLE_TIME_IN_MINS} onChange={handleSalesPermissionsChange} />
            <Label className="w-50 ps-2 mb-0">Idle Time in Minutes</Label>
          </div>
         
        </Col>
      </Row>
    </Container>
  );
};
