import React, { useMemo, useState, useRef, useEffect, useCallback, useContext } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { Col, Container, Input, Label, Row, Modal, ModalHeader, ModalBody, Form,  Nav,
  NavItem,
  NavLink, } from "reactstrap";
  import classnames from "classnames";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import WhTableContainer from "../../Components/Common/WhTables";
import { actions, generateColumns } from "./helper";
import DeleteModal from "../../Components/Common/DeleteModal";
import SearchPurchaseOrders from "./searchpurchaseorders";
import PaymentsAnywhere from "../../Components/Common/paymentsanywhere";
import ApiLoader from "../../Components/Common/ApiLoader";
//Api Helper
import { generateApiUrl } from "../../api/apihelper";
//Actions
import { getAllPurchaseOrdersAct, deletePoAct } from "../../store/purchaseorder/purchaseorderactions";
//Context
import { PermissionsContext } from "../../context/PermissionsContext"
//HOC
import withPermission from "../../hoc/withPermission"

function WarehousePurchaseOrders({ history }) {
  const [tableData, setTableData] = useState(null);
  const [modalTableData, setModalTableData] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [modalPageCount, setModalPageCount] = useState(0);
  const [rowSelection, setRowSelection] = useState({});
  const [showSearchModal, setShowSearchModal] = useState(false);
  function toggleSearchModal() {
    setShowSearchModal(!showSearchModal);
  }
  const [customActiveTab, setcustomActiveTab] = useState("received");
  const toggleCustom = (tab) => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab);
      setTableData([]);
    }
  };
  const [filters, setFilters] = useState({
    search: "",
    startDate: "",
    endDate: "",
    searchBy: "supplierName",
  });
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const toggleDeleteModal = () => setShowDeleteModal(!showDeleteModal);
  const dispatch = useDispatch();
  const { permissions } = useContext(PermissionsContext);
  const { getAllPurchaseOrdersResp, deletePoResp, purchaseOrderErrorResp, purchaseOrderApiLoading, getAllPurchaseOrdersLoading } = useSelector(
    ({ purchaseOrder }) => ({
      getAllPurchaseOrdersResp: purchaseOrder.getAllPurchaseOrdersResp,
      deletePoResp: purchaseOrder.deletePoResp,
      purchaseOrderErrorResp: purchaseOrder.purchaseOrderErrorResp,
      purchaseOrderApiLoading: purchaseOrder.purchaseOrderApiLoading,
      getAllPurchaseOrdersLoading: purchaseOrder.getAllPurchaseOrdersLoading
    }),
    shallowEqual
  );
  const columns = useMemo(() => generateColumns(), []);
  const inventoryResp = useRef({
    prevGetAllPurchaseOrdersResp: getAllPurchaseOrdersResp,
    prevDeletePoResp: deletePoResp,
    prevPurchaseOrderErrorResp: purchaseOrderErrorResp,
  });
  useEffect(() => {
    const { prevGetAllPurchaseOrdersResp, prevDeletePoResp, prevPurchaseOrderErrorResp } = inventoryResp.current || {};
    if (getAllPurchaseOrdersResp && prevGetAllPurchaseOrdersResp !== getAllPurchaseOrdersResp) {
      if (!showSearchModal) {
        setTableData(getAllPurchaseOrdersResp?.data?.purchaseOrders || []);
        setPageCount(getAllPurchaseOrdersResp?.data?.total);
      } else {
        setModalTableData(getAllPurchaseOrdersResp?.data?.purchaseOrders || []);
        setModalPageCount(getAllPurchaseOrdersResp?.data?.total);
      }
    }
    if (deletePoResp && prevDeletePoResp !== deletePoResp) {
      toast.success("Successfully deleted PO");
      getAllPurchaseOrdersApiCall();
      toggleDeleteModal();
    }
    if (purchaseOrderErrorResp && prevPurchaseOrderErrorResp !== purchaseOrderErrorResp) {
      const { errors, error, message } = purchaseOrderErrorResp || {};
      toast.error((Array.isArray(errors) && errors[0] && errors[0].msg) || error || message);
    }
    inventoryResp.current.prevGetAllPurchaseOrdersResp = getAllPurchaseOrdersResp;
    inventoryResp.current.prevDeletePoResp = deletePoResp;
    inventoryResp.current.prevPurchaseOrderErrorResp = purchaseOrderErrorResp;
  }, [getAllPurchaseOrdersResp, deletePoResp, purchaseOrderErrorResp]);
  const getAllPurchaseOrdersApiCall = useCallback(
    (page, pageSize) => {
      const { search, startDate, endDate, searchBy } = filters;
      let params = {
        page: page ? page + 1 : 1,
        pageSize: pageSize || 30,
        orderBy:"id",
        sort:"desc"
      };
      if(search) params.supplierName = search;
      if (customActiveTab === "received") params.receivedStatus = true;
      else params.receivedStatus = false;
      dispatch(getAllPurchaseOrdersAct(generateApiUrl(`get_all_purchase_orders`), params));
    },
    [filters,customActiveTab,dispatch]
  );

  useEffect(() => {
    if (history.location?.state?.showCreatePurchaseOrderSuccess) {
      toast.success("Successfully saved the purchase order details");
      history.replace();
    } else if (history.location?.state?.showDeletePurchaseOrderSuccess) {
      toast.success("Successfully deleted purchase order");
      history.replace();
    }
  }, []);

  useEffect(() => {
    const debounceFn = setTimeout(() => {
      getAllPurchaseOrdersApiCall();
    }, 400);
    return () => {
      clearTimeout(debounceFn);
    };
  }, [getAllPurchaseOrdersApiCall]);
  const filtersChngHndlr = (e) => {
    const { name, value } = e.target;
    setFilters((prev) => ({ ...prev, [name]: value }));
  };
  const resetFilters = () => {
    setFilters({
      search: "",
      searchBy: "purchaseOrderNumber",
    });
  };
  const handlePageChange = (page, pageSize) => {
    getAllPurchaseOrdersApiCall(page, pageSize);
  };
  const handleDeletePurchaseOrder = () => {
    const payload = [];
    getAllPurchaseOrdersResp?.data?.purchaseOrders
      .filter((item, index) => rowSelection[index])
      .forEach((item) => payload.push(item.purchaseOrderNumber));
    dispatch(deletePoAct(generateApiUrl("delete_po"), { ids: payload }));
  };
  const noOfRowsSelected = Object.keys(rowSelection).length;
  return (
    <React.Fragment>
      <ToastContainer />
      <div className="sale-wh-wrapper whbrand-text-color" >
        <Container fluid>
          <Row className="px-4 mb-2 wh-middle">
            <Col xs={12}>
              <Row>
                <Col xl="3" md="4" xs="4" className="border-section p-3 py-0">
                  <Label htmlFor="userrole" className="form-label mb-1">
                    Search
                  </Label>
                  <div className="d-flex align-items-center">
                    <div className="form-icon flex-grow-1 me-2">
                      {["purchaseOrderNumber", "paymentTerm", "supplierNumber","supplierName"].indexOf(filters.searchBy) > -1 && (
                        <Input
                          name="search"
                          className="form-control form-control-icon me-2 py-1"
                          type="text"
                          value={filters.search}
                          onChange={filtersChngHndlr}
                        />
                      )}
                      {["shipDate"].indexOf(filters.searchBy) > -1 && (
                        <Input
                          name="search"
                          className="form-control me-2 py-1"
                          type="date"
                          value={filters.search}
                          onChange={filtersChngHndlr}
                        />
                      )}
                      {["poDate"].indexOf(filters.searchBy) > -1 && (
                        <div className="d-flex align-items-center flex-grow-1 py-1">
                          <Input
                            name="startDate"
                            className="form-control me-2 py-1"
                            type="date"
                            value={filters.startDate}
                            onChange={filtersChngHndlr}
                          />
                          <span className="me-2">to</span>
                          <Input
                            name="endDate"
                            className="form-control me-2 flex-grow-1 py-1"
                            type="date"
                            value={filters.endDate}
                            onChange={filtersChngHndlr}
                          />
                        </div>
                      )}
                      {filters.searchBy === "receivedStatus" && (
                        <Input
                          name="search"
                          className="form-control me-2 py-1"
                          type="select"
                          value={filters.search}
                          onChange={filtersChngHndlr}
                        >
                          <option value={false}>Not Received</option>
                          <option value={true}>Received</option>
                        </Input>
                      )}
                      {filters.searchBy === "paidStatus" && (
                        <Input
                          name="search"
                          className="form-control me-2 py-1"
                          type="select"
                          value={filters.search}
                          onChange={filtersChngHndlr}
                        >
                          <option value={false}>Unpaid</option>
                          <option value={true}>Paid</option>
                        </Input>
                      )}
                      {["purchaseOrderNumber", "paymentTerm", "supplierNumber","supplierName"].indexOf(filters.searchBy) > -1 && (
                        <i className="ri-search-line fs-5"></i>
                      )}
                    </div>
                    {/* <Button color="primary" onClick={toggleSearchModal}>
                      Smart Search
                    </Button> */}
                  </div>
                </Col>
                <Col xl="5" lg="6" xs="8">
                  <div className="d-flex justify-content-center border border-section py-2 px-1 h-100 pe-3">
                    {actions(history, toggleDeleteModal, resetFilters, toggleSearchModal).map((item) => {
                      return (
                        <div
                          key={item.id}
                          className={`action-icon-container d-flex align-items-center ${
                            (item.id === "delete" && (!permissions["DELETE_OPEN_PO"] || noOfRowsSelected === 0 || customActiveTab === "received"))
                              ? "opacity-50 show-cursor-not-allowed"
                              : "show-cursor-pointer"
                          }`}
                          onClick={ (item.id === "delete" && (!permissions["DELETE_OPEN_PO"] || noOfRowsSelected === 0 || customActiveTab === "received")) ? () => {} : item.clickHandler}
                        >
                          {item.id === "smart_search" ? item.icon : <img className="action-icon" src={item.icon} />}
                          <span className="text-center text-nowrap">{item.label}</span>
                        </div>
                      );
                    })}
                  </div>
                </Col>
              </Row>
              {history.location?.state?.redirected && (
                <div className="d-flex mt-3">
                  <div
                    className="d-flex align-items-center text-primary fs-5"
                    style={{ cursor: "pointer" }}
                    onClick={history.goBack}
                  >
                    <i className="ri-arrow-left-line me-1"></i>
                    <div className="mb-0 text-decoration-underline">Go Back</div>
                  </div>
                </div>
              )}
            </Col>
          </Row>
          <Row className="m-0 mt-3" >
          <Col xs={12} className="whbrand-navs">
              <Nav tabs className="nav nav-tabs nav-tabs-custom nav-success">
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({ active: customActiveTab === "received" })}
                    onClick={() => {
                      toggleCustom("received");
                    }}
                  >
                    Received
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({ active: customActiveTab === "open" })}
                    onClick={() => {
                      toggleCustom("open");
                    }}
                  >
                    Open
                  </NavLink>
                </NavItem>
              </Nav>
            </Col>
            <Col className="px-0" xs={12}>
              <WhTableContainer
                columns={columns}
                data={tableData || []}
                className="custom-header-css"
                divClass="table-responsive mt-2 whbrand-table table-container-po"
                tableClass="align-middle"
                theadClass="table-light"
                handlePageChange={handlePageChange}
                pageCount={pageCount || 0}
                customPageSize={30}
                showNoDataComponent={true}
                showRowSelection={true}
                setRowSelection={setRowSelection}
                loading={(getAllPurchaseOrdersLoading || !tableData) && !showSearchModal}
              />
            </Col>
          </Row>
        </Container>
      </div>
      <Modal id="showSearchModal" isOpen={showSearchModal} toggle={toggleSearchModal} centered size="lg">
        <ModalHeader className="py-2 px-3" toggle={toggleSearchModal}>
          Smart Search-Purchase Orders
        </ModalHeader>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <ModalBody className="px-0 pt-0">
            <SearchPurchaseOrders
              setFilters={setFilters}
              setShowSearchModal={setShowSearchModal}
              modalTableData={modalTableData}
              modalPageCount={modalPageCount || 0}
              getAllPurchaseOrdersAct={getAllPurchaseOrdersAct}
              generateApiUrl={generateApiUrl}
              dispatch={dispatch}
              getAllPurchaseOrdersLoading={getAllPurchaseOrdersLoading}
            />
          </ModalBody>
        </Form>
      </Modal>
      <DeleteModal show={showDeleteModal} onCloseClick={toggleDeleteModal} onDeleteClick={handleDeletePurchaseOrder} />
      <PaymentsAnywhere />
      <ApiLoader loading={purchaseOrderApiLoading} />
    </React.Fragment>
  );
}
export default withPermission(WarehousePurchaseOrders, "PO_MENU");
