import React from "react";
import { Spinner } from "reactstrap";

const TableLoader = ({ className }) => {
  return (
    <div className={`d-flex flex-column align-items-center justify-content-center ${className}`} style={{height:"300px"}}>
      <Spinner color="light" size="lg">Loading...</Spinner>
    </div>
  );
};

export default TableLoader;