import React, { useMemo, useEffect, useCallback, useRef, useState } from "react";
import WhTableContainer from "../../../../Common/WhTables";
import { generateColumns } from "./helper";
//Actions
import { getSalesOfGivenItemAct } from "../../../../../store/inventory/inventoryactions";

const PurchaseHistoryModalBody = ({ itemNumber, customerNumber, dispatch, generateApiUrl, getSalesOfGivenItemResp }) => {
  const [tableData, setTableData] = useState([]);
  const prevGetSalesOfGivenItemResp = useRef(getSalesOfGivenItemResp);
  useEffect(() => {
    if(getSalesOfGivenItemResp && getSalesOfGivenItemResp !== prevGetSalesOfGivenItemResp.current) {
        setTableData(getSalesOfGivenItemResp?.data?.invoiceLineItems || []);
    }
    prevGetSalesOfGivenItemResp.current = getSalesOfGivenItemResp;
  }, [getSalesOfGivenItemResp])
  const getSalesOfGivenItemApiCall = useCallback((page, pageSize) => {
    let params = {
      page: page ? page + 1 : 1,
      pageSize: pageSize || 30,
      customerNumber,
    };
    dispatch(getSalesOfGivenItemAct(generateApiUrl("get_sales_of_given_item", { itemNumber }), params));
  }, []);
  useEffect(() => {
    getSalesOfGivenItemApiCall();
  }, [getSalesOfGivenItemApiCall]);
  const handlePageChange = (page, pageSize) => {
    getSalesOfGivenItemApiCall(page, pageSize);
  };
  const columns = useMemo(() => generateColumns(), []);
  return (
    <div style={{ minHeight: "350px" }}>
      <WhTableContainer
        columns={columns}
        data={tableData}
        className="custom-header-css"
        divClass="table-responsive mt-3"
        tableClass="align-middle"
        theadClass="table-light"
        handlePageChange={handlePageChange}
        pageCount={Math.ceil((getSalesOfGivenItemResp?.data?.total || 0) / 10)}
        customPageSize={30}
        showNoDataComponent={true}
      />
    </div>
  );
};

export default PurchaseHistoryModalBody;
