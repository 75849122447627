import React, {useEffect, useRef, useState} from 'react';
import {Col, Container, Input, Label, Row, Button} from 'reactstrap';
import ExcelIcon from '../../assets/images/warehouse/salespage/Excel-Icon.png';
import PreviewIcon from '../../assets/images/warehouse/salespage/preview-icon.png';
import PrinterIcon from '../../assets/images/warehouse/salespage/printer_old.png';
import PdfPreviewModal from '../../Components/Common/PdfPreviewModal';
import {shallowEqual, useSelector} from 'react-redux';

export default function PrintItem({getSuppliersListApiCall,invoiceNumber}) {
  const [showModal, setShowModal] = useState(false);
  const isPrintClicked = useRef(false);


  const {getInvPricingReportPrintResp} = useSelector(
    ({reports}) => ({
      getInvPricingReportPrintResp: reports.getInvPricingReportPrintResp,
    }),
    shallowEqual
  );

  const prevGetInvPricingReportPrintResp = useRef(getInvPricingReportPrintResp);

  useEffect(() => {
    if(getInvPricingReportPrintResp && prevGetInvPricingReportPrintResp.current !== getInvPricingReportPrintResp && isPrintClicked.current) {
      printDocumentHandler(getInvPricingReportPrintResp?.html || '');
      isPrintClicked.current = false;
    }
    prevGetInvPricingReportPrintResp.current = getInvPricingReportPrintResp;
  }, [getInvPricingReportPrintResp]);

  const handlePreviewClick = () => {
    getSuppliersListApiCall(0, 10, true, 'html',invoiceNumber);
    setShowModal(true);
  };

  const handlePrintClick = () => {
    // Call the API when the "Print" button is clicked
    getSuppliersListApiCall(0, 10, true, 'html',invoiceNumber);
    isPrintClicked.current = true;
  };
  const handleExportClick = () => {
    // Call the API when the "Print" button is clicked
    getSuppliersListApiCall(0, 10, true,'pdf',invoiceNumber);
    // isPrintClicked.current = true;
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const printDocumentHandler = (printContents) => {
    try {
      const printWindow = window.open(
        '',
        '_blank',
        'margin=auto',
        'width=600,height=600'
      );
      printWindow.document.open();
      printWindow.document.write(`
      <html>
        <head>
          <title>Print</title>
        </head>
        <body>
          ${printContents}
        </body>
      </html>
    `);
      printWindow.document.close();
      const printPromise = new Promise((resolve) => {
        printWindow.onbeforeprint = () => {
        };
        printWindow.onafterprint = () => {
          resolve();
        };
      });

      // Print the document and close the window
      printWindow.onload = () => {
        printWindow.print();
      };

      return printPromise.then(() => printWindow.close());
    } catch (error) {
      console.error('Error handling print:', error);
    } 
  };

  return (
    <>
      <Container fluid>
        <Row>
          <Col xs={12} className="bg-primary text-center fs-5 text-white py-2">
            Add Product Category
          </Col>
        </Row>
        <Row className="p-3 border-bottom">
          <Col xs={8}>
            <Row className="mb-3">
              <Col xs={4} className="d-flex align-items-center">
                <Label htmlFor="street" className="form-label mb-0 text-nowrap">
                  Print Level :
                </Label>
              </Col>
              <Col xs={8}>
                <select className="form-select">
                  <option>Avery 5160 30/Page</option>
                </select>
              </Col>
            </Row>
          </Col>
          <Col xs={4}>
            <Button
              color="primary"
              className="w-100 mb-2 wh-btn"
              outline
              onClick={handlePreviewClick}
            >
              <span className="d-flex justify-content-center align-items-center">
                {/* <Spinner size="sm" type="grow" className="flex-shrink-0" role="status"> Loading... </Spinner> */}
                <img
                  src={PreviewIcon}
                  style={{height: '16px', width: '16px'}}
                />
                <span className="ms-2">Preview</span>
              </span>
            </Button>
            <Button
              color="primary"
              className="w-100 mb-2 wh-btn"
              outline
              onClick={handlePrintClick}
            >
              <span className="d-flex justify-content-center align-items-center">
                {/* <Spinner size="sm" type="grow" className="flex-shrink-0" role="status"> Loading... </Spinner> */}
                <img
                  src={PrinterIcon}
                  style={{height: '16px', width: '16px'}}
                />
                <span className="ms-2">Print</span>
              </span>
            </Button>
            <Button color="primary" className="w-100 mb-2 wh-btn" outline onClick={handleExportClick}>
              <span className="d-flex justify-content-center align-items-center">
                {/* <Spinner size="sm" type="grow" className="flex-shrink-0" role="status"> Loading... </Spinner> */}
                <img src={ExcelIcon} style={{height: '16px', width: '16px'}} />
                <span className="ms-2">Export</span>
              </span>
            </Button>
          </Col>
        </Row>
        <Row className="p-3">
          <Col xs={8}>
            <Row className="mb-3">
              <Col xs={4} className="d-flex align-items-center">
                <Label htmlFor="street" className="form-label mb-0 text-nowrap">
                  Select Price :
                </Label>
              </Col>
              <Col xs={8}>
                <Input
                  className="form-control"
                  type="text"
                  placeholder="Current"
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <PdfPreviewModal
        isOpen={showModal}
        handleClose={handleCloseModal}
        pdfSrc={getInvPricingReportPrintResp?.html || ''}
      />
    </>
  );
}
