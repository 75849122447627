import React, { useState, useEffect, useMemo, useCallback, useRef } from "react";
import { Col, Container, Input, Label, Row, Button } from "reactstrap";
import WhTableContainer from "../../../Components/Common/WhTables";
import { generateColumns } from "./searchInventorieshelper";
import { getStartDateFilterValue } from "../../../utility/dateutility";
import WhBundleTable from "./WhBundleTable";



export default function SearchInventories({
  setFilters,
  setShowSearchModal,
  setRowSelection,
  modalTableData =[],
  modalPageCount = 0,
  handleSelectInventoryItem,
  getInventoryListAct,
  generateApiUrl,
  dispatch,
  getInventoryListLoading,
  rowSelection,
  defaultUniqueIds
}) {
  const [search, setSearch] = useState("");
  const [searchBy, setSearchBy] = useState("description");
  const selectedRows = useRef({
    all:false,
    partiallySelected:[...defaultUniqueIds],
    defaultSelected:[...defaultUniqueIds],
    data:[],
    oldDataLength:0,
    newDataLength:0
  })
  const getAllInventoryApiCall = useCallback(
    (page, pageSize) => {
      let params = {
        page: page ? page + 1 : 1,
        pageSize: pageSize || 30,
      };
      if (search) {
        if (searchBy !== "createdDate" && searchBy !== "lastUsedDate") params[searchBy] = search;
        else params[searchBy] = getStartDateFilterValue(search);
      }
      if(selectedRows.current){
        selectedRows.current.all = false;
      }
      dispatch(getInventoryListAct(generateApiUrl(`get_inventory_list`), params));
    },
    [search, searchBy]
  );
  useEffect(() => {
    const debounceFn = setTimeout(() => {
      getAllInventoryApiCall();
    }, 400);
    return () => {
      clearTimeout(debounceFn);
    };
  }, [getAllInventoryApiCall]);
  const handlePageChange = (page, pageSize) => {
    getAllInventoryApiCall(page, pageSize);
  };
  // const searchHandler = () => {
  //   setFilters(prev => ({...prev, search, searchBy}));
  //   setShowSearchModal(false);
  // }
  const columns = useMemo(() => generateColumns(), []);
 const handleAddProductsHndlr = () => {
  handleSelectInventoryItem(selectedRows.current.partiallySelected,selectedRows.current.data);
 }

  return (
    <Container style={{borderRadius:"10px"}} fluid>
      {searchBy && (
        <Row className="mb-2 mt-4">
          <Col xs={12}>
            <Label htmlFor="userrole" className="form-label mb-1">
              Search By 
            </Label>
            <div className="d-flex align-items-center">
              {searchBy? (
                <Input
                  className="form-control me-2"
                  type="text"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
              ) : (
                <Input
                  className="form-control me-2"
                  type="date"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
              )}
              
            </div>
          </Col>
        </Row>
      )}

   
      <Row>
        <Col xs={12}>
          {/* <WhTableContainer
            columns={columns}
            data={modalTableData}
            className="custom-header-css"
            divClass="table-responsive mt-3"
            tableClass="align-middle"
            theadClass="table-light"
            handlePageChange={handlePageChange}
            pageCount={modalPageCount}
            customPageSize={30}
            showNoDataComponent={true}
            showRowSelection={true}
            rowSelection={rowSelection}
            setRowSelection={setRowSelection}
            loading={(getInventoryListLoading || !modalTableData)}
          /> */}
          <WhBundleTable 
            columnsList={columns}
            divClass={'table-container-bundle'}
            tableData={modalTableData}
            loading={(getInventoryListLoading || !modalTableData)}
            showNoDataComponent={true}
            pageCount={modalPageCount}
            customPageSize={30}
            handlePageChange={handlePageChange}
            selectedRows={selectedRows}
            
          />
        </Col>
        <Col xs={12}  className={'d-flex flex-row justify-content-end gap-2'}>
        <Button style={{backgroundColor: "#fff", color: "#000", fontWeight: "bold", padding: "0.5rem 1rem", borderRadius: "0.25rem", cursor: "pointer", outline: "none", boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)", border: "none", transition: "background-color 0.3s ease" }} className="hover:bg-green-700 focus:outline-none focus:shadow-outline" onClick={() => handleAddProductsHndlr()}>Cancel</Button>
          <Button
            onClick={() => handleAddProductsHndlr()}
          >
            Select
          </Button>
        </Col>
      </Row>

    </Container>
  );
}
