export default {
    GET_SUPPLIERS_LIST_REQUEST:"GET_SUPPLIERS_LIST_REQUEST",
    GET_SUPPLIERS_LIST_SUCCESS:"GET_SUPPLIERS_LIST_SUCCESS",
    GET_SUPPLIERS_LIST_FAIL:"GET_SUPPLIERS_LIST_FAIL",

    CHANGE_GET_SUPPLIERS_LIST_LOADING_STATUS:"CHANGE_GET_SUPPLIERS_LIST_LOADING_STATUS",

    CREATE_SUPPLIER_REQUEST:"CREATE_SUPPLIER_REQUEST",
    CREATE_SUPPLIER_SUCCESS:"CREATE_SUPPLIER_SUCCESS",
    CREATE_SUPPLIER_FAIL:"CREATE_SUPPLIER_FAIL",

    GET_SUPPLIER_REQUEST:"GET_SUPPLIER_REQUEST",
    GET_SUPPLIER_SUCCESS:"GET_SUPPLIER_SUCCESS",
    GET_SUPPLIER_FAIL:"GET_SUPPLIER_FAIL",

    UPDATE_SUPPLIER_DETAILS_REQUEST:"UPDATE_SUPPLIER_DETAILS_REQUEST",
    UPDATE_SUPPLIER_DETAILS_SUCCESS:"UPDATE_SUPPLIER_DETAILS_SUCCESS",
    UPDATE_SUPPLIER_DETAILS_FAIL:"UPDATE_SUPPLIER_DETAILS_FAIL",

    GET_SUPPLIER_INVOICES_REQUEST:"GET_SUPPLIER_INVOICES_REQUEST",
    GET_SUPPLIER_INVOICES_SUCCESS:"GET_SUPPLIER_INVOICES_SUCCESS",
    GET_SUPPLIER_INVOICES_FAIL:"GET_SUPPLIER_INVOICES_FAIL",

    GET_MASTER_DATA_FOR_SUPPLIER_REQUEST:"GET_MASTER_DATA_FOR_SUPPLIER_REQUEST",
    GET_MASTER_DATA_FOR_SUPPLIER_SUCCESS:"GET_MASTER_DATA_FOR_SUPPLIER_SUCCESS",
    GET_MASTER_DATA_FOR_SUPPLIER_FAIL:"GET_MASTER_DATA_FOR_SUPPLIER_FAIL",

    DELETE_SUPPLIER_REQUEST:"DELETE_SUPPLIER_REQUEST",
    DELETE_SUPPLIER_SUCCESS:"DELETE_SUPPLIER_SUCCESS",
    DELETE_SUPPLIER_FAIL:"DELETE_SUPPLIER_FAIL",

    GET_ITEMS_OF_SUPPLIER_REQUEST:"GET_ITEMS_OF_SUPPLIER_REQUEST",
    GET_ITEMS_OF_SUPPLIER_SUCCESS:"GET_ITEMS_OF_SUPPLIER_SUCCESS",
    GET_ITEMS_OF_SUPPLIER_FAIL:"GET_ITEMS_OF_SUPPLIER_FAIL",

    GET_MAX_SUPPLIER_NUM_REQUEST:"GET_MAX_SUPPLIER_NUM_REQUEST",
    GET_MAX_SUPPLIER_NUM_SUCCESS:"GET_MAX_SUPPLIER_NUM_SUCCESS",
    GET_MAX_SUPPLIER_NUM_FAIL:"GET_MAX_SUPPLIER_NUM_FAIL",

    CHANGE_SUPPLIER_API_LOADING_STATUS: "CHANGE_SUPPLIER_API_LOADING_STATUS"
}