import { Link } from "react-router-dom";
import ResetIcon from "../../assets/images/warehouse/salespage/reset.png";
import PrintIcon from "../../assets/images/warehouse/salespage/printer.png";
import ExportIcon from "../../assets/images/warehouse/salespage/excel.png";
import DeleteIcon from "../../assets/images/warehouse/salespage/delete.png";
import NewIcon from "../../assets/images/warehouse/salespage/new.png";

export const actions = (toggle, togglePrintModal) => [
    {
        id: "reset",
        label: "Generate",
        icon: ResetIcon
    }, {
        id: "print",
        label: "Print",
        icon: PrintIcon,
        clickHandler: togglePrintModal,
    }, {
        id: "export",
        label: "Export",
        icon: ExportIcon
    }
]

export function generateColumns() {
    return [
        {
            Header: "Invoice#",
            accessor: "invoice"
        },
        {
            Header: "Customer",
            accessor: "customer"
        }, {
            Header: "Name",
            accessor: "name"
        }, {
            Header: "Salesman",
            accessor: "salesman"
        }, {
            Header: "Count",
            accessor: "count"
        }, {
            Header: "Total Amount",
            accessor: "totalAmount"
        }, {
            Header: "Bal Due",
            accessor: "balDue"
        }, {
            Header: "Profit",
            accessor: "profit"
        }
    ]
}
export const tableData = [
    {
        invoice: "0520221-808",
        customer: "404",
        name: "Penny Saver 2 Inc",
        salesman: "Shail",
        count: "1.0",
        totalAmount: "200",
        balDue: "0",
        profit: "150"
    }, {
        invoice: "0520221-808",
        customer: "404",
        name: "Penny Saver 2 Inc",
        salesman: "Shail",
        count: "1.0",
        totalAmount: "200",
        balDue: "0",
        profit: "150"
    }, {
        invoice: "0520221-808",
        customer: "404",
        name: "Penny Saver 2 Inc",
        salesman: "Shail",
        count: "1.0",
        totalAmount: "200",
        balDue: "0",
        profit: "150"
    }, {
        invoice: "0520221-808",
        customer: "404",
        name: "Penny Saver 2 Inc",
        salesman: "Shail",
        count: "1.0",
        totalAmount: "200",
        balDue: "0",
        profit: "150"
    }, {
        invoice: "0520221-808",
        customer: "404",
        name: "Penny Saver 2 Inc",
        salesman: "Shail",
        count: "1.0",
        totalAmount: "200",
        balDue: "0",
        profit: "150"
    }, {
        invoice: "0520221-808",
        customer: "404",
        name: "Penny Saver 2 Inc",
        salesman: "Shail",
        count: "1.0",
        totalAmount: "200",
        balDue: "0",
        profit: "150"
    }, {
        invoice: "0520221-808",
        customer: "404",
        name: "Penny Saver 2 Inc",
        salesman: "Shail",
        count: "1.0",
        totalAmount: "200",
        balDue: "0",
        profit: "150"
    }, {
        invoice: "0520221-808",
        customer: "404",
        name: "Penny Saver 2 Inc",
        salesman: "Shail",
        count: "1.0",
        totalAmount: "200",
        balDue: "0",
        profit: "150"
    }, {
        invoice: "0520221-808",
        customer: "404",
        name: "Penny Saver 2 Inc",
        salesman: "Shail",
        count: "1.0",
        totalAmount: "200",
        balDue: "0",
        profit: "150"
    }, {
        invoice: "0520221-808",
        customer: "404",
        name: "Penny Saver 2 Inc",
        salesman: "Shail",
        count: "1.0",
        totalAmount: "200",
        balDue: "0",
        profit: "150"
    }, {
        invoice: "0520221-808",
        customer: "404",
        name: "Penny Saver 2 Inc",
        salesman: "Shail",
        count: "1.0",
        totalAmount: "200",
        balDue: "0",
        profit: "150"
    },
];