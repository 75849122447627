export const supplierUrl = {
    get_suppliers_list : `/suppliers/all`,
    create_supplier: `/suppliers`,
    get_supplier:({supplierNumber}) => `suppliers/${supplierNumber}`,
    update_supplier_details:({supplierNumber}) => `suppliers/${supplierNumber}`,
    get_supplier_invoices:`suppliers/invoices`,
    get_master_data_for_supplier:`suppliers/master-data`,
    delete_supplier :  `/suppliers/delete`,
    get_items_of_supplier : ({supplierNumber}) => `/suppliers/${supplierNumber}/itemList`,
    get_max_supplier_num : `/suppliers/maxNumber`
}