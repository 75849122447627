import React, { useMemo, useState, useRef, useEffect } from "react";
import { Col, Container, Row, ModalHeader, Modal, ModalBody, Form } from "reactstrap";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { toast } from "react-toastify";
import WhTableContainer from "../../../../Components/Common/WhTables";
import { generateColumns, actions } from "./helper";
import AddDesignation from "./AddDesignation";
import ApiLoader from "../../../../Components/Common/ApiLoader";
import Confirmation from "../../../../Components/Common/Confirmation";
//Api Helper
import { generateApiUrl } from "../../../../api/apihelper";
//Actions
import { getDesignationsAct, deleteDesignationsAct } from "../../../../store/designation/designationactions";

export const DesignationsTab = () => {
  const [tableData, setTableData] = useState([]);
  const [modal, setModal] = useState(false);
  const [editModalData, setEditModalData] = useState(null);
  const [rowSelection, setRowSelection] = useState({});
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const deleteDesignationConfig = useRef({
    deleteMultiple:false,
    idOfDesignationToBeDeleted:""
  });
  const toggleDeleteConfirmation = () => setShowDeleteConfirmation(prev => !prev);
  function toggle() {
    setModal(!modal);
  }
  const columns = useMemo(() => generateColumns(setEditModalData, toggle, toggleDeleteConfirmation, deleteDesignationConfig), []);
  function newBtnToggle() {
    setModal(true);
  }
  function togglePrintModal() {}
  const dispatch = useDispatch();
  const { designationtApiLoading, getDesignationsResp, createDesignationResp, updateDesignationResp, deleteDesignationsResp, designationErrorResp } =
    useSelector(
      ({ designation }) => ({
        designationtApiLoading: designation.designationtApiLoading,
        getDesignationsResp: designation.getDesignationsResp,
        createDesignationResp: designation.createDesignationResp,
        updateDesignationResp: designation.updateDesignationResp,
        deleteDesignationsResp: designation.deleteDesignationsResp,
        designationErrorResp: designation.designationErrorResp,
      }),
      shallowEqual
    );
  const prevApiResp = useRef({
    prevGetDesignationsResp: getDesignationsResp,
    prevCreateDesignationResp: createDesignationResp,
    prevUpdateDesignationResp: updateDesignationResp,
    prevDeleteDesignationsResp: deleteDesignationsResp,
    prevDesignationErrorResp: designationErrorResp,
  });
  useEffect(() => {
    const { prevGetDesignationsResp, prevCreateDesignationResp, prevUpdateDesignationResp, prevDeleteDesignationsResp, prevDesignationErrorResp } =
      prevApiResp.current || {};
    if (getDesignationsResp && prevGetDesignationsResp !== getDesignationsResp) {
      setTableData(getDesignationsResp?.data?.designations || []);
    } else if (createDesignationResp && prevCreateDesignationResp !== createDesignationResp) {
      toast.success("Successfully created a user role");
      toggle();
      dispatch(getDesignationsAct(generateApiUrl("get_designations")));
    } else if (updateDesignationResp && prevUpdateDesignationResp !== updateDesignationResp) {
      toast.success("Successfully updated a user role");
      toggle();
      dispatch(getDesignationsAct(generateApiUrl("get_designations")));
    }  else if (deleteDesignationsResp && prevDeleteDesignationsResp !== deleteDesignationsResp) {
        toast.success("Successfully deleted designation");
        toggleDeleteConfirmation();
        dispatch(getDesignationsAct(generateApiUrl("get_designations")));
    } else if (designationErrorResp && prevDesignationErrorResp !== designationErrorResp) {
      const { errors, error, message } = designationErrorResp || {};
      toast.error((Array.isArray(errors) && errors[0]) || error || message);
    }
    prevApiResp.current.prevGetDesignationsResp = getDesignationsResp;
    prevApiResp.current.prevCreateDesignationResp = createDesignationResp;
    prevApiResp.current.prevUpdateDesignationResp = updateDesignationResp;
    prevApiResp.current.prevDeleteDesignationsResp = deleteDesignationsResp;
    prevApiResp.current.prevDesignationErrorResp = designationErrorResp;
  }, [createDesignationResp, updateDesignationResp, getDesignationsResp, deleteDesignationsResp, designationErrorResp]);
  useEffect(() => {
    dispatch(getDesignationsAct(generateApiUrl("get_designations")));
  }, []);
  useEffect(() => {
    if(!modal && editModalData) setEditModalData(null);
  }, [modal])
  const handleDesignationDelete = () => {
    const formData = new FormData();
    if(deleteDesignationConfig.current.deleteMultiple) {
      tableData.filter((item,index) => rowSelection[index]).forEach((item,index) => formData.append(`ids[${index}]`,item.id));
    } else {
      formData.append("ids[0]",deleteDesignationConfig.current.idOfDesignationToBeDeleted)
    }
    dispatch(deleteDesignationsAct(generateApiUrl("delete_designations"),formData))
  }
  return (
    <React.Fragment>
      <Container fluid className="g-0">
        <Row className="g-0">
          <Col className="offset-sm-10 px-4" xs={2}>
            <div className="d-flex justify-content-between border border-section py-2 px-1 h-100">
              {actions(newBtnToggle, togglePrintModal, toggleDeleteConfirmation, deleteDesignationConfig).map((item) => {
                return (
                  <div key={item.id} className="action-icon-container" onClick={item.clickHandler || (() => {})}>
                    <img className="action-icon" src={item.icon} />
                    <span>{item.label}</span>
                  </div>
                );
              })}
            </div>
          </Col>
        </Row>
        <Row className="g-0">
          <Col xs={12}>
            <WhTableContainer
              columns={columns}
              data={tableData || []}
              customPageSize={100}
              className="custom-header-css"
              divClass="table-responsive mt-2 table-height-66vh"
              tableClass="table-bordered"
              theadClass="table-light"
              showRowSelection={true}
              setRowSelection={setRowSelection}
            />
          </Col>
        </Row>
      </Container>
      <Modal id="showModal" isOpen={modal} toggle={toggle} centered size="lg">
        <ModalHeader className="py-2 px-3" toggle={toggle}>
          {editModalData ? "Edit" : "Add"} Designation
        </ModalHeader>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <ModalBody className="px-0 pt-0">
            <AddDesignation dispatch={dispatch} generateApiUrl={generateApiUrl} editModalData={editModalData} />
          </ModalBody>
        </Form>
      </Modal>
      <ApiLoader loading={designationtApiLoading} />
      <Confirmation show={showDeleteConfirmation} onCloseClick={toggleDeleteConfirmation} title="Delete Designation" confirmationText="Are you sure you want to delete the records?" onConfirmClick={handleDesignationDelete}/>
    </React.Fragment>
  );
};
