//Constants
import { currencySymbol } from "../../../constants";

export function generateColumns(page) {
    return [
      {
        Header: "S.No",
        accessor: "id",
        Cell:({row}) => (((page - 1) * 10 ) + (row.index + 1))
    },
      {
        Header: "Supplier Number",
        accessor: "supplierNumber",
        Cell: ({ cell: { value } }) => (
          <div
            className="text-primary"
            style={{ cursor: "pointer" }}
            onClick={() => {
              window.open(`/warehousevendors/${value}`, "_blank");
              localStorage.setItem("activeTab",JSON.stringify({warehouseinventory:"suppliers"}));
            }}
          >
            {value}
          </div>
        ),
      },
      {
        Header: "Supplier Name",
        accessor: "supplier.supplierName",
      },
      {
        Header: "Supplier Item #",
        accessor: "supplierItemNumber",
      },
      {
        Header: "Case Qty",
        accessor: "caseQty",
      },
      {
        Header: "Cost",
        accessor: "cost",
        Cell : ({cell : {value}}) => (typeof value == "number") ? <>{currencySymbol}{value.toFixed(2)}</> : "",
        style: {textAlign:"right"}
      },
      {
        Header: "Discount",
        accessor: "vDisc",
      },
      {
        Header: "Single Cost",
        accessor: "singleCost",
        Cell : ({cell : {value}}) => (typeof value == "number") ? <>{currencySymbol}{value.toFixed(2)}</> : "",
        style: {textAlign:"right"}
      },
      {
        Header: "Net Cost",
        accessor: "netCost",
        Cell : ({cell : {value}}) => (typeof value == "number") ? <>{currencySymbol}{value.toFixed(2)}</> : "",
        style: {textAlign:"right"}
      },
    ];
}
export const tableData = [
    {
        sno: "1",
        invoice: "Fleming Candy Co, Inc",
        invoiceDate: "",
        printDateTime: "1",
        salesman: "$19.70",
        user: "",
        totalAmount: "$19.70",
        totalPaid: "$304.55",
    }, {
        sno: "2",
        invoice: "Fleming Candy Co, Inc",
        invoiceDate: "",
        printDateTime: "1",
        salesman: "$19.70",
        user: "",
        totalAmount: "$19.70",
        totalPaid: "$304.55",
    }, {
        sno: "3",
        invoice: "Fleming Candy Co, Inc",
        invoiceDate: "",
        printDateTime: "1",
        salesman: "$19.70",
        user: "",
        totalAmount: "$19.70",
        totalPaid: "$304.55",
    }, {
        sno: "4",
        invoice: "Fleming Candy Co, Inc",
        invoiceDate: "",
        printDateTime: "1",
        salesman: "$19.70",
        user: "",
        totalAmount: "$19.70",
        totalPaid: "$304.55",
    }, {
        sno: "5",
        invoice: "Fleming Candy Co, Inc",
        invoiceDate: "",
        printDateTime: "1",
        salesman: "$19.70",
        user: "",
        totalAmount: "$19.70",
        totalPaid: "$304.55",
    }, {
        sno: "6",
        invoice: "Fleming Candy Co, Inc",
        invoiceDate: "",
        printDateTime: "1",
        salesman: "$19.70",
        user: "",
        totalAmount: "$19.70",
        totalPaid: "$304.55",
    },
];