import SalesIcon from "../../assets/images/warehouse/header/sales.png";
import CustomersIcon from "../../assets/images/warehouse/header/customers.png";
import ExitIcon from "../../assets/images/warehouse/header/exit.png";
import InventoryIcon from "../../assets/images/warehouse/header/inventory.png";
import MainmenuIcon from "../../assets/images/warehouse/header/main_menu.png";
import OrderMenuIcon from "../../assets/images/warehouse/header/order_menu.png";
import PurchaseOrderIcon from "../../assets/images/warehouse/header/purchase_order.png";
import SuppliersIcon from "../../assets/images/warehouse/header/suppliers.png";

export const menuList = [
    {
        id: "sales",
        mainLabel: "Sales",
        subLabel: "Point of Sales",
        link: ["/warehousesales"],
        permission:"POS_MENU",
        icon: SalesIcon
    },
    {
        id: "customers",
        mainLabel: "Customers",
        subLabel: "Customers",
        link:["/warehousecustomers"],
        permission:"CUSTOMER_MENU",
        icon: CustomersIcon
    },
    {
        id: "inventory",
        mainLabel: "Inventory",
        subLabel: "Inventory",
        link: ["/warehouseinventory"],
        permission:"INVENTORY_MENU",
        icon: InventoryIcon
    },
    {
        id: "purchase_order",
        mainLabel: "Purchase Order",
        subLabel: "PO",
        link: ["/warehousepurchaseorders"],
        permission:"PO_MENU",
        icon: PurchaseOrderIcon
    },
    {
        id: "suppliers",
        mainLabel: "Suppliers",
        subLabel: "Vendors",
        link: ["/warehousevendors"],
        permission:"VENDORS_MENU",
        icon: SuppliersIcon
    }, 
    {
        id: "orders_history",
        mainLabel: "Order History",
        subLabel: "Order History",
        link: ["/warehouseorderhistory"],
        // permission:"VENDORS_MENU",
        icon: OrderMenuIcon
    }, 
    // {
    //     id: "order_menu",
    //     mainLabel: "Order Menu",
    //     subLabel: "Order Menu",
    //     permission:"ORDER_MENU",
    //     link: "#",
    //     icon: OrderMenuIcon
    // }, 
    {
        id: "main_menu",
        mainLabel: "Main Menu",
        subLabel: "Main Menu",
        link: ["/warehousemenu"],
        icon: MainmenuIcon
    }, {
        id: "exit",
        mainLabel: "Exit",
        subLabel: "Exit",
        // link: ["/warehouselogin"],
        icon: ExitIcon
    }
]