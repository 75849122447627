import React from 'react';
import {Modal, ModalHeader, ModalBody } from "reactstrap";
import PurchaseHistoryModalBody from "./purchasehistorymodalbody";
import Draggable from 'react-draggable';
import F12CloseModalListener from '../../../Common/F12close';

const PurcahseHistoryModal = ({isOpen, toggle, purchaseHistoryItem, customerDetails, dispatch, generateApiUrl, getSalesOfGivenItemResp}) => {
    return ( 
    <Draggable cancel=".cancledrag">
    <Modal
        id="showPurchaseHistoryModal"
        isOpen={isOpen}
        toggle={toggle}
        centered
        size="lg"
      >
          <F12CloseModalListener onClose={toggle} />
        <ModalHeader className="py-2 px-3" toggle={toggle} >
          Sales: {purchaseHistoryItem.description}
        </ModalHeader>
        <ModalBody className="px-0 pt-0 cancledrag">
          <PurchaseHistoryModalBody
            itemNumber={purchaseHistoryItem.itemNumber}
            customerNumber={customerDetails.customerNumber}
            dispatch={dispatch}
            generateApiUrl={generateApiUrl}
            getSalesOfGivenItemResp={getSalesOfGivenItemResp}
          />
        </ModalBody>
      </Modal> 
      </Draggable>);
}
 
export default PurcahseHistoryModal;