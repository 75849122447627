import React, {useEffect, useRef, useState} from 'react';
import {Col, Container, Input, Label, Row, Button} from 'reactstrap';
import ExcelIcon from '../../../../../assets/images/warehouse/salespage/Excel-Icon.png';
import PreviewIcon from '../../../../../assets/images/warehouse/salespage/preview-icon.png';
import PrinterIcon from '../../../../../assets/images/warehouse/salespage/printer_old.png';
import PdfPreviewModal from '../../../../../Components/Common/PdfPreviewModal';
import {shallowEqual, useSelector} from 'react-redux';

export default function PrintItem({getInvPricingReportApiCall}) {
  const [showModal, setShowModal] = useState(false);
  const [selectedPrices, setSelectedPrices] = useState([]);
  const isPrintClicked = useRef(false);

  const handleCheckboxChange = (price) => {
  if (selectedPrices.includes(price)) {
    setSelectedPrices(selectedPrices.filter((p) => p !== price));
  } else {
    setSelectedPrices([...selectedPrices, price]);
  }
};

  const {getInvPricingReportPrintResp} = useSelector(
    ({reports}) => ({
      getInvPricingReportPrintResp: reports.getInvPricingReportPrintResp,
    }),
    shallowEqual
  );

  const prevGetInvPricingReportPrintResp = useRef(getInvPricingReportPrintResp);

  useEffect(() => {
    if(getInvPricingReportPrintResp && prevGetInvPricingReportPrintResp.current !== getInvPricingReportPrintResp && isPrintClicked.current) {
      printDocumentHandler(getInvPricingReportPrintResp?.html || '');
      isPrintClicked.current = false;
    }
    prevGetInvPricingReportPrintResp.current = getInvPricingReportPrintResp;
  }, [getInvPricingReportPrintResp]);

  const handlePreviewClick = () => {
    getInvPricingReportApiCall(0, 10, true, 'html', selectedPrices);
    setShowModal(true);
  };

  const handlePrintClick = () => {
    // Call the API when the "Print" button is clicked
    getInvPricingReportApiCall(0, 10, true, 'html' ,selectedPrices);
    isPrintClicked.current = true;
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const printDocumentHandler = (printContents) => {
    try {
      const printWindow = window.open(
        '',
        '_blank',
        'margin=auto',
        'width=600,height=600'
      );
      printWindow.document.open();
      printWindow.document.write(`
      <html>
        <head>
          <title>Print</title>
        </head>
        <body>
          ${printContents}
        </body>
      </html>
    `);
      printWindow.document.close();
      const printPromise = new Promise((resolve) => {
        printWindow.onbeforeprint = () => {
        };
        printWindow.onafterprint = () => {
          resolve();
        };
      });

      // Print the document and close the window
      printWindow.onload = () => {
        printWindow.print();
      };

      return printPromise.then(() => printWindow.close());
    } catch (error) {
      console.error('Error handling print:', error);
    } 
  };

  return (
    <>
      <Container fluid>
        <Row>
          <Col xs={12} className="bg-primary text-center fs-5 text-white py-2">
            Add Product Category
          </Col>
        </Row>
        <Row className="p-3 border-bottom">
          <Col xs={8}>
            <Row className="mb-3">
              <Col xs={4} className="d-flex align-items-center">
                <Label htmlFor="street" className="form-label mb-0 text-nowrap">
                  Price Type :
                </Label>
              </Col>
              <Col xs={8}>
              <Row>
              <Col xs={6}  md={3} className='pe-0'>
                <div className="form-check">
                  <Input
                    className="form-check-input"
                    type="checkbox"
                    id="priceA"
                    value="priceA"
                    checked={selectedPrices.includes('priceA')}
                    onChange={() => handleCheckboxChange('priceA')}
                  />
                  <Label className="form-check-label" htmlFor="priceA">
                    Price A
                  </Label>
                </div>
              </Col>
              <Col xs={6} md={3} className='pe-0'>
                <div className="form-check mb-2">
                  <Input
                    className="form-check-input"
                    type="checkbox"
                    id="priceB"
                    value="priceB"
                    checked={selectedPrices.includes('priceB')}
                    onChange={() => handleCheckboxChange('priceB')}
                  />
                  <Label className="form-check-label" htmlFor="priceB">
                    Price B
                  </Label>
                </div>
              </Col>
              <Col xs={6} md={3} className='pe-0'>
                <div className="form-check mb-2">
                  <Input
                    className="form-check-input"
                    type="checkbox"
                    id="priceC"
                    value="priceC"
                    checked={selectedPrices.includes('priceC')}
                    onChange={() => handleCheckboxChange('priceC')}
                  />
                  <Label className="form-check-label" htmlFor="priceC">
                    Price C
                  </Label>
                </div>
              </Col>
              <Col xs={6} md={3} className='pe-0'>
                <div className="form-check mb-2">
                  <Input
                    className="form-check-input"
                    type="checkbox"
                    id="priceD"
                    value="priceD"
                    checked={selectedPrices.includes('priceD')}
                    onChange={() => handleCheckboxChange('priceD')}
                  />
                  <Label className="form-check-label" htmlFor="priceD">
                    Price D
                  </Label>
                </div>
              </Col>
              <Col xs={12} className="mt-2">
                <div className="form-check">
                  <Input
                    className="form-check-input"
                    type="checkbox"
                    id="allPrices"
                    value="All Prices"
                    // checked={selectedPrices.length === 4}
                    checked={selectedPrices.includes('priceA') && selectedPrices.includes('priceB') && selectedPrices.includes('priceC') && selectedPrices.includes('priceD')}
                    onChange={() => {
                      if (selectedPrices.includes('priceA') && selectedPrices.includes('priceB') && selectedPrices.includes('priceC') && selectedPrices.includes('priceD')) {
                        // If all are already selected, remove only ['priceA', 'priceB', 'priceC', 'priceD']
                        setSelectedPrices(selectedPrices.filter(price => !['priceA', 'priceB', 'priceC', 'priceD'].includes(price)));
                      } else {
                        // If not all are selected, add them
                        setSelectedPrices([...selectedPrices, 'priceA', 'priceB', 'priceC', 'priceD']);
                      }
                    }}
                  />
                  <Label className="form-check-label" htmlFor="allPrices">
                    All Prices
                  </Label>
                </div>
              </Col>
            </Row>
        </Col>
            </Row>
            <Row className="mb-3">
              <Col xs={4} className="d-flex align-items-center">
                <Label htmlFor="street" className="form-label mb-0 text-nowrap">
                  Includes :
                </Label>
              </Col>
              <Col xs={8}>
                <div className="d-flex justify-content-between">
                  <div className="form-check me-1">
                    <Input
                      className="form-check-input"
                      type="checkbox"
                      id="cost"
                      defaultChecked
                      checked={selectedPrices.includes('cost')}
                      onChange={() => handleCheckboxChange('cost')}
                    />
                    <Label
                      className="form-check-label text-nowrap"
                      htmlFor="cost"
                    >
                      Cost
                    </Label>
                  </div>
                  <div className="form-check me-1">
                    <Input
                      className="form-check-input"
                      type="checkbox"
                      id="retail"
                      checked={selectedPrices.includes('retail')}
                  onChange={() => handleCheckboxChange('retail')}
                    />
                    <Label
                      className="form-check-label text-nowrap"
                      htmlFor="retail"
                    >
                      Retail
                    </Label>
                  </div>
                  <div className="form-check me-1">
                    <Input
                      className="form-check-input"
                      type="checkbox"
                      defaultChecked
                      id="quantityInStock"
                      checked={selectedPrices.includes('quantityInStock')}
                  onChange={() => handleCheckboxChange('quantityInStock')}
                    />
                    <Label
                      className="form-check-label text-nowrap"
                      htmlFor="quantityInStock"
                    >
                      Quantity
                    </Label>
                  </div>
                  <div className="form-check me-1">
                    <Input
                      className="form-check-input"
                      type="checkbox"
                      id="total"
                      defaultChecked
                    />
                    <Label
                      className="form-check-label text-nowrap"
                      htmlFor="total"
                    >
                      View Total
                    </Label>
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col xs={4} className="d-flex align-items-center">
                <Label htmlFor="street" className="form-label mb-0 text-nowrap">
                  Print Level :
                </Label>
              </Col>
              <Col xs={8}>
                <select className="form-select">
                  <option>Avery 5160 30/Page</option>
                </select>
              </Col>
            </Row>
          </Col>
          <Col xs={4}>
            <Button
              color="primary"
              className="w-100 mb-2 wh-btn"
              outline
              onClick={handlePreviewClick}
            >
              <span className="d-flex justify-content-center align-items-center">
                {/* <Spinner size="sm" type="grow" className="flex-shrink-0" role="status"> Loading... </Spinner> */}
                <img
                  src={PreviewIcon}
                  style={{height: '16px', width: '16px'}}
                />
                <span className="ms-2">Preview</span>
              </span>
            </Button>
            <Button
              color="primary"
              className="w-100 mb-2 wh-btn"
              outline
              onClick={handlePrintClick}
              // onClick={() =>{
              //   // getInvPricingReportApiCall(0, 10, true, 'html')
              //   if(getInvPricingReportPrintResp){
              //     printDocumentHandler(getInvPricingReportPrintResp || '');
              //   }
              // }}
            >
              <span className="d-flex justify-content-center align-items-center">
                {/* <Spinner size="sm" type="grow" className="flex-shrink-0" role="status"> Loading... </Spinner> */}
                <img
                  src={PrinterIcon}
                  style={{height: '16px', width: '16px'}}
                />
                <span className="ms-2">Print</span>
              </span>
            </Button>
            <Button color="primary" className="w-100 mb-2 wh-btn" outline>
              <span className="d-flex justify-content-center align-items-center">
                {/* <Spinner size="sm" type="grow" className="flex-shrink-0" role="status"> Loading... </Spinner> */}
                <img src={ExcelIcon} style={{height: '16px', width: '16px'}} />
                <span className="ms-2">Export</span>
              </span>
            </Button>
          </Col>
        </Row>
        <Row className="p-3">
          <Col xs={8}>
            <Row className="mb-3">
              <Col xs={4} className="d-flex align-items-center">
                <Label htmlFor="street" className="form-label mb-0 text-nowrap">
                  Select Price :
                </Label>
              </Col>
              <Col xs={8}>
                <Input
                  className="form-control"
                  type="text"
                  placeholder="Current"
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <PdfPreviewModal
        isOpen={showModal}
        handleClose={handleCloseModal}
        pdfSrc={getInvPricingReportPrintResp?.html || ''}
      />
    </>
  );
}
