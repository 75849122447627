import React, { useState, useCallback, useRef, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Input, Row, Col, Label, Container, Button, FormFeedback } from "reactstrap";
import SelectItem from "../../Components/Common/SelectItem";
//Actions
import { createMultiQtyAdjustmentAct } from "../../store/inventory/inventoryactions";

const customerColumnsList = [
  {
    id: "itemNumber",
    name: "Item No",
    cellClassName: "fw-medium",
  },
  {
    id: "description",
    name: "Description",
    cellClassName: "fbadge badge-soft-primary",
  },
  {
    id: "cost",
    name: "Cost",
    cellClassName: "fw-medium",
  },
];

const MultiQtyAdjustment = ({
  toggleMultiQtyAdjustmentModal,
  dispatch,
  generateApiUrl,
  getInventoryListAct,
  getInventoryListResp,
}) => {
  const itemsValidation = useFormik({
    initialValues: [{ itemNumber: "", quantityAdj: "", itemNumberSearch: "", itemNumberListOptions: [] }],
    validationSchema: Yup.array().transform(field => field.length > 1 ? field.slice(0,field.length-1) : field).of(
      Yup.object().shape({
        itemNumber: Yup.string().required("Please select item"),
        quantityAdj: Yup.string().required("Please enter quantity"),
      })
    ),
    onSubmit: () => {
      handleMultiUpdateQty();
    },
  });
  const qtyInputsRefs = useRef([]);
  const addNewRow = () => {
    const updatedItems = [
      ...itemsValidation.values,
      { itemNumber: "", quantityAdj: "", itemNumberSearch: "", itemNumberListOptions: [] },
    ];
    itemsValidation.setValues(updatedItems);
  };
  const removeRow = (index) => {
    const updatedItems = [...itemsValidation.values];
    updatedItems.splice(index, 1);
    itemsValidation.setValues(updatedItems);
  };
  const debounceFn = useRef(null);
  const currentSelectItemKey = useRef("");
  const prevGetInventoryListResp = useRef(getInventoryListResp);
  useEffect(() => {
    if (getInventoryListResp && getInventoryListResp !== prevGetInventoryListResp.current) {
      itemsValidation.setValues((prev) => {
        const updatedItems = [...prev],
          index = currentSelectItemKey.current,
          rowData = { ...updatedItems[index] },
          listOptions = [];
        (getInventoryListResp?.data?.inventories || []).forEach((item) => {
          const { itemNumber, description, cost, quantityInStock } = item;
          listOptions.push({
            itemNumber,
            description,
            cost: `$${cost}`,
            quantityInStock, 
          });
        });

        updatedItems.splice(index, 1, {
          ...rowData,
          itemNumberListOptions: listOptions,
        });
        return updatedItems;
      });
    }
    prevGetInventoryListResp.current = getInventoryListResp;
  }, [getInventoryListResp]);
  const getInventoryListApiCall = useCallback((value) => {
    let params = {
      page: 1,
      pageSize: 5,
      [`${isNaN(Number(value)) ? "description" : "itemNumber"}`]: value,
    };
    dispatch(getInventoryListAct(generateApiUrl(`get_inventory_list`), params));
  }, []);
  function selectItemHndlr(itemDetails, index) {
    const { itemNumber, description, quantityInStock } = itemDetails;
    itemsValidation.setValues((prev) => {
      const updatedItems = [...prev],
        rowData = { ...updatedItems[index] };
      updatedItems.splice(index, 1, {
        ...rowData,
        itemNumber,
        itemNumberSearch: itemNumber,
        itemDescription: description,
        itemQuantityInStock: quantityInStock,
      });
      return updatedItems;
    });
    if (itemNumber) {
        const element = document.querySelectorAll(".multi-qty-input")[index];
        if(element) element.focus();
    }
  }
  const handleInputBlur = (index) => {
    const itemDetails = itemsValidation.values[index].itemNumberListOptions[0];
    if(itemsValidation.values[index].itemNumberListOptions.length === 1 && itemDetails.itemNumber === itemsValidation.values[index].itemNumberSearch) {
      selectItemHndlr(itemDetails, index);
    }
    else {
      itemsValidation.setValues((prev) => {
        const updatedItems = [...prev],
          rowData = { ...updatedItems[index] };
        updatedItems.splice(index, 1, {
          ...rowData,
          itemNumberSearch: rowData.itemNumber,
          itemNumberListOptions:[]
        });
        return updatedItems;
      });
    }
  };
  const searchValueChngHndlr = (value, index) => {
    itemsValidation.setValues((prev) => {
      const updatedItems = [...prev],
        rowData = { ...updatedItems[index] };
      updatedItems.splice(index, 1, {
        ...rowData,
        itemNumberSearch: value,
      });
      clearTimeout(debounceFn.current);
      debounceFn.current = setTimeout(() => {
        getInventoryListApiCall(value);
      }, 400);
      currentSelectItemKey.current = index;
      return updatedItems;
    });
  };
  const qtyChngHndlr = (e, index) => {
    const { value } = e.target;
    const updatedItems = [...itemsValidation.values],
      rowData = { ...updatedItems[index] };
    updatedItems.splice(index, 1, {
      ...rowData,
      quantityAdj: value,
    });
    itemsValidation.setValues(updatedItems);
  };
  const handleMultiUpdateQty = () => {
    const payload = [];
    itemsValidation.values.forEach((item) => {
      const { itemNumber, quantityAdj } = item;
      if (itemNumber && quantityAdj) payload.push({ itemNumber, quantityAdj });
    });
    if (payload.length > 0)
      dispatch(createMultiQtyAdjustmentAct(generateApiUrl("create_multi_qty_adjustment"), { items: payload }));
  };
  const qtyKeyDownHandler = (e, index) => {
    if(["e", "E", "+", "-", "."].includes(e.key)) e.preventDefault();
    else if(e.key === "Enter" && e.target.value) {
      if(itemsValidation.values.length-1 === index) itemsValidation.setValues(prev => [...prev, { itemNumber: "", quantityAdj: "", itemNumberSearch: "", itemNumberListOptions: [] } ]);
      setTimeout(() => {
        const element = document.querySelectorAll(".multi-item-input")[index+1];
        if(element) element.focus();
      },200)
    }
  }
  useEffect(() => {
    const elements = document.getElementsByClassName("invoice-table");
    elements[0].scrollTop=elements[0].scrollHeight;
  },[itemsValidation.values.length])  
  
  return (
    <Container >
      <Row>
        {/* <Col xs="12" className="invoice-table" style={{minHeight:"100px",scrollBehavior:"smooth", maxHeight:"500px", overflowY:"auto"}}> */}
        <Row className="my-2 gap-2">
          <Col xs="3">
            <Label>Scan Item No.</Label>
          </Col>
          <Col xs="4">
            <Label>Description</Label>
          </Col>
          <Col xs="2">
            <Label>Old Qty.</Label>
          </Col>
          <Col xs="2">
            <Label>New Qty.</Label>
          </Col>
        </Row>
        <Col xs="12" className="invoice-table" style={{minHeight:"300px", maxHeight: "425px", overflowY: "auto", scrollBehavior: "auto" }}>
        {itemsValidation.values.map((item, index) => (
        <Row key={index} className="mb-3">
          <Col xs="3">
            <SelectItem
              searchValue={item.itemNumberSearch}
              searchValueChngHndlr={(value) => searchValueChngHndlr(value, index)}
              listOptions={item.itemNumberListOptions || []}
              columnsList={customerColumnsList}
              placeholder="Search Item"
              selectedItemHandler={(itemDetails) => selectItemHndlr(itemDetails, index)}
              fieldKey="itemNumber"
              dataIdKey="itemNumber"
              hideListOnBlur={true}
              inputClassname={`multi-item-input ${
                itemsValidation.touched?.[index]?.itemNumber && itemsValidation.errors?.[index]?.itemNumber
                  ? "custom-invalid-field"
                  : ""
              }`}
              onInputBlur={() => handleInputBlur(index)}
              showDropdownBtn={true}
              hideDropdownBtnFromUi={true}
              ref={(inputRef) => (qtyInputsRefs.current[index] = inputRef)}
            />
            {itemsValidation.touched?.[index]?.itemNumber && itemsValidation.errors?.[index]?.itemNumber && (
              <div className="custom-invalid-feedback">
                {itemsValidation.touched?.[index]?.itemNumber && itemsValidation.errors?.[index]?.itemNumber}
              </div>
            )}
          </Col>
          <Col xs="4">
            <Input
              type="text"
              style={{height:"1.4rem", fontSize:"12px"}}
              onWheel={(e) => e.target.blur()}
              value={item.itemDescription || ''}
              readOnly
            />
          </Col>
          <Col xs="2">
              <Input
                type="text"
                style={{ height: "1.4rem", fontSize: "12px"}}
                onWheel={(e) => e.target.blur()}
                value={item.itemQuantityInStock || '0'}
                readOnly
              />
            </Col>
          <Col xs="2">
            <Input
              style={{height:"1.4rem"}}
              className="multi-qty-input"
              type="number"
              onWheel={(e) => e.target.blur()}
              value={item.quantityAdj}
              onChange={(e) => qtyChngHndlr(e, index)}
              onKeyDown={(e) => qtyKeyDownHandler(e,index)}
              invalid={itemsValidation.touched?.[index]?.quantityAdj && itemsValidation.errors?.[index]?.quantityAdj}
            />
            <FormFeedback>{itemsValidation.touched?.[index]?.quantityAdj && itemsValidation.errors?.[index]?.quantityAdj}</FormFeedback>
          </Col>
          {itemsValidation.values.length > 1 && (
            <Col xs="1 d-flex align-items-center">
              <i
                className="ri-delete-bin-line fs-5 text-danger show-cursor-pointer"
                onClick={() => removeRow(index)}
              ></i>
            </Col>
          )}
        </Row>
      ))}
        </Col>
        <Col xs="12">
              <Row className="mb-3 mt-2">
              <Col xs="12">
                <Button outline color="primary" className="wh-btn" onClick={addNewRow}>
                  + Add New
                </Button>
              </Col>
            </Row>
            <Row>
              <Col xs="12" className="d-flex justify-content-center">
                <Button color="primary" className="me-2" onClick={itemsValidation.handleSubmit}>
                  Save
                </Button>
                <Button color="light" className="bg-gradient border" onClick={toggleMultiQtyAdjustmentModal}>
                  Close
                </Button>
              </Col>
            </Row>
              </Col>
      </Row>
      
   
      
    </Container>
  );
};

export default MultiQtyAdjustment;
