import React from "react";
import { withRouter } from "react-router-dom";

import HeaderWH from "./HeaderWH";

const WarehouseLayout = function (props) {
    return (
        <React.Fragment>
            <div id="warehouse-layout-wrapper" className="whbrand-bg">
                <HeaderWH />
                <div className="layout-content whbrand-bg">
                    {props.children}
                    {/* <FooterWH /> */}
                </div>
            </div>
        </React.Fragment>
    )
}
export default withRouter(WarehouseLayout);