export default {
    CREATE_INVOICE_REQUEST:"CREATE_INVOICE_REQUEST",
    CREATE_INVOICE_SUCCESS:"CREATE_INVOICE_SUCCESS",
    CREATE_INVOICE_FAIL:"CREATE_INVOICE_FAIL",

    UPDATE_INVOICE_REQUEST:"UPDATE_INVOICE_REQUEST",
    UPDATE_INVOICE_SUCCESS:"UPDATE_INVOICE_SUCCESS",
    UPDATE_INVOICE_FAIL:"UPDATE_INVOICE_FAIL",

    DELETE_INVOICE_REQUEST:"DELETE_INVOICE_REQUEST",
    DELETE_INVOICE_SUCCESS:"DELETE_INVOICE_SUCCESS",
    DELETE_INVOICE_FAIL:"DELETE_INVOICE_FAIL",

    GET_MAX_INVOICE_NO_REQUEST:"GET_MAX_INVOICE_NO_REQUEST",
    GET_MAX_INVOICE_NO_SUCCESS:"GET_MAX_INVOICE_NO_SUCCESS",
    GET_MAX_INVOICE_NO_FAIL:"GET_MAX_INVOICE_NO_FAIL",

    GET_INVOICE_DETAILS_REQUEST:"GET_INVOICE_DETAILS_REQUEST",
    GET_INVOICE_DETAILS_SUCCESS:"GET_INVOICE_DETAILS_SUCCESS",
    GET_INVOICE_DETAILS_FAIL:"GET_INVOICE_DETAILS_FAIL",

    CHANGE_POSTED_STATUS_OF_INVOICE_REQUEST:"CHANGE_POSTED_STATUS_OF_INVOICE_REQUEST",
    CHANGE_POSTED_STATUS_OF_INVOICE_SUCCESS:"CHANGE_POSTED_STATUS_OF_INVOICE_SUCCESS",
    CHANGE_POSTED_STATUS_OF_INVOICE_FAIL:"CHANGE_POSTED_STATUS_OF_INVOICE_FAIL",

    DOWNLOAD_INVOICE_PDF_REQUEST:"DOWNLOAD_INVOICE_PDF_REQUEST",
    DOWNLOAD_INVOICE_PDF_SUCCESS:"DOWNLOAD_INVOICE_PDF_SUCCESS",
    DOWNLOAD_INVOICE_PDF_FAIL:"DOWNLOAD_INVOICE_PDF_FAIL",

    CHANGE_SALES_API_LOADING_STATUS:"CHANGE_SALES_API_LOADING_STATUS"
}