export default {
  GET_ALL_PURCHASE_ORDERS_REQUEST: "GET_ALL_PURCHASE_ORDERS_REQUEST",
  GET_ALL_PURCHASE_ORDERS_SUCCESS: "GET_ALL_PURCHASE_ORDERS_SUCCESS",
  GET_ALL_PURCHASE_ORDERS_FAIL: "GET_ALL_PURCHASE_ORDERS_FAIL",

  CHANGE_GET_ALL_PURCHASE_ORDERS_LOADING_STATUS: "CHANGE_GET_ALL_PURCHASE_ORDERS_LOADING_STATUS",

  CREATE_PURCHASE_ORDER_REQUEST: "CREATE_PURCHASE_ORDER_REQUEST",
  CREATE_PURCHASE_ORDER_SUCCESS: "CREATE_PURCHASE_ORDER_SUCCESS",
  CREATE_PURCHASE_ORDER_FAIL: "CREATE_PURCHASE_ORDER_FAIL",

  GET_PURCHASE_ORDER_DETAILS_REQUEST: "GET_PURCHASE_ORDER_DETAILS_REQUEST",
  GET_PURCHASE_ORDER_DETAILS_SUCCESS: "GET_PURCHASE_ORDER_DETAILS_SUCCESS",
  GET_PURCHASE_ORDER_DETAILS_FAIL: "GET_PURCHASE_ORDER_DETAILS_FAIL",

  UPDATE_PURCHASE_ORDER_DETAILS_REQUEST: "UPDATE_PURCHASE_ORDER_DETAILS_REQUEST",
  UPDATE_PURCHASE_ORDER_DETAILS_SUCCESS: "UPDATE_PURCHASE_ORDER_DETAILS_SUCCESS",
  UPDATE_PURCHASE_ORDER_DETAILS_FAIL: "UPDATE_PURCHASE_ORDER_DETAILS_FAIL",

  GET_MAX_PO_NO_REQUEST: "GET_MAX_PO_NO_REQUEST",
  GET_MAX_PO_NO_SUCCESS: "GET_MAX_PO_NO_SUCCESS",
  GET_MAX_PO_NO_FAIL: "GET_MAX_PO_NO_FAIL",

  RECEIVE_PO_REQUEST: "RECEIVE_PO_REQUEST",
  RECEIVE_PO_SUCCESS: "RECEIVE_PO_SUCCESS",
  RECEIVE_PO_FAIL: "RECEIVE_PO_FAIL",

  DELETE_PO_REQUEST: "DELETE_PO_REQUEST",
  DELETE_PO_SUCCESS: "DELETE_PO_SUCCESS",
  DELETE_PO_FAIL: "DELETE_PO_FAIL",

  OPEN_PO_REQUEST: "OPEN_PO_REQUEST",
  OPEN_PO_SUCCESS: "OPEN_PO_SUCCESS",
  OPEN_PO_FAIL: "OPEN_PO_FAIL",

  DOWNLOAD_PO_PDF_REQUEST: "DOWNLOAD_PO_PDF_REQUEST",
  DOWNLOAD_PO_PDF_SUCCESS: "DOWNLOAD_PO_PDF_SUCCESS",
  DOWNLOAD_PO_PDF_FAIL: "DOWNLOAD_PO_PDF_FAIL",

  CHANGE_PO_API_LOADING_STATUS: "CHANGE_PO_API_LOADING_STATUS",
};
