import React, { useMemo, useState, useRef, useEffect, useCallback } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { Container, Row, Col, Label, Input, Modal, ModalHeader, ModalBody, Form } from "reactstrap";
import { actions, generateColumns } from "./helper";
import BackIcon from "../../../../../assets/images/warehouse/menu/back.png";
import WhTableContainer from "../../../../../Components/Common/WhTables";
import ApiLoader from "../../../../../Components/Common/ApiLoader";
import PrintItem from "./PrintItem";
//Api Helper
import { generateApiUrl } from "../../../../../api/apihelper";
//Actions
import { getInventoryAllMasterDataAct } from "../../../../../store/inventory/inventoryactions";
import { getCustomersReportAct, getInvPricingReportPrintAct } from "../../../../../store/reports/reportsactions";
import { getStatesListAct } from "../../../../../store/masterdata/masterdataactions";
import F12CloseModalListener from "../../../../../Components/Common/F12close";

export const ListBy = ({ history, toast }) => {
  const [showPrintModal, setShowPrintModal] = useState(false);
  const [filters, setFilters] = useState({
    search: "",
    status: "",
    state: "",
  });
  const [tableData, setTableData] = useState([]);
  const currentPageSize = useRef(30);
  const isDownloadBtnClicked = useRef(false);
  const [pageCount, setPageCount] = useState(0);

  function togglePrintModal() {
    setShowPrintModal(!showPrintModal);
  }

  const dispatch = useDispatch();

  const { getCustomersReportResp, getCustomersReportLoading,getInvPricingReportLoading, getStatesListResp, reportsErrorResp } = useSelector(
    ({ reports, masterData }) => ({
      getCustomersReportResp: reports.getCustomersReportResp,
      getCustomersReportLoading: reports.getCustomersReportLoading,
      getInvPricingReportLoading: reports.getInvPricingReportLoading,
      getStatesListResp: masterData.getStatesListResp,
      reportsErrorResp: reports.reportsErrorResp,
    }),
    shallowEqual
  );
  const inventoryResp = useRef({
    prevGetCustomersReportResp: getCustomersReportResp,
    prevReportsErrorResp: reportsErrorResp,
  });

  useEffect(() => {
    const { prevGetCustomersReportResp, prevReportsErrorResp } = inventoryResp.current || {};
    if (getCustomersReportResp && prevGetCustomersReportResp !== getCustomersReportResp) {
      if (isDownloadBtnClicked.current) {
        const file = new File([getCustomersReportResp], "customers_report.pdf", {
          type: "application/pdf",
        });
        const url = window.URL.createObjectURL(file);
        const a = document.createElement("a");
        a.href = url;
        a.setAttribute("download", "customers_report_list.pdf");
        document.body.appendChild(a);
        a.click();
        a.remove();
        isDownloadBtnClicked.current = false;
      } else {
        setTableData(getCustomersReportResp?.data?.report || []);
        currentPageSize.current = getCustomersReportResp?.data?.pageSize;
        setPageCount((getCustomersReportResp?.data?.total))
      }
    } else if (reportsErrorResp && prevReportsErrorResp !== reportsErrorResp) {
      const { errors, error, message } = reportsErrorResp || {};
      toast.error((Array.isArray(errors) && errors[0] && errors[0].msg) || error || message);
    }
    inventoryResp.current.prevGetCustomersReportResp = getCustomersReportResp;
    inventoryResp.current.prevReportsErrorResp = reportsErrorResp;
  }, [getCustomersReportResp, reportsErrorResp]);

  useEffect(() => {
    dispatch(getInventoryAllMasterDataAct(generateApiUrl(`get_inventory_all_master_data`)));
    dispatch(getStatesListAct(generateApiUrl(`get_states_list`)));
  }, []);

  const getCustomersReportApiCall = useCallback(
    (page, pageSize, download, downloadType='pdf') => {
      const { status, search, state } = filters || {};
      let params = {};
      if (!download) {
        params.page = page ? page + 1 : 1;
        params.pageSize = pageSize || currentPageSize.current;
      }
      if (status) params.status = status;
      if (search) params.search = search;
      if (state) params.state = state;
      if (download && downloadType === 'html') {
        params.download = true;
        params.downloadType = downloadType;
        dispatch(
          getInvPricingReportPrintAct(
            generateApiUrl(`get_customers_report`),
            params
          )
        );
      } 
      else if (download){
        params.download = true;
        params.downloadType = downloadType;
        isDownloadBtnClicked.current = true; 
        dispatch(
          getCustomersReportAct(
            generateApiUrl(`get_customers_report`),
            params,
            {responseType: 'blob'}
          )
        );
       }
        else {
          dispatch(
            getCustomersReportAct(
              generateApiUrl(`get_customers_report`),
              params
            )
          );
        }
    },
    [filters]
  );

  useEffect(() => {
    const debounceFn = setTimeout(() => {
      getCustomersReportApiCall();
    }, 400);
    return () => {
      clearTimeout(debounceFn);
    };
  }, [getCustomersReportApiCall]);

  const handlePageChange = (page, pageSize) => {
    getCustomersReportApiCall(page, pageSize);
  };

  const statusChngHndlr = (name) => {
    setFilters((prev) => ({ ...prev, status: name === "all" ? "" : name }));
  };

  const filtersChngHndlr = (e) => {
    const { name, value } = e.target;
    setFilters((prev) => ({ ...prev, [name]: value }));
  };

  const resetFilters = () => {
    setFilters({
      search: "",
      status: "",
      state: "",
    })
  }

  const columns = useMemo(() => generateColumns(), []);
  return (
    <>
      <Container fluid>
        <Row>
          <Col xs="12" className="mb-0">
            <div className="border ps-4 my-2" style={{ position: "relative" }}>
              <Label className="text-dark fs-5" style={{ position: "absolute", transform: "translateY(-50%)" }}>
              <span className=" px-1 wh-item-bg"> Filters</span>
              </Label>
              <Row>
                <Col xs="9" className="border-end pe-5">
                  <Row className="my-3">
                    {/* <Col xs={3}>
                      <div className="border p-3">
                        <div className="d-flex align-items-center mb-3">
                          <Input
                            type="radio"
                            // checked={filters.status === "active"}
                            className="mb-1"
                            // onChange={() => statusChngHndlr("active")}
                          />
                          <Label className="ms-1 mb-0">Code</Label>
                        </div>
                        <div className="d-flex align-items-center">
                          <Input
                            type="radio"
                            // checked={filters.status === "active"}
                            className="mb-1"
                            // onChange={() => statusChngHndlr("active")}
                          />
                          <Label className="ms-1 mb-0">Company</Label>
                        </div>
                      </div>
                    </Col> */}
                    <Col xs={5}>
                      <div className="d-flex align-items-center justify-content-around border py-1 flex-grow-1 mb-1">
                        <div className="d-flex align-items-center">
                          <Input
                            type="radio"
                            checked={filters.status === "active"}
                            className="mb-1"
                            onChange={() => statusChngHndlr("active")}
                          />
                          <Label className="ms-1 mb-0">Active</Label>
                        </div>
                        <div className="d-flex align-items-center">
                          <Input
                            type="radio"
                            checked={filters.status === "inactive"}
                            className="mb-1"
                            onChange={() => statusChngHndlr("inactive")}
                          />
                          <Label className="ms-1 mb-0">Non Active</Label>
                        </div>
                        <div className="d-flex align-items-center">
                          <Input
                            type="radio"
                            className="mb-1"
                            checked={filters.status === ""}
                            onChange={() => statusChngHndlr("all")}
                          />
                          <Label className="ms-1 mb-0">All Items</Label>
                        </div>
                      </div>

                      <Row>
                        <Col xs="8">
                          <Label>Search</Label>
                          <Input type="text" className="py-1" name="search" value={filters.search} onChange={filtersChngHndlr} />
                        </Col>
                        <Col xs="4">
                          <Label>State</Label>
                          <Input type="select" className="py-1" name="state" value={filters.state} onChange={filtersChngHndlr}>
                            <option value=""></option>
                            {(getStatesListResp?.data?.states || []).map((item) => (
                              <option key={item.id} value={item.code}>
                                {item.name}
                              </option>
                            ))}
                          </Input>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
                <Col xs="3">
                  <div className="d-flex align-items-center pe-xxl-5 pe-4 pt-3">
                    <div className="sale-wh-wrapper flex-grow-1 mt-0 me-xxl-4 me-2">
                      <div className="d-flex justify-content-around border border-section py-2 h-100">
                        {actions(togglePrintModal, getCustomersReportApiCall,resetFilters).map((item) => {
                          return (
                            <div
                              key={item.id}
                              className="action-icon-container"
                              onClick={item.clickHandler || (() => {})}
                              style={{cursor:"pointer"}}
                            >
                              <img className="action-icon" src={item.icon} />
                              <span>{item.label}</span>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                    <div
                      className="d-flex flex-column align-items-center"
                      style={{ cursor: "pointer" }}
                      onClick={() => history.push("/reportsmenu")}
                    >
                      <img src={BackIcon} width="32" height="32" alt="back-icon" />
                      <div className="text-decoration-underline mb-0">Go Back</div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
          <Col xs="12">
            <WhTableContainer
              columns={columns}
              data={tableData || []}
              className="custom-header-css"
              divClass="table-responsive table-container-customersreport"
              tableClass="align-middle table-bordered"
              theadClass="table-light"
              handlePageChange={handlePageChange}
              pageCount={pageCount || 0}
              customPageSize={30}
              showNoDataComponent={true}
            />
          </Col>
        </Row>
      </Container>
      <Modal
     id="showPrintModal"
     isOpen={showPrintModal}
     toggle={togglePrintModal}
     centered
     size="lg"
   >
     <F12CloseModalListener onClose={togglePrintModal} />
     <ModalHeader className="py-2 px-3" toggle={togglePrintModal}>
       Print Options
     </ModalHeader>
     <Form
       onSubmit={(e) => {
         e.preventDefault();
       }}
     >
       <ModalBody className="px-0 pt-0">
         <PrintItem
           getCustomersReportApiCall={getCustomersReportApiCall}
           filters={filters}
           showPrintModal={showPrintModal}
         />
       </ModalBody>
     </Form>
   </Modal>
      <ApiLoader loading={getCustomersReportLoading}/>
      <ApiLoader loading={getInvPricingReportLoading} />
    </>
  );
};
