import React, { useMemo, useState } from "react";
import {
   Col, Container, Row, Nav, NavItem, NavLink, TabContent, TabPane
} from 'reactstrap';
import { ToastContainer } from "react-toastify";
import { tabs } from "./helper";
import classnames from "classnames";
import {
  Roles,
  Employees,
  Permission
} from "./tabs";

function getActiveComponent(activeId) {
  switch (activeId) {
    case "roles":
      return Roles;
    case "permission":
      return Permission;
    case "employees":
      return Employees;
    default:
      return <></>;
  }
}


export default function UserManagement() {

  const [justifyPillsTab, setjustifyPillsTab] = useState("roles");
  const justifyPillsToggle = (tab) => {
    if (justifyPillsTab !== tab) {
      setjustifyPillsTab(tab);
    }
  };
  const TabComponent = useMemo(() => getActiveComponent(justifyPillsTab),[justifyPillsTab]);
  return (
    <React.Fragment>
      <ToastContainer />
      <div className="sale-wh-wrapper" style={{minHeight:"500px"}}>
        <Container fluid>
          <Row>
            <Col className="px-0" xs={12}>
              <Nav pills className="nav mb-2 bg-light px-5">
                {tabs.map(item => <NavItem key={item.id}>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({ active: justifyPillsTab === item.id })}
                    onClick={() => {
                      justifyPillsToggle(item.id);
                    }}
                  >
                    {item.name}
                  </NavLink>
                </NavItem>)}
              </Nav>
              <TabContent activeTab={justifyPillsTab} className="text-muted">
                <TabPane tabId={justifyPillsTab} id="pill-justified-home-1">
                  <TabComponent />
                </TabPane>
              </TabContent>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
}