import * as Yup from "yup";
export const detailsInitialValues = {
  active: true,
  // supplier: true,
  supplierAccountNumber: "",
  // typeOfVendor: "",
  supplierName: "",
  salutation: "",
  firstName: "",
  middleInitial: "",
  lastName: "",
  isOutOfState: false,
  flatNumber: "",
  street: "",
  landmark:"",
  // suite: "",
  city: "",
  st: "",
  zip: "",
  // country: "",
  phone: "",
  fax: "",
  email: "",
  gstNumber:"",
  // website: "",
  // paymentTerms: "",
  // creditLimit: "",
  // discount: "",
  // fedTaxID: "",
  comments: "",
  // memo: "",
  // creditBalance: "",
  // defaultAccount: "",
  // printYtd: true,
  // stateTaxID: "",
  // isReport: true,
  // discountPer: "",
  // isisOutOfState: true,
  // isLocalTaxesPaid: true,
  // salesPhone:"",
  // salesEmail:"",
  // salesFirstName:"",
  // salesLastName:"",
  // salesPhone2:"",
  // salesEmail2:"",
  // salesFirstName2:"",
  // salesLastName2:""
};

export const detailsValidationSchema = Yup.object({
  supplierName: Yup.string().required("Company Name is required"),
  gstNumber : Yup.string().required("GST number is required"),
  // firstName: Yup.string().required("First Name is required"),
  // lastName: Yup.string().required("Last Name is required"),
  flatNumber: Yup.string().required("Flat, House no., Building, Company, Apartment Number is required"),
  street: Yup.string().required("Street is required"),
  zip: Yup.string().required("Pincode is required"),
  landmark: Yup.string().required("Landmark is required"),
  city: Yup.string().required("City is required"),
  st: Yup.string().required("State is required"),
  phone: Yup.string().min(10,"Phone Number should contain minimum 10 digits"),
  email: Yup.string().email("Invalid Email")
});
export const generateStatesData = () => [
  { id: 1, code: 'AP', name: 'Andhra Pradesh' },
  { id: 2, code: 'AR', name: 'Arunachal Pradesh' },
  { id: 3, code: 'AS', name: 'Assam' },
  { id: 4, code: 'BR', name: 'Bihar' },
  { id: 5, code: 'CG', name: 'Chhattisgarh' },
  { id: 6, code: 'GA', name: 'Goa' },
  { id: 7, code: 'GJ', name: 'Gujarat' },
  { id: 8, code: 'HR', name: 'Haryana' },
  { id: 9, code: 'HP', name: 'Himachal Pradesh' },
  { id: 10, code: 'JH', name: 'Jharkhand' },
  { id: 11, code: 'KA', name: 'Karnataka' },
  { id: 12, code: 'KL', name: 'Kerala' },
  { id: 13, code: 'MP', name: 'Madhya Pradesh' },
  { id: 14, code: 'MH', name: 'Maharashtra' },
  { id: 15, code: 'MN', name: 'Manipur' },
  { id: 16, code: 'ML', name: 'Meghalaya' },
  { id: 17, code: 'MZ', name: 'Mizoram' },
  { id: 18, code: 'NL', name: 'Nagaland' },
  { id: 19, code: 'OD', name: 'Odisha' },
  { id: 20, code: 'PB', name: 'Punjab' },
  { id: 21, code: 'RJ', name: 'Rajasthan' },
  { id: 22, code: 'SK', name: 'Sikkim' },
  { id: 23, code: 'TN', name: 'Tamil Nadu' },
  { id: 24, code: 'TG', name: 'Telangana' },
  { id: 25, code: 'TR', name: 'Tripura' },
  { id: 26, code: 'UP', name: 'Uttar Pradesh' },
  { id: 27, code: 'UK', name: 'Uttarakhand' },
  { id: 28, code: 'WB', name: 'West Bengal' }
];

export const generatePayload = (values) => {
  const {
    active,
    // supplier,
    supplierAccountNumber,
    // typeOfVendor,
    supplierName,
    salutation,
    firstName,
    // middleInitial,
    lastName,
    isOutOfState,
    flatNumber,
    street,
    // suite,
    landmark,
    city,
    st,
    zip,
    // country,
    phone,
    fax,
    email,
    gstNumber,
    // website,
    // paymentTerms,
    // creditLimit,
    // discount,
    // fedTaxID,
    comments,
    // memo,
    // creditBalance,
    // defaultAccount,
    // printYtd,
    // stateTaxID,
    // isReport,
    // discountPer,
    // isOutOfState,
    // isLocalTaxesPaid,
    // salesPhone,
    // salesEmail,
    // salesFirstName,
    // salesLastName,
    // salesPhone2,
    // salesEmail2,
    // salesFirstName2,
    // salesLastName2
  } = values;
  return {
    active,
    // supplier,
    supplierAccountNumber: supplierAccountNumber || null,
    // typeOfVendor: typeOfVendor || null,
    supplierName: supplierName || null,
    salutation: salutation || null,
    firstName: firstName || null,
    // middleInitial: middleInitial || null,
    lastName: lastName || null,
    isOutOfState,
    gstNumber:gstNumber || null,
    flatNumber: flatNumber || null,
    street: street || null,
    // suite: suite || null,
    landmark: landmark || null,
    city: city || null,
    st: st || null,
    zip: zip || null,
    // country: country || null,
    phone: phone || null,
    fax: fax || null,
    email: email || null,
    
    // website: website || null,
    // paymentTerms: paymentTerms || null,
    // creditLimit: creditLimit || null,
    // discount: discount || null,
    // fedTaxID: fedTaxID || null,
    comments: comments || null,
    // memo: memo || null,
    // creditBalance: creditBalance || null,
    // defaultAccount: defaultAccount || null,
    // printYtd,
    // stateTaxID: stateTaxID || null,
    // isReport,
    // discountPer: discountPer || null,
    // isOutOfState,
    // isLocalTaxesPaid,
    // salesPhone: salesPhone || null,
    // salesEmail: salesEmail || null,
    // salesFirstName : salesFirstName || null,
    // salesLastName : salesLastName || null,
    // salesPhone2 : salesPhone2 || null,
    // salesEmail2 : salesEmail2 || null,
    // salesFirstName2 : salesFirstName2 || null,
    // salesLastName2 : salesLastName2 || null
  };
};