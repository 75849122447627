import * as Yup from "yup";
export const classificationInitialValues = {
  shipToRef: "",
  shippedVia: "",
  group: "",
  routeName: "",
  subGroup: "",
  routeDeliveryDay: "",
  zone: "",
  driverName: "",
  businessType: "",
  defaultInvComment:false,
  salesman: "",
  isOutOfState: false,
  isViewInvPrevBal: false,
  isEmailList: false,
  viewInvRetail:false,
  isMailingList: false,
  isRejectPromotion: false,
  barcode: false,
  invCopy: "",
  isInvoiceSpecial: false,
};

export const classificationValidationSchema = Yup.object({
    // group: Yup.string().required("Please Select Group"),
    // subGroup: Yup.string().required("Please Select Sub Group"),
})

export const getDropdownOptions = (options,customOption, key) => {
  if(!customOption) return options;
  else if(!key) return [...options,customOption];
  else return [...options,{id:"custom",[key]:customOption}];
}

export function generateColumns() {
  return [
    {
      Header: "Cust. No.",
      accessor: "customerNumber",
    },
    {
      Header: "Company Name",
      accessor: "companyName",
    },
    {
      Header: "Group",
      accessor: "group",
    },
  ];
}