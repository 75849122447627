import React, { Fragment, useCallback, useEffect, useMemo, useRef, useState } from "react";
import withPermission from "../../hoc/withPermission";
import { toast, ToastContainer } from "react-toastify";
import { Col, Container, Input, Label, Row } from "reactstrap";
import WhTableContainer from "../../Components/Common/WhTables";
import { DUMMY_DATA, generateColumns, actions } from "./helper";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { getShopifyReportAct } from "../../store/reports/reportsactions";
import { generateApiUrl } from "../../api/apihelper";
import { getStartDateFilterValue, getEndDateFilterValue } from "../../utility/dateutility";
import BackIcon from "../../assets/images/warehouse/menu/back.png";
import ApiLoader from "../../Components/Common/ApiLoader";
function WarehouseOrdersHistory({ history }) {
  const [pageCount, setPageCount] = useState(0);
  const [filters, setFilters] = useState({
    status: "",
    fromDate: "",
    toDate: "",
  });
  const [tableData, setTableData] = useState([]);
  
  const dispatch = useDispatch();
  const currentPageSize = useRef(30);
  const { getShopifyReportResp, getShopifyReportLoading, reportsErrorResp } = useSelector(
    ({ reports }) => ({
      getShopifyReportResp: reports.getShopifyReportResp,
      getShopifyReportLoading: reports.getShopifyReportLoading,
      reportsErrorResp: reports.reportsErrorResp,
    }),
    shallowEqual
  );
  const customerResp = useRef({
    prevGetShopifyReportResp: getShopifyReportResp,
    prevReportsErrorResp: reportsErrorResp,
  });
  useEffect(() => {
    const { prevGetShopifyReportResp, prevReportsErrorResp } = customerResp.current || {};
    if (getShopifyReportResp && prevGetShopifyReportResp !== getShopifyReportResp) {
      const ordersData = getShopifyReportResp?.data?.orders || [] ;
      setTableData(ordersData);
      currentPageSize.current = getShopifyReportResp?.data?.pageSize;
      setPageCount((getShopifyReportResp?.data?.total))
    } else if (reportsErrorResp && prevReportsErrorResp !== reportsErrorResp) {
      const { errors, error, message } = reportsErrorResp || {};
      toast.error((Array.isArray(errors) && errors[0].msg) || error || message);
    }
    customerResp.current.prevGetShopifyReportResp = getShopifyReportResp;
    customerResp.current.prevReportsErrorResp = reportsErrorResp;
  }, [getShopifyReportResp, reportsErrorResp]);

  const getShopifyReportApiCall = useCallback(
    (page, pageSize) => {
      const { status,fromDate, toDate} = filters;
      let params = {};
          params.page = page ? page + 1 : 1;
          params.pageSize = pageSize || currentPageSize.current;
          params.type = "SHOPIFY"
      if (status) params.status = status;
      if (fromDate) params.fromDate = getStartDateFilterValue(fromDate);
      if (toDate) params.toDate = getEndDateFilterValue(toDate);
      dispatch(
        getShopifyReportAct(
          generateApiUrl(`get_shopify_report`),
          params
        )
      );
        
    },
    [filters]
  );

  useEffect(() => {
    const debounceFn = setTimeout(() => {
      getShopifyReportApiCall();
    }, 400);
    return () => {
      clearTimeout(debounceFn);
    };
  }, [getShopifyReportApiCall]);

  const handlePageChange = (page, pageSize) => {
    getShopifyReportApiCall(page, pageSize);
  };

  const filtersChngHndlr = (e) => {
    const { value, checked, name, type } = e.target;
    setFilters((prev) => ({ ...prev, [name]: type === "checkbox" ? checked : value }));
  };

  const resetFilters = () => {
    setFilters({
      status: "",
      fromDate: "",
      toDate: "",
    })
  }

  const columns = useMemo(() => generateColumns(), []);
    return (
        <Fragment>
            <ToastContainer />
            <div className="sale-wh-wrapper whbrand-text-color">
        <Container fluid>
        <Row>
        <Col xs="12" className="mb-0">
        {/* <div className="px-3 mb-3 pt-1 border rounded-1 report-header" style={{display:"inline-block"}}>
          <h3 style={{color:'#fff'}}>Payments History </h3>
         </div> */}
          <div className="border ps-4 my-2" style={{ position: "relative" }}>
            <Label className="text-dark fs-5" style={{ position: "absolute", transform: "translateY(-50%)" }}>
              <span className=" px-1 wh-item-bg"> Filters</span>
            </Label>
            <Row>
              <Col xs="6" xxl="7" className="pe-5">
                <Row className="my-1 mt-3">
                  <Col xs={4}>
                    <Row className="my-1">
                      <Col xs="12">
                        <Label className="mb-1">Date From</Label>
                        <Input type="date" className="form-control py-1" name="fromDate" 
                        value={filters.fromDate} 
                        onChange={filtersChngHndlr} 
                        />
                      </Col>
                    </Row>
                    <Row className="mb-1">
                      <Col xs="12">
                        <Label className="mb-1">Date To</Label>
                        <Input type="date" className="form-control py-1" name="toDate" 
                        value={filters.toDate} 
                        onChange={filtersChngHndlr} 
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col xs="6" xxl="6" className="d-flex align-items-center pe-5">
                    <div className="sale-wh-wrapper flex-grow-1 mt-0 me-4">
                      <div className="d-flex justify-content-around border border-section py-2 h-100">
                        {actions(resetFilters).map((item) => {
                          return (
                            <div
                              key={item.id}
                              className="action-icon-container"
                              onClick={item.clickHandler || (() => {})}
                              style={{cursor:"pointer"}}
                            >
                              <img className="action-icon" src={item.icon} />
                              <span>{item.label}</span>
                            </div>
                          );
                        })}
                        <div
                          className="d-flex flex-column align-items-center opacity-50 show-cursor-not-allowed"
                          style={{ cursor: "pointer" }}
                          // onClick={() => history.push("/warehousemenu")}
                        >
                          <img src={BackIcon} width="32" height="32" alt="back-icon" />
                          <div className="text-decoration-underline mb-0">Go Back</div>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
              
              
            </Row>
          </div>
        </Col>
        <Col xs="12">
          <WhTableContainer
            columns={columns}
            data={tableData || []}
            className="custom-header-css"
            divClass="table-responsive table-container-order-history"
            tableClass="align-middle table-bordered"
            theadClass="table-light"
            handlePageChange={handlePageChange}
            pageCount={pageCount || 0}
            customPageSize={30}
            showNoDataComponent={true}
          />
        </Col>
      </Row>
      <ApiLoader loading={getShopifyReportLoading} />
        </Container>
      </div>
            
        </Fragment>
    )
}

export default withPermission(WarehouseOrdersHistory, "INVENTORY_MENU");