import React, { useRef, useState, useEffect } from "react";
import SelectItemForTableInput from "../SelectItemForTableInput";

function ClickedSelectItem({
  inputStyle,
  value = "",
  doubleClickHandler,
  containerClassName = "",
  isLast = false,
  addObserver = false,
  row,
  ...otherProps
}) {
  const [showValueState, setShowValueState] = useState(false);
  const selectItemWrapperRef = useRef(null);
  const ref = useRef(null);
  const handleClickOutside = (e) => {
    if (ref.current && !ref.current.contains(e.target)) {
      setShowValueState((prev) => {
        if (prev === true) {
          return false;
        }
        return prev;
      });
    }
  };
  const delay = useRef(200);
  const prevent = useRef(false);
  const timer = useRef(0);
  useEffect(() => {
    if(showValueState) document.addEventListener("mousedown", handleClickOutside, true);
    else document.removeEventListener("mousedown", handleClickOutside, true)
    return () => document.removeEventListener("mousedown", handleClickOutside, true);
  }, [showValueState]);
  function onClickHanlder() {
    setShowValueState(true);
  }
  function handleClick() {
    timer.current = setTimeout(function () {
      if (!prevent.current) {
        onClickHanlder();
      }
      prevent.current = false;
    }, delay.current);
  }
  function handleDoubleClick() {
    clearTimeout(timer.current);
    if (doubleClickHandler) {
      prevent.current = true;
      doubleClickHandler();
    }
  }

  useEffect(() => {
    let observer;
    if (addObserver) {
      const element = selectItemWrapperRef.current;
      // Create an observer that watches for changes in the className of the element
      observer = new MutationObserver((mutationsList) => {
        mutationsList.forEach((mutation) => {
          if (mutation.type === "attributes" && mutation.attributeName === "class") {
            if (element.className.includes("active")) setShowValueState(true);
          }
        });
      });
      if (isLast) {
        // Start observing the element for changes in its attributes
        observer.observe(element, { attributes: true });
      }
    }
    // Cleanup function to stop observing the element when the component unmounts
    return () => {
      if (addObserver && isLast) {
        observer.disconnect();
      }
    };
  }, [isLast]);

  useEffect(() => {
    if (!showValueState) {selectItemWrapperRef.current.classList.remove("active")}
  }, [showValueState]);


  return (
    <div style={{ height: "30px" }} className={containerClassName} ref={selectItemWrapperRef}>
      {showValueState && !value ? (
        
        <SelectItemForTableInput innerRef={ref} setShowValueState={setShowValueState} {...otherProps} inputStyle={inputStyle}/>
      ) : (
        <span
        className={`d-flex align-items-center w-100 h-100 `}
        style={{ userSelect: "none" }}
        onClick={handleClick}
        onDoubleClick={handleDoubleClick}
        >
          {value}
        </span> 
      )}
    </div>
  );
}

export default React.memo(ClickedSelectItem);
