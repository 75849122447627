import * as yup from "yup";
import DummyAvatar from "../../../../../assets/images/warehouse/salespage/dummy-avatar.jpg";
import PrintIcon from "../../../../../assets/images/warehouse/salespage/printer.png";
import DeleteIcon from "../../../../../assets/images/warehouse/salespage/delete.png";
import NewIcon from "../../../../../assets/images/warehouse/salespage/new.png";
//Utility
import { getMmddyyyyDateFormatted } from "../../../../../utility/dateutility";

export const actions = (setActiveId,toggleDeleteConfirmation, deleteEmployeeConfig) => [
  {
    id: "print",
    label: "Print",
    icon: PrintIcon,
  },
  {
    id: "delete",
    label: "Delete",
    icon: DeleteIcon,
    clickHandler: () => {
        deleteEmployeeConfig.current = {
          deleteMultiple: true,
          idOfEmployeeToBeDeleted: "",
        };
        toggleDeleteConfirmation();
      },
  },
  {
    id: "new",
    label: "New",
    icon: NewIcon,
    clickHandler: () => setActiveId("add_new_employee"),
  },
];

export function generateColumns(setEditEmployeeData, setActiveId, toggleDeleteConfirmation, deleteEmployeeConfig) {
  return [
    {
      Header: "Image",
      accessor: "item",
      Cell: ({row:{original:{image}}}) => <img src={image || DummyAvatar} alt="" className="rounded avatar-sm" />,
    },
    {
      Header: "Employee Name",
      accessor: "name",
    },
    {
      Header: "Employee Id",
      accessor: "userId",
    },
    {
      Header: "Department",
      accessor: "department.name",
    },
    {
      Header: "Designation",
      accessor: "designation.name",
    },
    {
      Header: "Contact Number",
      accessor: "homePhone",
    },
    {
      Header: "Total Salary",
      accessor: "price",
    },
    {
      Header: "Joining Date",
      accessor: "hireDate",
      Cell: ({ cell: { value } }) => (value ? getMmddyyyyDateFormatted(value) : ""),
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: ({
        row: {
          original: { user },
        },
      }) => (
        <span className={`badge badge-soft-${user?.isActive ? "success" : "danger"}`} style={{ fontSize: "13px" }}>
          {user?.isActive ? "Active" : "Inactive"}
        </span>
      ),
    },
    {
      Header: <div className="text-center">Actions</div>,
      accessor: "actions",
      style: { width: "180px", textAlign: "center" },
      Cell: ({ row }) => (
        <div className="hstack gap-3 flex-wrap justify-content-center">
          <span
            className="wh-link-color-green fs-15 show-cursor-pointer"
            onClick={() => {
              setActiveId("add_new_employee");
              setEditEmployeeData(row.original);
            }}
          >
            <i className="ri-edit-2-line"></i>
          </span>
          <span
            className="link-danger fs-15 show-cursor-pointer"
            onClick={() => {
              deleteEmployeeConfig.current = {
                deleteMultiple: false,
                idOfEmployeeToBeDeleted: row.original.id,
              };
              toggleDeleteConfirmation();
            }}
          >
            <i className="ri-delete-bin-line"></i>
          </span>
        </div>
      ),
    },
  ];
}

export const addEmployeeInitialValues = {
  image: "",
  name: "",
  departmentId: "",
  designationId: "",
  roleId: "",
  homePhone: "",
  email: "",
  birthDate: "",
  gender: "",
  hireDate: "",
  salary: "",
  username: "",
  password: "",
  street: "",
  city: "",
  zip: "",
  suit: "",
  state: "",
  county: "",
  active:true,
};

export const getAddEmployeeValidationSchema = (isEditMode) => {
  return yup.object().shape({
    name: yup.string().required("Employee Name is required"),
    homePhone: yup.string().required("Phone is required").min(10,"Should contain atleat 10 digits"),
    departmentId: yup.string().required("Department is required"),
    roleId: yup.string().required("Role is required"),
    password: !isEditMode ? yup.string().required("Password is required") : yup.string(),
    username: yup.string().required("Username is required"),
  });
};
