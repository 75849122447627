import { Link } from "react-router-dom";
export function generateColumns() {
    return [
        {
            Header: "Item Num",
            accessor: "itemNumber",
            Cell:({cell:{value}}) => <Link to={`/warehouseinventory/${value}`}>{value}</Link>
        },
        {
            Header: "Item Name",
            accessor: "description",
            Cell:({cell:{value}}) => value || "-"
        }, 
        {
            Header: "UPC Code",
            accessor: "upcCode",
            Cell:({cell:{value}}) => value || "-"
        },
    ]
}