import SalesMenuIcon from "../../assets/images/warehouse/menu/sales_menu.png";
import OrderMenuIcon from "../../assets/images/warehouse/menu/order_menu.png";
import AdministrationIcon from "../../assets/images/warehouse/menu/administration.png";
import OfficeToolsIcon from "../../assets/images/warehouse/menu/office_tools.png";
import OpenInvoiceIcon from "../../assets/images/warehouse/menu/open_invoices.png";
import LogOnOffIcon from "../../assets/images/warehouse/menu/logonoff.png";
import ExitIcon from "../../assets/images/warehouse/menu/exit.png";
import ReportIcon from "../../assets/images/warehouse/menu/report.png";


export const menuList = [
    {
        id: "sales_menu",
        name: "Sales Menu",
        imgSrc: SalesMenuIcon,
        url: "/warehousesales/new",
        permission:"POS_MENU",
    },
    {
        id: "reports",
        name: "Reports",
        imgSrc: ReportIcon,
        url:"/reportsmenu"
    },
    // {
    //     id: "order_menu",
    //     name: "Order Menu",
    //     imgSrc: OrderMenuIcon,
    //     permission:"ORDER_MENU",
    // },
    {
        id: "administrations",
        name: "Administrations",
        imgSrc: AdministrationIcon,
        url: "/admin/generalsettings",
        role:["ADMIN"],
    },
    // {
    //     id: "office_tools",
    //     name: "Office Tools",
    //     imgSrc: OfficeToolsIcon,
    // }, {
    //     id: "open_invoice",
    //     name: "Open Invoice",
    //     imgSrc: OpenInvoiceIcon,
    // }, {
    //     id: "logonoff",
    //     name: "Log On / Log Off",
    //     imgSrc: LogOnOffIcon,
    // }, 
    {
        id: "exit",
        name: "Exit",
        imgSrc: ExitIcon,
        url: "/warehouselogin"
    }
]