import React, { useCallback, useEffect, useRef } from "react";
import { useState } from "react";
import { Container, Row, Col, Label, Input, Button } from "reactstrap";
import moment from "moment";
import downloadIcon from "../../../../assets/images/warehouse/salespage/download-icon.png";
import shareIcon from "../../../../assets/images/warehouse/salespage/share.png";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { toast } from "react-toastify";
import {
  getEndDateFilterValue,
  getStartDateFilterValue,
} from "../../../../utility/dateutility";
import { getMsaFileReportAct } from "../../../../store/reports/reportsactions";
import { generateApiUrl } from "../../../../api/apihelper";
import ApiLoader from "../../../../Components/Common/ApiLoader";
export function MsaReportTab() {
  const [filters, setFilters] = useState({
    fileName: "",
    fromDate: moment().subtract(1, "weeks").format("YYYY-MM-DD"),
    toDate: moment().format("YYYY-MM-DD"),
    isLive: false,
  });

  const [weekDate, setWeekDate] = useState(() => {
    const currentDay = moment();
    let currentWeekSaturday = currentDay.clone().startOf('isoWeek').add(5, 'days');

    const previousSaturday = currentDay.clone().subtract(5, 'day');
  

    if (currentDay.isBefore(currentWeekSaturday, 'day')) {
      currentWeekSaturday = previousSaturday;
    }
  
    return currentWeekSaturday.format('YYYY-MM-DD');
  });
  useEffect(() => {
    setFilters({
      ...filters, 
      fromDate : moment(weekDate).subtract(7, "days")
      .format("YYYY-MM-DD"), 
      toDate: weekDate  
    })
    setWeekText(`Week of Sat ${moment(weekDate).format("MMM DD, YYYY")}`);
    
  },[])

  const [weekText, setWeekText] = useState("Current Week");
  const weekDateChngHndlr = (e) => {
    const selectedWeekDate = e.target.value;
    const toDate = selectedWeekDate;
    const fromDate = moment(selectedWeekDate)
      .subtract(1, "weeks")
      .format("YYYY-MM-DD");
    setFilters({ ...filters, fromDate, toDate });
    setWeekDate(selectedWeekDate);
  };
  const changeWeek = (direction) => {
    const newWeekDate = moment(weekDate)
      .subtract(direction * 7, "days")
      .format("YYYY-MM-DD");
    const fromDate = moment(newWeekDate)
      .subtract(7, "days")
      .format("YYYY-MM-DD");
    setFilters({ ...filters, fromDate, toDate: newWeekDate });
    setWeekDate(newWeekDate);
    setWeekText(`Week of Sat ${moment(newWeekDate).format("MMM DD, YYYY")}`);
 
  };
  const isCurrentWeek = moment().isSame(moment(weekDate).add(1, 'day').format('YYYY-MM-DD'), "week");
  const [uniqueIdentifier, setUniqueIdentifier] = useState("14561001");
  const [downloadFilename, setDownloadFilename] = useState("");

  const handleDistCodeChange = (event) => {
    setUniqueIdentifier(event.target.value);
  };

  const dispatch = useDispatch();
  const isDownloadBtnClicked = useRef(false);
  const filtersChngHndlr = (e) => {
    const { value, checked, name, type } = e.target;
    setFilters((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };
  const { getMsaFileReportResp,getMsaFileReportLoading,  reportsErrorResp } = useSelector(
    ({ reports }) => ({
      reportsErrorResp: reports.reportsErrorResp,
      getMsaFileReportLoading: reports.getMsaFileReportLoading,
      getMsaFileReportResp: reports.getMsaFileReportResp,
    }),
    shallowEqual
  );
  const customerResp = useRef({
    prevGetMsaFileReportResp: getMsaFileReportResp,
    prevReportsErrorResp: reportsErrorResp,
  });
  useEffect(() => {
    const { prevReportsErrorResp, prevGetMsaFileReportResp } =
      customerResp.current || {};
    if (
      getMsaFileReportResp &&
      prevGetMsaFileReportResp !== getMsaFileReportResp
    ) {
      if (isDownloadBtnClicked.current && getMsaFileReportResp) {
        // const file = new File([getMsaFileReportResp], "MSA_FILE_REPORT.pdf", {
        //   type: "application/pdf",
        // });
        // const url = window.URL.createObjectURL(file);
        // const a = document.createElement("a");
        // a.href = url;
        // a.setAttribute("download", "MSA_FILE_REPORT.pdf");
        // document.body.appendChild(a);
        // a.click();
        // a.remove();
        const url = window.URL.createObjectURL(
          new Blob([getMsaFileReportResp])
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          downloadFilename || "MSA_FILE_REPORT.csv"
        );
        document.body.appendChild(link);
        link.click();
        link.remove();
        isDownloadBtnClicked.current = false;
      }
    }
    if (reportsErrorResp && prevReportsErrorResp !== reportsErrorResp) {
      const { errors, error, message } = reportsErrorResp || {};
      toast.error((Array.isArray(errors) && errors[0].msg) || error || message);
    }
    customerResp.current.prevGetMsaFileReportResp = getMsaFileReportResp;
    customerResp.current.prevReportsErrorResp = reportsErrorResp;
  }, [getMsaFileReportResp, reportsErrorResp]);

  const getMsaFileReportApiCall = useCallback(
    (type) => {
      const { fromDate, toDate, isLive } = filters;
      let params = {};
      if (fromDate) params.fromDate = getStartDateFilterValue(fromDate);
      if (toDate) params.toDate = getEndDateFilterValue(toDate);
      if (isLive) params.isLive = isLive;
      if (type === "DOWNLOAD") {
        const timestamp = toDate
          ? new Date(toDate)
              .toISOString()
              .replace(/[-:.TZ]/g, "")
              .replace("T", "_")
              .slice(0, 8)
          : "";
        const identifier =
          uniqueIdentifier.trim() !== "" ? uniqueIdentifier : "MSA";
        const filename = `${identifier}_${timestamp}.txt`;
        setDownloadFilename(filename);

        params.type = type;
        isDownloadBtnClicked.current = true;

        dispatch(
          getMsaFileReportAct(generateApiUrl(`get_msa_report`), params, {
            responseType: "blob",
            headers: {
              "Content-Disposition": `attachment; filename=${filename}`,
            },
          })
        );
      }
    },
    [dispatch, filters, uniqueIdentifier]
  );
  const handleDownloadClick = () => {
    getMsaFileReportApiCall("DOWNLOAD");
  };

  useEffect(() => {
    if (filters.toDate) {
      const timestamp = new Date(filters.toDate)
        .toISOString()
        .replace(/[-:.TZ]/g, "")
        .replace("T", "_")
        .slice(0, 8);
      const filename = `${uniqueIdentifier}_${timestamp}.txt`;
      setDownloadFilename(filename);
    }
  }, [filters.toDate, uniqueIdentifier]);
  return (
    <>
      <Container fluid className=" pt-3 pb-0">
        <Row>
          <Col xs="12" className="mb-1">
            <div
              className="border ps-4 my-3 mb-1"
              style={{ position: "relative" }}
            >
              <Label
                className="text-dark fs-5"
                style={{ position: "absolute", transform: "translateY(-50%)" }}
              >
                <span className=" px-1 wh-item-bg py-1"> Last MSA Report</span>
              </Label>
              <Row xs={12}>
                <Col xs="12" md="12" className="pe-5 py-2">
                  <Row className="my-3 mb-1">
                    <Col xs={3} md={2}>
                      <Label
                        htmlFor="companyName"
                        className="form-label mb-0 text-nowrap"
                      >
                        Date From :
                      </Label>
                      <Input
                        type="date"
                        className="form-control py-1"
                        name="fromDate"
                        value={filters.fromDate}
                        onChange={filtersChngHndlr}
                      />
                    </Col>
                    <Col xs={3} md={2}>
                      <Label
                        htmlFor="companyName"
                        className="form-label mb-0 text-nowrap"
                      >
                        Date To :
                      </Label>
                      <Input
                        type="date"
                        className="form-control py-1"
                        name="toDate"
                        value={filters.toDate}
                        onChange={filtersChngHndlr}
                      />
                    </Col>
                    <Col xs={3} md={2}>
                      <Label
                        htmlFor="distCode"
                        className="form-label mb-0 text-nowrap"
                      >
                        Dist. Code
                      </Label>
                      <Input
                        type="text"
                        className="form-control py-1"
                        name="distCode"
                        value={uniqueIdentifier}
                        onChange={handleDistCodeChange}
                      />
                    </Col>

                    <Col xs={3} md={2} className="d-flex flex-column">
                      <Label
                        htmlFor="companyName"
                        className="form-label mb-0 text-nowrap"
                      >
                        Week Ending
                      </Label>
                      <Input
                        type="date"
                        className="form-control py-1"
                        name="toDate"
                        value={weekDate}
                        onChange={weekDateChngHndlr}
                      />
                      <div className="d-flex flex-row align-items-center justify-content-center mt-2 ">
                        <button
                          type="button"
                          className="btn bg-primary btn-sm text-white text-center d-flex justify-content-center align-content-center "
                          onClick={() => changeWeek(1)}
                          style={{
                            fontSize: "16px",
                            padding: "0.15rem 0.5rem",
                          }}
                        >
                          <span>&lt;</span>
                        </button>
                        <h5 className="text-white mx-3 mt-1">{weekText}</h5>
                        <button
                          type="button"
                          onClick={() => changeWeek(-1)}
                          className="btn bg-primary btn-sm text-white"
                          style={{
                            fontSize: "16px",
                            padding: "0.15rem 0.5rem",
                          }}
                          disabled={isCurrentWeek}
                        >
                          <span>&gt;</span>
                        </button>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </Col>
          <Col xs="6" md="4" className="mb-1">
            <div
              className="border ps-4 my-3 mb-1"
              style={{ position: "relative" }}
            >
              <Label
                className="text-dark fs-5"
                style={{ position: "absolute", transform: "translateY(-50%)" }}
              >
                <span className=" px-1 wh-item-bg py-1">
                  MSA Report Details
                </span>
              </Label>
              <Row>
                <Col xs="12" className="pe-5 py-2">
                  <Row xs="12" md="12" className="my-3 mb-1">
                    <Col xs="12" md="12">
                      <Label
                        htmlFor="companyName"
                        className="form-label mb-0 text-nowrap"
                      >
                        File Name
                      </Label>
                      <Input
                        type="text"
                        className="form-control py-1"
                        name="fileName"
                        value={downloadFilename}
                        readOnly
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={6}>
                      <div className="form-check my-2">
                        <Input
                          className="form-check-input"
                          type="checkbox"
                          id="isLive"
                          name="isLive"
                          checked={filters.isLive}
                          onChange={filtersChngHndlr}
                        />
                        <Label
                          className="form-check-label text-nowrap"
                          htmlFor="isLive"
                        >
                          Live System
                        </Label>
                      </div>
                    </Col>
                  </Row>

                  <Row xs="12" md="12">
                    <Col
                      xs="12"
                      className="d-flex flex-row justify-content-center align-items-center"
                    >
                      <div className="d-flex flex-row justify-content-center">
                        <Button
                          color="primary"
                          className="w-100 mb-2 wh-btn mx-2"
                          outline
                          onClick={handleDownloadClick}
                        >
                          <span className="d-flex justify-content-center align-items-center">
                            <img
                              src={downloadIcon}
                              style={{ height: "16px", width: "16px" }}
                            />
                            <span className="ms-2 text-nowrap">Download</span>
                          </span>
                        </Button>
                        <Button
                          color="primary"
                          className="w-100 mb-2 wh-btn mx-2"
                          outline
                        >
                          <span className="d-flex justify-content-center align-items-center">
                            <img
                              src={shareIcon}
                              style={{ height: "16px", width: "16px" }}
                            />
                            <span className="ms-2 text-nowrap">
                              MSA Transfer
                            </span>
                          </span>
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
      <ApiLoader loading={getMsaFileReportLoading} />
    </>
  );
}
