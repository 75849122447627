import { useContext } from 'react';
import { Redirect } from 'react-router-dom';
//Context
import { PermissionsContext } from "../context/PermissionsContext"

const withPermission = (Component, requiredPermission) => {
  const WithPermission = (props) => {
    const {permissions, loadingPermissions} = useContext(PermissionsContext);
    // check if the user has the required permission
    if (!permissions[requiredPermission] && !loadingPermissions) {
      // redirect the user to a different page if they don't have the required permission
      return <Redirect to="/" />;
    }

    // render the component if the user has the required permission
    return <Component {...props} />;
  };

  return WithPermission;
};

export default withPermission;