import React, { useMemo, useEffect, useRef, useState, useCallback } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { Container, Row, Col, Label, Input, Modal, ModalHeader, Form, ModalBody } from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import { actions, generateColumns } from "./helper";
import BackIcon from "../../../assets/images/warehouse/menu/back.png";
import WhTableContainer from "../../../Components/Common/WhTables";
import ApiLoader from "../../../Components/Common/ApiLoader";
import { getStartDateFilterValue, getEndDateFilterValue } from "../../../utility/dateutility";
//Api Helper
import { generateApiUrl } from "../../../api/apihelper";
//Actions
import { getInvPricingReportPrintAct, getSuppliersReportAct } from "../../../store/reports/reportsactions";
import F12CloseModalListener from "../../../Components/Common/F12close";
import PrintItem from "./PrintItem";

const SupplierReport = ({ history }) => {
  const [showPrintModal, setShowPrintModal] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [filters, setFilters] = useState({
    status: "",
    isOutOfState: true,
    isLocalTaxesPaid: true,
    isPOActivity: true,
    poStartDate: "",
    poEndDate: "",
  });
  const [tableData, setTableData] = useState([]);
  function togglePrintModal() {
    setShowPrintModal(!showPrintModal);
  }
  const dispatch = useDispatch();
  const currentPageSize = useRef(30);
  const isDownloadBtnClicked = useRef(false);
  const { getSuppliersReportResp, getSuppliersReportLoading, reportsErrorResp } = useSelector(
    ({ reports }) => ({
      getSuppliersReportResp: reports.getSuppliersReportResp,
      getSuppliersReportLoading: reports.getSuppliersReportLoading,
      reportsErrorResp: reports.reportsErrorResp,
    }),
    shallowEqual
  );
  const customerResp = useRef({
    prevgetSuppliersReportResp: getSuppliersReportResp,
    prevReportsErrorResp: reportsErrorResp,
  });
  useEffect(() => {
    const { prevgetSuppliersReportResp, prevReportsErrorResp } = customerResp.current || {};
    if (getSuppliersReportResp && prevgetSuppliersReportResp !== getSuppliersReportResp) {
      if (isDownloadBtnClicked.current) {
        // const file = new File([getSuppliersReportResp], "suppliers_report.pdf", {
        //   type: "application/pdf",
        // });
        const url = window.URL.createObjectURL(new Blob([getSuppliersReportResp]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute(
              "download",
             "suppliers_report.csv"
            );
            document.body.appendChild(link);
            link.click();
            link.remove();
        // const url = window.URL.createObjectURL(new Blob([(downloadInvoicePdfResp)]));
        // const url = window.URL.createObjectURL(file);
        // window.open(url)
        // // const a = document.createElement("a");
        // a.href = url;
        // a.setAttribute("download", "suppliers_report.pdf");
        // a.target = "__blank";
        // document.body.appendChild(a);
        // a.click();
        // a.remove();
        isDownloadBtnClicked.current = false;
      } else {
        setTableData(getSuppliersReportResp?.data?.report || []);
        currentPageSize.current = getSuppliersReportResp?.data?.pageSize;
        setPageCount((getSuppliersReportResp?.data?.total))
      }
    } else if (reportsErrorResp && prevReportsErrorResp !== reportsErrorResp) {
      const { errors, error, message } = reportsErrorResp || {};
      toast.error((Array.isArray(errors) && errors[0].msg) || error || message);
    }
    customerResp.current.prevgetSuppliersReportResp = getSuppliersReportResp;
    customerResp.current.prevReportsErrorResp = reportsErrorResp;
  }, [getSuppliersReportResp, reportsErrorResp]);

  const getSuppliersReportApiCall = useCallback(
    (page, pageSize, download, downloadType = 'pdf') => {
      const { status, isOutOfState, isLocalTaxesPaid, poStartDate, poEndDate, isPOActivity } = filters;
      let params = {};
      if (!download) {
        params.page = page ? page + 1 : 1;
        params.pageSize = pageSize || currentPageSize.current;
      }
      if (status) params.status = status;
      if (poStartDate) params.poStartDate = getStartDateFilterValue(poStartDate);
      if (poEndDate) params.poEndDate = getEndDateFilterValue(poEndDate);
      if (isOutOfState) params.isOutOfState = isOutOfState;
      if (isLocalTaxesPaid) params.isLocalTaxesPaid = isLocalTaxesPaid;
      if (isPOActivity) params.isPOActivity = isPOActivity;
      if (download && downloadType === 'html') {
        params.download = true;
        params.downloadType = downloadType;
        // isDownloadBtnClicked.current = true;
        dispatch(
          getInvPricingReportPrintAct(
            generateApiUrl(`get_suppliers_report`),
            params
          )
        );
      } 
      else if (download){
        params.download = true;
        // params.downloadType = downloadType;
        isDownloadBtnClicked.current = true; 
        dispatch(
          getSuppliersReportAct(
            generateApiUrl(`get_suppliers_report`),
            params,
            {responseType: 'blob'}
          )
        );}
        else {
          dispatch(
            getSuppliersReportAct(
              generateApiUrl(`get_suppliers_report`),
              params
            )
          );
        }
    },
    [filters]
  );

  useEffect(() => {
    const debounceFn = setTimeout(() => {
      getSuppliersReportApiCall();
    }, 400);
    return () => {
      clearTimeout(debounceFn);
    };
  }, [getSuppliersReportApiCall]);

  const handlePageChange = (page, pageSize) => {
    getSuppliersReportApiCall(page, pageSize);
  };

  const filtersChngHndlr = (e) => {
    const { value, checked, name, type } = e.target;
    setFilters((prev) => ({ ...prev, [name]: type === "checkbox" ? checked : value }));
  };

  const resetFilters = () => {
    setFilters({
      status: "",
      isOutOfState: false,
      isLocalTaxesPaid: false,
      isPOActivity: false,
      poStartDate: "",
      poEndDate: "",
    })
  }

  const columns = useMemo(() => generateColumns(), []);
  return (
  <>
    <Container fluid className=" pt-2 pb-0">
      <ToastContainer />
      <Row>
        <Col xs="12" className="mb-0 m-0">
         <div className="px-3 m-0 mb-3 pt-1 border rounded-1 report-header" style={{display:"inline-block"}}>
          <h3 style={{color:'#fff'}}>Supplier Report</h3>
         </div>
          <div className="border ps-4 my-1" style={{ position: "relative" }}>
            <Label className="text-dark fs-5" style={{ position: "absolute", transform: "translateY(-50%)" }}>
            <span className=" px-1 wh-item-bg"> Filters</span>
            </Label>
            <Row>
              <Col xs="6" className="border-end pe-5">
                <div className="form-check my-1 d-flex justify-content-end">
                  <Input
                    className="form-check-input"
                    type="checkbox"
                    id="isPOActivity"
                    name="isPOActivity"
                    checked={filters.isPOActivity}
                    onChange={filtersChngHndlr}
                  />
                  <Label className="form-check-label text-nowrap ms-2" htmlFor="isPOActivity">
                    PO Activities Within
                  </Label>
                </div>
                <Row className="my-1">
                  <Col xs={2} className="d-flex align-items-center">
                    <Label htmlFor="supplierStatus" className="form-label text-nowrap">
                      Status :
                    </Label>
                  </Col>
                  <Col xs={4}>
                    <select
                      className="form-select py-1"
                      id="supplierStatus"
                      name="status"
                      value={filters.status}
                      onChange={filtersChngHndlr}
                    >
                      <option value="">All</option>
                      <option value="active">Active</option>
                      <option value="non-active">Non-Active</option>
                    </select>
                  </Col>
                  <Col xs={2} className="d-flex align-items-center ps-xxl-5">
                    <Label htmlFor="poStartDate" className="form-label text-nowrap">
                      Date From :
                    </Label>
                  </Col>
                  <Col xs={4}>
                    <Input
                      className="form-control py-1"
                      type="date"
                      id="poStartDate"
                      name="poStartDate"
                      value={filters.poStartDate}
                      onChange={filtersChngHndlr}
                    />
                  </Col>
                </Row>

                <Row className="mb-3">
                  <Col xs={2} className="d-flex align-items-center">
                    <Label htmlFor="companyName" className="form-label text-nowrap">
                      Type :
                    </Label>
                  </Col>
                  <Col xs={4}>
                    <select className="form-select py-1">
                      <option>Select Type</option>
                    </select>
                  </Col>
                  <Col xs={2} className="d-flex align-items-center ps-xxl-5">
                    <Label htmlFor="poEndDate" className="form-labe text-nowrap">
                      Date To :
                    </Label>
                  </Col>
                  <Col xs={4}>
                    <Input
                      className="form-control py-1"
                      type="date"
                      id="poEndDate"
                      name="poEndDate"
                      value={filters.poEndDate}
                      onChange={filtersChngHndlr}
                    />
                  </Col>
                </Row>
              </Col>
              <Col xs="6" className="ps-5">
                <Row>
                  <Col xs="4" xxl="5">
                    <div className="form-check my-1">
                      <Input
                        className="form-check-input"
                        type="checkbox"
                        id="isOutOfState"
                        name="isOutOfState"
                        checked={filters.isOutOfState}
                        onChange={filtersChngHndlr}
                      />
                      <Label className="form-check-label text-nowrap" htmlFor="isOutOfState">
                        Out of State
                      </Label>
                    </div>
                    <div className="form-check mb-3">
                      <Input
                        className="form-check-input"
                        type="checkbox"
                        id="isLocalTaxesPaid"
                        name="isLocalTaxesPaid"
                        checked={filters.isLocalTaxesPaid}
                        onChange={filtersChngHndlr}
                      />
                      <Label className="form-check-label text-nowrap" htmlFor="isLocalTaxesPaid">
                        Local Taxes Paid
                      </Label>
                    </div>
                    <div className="form-check mb-2">
                      <Input className="form-check-input" type="checkbox" id="formCheck6" defaultChecked />
                      <Label className="form-check-label text-nowrap" htmlFor="formCheck6">
                        Report to State
                      </Label>
                    </div>
                  </Col>
                  <Col xs="8" xxl="7" className="d-flex align-items-center pe-5">
                    <div className="sale-wh-wrapper flex-grow-1 mt-0 me-4">
                      <div className="d-flex justify-content-around border border-section py-2 h-100">
                        {actions(togglePrintModal,getSuppliersReportApiCall,resetFilters).map((item) => {
                          return (
                            <div
                              key={item.id}
                              className="action-icon-container"
                              onClick={item.clickHandler || (() => {})}
                              style={{cursor:"pointer"}}
                            >
                              <img className="action-icon" src={item.icon} />
                              <span>{item.label}</span>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                    <div
                      className="d-flex flex-column align-items-center"
                      style={{ cursor: "pointer" }}
                      onClick={() => history.push("/reportsmenu")}
                    >
                      <img src={BackIcon} width="32" height="32" alt="back-icon" />
                      <div className="text-decoration-underline mb-0">Go Back</div>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </Col>
        <Col xs="12">
          <WhTableContainer
            columns={columns}
            data={tableData || []}
            className="custom-header-css"
            divClass="table-responsive table-container-suplierreport"
            tableClass="align-middle table-bordered"
            theadClass="table-light"
            handlePageChange={handlePageChange}
            pageCount={pageCount || 0}
            customPageSize={30}
            showNoDataComponent={true}
          />
        </Col>
      </Row>
      <ApiLoader loading={getSuppliersReportLoading} />
    </Container>
    <Modal
     id="showPrintModal"
     isOpen={showPrintModal}
     toggle={togglePrintModal}
     centered
     size="lg"
   >
     <F12CloseModalListener onClose={togglePrintModal} />
     <ModalHeader className="py-2 px-3" toggle={togglePrintModal}>
       Print Options
     </ModalHeader>
     <Form
       onSubmit={(e) => {
         e.preventDefault();
       }}
     >
       <ModalBody className="px-0 pt-0">
         <PrintItem
           getSuppliersReportApiCall={getSuppliersReportApiCall}
           filters={filters}
           showPrintModal={showPrintModal}
         />
       </ModalBody>
     </Form>
   </Modal>
  </>
  );
};

export default SupplierReport;
