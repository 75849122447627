import React, { useMemo } from "react";
import WhTableContainer from "../WhTables";
//Actions
import { getInvoicesOfCustomerAct } from "../../../store/customer/customeractions";
//Helper
import { generateApiUrl } from "../../../api/apihelper";
import { generateColumns } from "./helper";

const UnpaidInvoicesTab = ({tableData, dispatch, getInvoicesOfCustomerResp, customerNumber, invoicesForPayOrder, setInvoicesForPayOrder}) => {
  const handlePageChange = (page, pageSize) => {
    dispatch(
      getInvoicesOfCustomerAct(generateApiUrl("get_invoices_of_customer"), {
        customerNumber: customerNumber,
        status: "unpaid",
        page: page + 1,
        pageSize,
      })
    );
  };
  const columns = useMemo(() => generateColumns(invoicesForPayOrder, setInvoicesForPayOrder), [invoicesForPayOrder, setInvoicesForPayOrder]);
  return (
    <WhTableContainer
      columns={columns}
      data={tableData}
      className="custom-header-css"
      divClass="table-responsive mt-3"
      tableClass="align-middle table-bordered"
      theadClass="table-light"
      handlePageChange={handlePageChange}
      pageCount={Math.ceil((getInvoicesOfCustomerResp?.data?.total || 0) / 10)}
      customPageSize={30}
      showNoDataComponent={true}
    />
  );
};

export default UnpaidInvoicesTab;
