import React, { useMemo, useState, useRef, useEffect, useCallback } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { Col, Container, Input, Label, Row, Modal, ModalHeader, ModalBody, Form } from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import WhTableContainer from "../../Components/Common/WhTables";
import { actions, generateColumns, inventoryItemColumnsList } from "./helper";
import DeleteModal from "../../Components/Common/DeleteModal";
import SearchInventory from "./searchinventory";
import SelectItem from "../../Components/Common/SelectItem";
import PaymentsAnywhere from "../../Components/Common/paymentsanywhere";
import ApiLoader from "../../Components/Common/ApiLoader";
//Api Helper
import { generateApiUrl } from "../../api/apihelper";
//Actions
import { deleteInventoryItemAct, getInventoryListAct} from "../../store/inventory/inventoryactions";
//HOC
import withPermission from "../../hoc/withPermission"
import { useLocation } from "react-router-dom";

function WarehouseInventory({ history }) {
  const [tableData, setTableData] = useState(null);
  const [modalTableData, setModalTableData] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [modalPageCount, setModalPageCount] = useState(0);
  const [rowSelection, setRowSelection] = useState({});
  const [showSearchModal, setShowSearchModal] = useState(false);
  function toggleSearchModal() {
    setShowSearchModal(!showSearchModal);
  }
  const [search, setSearch] = useState("");
  const [searchListOptions, setSearchListOptions] = useState([]);
  const [filters, setFilters] = useState({
    search: "",
    searchBy: "description",
  });
  const resetFilters = () => {
    setFilters({
      search: "",
      searchBy: "description",
    });
  };
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const toggleDeleteModal = () => setShowDeleteModal(!showDeleteModal);
  const dispatch = useDispatch();

  const dropdownBtnClicked = useRef(false);
  const currentInventoryListPage = useRef(1);
  const callbackOnInventoryListApiResp = useRef(() => {});

  const {
    getInventoryListResp,
    deleteInventoryItemResp,
    deleteBundleItemResp,
    inventoryErrorResp,
    inventoryApiLoading,
    getInventoryListLoading,
  } = useSelector(
    ({ inventory }) => ({
      getInventoryListResp: inventory.getInventoryListResp,
      deleteInventoryItemResp: inventory.deleteInventoryItemResp,
      deleteBundleItemResp: inventory.deleteBundleItemResp,
      inventoryErrorResp: inventory.inventoryErrorResp,
      inventoryApiLoading: inventory.inventoryApiLoading,
      getInventoryListLoading: inventory.getInventoryListLoading,
    }),
    shallowEqual
  );
  const columns = useMemo(() => generateColumns(), []);
  const inventoryResp = useRef({
    prevGetInventoryListResp: getInventoryListResp,
    prevDeleteInventoryItemResp: deleteInventoryItemResp,
    prevDeleteBundleItemResp: deleteBundleItemResp,
    prevInventoryErrorResp: inventoryErrorResp,
  });
  useEffect(() => {
    const { prevGetInventoryListResp, prevDeleteInventoryItemResp, prevDeleteBundleItemResp, prevInventoryErrorResp } =
      inventoryResp.current || {};
    if (getInventoryListResp && prevGetInventoryListResp !== getInventoryListResp) {
      const list = [];
      (getInventoryListResp?.data?.inventories || []).forEach(({ itemNumber, description, category }) => {
        list.push({
          itemNumber,
          itemName: description,
          category,
        });
      });
      if (!dropdownBtnClicked.current) {
        if (!showSearchModal) {
          setTableData(getInventoryListResp?.data?.inventories || []);
          setPageCount(getInventoryListResp?.data?.total);
        } else {
          setModalTableData(getInventoryListResp?.data?.inventories || []);
          setModalPageCount(getInventoryListResp?.data?.total);
        }
        setSearchListOptions(list);
      } else {
        setSearchListOptions((prev) => [...prev, ...list]);
        if (getInventoryListResp?.data?.inventories?.length > 0) callbackOnInventoryListApiResp.current();
        currentInventoryListPage.current = Number(getInventoryListResp?.data?.page) + 1;
      }
    }
    if (deleteInventoryItemResp && prevDeleteInventoryItemResp !== deleteInventoryItemResp) {
      toast.success("Successfully deleted inventory items");
      getAllInventoryApiCall();
      toggleDeleteModal();
    }
    if (deleteBundleItemResp && prevDeleteBundleItemResp !== deleteBundleItemResp) {
      toast.success("Successfully deleted Bundles");
      getAllInventoryApiCall();
      toggleDeleteModal();
    }
    if (inventoryErrorResp && prevInventoryErrorResp !== inventoryErrorResp) {
      const { errors, error, message } = inventoryErrorResp || {};
      toast.error((Array.isArray(errors) && errors[0] && errors[0].msg) || error || message);
    }
    inventoryResp.current.prevGetInventoryListResp = getInventoryListResp;
    inventoryResp.current.prevDeleteInventoryItemResp = deleteInventoryItemResp;
    inventoryResp.current.prevDeleteBundleItemResp = deleteBundleItemResp;
    inventoryResp.current.prevInventoryErrorResp = inventoryErrorResp;
  }, [getInventoryListResp, deleteInventoryItemResp, deleteBundleItemResp, inventoryErrorResp]);
  const getAllInventoryApiCall = useCallback(
    (page, pageSize, showAllSuppliers, currentInventoryListPage) => {
      let params = {
        page: page ? page + 1 : 1,
        pageSize: pageSize || 30,
      };
      if (showAllSuppliers) {
        params = {
          page: currentInventoryListPage || 1,
          pageSize: pageSize || 30,
        };
        dropdownBtnClicked.current = true;
      } else if (search && isNaN(Number(search))) {
        params.description = search;
        dropdownBtnClicked.current = false;
      } else if (search && !isNaN(Number(search))) {
        params.itemNumber = search;
        dropdownBtnClicked.current = false;
      } else dropdownBtnClicked.current = false;
      dispatch(getInventoryListAct(generateApiUrl(`get_inventory_list`), params));
    },
    [search]
  );

  useEffect(() => {
    if (history.location?.state?.showCreateInventoryItemSuccess) {
      toast.success("Successfully saved the inventory details");
      history.replace();
    } else if (history.location?.state?.showDeleteInventoryItemSuccess) {
      toast.success("Successfully deleted inventory item");
      history.replace();
    }
  }, []);

  useEffect(() => {
    const debounceFn = setTimeout(() => {
      getAllInventoryApiCall();
    }, 400);
    return () => {
      clearTimeout(debounceFn);
    };
  }, [getAllInventoryApiCall]);
  const filtersChngHndlr = (e) => {
    const { name, value } = e.target;
    setFilters((prev) => ({ ...prev, [name]: value }));
  };
  const handlePageChange = (page, pageSize) => {
    getAllInventoryApiCall(page, pageSize);
  };
  const handleDeleteInventoryItem = () => {
    // const payload = [];
    // getInventoryListResp?.data?.inventories
    //   .filter((item, index) => rowSelection[index])
    //   .forEach((item, index) => payload.push(item.itemNumber));
      if (path.startsWith("/warehouseinventory/bundles")) {
      // dispatch(deleteBundleItemAct(generateApiUrl("delete_bundle" ),{ ids: payload }));
      // history.push("/warehouseinventory/bundles")
      return;
      }
      if (!path.startsWith("/warehouseinventory/bundles")) {
        const payload = [];
        getInventoryListResp?.data?.inventories
        .filter((item, index) => rowSelection[index])
       .forEach((item, index) => payload.push(item.itemNumber));
        dispatch(deleteInventoryItemAct(generateApiUrl("delete_inventory_item"), { ids: payload }));
        return;
        }
  };
  function selectItemCallbck(itemDetails) {
    const { itemNumber } = itemDetails;
    history.push(`/warehouseinventory/${itemNumber}`);
  }
  const noOfRowsSelected = Object.keys(rowSelection).length;
  const location = useLocation();
  const path = location.pathname;
  return (
    <React.Fragment>
      <ToastContainer />
      <div className="sale-wh-wrapper whbrand-text-color">
        <Container fluid >
          <Row className="px-4 mb-0">
            <Col xs="12">
              <Row>
                <Col xl="3" md="4" xs="4" className="border-section p-3 py-0">
                  <Label htmlFor="userrole" className="form-label mb-1">
                    Search Inventory Item
                  </Label>
                  <SelectItem
                    searchValue={search}
                    setSearchValue={setSearch}
                    listOptions={searchListOptions}
                    setSearchListOptions={setSearchListOptions}
                    columnsList={inventoryItemColumnsList}
                    placeholder="Search Inventory Item"
                    selectedItemHandler={selectItemCallbck}
                    fieldKey="itemName"
                    dataIdKey="itemNumber"
                    showDropdownBtn={true}
                    dropdownBtnClickHndlr={(setShowOptions) => {
                      getAllInventoryApiCall(null, null, true);
                      setShowOptions(true);
                    }}
                    callbackAfterBottomReached={(fn) => {
                      getAllInventoryApiCall(null, null, true, currentInventoryListPage.current);
                      callbackOnInventoryListApiResp.current = fn;
                    }}
                    inputClassname='py-0 whbrand-bg whbrand-text-color'
                    btnClassname={'py-0 whbrand-text-color border-light'}
                  />
                </Col>
                <Col xl="5" lg="6" xs="8">
                  <div className="d-flex justify-content-center border border-section py-2 px-1 h-100">
                    {actions(history, toggleDeleteModal, resetFilters, toggleSearchModal).map((item) => {
                      return (
                        <div
                          key={item.id}
                          className={`action-icon-container ${
                            item.id === "delete" && noOfRowsSelected === 0
                              ? "show-cursor-not-allowed opacity-50"
                              : "show-cursor-pointer"
                          }`}
                          onClick={item.id === "delete" && noOfRowsSelected === 0 ? () => {} : item.clickHandler}
                        >
                          {item.id === "smart_search" ? item.icon : <img className="action-icon" src={item.icon} />}
                          <span className="text-nowrap">{item.label}</span>
                        </div>
                      );
                    })}
                  </div>
                </Col>
              </Row>
              {history.location?.state?.redirected && (
                <div className="d-flex mt-3">
                  <div
                    className="d-flex align-items-center text-primary fs-5"
                    style={{ cursor: "pointer" }}
                    onClick={history.goBack}
                  >
                    <i className="ri-arrow-left-line me-1"></i>
                    <div className="mb-0 text-decoration-underline">Go Back</div>
                  </div>
                </div>
              )}
            </Col>
          </Row>
          <Row className="m-0">
            <Col className="px-0" xs={12}>
              <WhTableContainer
                columns={columns}
                data={tableData || []}
                className="custom-header-css"
                divClass="table-responsive mt-3 whbrand-table table-container "
                tableClass="align-middle"
                theadClass="table-light"
                handlePageChange={handlePageChange}
                pageCount={pageCount || 0}
                customPageSize={30}
                showNoDataComponent={true}
                showRowSelection={true}
                setRowSelection={setRowSelection}
                loading={(getInventoryListLoading || !tableData) && !showSearchModal}
              />
            </Col>
          </Row>
        </Container>
      </div>
      <Modal id="showSearchModal" isOpen={showSearchModal} toggle={toggleSearchModal} centered size="lg">
        <ModalHeader className="py-2 px-3" toggle={toggleSearchModal}>
          Smart Search-Inventory
        </ModalHeader>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <ModalBody className="px-0 pt-0">
            <SearchInventory
              setFilters={setFilters}
              setShowSearchModal={setShowSearchModal}
              modalTableData={modalTableData}
              modalPageCount={modalPageCount || 0}
              getInventoryListAct={getInventoryListAct}
              generateApiUrl={generateApiUrl}
              dispatch={dispatch}
              getInventoryListLoading={getInventoryListLoading}
            />
          </ModalBody>
        </Form>
      </Modal>
      <DeleteModal show={showDeleteModal} onCloseClick={toggleDeleteModal} onDeleteClick={handleDeleteInventoryItem} />
      <PaymentsAnywhere />
      <ApiLoader loading={inventoryApiLoading} />
    </React.Fragment>
  );
}
export default withPermission(WarehouseInventory, "INVENTORY_MENU");
