import PrintIcon from "../../../assets/images/warehouse/salespage/printer.png";
import GenerateIcon from "../../../assets/images/warehouse/salespage/generate.png";
import ExportIcon from "../../../assets/images/warehouse/salespage/excel.png";
import ResetIcon from "../../../assets/images/warehouse/salespage/reset.png";
import { getMmddyyyyDateFormatted } from "../../../utility/dateutility";
import { formatAmount } from "../../../utility/commonutility";
import { Link } from "react-router-dom";
export const actions = (togglePrintModal, getSuppliersReportApiCall,resetFilters) => [
  {
    id: "reset",
    label: "Reset",
    icon: ResetIcon,
    clickHandler: resetFilters
  },
  {
    id: "print",
    label: "Print",
    icon: PrintIcon,
    clickHandler:togglePrintModal
  },
  {
    id: "export",
    label: "Export",
    icon: ExportIcon,
    clickHandler: () => getSuppliersReportApiCall(0, 10, true),
  },
];

export function generateColumns() {
  return [
    {
      Header: "Invoice No",
      accessor: "invoiceNumber",
      Cell:({cell:{value}}) => <Link target="_blank" to={`/warehousesales/${value}`}>{value}</Link>
    },
    {
      Header: "Invoice date",
      accessor: "invoiceDate",
      Cell: ({ cell: { value } }) => (value ? getMmddyyyyDateFormatted(value) : ""),
    },
    {
      Header: "Customer No",
      accessor: "soldToCustomer",
      Cell:({cell:{value}}) => <Link target="_blank" to={`/warehousecustomers/${value}`}>{value}</Link>,
    },
    {
      Header: "Item Num",
      accessor: "itemNumber",
      Cell:({cell:{value}}) => <Link target="_blank" to={`/warehouseinventory/${value}`}>{value}</Link>
  },
  {
      Header: "Item Name",
      accessor: "description",
      Cell:({row:{original:{itemNumber, description}}}) => <Link target="_blank" to={`/warehouseinventory/${itemNumber}`}>{description}</Link>
  }, 
    {
      Header: "Quantity",
      accessor: "quantity",
    },
    {
      Header: "Amount",
      accessor: "rAmount",
      Cell: ({ cell: { value } }) => formatAmount(value),
    },
  ];
}
export const customerColumnsList = [
  {
    id: "customerNumber",
    name: "Cust. Num",
    cellClassName: "fw-medium",
  },
  {
    id: "companyName",
    name: "Company",
  },
  {
    id: "customerName",
    name: "Customer Name",
    cellClassName: "fbadge badge-soft-primary",
  },
];
export const tableData = [
  {
    id: "1503",
    item: "New Salem Conv Store",
    description: "Debit Card",
    quantity: "",
    caseQuantity: "05-05-2022",
    amount: "09 : 05 AM",
    received: "$300.90",
    amountAllocated: "$300.90",
    change: "$0.00",
  },
  {
    id: "1503",
    item: "New Salem Conv Store",
    description: "Debit Card",
    quantity: "",
    caseQuantity: "05-05-2022",
    amount: "09 : 05 AM",
    received: "$300.90",
    amountAllocated: "$300.90",
    change: "$0.00",
  },
  {
    id: "1503",
    item: "New Salem Conv Store",
    description: "Debit Card",
    quantity: "",
    caseQuantity: "05-05-2022",
    amount: "09 : 05 AM",
    received: "$300.90",
    amountAllocated: "$300.90",
    change: "$0.00",
  },
  {
    id: "1503",
    item: "New Salem Conv Store",
    description: "Debit Card",
    quantity: "",
    caseQuantity: "05-05-2022",
    amount: "09 : 05 AM",
    received: "$300.90",
    amountAllocated: "$300.90",
    change: "$0.00",
  },
  {
    id: "1503",
    item: "New Salem Conv Store",
    description: "Debit Card",
    quantity: "",
    caseQuantity: "05-05-2022",
    amount: "09 : 05 AM",
    received: "$300.90",
    amountAllocated: "$300.90",
    change: "$0.00",
  },
  {
    id: "1503",
    item: "New Salem Conv Store",
    description: "Debit Card",
    quantity: "",
    caseQuantity: "05-05-2022",
    amount: "09 : 05 AM",
    received: "$300.90",
    amountAllocated: "$300.90",
    change: "$0.00",
  },
  {
    id: "1503",
    item: "New Salem Conv Store",
    description: "Debit Card",
    quantity: "",
    caseQuantity: "05-05-2022",
    amount: "09 : 05 AM",
    received: "$300.90",
    amountAllocated: "$300.90",
    change: "$0.00",
  },
];
