import PropTypes from 'prop-types';
import React, { useState, useEffect, useRef } from 'react';
import {Modal, ModalBody, ModalHeader} from 'reactstrap';
import F12CloseModalListener from './F12close';

const ConfirmModal = ({
  show,
  onConfirmClick,
  onCloseClick,
  type,
  title = '',
  confirmationText = '',
  centered = true,
}) => {
  // const [isModalOpen, setIsModalOpen] = useState(false);
  const [focusedButton, setFocusedButton] = useState("yes"); 

  const yesButtonRef = useRef("");
  const noButtonRef = useRef("");

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (show && (e.key === "ArrowRight")) {
        e.preventDefault();
        setFocusedButton((prevButton) => (prevButton === "yes" ? "no" : "yes"));
      }
      if (show && (e.key === "ArrowLeft")) {
        e.preventDefault();
        setFocusedButton((prevButton) => (prevButton === "no" ? "yes" : "no"));
      }
    };
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    }
  }, [show])

  useEffect(() => {
    if (show) {
      const buttonRef = focusedButton === 'yes' ? yesButtonRef : noButtonRef;
      setTimeout(() => {
        if (buttonRef.current) {
          buttonRef.current.focus();
        }
      }, 100);
    }
  }, [show, focusedButton, yesButtonRef, noButtonRef]);
  



  return (
    <Modal isOpen={show} toggle={onCloseClick} centered={centered}>
      <F12CloseModalListener onClose={onCloseClick} />
      {title &&
        <ModalHeader className="border-bottom py-2">{title}</ModalHeader>}
      <ModalBody className="py-0 pb-2">
        <div className="mt-2">
          <div className="pt-2 fs-15">
            {/* <h4>Are you sure ?</h4> */}
            <p className="text-muted mb-0">
              {confirmationText
                ? confirmationText
                : `Are you sure you want to ${type === 'approve' ? 'approve the request' : type === 'reject' ? 'reject the request' : 'continue'}?`}
            </p>
          </div>
        </div>
        <div className="d-flex gap-2 justify-content-end mt-2 mb-2">
          <button
           ref={yesButtonRef}
            type="button"
            className= {`btn w-sm  ${focusedButton === "yes" ? "btn-primary border border-danger" : "btn-light"}`}
            id="delete-record"
            onClick={onConfirmClick}
            onKeyDown={(e) => {
              if (e.key === "ArrowLeft") {
                e.preventDefault();
                setFocusedButton("no");
              }
            }}
          >
            Yes
          </button>
          <button
          ref={noButtonRef}
            type="button"
            className={`btn w-sm  ${focusedButton === "no" ? "btn-primary border border-danger" : "btn-light"}`}
            data-bs-dismiss="modal"
            onClick={onCloseClick}
            onKeyDown={(e) => {
              if (e.key === "ArrowRight") {
                e.preventDefault();
                setFocusedButton("yes");
              }
            }}
          >
            No
          </button>
        </div>
      </ModalBody>
    </Modal>
  );
};

ConfirmModal.propTypes = {
  onCloseClick: PropTypes.func,
  onConfirmClick: PropTypes.func,
  show: PropTypes.any,
  type: PropTypes.any,
};

export default ConfirmModal;
