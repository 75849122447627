import React, { useMemo, useState, useEffect, useRef, useCallback } from "react";
import { Col, Container, Row, Button } from "reactstrap";
import WhTableContainer from "../../../../Components/Common/WhTables";
import DeleteModal from "../../../../Components/Common/DeleteModal";
import { generateColumns, mapSpecialPrices } from "../Financial/specialpriceshelper";
//Actions
import { getSpecialPricesAct, createCustomerInventoryPriceAct } from "../../../../store/inventory/inventoryactions";
import { getAllCustomersAct, deleteSpecialPriceForCustomerAct } from "../../../../store/customer/customeractions";

export default function AddNewItem({
  dispatch,
  generateApiUrl,
  getSpecialPricesResp,
  id,
  getAllCustomersResp,
  toggleSpecialPricesModal,
  deleteSpecialPriceForCustomerResp,
  toast
}) {
  const [specialPrices, setSpecialPrices] = useState([]);
  const [showPriceDeleteConfirm,setShowPriceDeleteConfirm] = useState(false);
  const togglePriceDeleteConfirm = () => setShowPriceDeleteConfirm(!showPriceDeleteConfirm);
  const debounceFn = useRef(null);
  const detailsOfPriceToBeDeleted = useRef({id:"",index:""});
  const currentSelectItem = useRef({
    index: "",
    key: "",
  });
  useEffect(() => {
    if (id !== "new") dispatch(getSpecialPricesAct(generateApiUrl("get_special_prices"), { itemNumber: id }));
  }, []);
  const prevGetSpecialPricesResp = useRef(getSpecialPricesResp);
  const prevGetAllCustomersResp = useRef(getAllCustomersResp);
  const prevDeleteSpecialPriceForCustomerResp = useRef(deleteSpecialPriceForCustomerResp);
  useEffect(() => {
    if (getSpecialPricesResp && getSpecialPricesResp !== prevGetSpecialPricesResp.current) {
      mapSpecialPrices(getSpecialPricesResp, setSpecialPrices);
    } else if (getAllCustomersResp && getAllCustomersResp !== prevGetAllCustomersResp.current) {
      setSpecialPrices((prev) => {
        const updatedSpecialPrices = [...prev],
          { index, key } = currentSelectItem.current,
          rowData = { ...updatedSpecialPrices[index] },
          listOptions = [];
        (getAllCustomersResp?.data?.customers || []).forEach((item) => {
          const { customerNumber, companyName, firstName, lastName } = item;
          listOptions.push({
            customerNumber,
            companyName,
            customerName: `${firstName || ""} ${lastName || ""}`,
          });
        });

        updatedSpecialPrices.splice(index, 1, {
          ...rowData,
          [key === "customerNumberSearch" ? "customerNumberListOptions" : "companyListOptions"]: listOptions,
        });
        return updatedSpecialPrices;
      });
    } else if (deleteSpecialPriceForCustomerResp && deleteSpecialPriceForCustomerResp !== prevDeleteSpecialPriceForCustomerResp.current) {
      toast.success("Successfully deleted the Customer Special Price");
      setSpecialPrices( prev => {
        const updatedSpecialPrices = [...prev];
        updatedSpecialPrices.splice(detailsOfPriceToBeDeleted.current.index,1);
        detailsOfPriceToBeDeleted.current = {id:"",index:""};
        return updatedSpecialPrices;
      });
      togglePriceDeleteConfirm();
    } 
    prevGetSpecialPricesResp.current = getSpecialPricesResp;
    prevGetAllCustomersResp.current = getAllCustomersResp;
    prevDeleteSpecialPriceForCustomerResp.current = deleteSpecialPriceForCustomerResp;
  }, [getSpecialPricesResp, getAllCustomersResp, deleteSpecialPriceForCustomerResp]);
  const getAllCustomersApiCall = useCallback((key, value) => {
    let params = {
      page: 1,
      pageSize: 5,
      [key]: value,
    };
    dispatch(getAllCustomersAct(generateApiUrl(`get_all_customers`), params));
  }, []);
  useEffect(() => {
    return () => {
      clearTimeout(debounceFn.current);
    };
  }, []);
  const addNewRow = () => {
    setSpecialPrices((prev) => [
      ...prev,
      {
        customerNumber: "",
        company: "",
        customerPrice: "",
      },
    ]);
  };
  const handleRowDelete = (id,index) => {
    if(!id) {
      setSpecialPrices( prev => {
        const updatedSpecialPrices = [...prev];
        updatedSpecialPrices.splice(index,1);
        return updatedSpecialPrices;
      });
    } else {
      togglePriceDeleteConfirm();
      detailsOfPriceToBeDeleted.current = {
        id,
        index
      };
    }
  }
  const handlePriceDelete = () => {
    dispatch(deleteSpecialPriceForCustomerAct(generateApiUrl('delete_special_price_for_customer'),{priceIds:[detailsOfPriceToBeDeleted.current.id]}));
  }
  function selectItemHndlr(itemDetails, index) {
    const { customerNumber, companyName } = itemDetails;
    setSpecialPrices((prev) => {
      const updatedSpecialPrices = [...prev],
        rowData = { ...updatedSpecialPrices[index] };
      updatedSpecialPrices.splice(index, 1, {
        ...rowData,
        customerNumber,
        company: companyName,
      });
      return updatedSpecialPrices;
    });
  }
  const searchValueChngHndlr = (value, index, key) => {
    setSpecialPrices((prev) => {
      const updatedSpecialPrices = [...prev],
        rowData = { ...updatedSpecialPrices[index] };
      updatedSpecialPrices.splice(index, 1, {
        ...rowData,
        [key]: value,
      });
      debounceFn.current = setTimeout(() => {
        getAllCustomersApiCall(key === "customerNumberSearch" ? "customerNumber" : "company", value);
      }, 400);
      currentSelectItem.current = {
        key,
        index,
      };
      return updatedSpecialPrices;
    });
  };
  const handleCustomerPriceChange = (e, index) => {
    const { value } = e.target;
    setSpecialPrices((prev) => {
      const updatedSpecialPrices = [...prev],
        rowData = { ...updatedSpecialPrices[index] };
      updatedSpecialPrices.splice(index, 1, {
        ...rowData,
        customerPrice: value,
      });
      return updatedSpecialPrices;
    });
  };
  const handleCustomerPriceBlur = (index) => {
    setSpecialPrices((prev) => {
      const updatedSpecialPrices = [...prev],
        rowData = { ...updatedSpecialPrices[index] };
      updatedSpecialPrices.splice(index, 1, {
        ...rowData,
        customerPrice: Number(rowData.customerPrice).toFixed(2),
      });
      return updatedSpecialPrices;
    });
  };
  const saveCustomerSpecialPrice = () => {
    const payload = [];
    specialPrices.forEach((item) => {
      const { customerNumber, customerPrice } = item;
      if (!item.id && customerNumber && customerPrice) payload.push({ customerNumber, newPrice: customerPrice });
    });
    if (payload.length > 0)
      dispatch(
        createCustomerInventoryPriceAct(generateApiUrl("create_customer_inventory_price", { itemNumber: id }), {
          customers: payload,
        })
      );
    else toggleSpecialPricesModal();
  };
  const columns = useMemo(() => generateColumns(selectItemHndlr, searchValueChngHndlr, handleCustomerPriceChange, handleRowDelete, handleCustomerPriceBlur), []);
  return (
    <Container fluid>
      <Row className="">
        <Col xs={12} className="bg-primary text-center fs-5 text-white py-2">
          Customer Special Price
        </Col>
      </Row>
      <Row>
        <Col xs={12} className="px-0">
          <WhTableContainer
            columns={columns}
            data={specialPrices}
            customPageSize={7}
            className="custom-header-css"
            divClass="table-responsive overflow-visible"
            tableClass="align-middle table-bordered"
            theadClass="table-light"
          />
        </Col>
        <Col xs="12">
          <Button color="primary" outline onClick={addNewRow}>
            + Add New
          </Button>
        </Col>
      </Row>
      <div className="d-flex justify-content-center">
        <Button color="primary" className="me-2 d-flex align-items-center" onClick={saveCustomerSpecialPrice} outline>
          {" "}
          <i className="ri-save-line fs-5 me-1"></i> Save
        </Button>
        <Button color="danger" className="d-flex align-items-center" outline onClick={toggleSpecialPricesModal}>
          {" "}
          <i className="ri-close-circle-line fs-5 me-1"></i> Close
        </Button>
      </div>
      <DeleteModal show={showPriceDeleteConfirm} onCloseClick={togglePriceDeleteConfirm} onDeleteClick={handlePriceDelete}/>
    </Container>
  );
}
