import React, { useRef, useState, useEffect } from "react";
import { Input } from "reactstrap";
import { useSelector, shallowEqual } from "react-redux";
import moment from "moment";
export default function ClickedInput({
  itemId,
  inputType,
  placeholder,
  value = "",
  itemKey,
  fieldChangeHndlr,
  index,
  icon,
  iconRightAlign = false,
  prefix,
  suffix,
  containerClass = "",
  spanPlaceholder = "",
  onKeyDown = () => {},
  inputMin,
  inputMax,
  reduxDetails,
  callback,
  searchBy,
  selectedItemHandler,
  afterSelectedItemhandlerCallback = () => {},
  apiResponseKey,
  spanClassName,
  addObserver = false,
  allowEdit,
  innerInputClass="",
  inputStyle,
}) {
  const [inputValue, setInputValue] = useState(value);
  const [showValueState, setShowValueState] = useState(false);
  const ref = useRef(null);
  const containerRef = useRef(null);
  const shouldCallSelectedItemHndlr = useRef(false);
  const reduxOptions = reduxDetails ? reduxDetails.split(".") : [];
  const { listResp } = useSelector(
    (state) => ({
      listResp: reduxOptions.length > 0 ? state[reduxOptions[0]][reduxOptions[1]] : "",
    }),
    shallowEqual
  );
  const apiResp = useRef({
    prevListResp: listResp,
  });
  useEffect(() => {
    const { prevlistResp } = apiResp.current || {};
    if (listResp && prevlistResp !== listResp && shouldCallSelectedItemHndlr.current ) {
       selectedItemHandler(listResp?.data[apiResponseKey][0],index,value);
       afterSelectedItemhandlerCallback();
       shouldCallSelectedItemHndlr.current = false;
    }
    apiResp.current.prevlistResp = listResp;
  }, [listResp]);
  const handleClickOutside = (e) => {
    if (ref.current && !ref.current.contains(e.target)) {
      let v;
      setInputValue((prev) => {
        v = prev;
        return prev;
      });
      setShowValueState((prev) => {
        if (prev === true) {
          if(fieldChangeHndlr){
            fieldChangeHndlr(itemKey, v, index);
          }
          if(callback) {
            callback({ [searchBy]: v });
            shouldCallSelectedItemHndlr.current = true;
          }
          return false;
        }
        return prev;
      });
    }
  };
  useEffect(() => {
    if(showValueState) document.addEventListener("mousedown", handleClickOutside, true);
    else document.removeEventListener("mousedown", handleClickOutside, true);
    return () => document.removeEventListener("mousedown", handleClickOutside, true);
  }, [showValueState]);
  function onClickHanlder() {
    if(allowEdit) {
      setInputValue(value);
      setShowValueState(true);
    }
  }
  useEffect(() => {
    if (showValueState) ref.current.focus();
  }, [showValueState]);
  function onChangeHandler(event) {
    const { name, value } = event.target;
    if (
      inputType === "number" && (inputMin || inputMin == "0") && (inputMax || inputMax == "0")
        ? Number(value) <= Number(inputMax) && Number(value) >= Number(inputMin)
        : true
    )
      setInputValue(value);
  }

  function onKeyDownHanlder(e) {
    onKeyDown(e);
    if (e.key === "Enter") {
      setShowValueState(false);
      if(fieldChangeHndlr){
        fieldChangeHndlr(itemKey, inputValue, index);
      }
      if(callback) {
        callback({ [searchBy]: inputValue });
        shouldCallSelectedItemHndlr.current = true;
      }
    } else {
      return;
    }
  }

  useEffect(() => {
    let observer;
    if (addObserver) {
      const element = containerRef.current;
      // Create an observer that watches for changes in the className of the element
      observer = new MutationObserver((mutationsList) => {
        mutationsList.forEach((mutation) => {
          if (mutation.type === "attributes" && mutation.attributeName === "class") {
            if (element.className.includes("active")) setShowValueState(true);
          }
        });
      });
      // Start observing the element for changes in its attributes
      observer.observe(element, { attributes: true });
    }
    // Cleanup function to stop observing the element when the component unmounts
    return () => {
      if (addObserver) {
        observer.disconnect();
      }
    };
  }, []);

  useEffect(() => {
    if (!showValueState) {containerRef.current.classList.remove("active")}
  }, [showValueState]);
  return (
    <div style={{ height: "30px" }} className={containerClass} ref={containerRef}>
      {showValueState ? (
        icon ? (
          <div className={`form-icon ${iconRightAlign ? "right" : ""}`}>
            <Input
              type={inputType || "text"}
              className={`form-control form-control-icon ${innerInputClass}`}
              value={inputValue || ""}
              onChange={onChangeHandler}
              onKeyDown={onKeyDownHanlder}
              onWheel={(e) => {
                if (e.deltaY !== 0) {
                  e.currentTarget.blur();
                }
                setTimeout(()=> {
                  if(ref.current){
                    ref.current.focus();
                  }
                },300)
              }}
              placeholder={placeholder}
              autoComplete="off"
              innerRef={ref}
              style={inputStyle}
            />
            {icon}
          </div>
        ) : (
          <Input
            type={inputType || "text"}
            className={`form-control form-control-icon ${innerInputClass}`}
            value={inputType === 'date' ? (inputValue ? moment(inputValue).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD')) : inputValue || ""}
            onChange={onChangeHandler}
            onKeyDown={onKeyDownHanlder}
            onWheel={(e) => {
              if (e.deltaY !== 0) {
                e.currentTarget.blur();
              }
              setTimeout(()=> {
                if(ref.current){
                  ref.current.focus();
                }
              },300)
            }}
            placeholder={placeholder}
            autoComplete="off"
            innerRef={ref}
            style={inputStyle}
          />
        )
      ) : (
        <span className={`d-flex align-items-center w-100 h-100 ${spanClassName}`} onMouseDown={onClickHanlder}>
          {/* {`${value ? (prefix || "") : ""}${value || ""}${value ? (suffix || "") : ""}`} */}
          {inputType === 'date'? (value ? moment(value).format("DD-MM-YYYY") : moment().format("DD-MM-YYYY")) :(value ? `${prefix || ""}${value}${suffix || ""}` : spanPlaceholder)}
        </span>
      )}
    </div>
  );
}
