export const tabs = [
    {
        id:"roles",
        name:"Roles"
    },
    {
        id:"permission",
        name:"Permission"
    },
    {
        id:"employees",
        name:"Employees"
    },
]