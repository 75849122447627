export const customerUrl = {
    get_all_customers : `/customers/all`,
    get_customer:({customerNo}) => `/customers/${customerNo}`,
    create_customer : `/customers`,
    get_max_customer_no:`/customers/maxNumber`,
    get_master_data_all:`/customers/master-data/all`,
    get_master_data_by_category:`/customers/master-data`,
    get_mobile_providers: `/masters/mobile-providers`,
    get_states_list : `/masters/states`,
    update_basic_info : ({customerNumber}) =>  `/customers/${customerNumber}/basicInfo`,
    update_classification : ({customerNumber}) =>  `/customers/${customerNumber}/classification`,
    update_billing_info : ({customerNumber}) =>  `/customers/${customerNumber}/billinginfo`,
    get_payments_of_customer : `/sales/payments`,
    get_invoices_of_customer : `/sales/invoices`,
    delete_customer :  `/customers/delete`,
    get_customer_account_balance_history : ({customerNumber}) => `customers/${customerNumber}/balanceHistory`,
    create_special_price_for_customer : ({customerNumber}) => `customers/${customerNumber}/specialPrice`,
    delete_special_price_for_customer : `customers/specialPrices`,
    accept_payments_anywhere: ({customerNumber}) => `customers/${customerNumber}/payInvoices`,
    get_customer_inventory_discounts: ({customerNumber}) => `/customers/${customerNumber}/invoice-item-discounts`
}