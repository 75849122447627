import React, { useMemo, useState, useRef, useEffect, useCallback } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { Col, Container, Label, Row, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import classnames from "classnames";
// Formik validation
import { useFormik } from "formik";
import { basicInfoInitialValues, basicInfoValidationSchema } from "./tabs/basicinfohelper";
import { classificationInitialValues, classificationValidationSchema } from "./tabs/classificationhelper";
import { billingInfoInitialValues, billingInfoValidationSchema } from "./tabs/BillingInfo/helper";
import { actions, tabs, customerColumnsList, mapCustomerDetails, mapDropdownState } from "./helper";
import {
  BasicInfo,
  Classification,
  BillingInfo,
  SalesTab,
  PaymentsTab,
  UnpaidInvoicesTab,
  AccountHistoryTab,
} from "./tabs";
import SelectItem from "../../Components/Common/SelectItem";
import DeleteModal from "../../Components/Common/DeleteModal";
import RouteChangeConfirmation from "../../Components/Common/RouteChangeConfirmation";
import PaymentsAnywhere from "../../Components/Common/paymentsanywhere";
import ApiLoader from "../../Components/Common/ApiLoader";
//Api Helper
import { generateApiUrl } from "../../api/apihelper";
//Actions
import {
  createCustomerAct,
  getAllCustomersAct,
  getCustomerAct,
  getMaxCustomerNoAct,
  getMasterDataAllAct,
  deleteCustomerAct,
  updateBasicInfoAct,
  updateClassificationAct,
  updateBillingInfoAct,
} from "../../store/customer/customeractions";
import {
  getMobileProvidersAct,
  getStatesListAct,
  getDriversListAct,
  getSalesmanListAct,
} from "../../store/masterdata/masterdataactions";
import { getMasterDataForSupplierAct } from "../../store/supplier/supplieractions";
//Utility
import { formatAmount } from "../../utility/commonutility";
//HOC
import withPermission from "../../hoc/withPermission"

function getActiveComponent(activeId) {
  switch (activeId) {
    case "basic_info":
      return BasicInfo;
    case "billing_info":
      return BillingInfo;
    case "sales":
      return SalesTab;
    case "payments":
      return PaymentsTab;
    case "unpaid_invoices":
      return UnpaidInvoicesTab;
    // case "account_history":
    //   return AccountHistoryTab;
    default:
      return <></>;
  }
}

function getActiveTabId(history, isRedirected) {
  const activeTab = JSON.parse(localStorage.getItem("activeTab") || "{}");
  let activeTabId = "";
  if (activeTab.warehousecustomer && history.action === "POP") {
    activeTabId = activeTab.warehousecustomer;
    delete activeTab.warehousecustomer;
    localStorage.setItem("activeTab", JSON.stringify(activeTab));
    isRedirected.current = true;
  } else activeTabId = "basic_info";
  return activeTabId;
}

function getRouteChngSaveDetailsHndlr(
  id,
  justifyPillsTab,
  basicInfoValidation,
  classificationValidation,
  billingInfoValidation,
  // isRouteChangeApiCalled
) {
  // isRouteChangeApiCalled.current = true;
  if (id === "new") {
    basicInfoValidation.handleSubmit();
    classificationValidation.handleSubmit();
    billingInfoValidation.handleSubmit();
  } else {
    if (justifyPillsTab === "basic_info") return basicInfoValidation.handleSubmit();
    else if (justifyPillsTab === "billing_info") return billingInfoValidation.handleSubmit();
  }
}

function getRouteChngApiResp(
  id,
  justifyPillsTab,
  createCustomerResp,
  updateBasicInfoResp,
  updateBillingInfoResp
) {
  if (id === "new") return createCustomerResp;
  else {
    if (justifyPillsTab === "basic_info") return updateBasicInfoResp;
    else if (justifyPillsTab === "billing_info") return updateBillingInfoResp;
  }
}

function getFormValidation(id, justifyPillsTab, basicInfoValidation, billingInfoValidation) {
  if (id === "new") return [basicInfoValidation, billingInfoValidation];
  else {
    if (justifyPillsTab === "basic_info") return basicInfoValidation;
    else if (justifyPillsTab === "billing_info") return billingInfoValidation;
  }
}

function WarehouseCustomers({ history }) {
  const { id } = useParams();
  const isRedirected = useRef(false);
  const [isBlocked, setIsBlocked] = useState(false);
  const [customerBalance, setCustomerBalance] = useState(0);
  const [justifyPillsTab, setjustifyPillsTab] = useState(getActiveTabId(history, isRedirected));
  const [search, setSearch] = useState("");
  const [searchListOptions, setSearchListOptions] = useState([]);
  const justifyPillsToggle = (tab) => {
    if (justifyPillsTab !== tab) {
      setjustifyPillsTab(tab);
    }
  };
  const changeToInitialTab = useRef(true);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const toggleDeleteModal = () => setShowDeleteModal(!showDeleteModal);
  const [dropdownState, setDropdownState] = useState({
    businessType: [],
    customerType: [],
    group: [],
    routeName: [],
    shipToRef: [],
    shippedVia: [],
    subGroup: [],
    zone: [],
    salesman: [],
    driverName: [],
    mobileProviders: [],
    states: [],
    customGroup: "",
    customSubGroup: "",
    customShippedVia: "",
    customRouteName: "",
    customBusinessType: "",
    customZone: "",
    modalCustomField: "",
  });
  const [invoicesForPayOrder, setInvoicesForPayOrder] = useState([]);

  const isPaymentAnywhereModelOpen = useRef(false);

  const basicInfoValidation = useFormik({
    enableReinitialize: true,
    initialValues: basicInfoInitialValues,
    validationSchema: basicInfoValidationSchema,
    onSubmit: (values) => {
      if (id && id === "new") justifyPillsToggle("billing_info");
      else dispatch(updateBasicInfoAct(generateApiUrl(`update_basic_info`, { customerNumber: id }), values));
    },
  });
  // const classificationValidation = useFormik({
  //   enableReinitialize: true,
  //   initialValues: classificationInitialValues,
  //   validationSchema: classificationValidationSchema,
  //   onSubmit: (values) => {
  //     if (id && id === "new") justifyPillsToggle("billing_info");
  //     else {
  //       const { invCopy } = values;
  //       const numberFields = {
  //         invCopy: invCopy ? Number(invCopy) : null,
  //       };
  //       dispatch(
  //         updateClassificationAct(generateApiUrl(`update_classification`, { customerNumber: id }), {
  //           ...values,
  //           ...numberFields,
  //         })
  //       );
  //     }
  //   },
  // });
  const billingInfoValidation = useFormik({
    enableReinitialize: true,
    initialValues: billingInfoInitialValues,
    validationSchema: billingInfoValidationSchema,
    onSubmit: (values) => {
      if (id && id === "new") {
        const { markupPercent, discountPercent, cashBackBalance, scheduleFrom, scheduleTo } = values;
        const numberFields = {
          markupPercent: markupPercent ? Number(markupPercent) : null,
          discountPercent: discountPercent ? Number(discountPercent) : null,
          cashBackBalance: cashBackBalance ? Number(cashBackBalance) : null,
        };
        const dateFields = {
          scheduleFrom: scheduleFrom || null,
          scheduleTo: scheduleTo || null,
        };
        const payload = {
          ...basicInfoValidation.values,
          ...values,
          ...numberFields,
          ...dateFields,
        };
        dispatch(createCustomerAct(generateApiUrl("create_customer"), payload));
      } else {
        const { markupPercent, discountPercent, cashBackBalance, scheduleFrom, scheduleTo } = values;
        const numberFields = {
          markupPercent: markupPercent ? Number(markupPercent) : null,
          discountPercent: discountPercent ? Number(discountPercent) : null,
          cashBackBalance: cashBackBalance ? Number(cashBackBalance) : null,
        };
        const dateFields = {
          scheduleFrom: scheduleFrom || null,
          scheduleTo: scheduleTo || null,
        };
        dispatch(
          updateBillingInfoAct(generateApiUrl(`update_billing_info`, { customerNumber: id }), {
            ...values,
            ...numberFields,
            ...dateFields,
          })
        );
      }
    },
  });

  //Change isBlocked to true whenever user changes the customer info
  const isRouteChangeApiCalled = useRef(false);
  const initialValidationValues = useRef({
    basicInfo: basicInfoInitialValues,
    billingInfo: billingInfoInitialValues,
  });

  useEffect(() => {
    const { basicInfo, billingInfo } = initialValidationValues.current;
    if (JSON.stringify(basicInfo) !== JSON.stringify(basicInfoValidation.values)) setIsBlocked(true);
    else if (JSON.stringify(billingInfo) !== JSON.stringify(billingInfoValidation.values)) setIsBlocked(true);
    else setIsBlocked(false);
    isRouteChangeApiCalled.current = false;
  }, [basicInfoValidation.values, billingInfoValidation.values]);

  const dispatch = useDispatch();

  const currentCustomerListPage = useRef(1);
  const dropdownBtnClicked = useRef(false);
  const callbackOnCustomerListApiResp = useRef(() => {});

  const isCreateCustomerBtnClicked = useRef(false);

  const {
    createCustomerResp,
    getAllCustomersResp,
    getMaxCustomerNoResp,
    getCustomerResp,
    getMasterDataAllResp,
    getMobileProvidersResp,
    getStatesListResp,
    getDriversListResp,
    getSalesmanListResp,
    updateBasicInfoResp,
    updateClassificationResp,
    updateBillingInfoResp,
    getZipcodeResp,
    deleteCustomerResp,
    getSpecialPricesResp,
    getInventoryListResp,
    createSpecialPriceForCustomerResp,
    deleteSpecialPriceForCustomerResp,
    getMasterDataForSupplierResp,
    acceptPaymentsAnywhereResp,
    customerErrResp,
    masterDataErrorResp,
    supplierErrorResp,
    customerApiLoading
  } = useSelector(
    ({ customer, inventory, masterData, supplier }) => ({
      createCustomerResp: customer.createCustomerResp,
      getAllCustomersResp: customer.getAllCustomersResp,
      getMaxCustomerNoResp: customer.getMaxCustomerNoResp,
      getCustomerResp: customer.getCustomerResp,
      getMasterDataAllResp: customer.getMasterDataAllResp,
      getMobileProvidersResp: masterData.getMobileProvidersResp,
      getStatesListResp: masterData.getStatesListResp,
      getDriversListResp: masterData.getDriversListResp,
      getSalesmanListResp: masterData.getSalesmanListResp,
      updateBasicInfoResp: customer.updateBasicInfoResp,
      updateClassificationResp: customer.updateClassificationResp,
      updateBillingInfoResp: customer.updateBillingInfoResp,
      getZipcodeResp: masterData.getZipcodeResp,
      deleteCustomerResp: customer.deleteCustomerResp,
      getSpecialPricesResp: inventory.getSpecialPricesResp,
      getInventoryListResp: inventory.getInventoryListResp,
      createSpecialPriceForCustomerResp: customer.createSpecialPriceForCustomerResp,
      deleteSpecialPriceForCustomerResp: customer.deleteSpecialPriceForCustomerResp,
      getMasterDataForSupplierResp: supplier.getMasterDataForSupplierResp,
      acceptPaymentsAnywhereResp: customer.acceptPaymentsAnywhereResp,
      customerErrResp: customer.customerErrResp,
      masterDataErrorResp: masterData.masterDataErrorResp,
      supplierErrorResp: supplier.supplierErrorResp,
      customerApiLoading: customer.customerApiLoading
    }),
    shallowEqual
  );
  const customerResp = useRef({
    prevCreateCustomerResp: createCustomerResp,
    prevGetAllCustomersResp: getAllCustomersResp,
    prevGetCustomerResp: getCustomerResp,
    prevGetMasterDataAllResp: getMasterDataAllResp,
    prevGetDriversListResp: getDriversListResp,
    prevGetSalesmanListResp: getSalesmanListResp,
    prevUpdateBasicInfoResp: updateBasicInfoResp,
    prevUpdateClassificationResp: updateClassificationResp,
    prevUpdateBillingInfoResp: updateBillingInfoResp,
    prevDeleteCustomerResp: deleteCustomerResp,
    prevCustomerErrResp: customerErrResp,
    prevMasterDataErrorResp: masterDataErrorResp,
    prevSupplierErrorResp: supplierErrorResp,
  });
  useEffect(() => {
    const {
      prevCreateCustomerResp,
      prevGetAllCustomersResp,
      prevGetCustomerResp,
      prevGetMasterDataAllResp,
      prevGetDriversListResp,
      prevGetSalesmanListResp,
      prevUpdateBasicInfoResp,
      prevUpdateClassificationResp,
      prevUpdateBillingInfoResp,
      prevDeleteCustomerResp,
      prevAcceptPaymentsAnywhereResp,
      prevCustomerErrResp,
      prevMasterDataErrorResp,
      prevSupplierErrorResp
    } = customerResp.current || {};
    if (createCustomerResp && prevCreateCustomerResp !== createCustomerResp) {
      if (!isRouteChangeApiCalled.current) {
        setIsBlocked(false);
        isCreateCustomerBtnClicked.current = true;
      }
      // history.push("/warehousecustomers", { showCreateCustomerSuccess: true });
      else isRouteChangeApiCalled.current = false;
    }
    if (getAllCustomersResp && prevGetAllCustomersResp !== getAllCustomersResp && !isPaymentAnywhereModelOpen.current) {
      const list = [];
      (getAllCustomersResp?.data?.customers || []).forEach(({ customerNumber, firstName, lastName, companyName }) => {
        list.push({
          customerNumber,
          customerName: `${firstName || ""} ${lastName || ""}`,
          companyName,
        });
      });
      if (!dropdownBtnClicked.current)  setSearchListOptions(list);
      else {
        setSearchListOptions((prev) => [...prev, ...list]);
        if (getAllCustomersResp?.data?.customers?.length > 0) callbackOnCustomerListApiResp.current();
        currentCustomerListPage.current = Number(getAllCustomersResp?.data?.page) + 1;
      }
    }
    if (getCustomerResp && prevGetCustomerResp !== getCustomerResp && !isPaymentAnywhereModelOpen.current) {
      setIsBlocked(false);
      const {
        data: {
          customer: {companyName, currentBalance },
        },
      } = getCustomerResp;
      setCustomerBalance((Number(currentBalance) || 0).toFixed(2));
      setSearch(`${companyName || ""}`);
      mapCustomerDetails(
        getCustomerResp,
        basicInfoValidation.setValues,
        billingInfoValidation.setValues,
        initialValidationValues
      );
      if (!isRedirected.current && changeToInitialTab.current) justifyPillsToggle("basic_info");
    }
    if (getMasterDataAllResp && prevGetMasterDataAllResp !== getMasterDataAllResp) {
      mapDropdownState(getMasterDataAllResp, setDropdownState);
    }
    if (getDriversListResp && prevGetDriversListResp !== getDriversListResp) {
      const {
        data: { drivers },
      } = getDriversListResp;
      setDropdownState((prev) => ({ ...prev, driverName: drivers }));
    }
    if (getSalesmanListResp && prevGetSalesmanListResp !== getSalesmanListResp) {
      const {
        data: { salesman },
      } = getSalesmanListResp;
      setDropdownState((prev) => ({ ...prev, salesman }));
    }
    if (updateBasicInfoResp && prevUpdateBasicInfoResp !== updateBasicInfoResp) {
      toast.success("Successfully updated the customer's basic info");
      if (!isRouteChangeApiCalled.current) {
        dispatch(getCustomerAct(generateApiUrl(`get_customer`, { customerNo: id })));
        changeToInitialTab.current = false;
      } else isRouteChangeApiCalled.current = false;
    }
    if (updateBillingInfoResp && prevUpdateBillingInfoResp !== updateBillingInfoResp) {
      toast.success("Successfully updated the customer's billing info");
      if (!isRouteChangeApiCalled.current) {
        dispatch(getCustomerAct(generateApiUrl(`get_customer`, { customerNo: id })));
        changeToInitialTab.current = false;
      } else isRouteChangeApiCalled.current = false;
    }
    if (deleteCustomerResp && prevDeleteCustomerResp !== deleteCustomerResp) {
      history.push("/warehousecustomers", { showDeleteCustomerSuccess: true });
    }
    if (acceptPaymentsAnywhereResp && prevAcceptPaymentsAnywhereResp !== acceptPaymentsAnywhereResp) {
      setInvoicesForPayOrder([]);
      if(id !=="new") {
        dispatch(getCustomerAct(generateApiUrl(`get_customer`, { customerNo: id })));
        changeToInitialTab.current = false;
      }
      isPaymentAnywhereModelOpen.current=false;
    }
    if (customerErrResp && prevCustomerErrResp !== customerErrResp && !isPaymentAnywhereModelOpen.current) {
      const { errors, error, message } = customerErrResp || {};
      toast.error((Array.isArray(errors) && errors[0].msg) || error || message);
      isCreateCustomerBtnClicked.current = false;
    }
    if (masterDataErrorResp && prevMasterDataErrorResp !== masterDataErrorResp) {
      const { errors, error, message } = masterDataErrorResp || {};
      toast.error((Array.isArray(errors) && errors[0].msg) || error || message);
    }
    if (supplierErrorResp && prevSupplierErrorResp !== supplierErrorResp) {
      const { errors, error, message } = supplierErrorResp || {};
      toast.error((Array.isArray(errors) && errors[0].msg) || error || message);
    }
    customerResp.current.prevGetAllCustomersResp = getAllCustomersResp;
    customerResp.current.prevCreateCustomerResp = createCustomerResp;
    customerResp.current.prevGetCustomerResp = getCustomerResp;
    customerResp.current.prevGetMasterDataAllResp = getMasterDataAllResp;
    customerResp.current.prevGetDriversListResp = getDriversListResp;
    customerResp.current.prevGetSalesmanListResp = getSalesmanListResp;
    customerResp.current.prevUpdateBasicInfoResp = updateBasicInfoResp;
    customerResp.current.prevUpdateClassificationResp = updateClassificationResp;
    customerResp.current.prevUpdateBillingInfoResp = updateBillingInfoResp;
    customerResp.current.prevDeleteCustomerResp = deleteCustomerResp;
    customerResp.current.prevAcceptPaymentsAnywhereResp = acceptPaymentsAnywhereResp;
    customerResp.current.prevCustomerErrResp = customerErrResp;
    customerResp.current.prevMasterDataErrorResp = masterDataErrorResp;
    customerResp.current.prevSupplierErrorResp = supplierErrorResp;
  }, [
    getAllCustomersResp,
    createCustomerResp,
    getCustomerResp,
    getMasterDataAllResp,
    getDriversListResp,
    getSalesmanListResp,
    updateBasicInfoResp,
    updateClassificationResp,
    updateBillingInfoResp,
    deleteCustomerResp,
    acceptPaymentsAnywhereResp,
    customerErrResp,
    masterDataErrorResp,
    supplierErrorResp
  ]);

  useEffect(() => {
    if(!isBlocked && isCreateCustomerBtnClicked.current)
      history.push("/warehousecustomers", { showCreateCustomerSuccess: true });
  }, [isBlocked])
  useEffect(() => {
    if (search === "") setSearchListOptions([]);
  }, [search]);
  const getAllCustomersApiCall = useCallback((showAllCustomers, page) => {
    if (showAllCustomers) {
      let params = {
        page: page || 1,
        pageSize: 50,
        orderBy:"company",
        sort:"asc"
      };
      dispatch(getAllCustomersAct(generateApiUrl(`get_all_customers`), params));
      dropdownBtnClicked.current = true;
    }
    else if (search) {
      let params = {
        page: 1,
        pageSize: 50,
        search,
        orderBy:"company",
        sort:"asc"
      };
      dispatch(getAllCustomersAct(generateApiUrl(`get_all_customers`), params));
      dropdownBtnClicked.current = false;
    }
  }, [search]);
  useEffect(() => {
    const debounceFn = setTimeout(() => {
      getAllCustomersApiCall();
    }, 200);
    return () => {
      clearTimeout(debounceFn);
    };
  }, [getAllCustomersApiCall]);
  const getCustomerApiCall = () => dispatch(getCustomerAct(generateApiUrl(`get_customer`, { customerNo: id })));
  useEffect(() => {
    if (id && id !== "new") {
      getCustomerApiCall();
      changeToInitialTab.current = true;
    }
  }, [id]);
  useEffect(() => {
    if (id && id === "new") {
      dispatch(getMaxCustomerNoAct(generateApiUrl(`get_max_customer_no`)));
      justifyPillsToggle("basic_info");
      setSearch("");
      initialValidationValues.current = {
        basicInfo: basicInfoInitialValues,
        billingInfo: billingInfoInitialValues,
      };
    }
    basicInfoValidation.resetForm();
    billingInfoValidation.resetForm();
  }, [id]);
  useEffect(() => {
    dispatch(getMasterDataAllAct(generateApiUrl(`get_master_data_all`)));
    dispatch(getMobileProvidersAct(generateApiUrl(`get_mobile_providers`)));
    dispatch(getStatesListAct(generateApiUrl(`get_states_list`)));
    dispatch(getDriversListAct(generateApiUrl(`get_drivers_list`)));
    dispatch(getSalesmanListAct(generateApiUrl(`get_salesman_list`)));
    dispatch(getMasterDataForSupplierAct(generateApiUrl(`get_master_data_for_supplier`), { category: "paymentTerms" }));
  }, []);
  const TabComponent = useMemo(() => getActiveComponent(justifyPillsTab), [justifyPillsTab]);
  function selectItemCallbck(itemDetails) {
    const { customerNumber } = itemDetails;
    history.push(`/warehousecustomers/${customerNumber}`);
  }
  const handleDeleteCustomer = () => {
    if (id) {
      dispatch(deleteCustomerAct(generateApiUrl("delete_customer"), { ids: [id] }));
    }
  };
  const {
    data: { mobileProviders },
  } = getMobileProvidersResp || { data: { mobileProviders: [] } };
  const {
    data: { states },
  } = getStatesListResp || { data: { states: [] } };
  return (
    <React.Fragment>
      <ToastContainer />
      <div className="sale-wh-wrapper">
        <Container fluid>
          <Row className="px-4 mb-3 whbrand-text-color">
            <Col xs={6}>
              <Row>
                <Col xs={6} className="border-section p-3 py-0">
                  <div>
                    <Label htmlFor="userrole" className="form-label mb-1">
                      Search Customer
                    </Label>
                    {/* <SelectItem
                      searchValue={search}
                      setSearchValue={setSearch}
                      listOptions={searchListOptions}
                      columnsList={customerColumnsList}
                      placeholder="Select Customer"
                      selectedItemHandler={selectItemCallbck}
                      fieldKey="companyName"
                      dataIdKey="customerNumber"
                    /> */}
                    <SelectItem
                      searchValue={search}
                      setSearchValue={setSearch}
                      listOptions={searchListOptions}
                      setSearchListOptions={setSearchListOptions}
                      columnsList={customerColumnsList}
                      placeholder="Select Customer"
                      selectedItemHandler={selectItemCallbck}
                      fieldKey="companyName"
                      dataIdKey="customerNumber"
                      showDropdownBtn={true}
                      dropdownBtnClickHndlr={(setShowOptions) => {
                        getAllCustomersApiCall(true);
                        setShowOptions(true);
                      }}
                      callbackAfterBottomReached={(fn) => {
                        getAllCustomersApiCall(true, currentCustomerListPage.current);
                        callbackOnCustomerListApiResp.current = fn;
                      }}
                    />
                    {/* <select className="form-select" id="userrole" aria-label="Default select example">
                                                <option >Select</option>
                                            </select> */}
                  </div>
                </Col>
                <Col xs={6}>
                  <div className="d-flex justify-content-center border border-section py-2 px-1 h-100">
                    {actions(
                      setSearch,
                      history,
                      toggleDeleteModal,
                      basicInfoValidation,
                      billingInfoValidation,
                      justifyPillsToggle,
                      id,
                      getCustomerApiCall
                    ).map((item) => {
                      return (
                        <div
                          key={item.id}
                          className={`action-icon-container ${
                            id === "new" && item.id === "delete"
                              ? "opacity-50 show-cursor-not-allowed"
                              : "show-cursor-pointer"
                          }`}
                          onClick={
                            (id !== "new" || item.id !== "delete") && item.clickHandler ? item.clickHandler : () => {}
                          }
                        >
                          <img className="action-icon" src={item.icon} />
                          <span>{item.label}</span>
                        </div>
                      );
                    })}
                  </div>
                </Col>
              </Row>
            </Col>
            <Col xs={6} className="d-flex">
              <div className="d-flex align-items-center me-5 flex-grow-1">
                <div 
                  className="fs-5 fs-lg-4 fw-bold d-flex align-items-center justify-content-center p-3 py-0 p-lg-3 wh-item-bg"
                  style={{ background: "#FFC10754" }}
                >
                  Customer Number :{" "}
                  {id === "new" ? getMaxCustomerNoResp && Number(getMaxCustomerNoResp?.data?.customerNumber) + 1 : id}
                </div>
              </div>
              <div className="d-flex align-items-center justify-content-end flex-grow-1">
                <Label className="fs-4 me-3 mb-0">Balance : </Label>
                <div className="border d-flex align-items-center justify-content-center fs-5 fs-lg-2 fw-bold p-3 py-2">
                  {formatAmount(id !== "new" ? customerBalance : "0.00")}
                </div>
              </div>
            </Col>
          </Row>
          {history.location?.state?.redirected && (
            <div className="d-flex mt-3">
              <div
                className="d-flex align-items-center text-primary fs-5 px-4 mb-3"
                style={{ cursor: "pointer" }}
                onClick={history.goBack}
              >
                <i className="ri-arrow-left-line me-1"></i>
                <div className="mb-0 text-decoration-underline">Go Back</div>
              </div>
            </div>
          )}
          <Row>
            <Col className="px-0" xs={12}>
              <Nav pills className="mb-1 bg-light px-4 wh-text-dark">
                {tabs.map((item) => (
                  <NavItem key={item.id}>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({ active: justifyPillsTab === item.id })}
                      onClick={() => {
                        justifyPillsToggle(item.id);
                      }}
                    >
                      {item.name}
                    </NavLink>
                  </NavItem>
                ))}
              </Nav>
              <TabContent activeTab={justifyPillsTab} className="text-muted">
                <TabPane tabId={justifyPillsTab} id="pill-justified-home-1">
                  <TabComponent
                    id={id}
                    history={history}
                    basicInfoValidation={basicInfoValidation}
                    billingInfoValidation={billingInfoValidation}
                    dropdownState={dropdownState}
                    setDropdownState={setDropdownState}
                    mobileProviders={mobileProviders}
                    states={states}
                    getAllCustomersAct={getAllCustomersAct}
                    generateApiUrl={generateApiUrl}
                    dispatch={dispatch}
                    getAllCustomersResp={getAllCustomersResp}
                    useSelector={useSelector}
                    shallowEqual={shallowEqual}
                    getZipcodeResp={getZipcodeResp}
                    getSpecialPricesResp={getSpecialPricesResp}
                    getInventoryListResp={getInventoryListResp}
                    createSpecialPriceForCustomerResp={createSpecialPriceForCustomerResp}
                    deleteSpecialPriceForCustomerResp={deleteSpecialPriceForCustomerResp}
                    toast={toast}
                    getMasterDataForSupplierResp={getMasterDataForSupplierResp}
                    invoicesForPayOrder={invoicesForPayOrder}
                    setInvoicesForPayOrder={setInvoicesForPayOrder}
                    isPaymentAnywhereModelOpen={isPaymentAnywhereModelOpen}
                  />
                </TabPane>
              </TabContent>
            </Col>
          </Row>
        </Container>
      </div>
      <DeleteModal show={showDeleteModal} onCloseClick={toggleDeleteModal} onDeleteClick={handleDeleteCustomer} />
      <RouteChangeConfirmation
        isBlocked={isBlocked}
        title="Leave Page?"
        content="You have unsaved changes. Are you sure you want to leave this page?"
        saveDetailsHndlr={() => {
          isRouteChangeApiCalled.current = true;
          getRouteChngSaveDetailsHndlr(
            id,
            justifyPillsTab,
            basicInfoValidation,
            billingInfoValidation,
            // isRouteChangeApiCalled
          );
        }}
        routeChangeApiResp={getRouteChngApiResp(
          id,
          justifyPillsTab,
          createCustomerResp,
          updateBasicInfoResp,
          updateBillingInfoResp
        )}
        routeChangeApiErrResp={customerErrResp}
        formValidation={getFormValidation(
          id,
          justifyPillsTab,
          basicInfoValidation,
          billingInfoValidation
        )}
        toast={toast}
      />
      <PaymentsAnywhere customerNo={id !== "new" ? id : ""} invoices={invoicesForPayOrder} isPaymentAnywhereModelOpen={isPaymentAnywhereModelOpen} />
      <ApiLoader loading={customerApiLoading} />
    </React.Fragment>
  );
}
export default withPermission(WarehouseCustomers, "CUSTOMER_MENU");
