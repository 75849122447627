import PrintIcon from "../../../../assets/images/warehouse/salespage/printer.png";
import ExportIcon from "../../../../assets/images/warehouse/salespage/excel.png";
import ResetIcon from "../../../../assets/images/warehouse/salespage/reset.png";
import { Link } from "react-router-dom";

export const actions = (togglePrintModal, generatePurchasesReport,resetFilters, selectedRadio) => [
  {
    id: "reset",
    label: "Reset",
    icon: ResetIcon,
    clickHandler: resetFilters
  },
  {
    id: "print",
    label: "Print",
    icon: PrintIcon,
    clickHandler:togglePrintModal
  },
  {
    id: "export",
    label: "Export",
    icon: ExportIcon,
    clickHandler : () => generatePurchasesReport(true, selectedRadio)
  },
];

export function generateColumns() {
  return [
    {
      Header: "Item Num",
      accessor: "itemNumber",
      Cell:({cell:{value}}) => <Link target="_blank" to={`/warehouseinventory/${value}`}>{value}</Link>
    },
    {
      Header: "Item Name",
      accessor: "description",
      Cell:({row:{original:{itemNumber, description}}}) => <Link target="_blank" to={`/warehouseinventory/${itemNumber}`}>{description}</Link>
    }, 
    {
      Header: "Manufacturer",
      accessor: "manufacturer",
    },
    {
      Header: "Category",
      accessor: "category",
    },
    {
      Header: "Sub Category",
      accessor: "subCategory",
    },
    {
      Header: "Quantity",
      accessor: "quantity",
    },
  ];
}

export const inventoryItemColumnsList = [
  {
    id: "itemNumber",
    name: "Item Num",
    cellClassName: "fw-medium",
  },
  {
    id: "itemName",
    name: "Item Name",
    cellClassName: "fbadge badge-soft-primary",
  },
  {
    id: "category",
    name: "Category",
    cellClassName: "fw-medium",
  },
];

export const supplierColumnsList = [
  {
    id: "supplierNumber",
    name: "Supplier. Num",
    cellClassName: "fw-medium",
  },
  {
    id: "companyName",
    name: "Company",
    cellClassName: "fbadge badge-soft-primary",
  },
  {
    id: "supplierName",
    name: "Supplier Name",
  },
];

export const tableData = [
  {
    id: "1503",
    item: "05-05-2022",
    description: "New Salem Conv Store",
    quantity: "",
    caseQuantity: "$300.90",
    amount: "$300.90",
    received: "$0.00",
  },
  {
    id: "1503",
    item: "05-05-2022",
    description: "New Salem Conv Store",
    quantity: "",
    caseQuantity: "$300.90",
    amount: "$300.90",
    received: "$0.00",
  },
  {
    id: "1503",
    item: "05-05-2022",
    description: "New Salem Conv Store",
    quantity: "",
    caseQuantity: "$300.90",
    amount: "$300.90",
    received: "$0.00",
  },
  {
    id: "1503",
    item: "05-05-2022",
    description: "New Salem Conv Store",
    quantity: "",
    caseQuantity: "$300.90",
    amount: "$300.90",
    received: "$0.00",
  },
  {
    id: "1503",
    item: "05-05-2022",
    description: "New Salem Conv Store",
    quantity: "",
    caseQuantity: "$300.90",
    amount: "$300.90",
    received: "$0.00",
  },
  {
    id: "1503",
    item: "05-05-2022",
    description: "New Salem Conv Store",
    quantity: "",
    caseQuantity: "$300.90",
    amount: "$300.90",
    received: "$0.00",
  },
  {
    id: "1503",
    item: "05-05-2022",
    description: "New Salem Conv Store",
    quantity: "",
    caseQuantity: "$300.90",
    amount: "$300.90",
    received: "$0.00",
  },
];
