import { getCustomDateFormatted } from "../../utility/dateutility";
import ResetIcon from "../../assets/images/warehouse/salespage/reset.png";
export function generateColumns(
  ) {
    
    return [
      {
        Header: "S.No.",
        accessor: "sNo",
        Cell: ({ row }) => row.index + 1
      },
      {
        Header: "Item Name",
        accessor: "itemName",
        Cell:({cell:{value}}) => value || "-"   
      },
      {
        Header: "Price",
        accessor: "price",
        Cell:({cell:{value}}) => value || "-"   
      },
      {
        Header: "Quantity",
        accessor: "quantity",
        Cell:({cell:{value}}) => value || "-"   
      },
      {
        Header: "Weight",
        accessor: "weight",
        Cell:({cell:{value}}) => value || "-"   
      },
      {
        Header: "Order Id",
        accessor: "orderId",
        Cell:({cell:{value}}) => value || "-"   
      },
      {
        Header: "Order Number",
        accessor: "orderNumber",
        Cell:({cell:{value}}) => value || "-"   
      },
      {
        Header: "Vendor",
        accessor: "vendor",
        Cell:({cell:{value}}) => value || "-"   
      },
      {
        Header: "Variant Title",
        accessor: "variantTitle",
        Cell:({cell:{value}}) => value || "-"   
      },
      {
        Header: "Created At",
        accessor: "createdAt",
        Cell:({cell:{value}}) => value?getCustomDateFormatted(value,"DD-MM-YYYY"):"-" || "-"   
      },
      {
        Header: "Status",
        accessor: "status",
        Cell:({cell:{value}}) => value || "-"   
      },
      
    ];
  }
  
  export const actions = (resetFilters) => [
    {
      id: "reset",
      label: "Reset",
      icon: ResetIcon,
      clickHandler: resetFilters
    },

  ];
  export const DUMMY_DATA = [
    {
      "id": 241,
      "platformType": "SHOPIFY",
      "orderId": "5611601199203",
      "lineItemId": "14392148295779",
      "itemName": "Collagen - Mango",
      "price": 0,
      "quantity": 10,
      "weight": 0,
      "orderNumber": "1019",
      "vendor": "Home Bake",
      "variantTitle": null,
      "createdAt": "2024-04-01T12:41:00.000Z",
      "status": null
  }
  ]
  