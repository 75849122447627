import React, { useMemo, useState, useRef, useEffect, useCallback } from "react";
import { Container, Row, Col, Label, Modal, ModalHeader, Form, ModalBody } from "reactstrap";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { toast } from "react-toastify";
import { actions, generateColumns, tableData } from "./helper";
import BackIcon from "../../../assets/images/warehouse/menu/back.png";
import WhTableContainer from "../../../Components/Common/WhTables";
//Api Helper
import { generateApiUrl } from "../../../api/apihelper";
//Actions
import { getCustomerRouteReportAct, getInvPricingReportPrintAct } from "../../../store/reports/reportsactions";
import { getMasterDataAllAct } from "../../../store/customer/customeractions";
import { getDriversListAct, getSalesmanListAct } from "../../../store/masterdata/masterdataactions";
import F12CloseModalListener from "../../../Components/Common/F12close";
import PrintItem from "./PrintItem";
import ApiLoader from "../../../Components/Common/ApiLoader";

const CustomerRoutesReport = ({ history }) => {
  const [showPrintModal, setShowPrintModal] = useState(false);
  const [filters, setFilters] = useState({
    shippedVia:"",
    routeName:"",
    routeDeliveryDay:"",
    zone:"",
    driverName:"",
    salesman:""
  });
  const [tableData, setTableData] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const isDownloadBtnClicked = useRef(false);
  function togglePrintModal() {
    setShowPrintModal(!showPrintModal);
  }
  const [dropdownState, setDropdownState] = useState({
    routeName: [],
    shippedVia: [],
    zone: [],
    salesman: [],
    driverName: [],
  });
  const dispatch = useDispatch();
  const currentPageSize = useRef(30);
  const {
    getCustomerRouteReportResp,
    getMasterDataAllResp,
    getDriversListResp,
    getSalesmanListResp,
    reportsErrorResp,
    customerErrResp,
    masterDataErrorResp,
    getInvPricingReportLoading
  } = useSelector(
    ({ customer, reports, masterData }) => ({
      getCustomerRouteReportResp: reports.getCustomerRouteReportResp,
      getMasterDataAllResp: customer.getMasterDataAllResp,
      getDriversListResp: masterData.getDriversListResp,
      getSalesmanListResp: masterData.getSalesmanListResp,
      getInvPricingReportLoading: reports.getInvPricingReportLoading,
      reportsErrorResp: reports.reportsErrorResp,
      customerErrResp: customer.customerErrResp,
      masterDataErrorResp: masterData.masterDataErrorResp,
    }),
    shallowEqual
  );
  const customerResp = useRef({
    prevGetCustomerRouteReportResp: getCustomerRouteReportResp,
    prevGetMasterDataAllResp: getMasterDataAllResp,
    prevGetDriversListResp: getDriversListResp,
    prevGetSalesmanListResp: getSalesmanListResp,
    prevReportsErrorResp: reportsErrorResp,
    prevCustomerErrResp: customerErrResp,
    prevMasterDataErrorResp: masterDataErrorResp,
  });
  useEffect(() => {
    const {
      prevGetCustomerRouteReportResp,
      prevGetMasterDataAllResp,
      prevGetDriversListResp,
      prevGetSalesmanListResp,
      prevReportsErrorResp,
      prevCustomerErrResp,
      prevMasterDataErrorResp,
    } = customerResp.current || {};
    if (getCustomerRouteReportResp && prevGetCustomerRouteReportResp !== getCustomerRouteReportResp) {
      if (isDownloadBtnClicked.current) {
        const url = window.URL.createObjectURL(new Blob([getCustomerRouteReportResp]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
         "customer_route_report.csv"
        );
        document.body.appendChild(link);
        link.click();
        link.remove();
        isDownloadBtnClicked.current = false;
      } 
      else{
        setTableData(getCustomerRouteReportResp?.data?.report || []);
        currentPageSize.current = getCustomerRouteReportResp?.data?.pageSize;
        setPageCount((getCustomerRouteReportResp?.data?.total))
      }
    } else if (reportsErrorResp && prevReportsErrorResp !== reportsErrorResp) {
      const { errors, error, message } = reportsErrorResp || {};
      toast.error((Array.isArray(errors) && errors[0].msg) || error || message);
    } else if (customerErrResp && prevCustomerErrResp !== customerErrResp) {
      const { errors, error, message } = customerErrResp || {};
      toast.error((Array.isArray(errors) && errors[0].msg) || error || message);
    } else if (masterDataErrorResp && prevMasterDataErrorResp !== masterDataErrorResp) {
      const { errors, error, message } = masterDataErrorResp || {};
      toast.error((Array.isArray(errors) && errors[0].msg) || error || message);
    }
    if (getMasterDataAllResp && prevGetMasterDataAllResp !== getMasterDataAllResp) {
      const {
        data: {
          lists: { routeName, shippedVia, zone },
        },
      } = getMasterDataAllResp;
      setDropdownState((prev) => ({
        ...prev,
        routeName,
        shippedVia,
        zone,
      }));
    }
    if (getDriversListResp && prevGetDriversListResp !== getDriversListResp) {
      const {
        data: { drivers },
      } = getDriversListResp;
      setDropdownState((prev) => ({ ...prev, driverName: drivers }));
    }
    if (getSalesmanListResp && prevGetSalesmanListResp !== getSalesmanListResp) {
      const {
        data: { salesman },
      } = getSalesmanListResp;
      setDropdownState((prev) => ({ ...prev, salesman }));
    }
    customerResp.current.prevGetCustomerRouteReportResp = getCustomerRouteReportResp;
    customerResp.current.prevReportsErrorResp = reportsErrorResp;
  }, [
    getCustomerRouteReportResp,
    getMasterDataAllResp,
    getDriversListResp,
    getSalesmanListResp,
    reportsErrorResp,
    customerErrResp,
    masterDataErrorResp,
  ]);

  const getCustomerRouteReportApiCall = useCallback(
    (page, pageSize, download, downloadType = 'pdf') => {
      const { shippedVia, routeName, routeDeliveryDay, zone, driverName, salesman } = filters;
      let params = {
        page: page ? page + 1 : 1,
        pageSize: pageSize || currentPageSize.current,
      };
      if (shippedVia) params.shippedVia = shippedVia;
      if (routeName) params.routeName = routeName;
      if (routeDeliveryDay) params.routeDeliveryDay = routeDeliveryDay;
      if (zone) params.zone = zone;
      if (driverName) params.driverName = driverName;
      if (salesman) params.salesman = salesman;
      if (download && downloadType === 'html') {
        params.download = true;
        params.downloadType = downloadType;
        dispatch(
          getInvPricingReportPrintAct(
            generateApiUrl(`get_customer_route_report`),
            params
          )
        );
      } 
      else if (download){
        params.download = true;
        isDownloadBtnClicked.current = true; 
        dispatch(
          getCustomerRouteReportAct(
            generateApiUrl(`get_customer_route_report`),
            params,
            {responseType: 'blob'}
          )
        );}
        else {
          dispatch(
            getCustomerRouteReportAct(
              generateApiUrl(`get_customer_route_report`),
              params
            )
          );
        }
    },
    [filters]
  );

  useEffect(() => {
    const debounceFn = setTimeout(() => {
      getCustomerRouteReportApiCall();
    }, 400);
    return () => {
      clearTimeout(debounceFn);
    };
  }, [getCustomerRouteReportApiCall]);

  useEffect(() => {
    dispatch(getMasterDataAllAct(generateApiUrl(`get_master_data_all`)));
    dispatch(getDriversListAct(generateApiUrl(`get_drivers_list`)));
    dispatch(getSalesmanListAct(generateApiUrl(`get_salesman_list`)));
  }, []);

  const handlePageChange = (page, pageSize) => {
    getCustomerRouteReportApiCall(page, pageSize);
  };

  const filtersChngHndlr = (e) => {
    const { value, name } = e.target;
    setFilters((prev) => ({ ...prev, [name]: value }));
  };

  const resetFilters = () => {
    setFilters({
      shippedVia:"",
      routeName:"",
      routeDeliveryDay:"",
      zone:"",
      driverName:"",
      salesman:""
    })
  }

  const columns = useMemo(() => generateColumns(), []);
  return (
    <>
    <Container fluid className=" pt-2 pb-0">
      <Row>
        <Col xs="12" className="mb-0">
        <div className="px-3 mb-3 pt-1 border rounded-1 report-header" style={{display:"inline-block"}}>
          <h3 style={{color:'#fff'}}>Customer Routes Report</h3>
         </div>
          <div className="border ps-4 my-3" style={{ position: "relative" }}>
            <Label className="text-dark fs-5" style={{ position: "absolute", transform: "translateY(-50%)" }}>
            <span className=" px-1 wh-item-bg"> Filters</span>
            </Label>
            <Row>
              <Col xs="8" xxl="9" className="border-end pe-5">
                <Row className="my-3 mb-1">
                  <Col xs={4}>
                    <Label htmlFor="companyName" className="form-label mb-0 text-nowrap">
                      Shipped Via:
                    </Label>
                    <select
                      className="form-select py-1 me-2"
                      name="shippedVia"
                      id="shippedVia"
                      value={filters.shippedVia}
                      onChange={filtersChngHndlr}
                    >
                      <option value=""></option>
                      {dropdownState.shippedVia.map((item) => (
                        <option key={item}>{item}</option>
                      ))}
                    </select>
                  </Col>
                  <Col xs={4}>
                    <Label htmlFor="companyName" className="form-label mb-0 text-nowrap">
                      Route Delivery Day:
                    </Label>
                    <select
                      className="form-select py-1"
                      name="routeDeliveryDay"
                      id="routeDeliveryDay"
                      value={filters.routeDeliveryDay}
                      onChange={filtersChngHndlr}
                    >
                      <option value=""></option>
                      <option>Monday</option>
                      <option>Tuesday</option>
                      <option>Wednesday</option>
                      <option>Thursday</option>
                      <option>Friday</option>
                      <option>Saturday</option>
                      <option>Sunday</option>
                    </select>
                  </Col>
                  <Col xs={4}>
                    <Label htmlFor="companyName" className="form-label mb-0 text-nowrap">
                      Driver's Name:
                    </Label>
                    <select
                      className="form-select py-1"
                      name="driverName"
                      id="driverName"
                      value={filters.driverName}
                      onChange={filtersChngHndlr}
                    >
                      <option value=""></option>
                      {dropdownState.driverName.map((item) => (
                        <option value={item.id} key={item.id}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                  </Col>
                </Row>

                <Row className="mb-1">
                  <Col xs={4}>
                    <Label htmlFor="companyName" className="form-label mb-0 text-nowrap">
                      Route Name:
                    </Label>
                    <select
                      className="form-select py-1 me-2"
                      name="routeName"
                      id="routeName"
                      value={filters.routeName}
                      onChange={filtersChngHndlr}
                    >
                      <option value=""></option>
                      {dropdownState.routeName.map((item) => (
                        <option key={item}>{item}</option>
                      ))}
                    </select>
                  </Col>
                  <Col xs={4}>
                    <Label htmlFor="companyName" className="form-label mb-0 text-nowrap">
                      Zone:
                    </Label>
                    <select
                      className="form-select py-1 me-2"
                      name="zone"
                      id="zone"
                      value={filters.zone}
                      onChange={filtersChngHndlr}
                    >
                      <option value=""></option>
                      {dropdownState.zone.map((item) => (
                        <option value={item} key={item}>
                          {item}
                        </option>
                      ))}
                    </select>
                  </Col>
                  <Col xs={4}>
                    <Label htmlFor="companyName" className="form-label mb-0 text-nowrap">
                      Salesman:
                    </Label>
                    <select
                      className="form-select py-1"
                      name="salesman"
                      id="salesman"
                      value={filters.salesman}
                      onChange={filtersChngHndlr}
                    >
                      <option value=""></option>
                      {dropdownState.salesman.map((item) => (
                        <option value={item.id} key={item.id}>
                          {item.salesman}
                        </option>
                      ))}
                    </select>
                  </Col>
                </Row>
              </Col>
              <Col xs="4" xxl="3" className="d-flex align-items-center pe-5">
                <div className="sale-wh-wrapper flex-grow-1 mt-0 me-4">
                  <div className="d-flex justify-content-center border border-section py-2 px-1 h-100">
                    {actions(togglePrintModal,getCustomerRouteReportApiCall,resetFilters).map((item) => {
                      return (
                        <div key={item.id} className="action-icon-container" onClick={item.clickHandler || (() => {})} style={{cursor:"pointer"}} >
                          <img className="action-icon" alt="" src={item.icon} />
                          <span>{item.label}</span>
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div
                  className="d-flex flex-column align-items-center"
                  style={{ cursor: "pointer" }}
                  onClick={() => history.push("/reportsmenu")}
                >
                  <img src={BackIcon} width="32" height="32" alt="back-icon" />
                  <div className="text-decoration-underline mb-0">Go Back</div>
                </div>
              </Col>
            </Row>
          </div>
        </Col>

        <Col xs="12">
          <WhTableContainer
            columns={columns}
            data={tableData || []}
            className="custom-header-css"
            divClass="table-responsive table-container-customersroutesreport"
            tableClass="align-middle table-bordered"
            theadClass="table-light"
            handlePageChange={handlePageChange}
            pageCount={pageCount || 0}
            customPageSize={30}
            showNoDataComponent={true}
          />
        </Col>
      </Row>
      {/* <ApiLoader loading={getPoAnalysisReportLoading} />  */}
      <ApiLoader loading={getInvPricingReportLoading} />
    </Container>
     <Modal
     id="showPrintModal"
     isOpen={showPrintModal}
     toggle={togglePrintModal}
     centered
     size="lg"
   >
     <F12CloseModalListener onClose={togglePrintModal} />
     <ModalHeader className="py-2 px-3" toggle={togglePrintModal}>
       Print Options
     </ModalHeader>
     <Form
       onSubmit={(e) => {
         e.preventDefault();
       }}
     >
       <ModalBody className="px-0 pt-0">
         <PrintItem
           getCustomerRouteReportApiCall={getCustomerRouteReportApiCall}
           filters={filters}
           showPrintModal={showPrintModal}
         />
       </ModalBody>
     </Form>
   </Modal>
   </>
  );
};

export default CustomerRoutesReport;
