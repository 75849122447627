import React, { useMemo, useEffect, useCallback } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { Col, Container, Row } from 'reactstrap';
import WhTableContainer from "../../../Components/Common/WhTables";
import { generateColumns, tableData } from "./helper";
//Api Helper
import { generateApiUrl } from "../../../api/apihelper";
//Actions
import { getSuppliersOfGivenItemAct } from "../../../store/inventory/inventoryactions";

export const Suppliers = ({itemNumber}) => {
    const dispatch = useDispatch();
    const { getSuppliersOfGivenItemResp,  } = useSelector(({inventory}) => ({
      getSuppliersOfGivenItemResp:inventory.getSuppliersOfGivenItemResp,
    }),shallowEqual);
    const purchaseOrdersApiCall = useCallback((page,pageSize) => {
        if(itemNumber) dispatch(
            getSuppliersOfGivenItemAct(generateApiUrl(`get_suppliers_of_given_item`,{itemNumber}), {
                page : page || 1,
                pageSize : pageSize || 10,
            })
          );
    },[itemNumber])
    useEffect(() => {
        purchaseOrdersApiCall();
    },[purchaseOrdersApiCall]);
    const handlePageChange = (page,pageSize) => {
        purchaseOrdersApiCall(page+1,pageSize)
    }
    const { data : {page}} = getSuppliersOfGivenItemResp || {data : {page:1}};
    const columns = useMemo(() => generateColumns(page), [page]);
    return (
        <React.Fragment>
            <Container fluid className="px-0">
                <Row className="m-0">
                    <Col className="px-0" xs={12}>
                        <WhTableContainer
                            columns={columns}
                            data={(getSuppliersOfGivenItemResp?.data?.suppliers || [])}
                            // customPageSize={7}
                            className="custom-header-css"
                            divClass="table-responsive mt-3"
                            tableClass="align-middle table-bordered"
                            theadClass="table-light"
                            handlePageChange={handlePageChange}
                            pageCount={Math.ceil((getSuppliersOfGivenItemResp?.data?.total || 0) / 10)}
                            customPageSize={30}
                            showNoDataComponent={true}
                        />
                    </Col>
                </Row>
            </Container>
        </React.Fragment>
    )
}