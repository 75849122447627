import supplierconstants from "./supplierconstants";

const initialState = {
  getSuppliersListResp: undefined,
  getSuppliersListLoading: false,
  createSupplierResp: undefined,
  getSupplierResp: undefined,
  updateSupplierDetailsResp: undefined,
  getSupplierInvoicesResp: undefined,
  getMasterDataForSupplierResp: undefined,
  deleteSupplierResp: undefined,
  getItemsOfSupplierResp: undefined,
  getMaxSupplierNumResp: undefined,
  supplierErrorResp: undefined,
  supplierApiLoading: false,
};

export default function supplier(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case supplierconstants.GET_SUPPLIERS_LIST_SUCCESS:
      return { ...state, getSuppliersListResp: payload };
    case supplierconstants.CHANGE_GET_SUPPLIERS_LIST_LOADING_STATUS:
      return { ...state, getSuppliersListLoading: payload };
    case supplierconstants.CREATE_SUPPLIER_SUCCESS:
      return { ...state, createSupplierResp: payload };
    case supplierconstants.GET_SUPPLIER_SUCCESS:
      return { ...state, getSupplierResp: payload };
    case supplierconstants.UPDATE_SUPPLIER_DETAILS_SUCCESS:
      return { ...state, updateSupplierDetailsResp: payload };
    case supplierconstants.GET_SUPPLIER_INVOICES_SUCCESS:
      return { ...state, getSupplierInvoicesResp: payload };
    case supplierconstants.GET_MASTER_DATA_FOR_SUPPLIER_SUCCESS:
      return { ...state, getMasterDataForSupplierResp: payload };
    case supplierconstants.DELETE_SUPPLIER_SUCCESS:
      return { ...state, deleteSupplierResp: payload };
    case supplierconstants.GET_ITEMS_OF_SUPPLIER_SUCCESS:
      return { ...state, getItemsOfSupplierResp: payload };
    case supplierconstants.GET_MAX_SUPPLIER_NUM_SUCCESS:
      return { ...state, getMaxSupplierNumResp: payload };
    case supplierconstants.CHANGE_SUPPLIER_API_LOADING_STATUS:
      return { ...state, supplierApiLoading: payload };

    case supplierconstants.GET_SUPPLIERS_LIST_FAIL:
    case supplierconstants.CREATE_SUPPLIER_FAIL:
    case supplierconstants.GET_SUPPLIER_FAIL:
    case supplierconstants.UPDATE_SUPPLIER_DETAILS_FAIL:
    case supplierconstants.GET_SUPPLIER_INVOICES_FAIL:
    case supplierconstants.GET_MASTER_DATA_FOR_SUPPLIER_FAIL:
    case supplierconstants.DELETE_SUPPLIER_FAIL:
    case supplierconstants.GET_ITEMS_OF_SUPPLIER_FAIL:
    case supplierconstants.GET_MAX_SUPPLIER_NUM_FAIL:
      return { ...state, supplierErrorResp: payload };
    default:
      return state;
  }
}
