import { currencySymbol } from "../constants";

export const toAbsoluteUrl = (pathname) => process.env.PUBLIC_URL + pathname;

export const blockInvalidChar = (e) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();

export const toTitleCase = (str) => {
  return str
    .toLowerCase()
    .replace(/\b\w/g, (match) => match.toUpperCase());
};

export const toCamelCase = (str) => {
  return str.replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) => {
    return index === 0 ? word.toUpperCase() : word;
  }).replace(/\s+/g, '');
};
export const blockInvalidCharForPhone = (e, callback) => /^[0-9()\-+ ]*$/.test(e.target.value) && callback();

export const addCommasToAmount = (amount) => {
  const nums = amount.toFixed(2).split(".");
  let formattedAmount = "";
  for (let i = 0; i < nums[0].length; i++) {
    formattedAmount = nums[0][nums[0].length - 1 - i] + formattedAmount;
    if ((i + 1) % 3 === 0 && !(i === nums[0].length - 1)) formattedAmount = "," + formattedAmount;
  }
  formattedAmount = formattedAmount + "." + (nums[1] || "00");
  return formattedAmount;
};

export const formatDollarAmount = (amount) => {
  const amountValue = typeof amount === "number" ? amount : Number(amount);
  if(!isNaN(amountValue)) {
    const formattedAmount = amountValue.toLocaleString("en-US",{style:"currency",currency:"USD"});
    if(formattedAmount[0] === "-")
     return `(${formattedAmount.slice(1)})`;
    return formattedAmount;
  }
  else return "";
}

export const formatAmount = (amount) => {
  const amountValue = typeof amount === "number" ? amount : Number(amount);
  if(!isNaN(amountValue)) {
    const formattedAmount = amountValue.toLocaleString("en-IN",{style:"currency",currency:"INR"});
    if(formattedAmount[0] === "-")
     return `(${formattedAmount.slice(1)})`;
    return formattedAmount;
  }
  else return "";
}

export const formatAmountSymbol = () => {
  return "₹"
}