import React, {useMemo, useEffect, useState, useRef} from "react";
import WhTableContainer from "../../Components/Common/WhTables";
import { generateAdjustmentHistoryColumns } from "./helper";

const AdjustmentHistory = ({getAdjustmentHistoryOfItemResp, getAdjustmentHistoryOfItemApiCall}) => {
  const [tableData, setTableData] = useState([]);
  const handlePageChange = (page, pageSize) => {
    getAdjustmentHistoryOfItemApiCall(page + 1, pageSize);
  };
  useEffect(() => {
    getAdjustmentHistoryOfItemApiCall();
  },[])
  const prevGetAdjustmentHistoryOfItemResp = useRef(getAdjustmentHistoryOfItemResp);
  useEffect(() => {
    if(getAdjustmentHistoryOfItemResp && getAdjustmentHistoryOfItemResp !== prevGetAdjustmentHistoryOfItemResp.current) {
      setTableData((getAdjustmentHistoryOfItemResp?.data?.qtyAdg || []))
    }
    prevGetAdjustmentHistoryOfItemResp.current = getAdjustmentHistoryOfItemResp;
  },[getAdjustmentHistoryOfItemResp])
  const {
    data: { page },
  } = getAdjustmentHistoryOfItemResp || { data: { page: 1 } };
  const columns = useMemo(() => generateAdjustmentHistoryColumns(page), [page]);
  return (
    <WhTableContainer
      columns={columns}
      data={tableData}
      // customPageSize={7}
      className="custom-header-css"
      divClass="table-responsive"
      tableClass="align-middle table-bordered"
      theadClass="table-light"
      handlePageChange={handlePageChange}
      pageCount={Math.ceil((getAdjustmentHistoryOfItemResp?.data?.total || 0) / 10)}
      customPageSize={30}
      showNoDataComponent={true}
    />
  );
};

export default AdjustmentHistory;
