import PrintIcon from "../../../../../assets/images/warehouse/salespage/printer.png";
import GenerateIcon from "../../../../../assets/images/warehouse/salespage/generate.png";
import ExportIcon from "../../../../../assets/images/warehouse/salespage/excel.png";
import ResetIcon from "../../../../../assets/images/warehouse/salespage/reset.png";
import { formatAmount } from "../../../../../utility/commonutility"
import { Link } from "react-router-dom";
export const actions = (togglePrintModal,generateCustomerBalanceReport,resetFilters) => [
    {
        id: "reset",
        label: "Reset",
        icon: ResetIcon,
        clickHandler: resetFilters
      }, {
        id: "print",
        label: "Print",
        icon: PrintIcon,
        clickHandler:togglePrintModal
    }, {
        id: "export",
        label: "Export",
        icon: ExportIcon,
        clickHandler:generateCustomerBalanceReport
    },
];

export function generateColumns() {
    return [
        {
            Header: "Customer No",
            accessor: "customerNumber",
            Cell:({cell:{value}}) => <Link target="_blank" to={`/warehousecustomers/${value}`}>{value}</Link>,
        },
        {
            Header: "Company",
            accessor: "companyName",
            Cell:({row:{original:{customerNumber,companyName}}}) => <Link target="_blank" to={`/warehousecustomers/${customerNumber}`}>{companyName}</Link>,
        },
        {
            Header: "Phone",
            accessor: "phone"
        }, {
            Header: "Name",
            accessor:"name",
            Cell: ({row:{original:{salutation,firstName,lastName}}}) => `${salutation || ""} ${firstName || ""} ${lastName || ""}`
        }, {
            Header: "Tax Id",
            accessor: "taxId"
        },{
            Header: "Balance",
            accessor: "currentBalance",
            Cell: ({cell:{value}}) => formatAmount(value)
        },
    ]
}