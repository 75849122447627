import React, { useMemo, useState, useEffect, useCallback } from "react";
import { Col, Container, Input, Label, Row, Button } from "reactstrap";
import WhTableContainer from "../../Components/Common/WhTables";
import { getSearchLabel } from "./helper";
import { generateColumns } from "./searchcustomerhelper";
import { getStartDateFilterValue } from "../../utility/dateutility";

export default function SearchCustomer({
  modalTableData,
  getAllCustomersAct,
  dispatch,
  generateApiUrl,
  modalPageCount,
  getAllCustomersLoading,
}) {
  const [search, setSearch] = useState("");
  const [searchBy, setSearchBy] = useState("company");
  const getAllCustomersApiCall = useCallback(
    (page, pageSize) => {
      let params = {
        page: page ? page + 1 : 1,
        pageSize: pageSize || 30,
      };
      if (search) {
        if (searchBy !== "dateCreated") params[searchBy] = search;
        else params[searchBy] = getStartDateFilterValue(search);
      }
      dispatch(getAllCustomersAct(generateApiUrl(`get_all_customers`), params));
    },
    [search, searchBy]
  );

  useEffect(() => {
    const debounceFn = setTimeout(() => {
      getAllCustomersApiCall();
    }, 400);
    return () => {
      clearTimeout(debounceFn);
    };
  }, [getAllCustomersApiCall]);

  const handlePageChange = (page, pageSize) => {
    getAllCustomersApiCall(page, pageSize);
  };
  const handleChange = (e) => {
    const { name } = e.target;
    if (searchBy != name) {
      setSearchBy(name);
      if (name === "dateCreated" || searchBy === "dateCreated") setSearch("");
    }
  };
  // const searchHandler = () => {
  //   setFilters(prev => ({...prev, search, searchBy}));
  //   setShowSearchModal(false);
  // }
  const columns = useMemo(() => generateColumns(), []);
  return (
    <Container fluid>
      <Row className="mb-4">
        <Col xs={12} className="bg-primary text-center fs-5 text-white py-2">
          Smart Search-Customers
        </Col>
      </Row>
      <Row className="mb-4">
        <Col xs={12}>
          <div className="px-3" style={{ position: "relative" }}>
            <Label
              className="fs-5 font-weight-bold "
              style={{ position: "absolute", transform: "translateY(-50%)" }}
            >
              <span className=" px-1 wh-item-bg"> Type Of Search</span>
            </Label>
            <Row className="border pt-4 pb-3">
              <Col xs="4" className="mb-2">
                <Input
                  type="radio"
                  name="company"
                  className="me-2"
                  onChange={handleChange}
                  checked={searchBy == "company"}
                />{" "}
                By Company
              </Col>
              <Col xs="4">
                <Input type="radio" name="name" className="me-2" onChange={handleChange} checked={searchBy == "name"} />{" "}
                By Customer Name
              </Col>
              <Col xs="4" className="mb-2">
                <Input
                  type="radio"
                  name="phone"
                  className="me-2"
                  onChange={handleChange}
                  checked={searchBy == "phone"}
                />{" "}
                By Customer Phone
              </Col>
              <Col xs="4" className="mb-2">
                <Input
                  type="radio"
                  name="customerNumber"
                  className="me-2"
                  onChange={handleChange}
                  checked={searchBy == "customerNumber"}
                />{" "}
                By Customer Number
              </Col>
              <Col xs="4" className="mb-2">
                <Input type="radio" name="city" className="me-2" onChange={handleChange} checked={searchBy == "city"} />{" "}
                By City
              </Col>

              <Col xs="4" className="mb-2">
                <Input
                  type="radio"
                  name="dateCreated"
                  className="me-2"
                  onChange={handleChange}
                  checked={searchBy == "dateCreated"}
                />{" "}
                By Date Created
              </Col>
              {/* <Col xs="4" className="mb-2">
                  <Input type="radio" name="searchType" className="me-2" /> By Company*
                </Col> */}
              <Col xs="4">
                <Input
                  type="radio"
                  name="license"
                  className="me-2"
                  onChange={handleChange}
                  checked={searchBy == "license"}
                />{" "}
                By Licenses
              </Col>
              {/* <Col xs="4">
                  <Input type="radio" name="searchType" className="me-2" /> By Other Reference
                </Col> */}
            </Row>
          </div>
        </Col>
      </Row>

      {searchBy && (
        <Row className="mb-2">
          <Col xs={12}>
            <Label htmlFor="userrole" className="form-label mb-1">
              Search By {getSearchLabel(searchBy)}
            </Label>
            <div className="d-flex align-items-center">
              {searchBy !== "dateCreated" ? (
                <Input
                  className="form-control me-2"
                  type="text"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
              ) : (
                <Input
                  className="form-control me-2"
                  type="date"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
              )}
              {/* <Button color="primary" onClick={searchHandler} disabled={search ? false : true}>Search</Button> */}
            </div>
          </Col>
        </Row>
      )}

      {/* <Row>
          <Col xs="12" className="d-flex justify-content-end"></Col>
        </Row> */}
      <Row>
        <Col xs={12} style={{ minHeight: "340px", maxHeight: "340px", overflowY: "auto" }}>
          <WhTableContainer
            columns={columns}
            data={modalTableData}
            className="custom-header-css"
            divClass="table-responsive mt-3"
            tableClass="align-middle"
            theadClass="table-light"
            handlePageChange={handlePageChange}
            pageCount={modalPageCount}
            customPageSize={30}
            showNoDataComponent={true}
            loading={getAllCustomersLoading}
          />
        </Col>
      </Row>
    </Container>
  );
}
