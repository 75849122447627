import React from "react";
import { Col, Container, Input, Label, Row, Button, FormFeedback } from "reactstrap";
import CancelIcon from "../../assets/images/warehouse/salespage/cancel_icon.png";
//Utility
import { formatAmount, formatAmountSymbol } from "../../utility/commonutility";

export default function PaymentDetails({
  validation,
  supplierDetails,
  totalAmount,
  invNumber,
  received,
  togglePaymentDetailsModal,
  poTotal,
}) {
  return (
    <Container className="px-0">
      <Row className="p-3 m-0 border-bottom">
        <Col xs={7}>
          <Row className="mb-3">
            <Col xs={4}>
              <h5>{supplierDetails.supplierNumber || "-"}</h5>
              <h5>{supplierDetails.companyName || "-"}</h5>
            </Col>
            <Col xs={8}>
              <div
                className="fw-bold d-flex align-items-center justify-content-center px-5 py-3 mb-3"
                style={{ background: "#FFC10754" }}
              >
                Invoice # {invNumber}
              </div>
            </Col>
            <Col xs={4} className="d-flex align-items-center">
              <Label className="form-label mb-0 text-nowrap">Invoice Total :</Label>
            </Col>
            <Col xs={8}>
              <Input
                name="invoiceTotal"
                id="invoiceTotal"
                className="form-control form-control-icon"
                placeholder="0.00"
                type="text"
                readOnly
                value={formatAmount(poTotal.toFixed(2))}
              />
            </Col>
          </Row>
          <Row className="mb-3">
            <Col xs={4} className="d-flex align-items-center">
              <Label className="form-label mb-0 text-nowrap">Balance Due :</Label>
            </Col>
            <Col xs={8}>
                <Input
                  name="balanceDue"
                  id="balanceDue"
                  className="form-control form-control-icon"
                  placeholder="0.00"
                  type="text"
                  readOnly
                  value={formatAmount((Number(validation.values.amountPaid) - Number(poTotal).toFixed(2)).toFixed(2))}
                />
            </Col>
          </Row>
        </Col>
        <Col xs="5">
          {!received && (
            <Button color="primary" className={`w-100 mb-2 wh-btn`} outline onClick={validation.handleSubmit}>
              <span className="d-flex justify-content-center align-items-center">
                {/* <Spinner size="sm" type="grow" className="flex-shrink-0" role="status"> Loading... </Spinner> */}
                <i className="ri-save-line fs-5"></i>
                <span className="ms-2">Save</span>
              </span>
            </Button>
          )}
          <Button color="danger" className="w-100 mb-2 wh-btn-danger" outline onClick={togglePaymentDetailsModal}>
            <span className="d-flex justify-content-center align-items-center">
              {/* <Spinner size="sm" type="grow" className="flex-shrink-0" role="status"> Loading... </Spinner> */}
              <img src={CancelIcon} style={{ height: "16px", width: "16px" }} />
              <span className="ms-2">Close</span>
            </span>
          </Button>
        </Col>
      </Row>
      <Row className="p-3 m-0">
        <Col xs={7}>
          <h5>Payment Details</h5>
          <Row className="mb-3 mt-2">
            <Col xs={4} className="d-flex align-items-center">
              <Label className="form-label mb-0 text-nowrap">PO Amount :</Label>
            </Col>
            <Col xs={8}>
              <Input
                name="poAmount"
                id="poAmount"
                className="form-control form-control-icon"
                placeholder="0.00"
                type="text"
                readOnly
                value={formatAmount(poTotal.toFixed(2))}
              />
            </Col>
          </Row>
          <Row className="mb-3">
            <Col xs={4} className="d-flex align-items-center">
              <Label className="form-label mb-0 text-nowrap">Amount Paid :</Label>
            </Col>
            <Col xs={8}>
              <div className="form-icon">
                <Input
                  className="form-control ps-4"
                  placeholder="0.00"
                  type="number"
                  onWheel={(e) => e.target.blur()}
                  name="amountPaid"
                  id="amountPaid"
                  value={validation.values.amountPaid}
                  onChange={validation.handleChange}
                  invalid={validation.touched.amountPaid && validation.errors.amountPaid ? true : false}
                  readOnly={received}
                />
                <i style={{ fontStyle: "normal" }}>{formatAmountSymbol()}</i>
              </div>
              {validation.touched.amountPaid && validation.errors.amountPaid && (
                <div className="custom-invalid-feedback">{validation.errors.amountPaid}</div>
              )}
            </Col>
          </Row>
          <Row className="mb-3">
            <Col xs={4} className="d-flex align-items-center">
              <Label className="form-label mb-0 text-nowrap">Balance :</Label>
            </Col>
            <Col xs={8}>
                <Input
                  className="form-control form-control-icon"
                  placeholder="0.00"
                  type="text"
                  value={formatAmount((Number(validation.values.amountPaid) - Number(poTotal).toFixed(2)).toFixed(2))}
                  readOnly
                />
            </Col>
          </Row>
          <Row className="mb-3">
            <Col xs={4} className="d-flex align-items-center">
              <Label className="form-label mb-0 text-nowrap">Payment Type :</Label>
            </Col>
            <Col xs={8}>
              <Input
                type="select"
                className="form-control"
                name="paymentType"
                id="paymentType"
                value={validation.values.paymentType}
                onChange={validation.handleChange}
                invalid={validation.touched.paymentType && validation.errors.paymentType ? true : false}
                disabled={received}
              >
                <option value="">Select</option>
                <option value="CASH">Cash</option>
                <option value="CHEQUE">Cheque</option>
                <option value="ONLINE">Online</option>
              </Input>
              <FormFeedback>
                {validation.touched.paymentType && validation.errors.paymentType ? validation.errors.paymentType : ""}
              </FormFeedback>
            </Col>
          </Row>
          {validation.values.paymentType === "CHEQUE" && (
            <>
              <Row className="mb-3">
                <Col xs={4} className="d-flex align-items-center">
                  <Label className="form-label mb-0 text-nowrap">Cheque No :</Label>
                </Col>
                <Col xs={8}>
                  <Input
                    className="form-control"
                    name="chequeNo"
                    id="chequeNo"
                    value={validation.values.chequeNo}
                    onChange={validation.handleChange}
                    placeholder="Enter Cheque No"
                    invalid={validation.touched.chequeNo && validation.errors.chequeNo ? true : false}
                    readOnly={received}
                  />
                  <FormFeedback>
                    {validation.touched.chequeNo && validation.errors.chequeNo ? validation.errors.chequeNo : ""}
                  </FormFeedback>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col xs={4} className="d-flex align-items-center">
                  <Label className="form-label mb-0 text-nowrap">Cheque Date :</Label>
                </Col>
                <Col xs={8}>
                  <Input
                    type="date"
                    className="form-control"
                    name="chequeDate"
                    id="chequeDate"
                    value={validation.values.chequeDate}
                    onChange={validation.handleChange}
                    invalid={validation.touched.chequeDate && validation.errors.chequeDate ? true : false}
                    readOnly={received}
                  />
                  <FormFeedback>
                    {validation.touched.chequeDate && validation.errors.chequeDate ? validation.errors.chequeDate : ""}
                  </FormFeedback>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col xs={4} className="d-flex align-items-center">
                  <Label className="form-label mb-0 text-nowrap">In The Name Of :</Label>
                </Col>
                <Col xs={8}>
                  <Input
                    type="text"
                    className="form-control"
                    name="inTheNameOf"
                    id="inTheNameOf"
                    value={validation.values.inTheNameOf}
                    onChange={validation.handleChange}
                    placeholder="Enter In The Name Of"
                    invalid={validation.touched.inTheNameOf && validation.errors.inTheNameOf ? true : false}
                    readOnly={received}
                  />
                  <FormFeedback>
                    {validation.touched.inTheNameOf && validation.errors.inTheNameOf
                      ? validation.errors.inTheNameOf
                      : ""}
                  </FormFeedback>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col xs={4} className="d-flex align-items-center">
                  <Label className="form-label mb-0 text-nowrap">Bank Name :</Label>
                </Col>
                <Col xs={8}>
                  <Input
                    type="text"
                    className="form-control"
                    name="bankName"
                    id="bankName"
                    value={validation.values.bankName}
                    onChange={validation.handleChange}
                    placeholder="Enter Bank Name"
                    invalid={validation.touched.bankName && validation.errors.bankName ? true : false}
                    readOnly={received}
                  />
                  <FormFeedback>
                    {validation.touched.bankName && validation.errors.bankName ? validation.errors.bankName : ""}
                  </FormFeedback>
                </Col>
              </Row>
            </>
          )}
          {validation.values.paymentType === "ONLINE" && (
            <>
              <Row className="mb-3">
                <Col xs={4} className="d-flex align-items-center">
                  <Label className="form-label mb-0 text-nowrap">Transaction Id :</Label>
                </Col>
                <Col xs={8}>
                  <Input
                    type="text"
                    className="form-control"
                    name="transactionId"
                    id="transactionId"
                    value={validation.values.transactionId}
                    onChange={validation.handleChange}
                    placeholder="Enter Transaction Id"
                    invalid={validation.touched.transactionId && validation.errors.transactionId ? true : false}
                    readOnly={received}
                  />
                  <FormFeedback>
                    {validation.touched.transactionId && validation.errors.transactionId
                      ? validation.errors.transactionId
                      : ""}
                  </FormFeedback>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col xs={4} className="d-flex align-items-center">
                  <Label className="form-label mb-0 text-nowrap">Transaction Date :</Label>
                </Col>
                <Col xs={8}>
                  <Input
                    type="date"
                    className="form-control"
                    name="transactionDate"
                    id="transactionDate"
                    value={validation.values.transactionDate}
                    onChange={validation.handleChange}
                    invalid={validation.touched.transactionDate && validation.errors.transactionDate ? true : false}
                    readOnly={received}
                  />
                  <FormFeedback>
                    {validation.touched.transactionDate && validation.errors.transactionDate
                      ? validation.errors.transactionDate
                      : ""}
                  </FormFeedback>
                </Col>
              </Row>
            </>
          )}
        </Col>
      </Row>
    </Container>
  );
}
