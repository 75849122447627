import salesconstants from "./salesconstants";

const initialState = {
  createInvoiceResp: undefined,
  updateInvoiceResp: undefined,
  deleteInvoiceResp: undefined,
  getMaxInvoiceNoResp: undefined,
  getInvoiceDetailsResp: undefined,
  changePostedStatusOfInvoiceResp: undefined,
  downloadInvoicePdfResp: undefined,
  salesApiLoading: false,
  salesErrorResp: undefined,
};

export default function sales(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case salesconstants.CREATE_INVOICE_SUCCESS:
      return { ...state, createInvoiceResp: payload };
    case salesconstants.UPDATE_INVOICE_SUCCESS:
      return { ...state, updateInvoiceResp: payload };
    case salesconstants.DELETE_INVOICE_SUCCESS:
      return { ...state, deleteInvoiceResp: payload };
    case salesconstants.GET_MAX_INVOICE_NO_SUCCESS:
      return { ...state, getMaxInvoiceNoResp: payload };
    case salesconstants.GET_INVOICE_DETAILS_SUCCESS:
      return { ...state, getInvoiceDetailsResp: payload };
    case salesconstants.CHANGE_POSTED_STATUS_OF_INVOICE_SUCCESS:
      return { ...state, changePostedStatusOfInvoiceResp: payload };
    case salesconstants.DOWNLOAD_INVOICE_PDF_SUCCESS:
      return { ...state, downloadInvoicePdfResp: payload };
    case salesconstants.CHANGE_SALES_API_LOADING_STATUS:
      return { ...state, salesApiLoading: payload };

    case salesconstants.CREATE_INVOICE_FAIL:
    case salesconstants.UPDATE_INVOICE_FAIL:
    case salesconstants.DELETE_INVOICE_FAIL:
    case salesconstants.GET_MAX_INVOICE_NO_FAIL:
    case salesconstants.GET_INVOICE_DETAILS_FAIL:
    case salesconstants.CHANGE_POSTED_STATUS_OF_INVOICE_FAIL:
    case salesconstants.DOWNLOAD_INVOICE_PDF_FAIL:
      return { ...state, salesErrorResp: payload };
    default:
      return state;
  }
}
