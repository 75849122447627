export default {
  GET_ALL_CUSTOMERS_REQUEST: "GET_ALL_CUSTOMERS_REQUEST",
  GET_ALL_CUSTOMERS_SUCCESS: "GET_ALL_CUSTOMERS_SUCCESS",
  GET_ALL_CUSTOMERS_FAIL: "GET_ALL_CUSTOMERS_FAIL",

  CHANGE_GET_ALL_CUSTOMERS_LOADING_STATUS: "CHANGE_GET_ALL_CUSTOMERS_LOADING_STATUS",

  GET_CUSTOMER_REQUEST: "GET_CUSTOMER_REQUEST",
  GET_CUSTOMER_SUCCESS: "GET_CUSTOMER_SUCCESS",
  GET_CUSTOMER_FAIL: "GET_CUSTOMER_FAIL",

  CREATE_CUSTOMER_REQUEST: "CREATE_CUSTOMER_REQUEST",
  CREATE_CUSTOMER_SUCCESS: "CREATE_CUSTOMER_SUCCESS",
  CREATE_CUSTOMER_FAIL: "CREATE_CUSTOMER_FAIL",

  GET_MAX_CUSTOMER_NO_REQUEST: "GET_MAX_CUSTOMER_NO_REQUEST",
  GET_MAX_CUSTOMER_NO_SUCCESS: "GET_MAX_CUSTOMER_NO_SUCCESS",
  GET_MAX_CUSTOMER_NO_FAIL: "GET_MAX_CUSTOMER_NO_FAIL",

  GET_MASTER_DATA_ALL_REQUEST: "GET_MASTER_DATA_ALL_REQUEST",
  GET_MASTER_DATA_ALL_SUCCESS: "GET_MASTER_DATA_ALL_SUCCESS",
  GET_MASTER_DATA_ALL_FAIL: "GET_MASTER_DATA_ALL_FAIL",

  GET_MASTER_DATA_BY_CATEGORY_REQUEST: "GET_MASTER_DATA_BY_CATEGORY_REQUEST",
  GET_MASTER_DATA_BY_CATEGORY_SUCCESS: "GET_MASTER_DATA_BY_CATEGORY_SUCCESS",
  GET_MASTER_DATA_BY_CATEGORY_FAIL: "GET_MASTER_DATA_BY_CATEGORY_FAIL",

  UPDATE_BASIC_INFO_REQUEST: "UPDATE_BASIC_INFO_REQUEST",
  UPDATE_BASIC_INFO_SUCCESS: "UPDATE_BASIC_INFO_SUCCESS",
  UPDATE_BASIC_INFO_FAIL: "UPDATE_BASIC_INFO_FAIL",

  UPDATE_CLASSIFICATION_REQUEST: "UPDATE_CLASSIFICATION_REQUEST",
  UPDATE_CLASSIFICATION_SUCCESS: "UPDATE_CLASSIFICATION_SUCCESS",
  UPDATE_CLASSIFICATION_FAIL: "UPDATE_CLASSIFICATION_FAIL",

  UPDATE_BILLING_INFO_REQUEST: "UPDATE_BILLING_INFO_REQUEST",
  UPDATE_BILLING_INFO_SUCCESS: "UPDATE_BILLING_INFO_SUCCESS",
  UPDATE_BILLING_INFO_FAIL: "UPDATE_BILLING_INFO_FAIL",

  GET_PAYMENTS_OF_CUSTOMER_REQUEST: "GET_PAYMENTS_OF_CUSTOMER_REQUEST",
  GET_PAYMENTS_OF_CUSTOMER_SUCCESS: "GET_PAYMENTS_OF_CUSTOMER_SUCCESS",
  GET_PAYMENTS_OF_CUSTOMER_FAIL: "GET_PAYMENTS_OF_CUSTOMER_FAIL",

  GET_INVOICES_OF_CUSTOMER_REQUEST: "GET_INVOICES_OF_CUSTOMER_REQUEST",
  GET_INVOICES_OF_CUSTOMER_SUCCESS: "GET_INVOICES_OF_CUSTOMER_SUCCESS",
  GET_INVOICES_OF_CUSTOMER_FAIL: "GET_INVOICES_OF_CUSTOMER_FAIL",

  CHANGE_GET_INVOICES_LOADING_STATUS : "CHANGE_GET_INVOICES_LOADING_STATUS",

  DELETE_CUSTOMER_REQUEST: "DELETE_CUSTOMER_REQUEST",
  DELETE_CUSTOMER_SUCCESS: "DELETE_CUSTOMER_SUCCESS",
  DELETE_CUSTOMER_FAIL: "DELETE_CUSTOMER_FAIL",

  GET_CUSTOMER_ACCOUNT_BALANCE_HISTORY_REQUEST: "GET_CUSTOMER_ACCOUNT_BALANCE_HISTORY_REQUEST",
  GET_CUSTOMER_ACCOUNT_BALANCE_HISTORY_SUCCESS: "GET_CUSTOMER_ACCOUNT_BALANCE_HISTORY_SUCCESS",
  GET_CUSTOMER_ACCOUNT_BALANCE_HISTORY_FAIL: "GET_CUSTOMER_ACCOUNT_BALANCE_HISTORY_FAIL",

  CREATE_SPECIAL_PRICE_FOR_CUSTOMER_REQUEST: "CREATE_SPECIAL_PRICE_FOR_CUSTOMER_REQUEST",
  CREATE_SPECIAL_PRICE_FOR_CUSTOMER_SUCCESS: "CREATE_SPECIAL_PRICE_FOR_CUSTOMER_SUCCESS",
  CREATE_SPECIAL_PRICE_FOR_CUSTOMER_FAIL: "CREATE_SPECIAL_PRICE_FOR_CUSTOMER_FAIL",

  DELETE_SPECIAL_PRICE_FOR_CUSTOMER_REQUEST: "DELETE_SPECIAL_PRICE_FOR_CUSTOMER_REQUEST",
  DELETE_SPECIAL_PRICE_FOR_CUSTOMER_SUCCESS: "DELETE_SPECIAL_PRICE_FOR_CUSTOMER_SUCCESS",
  DELETE_SPECIAL_PRICE_FOR_CUSTOMER_FAIL: "DELETE_SPECIAL_PRICE_FOR_CUSTOMER_FAIL",

  ACCEPT_PAYMENTS_ANYWHERE_REQUEST: "ACCEPT_PAYMENTS_ANYWHERE_REQUEST",
  ACCEPT_PAYMENTS_ANYWHERE_SUCCESS: "ACCEPT_PAYMENTS_ANYWHERE_SUCCESS",
  ACCEPT_PAYMENTS_ANYWHERE_FAIL: "ACCEPT_PAYMENTS_ANYWHERE_FAIL",

  CHANGE_CUSTOMER_API_LOADING_STATUS: "CHANGE_CUSTOMER_API_LOADING_STATUS",

  GET_CUSTOMER_INVENTORY_DISCOUNTS_REQUEST: "GET_CUSTOMER_INVENTORY_DISCOUNTS_REQUEST",
  GET_CUSTOMER_INVENTORY_DISCOUNTS_SUCCESS: "GET_CUSTOMER_INVENTORY_DISCOUNTS_SUCCESS",
  GET_CUSTOMER_INVENTORY_DISCOUNTS_FAIL: "GET_CUSTOMER_INVENTORY_DISCOUNTS_FAIL",
};
