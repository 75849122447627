import React,{useRef,useEffect} from "react";
import { Modal, ModalBody, ModalHeader, } from "reactstrap";
import F12CloseModalListener from "../../Components/Common/F12close";

const PopUpMsgModal = ({ showPopUpMsgModal, togglePopUpMsgModal, popMemo }) => {
  const okButtonRef = useRef('');
  useEffect(() => {
    if(showPopUpMsgModal){
      setTimeout(() => {
        okButtonRef.current.focus();
      }, 500);
    }
  },[showPopUpMsgModal])
  return (
    <Modal isOpen={showPopUpMsgModal} toggle={togglePopUpMsgModal} centered={true}>
         <F12CloseModalListener onClose={togglePopUpMsgModal} />
      <ModalHeader className="py-2 border-bottom">Customer's Memo</ModalHeader>
      <ModalBody className="py-0">
        <div className="text-center">
          <i className="ri-error-warning-line text-warning" style={{ fontSize: "48px" }}></i>
          <div className="fs-15 mx-4 mx-sm-5">
            <p className="text-muted mx-4 mb-0">{popMemo}</p>
          </div>
        </div>
        <div className="d-flex gap-2 justify-content-end py-2 mb-2">
          <button ref={okButtonRef} type="button" className="btn w-sm btn-success border border-danger" id="delete-record" onClick={togglePopUpMsgModal}>
            Ok
          </button>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default PopUpMsgModal;