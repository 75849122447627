import { Input, FormFeedback } from "reactstrap";
import SelectItem from "../../../../Components/Common/SelectItem";
//Constants
import { currencySymbol } from "../../../../constants";
//Utility
import { blockInvalidChar } from "../../../../utility/commonutility";

const customerColumnsList = [
    {
      id: "itemNumber",
      name: "Item No",
      cellClassName: "fw-medium",
    },
    {
      id: "description",
      name: "Description",
      cellClassName: "fbadge badge-soft-primary",
    },
    {
      id: "cost",
      name: "Cost",
      cellClassName: "fw-medium",
    },
  ];
export function generateColumns(selectItemHndlr, searchValueChngHndlr, handleCustomerPriceChange, handleRowDelete, handleSpecialPriceBlur, specialPricesValidation) {
    return [
       {
            Header: "Item No",
            accessor: "itemNumber",
            style: { width: "25%" },
            Cell: ({ row }) => {
                const { itemNumber, itemNumberSearch, itemNumberListOptions } = row.original;
                return itemNumber ? (
                  itemNumber
                ) : (<>
                  <SelectItem
                    searchValue={itemNumberSearch}
                    searchValueChngHndlr={(value) => searchValueChngHndlr(value, row.index, "itemNumberSearch")}
                    listOptions={itemNumberListOptions || []}
                    columnsList={customerColumnsList}
                    placeholder="Search Item Number"
                    selectedItemHandler={(itemDetails) => selectItemHndlr(itemDetails, row.index)}
                    fieldKey="itemNumber"
                    dataIdKey="itemNumber"
                    hideListOnBlur={true}
                    inputClassname={`multi-item-input ${
                      specialPricesValidation.touched?.[row.index]?.itemNumber && specialPricesValidation.errors?.[row.index]?.itemNumber
                        ? "custom-invalid-field"
                        : ""
                    }`}
                  />
                  {specialPricesValidation.touched?.[row.index]?.itemNumber && specialPricesValidation.errors?.[row.index]?.itemNumber && (
                    <div className="custom-invalid-feedback">
                      {specialPricesValidation.touched?.[row.index]?.itemNumber && specialPricesValidation.errors?.[row.index]?.itemNumber}
                    </div>
                  )}
                  </>
                );
              },
        }, 
        {
            Header: "Description",
            accessor: "description",
            style: { width: "50%" },
            Cell: ({ row }) => {
                const { description, descriptionSearch, descriptionListOptions } = row.original;
                return description ? (
                  description
                ) : (<>
                  <SelectItem
                    searchValue={descriptionSearch}
                    searchValueChngHndlr={(value) => searchValueChngHndlr(value, row.index, "descriptionSearch")}
                    listOptions={descriptionListOptions || []}
                    columnsList={customerColumnsList}
                    placeholder="Search Description"
                    selectedItemHandler={(itemDetails) => selectItemHndlr(itemDetails, row.index)}
                    fieldKey="description"
                    dataIdKey="description"
                    hideListOnBlur={true}
                    inputClassname={`multi-item-input ${
                      specialPricesValidation.touched?.[row.index]?.description && specialPricesValidation.errors?.[row.index]?.description
                        ? "custom-invalid-field"
                        : ""
                    }`}
                  />
                   {specialPricesValidation.touched?.[row.index]?.description && specialPricesValidation.errors?.[row.index]?.description && (
                    <div className="custom-invalid-feedback">
                      {specialPricesValidation.touched?.[row.index]?.description && specialPricesValidation.errors?.[row.index]?.description}
                    </div>
                  )}
                  </>
                );
              },
        }, 
        {
            Header: "Customer Price",
            accessor: "customerPrice",
            style: { width: "25%" },
            Cell: ({ row }) => {
                const { customerPrice, itemNumber, id } = row.original;
                return !id && itemNumber ? (<>
                  <div className={`form-icon`}>
                    <Input
                      type="number" onWheel={(e) => e.target.blur()}
                      className="form-control form-control-icon"
                      value={customerPrice}
                      onChange={(e) => {
                        handleCustomerPriceChange(e, row.index);
                      }}
                      onKeyDown={blockInvalidChar}
                      onBlur={() => handleSpecialPriceBlur(row.index)}
                      invalid={specialPricesValidation.touched?.[row.index]?.customerPrice && specialPricesValidation.errors?.[row.index]?.customerPrice}
                    />
                    <i style={{ fontStyle: "normal" }}>{currencySymbol}</i>
                  </div>
                  {specialPricesValidation.touched?.[row.index]?.customerPrice && specialPricesValidation.errors?.[row.index]?.customerPrice && (
                    <div className="custom-invalid-feedback">{specialPricesValidation.errors?.[row.index]?.customerPrice}</div>
                  )}
                  </>
                ) : customerPrice ? (
                  `${currencySymbol}${customerPrice}`
                ) : (
                  ""
                );
              },
        }, 
        // {
        //     Header: "Group Coder",
        //     accessor: "groupCode",
        //     Cell:({row:{original}}) => <Input className="form-control" type="text" />
        // }, 
        // {
        //     Header: "",
        //     accessor: "action",
        //     Cell:() => <i className="ri-delete-bin-line text-danger fs-5"></i>
        // }
        {
            Header: "Action",
            accessor: "action",
            Cell: ({row}) => <i className="ri-delete-bin-line text-danger fs-5" onClick={() => handleRowDelete(row.original.id,row.index)}></i>,
          },
    ]
}

export const mapSpecialPrices = (apiResp, setSpecialPrices) => {
    const specialPrices = [];
    (apiResp?.data?.specialPrices || []).forEach((item) => {
      specialPrices.push({
        id: item?.id,
        itemNumber: item?.inventory?.itemNumber,
        description: item?.inventory?.description,
        customerPrice: item?.cPrice,
      });
    });
    setSpecialPrices(specialPrices);
  };