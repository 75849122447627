import departmentconstants from "./departmentconstants";

export const changeDepartmentApiLoadingStatusAct = (status) => {
    return {
      type: departmentconstants.CHANGE_DEPARTMENT_API_LOADING_STATUS,
      payload: status,
    };
};
  

export const createDepartmentAct = (apiEndpoint, payload, optionalConfig) => {
  return {
    type: departmentconstants.CREATE_DEPARTMENT_REQUEST,
    apiEndpoint,
    payload,
    optionalConfig,
  };
};

export const updateDepartmentAct = (apiEndpoint, payload, optionalConfig) => {
    return {
      type: departmentconstants.UPDATE_DEPARTMENT_REQUEST,
      apiEndpoint,
      payload,
      optionalConfig,
    };
};

export const getDepartmentsAct = (apiEndpoint, payload, optionalConfig) => {
    return {
      type: departmentconstants.GET_DEPARTMENTS_REQUEST,
      apiEndpoint,
      payload,
      optionalConfig,
    };
};

export const deleteDepartmentsAct = (apiEndpoint, payload, optionalConfig) => {
    return {
      type: departmentconstants.DELETE_DEPARTMENTS_REQUEST,
      apiEndpoint,
      payload,
      optionalConfig,
    };
};
