import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import { Card, CardBody, Col, Container, Row, } from 'reactstrap';
// Import Images
import HeaderLogo from "../../assets/images/warehouse/menu/header_logo.png";
//Helper
import { menuList } from "./helper";

export default function ReportsMenu() {
    const [currentDateTime, setCurrentDateTime] = useState(new Date());
    useEffect(() => {
        const intervalId = setInterval(() => {
          setCurrentDateTime(new Date());
        }, 1000);
        return () => clearInterval(intervalId);
      }, []);
    return (
        <div className="warehouse-menu-wrapper overflow-hidden min-vh-100 wh-background-image">
            <div className="menu-header d-flex justify-content-between align-items-center py-1 px-4">
                <img className="header-logo" alt="" src={HeaderLogo} style={{mixBlendMode:"darken"}}/>
                <div className="wh-text-muted">
                {currentDateTime.toLocaleString('en-US', {
                       weekday: 'long',
                       year: 'numeric',
                       month: 'long',
                       day: 'numeric',
                       hour: '2-digit',
                       minute: '2-digit',
                       second: '2-digit',
                       hour12: true,
                     })}{' '}
                </div>
            </div>
            <div className="page-menu-content py-2 px-2 ">
                <Container fluid>
                    <Row>
                        {(menuList || []).map((item, key) => (
                            <Col xs={12} md={6} xl={4} key={key}>
                                <Link to={item.url}>
                                    <div className="d-flex align-items-center justify-content-center mb-3 card-animate wh-report-card">
                                        <div className="menu-card-body d-flex justify-content-center align-items-center ">
                                            <div className="d-flex flex-column align-items-center justify-content-center h-100">
                                                <img className="mb-1 menu-image-icon" src={item.imgSrc} />
                                                <h3 className="font-weight-bold wh-menu-card-text">{item.name}</h3>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            </Col>
                        ))}
                    </Row>
                </Container>
            </div>

        </div>
    )
}