import React, { useState, useRef, useEffect, useContext } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { Col, Container, Input, Label, Row, Button, Form, FormFeedback, } from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";
//Api Helper
import { generateApiUrl } from "../../api/apihelper";
//Actions
import { loginUserAct, saveLoginDetails } from "../../store/auth/login/actions";
import { getAllStationAct } from "../../store/usermanagement/usermanagementactions";
//Context
import { PermissionsContext } from "../../context/PermissionsContext"
// Import Images
import LoginLogo from "../../assets/images/warehouse/login/login-logo.png";

export default function WarehouseLogin({ history }) {
  const [showPassword,setShowPassword] = useState(false);
  const { handleLogin } = useContext(PermissionsContext);
  const togglePassword = () => setShowPassword(!showPassword)
  const dispatch = useDispatch();
  const validation = useFormik({
    initialValues: {
      stationId: "",
      username: "",
      password: "",
    },
    validationSchema: Yup.object({
      stationId: Yup.string().required("Please Select Station"),
      username: Yup.string().required("Please Enter Your Username"),
      password: Yup.string().required("Please Enter Your Password"),
    }),
    onSubmit: (values) => {
      dispatch(loginUserAct(generateApiUrl("login_user"),values));
    },
  });
  const { loginUserResp, getAllStationsResp, loginErrorResp, userManagementErrorResp } = useSelector(({Login, userManagement}) => ({
    loginUserResp:Login.loginUserResp,
    getAllStationsResp:userManagement.getAllStationsResp,
    loginErrorResp: Login.loginErrorResp,
    userManagementErrorResp: userManagement.userManagementErrorResp
  }),shallowEqual);
  const authResp = useRef({
    prevLoginUserResp: loginUserResp,
    prevLoginErrorResp: loginErrorResp,
    prevUserManagementErrorResp: userManagementErrorResp
  });
  useEffect(() => {
    const { prevLoginUserResp, prevLoginErrorResp, prevUserManagementErrorResp } = authResp.current || {};
    if (loginUserResp && prevLoginUserResp !== loginUserResp) {
      const { data : {token, tokenExpiresAt, refreshToken, user}} = loginUserResp;
      localStorage.setItem("token",token);
      localStorage.setItem("tokenExpiresAt",tokenExpiresAt);
      localStorage.setItem("refreshToken",refreshToken);
      localStorage.setItem("user",JSON.stringify(user));
      dispatch(saveLoginDetails({
        token,
        tokenExpiresAt,
        refreshToken,
        user
      }));
      handleLogin(user.role.id);
      history.push("/warehousemenu");
    }
    else if (loginErrorResp && prevLoginErrorResp !== loginErrorResp) {
        const { errors,error, message } = loginErrorResp || {};
        toast.error((Array.isArray(errors) && errors[0]) || error || message);
    }
    else if (userManagementErrorResp && prevUserManagementErrorResp !== userManagementErrorResp) {
      const { errors,error, message } = userManagementErrorResp || {};
      toast.error((Array.isArray(errors) && errors[0]) || error || message);
  }
    authResp.current.prevLoginUserResp = loginUserResp;
    authResp.current.prevLoginErrorResp = loginErrorResp;
    authResp.current.prevUserManagementErrorResp = userManagementErrorResp;
  }, [ loginUserResp, loginErrorResp, userManagementErrorResp ]);
  useEffect(() => {
    dispatch(getAllStationAct(generateApiUrl("get_all_stations")));
  },[])
//   const handleSubmit = (e) => {
//     e.preventDefault();
//     history.push("/warehousemenu");
//   };
  return (
      <>
      <ToastContainer />
    <div className="warehouse-login-wrapper py-5 d-flex justify-content-center align-items-center min-vh-100 wh-background-image">
      <div className="overflow-hidden pt-lg-5 px-5 w-100" >
        <Container fluid>
            <Row className="g-0">
              <Col xl={6}>
                <div className="d-flex justify-content-center align-items-center h-100">
                  <img className="logo-image h-50 w-25" alt="" src={LoginLogo} />
                </div>
              </Col>
              <Col xl={6}>
                <div className="wh-login-card px-5">
                  <div>
                    <h5 className="text-light" style={{fontSize:'20px'}}>Welcome !</h5>
                    <p style={{color: "rgb(189 192 205)"}}>Sign in to continue to Ware house.</p>
                  </div>

                  <div className="mt-4">
                    <Form onSubmit={validation.handleSubmit}>
                      <div className="mb-4 form-select-login">
                        <Label htmlFor="userrole" className="form-label mb-0  " >
                          Station
                        </Label>
                        
                        <Input
                          type="select"
                          className={`form-select mb-0`}
                          id="stationId"
                          name="stationId"
                          value={validation.values.stationId}
                          onChange={validation.handleChange}
                          invalid={validation.touched.stationId && validation.errors.stationId ? true : false}
                          style={{ color: validation.values.stationId ? '#696b6c' : '#606264',fontWeight: validation.values.stationId ?'900':'700' }}
                        >
                          <option value="" style={{color: '#606264'}}>Select Station</option>
                              {(getAllStationsResp?.data?.stations?.rows || []).length === 0 ? (
                                <option value="" disabled> Loading...</option>
                              ) : (
                                (getAllStationsResp?.data?.stations?.rows || []).map(item => (
                                  <option 
                                      key={item.id} value={item.id}
                                  >
                                    {item.station}
                                  </option>
                                ))
                              )}
                        </Input>
                        <FormFeedback>
                          {validation.touched.stationId && validation.errors.stationId ? validation.errors.stationId : ""}
                        </FormFeedback>
                      </div>
                      <div className="mb-4 form-control-login">
                        <Label htmlFor="username" className="form-label mb-0  ">
                          User Name
                        </Label>
                        <Input
                          id="username"
                          name="username"
                          value={validation.values.username}
                          onChange={validation.handleChange}
                          type="text"
                          className="form-control"
                          placeholder="Enter Name"
                          invalid={validation.touched.username && validation.errors.username ? true : false}
                          onKeyDown={e => {
                            if(e.key === "Enter") validation.handleSubmit()
                          }}
                        style={{color:'#212529'}}
                        />
                        <FormFeedback>
                          {validation.touched.username && validation.errors.username ? validation.errors.username : ""}
                        </FormFeedback>
                      </div>

                      <div className="form-control-login mb-5">
                        <Label className="form-label mb-0" htmlFor="password-input" >
                          Password
                        </Label>
                        <div className="position-relative  form-control-login">
                          <Input
                            type={showPassword ? "text" : "password"}
                            className="form-control pe-5"
                            placeholder="Enter password"
                            id="password"
                            name="password"
                            value={validation.values.password}
                            onChange={validation.handleChange}
                            invalid={validation.touched.password && validation.errors.password ? true : false}
                            onKeyDown={e => {
                              if(e.key === "Enter") validation.handleSubmit()
                            }}
                          />
                          <FormFeedback>
                            {validation.touched.password && validation.errors.password
                              ? validation.errors.password
                              : ""}
                          </FormFeedback>
                          <button
                            className="btn btn-link position-absolute end-0 top-0 text-decoration-none password-addon wh-login-eye-icon"
                            type="button"
                            id="password-addon"
                            onClick={togglePassword}
                            style={{padding: '0.2rem 1rem',}}
                          >
                            {showPassword ? <i className="ri-eye-off-fill align-middle"></i> : <i className="ri-eye-fill align-middle"></i>}
                          </button>
                        </div>
                      </div>

                      <div className="mt-4">
                        <button className="btn wh-login-btn w-100" type="button" onClick={validation.handleSubmit}
                        >
                          Sign In
                        </button>
                      </div>
                    </Form>
                  </div>
                </div>
              </Col>
            </Row>
        </Container>
      </div>
    </div>
    </>
  );
}
