import React, { useState, useEffect, useRef } from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { Prompt, useLocation, useHistory } from "react-router-dom";
import F12CloseModalListener from "./F12close";

const RouteChangeConfirmation = ({
  content,
  title,
  isBlocked,
  saveDetailsHndlr,
  routeChangeApiResp,
  routeChangeApiErrResp,
  formValidation,
  toast,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [focusedButton, setFocusedButton] = useState("yes"); 

  const location = useLocation();
  const history = useHistory();
  const [lastLocation, setLastLocation] = useState(location);
  const [shouldUnload, setShouldUnload] = useState(false);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);

  const yesButtonRef1 = useRef(null);
  const noButtonRef1 = useRef(null);


  const closeModal = () => {
    setIsModalOpen(false);
    setShouldUnload(false);
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const showModal = (nextLocation) => {
    openModal();
    setLastLocation(nextLocation);
  };

  const handleBlockedRoute = (nextLocation) => {
    if (!confirmedNavigation && isBlocked) {
      showModal(nextLocation);
      return false;
    }
    return true;
  };

  const showFormValidation = useRef(true);

  const handleConfirmNavigationClick = (saveTheInfo) => {
    if (saveTheInfo) {
      saveDetailsHndlr();
      showFormValidation.current = true;
    } else {
      closeModal();
      setConfirmedNavigation(true);
    }
  };

  const prevRouteChangeApiResp = useRef(routeChangeApiResp);
  const prevRouteChangeApiErrResp = useRef(routeChangeApiErrResp);
  const prevFormValidation = useRef(formValidation);

  useEffect(() => {
    if (routeChangeApiResp && prevRouteChangeApiResp.current !== routeChangeApiResp && isModalOpen) {
      closeModal();
      setConfirmedNavigation(true);
    } else if (routeChangeApiErrResp && prevRouteChangeApiErrResp.current !== routeChangeApiErrResp && isModalOpen) {
      closeModal();
    } else if (formValidation && prevFormValidation.current !== formValidation && isModalOpen) {
      let isError = false;
      if (!Array.isArray(formValidation) && Object.keys(formValidation.errors).length && formValidation.isSubmitting) isError = true;
      else if (Array.isArray(formValidation)) {
        for (let i of formValidation) {
          if (Object.keys(i.errors).length && i.isSubmitting) {
            isError = true;
            break;
          }
        }
      }
      if (isError) {
        closeModal();
        if (toast && showFormValidation.current) {
          toast.error("Please provide all the required fields");
          showFormValidation.current = false;
        }
      }
    }
    prevRouteChangeApiResp.current = routeChangeApiResp;
    prevRouteChangeApiErrResp.current = routeChangeApiErrResp;
    prevFormValidation.current = formValidation;
  }, [routeChangeApiResp, routeChangeApiErrResp, formValidation]);

  // Block react routes
  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      // Navigate to the previous blocked location with your navigate function
      setShouldUnload(true);
      history.push(lastLocation.pathname);
      setConfirmedNavigation(false)
    }
  }, [confirmedNavigation, lastLocation, history]);


  // Block non-react routes
  useEffect(() => {
    const unload = (event) => {
      if (isBlocked && !shouldUnload) {
        // eslint-disable-next-line no-param-reassign
        event.returnValue = content;
      }
      if (shouldUnload) {
        // eslint-disable-next-line no-param-reassign
        event.returnValue = "";
      }
    };
    const handleKeyDown = (e) => {
      if ((e.key === "Escape" || e.key === "Esc") && isModalOpen) {
        closeModal();
      } else if (isModalOpen && (e.key === "ArrowRight")) {
        e.preventDefault();
        setFocusedButton((prevButton) => (prevButton === "yes" ? "no" : "yes"));
      }
      else if (isModalOpen && (e.key === "ArrowLeft")) {
        e.preventDefault();
        setFocusedButton((prevButton) => (prevButton === "no" ? "yes" : "no"));
      }
    
    };
    window.addEventListener("beforeunload", unload);
    window.addEventListener('keydown', handleKeyDown);

    return () => window.removeEventListener("beforeunload", unload);
  }, [isBlocked, content, shouldUnload,isModalOpen,]);




  useEffect(() => {
    if (isModalOpen) {
      const buttonRef = focusedButton === 'yes' ? yesButtonRef1 : noButtonRef1;
      setTimeout(() => {
        if (buttonRef.current) {
          buttonRef.current.focus();
        }
      }, 0);
    }
  }, [isModalOpen, focusedButton, yesButtonRef1, noButtonRef1]);
  
 
  return (
    <>
      <Prompt when message={handleBlockedRoute} />
      <Modal isOpen={isModalOpen} toggle={closeModal} centered>
      <F12CloseModalListener onClose={closeModal} />
        <ModalHeader className="border-bottom py-2">Save the changes</ModalHeader>
        <ModalBody className="py-0 pb-2">
          <div className="mt-2">
            <div className="pt-2 fs-15">
              {/* <h4>Are you sure ?</h4> */}
              <p className="text-muted mb-0">Do you want save the changed infomation?</p>
            </div>
          </div>
          <div className="d-flex gap-2 justify-content-end mt-2 mb-2">
            <button
               ref={yesButtonRef1}
              type="button"
              className= {`btn w-sm  ${focusedButton === "yes" ? "btn-primary border border-danger" : "btn-light"}`}
              id="delete-record"
              onClick={() => handleConfirmNavigationClick(true)}
              onKeyDown={(e) => {
                if (e.key === "ArrowLeft") {
                  e.preventDefault();
                  setFocusedButton("no");
                }
              }}
            >
              Yes
            </button>
            <button
                ref={noButtonRef1}
              type="button"
              className={`btn w-sm  ${focusedButton === "no" ? "btn-primary border border-danger" : "btn-light"}`}
              data-bs-dismiss="modal"
              onClick={() => handleConfirmNavigationClick(false)}
              onKeyDown={(e) => {
                if (e.key === "ArrowRight") {
                  e.preventDefault();
                  setFocusedButton("yes");
                }
              }}
            >
              No
            </button>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default RouteChangeConfirmation;
