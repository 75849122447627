import React, { useMemo, useState, useRef, useEffect } from "react";
import { Col, Container, Row, ModalHeader, Modal, ModalBody, Form } from "reactstrap";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { toast } from "react-toastify";
import WhTableContainer from "../../../../Components/Common/WhTables";
import { generateColumns, actions } from "./helper";
import AddDepartment from "./AddDepartment";
import ApiLoader from "../../../../Components/Common/ApiLoader";
import Confirmation from "../../../../Components/Common/Confirmation";
//Api Helper
import { generateApiUrl } from "../../../../api/apihelper";
//Actions
import { getDepartmentsAct, deleteDepartmentsAct } from "../../../../store/department/departmentactions";

export const DepartmentsTab = () => {
  const [tableData, setTableData] = useState([]);
  const [modal, setModal] = useState(false);
  const [editModalData, setEditModalData] = useState(null);
  const [rowSelection, setRowSelection] = useState({});
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const deleteDepartmentConfig = useRef({
    deleteMultiple:false,
    idOfDepartmentToBeDeleted:""
  });
  const toggleDeleteConfirmation = () => setShowDeleteConfirmation(prev => !prev);
  function toggle() {
    setModal(!modal);
  }
  const columns = useMemo(() => generateColumns(setEditModalData, toggle, toggleDeleteConfirmation, deleteDepartmentConfig), []);
  function newBtnToggle() {
    setModal(true);
  }
  function togglePrintModal() {}
  const dispatch = useDispatch();
  const { departmentApiLoading, getDepartmentsResp, createDepartmentResp, updateDepartmentResp, deleteDepartmentsResp, departmentErrorResp } =
    useSelector(
      ({ department }) => ({
        departmentApiLoading: department.departmentApiLoading,
        getDepartmentsResp: department.getDepartmentsResp,
        createDepartmentResp: department.createDepartmentResp,
        updateDepartmentResp: department.updateDepartmentResp,
        deleteDepartmentsResp: department.deleteDepartmentsResp,
        departmentErrorResp: department.departmentErrorResp,
      }),
      shallowEqual
    );
  const prevApiResp = useRef({
    prevGetDepartmentsResp: getDepartmentsResp,
    prevCreateDepartmentResp: createDepartmentResp,
    prevUpdateDepartmentResp: updateDepartmentResp,
    prevDeleteDepartmentsResp: deleteDepartmentsResp,
    prevDepartmentErrorResp: departmentErrorResp,
  });
  useEffect(() => {
    const { prevGetDepartmentsResp, prevCreateDepartmentResp, prevUpdateDepartmentResp, prevDeleteDepartmentsResp, prevDepartmentErrorResp } =
      prevApiResp.current || {};
    if (getDepartmentsResp && prevGetDepartmentsResp !== getDepartmentsResp) {
      setTableData(getDepartmentsResp?.data?.departments || []);
    } else if (createDepartmentResp && prevCreateDepartmentResp !== createDepartmentResp) {
      toast.success("Successfully created a user role");
      toggle();
      dispatch(getDepartmentsAct(generateApiUrl("get_departments")));
    } else if (updateDepartmentResp && prevUpdateDepartmentResp !== updateDepartmentResp) {
      toast.success("Successfully updated a user role");
      toggle();
      dispatch(getDepartmentsAct(generateApiUrl("get_departments")));
    }  else if (deleteDepartmentsResp && prevDeleteDepartmentsResp !== deleteDepartmentsResp) {
        toast.success("Successfully deleted department");
        toggleDeleteConfirmation();
        dispatch(getDepartmentsAct(generateApiUrl("get_departments")));
    } else if (departmentErrorResp && prevDepartmentErrorResp !== departmentErrorResp) {
      const { errors, error, message } = departmentErrorResp || {};
      toast.error((Array.isArray(errors) && errors[0]) || error || message);
    }
    prevApiResp.current.prevGetDepartmentsResp = getDepartmentsResp;
    prevApiResp.current.prevCreateDepartmentResp = createDepartmentResp;
    prevApiResp.current.prevUpdateDepartmentResp = updateDepartmentResp;
    prevApiResp.current.prevDeleteDepartmentsResp = deleteDepartmentsResp;
    prevApiResp.current.prevDepartmentErrorResp = departmentErrorResp;
  }, [createDepartmentResp, updateDepartmentResp, getDepartmentsResp, deleteDepartmentsResp, departmentErrorResp]);
  useEffect(() => {
    dispatch(getDepartmentsAct(generateApiUrl("get_departments")));
  }, []);
  useEffect(() => {
    if(!modal && editModalData) setEditModalData(null);
  }, [modal])
  const handleDepartmentDelete = () => {
    const formData = new FormData();
    if(deleteDepartmentConfig.current.deleteMultiple) {
      tableData.filter((item,index) => rowSelection[index]).forEach((item,index) => formData.append(`ids[${index}]`,item.id));
    } else {
      formData.append("ids[0]",deleteDepartmentConfig.current.idOfDepartmentToBeDeleted)
    }
    dispatch(deleteDepartmentsAct(generateApiUrl("delete_departments"),formData))
  }
  return (
    <React.Fragment>
      <Container fluid className="g-0">
        <Row className="g-0">
          <Col className="offset-sm-10 px-4" xs={2}>
            <div className="d-flex justify-content-between border border-section py-2 px-1 h-100">
              {actions(newBtnToggle, togglePrintModal, toggleDeleteConfirmation, deleteDepartmentConfig).map((item) => {
                return (
                  <div key={item.id} className="action-icon-container" onClick={item.clickHandler || (() => {})}>
                    <img className="action-icon" src={item.icon} />
                    <span>{item.label}</span>
                  </div>
                );
              })}
            </div>
          </Col>
        </Row>
        <Row className="g-0">
          <Col xs={12}>
            <WhTableContainer
              columns={columns}
              data={tableData || []}
              customPageSize={100}
              className="custom-header-css"
              divClass="table-responsive mt-2 table-height-66vh"
              tableClass="table-bordered"
              theadClass="table-light"
              showRowSelection={true}
              setRowSelection={setRowSelection}
            />
          </Col>
        </Row>
      </Container>
      <Modal id="showModal" isOpen={modal} toggle={toggle} centered size="lg">
        <ModalHeader className="py-2 px-3" toggle={toggle}>
        {editModalData ? "Edit" : "Add"} Department
        </ModalHeader>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <ModalBody className="px-0 pt-0">
            <AddDepartment dispatch={dispatch} generateApiUrl={generateApiUrl} editModalData={editModalData} />
          </ModalBody>
        </Form>
      </Modal>
      <ApiLoader loading={departmentApiLoading} />
      <Confirmation show={showDeleteConfirmation} onCloseClick={toggleDeleteConfirmation} title="Delete Department" confirmationText="Are you sure you want to delete the records?" onConfirmClick={handleDepartmentDelete}/>
    </React.Fragment>
  );
};
