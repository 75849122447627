import {
  LOGIN_USER,
  LOGIN_SUCCESS,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  API_ERROR,
  RESET_LOGIN_FLAG,
  SAVE_LOGIN_DETAILS,
  EMPTY_LOGIN_DETAILS,
} from "./actionTypes";
import loginConstants from "./actionTypes";

const initialState = {
  error: "",
  loading: false,
  loginUserResp: undefined,
  loginDetails: undefined,
  loginErrorResp: undefined,
};

const login = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_USER:
      state = {
        ...state,
        loading: true,
      };
      break;
    case LOGIN_SUCCESS:
      state = {
        ...state,
        loading: false,
      };
      break;
    case LOGOUT_USER:
      state = { ...state, isUserLogout: false };
      break;
    case LOGOUT_USER_SUCCESS:
      state = { ...state, isUserLogout: true };
      break;
    case API_ERROR:
      state = {
        ...state,
        error: action.payload.data,
        loading: false,
        isUserLogout: false,
      };
      break;
    case RESET_LOGIN_FLAG:
      state = {
        ...state,
        error: null,
      };
      break;
    case loginConstants.LOGIN_USER_SUCCESS:
      state = { ...state, loginUserResp: action.payload };
      break;
    case SAVE_LOGIN_DETAILS:
      state = { ...state, loginDetails: action.payload };
      break;
    case EMPTY_LOGIN_DETAILS:
      state = { ...state, loginDetails: undefined };
      break;
    case loginConstants.LOGIN_USER_FAIL:
      state = { ...state, loginErrorResp: action.payload };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default login;
