//Utility
import { getMmddyyyyDateFormatted } from "../../../../utility/dateutility";
import {formatAmount} from "../../../../utility/commonutility";

export function generateColumns(history, page) {
  return [
    {
      Header: "S.No",
      accessor: "id",
      Cell: ({ row }) => (page - 1) * 10 + (row.index + 1),
    },
    {
      Header: "Invoice Number",
      accessor: "invoiceNumber",
      Cell: ({ cell: { value } }) => (
        <div
          className="wh-link-color-green"
          style={{ cursor: "pointer" }}
          onClick={() => {
            history.push(`/warehousesales/${value}`, { redirected: true });
            localStorage.setItem("activeTab", JSON.stringify({ warehouseinventory: "sales" }));
          }}
        >
          {value}
        </div>
      ),
    },
    {
      Header: "Invoice Date",
      accessor: "invoiceDate",
      Cell : ({cell:{value}}) => value ? getMmddyyyyDateFormatted(value) : "-"
    },
    {
      Header: "Customer Name",
      accessor: "customerName",
      Cell: ({ row: { original : {customer : {firstName, lastName, customerNumber}} } }) => (
        <div
          className="wh-link-color-green"
          style={{ cursor: "pointer" }}
          onClick={() => {
            history.push(`/warehousecustomers/${customerNumber}`, { redirected: true });
            localStorage.setItem("activeTab", JSON.stringify({ warehouseinventory: "sales" }));
          }}
        >
          {`${firstName || ""} ${lastName || ""} ${(!firstName && !lastName) ? "-" : ""}`}
        </div>
      ),
    },
    {
      Header: <div className="text-center">Sales</div>,
      accessor: "sales",
      Cell:({row:{original:{shipQty}}}) => shipQty > 0 ? shipQty : "",
      style:{textAlign:"center"}
    },
    {
      Header: <div className="text-center">Return</div>,
      accessor: "return",
      Cell:({row:{original:{shipQty}}}) => shipQty < 0 ? Math.abs(shipQty) : "",
      style:{textAlign:"center"}
    },
    {
      Header: <div className="text-center">Damaged</div>,
      accessor: "damaged",
      style:{textAlign:"center"}
    },
    {
      Header: "Cost",
      accessor: "cost",
      Cell : ({cell : {value}}) => (typeof value == "number") ? <>{formatAmount(value.toFixed(2))}</> : "",
      style: {textAlign:"right"}
    },
    {
      Header: "Price",
      accessor: "price",
      Cell : ({cell : {value}}) => (typeof value == "number") ? <>{formatAmount(value.toFixed(2))}</> : "",
      style: {textAlign:"right"}
    },
    {
      Header: "Total",
      accessor: "RAmount",
      Cell : ({row : {original : {price, quantity}}}) => (typeof price == "number") ? <>{formatAmount((price * quantity).toFixed(2))}</> : "",
      style: {textAlign:"right"}
    },
  ];
}
